module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="bydate-listing">\n  ';
 if (_.isEmpty(groups)) { 
__p+='\n\n  <p class="news-msg">No archive news items found.</p>\n\n  ';
 } else { 
__p+=' ';
 if (obj.dateFrom && obj.dateTo) { 
__p+='\n  <p class="news-msg">\n    Archive news items between <em>'+
((__t=( obj.dateFrom ))==null?'':_.escape(__t))+
'</em> and <em>'+
((__t=( obj.dateTo ))==null?'':_.escape(__t))+
'</em>...\n  </p>\n  ';
 } else { 
__p+='\n  <p class="news-msg">Archive news items for <em>'+
((__t=( obj.dateFrom ))==null?'':_.escape(__t))+
'</em>...</p>\n  ';
 } 
__p+=' ';
 // Reverse the keys to get the years and months into the right order 
__p+=' ';

  _.each(_.keys(groups).reverse(), function(yearKey) { 
__p+='\n  <ul>\n    <li>'+
((__t=( yearKey ))==null?'':_.escape(__t))+
'</li>\n\n    ';
 _.each(_.keys(groups[yearKey]).reverse(), function(monthKey) { 
__p+='\n    <ul>\n      <li>'+
((__t=( monthNames[monthKey] ))==null?'':_.escape(__t))+
'</li>\n\n      ';
 _.each(groups[yearKey][monthKey], function(newsitems, published_date) { 
__p+='\n      <ul>\n        <li>'+
((__t=( app.moment(published_date).format(app.settings['longDateFormat']) ))==null?'':_.escape(__t))+
'</li>\n        <ul>\n          ';
 _.each(newsitems, function(item) { 
__p+='\n          <li>\n            <a\n              class="doc-link"\n              href="/news/'+
((__t=( item.attributes.doc_id ))==null?'':_.escape(__t))+
'"\n              data-id="'+
((__t=( item.attributes.doc_id ))==null?'':_.escape(__t))+
'"\n              >'+
((__t=( item.attributes.title ))==null?'':_.escape(__t))+
'</a\n            >\n          </li>\n          ';
 }); 
__p+='\n        </ul>\n      </ul>\n      ';
 }); 
__p+='\n    </ul>\n    ';
 }); 
__p+='\n  </ul>\n  ';
 }); 
__p+=' ';
 } 
__p+='\n</div>\n';
}
return __p;
};
