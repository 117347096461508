define(['backbone', '../helpers'], function (Backbone, helpers) {
    /*
        Table of documents helpers
    */
    var PopupWin = helpers.BaseView.extend({
        el: 'body',
        events: {
            'click #toolbar-pwclose .btn': 'closeWindow',
            'click [data-doc_id]': 'navigateNew',
            'click #toolbar-brand a': 'navigateParent',
            'click #recent-menu a': 'navigateNew',
            'click #amended-menu a': 'navigateNew',
            'click .toolbar-top a': 'scrollToTop',
            'change #toolbar-preferences input[name="prefs-tod"]': 'updatePreferences',
        },
        initialize: function () {
            this.toggleClose();
            var callBackEvent = (this.callBackEvent = new URLSearchParams(location.search).get(
                'event',
            ));
            var callBackArgs = (this.callBackArgs = new URLSearchParams(window.location.search).get(
                'args',
            ));
            if (this.callBackEvent) {
                // need to load all the anchor tags with this querystring so when we
                // navigate we don't lose it
                $(
                    '.toolbar-tod-home a.toolbar-item, #main .tod-docs a, #main .tod-menu a, #recent-menu a, #amended-menu a, #main .breadcrumb a',
                ).each(function () {
                    var href = $(this).attr('href');
                    if (href) {
                        href +=
                            (href.match(/\?/) ? '&' : '?') +
                            'event=' +
                            callBackEvent +
                            '&' +
                            'args=' +
                            callBackArgs;
                        $(this).attr('href', href);
                    }
                });
            }
            // we've lost the ability to go to the top of the page, possibly
            // because of the fixed bottom nav or something
            $(window).on(
                'hashchange',
                _.bind(function () {
                    if (document.location.hash == '') {
                        this.$('#main').scrollTop(0);
                    } else {
                        var lastPage =
                            document.location.pathname.substr((app.urls.tod + '/').length) +
                            document.location.hash;
                        if (lastPage && !lastPage.startsWith('#')) {
                            app.user.displayPrefs({ tod_lastpage: lastPage });
                        }
                        const newLocation = $(document.location.hash)[0];
                        // check that the location actually exists because the tod does funny things
                        if (newLocation) newLocation.scrollIntoView();

                        // if we're navigating make sure the index panel will scroll as well
                        const indexPanelHash = document.location.hash.replace(
                            'tod-section-header',
                            'index-panel',
                        );
                        const newIndexLocation = $(indexPanelHash)[0];
                        if (newIndexLocation) newIndexLocation.scrollIntoView();
                    }
                }, this),
            );
        },
        scrollToTop: function () {
            // Scroll to top of ToD Doc List and ToD Index Panel
            const pageTop = $('#top')[0];
            if (pageTop) pageTop.scrollIntoView();
            const indexTop = $(`#top-index`)[0];
            if (indexTop) {
                indexTop.scrollIntoView();
                // no button should be active in the Index Panel anymore
                $('a.tod-item-btn.active').removeClass('active');
            }
        },
        toggleClose: function () {
            this.$('#toolbar-pwclose').toggle(!_.isNull(window.opener));
        },
        closeWindow: function () {
            window.close();
        },
        navigateParent: function (evt) {
            if (window.opener) {
                evt.preventDefault();
                window.opener.location.href = evt.currentTarget.href;
            }
        },
        navigateNew: function (evt) {
            if (this.callBackEvent) {
                evt.preventDefault();
                var docId = $(evt.currentTarget).data('doc_id');
                var instanceId = $(evt.currentTarget).data('instance_id');
                window.opener.app.trigger(this.callBackEvent, docId, instanceId, this.callBackArgs);
                window.opener.focus();
            } else {
                if (window.opener) {
                    evt.preventDefault();
                    if (window.opener?.app._compareView && evt.originalEvent.view.name.includes("args=")) {
                        // we're in a ToD opened from Compare View inner ToD button, so need to open in correct place
                        const panel = new URLSearchParams(new URL(document.referrer).search).get(
                            'args',
                        );
                        window.opener.app._compareView.openDocument(
                            $(evt.currentTarget).data('doc_id'),
                            $(evt.currentTarget).data('instance_id'),
                            panel,
                        );
                    } else {
                        // open in a separate tab if ctrl click or middle click
                        if (evt && (evt.which == 2 || evt.ctrlKey)) {
                            window.manager.open(evt.currentTarget.href);
                        } else {
                            window.opener.document.location.href = evt.currentTarget.href;
                        }
                    }
                }
            }
            evt.stopImmediatePropagation();
        },
        updatePreferences: function (evt) {
            app.user.displayPrefs({ tod_homepage: evt.currentTarget.id === 'prefs-tod-todhome' });
        },
        render: function () {
            TemplateView.prototype.render.apply(this, arguments);
            if (document.location.hash != '') {
                $(document.location.hash)[0].scrollIntoView();
            }
        },
    });
    /*
        Launch table of documents window
    */
    var openPopupWin = function (evt) {
        evt.preventDefault();
        const host = $(evt.currentTarget)[0].host;
        var winUrl = $(evt.currentTarget).attr('href');
        var window_name = winUrl.substr(1);
        var popup_win = app.popups[window_name];
        const siteUrlRegEx = new RegExp(window.SITENAME);
        if (_.isUndefined(popup_win)) {
            popup_win = {
                name: window_name,
                orpahn: false,
            };
            app.popups[window_name] = popup_win;
        }
        if (winUrl == '/tod') {
            // Get the latest display prefs to see if we are opening the
            // ToD at the last page location.
            app.user.displayPrefs().done(function (data) {
                if (!data.tod_homepage && data.tod_lastpage.length) {
                    winUrl = app.urls.tod + '/' + data.tod_lastpage.replace(/^\//, '');
                }
                app.popups.tod.ref = window.manager.open(winUrl, {
                    name: app.popups.tod.name,
                    features: 'width=900,height=600',
                });
                app.popups.tod.ref.focus();
            });
        } else if (!siteUrlRegEx.test(host)) {
            // if the url is trying to navigate outside of Perspective from homepage only, open in a new tab
            window.manager.open(winUrl);
        } else {
            popup_win.ref = window.manager.open(winUrl, {
                name: popup_win.name,
                features: 'width=800,height=500',
            });
            popup_win.ref.focus();
        }
    };

    return {
        PopupWin: PopupWin,
        openPopupWin: openPopupWin,
    };
});
