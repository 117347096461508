import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';
import ModalDialog from 'react-bootstrap/ModalDialog';

import { WithLoadingOverlay } from './WithLoading';
import DraggableModalDialog from './DraggableModalDialog';

export const MODAL_SIZE = {
    SMALL: 'sm',
    MEDIUM: 'md',
    LARGE: 'lg',
};
Object.freeze(MODAL_SIZE);

const FormModal = ({
    show,
    hideModal,
    title,
    submitForm,
    children,
    loading,
    checkInputValidity,
    size,
    backdrop,
    cancelText,
    saveText,
    className,
    extraButtons,
    draggable,
    isMain,
}) => {
    const [validated, setValidated] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        setSubmitLoading(true);
        const form = event.currentTarget;
        const isValid = form.checkValidity(); // checks that required fields are filled

        const validInput = checkInputValidity && (await checkInputValidity(form)); // checks contents of fields

        setValidated(true);

        if (isValid === false || validInput === false) {
            setSubmitLoading(false);
            return;
        }

        const submitted = await submitForm(form);
        setSubmitLoading(false);

        if (submitted === false) return;

        setValidated(false);
        hideModal();
    };

    return (
        <Modal
            className={`rbs4 ${className ?? ''}`}
            show={show}
            onHide={hideModal}
            size={size}
            backdrop={backdrop}
            dialogAs={draggable ? DraggableModalDialog : ModalDialog}
            aria-label="form-modal"
        >
            <WithLoadingOverlay loading={loading || submitLoading} isMain={isMain}>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <h1 className="sr-only">{title}</h1>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{children}</Modal.Body>
                    <Modal.Footer>
                        {extraButtons}
                        {saveText && <Button type="submit">{saveText}</Button>}
                        <Button variant="secondary btn-default" onClick={hideModal}>
                            {cancelText}
                        </Button>
                    </Modal.Footer>
                </Form>
            </WithLoadingOverlay>
        </Modal>
    );
};

export default FormModal;

FormModal.defaultProps = {
    size: MODAL_SIZE.LARGE,
    cancelText: 'Cancel',
    saveText: 'Save',
    backdrop: true,
    loading: false,
    draggable: true,
};

FormModal.propTypes = {
    show: PropTypes.bool.isRequired,
    hideModal: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    submitForm: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    loading: PropTypes.bool.isRequired,
    size: PropTypes.oneOf([MODAL_SIZE.SMALL, MODAL_SIZE.MEDIUM, MODAL_SIZE.LARGE]),
    cancelText: PropTypes.string,
    saveText: PropTypes.string,
    checkInputValidity: PropTypes.func,
    backdrop: PropTypes.oneOf([true, false, 'static']),
    className: PropTypes.string,
    extraButtons: PropTypes.node,
    draggable: PropTypes.bool.isRequired,
    isMain: PropTypes.bool,
};
