import React from 'react';
import PropTypes from 'prop-types';

import DetailCard from './DetailCard';
import ContentManager from '../ContentManagement/ContentManager';
import { USER_SETTING_CONTENT_TYPES } from '../ContentManagement/ContentContainer';

const UserHistorySettings = ({ userModel }) => {
    const handleHistory = async (data) => {
        try {
            const response = await userModel.clearHistory({ clear: data });
            toaster.success(response.status);
        } catch (err) {
            toaster.error(`Could not clear history.`);
        }
    };

    return (
        <>
            <h2 id="clear-history">Clear history</h2>
            <ContentManager contentType={USER_SETTING_CONTENT_TYPES.SITE_DATA} />
            <div className="clear-history-section">
                <DetailCard
                    {...{
                        title: 'Search terms',
                        message: 'Clear the autocomplete suggestions in the Search window.',
                        buttonText: 'Clear my search term history',
                        handleClick: () => handleHistory('searchterms'),
                    }}
                />
                <DetailCard
                    {...{
                        title: 'Recent documents',
                        message: 'Clear the list of documents in "Recently Opened Documents".',
                        buttonText: 'Clear my recent document history',
                        handleClick: () => handleHistory('recentdocuments'),
                    }}
                />
            </div>
        </>
    );
};

export default UserHistorySettings;

UserHistorySettings.propTypes = {
    userModel: PropTypes.shape({
        clearHistory: PropTypes.func.isRequired,
    }),
};
