module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<p class="news-msg">\nUse the calendar to select all news articles published on a particular date or between two dates.\n</p>\n\n<form action="">\n\n    <div class="date-entry">\n        <label for="daterange-from">On/from</label>\n        <input class="form-control" type="text" maxlength="10" placeholder="'+
((__t=( app.settings.dateFormat ))==null?'':_.escape(__t))+
'" id="daterange-from">\n        <button type="button" class="btn-datepicker btn-link" id="daterange-from-datepicker" aria-hidden="true">\n            <span class="icon icon-sm icon-datepicker"><i class="picon picon-calendar"></i></span>\n        </button>\n        <button type="button" class="btn-link insert-today" id="daterange-from-insert-today">Today</button>\n    </div>\n    <div class="date-entry">\n        <label for="daterange-to">To</label>\n        <input class="form-control" type="text" maxlength="10" placeholder="'+
((__t=( app.settings.dateFormat ))==null?'':_.escape(__t))+
'" id="daterange-to">\n        <button type="button" class="btn-datepicker btn-link" id="daterange-to-datepicker" aria-hidden="true">\n            <span class="icon icon-sm icon-datepicker"><i class="picon picon-calendar"></i></span>\n        </button>\n        <button type="button" class="btn-link insert-today" id="daterange-to-insert-today">Today</button>\n    </div>\n\n    <div>\n        <input type="button" class="btn btn-sm btn-primary" id="searchByDate" value="Search">\n        <button type="button" class="btn btn-sm btn-default" id="clearSearchDates">Clear</button>\n    </div>\n\n</form>\n\n<div id="bydate-message">\n    <p class="news-msg">Fetching archive, please wait...</p>\n</div>\n\n<div class="" id="bydate-listing">\n<!-- Archive news listing gets inserted here for by date search -->\n</div>';
}
return __p;
};
