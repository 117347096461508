import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import models from '../../models';
import DocSearchItem from './DocSearchItem';
import DocResultsHeader from './DocResultsHeader';
import LoadingSpinner from '../LoadingSpinner';

const DocSearchContainer = ({ query, permission, triggerComparison, handleModal }) => {
    const docSearchResults = new models.DocumentSearchResults();

    // Title and Abbreviation searches happen separately because of how Django searches
    // within Solr for each of them respectfully. This means that although they are sorted
    // in Django within their type, they need to be resorted here when made into one list.
    // Also, can't limit the search return until here either...
    const [abbrevSearch, setAbbrevSearch] = useState({
        collectionData: {},
        documents: [],
        query: '',
    });
    const [titleSearch, setTitleSearch] = useState({
        collectionData: {},
        documents: [],
        query: '',
    });
    const [searching, setSearching] = useState(false);

    const [selectedRow, updateSelectedRow] = useState(0);

    const [families, setFamilies] = useState([]);

    useEffect(() => {
        (async () => {
            const results = await new models.Families().fetch();
            setFamilies(results);
        })();
    }, []);

    const abbrevResults = abbrevSearch.documents;

    const titleResults = titleSearch.documents;
    // Still need to join abbrev and title searches because of key-press scrolling
    const totalResults = abbrevResults.concat(titleResults);

    const fetchAbbrevSearch = async () => {
        try {
            const result = await docSearchResults.fetch({
                data: {
                    q: query,
                    state: 'draft OR ready OR published',
                    search: 'abbreviation',
                    sort_field: 'doc_reference_sort',
                    strict: true,
                },
                processData: true,
            });
            setAbbrevSearch(result);
        } catch (error) {
            toaster.error('Could not retrieve Abbreviated Search Results');
            console.error(error);
        }
    };

    const fetchTitleSearch = async () => {
        try {
            const result = await docSearchResults.fetch({
                data: {
                    q: query,
                    state: 'draft OR ready OR published',
                    search: 'title',
                    sort_field: 'doc_reference_sort',
                    strict: true,
                },
                processData: true,
            });
            setTitleSearch(result);
        } catch (error) {
            toaster.error('Could not retrieve Title Search Results');
            console.error(error);
        }
    };

    const debounceSearch = _.debounce(async () => {
        await fetchTitleSearch();
        await fetchAbbrevSearch();
        setSearching(false);
    }, 500);

    useEffect(() => {
        // **NOTE**: unless an unpublished file has been marked 'Index for Search' on the Document lifecycle page, it will not appear in the search results

        // Incase they type something that was a hit, but then continue typing and the new query isn't a match, we need to clear out the old matches
        setAbbrevSearch({ collectionData: {}, documents: [], query: '' });
        setTitleSearch({ collectionData: {}, documents: [], query: '' });
        setSearching(true);
        debounceSearch();
    }, [query]);

    const createDocUrl = (doc_id) => {
        let docUrl = '';
        if (doc_id.includes('timeline')) {
            docUrl = '/timeline';
        } else {
            docUrl = `${app.urls.documents}/${doc_id}`;
        }
        return docUrl;
    };

    const handlePress = async (event) => {
        const { key } = event;
        const totalRows = totalResults.length;
        if (key === 'ArrowUp' && selectedRow > 0) {
            updateSelectedRow(selectedRow - 1);
        } else if (key === 'ArrowDown' && selectedRow < totalRows - 1) {
            updateSelectedRow(selectedRow + 1);
        } else if (key === 'Enter') {
            // Check if we're comparing two documents, and therefore need to send the one we want back to the Compare View
            var notComparing = await triggerComparison(
                totalResults[selectedRow].doc_id,
                totalResults[selectedRow].doc_instance,
            );
            if (notComparing) {
                // Or we just want to see this one document
                const docId = totalResults[selectedRow].doc_id;
                const docUrl = createDocUrl(docId);
                handleModal();
                event.ctrlKey ? window.open(docUrl) : (window.location.href = docUrl);
            }
        }
    };

    const handleClick = async (event, doc_id, doc_instance) => {
        event.persist();
        const notComparing = await triggerComparison(doc_id, doc_instance);
        if (notComparing) {
            const docUrl = createDocUrl(doc_id);
            handleModal();
            event.ctrlKey ? window.open(docUrl) : (window.location.href = docUrl);
        }
    };

    const handleAuxClick = async (doc_id, doc_instance) => {
        const notComparing = await triggerComparison(doc_id, doc_instance);
        if (notComparing) {
            const docUrl = createDocUrl(doc_id);
            handleModal();
            window.open(docUrl);
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handlePress);

        return () => {
            window.removeEventListener('keydown', handlePress);
        };
    });

    const formatFamilyTitle = (result) => {
        // Because the Display title for family can be changed in Django Admin,
        // we need to match the doc_primary_family saved in Solr to the family slug.
        const resultFam = families.find((fam) => fam.slug === result.doc_primary_family);
        result['doc_primary_family_set_title'] = resultFam.title || '';

        return result;
    };

    if (searching) {
        return (
            <div className="retrieving-msg">
                <LoadingSpinner />
                <i>Retrieving all matching documents...</i>
            </div>
        );
    } else if (abbrevSearch.documents.length === 0 && titleSearch.documents.length === 0) {
        return (
            <div>
                <i>No documents found</i>
            </div>
        );
    } else {
        return (
            <table className="doc-search-container">
                <DocResultsHeader {...{ permission }} />
                <tbody>
                    {abbrevResults &&
                        abbrevResults.map((result, index) => {
                            const formattedResult = formatFamilyTitle(result);
                            return (
                                <DocSearchItem
                                    key={index}
                                    {...{ index, query, permission, handleClick, handleAuxClick }}
                                    doc={formattedResult}
                                    highlight={
                                        abbrevResults && selectedRow === index ? true : false
                                    }
                                />
                            );
                        })}
                    {abbrevResults.length > 0 && titleResults.length > 0 && (
                        <tr className="doc-search-item table-divider">
                            <td colSpan="102%"></td>
                        </tr>
                    )}
                    {titleResults &&
                        titleResults.map((result, index) => {
                            const formattedResult = formatFamilyTitle(result);
                            return (
                                <DocSearchItem
                                    key={index}
                                    {...{ query, permission, handleClick, handleAuxClick }}
                                    doc={formattedResult}
                                    index={index + abbrevResults.length}
                                    highlight={
                                        titleResults && selectedRow === index + abbrevResults.length
                                            ? true
                                            : false
                                    }
                                />
                            );
                        })}
                </tbody>
            </table>
        );
    }
};

export default DocSearchContainer;

DocSearchContainer.propTypes = {
    query: PropTypes.string,
    permission: PropTypes.bool,
    triggerComparison: PropTypes.func.isRequired,
    handleModal: PropTypes.func,
};
