import React, { useContext, useState } from 'react';
import FileRow, { DEFAULT_EXPANDED_LIMIT } from './FileRow';
import Table from 'react-bootstrap/Table';
import { FileContext } from './FileContext';
import FileSelect from './FileSelect';
import ExpandIcon from './ExpandIcon';

const FileTable = () => {
    const {
        filesState: { filesTree, files },
    } = useContext(FileContext);

    const [showAll, setShowExpanded] = useState(files.length < DEFAULT_EXPANDED_LIMIT);

    return (
        <div className="admin-table">
            <Table responsive className="collapsible">
                <thead>
                    <tr onClick={() => setShowExpanded(!showAll)} className="expandable">
                        <th>
                            <ExpandIcon showExpanded={showAll} />
                            Filename
                        </th>

                        <th>ID</th>
                        <th>Size</th>
                        <th>Type</th>
                        <th className="select-column">
                            <FileSelect fileBranch={filesTree} />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {filesTree?.children?.map((fileBranch) => {
                        return (
                            <FileRow
                                key={fileBranch.path}
                                fileBranch={fileBranch}
                                showAll={showAll}
                            />
                        );
                    })}
                </tbody>
            </Table>
        </div>
    );
};

export default FileTable;
