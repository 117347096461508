define([
    'backbone',
    'bootbox',
    './helpers',
    './search/modal',
    '../widgets/windowbox',
    'jqueryCookie',
], function (Backbone, bootbox, helpers, SearchModalView) {
    const React = require('react');
    const ReactDOM = require('react-dom');
    const { getAppInstance } = require('../components/utils');

    var ToolbarView = Backbone.View.extend({
        el: '.toolbar',
        events: {
            'click #toolbar-anno .btn': 'openAnnotations',
            'click #toolbar-anno-dropped .btn': 'openAnnotations',
            'click .btn-goto': 'navigateGoto',
            'click .toolbar-search button': 'search',
            'click #toolbar-tod .opens-pw': function (evt) {
                app.trigger('popupWin:open', evt);
            },
            'click #toolbar-rod .opens-pw': function (evt) {
                app.trigger('popupWin:open', evt);
            },
            'click #toolbar-pwclose button': function () {
                window.close();
            },
            'click #toolbar-replace a': 'openMainDocument',
            'click #toolbar-open a': 'openMainDocument',
            'click #toolbar-sidebyside .btn': 'showCompare',
            'click #toolbar-sidebyside-dropped .btn': 'showCompare',
            'change .location-type-select': 'gotoTypeChange',
            'keydown .location-select': 'gotoSelectEnter',
        },
        initialize: function () {
            this.waitingForPrint = false;
            this.contextualHelp = true;
            if (app.user.get('display_prefs')) {
                this.contextualHelp = app.user.get('display_prefs').contextual_help;
            }
            this.listenTo(app, 'print:loaded', this.updatePrint);
            this.listenTo(app, 'search:changeQuery', this.search);
            this.listenTo(app, 'document:ready', this.initCopyLocation);
            this.listenTo(app, 'document:content:show', this.initGotoLocation);
            this.listenTo(app, 'search:closed', this.disableGoToLocation);
            this.listenTo(app, 'search:results:section', this.disableSearchControls, false);
            this.listenTo(app, 'search:results:document', this.disableSearchControls, true);
            this.listenTo(app, 'document:compare:switch', function (doc) {
                this.copyLocationUrl = doc ? doc.getPageUrl() : document.location.href;
            });
            this.listenTo(
                app,
                'document:compare:switch',
                function (doc) {
                    if (doc) {
                        this.activeDocument = doc;
                        this.currentSection();
                        this.renderGotoTypes();
                        this.enableGoToLocation();
                    } else {
                        this.disableGoToLocation();
                    }
                },
                this,
            );

            this.initTOD();
            this.initQuickSearch();
            this.initFavourites();
            this.initPrint();
            this.initSearch();
            this.initCompare();
            this.initAnnotations();
            this.initDarkModeOptions();
            this.initVersionsDropdown();
            this.initVersionsChips();
            this.initSearchControls();
        },
        search: function (evt) {
            evt.preventDefault();
            var changeQuery = $(evt.currentTarget).hasClass('change-query');

            if (!this.searchView) {
                this.searchView = new SearchModalView({
                    model: app.getDocument(),
                    ...(app.documentView?.model && { norris: app.documentView.model }),
                });
                this.searchView.render();
            }
            this.searchView.reset(changeQuery);
            this.searchView.show();
        },
        initTOD: function () {
            if (this.contextualHelp) {
                this.$('#toolbar-tod').popover({
                    placement: 'bottom',
                    title: 'Table of Documents',
                    trigger: 'hover',
                    delay: 100,
                    content: 'Access all the documents on the system sorted by type of document',
                });
            }
        },
        initQuickSearch: function () {
            const DocumentFinder = require('../components/DocumentFinder/DocumentFinder').default;
            const element = document.getElementById('toolbar-qsearch');
            // This is needed, because some popup pages don't have a search toolbar
            if (element) {
                ReactDOM.render(<DocumentFinder />, element);
            }
        },
        initFavourites: function () {
            const ViewFavouritesModal =
                require('../components/Favourites/ViewFavouritesModal').default;
            const element = document.getElementById('toolbar-favourites');

            const that = this;

            const handleUpdate = () => {
                that.favouriteChanged();
            };

            if (element) {
                ReactDOM.render(<ViewFavouritesModal {...{ handleUpdate }} />, element);
            }
        },
        favouriteChanged: function () {
            app.trigger('favourite:changed');
        },
        initCompare: function () {
            if (
                (!document.referrer.includes('/compare') ||
                    !window.location.pathname.includes('/inter')) &&
                !window.location.pathname.startsWith('/timeline') &&
                (!document.referrer.includes('/timeline') ||
                    !window.location.pathname.includes('/inter'))
            ) {
                // Only allow side-by-side in inter window if not already in compare view or if we haven't opened from Timeline
                this.$('#toolbar-sidebyside .btn').removeClass('disabled');
                this.$('#toolbar-sidebyside-dropped .btn').removeClass('disabled');
            }

            if (this.contextualHelp) {
                const interWindow = this.$('#toolbar-sidebyside').parents().hasClass('pw-inter');
                const message = interWindow
                    ? 'Open this document in the main window in the side by side view'
                    : 'Display two documents, or two views of the same document, side by side for ease of comparison';
                this.$('#toolbar-sidebyside,#toolbar-sidebyside-dropped').popover({
                    placement: 'bottom',
                    title: 'Side-by-side',
                    trigger: 'hover',
                    delay: 100,
                    content: message,
                });
            }
        },
        initAnnotations: function () {
            this.$('#toolbar-anno .btn').removeClass('disabled');
            this.$('#toolbar-anno-dropped .btn').removeClass('disabled');

            if (this.contextualHelp) {
                this.$('#toolbar-anno,#toolbar-anno-dropped').popover({
                    placement: 'bottom',
                    title: 'Annotations',
                    trigger: 'hover',
                    delay: 100,
                    content:
                        'Create your own notes, either private or shared across your organisation',
                });
            }
        },
        initSearch: function () {
            if (this.contextualHelp) {
                this.$('#toolbar-search').popover({
                    placement: 'bottom',
                    title: 'Search',
                    trigger: 'hover',
                    delay: 100,
                    content:
                        'Search the full text of your current document, recently opened documents, or across selected families of documents',
                });
            }
        },
        updatePrint: function () {
            if (this.waitingForPrint) {
                $('.printModal .btn-success').prop('disabled', false);
                $('.printModal .modal-title').text('Ready To Print');
            }
        },
        initPrint: function () {
            this.listenTo(app, 'search:results:synced', function (data) {
                this.searchResultInfo = data;
            });

            if (!window.location.pathname.startsWith('/timeline')) {
                this.$('#toolbar-print .btn, #toolbar-print-dropped .btn')
                    .removeClass('disabled')
                    .on(
                        'click',
                        _.bind(function () {
                            if (
                                app.getDocument() &&
                                !$('body').hasClass('with-searchresults') &&
                                !$('body').hasClass('pw-note')
                            ) {
                                var url =
                                    app.urls.printWindow + '/' + app.getDocument().get('doc_id');
                                if (
                                    app.getDocument()._activeInstance &&
                                    app.getDocument()._activeState &&
                                    app.getDocument()._activeState != 'published'
                                ) {
                                    url += '/' + app.getDocument()._activeInstance;
                                }
                                if (this.sections) {
                                    // url may already have a hash
                                    if (url.includes('#')) {
                                        url.replace(/#(.*)/g, this.sections[0]);
                                    } else {
                                        url += `#${this.sections[0]}`;
                                    }
                                }
                                app.popups.print.ref = window.manager.open(url, {
                                    name: app.popups.print.name,
                                    features: 'width=800,height=500',
                                });
                                app.popups.print.ref.focus();
                            }
                            // print search results
                            else if ($('body').hasClass('with-searchresults')) {
                                if (
                                    this.searchResultInfo &&
                                    this.searchResultInfo.loaded < 500 &&
                                    this.searchResultInfo.loaded < this.searchResultInfo.total
                                ) {
                                    bootbox.dialog({
                                        title: 'Not enough results',
                                        message: 'Wait for results to load',
                                        buttons: {
                                            ok: {
                                                label: 'OK',
                                                className: 'btn-primary',
                                            },
                                        },
                                    });
                                } else {
                                    var message;
                                    this.waitingForPrint = true;
                                    if (this.searchResultInfo.total > 500) {
                                        message =
                                            'Printing is limited to the first 500 search results.';
                                    } else {
                                        message =
                                            'Printing ' + this.searchResultInfo.total + ' results';
                                    }

                                    bootbox
                                        .dialog({
                                            title: 'Preparing Results - Please Wait',
                                            message: message,
                                            className: 'printModal',
                                            buttons: {
                                                cancel: {
                                                    label: 'Cancel',
                                                    className: 'btn-cancel',
                                                    callback: function () {},
                                                },
                                                print: {
                                                    label: 'Print',
                                                    className: 'btn-success',
                                                    callback: function () {
                                                        window.print();
                                                    },
                                                },
                                            },
                                        })
                                        .find('.btn-success')
                                        .prop('disabled', this.waitingForPrint);

                                    // load in up to first 500 rows
                                    app.trigger('print:results', 500);
                                }

                                $.post('/log/print', {
                                    type: app.router.current,
                                    url: document.location.href,
                                });
                            }
                            // print everything else
                            else {
                                window.print();
                            }
                        }, this),
                    );
            }

            if (this.contextualHelp) {
                this.$('#toolbar-print,#toolbar-print-dropped').popover({
                    placement: 'bottom',
                    title: 'Print',
                    trigger: 'hover',
                    delay: 100,
                    content:
                        'Print documents, or parts of documents, including the Table of Contents',
                });
            }
        },

        openAnnotations: function () {
            const that = this;
            let url = app.urls.annotationsWindow;
            const curr_doc = app.getDocument();
            if (curr_doc) {
                // we need to also send the doc's families for filtering out weird happenings with Project Annotations
                const families = curr_doc
                    .getInstance()
                    .get('families')
                    .reduce((arr, fam) => (fam?.slug ? [...arr, fam.slug] : arr), []);
                url += `/${curr_doc.get('doc_id')}?families=${families.join(',')}`;
            }

            const annotationWindowRef = window.manager.open(url, {
                name: app.popups.intermediate.name,
                features: 'width=900,height=700',
            });
            app.popups.intermediate.ref = annotationWindowRef;

            $(annotationWindowRef).on('load', function () {
                annotationWindowRef.app.listenTo(
                    annotationWindowRef.app,
                    'annotation:changed',
                    that.annotationChanged,
                );
            });

            app.popups.intermediate.ref.focus();
        },
        annotationChanged: function () {
            app.trigger('annotation:changed');
        },
        showCompare: function () {
            var doc = app.getDocument();
            var url = app.urls.compare;
            url += url.endsWith('/') ? '' : '/';
            const fromInterWindow = window.location.pathname.includes('inter');
            if (doc) {
                url += fromInterWindow
                    ? window.opener.location.pathname.includes('search')
                        ? window.opener.location.hash
                              .replace('#', '')
                              .split('-')
                              .splice(0, 2)
                              .join('-') // we've come from search and in interWindow
                        : window.opener.location.pathname
                              .replace('/compare', '')
                              .replaceAll('/', ' ')
                              .trim()
                              .split(' ')[1] // we've come from doc and in interWindow
                    : doc.get('doc_id'); // we're in a doc from document view and wanting to compare
                if (
                    doc.get('activeInstance') &&
                    app.user.has_perm('content.view_all_document_states')
                ) {
                    url += '/' + doc.get('activeInstance');
                }

                const instance = doc.getInstance();
                const ttViewDate = app
                    .moment(instance.ttViewDate)
                    .format(app.settings.dateTransportFormat);
                if (
                    instance.ttViewOption !== 'tt-current' ||
                    ttViewDate !== app.moment().format(app.settings.dateTransportFormat)
                ) {
                    url += `?tt_option=${instance.ttViewOption}&tt_date=${ttViewDate}`;
                    if (instance.ttViewOption === 'tt-between') {
                        url += `&tt_date2=${app
                            .moment(instance.ttViewDate2)
                            .format(app.settings.dateTransportFormat)}`;
                    }
                }
            } else {
                const nonDocUrl = new URL(window.location.href);
                if (
                    nonDocUrl.pathname.startsWith('/news/') ||
                    nonDocUrl.pathname.startsWith('/survey/')
                ) {
                    // /news/ and /survey/ can't go into compare, so we're removing them from the url
                    const pathInfo = nonDocUrl.pathname.split('/').slice(2).join('/');
                    url += pathInfo;
                }
            }

            if (fromInterWindow) {
                // save the hash in localStorage as the window.opener has no clue what the interWindow's url is and will need it for scrolling the right pane
                localStorage.setItem('inter_window_hash', app.documentView.content.activeElement);
                window.opener.location.href = url;
                window.close();
            } else {
                if (app.documentView) {
                    url += `#${app.documentView.content.activeElement}`;
                }
                document.location.href = url;
            }
        },
        openMainDocument: function (evt) {
            evt.preventDefault();
            const action = $(evt.currentTarget).closest('li').attr('id');

            var model = app.getDocument().getInstance();

            if (action == 'toolbar-replace') {
                // If we've come from compare view, replace the doc that opened the intWindow
                if (document.referrer.includes('compare')) {
                    // check if we're coming from the left pane
                    const pane = $(window.opener.app._compareView.contentViews.left.$el).hasClass(
                        'pane-selected',
                    )
                        ? 'left'
                        : 'right';
                    window.opener.app._compareView.openDocument(
                        model.attributes.document,
                        model.id,
                        pane,
                        app.documentView.content.activeElement,
                        {
                            tt_option: model.ttViewOption,
                            tt_date: model.ttViewDate,
                            tt_date2: model.ttViewDate2,
                        },
                    );
                } else {
                    window.opener.location.href = model.getPageUrl();
                }
            } else if (action == 'toolbar-open') {
                window.opener.open(model.getPageUrl(), '_blank');
            }
            window.close();
        },
        initGotoLocation: function () {
            this.activeDocument = app.documentView.content.model;
            this.currentSection();
            this.renderGotoTypes();
            this.enableGoToLocation();
        },
        enableGoToLocation: function () {
            this.$('.location-select').removeClass('disabled');

            if (this.contextualHelp) {
                this.$('#toolbar-goto,#toolbar-goto-dropped').popover({
                    placement: 'bottom',
                    title: 'Go to Location',
                    trigger: 'hover',
                    delay: 100,
                    html: true,
                    template:
                        '<div class="popover dd-toolhelp">' +
                        '<div class="arrow"></div><h3 class="popover-title"></h3>' +
                        '<div class="popover-content"></div></div>',
                    content:
                        '<p>You can jump directly to a Section, Regulation, Paragraph, Part, Schedule or Appendix if you know the number. Omit brackets.</p>' +
                        '<div class="popover-examples">' +
                        '<h4 class="example-title">Examples:</h4>' +
                        '<p><em class="example">Section 1</em> goes to Section 1 of the current document</p>' +
                        '<p><em class="example">Part II</em> goes to Part II of the current document</p>' +
                        '<p><em class="example">Paragraph iv goes to Paragraph (iv) of the current document</p>' +
                        '</div>',
                });
            }
        },
        disableGoToLocation: function () {
            this.$('.location-select').addClass('disabled').popover('destroy').val('');

            this.$('.location-type-select').html('');
        },
        renderGotoTypes: function () {
            // Goto Locs are fetched from document, not via norris JSON
            //this.gotos = gotos;
            const gotos = this.activeDocument.get('goto_classes');

            if (!gotos.length) {
                this.disableGoToLocation();
                return;
            }

            // reset
            this.$('.location-select').val('');
            this.$('.location-type-select').html('');

            var select = this.$('.location-type-select');
            _.each(gotos, function (entry) {
                var selected = '';
                if (entry['is_default']) {
                    selected = ' selected="selected"';
                }
                select.append(
                    '<option value="' +
                        entry['prefix'] +
                        '"' +
                        selected +
                        '>' +
                        entry['label'] +
                        '</option>',
                );
            });

            // enable the form
            this.$('#toolbar-goto .location-type-select').prop('disabled', null);
            this.$('#toolbar-goto .location-select').prop('disabled', null);
            this.$('#toolbar-goto .btn-goto').prop('disabled', null).removeClass('disabled');

            this.$('#toolbar-goto-dropped .location-type-select').prop('disabled', null);
            this.$('#toolbar-goto-dropped .location-select').prop('disabled', null);
            this.$('#toolbar-goto-dropped .btn-goto')
                .prop('disabled', null)
                .removeClass('disabled');
        },
        gotoTypeChange: function () {
            this.$('.location-select').val('').focus();
        },
        gotoSelectEnter: function (evt) {
            if (evt.keyCode == 13) {
                this.navigateGoto(evt);
            }
        },
        navigateGoto: function (evt) {
            evt.preventDefault();

            // Get the element id from the gotos dictionary based
            // on goto type and number and trigger the navigation
            // if valid.
            var prefix = this.$('.location-type-select').val();
            var location = this.$('.location-select').val();

            var elementID = prefix + '-' + location.toLowerCase();

            // Special case for Law Reports
            if (this.activeDocument.norris.doctype == 'law-rep' && location.indexOf('.') == -1) {
                // For location X, look up <PREFIX>-1.X, <PREFIX>-2.X, etc.
                for (let p = 0; p < 100; p++) {
                    elementID = prefix + '-' + p + '.' + location.toLowerCase();
                    if (!_.isUndefined(this.activeDocument.norris.ids[elementID])) {
                        break;
                    }
                }
            }

            //var element = this.gotos[type][location.toLowerCase()];

            if (_.isUndefined(this.activeDocument.norris.ids[elementID])) {
                // Temporarily disable document scrolling while alert is displayed
                if (app.documentView) {
                    app.documentView.content.enableScroll = false;
                }
                bootbox.dialog({
                    title: 'Destination not found',
                    message: 'The requested destination does not exist in this document',
                    buttons: {
                        ok: {
                            label: 'OK',
                            className: 'btn-primary',
                            callback: function () {
                                // Re-enable document scrolling
                                if (app.documentView) {
                                    app.documentView.content.enableScroll = true;
                                }
                            },
                        },
                    },
                });
            } else {
                this.activeDocument.trigger('document:navigate', elementID);
            }
        },
        initCopyLocation: function () {
            const CopyLocation = require('../components/CopyLocation/ViewCopyLocation').default;
            const locationIds = ['toolbar-copyloc', 'toolbar-copyloc-dropped'];
            locationIds.forEach((id) => {
                const element = document.getElementById(id);
                // This is needed because some popup pages don't have a copy location button
                if (element) {
                    ReactDOM.render(<CopyLocation />, element);
                }
            });
        },
        initSearchControls: function () {
            // back and forward arrows in toolbar do something
            this.$('#toolbar-prev button, #toolbar-next button').on('click', function (evt) {
                app.trigger('search:direction', $(evt.currentTarget).data('dir'));
            });
        },
        currentSection: function () {
            this.listenTo(this.activeDocument, 'document:section:change', function (sections) {
                this.sections = sections;
            });
        },
        disableSearchControls: function (disable) {
            // back and forward arrows in toolbar allow to do something
            if (disable) {
                this.$('#toolbar-prev button, #toolbar-next button')
                    .addClass('disabled')
                    .prop('disabled', true);
            } else {
                this.$('#toolbar-prev button, #toolbar-next button')
                    .removeClass('disabled')
                    .prop('disabled', false);
            }
        },
        initDarkModeOptions: function () {
            const DarkModeNavButtons = require('../components/DarkModeNavButtons').default;
            const element = document.getElementById('toolbar-mode');

            if (element) {
                ReactDOM.render(<DarkModeNavButtons />, element);
            }
        },
        initVersionsDropdown: function () {
            if (app.user.attributes.is_staff) {
                const VersionsContainer =
                    require('../components/VersionControls/VersionContainer').default;
                const element = document.getElementById('version-container');
                const siteName = window.SITENAME;
                const solrVersions = window.SOLR_VERSIONS.replaceAll(/(&#x?\d+;)/g, '"'); // single or double quotes are sent back
                if (element) {
                    ReactDOM.render(
                        <VersionsContainer
                            {...{ siteName, solrVersions: JSON.parse(solrVersions) }}
                        />,
                        element,
                    );
                }
            }
        },
        initVersionsChips: function () {
            const VersionChip = require('../components/VersionControls/VersionChip').default;
            const siteElement = document.getElementById('instance-chip');
            const solrElement = document.getElementById('solr-chip');

            const siteName = getAppInstance(window.SITENAME);

            const newSolr = app.user.attributes.roles.some((role) => role.name === 'Solr Tester');

            if (siteElement && siteName) {
                ReactDOM.render(
                    <VersionChip
                        {...{
                            label: siteName,
                            background: 'dark-grey',
                        }}
                    />,
                    siteElement,
                );
            }
            if (app.user.attributes.is_staff) {
                if (solrElement) {
                    ReactDOM.render(
                        <VersionChip
                            {...{
                                label: newSolr ? 'SOLR PASSIVE' : 'SOLR ACTIVE',
                                background: newSolr ? 'grey' : '',
                            }}
                        />,
                        solrElement,
                    );
                }
            }
        },
    });

    return {
        ToolbarView: ToolbarView,
    };
});
