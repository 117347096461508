import React from 'react';
import PropTypes from 'prop-types';

import { Form } from 'react-bootstrap';
import FormModal from '../FormControls/FormModal';
import FormInput from '../FormControls/Input/FormInput';

const DetailEditModal = ({
    show,
    handleModal,
    currentValue,
    label,
    property,
    handleSave,
    multiInput,
    textarea,
    required,
}) => {
    const inputType = textarea ? 'textarea' : 'text';

    const submitForm = (form) => {
        if (multiInput) {
            const updatedValues = multiInput.reduce((obj, input) => {
                // Only add the inputs that have actually changed
                if (input.value != form.elements[input.property].value) {
                    obj[input.property] = form.elements[input.property].value.replaceAll(
                        '\n',
                        ', ',
                    );
                }
                return obj;
            }, {});
            handleSave(updatedValues);
        } else {
            handleSave({ [property]: form.elements[property].value.replaceAll('\n', ', ') });
        }
    };

    return (
        <FormModal
            {...{
                show,
                size: 'sm',
                hideModal: handleModal,
                title: `Edit ${label}`,
                submitForm,
            }}
        >
            <Form.Group>
                <Form.Label>{`Current ${label}`}</Form.Label>
                <Form.Control
                    aria-label={`Current ${label}`}
                    readOnly
                    name={`old_${property}`}
                    defaultValue={currentValue}
                    placeholder={currentValue}
                ></Form.Control>
            </Form.Group>
            {multiInput ? (
                multiInput.map((input) => (
                    <FormInput
                        key={input.property}
                        label={`New ${input.label}`}
                        defaultValue={input.value}
                        name={input.property}
                        type={inputType}
                    />
                ))
            ) : (
                <FormInput
                    label={`New ${label}`}
                    name={property}
                    type={inputType}
                    required={required}
                />
            )}
        </FormModal>
    );
};

export default DetailEditModal;

DetailEditModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleModal: PropTypes.func.isRequired,
    currentValue: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    property: PropTypes.string.isRequired,
    handleSave: PropTypes.func.isRequired,
    multiInput: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            property: PropTypes.string,
            value: PropTypes.string,
        }),
    ),
    textarea: PropTypes.bool,
    required: PropTypes.bool,
};
