define(['backbone'], function (Backbone) {
    var ColourKeyMixin = {
        events: {
            mousemove: 'updateColourKey',
            mouseleave: 'clearColourKey',
        },
        initializeColourKey: function () {
            this.enableColourKey = app.user.get('display_prefs').is_colour_key;
            this.colourKey = $(
                '<div class="pane-doc popover dd-colhelp" id="colour-key"></div>',
            ).appendTo('body');
            this.colourKey.css({ 'z-index': 1000, absolute: 'fixed', top: '0px', left: '0px' });
            this.colourKey.hide();
            this.listenTo(app, 'help:colourkey', this.helpColourKey);
        },
        helpColourKey: function (enable, target) {
            if (!target || target === this.options.target) {
                this.enableColourKey = enable;
                this.clearColourKey();
            }
        },
        clearColourKey: function () {
            if (!this.colourKey) {
                return;
            }
            this.ttElementId = '';
            this.colourKey.hide();
            this.colourKey.empty();
            this.$el.removeClass('colhelp-enabled');
            this.colHelpTarget ? this.colHelpTarget.removeClass('colhelp-enabled') : '';
        },
        updateColourKey: function (evt) {
            if (this.enableColourKey) {
                var $target = $(evt.target);
                var $tt = $target.closest('.tts');
                if (
                    !_.isEmpty($tt) &&
                    !$tt.hasClass('tts-hidden') &&
                    !$target.hasClass('picon-annotation') &&
                    !$target.hasClass('picon-note') &&
                    !$target.is('.dd-flags-anno, .dd-flags-fave') &&
                    !$target.parents('.dd-flag').length &&
                    !$target.parents('.textnote').length
                ) {
                    var ttMsg = '';
                    var keyMsg = '';
                    var html = '';

                    // Update the colour help message when the timetravel
                    // container or target element changes
                    if (
                        $tt.attr('id') != this.ttElementId ||
                        _.isUndefined(this.colHelpTarget) ||
                        this.colHelpTarget[0] != $target[0]
                    ) {
                        // Store the new timetravel containter id so we
                        // check see if it changes and update the help message
                        this.ttElementId = $tt.attr('id');

                        var enfor_dt = app
                            .moment($tt.attr('enfor-dt'), app.settings.dateTransportFormat)
                            .format(app.settings.dateFormat);
                        var renfo_dt = app
                            .moment($tt.attr('renfo-dt'), app.settings.dateTransportFormat)
                            .format(app.settings.dateFormat);
                        var viewDate = app
                            .moment(this.model.ttViewDate, app.settings.dateTransportFormat)
                            .format(app.settings.dateFormat);
                        var viewDate2 = app
                            .moment(this.model.ttViewDate2, app.settings.dateTransportFormat)
                            .format(app.settings.dateFormat);
                        var ttViewOption = this.model.ttViewOption;

                        let mainTextColour = 'Black';
                        let edNoteColour = 'Maroon';

                        if (document.documentElement.getAttribute('data-theme') === 'dark') {
                            mainTextColour = 'White';
                            edNoteColour = 'Pink';
                        }
                        if ($tt.hasClass('tts-regular')) {
                            // Check if we are over an ednote, rather then a plain tts-regular
                            if ($tt.hasClass('ednote')) {
                                ttMsg = `<dt><span class="ednote">${edNoteColour} text</span></dt><dd>editorial notes</dd>`;
                            } else {
                                if (ttViewOption == 'tt-between') {
                                    ttMsg = `<dt><span class="tts-regular">${mainTextColour} text</span></dt><dd>text in force on both View Dates</dd>`;
                                } else {
                                    ttMsg = `<dt><span class="tts-regular">${mainTextColour} text</span></dt><dd>text in force</dd>`;
                                }
                            }
                        } else if ($tt.hasClass('tts-orig-into-force')) {
                            if (ttViewOption == 'tt-onthisday') {
                                ttMsg =
                                    '<dt><span class="tts-orig-into-force">Highlighted text</span></dt><dd>original text coming into force on the View Date (' +
                                    enfor_dt +
                                    ')</dd>';
                            } else if (ttViewOption == 'tt-between') {
                                ttMsg =
                                    '<dt><span class="tts-orig-into-force">Highlighted text</span></dt><dd>original text coming into force after the first View Date and on or before the second View Date (' +
                                    enfor_dt +
                                    ')</dd>';
                            } else {
                                ttMsg =
                                    '<dt><span class="tts-orig-into-force">highlighted text</span></dt><dd>is original text that comes into force after the first date (' +
                                    viewDate +
                                    ') and on or before the second date (' +
                                    viewDate2 +
                                    ')</dd>';
                            }
                        } else if ($tt.hasClass('tts-orig-into-force-strike')) {
                            if (ttViewOption == 'tt-onthisday') {
                                ttMsg =
                                    '<dt><span class="tts-orig-into-force-strike">Struck out highlighted text (whether or not underlined)</span></dt><dd>original text that both comes into force and is repealed on the View Date (' +
                                    enfor_dt +
                                    ')</dd>';
                            } else if (ttViewOption == 'tt-between') {
                                ttMsg =
                                    '<dt><span class="tts-orig-into-force-strike">Struck out highlighted text (whether or not underlined)</span></dt><dd>original text that comes into force (' +
                                    enfor_dt +
                                    ') and is repealed (' +
                                    renfo_dt +
                                    ') after the first View Date and on or before the second View Date </dd>';
                            } else {
                                ttMsg =
                                    '<dt><span class="tts-orig-into-force-strike">highlighted text</span></dt><dd>is original text that comes into force after the first date (' +
                                    viewDate +
                                    ') and is repealed on or before the second date (' +
                                    viewDate2 +
                                    ')</dd>';
                            }
                        } else if ($tt.hasClass('tts-not-in-force')) {
                            if (ttViewOption == 'tt-between') {
                                ttMsg = `<dt><span class="tts-not-in-force">${mainTextColour} italic text</span></dt><dd>text not yet in force on either View Date</dd>`;
                            } else {
                                ttMsg = `<dt><span class="tts-not-in-force">${mainTextColour} italic text</span></dt><dd>text not yet in force</dd>`;
                            }
                        } else if ($tt.hasClass('tts-not-in-force-future-repealed')) {
                            ttMsg =
                                `<dt><span class="tts-not-in-force-future-repealed">${mainTextColour} italic underlined text</span></dt><dd>text not yet in force and due to be repealed (` +
                                enfor_dt +
                                ')</dd>';
                        } else if ($tt.hasClass('tts-repealed')) {
                            if (ttViewOption == 'tt-onthisday') {
                                ttMsg =
                                    '<dt><span class="tts-repealed">Struck out text (whether or not underlined)</span></dt><dd>text repealed on the View Date (' +
                                    renfo_dt +
                                    ') </dd>';
                            } else if (ttViewOption == 'tt-between') {
                                ttMsg =
                                    '<dt><span class="tts-repealed">Struck out text (whether or not underlined)</span></dt><dd>text repealed after the first View Date and on or before the second View Date (' +
                                    renfo_dt +
                                    ') </dd>';
                            } else {
                                ttMsg =
                                    '<dt><span class="tts-repealed">Struck out text (whether or not underlined)</span></dt><dd>text has been repealed (' +
                                    renfo_dt +
                                    ') </dd>';
                            }
                        } else if ($tt.hasClass('tts-repealed-before-in-force')) {
                            if (ttViewOption == 'tt-onthisday') {
                                ttMsg =
                                    `<dt><span class="tts-repealed-before-in-force">${mainTextColour} italic strikeout</span></dt><dd>text repealed on the View Date (` +
                                    renfo_dt +
                                    ') without ever coming into force</dd>';
                            } else if (ttViewOption == 'tt-between') {
                                ttMsg =
                                    `<dt><span class="tts-repealed-before-in-force">${mainTextColour} italic strikeout</span></dt><dd>text repealed after the first View Date and on or before the second View Date (` +
                                    renfo_dt +
                                    ') without ever coming into force</dd>';
                            } else {
                                ttMsg =
                                    `<dt><span class="tts-repealed-before-in-force">${mainTextColour} italic strikeout</span></dt><dd>text has been repealed (` +
                                    renfo_dt +
                                    ') without ever coming into force</dd>';
                            }
                        } else if ($tt.hasClass('tts-repealed-before-future-inserted')) {
                            ttMsg =
                                '<dt><span class="tts-repealed-before-future-inserted">Green strikethrough text</span></dt><dd>inserted text that has been repealed (' +
                                renfo_dt +
                                ') without ever coming into force</dd>';
                        } else if ($tt.hasClass('tts-inserted')) {
                            if (ttViewOption == 'tt-onthisday') {
                                ttMsg =
                                    '<dt><span class="tts-inserted">Gold text</span></dt><dd>text inserted on the View Date (' +
                                    enfor_dt +
                                    ') </dd>';
                            } else if (ttViewOption == 'tt-between') {
                                ttMsg =
                                    '<dt><span class="tts-inserted">Gold text</span></dt><dd>text inserted after the first View Date and on or before the second View Date (' +
                                    enfor_dt +
                                    ') </dd>';
                            } else {
                                ttMsg =
                                    '<dt><span class="tts-inserted">Gold text</span></dt><dd>text has been inserted (' +
                                    enfor_dt +
                                    ') </dd>';
                            }
                        } else if ($tt.hasClass('tts-inserted-repealed')) {
                            if (ttViewOption == 'tt-onthisday') {
                                ttMsg =
                                    '<dt><span class="tts-inserted-repealed">Gold struckthrough text (whether or not underlined)</span></dt><dd>text both inserted and repealed on the View Date (' +
                                    enfor_dt +
                                    ')</dd>';
                            } else if (ttViewOption == 'tt-between') {
                                ttMsg =
                                    '<dt><span class="tts-inserted-repealed">Gold struckthrough text (whether or not underlined)</span></dt><dd>text inserted (' +
                                    enfor_dt +
                                    ') and repealed (' +
                                    renfo_dt +
                                    ') after the first View Date and on or before the second View Date</dd>';
                            } else {
                                ttMsg =
                                    '<dt><span class="tts-inserted-repealed">Gold struckthrough text (whether or not underlined)</span></dt><dd>text has been inserted (' +
                                    enfor_dt +
                                    ') and repealed (' +
                                    renfo_dt +
                                    ')</dd>';
                            }
                        } else if ($tt.hasClass('tts-inserted-future-repealed')) {
                            ttMsg =
                                '<dt><span class="tts-inserted-future-repealed">Gold underlined text</span></dt><dd>text has been inserted (' +
                                enfor_dt +
                                ') and is due to be repealed (' +
                                renfo_dt +
                                ')</dd>';
                        } else if ($tt.hasClass('tts-future-repealed')) {
                            let dateText =
                                renfo_dt === '01/01/3000' ? 'from a day to be appointed ' : '';
                            ttMsg = `<dt><span class="tts-future-repealed">Underlined text</span></dt>
                            <dd>text due to be repealed ${dateText}(${renfo_dt})</dd>`;
                        } else if ($tt.hasClass('tts-future-inserted')) {
                            let dateText =
                                enfor_dt === '01/01/3000' ? 'from a day to be appointed ' : '';
                            ttMsg = `<dt><span class="tts-future-inserted">Green text</span></dt>
                            <dd>text due to be inserted ${dateText}(${enfor_dt})</dd>`;
                        } else if ($tt.hasClass('tts-future-inserted-future-repealed')) {
                            ttMsg =
                                '<dt><span class="tts-future-inserted-future-repealed">Green underlined text</span></dt><dd>text due to be inserted (' +
                                enfor_dt +
                                ') and due to be repealed (' +
                                renfo_dt +
                                ')</dd>';
                        }

                        if ($target.hasClass('term')) {
                            keyMsg =
                                '<dt><a class="term">Pink text</a></dt><dd>links to a definition</dd>';
                        } else if ($target.hasClass('xref') || $target.hasClass('inref')) {
                            keyMsg =
                                '<dt><a class="xref">Dark blue text</a></dt><dd>links to another place or document<br>(in this case, within Perspective)</dd>';
                        } else if ($target.hasClass('anchor')) {
                            keyMsg =
                                '<dt><a class="xref">Dark blue text</a></dt><dd>links to another place or document<br>(in this case, outside Perspective)</dd>';
                        } else if ($target.hasClass('defterm')) {
                            keyMsg =
                                '<dt><a class="defterm">Purple text</a></dt><dd>shows the phrase being defined</dd>';
                        }

                        html =
                            '<div class="popover-content"><dl class="dl-horizontal">' +
                            ttMsg +
                            keyMsg +
                            '</dl></div>';

                        // Remove the colhelp-enabled class, update and add the
                        // class back to the new target
                        if (_.isUndefined(this.colHelpTarget)) {
                            this.colHelpTarget = $target;
                            this.colHelpTarget.addClass('colhelp-enabled');
                        } else if (this.colHelpTarget[0] != $target[0]) {
                            this.colHelpTarget.removeClass('colhelp-enabled');
                            this.colHelpTarget = $target;
                            this.colHelpTarget.addClass('colhelp-enabled');
                        }

                        // Update the text
                        this.colourKey.empty();
                        this.colourKey.append(html);
                        this.colourKey.show();

                        if (!this.$el.hasClass('colhelp-enabled')) {
                            this.$el.addClass('colhelp-enabled');
                        }
                    }

                    // Update the position, avoiding going out of the document view
                    var x = 0;
                    var y = 0;

                    if (
                        evt.pageX + this.colourKey.width() + 10 >
                        this.el.getBoundingClientRect().right
                    ) {
                        x = evt.pageX - this.colourKey.width() - 10;
                    } else {
                        x = 5 + evt.pageX;
                    }

                    if (
                        evt.pageY + this.colourKey.height() + 0 >
                        this.el.getBoundingClientRect().bottom
                    ) {
                        y = evt.pageY - this.colourKey.height() - 20;
                    } else {
                        y = 5 + evt.pageY;
                    }

                    this.colourKey.css({ left: x + 'px', top: y + 'px' });
                } else {
                    this.clearColourKey();
                }
            }
        },
    };

    return ColourKeyMixin;
});
