import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import {
    getDateTime,
    doFetchSelectableTable,
    formatAlertFrequency,
    formatUserName,
    multilineSplit,
} from '../utils';
import PiconIcon from '../FormControls/Icons/PiconIcon';
import TableControl from '../FormControls/Table/TableControl';
import Flags from './Flags/Flags';
import { TableContext } from '../FormControls/Table/TableContext';
import { Chip } from '../FormControls/Chip';

//#region helpers

const getDateWithTooltip = (date, tooltip) => {
    return <div title={tooltip}>{getDateTime(date)} </div>;
};

//#endregion

//#endregion

const UserList = ({ usersModel, currentOrganisation, showFlags, additionalButtons }) => {
    const showOnlyCurrentOrg = typeof currentOrganisation !== 'undefined';
    const showOrganisation =
        app.user.has_perm('main.view_all_organisations') && !showOnlyCurrentOrg;

    // Flags should only be shown to those with permission
    const showUserFlags = app.user.has_perm('main.flag_user');

    // Organisation user pages should be readonly
    const isReadonly = showOnlyCurrentOrg;

    const { tableState } = useContext(TableContext);

    const headers = [
        { title: 'User', dataField: 'full_name' },
        ...(showOrganisation ? [{ title: 'Organisation', dataField: 'organisation_order' }] : []),
        { title: 'Groups', dataField: 'group_names' },
        { title: 'Alerts', dataField: 'alert_order' },
        { title: <span title="Date Created or Activated">Date</span>, dataField: 'date_added' },
        { title: 'Updated At', dataField: 'updated_at' },
        { title: 'Status', dataField: 'status_order' },
        ...(showUserFlags ? [{ title: 'Flags', dataField: 'flag_order' }] : []),
    ];

    const doFetch = async () => {
        return await doFetchSelectableTable({
            tableState,
            fetch: async (params) => await usersModel.fetch(params),
            refreshSelected: async (params) => await usersModel.refreshSelected(params),
        });
    };

    const getTableColumns = (item) => {
        const isClientManager = item.roles.some((r) => r.name == 'Client Manager') ? (
            <span className="is-admin" title="Is Client Manager">
                <PiconIcon className="dark-grey" iconName={'padlock'} />{' '}
            </span>
        ) : (
            ''
        );

        const userUrl = currentOrganisation
            ? `/organisation/user/${currentOrganisation.id}/${item.id}`
            : `/administration/user/${item.id}`;

        const user = (
            <>
                <div>
                    <b>
                        {app.user.has_perm('main.view_user_details') ? (
                            <a href={userUrl}>{formatUserName(item.first_name, item.last_name)}</a>
                        ) : (
                            <>
                                {item.first_name} {item.last_name}{' '}
                            </>
                        )}
                    </b>
                    {isClientManager}
                </div>
                <a href={`mailto:${item.email}`}>{item.email}</a>
            </>
        );

        const alerts = (
            <div className="alert-list">
                <span>
                    {item.communication_prefs.news_alerts ? 'News, ' : '--'}
                    {item.communication_prefs.news_alerts &&
                        formatAlertFrequency(item.communication_prefs.news_alert_frequency?.label)}
                </span>
                <span>
                    {item.communication_prefs.document_alerts ? 'Documents, ' : '--'}
                    {item.communication_prefs.document_alerts &&
                        formatAlertFrequency(
                            item.communication_prefs.document_alert_frequency?.label,
                        )}
                </span>
            </div>
        );

        const date = item.date_joined
            ? getDateWithTooltip(item.date_joined, 'Date activated')
            : getDateWithTooltip(item.date_added, 'Date invite email sent ');

        const state = (
            <div
                title={
                    item.state === 'scheduled'
                        ? `Scheduled activation for ${app
                              .moment(item.user_details?.activation_req_on)
                              .format(app.settings.dateFormat)}`
                        : ''
                }
            >
                <Chip state={item.state}>{item.state}</Chip>
            </div>
        );
        const groupsSubscriptions = multilineSplit(item.user_groups, 'name');

        const flags = (
            <Flags
                {...{
                    flags: item.flags.filter(
                        (flag) =>
                            !flag.snoozed_until || // If flag is not snoozed
                            app.moment(flag.snoozed_until).isBefore(app.moment().format()), // If flag is snoozed but snooze time is over
                    ),
                    itemId: item.id,
                    showFlags,
                    isReadonly,
                }}
            />
        );
        const organisationLink = app.user.has_perm('main.view_organisation') ? (
            <a href={`/organisation/${item.organisation?.id}`}>{item.organisation?.name}</a>
        ) : (
            item.organisation?.name
        );

        const updatedAt = getDateTime(item.updated_at);

        return [
            user,
            ...(showOrganisation ? [organisationLink] : []),
            groupsSubscriptions,
            alerts,
            date,
            updatedAt,
            state,
            ...(showUserFlags ? [flags] : []),
        ];
    };

    return (
        <TableControl
            {...{
                headers,
                getTableColumns,
                doFetch,
                className: currentOrganisation ? 'large' : null,
                additionalButtons,
            }}
        />
    );
};

export default UserList;

UserList.propTypes = {
    usersModel: PropTypes.shape({
        fetch: PropTypes.func.isRequired,
        refreshSelected: PropTypes.func.isRequired,
    }),
    currentOrganisation: PropTypes.object,
    showFlags: PropTypes.func.isRequired,
    additionalButtons: PropTypes.element,
};
