import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { FileContext } from './FileContext';
import Button from 'react-bootstrap/Button';
import confirm from '../FormControls/ConfirmModal/ConfirmModalService';
import { formatList } from '../utils';
import PiconIcon from '../FormControls/Icons/PiconIcon';

//#region file helpers

const getFilesSelectedText = (filesState, selectedCount) => {
    if (filesState.files == 0) {
        return '0 files selected';
    }

    return `${selectedCount} File${selectedCount === 1 ? '' : 's'} (out of ${
        filesState.files.length
    }) selected`;
};

//#endregion

const FileUpload = ({ setLoading }) => {
    const { filesState } = useContext(FileContext);

    const files = filesState.files;
    const selectedCount = files.filter((f) => f.isSelected === true).length;
    const hasDocumentsSelected = files.some((f) => f.isSelected === true && f.id);

    const upload = async (publish) => {
        setLoading(true);
        // TODO some minor validation here

        const selectedFiles = files.filter((f) => f.isSelected);

        const formData = new FormData();
        const documentIds = [];

        for (const i in selectedFiles) {
            const item = selectedFiles[i];
            if (!item.file) {
                return;
            } // filter out empty files

            formData.append(`${item.fullPath}`, item.file, item.file.name);

            if (item.docId && !documentIds.some((i) => i == item.docId)) {
                documentIds.push(item.docId);
            }
        }

        const autopublishDocs = selectedFiles.filter((file) => file.type === 'survey');

        if (publish) {
            const proceedWithUpload = await confirm({
                message: `All files in this upload batch will be published automatically. Do you want to continue?`,
            });

            if (proceedWithUpload === false) {
                setLoading(false);
                return;
            }
        } else {
            if (autopublishDocs.length > 0) {
                const foundDocTypes = autopublishDocs.reduce((arr, file) => {
                    if (!arr.includes(file.type)) {
                        arr = [...arr, file.type];
                    }
                    return arr;
                }, []);

                const proceedWithUpload = await confirm({
                    message: `This upload batch contains some ${formatList(
                        foundDocTypes,
                        true,
                    )} files which will be published automatically. Do you want to continue?`,
                });

                if (proceedWithUpload === false) {
                    setLoading(false);
                    return;
                }
            }
        }

        // Chunking Large Uploads

        const formDataArray = [...formData.entries()];
        const totalSize = formDataArray.reduce((acc, [, value]) => {
            if (typeof value === 'object') return acc + value.size;
            return acc;
        }, 0);

        const chunkSize = 50000000; // Max we can send is 100MB, so we'll chunk it to 50MB as smaller chunks are more reliable
        const chunks = [];

        let loaded = 0;

        const getChunks = (dataArray, index = 0) => {
            let start = 0;
            let chunkedFormData = new FormData();
            let formIndex = index;
            do {
                if (typeof dataArray[formIndex][1] === 'object') {
                    start += dataArray[formIndex][1].size;
                    chunkedFormData.append(dataArray[formIndex][0], dataArray[formIndex][1]);
                    formIndex++;
                } else {
                    formIndex++;
                }
            } while (start < chunkSize && formIndex <= dataArray.length - 1);

            if (dataArray.length > formIndex) {
                // Make another formData
                getChunks(dataArray, formIndex);
            }

            // Django will need to know to tell s3 more is coming or to complete
            loaded += start;
            if (loaded === totalSize) chunkedFormData.append('upload_complete', true);
            chunkedFormData.append('multipart_upload', true);

            chunks.push(chunkedFormData);
            return true;
        };

        if (totalSize > chunkSize) {
            // We just need to wait until all the chunkedFromDatas get pushed to chunks array
            await getChunks(formDataArray);
        } else {
            // The size of formData is small enough, so it'll go in one chunk
            formData.append('upload_complete', true);
            chunks.push(formData);
        }

        // Actually start sending the chunks
        let upload_id = '';
        for (let chunk of chunks) {
            try {
                chunk.append('total_uploads', documentIds.length);
                chunk.append('publish', publish);
                if (upload_id) chunk.append('upload_id', upload_id);

                const result = await $.ajax({
                    type: 'POST',
                    url: app.urls.upload,
                    data: chunk,
                    contentType: false,
                    processData: false,
                });

                if (result.redirect) {
                    // Uploading all chunks is done, so go to progress page
                    document.location.href = result.redirect;
                } else if (result.upload_continue) {
                    // Make sure the rest of the chunks going to Django have same upload_id
                    if (!upload_id) upload_id = result.upload_id;
                    continue;
                } else {
                    toaster.error('Could not upload documents');
                    console.error('document upload error:', result);
                    setLoading(false);
                }
            } catch (error) {
                const errs = Object.values(error?.responseJSON);
                errs.length > 0
                    ? toaster.error(errs.join('\n'))
                    : toaster.error('Could not upload documents');
                console.error(error);
                setLoading(false);
            }
        }
    };

    return (
        <div className="file-info">
            <span>{getFilesSelectedText(filesState, selectedCount)}</span>
            <Button
                type="submit"
                disabled={!hasDocumentsSelected}
                onClick={() => upload(false)}
                className="btn-sm"
            >
                Upload and Create
            </Button>
            {app.user.has_perm('content.publish_document') && (
                <Button
                    type="submit"
                    disabled={!hasDocumentsSelected}
                    onClick={() => upload(true)}
                    className="btn-sm"
                >
                    <PiconIcon className="red left" iconName="warning-sign" />
                    Upload and Publish
                </Button>
            )}
        </div>
    );
};

export default FileUpload;

FileUpload.propTypes = {
    setLoading: PropTypes.func.isRequired,
};
