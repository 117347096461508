import React from 'react';
import PropTypes from 'prop-types';

const JumpToNav = ({ options }) => {
    const handleScroll = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <div className="jump-to-nav">
            Jump to:
            {options.map((option) => (
                <a key={option.id} href={`#${option.id}`} onClick={() => handleScroll(option.id)}>
                    {option.label}
                </a>
            ))}
        </div>
    );
};

export default JumpToNav;

JumpToNav.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
        }),
    ),
};
