import React, { useState, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';

const GroupMerger = ({ metadata, groupList }) => {
    const { organisations, user_groups } = metadata;
    const [groupOptions, setGroupOptions] = useState([]);

    const organisationRef = useRef(null);
    const handleOrganisationSelect = () => {
        setGroupOptions(user_groups[organisationRef.current.value]);
    };

    return (
        <>
            <Form.Group>
                <Form.Row>
                    <Form.Label>Organisation to merge into</Form.Label>
                    <Form.Control
                        as="select"
                        ref={organisationRef}
                        name="organisation"
                        onChange={handleOrganisationSelect}
                        required
                    >
                        <option value="">- Select organisation -</option>
                        {organisations.map((organisation, index) => {
                            return <option key={index}>{organisation.name}</option>;
                        })}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                        Please select an organisation
                    </Form.Control.Feedback>
                </Form.Row>
            </Form.Group>
            <p>
                The user group(s) below, along with all their users, will be merged into the chosen
                user groups of the organisation above. Group memebers can be transferred to existing
                groups of the same or different organisation.
            </p>
            <Form.Group>
                <Form.Row>
                    <Col>
                        <Form.Label>Exisiting user group(s)</Form.Label>
                        {groupList.map((group, index) => {
                            return (
                                <Form.Control
                                    className="option-list"
                                    key={index}
                                    as="input"
                                    name={`${group}-readonly`}
                                    defaultValue={group}
                                    readOnly={true}
                                ></Form.Control>
                            );
                        })}
                    </Col>
                    <Col>
                        <Form.Label>User group in new organisation</Form.Label>
                        {groupList.map((group, index) => {
                            return (
                                <div key={`${index}-form-control`}>
                                    <Form.Control
                                        className="option-list"
                                        key={`${index}-option-list`}
                                        as="select"
                                        name={group}
                                        required
                                    >
                                        <option value="">- Select group -</option>
                                        {groupOptions.map((g, index) => {
                                            return <option key={`${index}-${g}`}>{g.name}</option>;
                                        })}
                                    </Form.Control>
                                    <Form.Control.Feedback key={`${index}-feedback`} type="invalid">
                                        Please select a user group
                                    </Form.Control.Feedback>
                                </div>
                            );
                        })}
                    </Col>
                </Form.Row>
            </Form.Group>
        </>
    );
};

export default GroupMerger;

GroupMerger.propTypes = {
    metadata: PropTypes.shape({
        organisations: PropTypes.array,
        groups: PropTypes.array,
        subscription_groups: PropTypes.array,
        postcodes: PropTypes.array,
        user_groups: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
    }).isRequired,
    groupList: PropTypes.arrayOf(PropTypes.string).isRequired,
};
