import React from 'react';
import PropTypes from 'prop-types';

const CaretIcon = ({ down }) => {
    const className = down ? 'dropdown' : 'dropup';

    return (
        <span className={className}>
            <span className="caret" />
        </span>
    );
};

CaretIcon.propTypes = {
    down: PropTypes.bool,
};

export const DownCaretIcon = () => {
    return <CaretIcon down={true} />;
};

export const UpCaretIcon = () => {
    return <CaretIcon down={false} />;
};

export const DoubleCaret = () => {
    return (
        <div className="sort-icons">
            <UpCaretIcon /> <DownCaretIcon />
        </div>
    );
};
