define([
    'backbone',
    '../helpers',
    './timetraveller',
    '../../templates/document/toc.html',
    '../../templates/prefs/toc_prefs.html',
    '../../widgets/dragger',
    'dotdotdot',
], function (Backbone, helpers, TimeTraveller, tocTpl, tocPrefsTpl) {
    /*
        TOCView
        Table of contents to go with content view below
    */
    var TOCView = helpers.TemplateView.extend({
        el: '.pane-left',
        template: tocTpl,
        cssPrefsTemplate: tocPrefsTpl,
        events: {
            'click .toc-icon': 'itemToggle',
            'click .toc-icon-new': 'itemToggle',
            'click a': 'navigate',
            'click .collapse-toc': 'collapseToC',
        },
        initialize: function () {
            this.displayPrefs = app.user.attributes.display_prefs;
            var titleHeight = this.calcTitleHeight(
                this.displayPrefs.line_spacing,
                this.displayPrefs.text_size,
            );

            this.truncateFunc = function () {
                if ($(this).text().length > 91) {
                    $(this).dotdotdot({
                        height: titleHeight,
                        lastCharacter: {
                            remove: [' ', ',', ';', '.', '!', '?', "'", '"'],
                        },
                    });
                }
            };

            this.listenTo(this, 'render', this.initializeDisplayPrefs);

            if (this.model.get('timetravel')) {
                this.timeTraveller = new TimeTraveller(this);
            }

            this.listenTo(this.model, 'syncTOC', function (data) {
                // Need to check if the document has loaded first because
                // IE8 gets in a tiz if we have frame thrashing going on from
                // all quarters.
                // See #708, but the callback on timeTraveller.filter was not
                // getting called unless the ToC timetravel and the Content
                // time travel happened synchronously
                if (this.model.documentReady) {
                    this.renderTree(data);
                    this.initDragger();
                } else {
                    this.listenTo(this.model, 'document:ready', function () {
                        this.renderTree(data);
                        this.initDragger();
                    });
                }
            });

            $(window).on('resize', _.bind(this.adjustContent, this));
            this.listenTo(this.model, 'document:section:change', this.syncContentScroll);

            this.bodyClassExpanded = 'with-pane-left';
            this.bodyClassContracted = 'with-pane-left-min';

            this.tocId = '#toc';
            this.contentId = '#main';
            this.annoId = '#anno';
        },
        initializeDisplayPrefs: function () {
            // Build stylesheet
            var html = this.cssPrefsTemplate(this.displayPrefs);

            // Add the stylesheet to the head
            $('head').append(
                '<style id="toc-display-prefs-styles" type="text/css">' + html + '</style>',
            );
        },
        adjustContent: function () {
            if ($('body').hasClass(this.bodyClassExpanded)) {
                var offset =
                    $(this.tocId).offset()['left'] +
                    $(this.tocId + ', ' + this.tocId + ' .panetitle').outerWidth();
                $(this.contentId + ', ' + this.contentId + ' .panetitle').css({ left: offset });
            }
        },
        renderTree: function (data) {
            if (this.model.get('timetravel')) {
                var $data = $('<div>').append(data);
                this.timeTraveller.filter(
                    $data,
                    _.bind(function () {
                        // Render the initial time travelled TOC into the inner div
                        this.$('.inner').html($data);

                        this.adjustEmptyTreeItems();

                        var elementID = window.location.hash.replace(/^#/, '');
                        if (elementID) {
                            this.expandToRef(elementID, true);
                        }

                        this.truncateTocRoot();

                        // Register the time travel change filter once
                        // the TOC has been initially rendered.
                        this.listenTo(this.model, 'timetravel:change', this.timeTravel);
                    }, this),
                );
            } else {
                this.$('.inner').html(data);
                var elementID = window.location.hash.replace(/^#/, '');
                if (elementID) {
                    this.expandToRef(elementID, true);
                }
                this.truncateTocRoot();
            }
        },
        timeTravel: function () {
            this.timeTraveller.filter(this.$el, _.bind(this.adjustEmptyTreeItems, this));
        },
        adjustEmptyTreeItems: function () {
            /*
             If a tree item is time travel hidden, but it's contents aren't,
             expand the children and hide expand/collapse icon
            */
            // reset
            this.$('.toc-icon').show();
            this.$('.toc-icon-new').show();

            this.$('li.expandable > .toc-item .toc-title .toc-root span.tts-hidden').each(
                _.bind(function (idx, el) {
                    // make sure there's nothing else in the container element
                    if ($(el).text() != $(el).closest('.toc-title').text()) {
                        return;
                    }

                    $(el)
                        .closest('.toc-title')
                        .find('.toc-icon')
                        .hide() // hide the expand/collapse icon
                        .closest('li.expandable')
                        .has('ul:not(.tts-hidden)') // if the child list isn't hidden
                        .addClass('expanded'); // expand it

                    $(el)
                        .closest('.toc-title')
                        .find('.toc-icon-new')
                        .hide() // hide the expand/collapse icon
                        .closest('li.expandable')
                        .has('ul:not(.tts-hidden)') // if the child list isn't hidden
                        .addClass('expanded'); // expand it
                }, this),
            );
        },
        draggerClickCallback: function (hasDragged) {
            // reset style on altered elements
            function clearStyle() {
                $(this.tocId + ', ' + this.tocId + ' .panetitle, ' + this.contentId).removeAttr(
                    'style',
                );
                $(`${this.contentId} .panetitle`).removeAttr('style');
                _.defer(
                    _.bind(function () {
                        // Needs to take into account the annotations panel
                        const startingWidth = app?._compareView
                            ? $(window).width() * 0.49
                            : $(window).width();
                        const mainWidth =
                            startingWidth - $(`${this.tocId} .panetitle-left`).width();
                        const annoTitleWidth = $(`${this.annoId} #anno-panetitle`).outerWidth();
                        $(`${this.contentId} .panetitle`).css({
                            width: `${mainWidth - annoTitleWidth}px`,
                        });
                    }, this),
                );
            }

            if (!hasDragged) {
                _.bind(clearStyle, this)();
                $('body').toggleClass(this.bodyClassExpanded);
                $('body').toggleClass(this.bodyClassContracted);
            } else if ($('body').hasClass(this.bodyClassContracted)) {
                _.bind(clearStyle, this)();
                $('body').addClass(this.bodyClassExpanded);
                $('body').removeClass(this.bodyClassContracted);
            } else if ($(this.tocId + ' .panetitle-left').width() < 10) {
                _.bind(clearStyle, this)();
                $('body').removeClass(this.bodyClassExpanded);
                $('body').addClass(this.bodyClassContracted);
            }
        },
        initDragger: function () {
            var draggerWidth = this.$('.pane-grabber').outerWidth();

            // for keyboard accessibility
            this.$('.pane-grabber').on('keydown', (event) => {
                if (event.keyCode === 13 || event.keyCode === 32) {
                    this.draggerClickCallback(false);
                }
            });

            this.$('.pane-grabber').dragger({
                // eslint-disable-next-line no-unused-vars
                repositionCallback: _.bind(function (x, y, e) {
                    if ($('body').hasClass(this.bodyClassContracted)) {
                        return;
                    }
                    const originalTocWidth = $(this.tocId).width();
                    var dragX = x;

                    if (x < $(this.tocId).offset()['left']) {
                        dragX = $(this.tocId).offset()['left'];
                    }
                    var tocWidth = dragX - $(this.tocId).offset()['left'] + draggerWidth;
                    var mainLeft = dragX + draggerWidth;
                    const mainWidth = $(this.contentId).width();
                    const annoTitleWidth = $(`${this.annoId} #anno-panetitle`).width();
                    const annoPanelClosed = document.querySelector('.pane-anno-hidden');
                    const amountOfChange = originalTocWidth - tocWidth;
                    const newWidth = mainWidth + amountOfChange;

                    const startingWidth = app?._compareView
                        ? $(document).width() * 0.49
                        : $(document).width();

                    tocWidth = startingWidth < tocWidth ? startingWidth : tocWidth;

                    // write in the next frame
                    _.defer(
                        _.bind(function () {
                            $(this.tocId + ', ' + this.tocId + ' .panetitle').css({
                                width: tocWidth,
                            });
                            $(this.contentId).css({
                                left: mainLeft,
                                width: `${newWidth}px`,
                            });
                            $(`${this.contentId} .panetitle`).css({
                                left: mainLeft,
                                width: `${newWidth - (annoPanelClosed ? annoTitleWidth : 0)}px`,
                            });
                        }, this),
                    );
                }, this),
                bounds: {
                    left: 0,
                    right: function () {
                        return $(document).width() - draggerWidth - 120;
                    },
                },
                clickCallback: _.bind(this.draggerClickCallback, this),
            });
        },
        expandToRef: function (elementID, expand) {
            var $element = this.$('a[ref="' + elementID + '"]');
            if ($element.length) {
                this.$('.toc-item').removeClass('active child-active');

                // highlight the entry and any collapsed parents
                $element
                    .addClass('active')
                    .parents('li.expandable:not(".expanded")')
                    .find('a.toc-item:first')
                    .addClass('active child-active');

                $element.removeClass('child-active');

                if (expand) {
                    $element.parents('li.expandable').addClass('expanded');
                }

                var viewPortRect = this.$el.get(0).getBoundingClientRect();
                var rowRect = $element[0].getBoundingClientRect();
                if (rowRect.bottom > viewPortRect.bottom || rowRect.top < viewPortRect.top) {
                    $element[0].scrollIntoView();
                }
            }
        },
        itemToggle: function (evt) {
            evt.preventDefault();
            var $li = $(evt.target).closest('li');

            if ($li.hasClass('expandable')) {
                // only toggle collapse if we've clicked on the icon
                if (
                    $(evt.target).closest('.toc-icon').length ||
                    $(evt.target).closest('.toc-icon-new').length
                ) {
                    $li.toggleClass('expanded');
                } else {
                    $li.addClass('expanded');
                }
                if (this.displayPrefs.text_size < 25) {
                    $li.find('.toc-root').each(this.truncateFunc);
                }
            }
        },
        navigate: function (evt) {
            evt.preventDefault();
            if (
                $(evt.target).closest('.toc-icon').length ||
                $(evt.target).closest('.toc-icon-new').length
            ) {
                return;
            }
            var ref = $(evt.currentTarget).attr('ref');
            this.model.trigger('document:navigate', ref);
        },
        syncContentScroll: function (sections, parentId) {
            if (this.$('a[ref="' + sections[0] + '"]').length) {
                this.expandToRef(sections[0], false);
            } else {
                // section might not have it's own ToC entry, so try the parent
                this.expandToRef(parentId, false);
            }
        },
        collapseToC: function () {
            this.$('#toc-tree ul li.expandable').removeClass('expanded');
        },
        calcTitleHeight: function (spacing, textSize) {
            var lineHeight = 1.4;
            switch (spacing) {
                case 'compact':
                    lineHeight = 1.2;
                    break;
                case 'spacious':
                    lineHeight = 1.8;
            }

            return lineHeight * textSize * 2.35;
        },
        truncateTocRoot: function () {
            if (this.displayPrefs.text_size < 25) {
                this.$('.toc-root').each(this.truncateFunc);
            }
        },
    });

    return TOCView;
});
