import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import requiredIf from 'react-required-if';

import Checkbox from '../FormControls/Checkbox';
import Form from 'react-bootstrap/Form';
import models from '../../models';
import FormModal, { MODAL_SIZE } from '../FormControls/FormModal';

const PENULTIMATE_RESET_COUNT = 4;

const ResetPassword = ({ show, handleModal, email, fetchUser, resetRequests }) => {
    const [reset, setReset] = useState(false);
    const [disable, setDisable] = useState(false);

    useEffect(() => {
        setReset(resetRequests >= PENULTIMATE_RESET_COUNT);
        // we can't disable the check button based off reset boolean because when it changes, that would disable/enable the button
        setDisable(resetRequests >= PENULTIMATE_RESET_COUNT);
    }, [resetRequests]);

    // for sending a password reset request for a user
    const userModel = new models.User();
    const submitForm = async () => {
        try {
            const result = await userModel.resetUserPassword({
                email: email,
                count: reset ? -1 : resetRequests,
            });
            toaster.success(result.result);
            if (fetchUser) {
                fetchUser();
            }
        } catch (err) {
            toaster.error(err.responseJSON.result);
            // sometimes the error still changes info on the user's page, so fetch them again
            if (fetchUser) {
                fetchUser();
            }
        }
    };

    return (
        <FormModal
            title="Reset password"
            size={MODAL_SIZE.SMALL}
            {...{ show, submitForm }}
            hideModal={handleModal}
            saveText="Send"
        >
            <Form.Group>
                <Form.Row className="user-email-reset">
                    <Form.Label>User&apos;s email</Form.Label>
                    <Form.Control
                        readOnly
                        plaintext
                        defaultValue={email}
                        className="dd-privacy-mask"
                    ></Form.Control>
                </Form.Row>
            </Form.Group>
            <Form.Group className="password-reset-count">
                <Checkbox
                    label="Set Reset Count to 0"
                    checked={reset}
                    onChange={() => setReset(!reset)}
                    disable={disable}
                />
            </Form.Group>
            <p>An email with instruction on how to reset their password will be sent to {email}</p>
        </FormModal>
    );
};

export default ResetPassword;

ResetPassword.propTypes = {
    show: PropTypes.bool.isRequired,
    handleModal: PropTypes.func.isRequired,
    email: requiredIf(PropTypes.string, (props) => props.show),
    fetchUser: PropTypes.func.isRequired,
    resetRequests: PropTypes.number,
};
