import React from 'react';
import PiconIcon from '../FormControls/Icons/PiconIcon';
import PropTypes from 'prop-types';

const MultipleGroupWarning = ({ organisation }) => {
    return (
        <>
            <PiconIcon iconName="warning-sign" className="red" />
            <i className="multigroup-warning">
                {organisation
                    ? '**Note: The email address is valid for more than one organisation. Please make sure this is correct.'
                    : '**Note: More than one user group exists for the chosen organisation, and a default group has been pre-selected. Please make sure this is correct.'}
            </i>
        </>
    );
};

export default MultipleGroupWarning;

MultipleGroupWarning.propTypes = {
    organisation: PropTypes.bool,
};
