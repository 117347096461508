import React from 'react';
import PropTypes from 'prop-types';

const IntroContainer = ({ intro }) => {
    // This will most likely need to become more complex as Pendragon decide what all goes in the intro
    return (
        <div className="intro-block" id="intro">
            <div className="copyrt">{intro?.copyright ? `Copyright: ${intro.copyright}` : ''}</div>
            <div
                className={`intro level1 ${intro.class}`}
                dangerouslySetInnerHTML={{ __html: intro.title }}
            ></div>
            <div className="intro section level2">
                <p className="ptxt">{intro.ptxt}</p>
            </div>
        </div>
    );
};

export default IntroContainer;

IntroContainer.propTypes = {
    intro: PropTypes.object.isRequired,
};
