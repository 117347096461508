import React from 'react';
import PropTypes from 'prop-types';

const UserDetailRow = ({ item, handleModal }) => {
    const handleClick = () => {
        handleModal(item.aTagEdit.id);
    };
    const isDisabled = item.disabled ? 'anchor-btn-disabled' : 'anchor-btn';

    return (
        <tr>
            <th>{item.title}</th>
            <td>
                {Array.isArray(item.data) ? item.data?.join(', ') : item.data}
                {item.disabled && <font color="red"> {item.aTagEdit.msg}</font>}
                {item.aTagEdit && app.user.has_perm(item.aTagEdit.permission) && !item.disabled && (
                    <button className={isDisabled} disabled={item.disabled} onClick={handleClick}>
                        {item.aTagEdit.btn}
                    </button>
                )}
            </td>
        </tr>
    );
};

export default UserDetailRow;

const entryItemPropTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.node,
    aTagEdit: PropTypes.shape({
        btn: PropTypes.string,
        id: PropTypes.string,
        permission: PropTypes.string,
    }),
};

UserDetailRow.propTypes = {
    item: PropTypes.shape(entryItemPropTypes).isRequired,
    handleModal: PropTypes.func.isRequired,
};
