import React, { useState } from 'react';
import PropTypes from 'prop-types';

import FormModal from '../FormControls/FormModal';
import FormInputUncontrolled from '../FormControls/Input/FormInputUncontrolled';

const VALIDATION_TYPES = {
    CONTENTS: 'password',
    MATCHING: 'confirm_password',
};

const ChangePasswordModal = ({ show, handleModal, userModel }) => {
    const [data, setData] = useState({
        old_password: '',
        password: '',
        confirm_password: '',
    });
    const [invalidMessage, setInvalidMessage] = useState({});

    const onChange = (value, name) => {
        setData({ ...data, [name]: value });

        if (Object.hasOwn(invalidMessage, name)) {
            setInvalidMessage({});
        }
    };

    const onBlur = (value, name) => {
        // checks if there's at least a letter and a number
        const alphanumeric = /\S*(\S*([a-zA-Z]\S*[0-9])|([0-9]\S*[a-zA-Z]))\S*/gi.test(value);
        const longEnough = value.length >= 8;
        if (!alphanumeric || !longEnough) {
            setInvalidMessage({
                ...invalidMessage,
                [VALIDATION_TYPES.CONTENTS]:
                    'Passwords must contain at least one letter and one number, and be at least 8 characters',
            });
        }

        if (data.password) {
            if (
                (data.password !== value && name === VALIDATION_TYPES.MATCHING) ||
                (data.confirm_password.length && data.confirm_password !== value)
            ) {
                setInvalidMessage({
                    ...invalidMessage,
                    [name]: 'New password and confirmation do not match',
                });
            }
        }
    };
    const submitForm = async (form) => {
        const formData = Object.keys(data).reduce((obj, item) => {
            obj[item] = form.elements[item].value;
            return obj;
        }, {});
        try {
            const response = await userModel.changePassword(formData);
            toaster.success(response.result);
        } catch (err) {
            console.error(err);
            toaster.error('Password could not be updated');
        }
    };

    return (
        <FormModal
            className="change-password-modal"
            title="Change password"
            size="sm"
            {...{ show, submitForm }}
            hideModal={handleModal}
        >
            <FormInputUncontrolled
                label="Old password"
                name="old_password"
                type="password"
                required={true}
                value={data.old_password}
                {...{ onChange }}
            />
            <FormInputUncontrolled
                label="New password"
                name="password"
                type="password"
                required={true}
                value={data.password}
                onBlur={onBlur}
                invalidMessage={invalidMessage?.password}
                {...{ onChange }}
            />
            <FormInputUncontrolled
                label="New password (again)"
                name="confirm_password"
                type="password"
                required={true}
                value={data.confirm_password}
                onBlur={onBlur}
                invalidMessage={invalidMessage?.confirm_password}
                {...{ onChange }}
            />
            <p>At least 8 characters, must include both letters and numbers</p>
        </FormModal>
    );
};

export default ChangePasswordModal;

ChangePasswordModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleModal: PropTypes.func.isRequired,
    userModel: PropTypes.shape({ changePassword: PropTypes.func }),
};
