import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';

import FormModal from '../FormControls/FormModal';
import { handleSaveErrors } from '../utils';
import PiconIcon from '../FormControls/Icons/PiconIcon';
import { deleteItem } from '../Favourites/FavouriteActions';
import { favouriteModel, documentPropTypes, favouritesModel } from './utils';

const EditFavouriteModal = ({
    hideModal,
    item,
    show,
    setDirty,
    canDelete,
    document,
    inTimeline,
}) => {
    const [dateView, setDateView] = useState(false);
    const [error, setError] = useState();
    const [faveName, setFaveName] = useState('');

    useEffect(() => {
        if (item.model.attributes.time_travelled) {
            setDateView(true);
        }
    }, []);

    useEffect(() => {
        setFaveName(item.model.get('name'));
    }, []);

    const updateUrl = (url, dateView) => {
        const newUrl = new URL(url);
        if (!dateView) {
            newUrl.search = '';
        } else {
            let ttInfo = `?tt_option=${
                document.model.ttViewOption
            }&tt_date=${document.model.ttViewDate.format(app.settings.dateTransportFormat)}`;
            if (document.model.ttViewOption === 'tt-between') {
                ttInfo = ttInfo.concat(
                    '',
                    `&tt_date2=${document.model.ttViewDate2.format(
                        app.settings.dateTransportFormat,
                    )}`,
                );
            }
            newUrl.search = ttInfo;
        }
        return newUrl.href;
    };

    const checkInputValidity = async () => {
        let isValid = true;
        let duplicate;

        // The document is different if we're coming from the document or from the list of faves
        if (document.options != undefined) {
            duplicate = await document.options.favourites.fetchFavourites({ name: faveName });
        } else {
            duplicate = await document.fetchFavourites({ name: faveName });
        }

        if (duplicate.length) {
            setError('Favourite name must be unique');
            isValid = false;
        }

        return isValid;
    };

    const submitForm = async (form) => {
        // need to check for duplicate name here before moving into try/catch block
        try {
            item.model.set('name', form.elements.name.value);
            // this will set the url depending on the check form
            if (!item.tt_disabled) {
                item.model.set(
                    'favourite_url',
                    updateUrl(item.model.get('favourite_url'), dateView),
                );
                item.model.set('time_travelled', dateView);
            }

            item.isNew ? await item.model.save() : await item.model.update();
            setDirty();
            toaster.success('Favourite saved');
        } catch (error) {
            handleSaveErrors({
                xhr: error,
                genericMessage: 'Could not save favourite',
                validFormFields: [
                    'name',
                    'favourite_url',
                    'family',
                    'owner',
                    'ref_id',
                    'document',
                    'time_travelled',
                ],
            });
        }
    };

    const extraButtons = (
        <div className="modal-footer-checkbox">
            <span
                className="favourite-delete"
                onClick={() => deleteItem(item, setDirty, hideModal)}
            >
                <PiconIcon iconName="delete" className="left" />
                Delete
            </span>
        </div>
    );

    return (
        <FormModal
            {...{
                submitForm,
                show,
                hideModal,
                checkInputValidity,
                title: item.isNew ? 'Add Favourite' : 'Edit Favourite',
                size: 'sm',
                ...(!item.isNew && canDelete ? { extraButtons } : {}),
                isMain: true,
            }}
        >
            <Form.Group>
                {item.isNew && (
                    <Form.Text>
                        Adding Favourite: <i>{item.model.get('name')}</i>
                    </Form.Text>
                )}
            </Form.Group>
            <Form.Group>
                <Form.Label>Favourite Name</Form.Label>
                <Form.Control
                    aria-label="favourite name"
                    type="text"
                    name="name"
                    defaultValue={item.model.get('name')}
                    required
                    onChange={(evt) => {
                        setFaveName(evt.target.value);
                        setError(null);
                    }}
                    isInvalid={error}
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                    {error ? error : 'Please provide a name'}
                </Form.Control.Feedback>
                <Form.Text id="favouriteHelpBlock">
                    This name is how it will appear in your list of Favourites
                </Form.Text>
            </Form.Group>
            {!inTimeline && (
                <Form.Group id="timeTravelCheckbox">
                    {!item.tt_disabled && (
                        <Form.Check
                            aria-label="time travel checkbox"
                            type="checkbox"
                            label="Include the Date and View information"
                            name="dateAndView"
                            checked={dateView}
                            onChange={() => setDateView(!dateView)}
                            disabled={item.tt_disabled}
                        />
                    )}
                    {(item.isNew || item.model.get('time_travelled')) && (
                        <Form.Text id="favouriteTimeTravelHelpBlock">{`${item.viewOption} at ${item.viewDate}`}</Form.Text>
                    )}
                </Form.Group>
            )}
        </FormModal>
    );
};

export default EditFavouriteModal;

EditFavouriteModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    item: PropTypes.shape(favouriteModel),
    show: PropTypes.bool.isRequired,
    setDirty: PropTypes.func.isRequired,
    canDelete: PropTypes.bool,
    document: PropTypes.oneOfType([
        PropTypes.shape(documentPropTypes),
        PropTypes.shape(favouritesModel),
    ]),
    inTimeline: PropTypes.object,
};
