module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<label class="toc-item" role="treeitem">\n    <span class="toc-title">\n        <span aria-hidden="true" role="presentation" class="toc-icon"><i></i></span>\n        <input class="toc-select doc-id" type="checkbox" value="'+
((__t=( document.doc_id ))==null?'':_.escape(__t))+
'">\n        '+
((__t=( document.title ))==null?'':_.escape(__t))+
'\n    </span>\n</label>\n';
}
return __p;
};
