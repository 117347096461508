import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Modal, Button, Form, FormControl } from 'react-bootstrap';
import { formatViewOption } from '../DocumentFavourites/FavouritePopover';

const CopyLocationModal = ({ handleModal, show }) => {
    const [location, setLocation] = useState('');
    const [viewDate, setViewDate] = useState(false);
    const [dateAndTime, setDateAndTime] = useState({ viewOption: '', viewDate: '' });
    const [hash, setHash] = useState('');

    useEffect(() => {
        // Checking where we are to get the right link - checking documentView is more reliable than if our current router is 'search'
        if (app.documentView && app.getDocument() && app.getDocument().getInstance()) {
            const locationURL = new URL(app.getDocument().getInstance().getPageUrl());
            locationURL.hash = app.documentView.content.activeElement;
            setLocation(`${locationURL.origin}${locationURL.pathname}${locationURL.hash}`);
            // Save the hash for scrolling
            setHash(locationURL.hash);

            const tt_params = _.fromPairs([...new URLSearchParams(locationURL.search)]);
            // Format the date or date range
            const viewDate =
                tt_params.tt_option != 'tt-between'
                    ? app.moment(tt_params.tt_date).format(app.settings.dateFormat)
                    : `${app.moment(tt_params.tt_date).format(app.settings.dateFormat)} - ${app
                          .moment(tt_params.tt_date2)
                          .format(app.settings.dateFormat)}`;

            setDateAndTime({ viewOption: formatViewOption(tt_params.tt_option), viewDate });
        } else {
            // Resetting the location if we aren't in a documentView or we'll get an old link if we're searching
            setLocation(window.location.href.replace('@', '%40'));
        }
    }, []);

    const handleCheck = () => {
        const addDate = !viewDate;
        const locationURL = new URL(location);

        if (addDate) {
            locationURL.searchParams.set(
                'tt_option',
                `tt-${dateAndTime.viewOption.toLowerCase().replaceAll(' ', '')}`,
            );
            // Set date or date range to url
            if (dateAndTime.viewOption != 'Between') {
                locationURL.searchParams.set(
                    'tt_date',
                    app
                        .moment(dateAndTime.viewDate, app.settings.dateValidFormats)
                        .format(app.settings.dateTransportFormat),
                );
            } else {
                const dates = dateAndTime.viewDate.split(' - ');
                locationURL.searchParams.set(
                    'tt_date',
                    app
                        .moment(dates[0], app.settings.dateValidFormats)
                        .format(app.settings.dateTransportFormat),
                );
                locationURL.searchParams.set(
                    'tt_date2',
                    app
                        .moment(dates[1], app.settings.dateValidFormats)
                        .format(app.settings.dateTransportFormat),
                );
            }

            // the hash is already on locationURL
            setLocation(`${locationURL}`);
        } else {
            setLocation(`${locationURL.origin}${locationURL.pathname}${hash}`);
        }

        setViewDate(addDate);
    };

    const autoFocus = useCallback((element) => {
        return element ? element.focus() : null, [];
    });

    return (
        <Modal
            className="rbs4"
            size="sm"
            show={show}
            onHide={handleModal}
            aria-label="copy-location-modal"
            role="main"
        >
            <Modal.Header closeButton>
                <h1 className="sr-only">Copy Location Modal</h1>
                <Modal.Title>Copy Location</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Create a shareable link to your current document, at your current location, view
                    date, and time travel view
                </p>
                <Form.Group id="location">
                    <Form.Label>
                        Link <span className="muted">(Ctrl-C to copy to clipboard)</span>
                    </Form.Label>
                    <FormControl
                        type="url"
                        defaultValue={location}
                        ref={autoFocus}
                        aria-label="location link"
                        onFocus={(evt) => evt.target.select()}
                    />
                </Form.Group>
                <Form.Group id="timeTravelCheckbox">
                    <Form.Check
                        aria-label="time travel checkbox"
                        type="checkbox"
                        label="Include the Date and View information"
                        name="dateAndView"
                        checked={viewDate}
                        onChange={handleCheck}
                    />
                    <Form.Text id="locationTimeTravelHelpBlock">{`${dateAndTime.viewOption} - ${dateAndTime.viewDate}`}</Form.Text>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn-primary" onClick={handleModal}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CopyLocationModal;

CopyLocationModal.propTypes = {
    handleModal: PropTypes.func,
    show: PropTypes.bool,
};
