import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';

import Form from 'react-bootstrap/Form';

import WorkplaceSection from './WorkplaceSection';
import confirm from '../FormControls/ConfirmModal/ConfirmModalService.js';
import FormModal from '../FormControls/FormModal';
import FormInput from '../FormControls/Input/FormInput';
import TypedSelect from '../FormControls/Select/TypedSelect';
import ChangeEmailModal from '../Modals/ChangeEmailModal';
import models from '../../models.js';

import { getPostcodesForGroup, getUserGroupAlertMessage, VALID_POSTCODE_LENGTH } from './utils';
import { useFetch } from '../customHooks';
import {
    arrayNotEmpty,
    handleSaveErrors,
    mapArrayToOptionsField,
    mapObjectToOptionsField,
    mapStringToOptionsField,
    checkPostcodeIsValid,
    reverseMapArrayOptions,
} from '../utils';

const mapUserGroups = (groups) => {
    return groups.map((g) => {
        return {
            value: g.id,
            label: g.name,
            postcodes: g.postcodes,
        };
    });
};

const clientManagerRole = 'Client Manager';

const UserEditorModal = ({ userID, show, hideModal, metadata, setDirty }) => {
    const [data, setData] = useState({});
    const [editedData, setEditedData] = useState({});
    const [originalData, setOriginalData] = useState({});
    const [validateChildren, setValidateChildren] = useState({});
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [preAlerts, setPreAlerts] = useState([]);

    const userModel = new models.User({ id: userID });

    const { loading } = useFetch({
        doFetch: async () => await userModel.fetch(),
        setFetchedState: (userData) => {
            setData({
                ...userData,
                organisation: mapObjectToOptionsField(userData?.organisation),
                user_groups: mapUserGroups(userData?.user_groups),
                postcode: mapStringToOptionsField(userData?.user_details?.postcode),
                roles: mapArrayToOptionsField(userData?.roles),
            });
            // Use userData to get the original data, but flatten user_details into it
            const { user_details, ...flatterData } = userData;
            setOriginalData({ ...flatterData, ...user_details });
        },
    });

    const submitForm = async (form) => {
        if (preAlerts.length > 0) {
            // trigger confirm modal for pre-alerts
            const isMultiple = preAlerts.length > 1;
            const confirmed = await confirm({
                title: `User Group Warning${isMultiple ? 's' : ''}`,
                message: getUserGroupAlertMessage(isMultiple, preAlerts),
                centered: true,
            });

            if (!confirmed) return false;
        }
        try {
            const { first_name, last_name, job_title, department, phone, address } = form.elements;
            const organisation = data.organisation.value;
            const user_groups = reverseMapArrayOptions(data.user_groups);
            const postcode = data.postcode?.value || ''; // if we've removed the postcode value, add empty string
            const roles = reverseMapArrayOptions(data.roles);

            const allData = {
                id: userID,
                first_name: first_name.value,
                last_name: last_name.value,
                organisation,
                user_groups,
                roles,
                user_details: {
                    department: department.value,
                    phone: phone.value,
                    address: address.value,
                    job_title: job_title.value,
                    postcode,
                },
                edited_data: editedData,
                original_data: originalData,
            };

            const result = await userModel.action(allData, 'PATCH');
            toaster.success(`User ${result.email} successfully edited`);
            setDirty();
        } catch (error) {
            handleSaveErrors({
                xhr: error,
                genericMessage: 'The user could not be edited',
            });
            return false;
        }
    };

    const checkInputValidity = () => {
        setValidateChildren({ validate: true });
        let isValid = true;

        if (!data.organisation || !arrayNotEmpty(data.user_groups)) {
            isValid = false;
        }

        return isValid;
    };

    const onChange = (newVal, name) => {
        const value = newVal?.constructor === Object ? newVal.value : newVal;
        setEditedData({ ...editedData, [name]: value });
    };

    const handleShowEmail = (e) => {
        e.preventDefault();
        setShowEmailModal(!showEmailModal);
    };

    const handleAddRemoveClientManager = async (e) => {
        e.preventDefault();
        let roles = data.roles;
        const adminRole = mapArrayToOptionsField(metadata?.groups).find(
            (role) => role.label === clientManagerRole,
        );
        if (roles.some((role) => role.label === clientManagerRole)) {
            roles = roles.filter((role) => role.label !== clientManagerRole);
        } else {
            roles[roles.length] = adminRole;
        }
        setData({ ...data, ['roles']: roles });
        setEditedData({ ...editedData, ['roles']: roles });
    };

    const postcodesFromGroup = getPostcodesForGroup(data.user_groups);

    return (
        <FormModal
            title="Edit user"
            {...{
                submitForm,
                hideModal,
                show,
                checkInputValidity,
                loading: loading,
                backdrop: 'static',
                className: 'edit-user',
            }}
        >
            <FormInput
                label="First name"
                name="first_name"
                defaultValue={data.first_name}
                onChange={onChange}
                required={true}
                invalidMessage="Please enter first name"
            />
            <FormInput
                label="Last name"
                name="last_name"
                defaultValue={data.last_name}
                onChange={onChange}
                required={true}
                invalidMessage="Please enter last name"
            />
            <FormInput
                label="Job Title"
                name="job_title"
                defaultValue={data.user_details?.job_title}
                onChange={onChange}
            />
            <FormInput
                label="Department"
                name="department"
                defaultValue={data.user_details?.department}
                onChange={onChange}
            />
            <Form.Label>
                Email Address
                <p>
                    {data.email}
                    <button className="anchor-btn btn" onClick={(e) => handleShowEmail(e)}>
                        Change
                    </button>
                </p>
            </Form.Label>
            {showEmailModal && (
                <ChangeEmailModal
                    show={true}
                    handleModal={() => setShowEmailModal(false)}
                    currEmail={data.email}
                    userID={userID}
                    setDirty={setDirty}
                />
            )}
            <FormInput
                label="Phone"
                name="phone"
                defaultValue={data.user_details?.phone}
                onChange={onChange}
                maxLength={30}
            />
            <WorkplaceSection
                {...{
                    setData,
                    data,
                    metadata,
                    setEditedData,
                    editedData,
                    shouldCheckValidity: validateChildren,
                    setPreAlerts,
                }}
            />
            <Form.Group>
                <Form.Label>Roles / Permissions</Form.Label>
                <p>
                    {data.roles?.map((role, idx, roles) => (
                        <Fragment key={role.value}>
                            <strong>{role.label}</strong>
                            {idx + 1 < roles.length && ', '}
                        </Fragment>
                    ))}
                    {app.user.has_perm('main.change_userdetails') && (
                        <button
                            className="anchor-btn btn"
                            onClick={(e) => handleAddRemoveClientManager(e)}
                        >
                            {data?.roles?.some((role) => role.label === clientManagerRole)
                                ? 'Remove '
                                : 'Add '}
                            Client Manager
                        </button>
                    )}
                </p>
            </Form.Group>
            <Form.Group>
                <Form.Label>Work postcode</Form.Label>
                <TypedSelect
                    options={postcodesFromGroup}
                    onChange={(postcode) => {
                        setData({ ...data, postcode });
                        onChange(postcode, 'postcode');
                    }}
                    value={data?.postcode}
                    name="postcode"
                    placeholder="Create or select a postcode"
                    maxLength={VALID_POSTCODE_LENGTH}
                />
            </Form.Group>
            {/*Work address is required if the postcode doesn't match some of the available postcodes from user groups */}
            <FormInput
                label="Work address (if different)"
                name="address"
                defaultValue={data.user_details?.address}
                type="textarea"
                onChange={onChange}
                maxLength={3000}
                required={
                    !checkPostcodeIsValid(
                        data.postcode?.value,
                        data.user_groups
                            ?.filter((group) => group.postcode)
                            ?.map((group) => group.postcodes),
                    )
                }
            />
        </FormModal>
    );
};

export default UserEditorModal;

UserEditorModal.propTypes = {
    userID: PropTypes.string.isRequired,
    show: PropTypes.bool,
    hideModal: PropTypes.func.isRequired,
    metadata: PropTypes.shape({
        organisations: PropTypes.array,
        groups: PropTypes.array,
        subscription_groups: PropTypes.array,
        postcodes: PropTypes.array,
    }),
    setDirty: PropTypes.func,
};
