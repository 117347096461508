import React from 'react';

import { ProgressBar } from 'react-bootstrap';
import { useDownloadContext } from './DownloadContext';

const getPercentage = (total, count) => {
    if (!total) {
        return 0;
    }
    return Math.min(100, Math.round((100 * count) / total));
};

const DownloadProgress = () => {
    const { countRef } = useDownloadContext();
    const successfulPercentage = getPercentage(countRef.current.total, countRef.current.completed);
    const failedPercentage = getPercentage(countRef.current.total, countRef.current.failed);

    return (
        <div>
            <div>
                <span>Total: {countRef.current.total} </span>
                <span>Successful: {countRef.current.completed} </span>
                <span>Failed: {countRef.current.failed} </span>
            </div>
            <ProgressBar>
                <ProgressBar
                    variant="success"
                    now={successfulPercentage}
                    key={1}
                    label={`${
                        successfulPercentage >= 10 ? 'Successful' : 'S'
                    }: ${successfulPercentage}%`}
                    aria-label="progress success"
                />
                <ProgressBar
                    variant="danger"
                    now={failedPercentage}
                    key={3}
                    label={`${failedPercentage >= 10 ? 'Failed' : 'F'}: ${failedPercentage}%`}
                    aria-label="progress fail"
                />
            </ProgressBar>
        </div>
    );
};

export default DownloadProgress;
