import React, { useState, useContext } from 'react';

import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';

import { getSelectedFilterValues, asyncAction } from '../../utils';
import SimpleTableControl from '../../FormControls/Table/SimpleTableControl';
import FormModal from '../../FormControls/FormModal';
import { getDateTime, getDate } from '../../utils';
import { Flags } from '../../../models';
import { getSelectedCount } from '../../FormControls/Table/utils';
import confirm from '../../FormControls/ConfirmModal/ConfirmModalService';
import { SnoozeFlagsModal } from './SnoozeFlagsModal';
import { ResolveFlagsModal } from './ResolveFlagsModal';
import FlagNote from './FlagNote';
import { TableContext, TABLE_ACTION } from '../../FormControls/Table/TableContext';

// TODO - should this come from DB??
// This list shouldn't include the Mandrill event Flag options
const flagTypes = [
    { value: 'postcode', text: 'Postcode' },
    { value: 'email', text: 'Email' },
    { value: 'orphaned', text: 'Orphaned annotations' },
    { value: 'other', text: 'Other' },
];

const headers = ['User', 'Date', 'Type', 'Reason', 'Notes', '', ''];

const FLAG_ACTION_TYPES = {
    SNOOZE: 0,
    RESOLVE: 1,
};
Object.freeze(FLAG_ACTION_TYPES);

export const UserFlags = ({ show, selectedId, hideFlags, setDirty }) => {
    const ownerParams = {
        single_user_selected: true,
        user_id: selectedId,
    };

    const userCount = 1;

    return <FlagsContainer {...{ hideFlags, ownerParams, show, setDirty, userCount }} />;
};

export const UserTableFlags = ({ flagState, hideFlags }) => {
    const { tableState, dispatchTableState } = useContext(TableContext);

    const setDirty = () => dispatchTableState({ type: TABLE_ACTION.SET_DIRTY });

    const ownerParams = flagState.selectedId
        ? {
              single_user_selected: true,
              user_id: flagState.selectedId,
          }
        : getSelectedFilterValues(tableState);

    const userCount = flagState.selectedId ? 1 : getSelectedCount(tableState);

    return (
        <FlagsContainer
            show={flagState.flagShown}
            {...{ hideFlags, ownerParams, setDirty, userCount }}
        />
    );
};

const FlagsContainer = ({ show, hideFlags, ownerParams, setDirty, userCount }) => {
    const [flagActionShown, setShowFlagAction] = useState({ show: false });

    const showFlagAction = (flag, type) => setShowFlagAction({ show: true, type, flag });
    const hideFlagAction = () => setShowFlagAction({ show: false });

    const flagsModel = new Flags();

    const submitForm = async (form) => {
        if (userCount > 1) {
            const confirmedResult = await confirm({
                message: `You are about to set flags to ${userCount} users. Are you sure you want to continue?`,
            });

            if (!confirmedResult) return;
        }

        const args = {
            flag_type: form.elements.type.value,
            reason: form.elements.reason.value,
            notes: form.elements.notes?.value,
            ...ownerParams,
        };

        const fun = (params) => flagsModel.addFlags(params);
        await asyncAction({ fun, setDirty, args, actionName: 'change flags' });

        hideFlags();
    };

    const doFetch = async () => {
        return await flagsModel.getFlagsForUsers(ownerParams);
    };

    const cancelSnoozeFlag = async (flag) => {
        const args = {
            flag_id: flag.id,
        };

        const fun = (params) => flagsModel.cancelSnoozeFlag(params);
        await asyncAction({ fun, setDirty, args, actionName: 'cancel snooze flags' });

        hideFlags();
    };

    const getTableColumns = (item) => {
        const date = getDateTime(item.date_created);

        const resolve = (
            <Button
                variant="link"
                className="flag-action"
                onClick={() => showFlagAction(item, FLAG_ACTION_TYPES.RESOLVE)}
            >
                Resolve
            </Button>
        );

        const snooze = (
            <>
                <Button
                    variant="link"
                    className="flag-action"
                    onClick={() => showFlagAction(item, FLAG_ACTION_TYPES.SNOOZE)}
                >
                    {item?.snoozed_until
                        ? `Snoozed until ${getDate(item.snoozed_until)}`
                        : 'Snooze'}
                </Button>
                {item?.snoozed_until && (
                    <Button
                        variant="link"
                        className="flag-action"
                        onClick={() => cancelSnoozeFlag(item)}
                    >
                        (Cancel)
                    </Button>
                )}
            </>
        );

        return [
            item.user_name,
            date,
            item.flag_type_name,
            item.reason,
            item.notes,
            resolve,
            snooze,
        ];
    };

    return (
        <div>
            <FormModal title="Change flags" hideModal={hideFlags} {...{ show, submitForm }}>
                <>
                    <SimpleTableControl
                        className="flag-table"
                        {...{ doFetch, getTableColumns, headers, dataName: 'flag' }}
                    />

                    <Form.Group>
                        <Form.Label>Add flag</Form.Label>
                        <Form.Row>
                            <Col>
                                <Form.Control as="select" defaultValue="" name="type" required>
                                    <option disabled hidden value="">
                                        - Select type -
                                    </option>
                                    {flagTypes.map((type) => {
                                        return (
                                            <option key={type.value} value={type.value}>
                                                {type.text}
                                            </option>
                                        );
                                    })}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Please choose a flag type.
                                </Form.Control.Feedback>
                            </Col>
                            <Col>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Reason"
                                    name="reason"
                                    maxLength={100}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a flag reason.
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Row>
                    </Form.Group>
                    <FlagNote />
                </>
            </FormModal>
            <SnoozeFlagsModal
                {...{
                    show: flagActionShown.show && flagActionShown.type == FLAG_ACTION_TYPES.SNOOZE,
                    flag: flagActionShown.flag,
                    hideFlags,
                    hideFlagAction,
                    setDirty,
                    flagsModel,
                }}
            />
            <ResolveFlagsModal
                {...{
                    show: flagActionShown.show && flagActionShown.type == FLAG_ACTION_TYPES.RESOLVE,
                    flag: flagActionShown.flag,
                    hideFlags,
                    hideFlagAction,
                    setDirty,
                    flagsModel,
                }}
            />
        </div>
    );
};

UserFlags.propTypes = {
    show: PropTypes.bool.isRequired,
    selectedId: PropTypes.string.isRequired,
    hideFlags: PropTypes.func.isRequired,
    setDirty: PropTypes.func.isRequired,
};

UserTableFlags.propTypes = {
    flagState: PropTypes.shape({
        selectedId: PropTypes.number,
        flagShown: PropTypes.bool.isRequired,
    }).isRequired,
    hideFlags: PropTypes.func.isRequired,
};

FlagsContainer.propTypes = {
    show: PropTypes.bool.isRequired,
    hideFlags: PropTypes.func.isRequired,
    ownerParams: PropTypes.object.isRequired,
    setDirty: PropTypes.func.isRequired,
    userCount: PropTypes.number.isRequired,
};
