module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="bydate-listing">\n\n    ';
 if (_.isEmpty(groups)) { 
__p+='\n\n        <p class="news-msg">No archive survey items found.</p>\n\n    ';
 } else { 
__p+='\n\n        ';
 if (dateFrom && dateTo) { 
__p+='\n        <p class="news-msg">Archive surveys between <em>'+
((__t=( dateFrom ))==null?'':_.escape(__t))+
'</em> and <em>'+
((__t=( dateTo ))==null?'':_.escape(__t))+
'</em>...</p>\n        ';
 } else { 
__p+='\n        <p class="news-msg">Archive surveys for <em>'+
((__t=( dateFrom ))==null?'':_.escape(__t))+
'</em>...</p>\n        ';
 } 
__p+='\n\n        ';

        // Reverse the keys to get the years and months into the right order
        
__p+='\n\n        ';
 _.each(_.keys(groups).reverse(), function(yearKey) { 
__p+='\n        <ul>\n            <li>'+
((__t=( yearKey ))==null?'':_.escape(__t))+
'</li>\n\n            ';
 _.each(_.keys(groups[yearKey]).reverse(), function(monthKey) { 
__p+='\n            <ul>\n                <li>'+
((__t=( monthNames[monthKey] ))==null?'':_.escape(__t))+
'</li>\n\n                ';
 _.each(groups[yearKey][monthKey], function(surveys, published_date) { 
__p+='\n                <ul>\n                    <li>'+
((__t=( app.moment(published_date).format(app.settings['longDateFormat']) ))==null?'':_.escape(__t))+
'</li>\n                    <ul>\n                        ';
 _.each(surveys, function(item) { 
__p+='\n                        <li>\n                            <a class="doc-link" href="/surveys/'+
((__t=( item.attributes.doc_id ))==null?'':_.escape(__t))+
'" data-id="'+
((__t=( item.attributes.doc_id ))==null?'':_.escape(__t))+
'">'+
((__t=( item.attributes.title ))==null?'':_.escape(__t))+
'</a>\n                        </li>\n                        ';
 }); 
__p+='\n                    </ul>\n                </ul>\n                ';
 }); 
__p+='\n            </ul>\n            ';
 }); 
__p+='\n        </ul>\n        ';
 }); 
__p+='\n\n    ';
 } 
__p+='\n\n</div>';
}
return __p;
};
