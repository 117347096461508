import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import requiredIf from 'react-required-if';

import 'react-datepicker/dist/react-datepicker.css';

import { asyncAction } from '../../utils';
import FormModal, { MODAL_SIZE } from '../../FormControls/FormModal';
import Col from 'react-bootstrap/Col';
import { DatePicker } from '../../FormControls/DatePicker';

export const SnoozeFlagsModal = ({
    show,
    flag,
    hideFlagAction,
    hideFlags,
    setDirty,
    flagsModel,
}) => {
    const submitForm = async (form) => {
        const args = {
            flag_id: flag.id,
            date: form.elements.date.dataset.formatted,
        };

        const fun = (params) => flagsModel.snoozeFlag(params);
        await asyncAction({ fun, setDirty, args, actionName: 'snooze flags' });

        hideFlagAction();
        hideFlags();
    };

    const tomorrow = app.moment.utc().add(1, 'days').toDate();

    return (
        <FormModal
            title="Snooze flags"
            hideModal={hideFlagAction}
            size={MODAL_SIZE.SMALL}
            {...{ submitForm, show }}
        >
            <Form.Group as={Form.Row} controlId="formHorizontalEmail">
                <Form.Label column sm={2}>
                    Snooze flag until
                </Form.Label>
                <Col sm={10}>
                    <DatePicker
                        minDate={tomorrow}
                        required={true}
                        initialDate={
                            flag?.snoozed_until && app.moment(flag.snoozed_until).isValid()
                                ? app.moment(flag.snoozed_until).toDate()
                                : tomorrow
                        }
                    />
                </Col>
            </Form.Group>
        </FormModal>
    );
};

SnoozeFlagsModal.propTypes = {
    hideFlagAction: PropTypes.func.isRequired,
    hideFlags: PropTypes.func.isRequired,
    setDirty: PropTypes.func.isRequired,
    flagsModel: PropTypes.shape({
        snoozeFlag: PropTypes.func.isRequired,
    }),
    show: PropTypes.bool.isRequired,
    flag: requiredIf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            snoozed_until: PropTypes.string,
        }),
        (props) => props.show,
    ),
};
