import React, { useState } from 'react';
import PropTypes from 'prop-types';

import FormModal from '../FormControls/FormModal';
import { Form } from 'react-bootstrap';

const AlertFrequencyModal = ({ show, hideModal, options, title, handleSubmit, active }) => {
    const [activeChoice, setActiveChoice] = useState(active);

    return (
        <FormModal
            {...{ title, show, hideModal }}
            size="sm"
            submitForm={() => handleSubmit(activeChoice)}
            backdrop="static"
        >
            <Form.Group>
                <h5>How often do you wish to receive alerts?</h5>
                {options &&
                    options.map((option) => (
                        <Form.Check
                            key={option.id}
                            type="radio"
                            id={option.id}
                            label={option.label}
                            checked={activeChoice.id === option.id}
                            onChange={() => setActiveChoice(option)}
                        />
                    ))}
            </Form.Group>
        </FormModal>
    );
};

export default AlertFrequencyModal;

AlertFrequencyModal.propTypes = {
    show: PropTypes.bool.isRequired,
    hideModal: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, label: PropTypes.string })),
    title: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    active: PropTypes.shape({ id: PropTypes.number, label: PropTypes.string }),
};
