define([
    'backbone',
    'bootbox',
    './helpers',
    '../models',
    '../templates/administration/document.html',
    '../templates/administration/document_table.html',
    '../extras/backbone-mixin',
], function (Backbone, bootbox, helpers, models, documentTpl, documentTableTpl) {
    const { isNewsItem, isSurveyItem } = require('../extras/index');
    /*
        Document detail table
    */
    var DocumentTableView = helpers.TemplateView.extend({
        template: documentTableTpl,
        events: {
            'click .action-menu ul.dropdown-menu li a': 'action',
        },
        getInPlaceUrl: function () {
            let url = app.urls.documents;
            this.collection.forEach((item) => {
                if (item.families.filter((family) => family.slug === 'news').length) {
                    url = app.urls.news;
                }
                if (item.families.filter((family) => family.slug === 'srv').length) {
                    url = app.urls.surveys;
                }
                if (item.families.filter((family) => family.slug === 'timeline').length) {
                    url = app.urls.timeline;
                }
            });
            return url;
        },
        templateHelpers: function () {
            return {
                tableTitle: this.options.tableTitle,
                multiselect: this.options.multiselect,
                actions: this.getActions(),
                viewInPlaceUrl: this.getInPlaceUrl(),
                processConversionNotes: 'processConversionNotes',
            };
        },
        render: function () {
            if (!_.isEmpty(this.collection)) {
                helpers.TemplateView.prototype.render.apply(this, arguments);
            }
            return this;
        },
        getActions: function () {
            const isNews = isNewsItem(this.collection);
            return _.filter(
                this.options.actions,
                _.bind(function (action) {
                    if (action.action === 'publish' && isNews) {
                        const canPublish = app.user.has_perm('content.publish_document')
                            ? app.user.has_perm('content.publish_document')
                            : app.user.has_perm('content.publish_news_document');
                        return canPublish;
                    } else if (action.action === 'retire' && isNews) {
                        const canRetire = app.user.has_perm('content.retire_document')
                            ? app.user.has_perm('content.retire_document')
                            : app.user.has_perm('content.retire_news_document');
                        return canRetire;
                    }
                    return app.user.has_perm(action.perm);
                }, this),
            );
        },
        action: function (evt) {
            evt.preventDefault();
            var action = $(evt.currentTarget).data('action');
            var id = $(evt.currentTarget).data('uuid');

            $(evt.currentTarget)
                .parents('.action-menu')
                .find('button .picon')
                .removeClass('picon-cog')
                .addClass('picon-spinner ')
                .addClass('icon-spin');

            this.trigger('action', action, id);
        },
    });

    /*
        Document detail
    */
    var DocumentView = helpers.TemplateView.extend({
        el: '#main .main-content .container',
        template: documentTpl,
        initialize: function () {
            this.isNews = isNewsItem(this.model.get('instances'));
            this.isSurvey = isSurveyItem(this.model.get('instances'));
            this.initializeTables();
            this.listenTo(this, 'render', this.renderTables);
            this.model.bind('change', _.bind(this.render, this));
            this.refresh();
            app.trigger('document:ready');
        },
        templateHelpers: function () {
            return {
                published: this.model
                    .get('instances')
                    .filter((instance) => instance.state === 'published'),
            };
        },
        getAction: function (action, docType) {
            if (docType) {
                return `${action}_${docType}`;
            }
            return action;
        },
        getPermission: function (action, docType) {
            if (docType) {
                return `content.${action}_${docType}_document`;
            }
            return `content.${action}_document`;
        },
        initializeTables: function () {
            let docType = '';
            if (this.isNews) {
                docType = 'news';
            } else if (this.isSurvey) {
                docType = 'survey';
            }
            this.publishedTableView = new DocumentTableView({
                tableTitle: 'Published',
                actions: [
                    {
                        action: this.getAction('retire', docType),
                        title: 'Retire',
                        perm: this.getPermission('retire', docType),
                        index_state: '',
                    },
                    {
                        action: 'index',
                        title: 'Index for Search',
                        perm: 'content.publish_document',
                        index_state: 'NOT',
                    },
                    {
                        action: 'index',
                        title: 'Re-index',
                        perm: 'content.publish_document',
                        index_state: 'ERR',
                    },
                ],
            });
            this.listenTo(this.publishedTableView, 'action', this.action);

            this.readyTableView = new DocumentTableView({
                tableTitle: 'Ready to Publish',
                actions: [
                    {
                        action: this.getAction('publish', docType),
                        title: 'Publish',
                        perm: this.getPermission('publish', docType),
                        index_state: '',
                    },
                    {
                        action: 'reject',
                        title: 'Reject',
                        perm: 'content.reject_document',
                        index_state: '',
                    },
                    {
                        action: 'index',
                        title: 'Index for Search',
                        perm: 'content.index_document',
                        index_state: 'NOT',
                    },
                    {
                        action: 'index',
                        title: 'Re-index',
                        perm: 'content.index_document',
                        index_state: 'ERR',
                    },
                    {
                        action: 'delind',
                        title: 'Remove Index',
                        perm: 'content.index_document',
                        index_state: 'IND',
                    },
                ],
            });
            this.listenTo(this.readyTableView, 'action', this.action);

            this.draftTableView = new DocumentTableView({
                tableTitle: 'Draft',
                actions: [
                    {
                        action: 'make_ready',
                        title: 'Ready',
                        perm: 'content.make_ready_document',
                        index_state: '',
                    },
                    {
                        action: 'index',
                        title: 'Index for Search',
                        perm: 'content.index_document',
                        index_state: 'NOT',
                    },
                    {
                        action: 'index',
                        title: 'Re-index',
                        perm: 'content.index_document',
                        index_state: 'ERR',
                    },
                    {
                        action: 'delind',
                        title: 'Remove Index',
                        perm: 'content.index_document',
                        index_state: 'IND',
                    },
                ],
            });
            this.listenTo(this.draftTableView, 'action', this.action);

            this.otherTableView = new DocumentTableView({
                tableTitle: 'Other',
                actions: [
                    {
                        action: 'delind',
                        title: 'Remove Index',
                        perm: 'content.publish_document',
                        index_state: 'IND',
                    },
                    {
                        action: 'delind',
                        title: 'Remove Index',
                        perm: 'content.publish_document',
                        index_state: 'ERR',
                    },
                    {
                        action: 'index',
                        title: 'Fix Index',
                        perm: 'content.publish_document',
                        index_state: 'ERR',
                    },
                    {
                        action: 'delete_failed',
                        title: 'Delete failed',
                        perm: 'content.delete_failed_document',
                        index_state: 'ERR',
                        show_on_failed: true,
                    },
                ],
            });
            this.otherTableView.on('action', this.action, this);

            this.deletedTableView = new DocumentTableView({
                tableTitle: 'Deleted',
                actions: [
                    {
                        action: 'delind',
                        title: 'Remove Index',
                        perm: 'content.publish_document',
                        index_state: 'ERR',
                    },
                    {
                        action: 'dismiss',
                        title: 'Dismiss',
                        perm: 'content.publish_document',
                        index_state: 'NOT',
                    },
                ],
            });
            this.deletedTableView.on('action', this.action, this);
        },
        processConversionNotes: function (raw_notes) {
            // N.B. This is relatively fragile - the note's line state is
            // gleaned from the string at the start
            var lines = raw_notes.split('/n');
            var notes = [];

            lines.forEach((line) => {
                let note = '<div>';
                let cut = 0;
                if (line.startsWith('WARNINGS:')) {
                    note = '<div class="text-warning"><strong>WARNING:</strong>';
                    cut = 'WARNING:'.length;
                } else if (line.startsWith('ERROR:')) {
                    note = '<div class="text-error"><strong>ERROR:</strong>';
                    cut = 'ERROR:'.length;
                }
                note += line.substr(cut);
                note += '</div>';
                notes.push(note);
            });

            return notes.join();
        },
        populateTables: function () {
            // generate the instance document notes html

            const gotInstances = this.model.get('instances');
            const instances = gotInstances.map((instance) => {
                instance['conversion_notes_html'] = this.processConversionNotes(
                    instance['conversion_notes'],
                );
                return { ...instance };
            });

            this.publishedTableView.collection = instances.filter((instance) => {
                if (instance.state === 'published') return instance;
            });
            this.readyTableView.collection = instances.filter((instance) => {
                if (instance.state === 'ready') return instance;
            });
            this.draftTableView.collection = instances.filter((instance) => {
                if (instance.state === 'draft') return instance;
            });
            this.deletedTableView.collection = instances.filter((instance) => {
                if (instance.state === 'archived') return instance;
            });

            var other = _.reject(this.model.get('instances'), function (instance) {
                return _.indexOf(['published', 'ready', 'draft', 'archived'], instance.state) > -1;
            });
            other = _.sortBy(other, function (instance) {
                return -app.moment(instance.uploaded_at).unix();
            });
            other = _.take(other, 20);
            this.otherTableView.collection = other;
        },
        renderTables: function () {
            this.populateTables();
            this.assign(this.publishedTableView, '#published-table');
            this.assign(this.readyTableView, '#ready-table');
            this.assign(this.draftTableView, '#draft-table');
            this.assign(this.otherTableView, '#other-table');
            this.assign(this.deletedTableView, '#deleted-table');
        },
        action: function (action, uuid) {
            this.model
                .workflowAction(action, uuid)
                .then(() => this.refresh())
                .catch((err) => console.error(err));
        },
        refresh: function () {
            window.clearTimeout(this._refreshTimout);
            this.model.fetch().done(
                _.bind(function () {
                    var processing = _.some(this.model.get('instances'), function (instance) {
                        return instance.state == 'processing';
                    });
                    if (processing) {
                        this.refreshInterval = 1000;
                    } else {
                        this.refreshInterval = 10000;
                    }
                    this._refreshTimout = window.setTimeout(
                        _.bind(this.refresh, this),
                        this.refreshInterval,
                    );
                }, this),
            );
        },
    });

    return {
        DocumentView: DocumentView,
    };
});
