import React from 'react';
import { arrayNotEmpty, stringCompareIgnoreSpace } from '../utils';

const VALID_POSTCODE_LENGTH = 10; //Normally 7, but allow some spaces

const getPostcodesForGroup = (user_groups) => {
    try {
        if (!arrayNotEmpty(user_groups)) return [];

        let all = [];

        for (const group of user_groups) {
            const postcodes = group.postcodes?.split(',') ?? [];
            all = [...all, ...postcodes];
        }

        return getPostcodes(all);
    } catch (error) {
        console.error(error);
        toaster.error(`Couldn't autofill postcodes`);
        return [];
    }
};

const getPostcodes = (allPostCodes) => {
    try {
        if (!arrayNotEmpty(allPostCodes)) return [];

        // Extract and de-dupe all postcodes from all selected groups. Comparison is case insensitive and ignores spaces
        let uniquePostcodes = [];
        for (const postcode of allPostCodes) {
            if (postcode && !uniquePostcodes.some((p) => stringCompareIgnoreSpace(p, postcode)))
                uniquePostcodes.push(postcode);
        }

        return uniquePostcodes
            .sort((a, b) => a.localeCompare(b))
            .map((p) => {
                const val = p.trim();
                return { value: val, label: val };
            });
    } catch (error) {
        console.error(error);
        toaster.error(`Couldn't autofill postcodes`);
        return [];
    }
};

const getUserGroups = (allGroups, organisationId) => {
    return (
        allGroups
            ?.filter((group) => group.org_id == organisationId)
            ?.sort((a, b) => a.name.localeCompare(b.name))
            ?.map((g) => {
                return {
                    value: g.id,
                    label: g.name,
                    isDefault: g.is_organisation_default,
                    postcodes: g.postcodes,
                    domains: g.domains,
                    preAddAlert: g.alert_on_add_user,
                };
            }) ?? []
    );
};

const getOrganisationsList = (organisations) => {
    if (!app.user.has_perm('main.view_all_organisations')) return [];

    const coalesce = (org) =>
        org.order_tag && org.order_tag?.trim() != '' ? org.order_tag : org.name;

    return (
        organisations
            ?.sort((a, b) => coalesce(a).localeCompare(coalesce(b)))
            .map((o) => {
                return {
                    value: o.id,
                    label: o.name,
                };
            }) ?? []
    );
};

const getDefaultUserGroup = (orgId, metadata) => {
    return getUserGroups(metadata?.subscription_groups, orgId)?.filter((g) => g.isDefault);
};

const getPreAlerts = (userGroups) => {
    return userGroups.reduce((acc, group) => {
        if (group.preAddAlert) acc.push({ label: group.label, preAlert: group.preAddAlert });
        return acc;
    }, []);
};

const getUserGroupAlertMessage = (isMultiple, preAlerts) => (
    <div>
        <p className="user-group-warning-title">{`The following user group${
            isMultiple ? 's' : ''
        } ${isMultiple ? 'have' : 'has'} an alert on adding a user:`}</p>
        <ul>
            {preAlerts.map((alert) => (
                <li key={alert.label} className="user-group-alert">
                    <p className="user-group-name">{alert.label}</p>
                    <p
                        className="user-group-alert-text"
                        dangerouslySetInnerHTML={{ __html: alert.preAlert }}
                    ></p>
                </li>
            ))}
        </ul>
    </div>
);

export {
    getPostcodesForGroup,
    getPostcodes,
    getUserGroups,
    getOrganisationsList,
    getDefaultUserGroup,
    getPreAlerts,
    getUserGroupAlertMessage,
    VALID_POSTCODE_LENGTH,
};
