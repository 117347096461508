module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="empty-results">\n    <h2>No Results Found</h2>\n    <p>We couldn\'t find "<strong>'+
((__t=(query.q ))==null?'':_.escape(__t))+
'</strong>"\n    ';
 if(query.tab == 'current') { 
__p+='\n        in the document you selected\n    ';
 } else if(query.tab == 'collection') { 
__p+='\n        in the families you selected\n    ';
 } else if(query.tab == 'recent' || query.tab == 'within') { 
__p+='\n        in the documents you selected\n    ';
 } 
__p+='\n    </p>\n    <p>Suggestions:</p>\n    <ul>\n        <li>Ensure all the words were spelled correctly.</li>\n        <li>Try an alternative search term.</li>\n        ';
 if(query.mode == 'exact') { 
__p+='\n            <li>Select \'Any of the terms\' or \'All terms\' rather than \'Exact phrase\'.</li>\n        ';
 } 
__p+='\n        ';
 if(query.date != 'all') { 
__p+='\n            <li>Increase the date range, or search across \'All time\'.</li>\n        ';
 } 
__p+='\n        ';
 if(query.tab == 'collection') { 
__p+='\n            <li>Select more families.</li>\n        ';
 } 
__p+='\n        ';
 if(query.tab == 'recent' || query.tab == 'within') { 
__p+='\n            <li>Select more documents.</li>\n        ';
 } 
__p+='\n    </ul>\n</div>\n';
}
return __p;
};
