import React from 'react';
import PropTypes from 'prop-types';

import models from '../../../models';

import { TableContextWrapper } from '../../FormControls/Table/TableContext';
import { useTableReducer } from '../../customHooks';
import AnnotationList from './AnnotationList';
import AnnotationFilter from './AnnotationFilter';
import { initialTableState } from '../../FormControls/Table/utils';

const AnnotationManager = ({ documentID, families, docFamilies }) => {
    const defaultTableState = {
        ...initialTableState,
        filter: {
            doc_id: documentID,
        },
    };

    const [tableState, dispatchTableState] = useTableReducer({
        defaultTableState,
    });

    const annosModel = new models.Annotations({});

    return (
        <>
            <h1 className="sr-only">Annotations</h1>
            <TableContextWrapper
                {...{
                    tableState,
                    dispatchTableState,
                    options: {
                        selectable: false,
                        sortable: true,
                        pageable: true,
                        hidePagerOnLowRows: true,
                    },
                    model: annosModel,
                }}
            >
                <div className="anno-table">
                    <AnnotationFilter model={annosModel} documentID={documentID} />
                    <AnnotationList
                        model={annosModel}
                        families={families}
                        docFamilies={docFamilies}
                    />
                </div>
            </TableContextWrapper>
        </>
    );
};

export default AnnotationManager;

AnnotationManager.propTypes = {
    documentID: PropTypes.string,
    families: PropTypes.array.isRequired,
    docFamilies: PropTypes.array,
};
