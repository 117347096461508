define(['backbone', './helpers', '../models', '../templates/prefs/prefs.html'], function (
    Backbone,
    helpers,
    models,
    prefsTpl,
) {
    var DisplayPreferencesView = helpers.BaseView.extend({
        el: 'body',
        cssPrefsTemplate: prefsTpl,
        events: {
            'click .font-prefs input[type="radio"]': 'changeFont',
            'click .size-prefs button': 'changeSize',
            'click .linespacing-prefs button': 'changeSpacing',
            'click .bg-prefs input[type="radio"]': 'changeBackground',
            'click .darkmode-prefs input[type="radio"]': 'changeDarkmode',
            'click .linelength-prefs input[type="radio"]': 'changeLineLength',
            'click .help-prefs input[type="radio"]': 'changeHelpPrefs',
            'click .tod-prefs input[type="radio"]': 'changeToDPrefs',
            'click #discardChanges': 'discardChanges',
            'click #saveSettings': 'submitForm',
        },
        initialize: function () {
            this.minTextSize = 13;
            this.maxTextSize = 72;
            this.displayPrefs = $.extend(
                {
                    font: 'sans-serif',
                    text_size: 15,
                    line_spacing: 1.4,
                    dark_mode: true,
                    linelength: true,
                    contextual_help: true,
                    tod_homepage: true,
                },
                app.user.attributes.display_prefs,
            );
            if (
                document.documentElement.getAttribute('data-theme') === 'dark' &&
                app.user.attributes.display_prefs.background_colour === 'sepia'
            ) {
                this.displayPrefs.background_colour = 'dark';
            }
            this.updatePreview();
            this.setData();
        },
        changeFont: function () {
            if ($('#prefs-font-serif').prop('checked')) {
                this.displayPrefs.font = 'serif';
            } else if ($('#prefs-font-sans-serif').prop('checked')) {
                this.displayPrefs.font = 'sans-serif';
            }
            this.updatePreview();
        },
        validateSize: function () {
            if (this.displayPrefs.text_size < this.minTextSize) {
                this.displayPrefs.text_size = this.minTextSize;
            } else if (this.displayPrefs.text_size > this.maxTextSize) {
                this.displayPrefs.text_size = this.maxTextSize;
            }
        },
        changeSize: function (evt) {
            evt.preventDefault();

            if (evt.currentTarget.id == 'prefs-size-smaller') {
                this.displayPrefs.text_size = this.displayPrefs.text_size - 1;
            } else if (evt.currentTarget.id == 'prefs-size-bigger') {
                this.displayPrefs.text_size = this.displayPrefs.text_size + 1;
            } else {
                this.displayPrefs.text_size = 15;
            }
            this.validateSize();
            this.updatePreview();
        },
        changeSpacing: function (evt) {
            evt.preventDefault();

            if (evt.currentTarget.id == 'prefs-spacing-compact') {
                this.displayPrefs.line_spacing = 'compact';
            } else if (evt.currentTarget.id == 'prefs-spacing-spacious') {
                this.displayPrefs.line_spacing = 'spacious';
            } else {
                this.displayPrefs.line_spacing = 'default';
            }
            this.updatePreview();
        },
        changeBackground: function () {
            if ($('#prefs-bg-sepia').prop('checked')) {
                this.displayPrefs.background_colour = 'sepia';
            } else {
                this.displayPrefs.background_colour = 'light';
            }
            this.updatePreview();
        },
        changeLineLength: function () {
            this.displayPrefs.linelength = $('#prefs-linelength-limit').prop('checked')
                ? true
                : false;
            this.updatePreview();
        },
        changeHelpPrefs: function () {
            this.displayPrefs.contextual_help = $('#prefs-help-examples').prop('checked')
                ? true
                : false;
        },
        changeToDPrefs: function () {
            this.displayPrefs.tod_homepage = $('#prefs-tod-todhome').prop('checked') ? true : false;
        },
        updatePreview: function () {
            // Build stylesheet
            var html = this.cssPrefsTemplate(this.displayPrefs);

            if ($('#display-prefs-styles').length) {
                $('#display-prefs-styles').html(html);
            } else {
                // Add the stylesheet to the head
                $('head').append(
                    '<style id="display-prefs-styles" type="text/css">' + html + '</style>',
                );
            }

            // Remove previous background classes and add the new one
            $('body').removeClass('bg-dark bg-sepia');

            if (this.displayPrefs.background_colour == 'sepia') {
                $('body').addClass('bg-sepia');
            }
        },
        setData: function () {
            // Set the font
            switch (this.displayPrefs.font) {
                case 'serif':
                    $('#prefs-font-serif').prop('checked', true);
                    break;
                default:
                    $('#prefs-font-sans-serif').prop('checked', true);
                    break;
            }

            // Set background colour
            switch (this.displayPrefs.background_colour) {
                case 'dark':
                    $('#prefs-bg-dark').prop('checked', true);
                    break;
                case 'sepia':
                    $('#prefs-bg-sepia').prop('checked', true);
                    break;
                case 'light':
                default:
                    $('#prefs-bg-light').prop('checked', true);
                    break;
            }

            // Set dark mode
            if (
                this.displayPrefs.dark_mode === 'light' ||
                (this.displayPrefs.dark_mode === 'auto' &&
                    window.matchMedia('(prefers-color-scheme: light)').matches)
            ) {
                $('#prefs-bg-light').prop('disabled', false);
                $('#prefs-bg-sepia').prop('disabled', false);
            } else {
                $('#prefs-bg-light').prop('disabled', true);
                $('#prefs-bg-sepia').prop('disabled', true);
            }

            // Set underlining, line length, cntextual help and tod homepage
            this.displayPrefs.dark_mode
                ? $('#prefs-links-darkmode').prop('checked', true)
                : $('#prefs-links-nodarkmode').prop('checked', true);
            this.displayPrefs.linelength
                ? $('#prefs-linelength-limit').prop('checked', true)
                : $('#prefs-linelength-nolimit').prop('checked', true);
            this.displayPrefs.contextual_help
                ? $('#prefs-help-examples').prop('checked', true)
                : $('#prefs-help-noexamples').prop('checked', true);
            this.displayPrefs.tod_homepage
                ? $('#prefs-tod-todhome').prop('checked', true)
                : $('#prefs-tod-lasttodpage').prop('checked', true);
        },
        getData: function () {
            return this.displayPrefs;
        },
        discardChanges: function () {
            window.location.reload(false);
        },
        submitForm: async function (evt) {
            evt.preventDefault();

            const data = this.getData();

            const response = await new models.User().setPreferences(data);
            if (response.result) {
                messages.success(response.result);
            } else {
                messages.error('An error has occured the updating display preferences');
            }
        },
    });

    return {
        DisplayPreferencesView: DisplayPreferencesView,
    };
});
