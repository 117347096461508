import React, { useState } from 'react';
import PropTypes from 'prop-types';

import FormModal from '../../FormControls/FormModal';
import confirm from '../../FormControls/ConfirmModal/ConfirmModalService';
import PrivacyIcon, { PRIVACY } from '../Privacy';
import ViewAnnotationActions from './ViewAnnotationActions';
import { annotationPropTypes, getModalPosition } from '../utils';
import { getDateTime } from '../../utils';

export const ViewAnnotationModal = ({ show, hideModal, showEdit, item, setDirty, includeGoTo }) => {
    const [loading, setLoading] = useState(false);

    const submitForm = async () => {
        if (item.privacy === PRIVACY.PROJECT) {
            const position = getModalPosition();
            const confirmed = await confirm({
                title: `Edit annotation shared with ${item.project_title}`,
                message: `You are about to edit an annotation that has been shared with the project ${item.project_title}. Are you sure you want to continue?`,
                confirmText: 'Edit annotation',
                centered: false,
                position: position,
            });
            if (!confirmed) {
                return false;
            }
        }

        showEdit();
    };

    return (
        <FormModal
            {...{
                submitForm,
                loading,
                show,
                hideModal,
                title: `View annotations for ${item.ref_label || 'section'}`,
                size: 'sm',
                className: `white annotations ${item.ref_id}`,
                saveText: item.can_edit ? 'Edit' : null,
                cancelText: 'OK',
                extraButtons: (
                    <ViewAnnotationActions
                        {...{ item, hideModal, setLoading, setDirty, includeGoTo }}
                    />
                ),
                isMain: true,
            }}
        >
            {(item.title || item.text) && (
                <div className="grey-body">
                    <div className="h3 dd-privacy-mask">{item.title}</div>
                    {/* dangerouslySetInnerHTML - !!Make sure the text is ALWAYS sanitized in back-end to prevent XSS!! */}
                    <p
                        className="dd-privacy-mask"
                        dangerouslySetInnerHTML={{ __html: item.text }}
                    ></p>
                </div>
            )}
            <div className="semi-footer">
                <dl className="dl-horizontal">
                    <dt>Owner:</dt>
                    <dd className="dd-privacy-mask">
                        {item.owner_name}
                        {item.owner_removed ? ' [removed]' : ''}
                    </dd>
                    {item.reassigned_from ? (
                        <>
                            <dt>Reassigned from:</dt>
                            <dd className="dd-privacy-mask">{item.reassigned_from}</dd>
                        </>
                    ) : (
                        <></>
                    )}
                    <dt>Last modified:</dt>
                    <dd>
                        {getDateTime(item.last_modified)} by {item.modifier}
                    </dd>
                    <dt>Privacy:</dt>
                    <dd>
                        <PrivacyIcon
                            privacy={item.privacy}
                            longTitle={true}
                            groups={item.user_groups}
                            project={item.project_title}
                        />
                    </dd>
                </dl>
            </div>
        </FormModal>
    );
};

ViewAnnotationModal.propTypes = {
    item: PropTypes.shape(annotationPropTypes),
    show: PropTypes.bool.isRequired,
    hideModal: PropTypes.func.isRequired,
    showEdit: PropTypes.func.isRequired,
    setDirty: PropTypes.func.isRequired,
    includeGoTo: PropTypes.bool,
};
