/*
    Calendar widget based on Bootstrap tooltip
*/

define([
    '../helpers',
    '../../templates/document/calendar_widget.html',
    'bootstrap'
], function (helpers, calendarTpl) {

    /*

    */
    var CalendarView = helpers.TemplateView.extend({
        template: calendarTpl,
        events: {
            'click .close, .cancel': function(e){ this.widget.hide(e) },
            'click .set-view-date': 'setViewDate'
        },
        initialize: function(options) {
            this.listenTo(this, 'render', this.initDatepicker);
        },
        templateHelpers: function() {
            return {
                confirmDate: this.options.getViewDate().format(app.settings.dateFormat),
                todayDate: app.moment().format(app.settings.dateFormat)
            };
        },
        setViewDate: function(evt) {
            var viewDate = $(evt.currentTarget).find('strong.view-date').html();

            if($(evt.currentTarget).hasClass('set-confirm')) {
                viewDate = this.$('.date-confirm strong.view-date').html();
            }

            if(viewDate && this.initialDate.format(app.settings.dateFormat) !== viewDate) {
                this.options.calendar.updateViewDate(viewDate);
            }

            this.widget.hide(evt);
        },
        setConfirmDate: function(evt) {
            this.$('.date-confirm strong').html(evt.format());
        },
        initDatepicker: function() {
            this.initialDate = this.options.getViewDate();
            this.$('.calendar-inline')
            .datepicker({
                allowDeselection: false
            })
            .datepicker('setDate', this.initialDate.isValid() ? this.initialDate.toDate() : app.moment().toDate())
            .on('changeDate', _.bind(this.setConfirmDate, this))
        }
    });


    return CalendarView;

});