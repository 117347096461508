import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import PropTypes from 'prop-types';

import { WithLoadingOverlay } from '../WithLoading';

const SimpleTableControl = ({ doFetch, getTableColumns, headers, className, dataName }) => {
    const [state, setState] = useState({
        loading: true,
        results: [],
    });

    const fetchData = async () => {
        try {
            setState({ loading: true });
            const results = await doFetch();
            setState({ loading: false, results });
        } catch (error) {
            toaster.error(`Couldn't retrieve ${dataName ?? ''} data`);
            console.error(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const rows =
        state.results?.map((item) => {
            return { id: item.id, cols: getTableColumns(item) };
        }) ?? [];

    return (
        <div className={`simple-table data-table ${className}`}>
            <WithLoadingOverlay loading={state.loading}>
                <Table responsive>
                    <thead>
                        <tr>
                            {headers.map((header, index) => {
                                return <th key={index}>{header}</th>;
                            })}
                        </tr>
                    </thead>

                    <tbody>
                        {rows.length == 0 && (
                            <tr>
                                <td colSpan={headers.length}>- No matches found -</td>
                            </tr>
                        )}
                        {rows.map((row, i) => {
                            return (
                                <tr key={i}>
                                    {row.cols.map((column, j) => (
                                        <td key={j}>{column}</td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </WithLoadingOverlay>
        </div>
    );
};

export default SimpleTableControl;

SimpleTableControl.propTypes = {
    doFetch: PropTypes.func.isRequired,
    getTableColumns: PropTypes.func.isRequired,
    headers: PropTypes.arrayOf(PropTypes.string).isRequired,
    className: PropTypes.string,
    dataName: PropTypes.string,
};
