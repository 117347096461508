define([
    'bootbox',
    '../../models',
    '../helpers',
    '../document/main',
    './base_results',
    '../../templates/search/document_search_results.html',
    '../../templates/search/document_search_results_table.html',
    '../../templates/search/document_search_results_row.html',
    '../../templates/search/document_search_results_form.html',
], function (
    bootbox,
    models,
    helpers,
    documentViews,
    baseResultsViews,
    documentSearchResultsTpl,
    documentSearchResultsTableTpl,
    documentSearchResultsRowTpl,
    documentSearchResultsFormTpl,
) {
    const { pluralise } = require('../../extras/index');

    /*
        Document search results table row
    */
    var DocumentSearchResultsRow = baseResultsViews.BaseSearchResultsRow.extend({
        template: documentSearchResultsRowTpl,
        events: function () {
            return _.extend(baseResultsViews.BaseSearchResultsRow.prototype.events, {
                'click .show-document': 'showDocument',
                'click .show-abstract': 'showAbstract',
            });
        },
        context: function () {
            const modelObject = this.model.toJSON();
            return { ...modelObject, includeHits: this.data.q.length > 0 };
        },
        initialize: function () {
            // getting the search params from the URL
            this.data = _.fromPairs([...new URLSearchParams(window.location.search)]);
        },
        showDocument: function (evt) {
            evt.preventDefault();
            evt.stopPropagation();

            if ($(evt.currentTarget).hasClass('new-tab')) {
                delete this.data['fam'];
                this.data['search'] = 'section';
                this.data['doc_id'] = this.model.get('doc_id');
                this.data['doc_instance'] = this.model.get('doc_instance');
                this.data['tab'] = 'current';
                this.data['view'] = 'document';
                this.data['show_doc'] = 'true';

                if (this.data['doc_id'] === 'reg-timeline') {
                    let docUrl;
                    if (this.model.get('doc_state') === 'published') {
                        docUrl = `/timeline`;
                    } else {
                        docUrl = `/timeline/${this.data['doc_instance']}`;
                    }
                    window.manager.open(docUrl);
                } else {
                    const docUrl = `${app.urls.search}?${$.param(this.data, true)}`;
                    window.manager.open(docUrl);
                }
            } else {
                if (this.model.get('doc_id') === 'reg-timeline') {
                    let docUrl;
                    if (this.model.get('doc_state') === 'published') {
                        docUrl = `/timeline`;
                    } else {
                        docUrl = `/timeline/${this.data['doc_instance']}`;
                    }
                    window.open(docUrl, '_self');
                } else {
                    this.$el.addClass('active').siblings('tr').removeClass('active');
                    this.trigger('result:selected', { showDocument: true });
                }
            }
        },
        showAbstract: function (evt) {
            evt.preventDefault();
            evt.stopPropagation();

            var pane = $(evt.currentTarget).closest('td.title').find('.mini-previewpane');

            $('.mini-previewpane').not(pane).slideUp();

            pane.slideToggle();
        },
    });

    /*
        Document search results table
    */
    var DocumentSearchResultsTable = baseResultsViews.BaseSearchResultsTable.extend({
        template: documentSearchResultsTableTpl,
        itemView: DocumentSearchResultsRow,
        events: {
            'click thead th.sortable': 'sortColumn',
        },
        initialize: function (options) {
            baseResultsViews.BaseSearchResultsTable.prototype.initialize.apply(this, arguments);

            if (app.user.has_perm('content.view_all_document_states')) {
                this.blankRowStr =
                    '<tr class="no-print"><td colspan="7" class="loading">Retrieving result, please wait...</td></tr>';
            } else {
                this.blankRowStr =
                    '<tr class="no-print"><td colspan="5" class="loading">Retrieving result, please wait...</td></tr>';
            }
            this.queryParams = options.queryParams;
            this.numFound = window.documentSearchResultsPreFill.collectionData.numFound;
            this.collectionLoaded = this.collection.length == this.numFound;
            this.listenTo(this.collection, 'sync', function (collection) {
                this.collectionLoaded =
                    collection.length ==
                    window.documentSearchResultsPreFill.collectionData.numFound;
                if (this.collectionLoaded) {
                    this.unsetPopovers();
                    // don't blow away incrementally rendered results
                    //this.render();
                }
            });
            this.listenTo(this, 'render', this.markSortColumn);

            if (!this.collectionLoaded) {
                this.once('render', this.setPopovers, this);
            }
        },
        templateHelpers: function () {
            return {
                includeHits: this.queryParams.q.length > 0,
            };
        },
        setPopovers: function () {
            this.$('th.sort-client').popover({
                placement: 'auto top',
                title: 'Please wait...',
                container: 'body',
                trigger: 'hover',
                delay: { show: 0, hide: 0 },
                content:
                    'Column sort on hits and extracts is only available when all results have been returned',
            });
        },
        unsetPopovers: function () {
            this.$('th.sort-client').popover('hide').popover('destroy');
        },
        markSortColumn: function () {
            var sortClass = this.collection.reverseSort ? 'picon-arrow-down' : 'picon-arrow-up';

            this.$('th.sortable')
                .find('span.picon')
                .removeClass('picon-arrow-up, picon-arrow-down');

            this.$('[data-sort_field="' + this.collection.sortField + '"]')
                .data('reverse_sort', !this.collection.reverseSort)
                .find('span.picon')
                .addClass(sortClass);
        },
        sortColumn: function (evt) {
            var th = $(evt.currentTarget);

            this.queryParams['reverse_sort'] = th.data('reverse_sort');
            this.queryParams['sort_field'] = th.data('sort_field');

            var newUrl = window.location.pathname + '?' + $.param(this.queryParams, true);

            if (this.collectionLoaded) {
                this.collection.reverseSort = this.queryParams['reverse_sort'];
                this.collection.sortField = this.queryParams['sort_field'];
                this.collection.sort();
                this.render();

                app.router.navigate(newUrl, { replace: true });
            } else if (!th.hasClass('sort-client')) {
                document.location.href = newUrl;
            }
        },
    });

    /*
        Documents search form view
    */
    var DocumentResultsFormView = baseResultsViews.BaseResultsFormView.extend({
        template: documentSearchResultsFormTpl,
        events: {
            'click .show-search-within, #close-search-within': 'toggleSearchWithin',
            'click .show-search-options, #close-search-options': 'toggleSearchOptions',
            'click #searchDocuments': 'submitSearch',
            'click .insert-today': 'insertToday',
            'click .btn-datepicker': 'showPicker',
            'focus .search-date .form-control': 'toggleDateRange',
            'hide .search-date': 'hideDatePicker',
            'focus .search-how': 'toggleSearchMode',
        },
        initialize: function (options) {
            this.queryParams = options.queryParams;
            this.previousCount = options.previousCount;
            this.previousQuery = options.previousQuery;
            this.previousFilter = options.previousFilter;
            this.previousField = options.previousField;

            this.hasEmptyResult = options.hasEmptyResult;

            this.listenTo(this, 'render', this.updateFormattedScope);
            this.listenTo(this, 'render', this.setWidgets);
        },
        formattedScope: function () {
            // Format the document/families(collection) scope
            // Bit cludgey, but we need to fetch the families and get their
            // titles if it's a collection search, so it's easier for it all
            // to return a deferred

            var dfd = new jQuery.Deferred();
            var names = [];

            switch (this.queryParams.tab) {
                // this won't happen
                case 'current':
                    dfd.resolve(this.queryParams.doc_title);
                    break;

                case 'recent':
                    // Get the recent docs titles used in the search from the doc_ids
                    var recentDocuments = new models.RecentDocuments();
                    recentDocuments.fetch().done(
                        _.bind(function () {
                            _.each(this.queryParams.docs, function (id) {
                                var doc = recentDocuments.find(function (rd) {
                                    return rd.get('document').doc_id == id;
                                });
                                if (doc) {
                                    names.push(doc.get('document').title);
                                }
                            });
                            dfd.resolve(names.join(', '));
                        }, this),
                    );

                    break;

                case 'collection':
                    // Get the collection/families from the slugs
                    var families = new models.Families();
                    families.fetch().done(
                        _.bind(function () {
                            // collections are tree like
                            function _recurseForName(slug, model, names) {
                                if (model.get('slug') == slug) {
                                    names.push(model.get('title'));
                                }
                                _.each(model.children.models, function (child) {
                                    _recurseForName(slug, child, names);
                                });
                            }

                            _.each(this.queryParams.fam, function (slug) {
                                families.each(function (model) {
                                    _recurseForName(slug, model, names);
                                });
                            });

                            dfd.resolve(names.join(', '));
                        }, this),
                    );

                    break;

                case 'within':
                    // TODO: Maybe get the doc titles back from a xhr call.
                    // This could also be done to simplify getting recent documents
                    // titles.
                    dfd.resolve(this.queryParams.doc_titles);
                    break;
            }

            return dfd.promise();
        },
        updateFormattedScope: function () {
            // Update the element with the calculated string
            var document = {};
            if (this.hasEmptyResult) {
                if (this.queryParams.doc_id) {
                    // try and figure out the title of the document we're searching in
                    document = new models.Document({
                        doc_id: this.queryParams.doc_id,
                    });
                    document
                        .fetch()
                        .done(
                            _.bind(function () {
                                this.$('.previous-search-scope span.field').html(
                                    document.get('title'),
                                );
                            }, this),
                        )
                        .fail(function () {
                            debugger; // eslint-disable-line no-debugger
                        });
                } else if (this.queryParams.docs && this.queryParams.docs.length) {
                    // try and figure out the titles of the documents we're searching in
                    document = new models.Document({
                        doc_id: this.queryParams.docs[0],
                    });
                    document.getTitles(this.queryParams.docs).done(
                        _.bind(function (data) {
                            this.$('.previous-search-scope span.field').html(
                                _.values(data).join(', '),
                            );
                        }, this),
                    );
                } else if (this.queryParams.fam && this.queryParams.fam.length) {
                    // try and figure out the titles of the families we're searching in
                    var family = new models.Family({
                        slug: this.queryParams.fam[0],
                    });
                    family.getTitles(this.queryParams.fam).done(
                        _.bind(function (data) {
                            this.$('.previous-search-scope span.field').html(
                                _.values(data).join(', '),
                            );
                        }, this),
                    );
                } else {
                    this.$('.previous-search-scope span.field').html('The selected document(s)');
                }
            } else {
                $.when(this.formattedScope()).then(
                    _.bind(function (s) {
                        this.$('.previous-search-scope span.field').html(s);
                    }, this),
                );
            }
        },
        setWidgets: function () {
            // datepickers
            $('#daterange-from-within')
                .datepicker({
                    autoclose: true,
                })
                .on('show', function () {
                    $('.datepicker').css({ 'z-index': 100000000 });
                });

            $('#daterange-to-within')
                .datepicker({
                    autoclose: true,
                })
                .on('show', function () {
                    $('.datepicker').css({ 'z-index': 100000000 });
                });
        },
        toggleSearchMode: function (evt) {
            $(evt.currentTarget)
                .parents('.radio')
                .find('input[name="searchMode"]')
                .prop('checked', true);
        },
        toggleDateRange: function (evt) {
            $(evt.currentTarget)
                .parents('.radio')
                .find('input[name="searchDate"]')
                .prop('checked', true);
        },
        insertToday: function (evt) {
            evt.preventDefault();
            $(evt.currentTarget)
                .siblings('.form-control')
                .datepicker('update', app.moment().format(app.settings.dateFormat));
            $(evt.currentTarget).blur();
            $('#searchDate-range-within').prop('checked', true);
        },
        validateProximity: function (evt) {
            evt.preventDefault();

            var currentValue = $(evt.currentTarget).val();

            var intValue = parseInt(currentValue);

            if (isNaN(intValue)) {
                intValue = app.settings['proximityDefault'];
            } else if (intValue < app.settings['proximityLowerBound']) {
                intValue = app.settings['proximityLowerBound'];
            } else if (intValue > app.settings['proximityUpperBound']) {
                intValue = app.settings['proximityUpperBound'];
            }

            if (currentValue != intValue.toString()) {
                $(evt.currentTarget).val(intValue);
            }
        },
        toggleSearchWithin: function (evt) {
            evt.preventDefault();
            $(evt.currentTarget).closest('.search-body').toggleClass('with-searchwithin');
            $('#show-search-within').toggleClass('active');
            $('#search-within').toggle();

            if ($('#show-search-within').hasClass('active')) {
                $('#search-within').attr('aria-hidden', false);
            } else {
                $('#search-within').attr('aria-hidden', true);

                // Close the lower search options as well if
                // they are currently shown.
                if ($('#show-search-options').hasClass('active')) {
                    this.toggleSearchOptions(evt);
                }
            }
        },
        toggleSearchOptions: function (evt) {
            evt.preventDefault();
            $(evt.currentTarget).closest('.search-body').toggleClass('with-searchoptions');
            $('#show-search-options').toggleClass('active');
            $('#search-within-options').toggle();

            if ($('#show-search-options').hasClass('active')) {
                $('#search-within-options').attr('aria-hidden', false);
            } else {
                $('#search-within-options').attr('aria-hidden', true);
            }
        },
        _toISODate: function (dateStr) {
            return (
                app.moment(dateStr, [app.settings.dateFormat]).format('YYYY-MM-DDTHH:mm:ss') + 'Z'
            );
        },
        _fromISODate: function (dateStr) {
            return app.moment(dateStr).format(app.settings.dateFormat);
        },
        _getData: function () {
            this.errorMsg = new Array();

            var data = {};

            data['search'] = 'document';
            data['tab'] = 'within';

            // Keep the state from the previous query
            data['state'] = this.queryParams.state;
            data['fam'] = this.queryParams.fam;

            data['q'] = this.$('#searchWithinQuery').val();

            // Create list of previous queries
            var numQueries = 0;

            if (this.queryParams.pq) {
                data['pq'] = _.isArray(this.queryParams.pq)
                    ? this.queryParams.pq
                    : [this.queryParams.pq];
                data['count'] = _.isArray(this.queryParams.count)
                    ? this.queryParams.count
                    : [this.queryParams.count];
                data['pfq'] = _.isArray(this.queryParams.pfq)
                    ? this.queryParams.pfq
                    : [this.queryParams.pfq];
                data['pqf'] = _.isArray(this.queryParams.pqf)
                    ? this.queryParams.pqf
                    : [this.queryParams.pqf];
                numQueries = data['pq'].length;
            } else {
                data['pq'] = new Array();
                data['count'] = new Array();
                data['pfq'] = new Array();
                data['pqf'] = new Array();
            }

            // add back the last query details
            data['pq'].push(numQueries + '~' + this.previousQuery);
            data['count'].push(numQueries + '~' + this.previousCount);
            data['pqf'].push(numQueries + '~' + this.previousField);
            data['pfq'] = data['pfq'].concat(
                _.map(this.previousFilter, function (item) {
                    return numQueries + '~' + item;
                }),
            );

            if (!data['q']) {
                if (data['date'] === 'all') {
                    this.errorMsg.push('Please add one or more search terms.');
                }
            }

            data['mode'] = this.$('input[name="searchMode"]:checked').val();
            data['scope'] = this.$('input[name="searchScope"]:checked').val();
            data['date'] = this.$('input[name="searchDate"]:checked').val();

            // Set the search within word proximity if all terms search
            if (data['mode'] == 'all') {
                data['proximity'] = this.$('input[name="searchProximity"]').val();
            }

            // Date range/period specifics
            switch (data['date']) {
                case 'range':
                    data['date_from'] = app.moment(
                        this.$('#daterange-from-within').val(),
                        app.settings.dateFormat,
                    );
                    data['date_to'] = app.moment(
                        this.$('#daterange-to-within').val(),
                        app.settings.dateFormat,
                    );

                    if (!data['date_from'].isValid() || !data['date_to'].isValid()) {
                        this.errorMsg.push('Invalid or missing date(s).');
                    }

                    if (data['date_from'].isAfter(data['date_to'])) {
                        this.errorMsg.push('From date must be before To date.');
                    }

                    data['date_from'] = this._toISODate(data['date_from']);
                    data['date_to'] = this._toISODate(data['date_to']);

                    break;
                case 'period':
                    data['date_last_value'] = this.$('#searchDate-last-value-within').val();
                    data['date_last_units'] = this.$(
                        '#searchDate-last-units-within option:selected',
                    ).val();
                    break;
            }
            return data;
        },
        submitSearch: function (evt) {
            evt.preventDefault();

            var data = this._getData();

            if (this.errorMsg.length) {
                this.$el.modal('hide');

                // Format the error messages as a list
                let message = '<ul>';
                for (var i in this.errorMsg) {
                    message += '<li>' + this.errorMsg[i] + '</li>';
                }
                message += '</ul>';

                bootbox.dialog({
                    title: 'Search Error',
                    message: message,
                    buttons: {
                        ok: {
                            label: 'OK',
                            className: 'btn-primary',
                        },
                    },
                });
            } else {
                document.location.href = '/documents/search?' + $.param(data, true);
            }
        },
    });

    /*
        Document search results
    */
    var DocumentSearchResultsView = baseResultsViews.BaseSearchResultsView.extend({
        el: '#results',
        className: 'pan-top pan-searchresults',
        template: documentSearchResultsTpl,
        templateHelpers: function () {
            return {
                collectionData: this.searchResults.collectionData,
            };
        },
        initialize: function (options) {
            app.trigger('document:ready');
            this.queryParams = options.queryParams;
            this.documentRowIdx = options.documentRowIdx;
            this.searchResults = options.searchResults;
            this.numFound = window.documentSearchResultsPreFill.collectionData['numFound'];

            // set window title
            document.title = 'Search - Perspective';
        },
        render: function () {
            baseResultsViews.BaseSearchResultsView.prototype.render.apply(this, arguments);

            this.formView = new DocumentResultsFormView({
                queryParams: this.queryParams,
                previousCount: this.numFound,
                previousQuery: this.searchResults.collectionData['lq'],
                previousFilter: this.searchResults.collectionData['last_fq'],
                previousField: this.searchResults.collectionData['last_qf'],
                hasEmptyResult: this.hasEmptyResult(),
            });
            this.assign(this.formView, '.search-details');

            if (this.numFound > 0) {
                this.renderDocumentResultsTable();
            } else {
                this.handleNoResults();
            }
        },
        renderDocumentResultsTable: function () {
            // notify that there are search results
            app.trigger('search:results:document');
            this.$('.back-to-full-results').hide();
            this.resultsTable = new DocumentSearchResultsTable({
                queryParams: this.queryParams,
                collection: this.searchResults,
            });

            // scroll to document row if we've come from section results
            if (this.rowIndex) {
                // listen in on the rows being created
                this.listenTo(this.resultsTable, 'render', function () {
                    this.resultsTable.$el
                        .find('tbody tr:eq(' + this.rowIndex + ')')
                        .addClass('active')
                        .get(0)
                        .scrollIntoView(true);
                });
            }
            // row clicked, navigate to Section Results
            this.listenTo(this.resultsTable, 'result:selected', function (view, args) {
                this.rowIndex = this.$('.results-table tbody tr').index(view.$el);

                if (this.queryParams.q) {
                    this.trigger('result:selected', view.model, args['showDocument']);
                } else {
                    this.trigger('result:selected', view.model, { showDocument: true });
                }
            });
            this.assign(this.resultsTable, '.search-results');
        },
    });

    /*
        Global body view for document results, drilling down to section results and a highlighted document
    */
    var SearchResultsView = helpers.BaseView.extend({
        el: 'body',
        _rows: window.SOLR_DEFAULT_RESULT_ROWS,
        _start: window.SOLR_DEFAULT_RESULT_ROWS,
        events: { 'click .back-to-full-results': 'renderDocumentResults' },
        retryWait: 30000,
        retryCount: 0,
        retryLimit: 5,
        initialize: function (options) {
            this.searchResults = options.searchResults;

            var urlParams = new URLSearchParams(window.location.search);
            this.queryParams = _.fromPairs([...urlParams]);
            this.queryParams.q = this.queryParams.q.replace(/%2F/g, '/');

            this.queryParams.docs = urlParams.getAll('docs');
            this.queryParams.fam = urlParams.getAll('fam');
            this.queryParams.pq = urlParams.getAll('pq');
            this.queryParams.count = urlParams.getAll('count');
            this.queryParams.pfq = urlParams.getAll('pfq');
            this.queryParams.pqf = urlParams.getAll('pqf');

            this.numFound = this.searchResults.collectionData.numFound;
            this.searchResults.qs.ngroups = false;
            this.renderDocumentResults();
            this.updateDocumentResults();

            this.listenTo(this.searchResults, 'sync', this.updateWaitMessage);
            this.listenTo(app, 'search:skip-section-results', this.renderDocumentResults);
        },
        renderDocumentResults: function () {
            this.closeSectionResults();

            if (!this.resultsView) {
                this.resultsView = new DocumentSearchResultsView({
                    searchResults: this.searchResults,
                    queryParams: this.queryParams,
                    documentRowIdx: this.documentRowIndex,
                });
                this.listenTo(this.resultsView, 'result:selected', this.renderSectionResults);
            } else {
                var newUrl = window.location.pathname + '?' + $.param(this.queryParams, true);
                app.router.navigate(newUrl, { replace: true });
                this.resultsView.resultsTable.close();
            }

            this.assign(this.resultsView, '#results');
            this.updateWaitMessage();
        },
        updateDocumentResults: function () {
            // Recursively get the next set of results until there are none left
            if (this._start < this.numFound) {
                this.searchResults.qs['start'] = this._start;
                if (!_.isEmpty(window.documentSearchResultsPreFill.collectionData)) {
                    this.searchResults.qs['fids'] =
                        window.documentSearchResultsPreFill.collectionData.refine_ids.slice(
                            this._start,
                            Math.min(
                                window.documentSearchResultsPreFill.collectionData.refine_ids
                                    .length + 1, // eslint-disable-line
                                this._start + this._rows,
                            ),
                        );
                    if (window.documentSearchResultsPreFill.collectionData.refine_ids.length > 0) {
                        this.searchResults.qs['flength'] =
                            window.documentSearchResultsPreFill.collectionData.refine_ids.length;
                    }
                }

                this.searchResults
                    .fetch({
                        data: this.searchResults.qs,
                        processData: true,
                        traditional: true,
                        remove: false,
                    })
                    .done(
                        _.bind(function () {
                            // delay from animation frame to smooth out scrolling on large datasets
                            _.delay(
                                _.bind(function () {
                                    this.updateDocumentResults();
                                    // increment
                                    this._start += this._rows;
                                }, this),
                            );

                            // reset retries
                            this.retryCount = 0;
                        }, this),
                    )
                    .fail(
                        _.bind(function (xhr) {
                            if (xhr.status === 503 && this.retryCount < this.retryLimit) {
                                this.retryCount++;
                                if (this.retryCount === this.retryLimit) {
                                    toaster.error(
                                        'Could not retrieve search results. Please try again in a few moments.',
                                    );
                                } else {
                                    toaster.error(
                                        `Could not retrieve search results. Retrying in ${
                                            this.retryWait / 1000
                                        } seconds...`,
                                        { ttl: this.retryWait },
                                    );
                                    _.delay(
                                        _.bind(this.updateDocumentResults, this),
                                        this.retryWait,
                                    );
                                }
                            }
                        }, this),
                    );
            }
        },
        updateWaitMessage: function () {
            if (!app.documentView) {
                if (this._start >= this.numFound) {
                    this.$('.found').html(
                        this.numFound +
                            ' ' +
                            pluralise(this.numFound, 'document', 'documents') +
                            ' found',
                    );
                } else {
                    this.$('.found').html(
                        'Retrieving document ' + this._start + ' of ' + this.numFound,
                    );
                }
            }
        },
        closeSectionResults: function () {
            if (app.documentView) {
                // nuke from orbit
                app.documentView.closeSubViews();
                app.documentView.stopListening();
                app.documentView.searchResults.remove(app.documentView.searchResults.models);
                app.documentView = null;
                delete app.documentView;
            }
        },
        renderSectionResults: async function (docModel, showDocument) {
            // This is for the showDocument button on the Document Results list
            if (this.resultsView) {
                this.resultsView.resultsTable.close();
            }

            const docId = docModel.get('doc_id');

            var sectionParams = _.extend({}, this.queryParams, {
                search: 'section',
                doc_id: docId,
                doc_state: docModel.get('doc_state'),
            });
            sectionParams = _.omit(sectionParams, ['fam', 'docs', 'sort_field', 'reverse_sort']);

            var doc = new models.Document({
                doc_id: docId,
                activeInstance: docModel.get('doc_instance'),
            });
            var annotations = new models.Annotations({ document: docId });
            const favourites = new models.Favourites({ document: docId });

            await annotations.fetch();
            await favourites.fetchByDoc();

            if (docId.includes('timeline')) {
                // We need to add the documentSearchResultsPreFill to localStorage so that if we navigate back from timeline, we can get back to the full results list
                if (window.documentSearchResultsPreFill?.qs?.search === 'document') {
                    window.localStorage.setItem(
                        'documentSearchResults',
                        JSON.stringify(this.queryParams),
                    );
                }
            }

            doc.fetch()
                .then(
                    _.bind(function () {
                        var docInstance = doc.getInstance();

                        if (docInstance) {
                            app.documentView = new documentViews.DocumentView({
                                model: docInstance,
                                doc: doc,
                                queryParams: sectionParams,
                                annotations: annotations,
                                favourites: favourites,
                                view: showDocument ? 'document' : 'results',
                                source: 'docSearch',
                            });
                            app.documents = [doc];
                        } else {
                            app.controller.error404();
                        }
                    }, this),
                )
                .fail(_.bind(app.controller.errorHandler, app.controller));
        },
    });

    return SearchResultsView;
});
