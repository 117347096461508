import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import models from '../../models';

import { EditAnnotationModal } from '../Annotations/EditAnnotations/EditAnnotationModal';
import { AnnotationPopover } from './AnnotationPopover';
import { filterProjects } from './AnnotationPanel';

const initialIconClass = {
    anno: '',
    circle: '',
    text: '',
};

const DocumentAnnotation = ({ doc, sectionID, sectionLabel, inProject }) => {
    const annotations = doc.options.annotations;

    if (!annotations) {
        return null;
    }

    const [editVisible, setEditVisible] = useState({ show: false });
    const [annos, setAnnos] = useState(filterProjects(annotations.annoMap[sectionID], inProject));
    const [iconClass, setIconClass] = useState(initialIconClass);

    const annoModel = new models.Annotation({
        document: annotations.document,
        ref_id: sectionID,
        ref_label: sectionLabel,
        org: app.user.get('organisation'),
        can_edit: true,
    });

    //#region method shortcuts
    const showEditModal = (item) => {
        setEditVisible({ show: true, item });
    };

    const hideEditModal = () => {
        setEditVisible({ show: false });
    };

    //#endregion

    const setDirty = async () => {
        app.trigger('annotation:changed');
        const body = document.getElementsByTagName('body');
        $(body[0]).trigger('submit');
    };

    annotations.on('sync', () => {
        setAnnos(filterProjects(annotations.annoMap[sectionID], inProject));
    });

    const handleColours = (mode) => {
        switch (mode) {
            case 'dark':
                setIconClass({
                    anno: '#8b9aeb',
                    circle: '#FF9999',
                    text: '#000',
                });
                break;
            case 'light':
                setIconClass({
                    anno: '#0b008b',
                    circle: '#800100',
                    text: '#fff',
                });
                break;
            default:
                if (document.documentElement.getAttribute('data-theme') === 'dark') {
                    setIconClass({
                        anno: '#8b9aeb',
                        circle: '#FF9999',
                        text: '#000',
                    });
                } else {
                    setIconClass({
                        anno: '#0b008b',
                        circle: '#800100',
                        text: '#fff',
                    });
                }
                break;
        }
    };

    useEffect(() => {
        // set the annotation icon colours when we've arrived or when scrolling
        handleColours(app.user.attributes.display_prefs.dark_mode);
    }, []);

    useEffect(() => {
        const handleClick = (event) => {
            const darkOptions = $(event.target).parents('#toolbar-mode');
            if (darkOptions.length) {
                const $clicked = $(event.target);
                const option = $clicked.hasClass('label')
                    ? $clicked[0].textContent
                    : $clicked.find('.label')[0].textContent;

                handleColours(option.toLowerCase());
            }
        };

        const body = document.getElementsByTagName('body');
        body[0].addEventListener('click', handleClick);
        return () => body[0].removeEventListener('click', handleClick);
    }, []);

    useEffect(() => {
        const handleClick = (event) => {
            const darkOptions = $(event.target).parents('#toolbar-mode');
            if (darkOptions.length) {
                const $clicked = $(event.target);
                const option = $clicked.hasClass('label')
                    ? $clicked[0].textContent
                    : $clicked.find('.label')[0].textContent;

                handleColours(option.toLowerCase());
            }
        };

        const body = document.getElementsByTagName('body');
        body[0].addEventListener('click', handleClick);
        return () => body[0].removeEventListener('click', handleClick);
    }, []);

    const canAddAnnotation =
        app.user.has_perm('main.manage_annotations') ||
        app.user.attributes.user_groups.some((g) => g.can_add_annotations);

    return (
        <>
            {annos?.length ? (
                <>
                    <AnnotationPopover // on hover, an Add Annotation icon/popover will drop down from under the View annotation icon/button
                        {...{
                            title: 'Add annotation',
                            data: {
                                can_edit: true,
                                ref_label: sectionLabel,
                                model: annoModel,
                                isNew: true,
                            },
                            refID: sectionID,
                            sectionID: sectionID,
                            label: sectionLabel,
                            showModal: showEditModal,
                            number: 0,
                            annoClass: 'add-anno',
                        }}
                    />
                    <AnnotationPopover
                        {...{
                            title: `View annotation${annos.length > 1 ? 's' : ''}`,
                            refID: sectionID,
                            sectionID: sectionID,
                            label: sectionLabel,
                            number: annos.length, // should be the number of annos for the section
                            data: {
                                can_edit: true,
                                ref_label: sectionLabel,
                                model: annoModel,
                            },
                            showModal: () => {},
                            iconClass: iconClass,
                            annoClass: 'has-annos',
                        }}
                    />
                </>
            ) : (
                canAddAnnotation && (
                    <AnnotationPopover
                        {...{
                            title: 'Add annotation',
                            data: {
                                can_edit: true,
                                ref_label: sectionLabel,
                                model: annoModel,
                                isNew: true,
                            },
                            refID: sectionID,
                            sectionID: sectionID,
                            label: sectionLabel,
                            showModal: showEditModal,
                            number: 0,
                            annoClass: '',
                        }}
                    />
                )
            )}
            {editVisible.show && (
                <EditAnnotationModal
                    {...{
                        hideModal: hideEditModal,
                        item: editVisible.item,
                        show: editVisible.show,
                        model: annotations,
                        setDirty,
                        inProject,
                    }}
                />
            )}
        </>
    );
};

export default DocumentAnnotation;

DocumentAnnotation.propTypes = {
    doc: PropTypes.shape({
        options: PropTypes.shape({
            annotations: PropTypes.shape({
                annoMap: PropTypes.object,
                on: PropTypes.func,
                document: PropTypes.string,
            }),
            doc: PropTypes.shape({
                id: PropTypes.string.isRequired,
            }),
        }),
        once: PropTypes.func,
    }),
    sectionID: PropTypes.string,
    sectionLabel: PropTypes.string,
    inProject: PropTypes.arrayOf(PropTypes.number),
};
