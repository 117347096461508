import React from 'react';
import PropTypes from 'prop-types';

import PiconIcon from '../FormControls/Icons/PiconIcon';
import { useDownloadContext } from './DownloadContext';

const StateIcon = ({ item }) => {
    const { downloadState } = useDownloadContext();

    if (downloadState.completed.some((path) => path.includes(item))) {
        return <PiconIcon iconName="tick-circle" className="green" />;
    } else if (downloadState.failed.some((path) => path.includes(item))) {
        return <PiconIcon iconName="cross-circle" className="red" />;
    } else if (downloadState.warn.some((path) => path.includes(item))) {
        return (
            <PiconIcon
                iconName="warning-sign"
                className="red"
                title="Not all files successfully downloaded for this document"
            />
        );
    } else {
        return <span></span>;
    }
};

export default StateIcon;

StateIcon.propTypes = {
    item: PropTypes.string.isRequired,
};
