import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const DocSearchItem = ({
    doc,
    query,
    highlight,
    permission,
    index,
    handleClick,
    handleAuxClick,
}) => {
    const currentRef = useRef(null);

    useEffect(() => {
        // Without the index check, this would take focus away from the
        // input in SearchModal as soon as there's a search result. This
        // reassigns focus to allow scrolling into the overflow with arrow
        // keys once scrolling has started.
        if (highlight && index > 8) {
            currentRef.current.focus();
        }
    }, [highlight]);

    // show
    const highlightSearch = (str, highlightClass) => {
        const escapeRegExp = (string = '') => {
            return string.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
        };

        const regex = new RegExp(`${escapeRegExp(query)}`, 'gi');
        const originalCasing = str.match(regex);
        const parts = str.split(regex);
        let result;

        if (parts) {
            // Query match in the middle of the title/abbreviation
            if (parts.length === 2) {
                result = (
                    <span>
                        {parts[0]}
                        <span className={highlightClass}>{originalCasing[0]}</span>
                        {parts[1]}
                    </span>
                );
            } else {
                // Query match at the beginning of the title/abbreviation
                if (parts.length === 1 && str.search(regex) === 0) {
                    result = (
                        <span>
                            <span className={`${highlightClass}`}>{originalCasing[0]}</span>
                            {parts[1]}
                        </span>
                    );
                } else if (parts.length === 1) {
                    // Query doesn't match any part of title/abbreviation
                    result = <span>{parts[0]}</span>;
                } else {
                    // Query mtach in multiple locations of title/abbreviation
                    result = (
                        <span>
                            {' '}
                            {parts.map((part, index) => (
                                <span key={index}>
                                    {part}
                                    <span className={highlightClass}>{originalCasing[index]}</span>
                                </span>
                            ))}{' '}
                        </span>
                    );
                }
            }
        }
        return result;
    };

    return (
        <tr
            className="doc-search-item"
            title={doc.doc_title}
            id={highlight ? 'highlighted' : ''}
            ref={currentRef}
            tabIndex={-1}
            onClick={(event) => handleClick(event, doc.doc_id, doc.doc_instance)}
            onAuxClick={() => handleAuxClick(doc.doc_id, doc.doc_instance)}
            data-dd-action-name="Select Open Document Result"
        >
            <td className="doc-search-abbrev">
                {doc.doc_abbrv ? highlightSearch(doc.doc_abbrv.join(', '), 'matched-query') : ''}
            </td>
            <td className="doc-search-title" title={doc.doc_title}>
                {highlightSearch(doc.doc_title, 'matched-query')}
            </td>
            <td className="doc-search-fam">{doc.doc_primary_family_set_title}</td>
            {permission && <td className="doc-search-status">[{doc.doc_state}]</td>}
        </tr>
    );
};

export default DocSearchItem;

DocSearchItem.propTypes = {
    doc: PropTypes.object.isRequired,
    query: PropTypes.string.isRequired,
    highlight: PropTypes.bool.isRequired,
    permission: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
    handleClick: PropTypes.func,
    handleAuxClick: PropTypes.func,
};
