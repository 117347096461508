import React from 'react';
import PropTypes from 'prop-types';

import models from '../../models';
import ContentContainer from './ContentContainer';

const ContentManager = ({ contentType }) => {
    const contentModel = new models.UserSettingsContent();

    const doFetch = async () => {
        const content = await contentModel.retrieve(contentType);
        return {
            ...content,
            helpTextUrl: `/admincms_lite/usersettingscontent/${content.pk}/change/`,
        };
    };

    const doPost = async (data) => {
        const content = await contentModel.create(data);
        return {
            ...content,
            helpTextUrl: `/admincms_lite/usersettingscontent/${content.pk}/change/`,
        };
    };

    const canEdit =
        app.user.has_perm('cms_lite.add_usersettings_content') &&
        app.user.has_perm('cms_lite.change_usersettings_content');

    return <ContentContainer {...{ doFetch, doPost, contentType, canEdit }} />;
};

export default ContentManager;

ContentManager.propTypes = {
    contentType: PropTypes.string.isRequired,
};
