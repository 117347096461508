import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { NewFavouritePopover, EditFavouritePopover } from './FavouritePopover';
import EditFavouriteModal from '../Favourites/EditFavouriteModal';
import { documentPropTypes } from '../Favourites/utils';

const DocumentFavourite = ({ document, sectionID, sectionLabel, canDelete, inTimeline }) => {
    const favourites = document.options.favourites;

    if (!favourites) {
        return null;
    }

    const [editVisible, setEditVisible] = useState({ show: false });
    const [favourite, setFavourite] = useState(
        favourites.models.find((favourite) => favourite.attributes.ref_id === sectionID),
    );

    // #region method shortcuts
    const showEditModal = (item) => {
        setEditVisible({ show: true, item });
    };

    const hideEditModal = () => {
        setEditVisible({ show: false });
    };
    //#endregion

    const setDirty = async () => {
        if (inTimeline) {
            // if we're in timeline, we need to refetch favourites here
            await favourites.fetchByDoc();
        }
        app.trigger('favourite:changed');
    };

    favourites.on('sync', () => {
        setFavourite(
            favourites.models.find((favourite) => favourite.attributes.ref_id === sectionID),
        );
    });

    return (
        <>
            {favourite ? (
                <EditFavouritePopover
                    {...{
                        sectionID,
                        showModal: showEditModal,
                        model: favourite,
                        document,
                        sectionLabel,
                    }}
                />
            ) : (
                <NewFavouritePopover
                    {...{ sectionID, showModal: showEditModal, sectionLabel, document, inTimeline }}
                />
            )}
            {editVisible.show && (
                <EditFavouriteModal
                    {...{
                        hideModal: hideEditModal,
                        item: editVisible.item,
                        show: editVisible.show,
                        setDirty,
                        canDelete,
                        document,
                        inTimeline,
                    }}
                />
            )}
        </>
    );
};

export default DocumentFavourite;

DocumentFavourite.propTypes = {
    document: PropTypes.shape(documentPropTypes),
    sectionID: PropTypes.string,
    sectionLabel: PropTypes.string,
    canDelete: PropTypes.bool,
    inTimeline: PropTypes.object,
};
