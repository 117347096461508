import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ConditionalWrapper from '../ConditionalWrapper';
import SearchModal from '../Modals/SearchModal';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import PiconIcon from '../FormControls/Icons/PiconIcon';

const DocumentFinder = ({ compareDoc, handleSelection, target }) => {
    const [showModal, setModal] = useState(false);

    const popoverDocFinder = (
        <Popover id="popover-doc-finder" className="popover fade bottom in">
            <Popover.Title className="popover-title">Open Document</Popover.Title>
            <Popover.Content className="popover-content">
                Quickly find any document by title, abbreviation or from ones you&apos;ve recently
                opened
            </Popover.Content>
        </Popover>
    );

    const displayToolTip = app.user.get('display_prefs').contextual_help;

    return (
        <ConditionalWrapper
            condition={displayToolTip}
            wrapper={(children) => (
                <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="bottom"
                    overlay={popoverDocFinder}
                    rootClose={true}
                >
                    {children}
                </OverlayTrigger>
            )}
        >
            <div>
                <button
                    className="toolbar-item btn"
                    onClick={() => setModal(true)}
                    title="Quickly find a document"
                    role="menuitem"
                >
                    <span className="icon">
                        <PiconIcon className={`lg left top`} iconName="open" />
                    </span>
                    <span className="label">Open Document</span>
                </button>
                {showModal && (
                    <SearchModal
                        handleModal={() => setModal(false)}
                        show={showModal}
                        compareDoc={compareDoc}
                        handleSelection={handleSelection}
                        target={target}
                    />
                )}
            </div>
        </ConditionalWrapper>
    );
};

export default DocumentFinder;

DocumentFinder.defaultProps = {
    compareDoc: false,
    handleSelection: () => {},
    target: 'Document 2',
};

DocumentFinder.propTypes = {
    compareDoc: PropTypes.bool,
    handleSelection: PropTypes.func,
    target: PropTypes.string,
};
