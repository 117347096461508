module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<hr class="hidden"/>\n<div class="panetitle panetitle-main">\n    Document\n    <button class="change-compare-doc" title="Change document displayed on '+
((__t=( target ))==null?'':_.escape(__t))+
'">\n        Change document\n    </button>\n    ';
 if (app.user.has_perm('content.view_all_document_states')) { 
__p+='\n        <span class="compare-doc-state flag flag-'+
((__t=( doc_state ))==null?'':_.escape(__t))+
'" data-toggle="dropdown" title="State" haspopup="true" role="menu">\n            ';
 if(doc_state == 'draft') { 
__p+='\n            <span class="picon picon-edit"></span>\n            ';
 } else if(doc_state == 'ready') { 
__p+='\n            <span class="picon picon-thumbs-up"></span>\n            ';
 } else if(doc_state == 'published') { 
__p+='\n            <span class="picon picon-tick-circle"></span>\n            ';
 } 
__p+='\n            '+
((__t=( doc_state ))==null?'':_.escape(__t))+
'\n            ';
 if(valid_instances.length > 1) { 
__p+='\n            <b class="caret"></b>\n            ';
 } 
__p+='\n        </span>\n        ';
 if(valid_instances.length > 1) { 
__p+='\n            <ul class="dropdown-menu dropdown-menu-right" role="menu">\n                <li class="doc-state-option" role="presentation">\n                    ';
 _.each(valid_instances, function(instance) {
                        if(doc_state !== instance.get('state')) { 
__p+='\n                        <a data-docId='+
((__t=( doc_id ))==null?'':_.escape(__t))+
' data-docInstance='+
((__t=( instance.get('uuid') ))==null?'':_.escape(__t))+
' data-target='+
((__t=( target ))==null?'':_.escape(__t))+
' role="menuitem">'+
((__t=( instance.get('state') ))==null?'':_.escape(__t))+
'</a>\n                    ';
 }}); 
__p+='\n                </li>\n            </ul>\n        ';
 } 
__p+='\n    ';
 } 
__p+='\n</div>\n\n';
 if (is_news) { 
__p+='\n    <div id="header" class="header-container">\n        <div class="news-header" alt="News header" ></div>\n    </div>\n    <div class="inner newsitem" id="top">\n        <p>Fetching document data...</p>\n    </div> <!--/.inner-->\n';
 } else if (is_survey) { 
__p+='\n    <div id="header" class="header-container">\n        <div class="survey-header" alt="Survey header" ></div>\n    </div>\n    <div class="inner survey" id="top">\n        <p>Fetching document data...</p>\n    </div> <!--/.inner-->\n';
 }  else { 
__p+='\n    <div class="inner" id="top">\n        <p>Fetching document data...</p>\n    </div> <!--/.inner-->\n';
 } 
__p+='\n';
}
return __p;
};
