define([
    './base_results',
    '../../templates/search/section_search_results.html',
    '../../templates/search/section_search_results_form.html',
    '../../templates/search/section_search_results_table.html',
    '../../templates/search/section_search_results_row.html',
], function (
    baseResultsViews,
    sectionSearchResultsTpl,
    sectionSearchResultsFormTpl,
    sectionSearchResultsTableTpl,
    sectionSearchResultsRowTpl,
) {
    const React = require('react');
    const ReactDOM = require('react-dom');
    const { pluralise } = require('../../extras/index');
    const { getTerms } = require('../../extras/highlighting');

    /*
        Section search results table row
    */
    var SectionSearchResultsRow = baseResultsViews.BaseSearchResultsRow.extend({
        template: sectionSearchResultsRowTpl,
        initialize: function (options) {
            this.norris = options.norris;
        },
        clickRow: function () {
            baseResultsViews.BaseSearchResultsRow.prototype.clickRow.apply(this, arguments);
        },
        getSectionLabel: function () {
            var secClass = this.model.get('sec_class');
            var secId = this.model.get('sec_id');
            var secLabelLocation = this.model.get('sec_label_location');
            var secLabel = '';

            if (secLabelLocation) {
                // sec_label_location set during batch_convert where possible
                secLabel = secLabelLocation;
            } else if (secId.indexOf('title') != -1) {
                secLabel = 'Title';
            } else if (secClass == 'preamble' || secClass == 'begin') {
                secLabel = 'Preliminaries';
            } else if (secClass == 'explnote') {
                secLabel = 'Explanatory Note';
            } else {
                if (this.norris.initialized) {
                    secLabel = this.norris.getLabelFromId(secId);
                } else {
                    this.listenTo(this.norris, 'initialized', function () {
                        this.$('td.loc a').html(this.norris.getLabelFromId(secId));
                    });
                }
            }

            return secLabel;
        },
        getNumberHits: function () {
            return _.reduce(
                this.model.get('highlighting').snippets,
                function (result, snippet) {
                    return result + $('<div/>').append(snippet.fragment).find('.hit').length;
                },
                0,
            );
        },
        templateHelpers: function () {
            return {
                location: this.getSectionLabel(),
                documentUrl: this.model.getDocumentUrl(),
                hits: this.getNumberHits(),
            };
        },
    });

    /*
        Section search results table
    */
    var SectionSearchResultsTable = baseResultsViews.BaseSearchResultsTable.extend({
        blankRowStr:
            '<tr class="no-print"><td colspan="3" class="loading">Retrieving location, please wait...</td></tr>',
        template: sectionSearchResultsTableTpl,
        itemView: SectionSearchResultsRow,
    });

    /*
        Section search form view
    */
    var SectionResultsFormView = baseResultsViews.BaseResultsFormView.extend({
        template: sectionSearchResultsFormTpl,
        templateHelpers: function () {
            return _.extend(
                baseResultsViews.BaseResultsFormView.prototype.templateHelpers.apply(
                    this,
                    arguments,
                ),
                {
                    title: this.options.model.doc_title,
                },
            );
        },
    });

    /*
        Section search results
    */
    var SectionSearchResultsView = baseResultsViews.BaseSearchResultsView.extend({
        el: '#section-results',
        events: {
            'click .search-title .pane-close': 'closeResults',
            'click .change-query': 'changeQuery',
        },
        _rows: window.SOLR_DEFAULT_RESULT_ROWS,
        _start: window.SOLR_DEFAULT_RESULT_ROWS,
        template: sectionSearchResultsTpl,
        initialize: function () {
            this.listenTo(app, 'search:direction', this.goToResultRow);
            this.searchResults = this.sectionResults = this.options.searchResults;
            this.fullDocumentSearchResults = this.formatFullResultsQS(
                JSON.parse(window.localStorage.getItem('documentSearchResults')),
            );
            this.resultsTable = new SectionSearchResultsTable({
                collection: this.searchResults,
                norris: this.options.model.norris,
            });
            this.listenTo(this.resultsTable, 'result:selected', this.resultSelected);
            this.listenTo(app, 'search:scrollactiverow', this.scrollResultIntoView);
            this.listenTo(this.searchResults, 'sync', this.updateWaitMessage, this);

            this.listenTo(this, 'render', this.renderSubViews);
            this.queryParams = this.options.queryParams;
        },
        formatFullResultsQS: function (params) {
            if (!params || Object.keys(params).length === 0) return;
            const qs = [];
            Object.entries(params).forEach(([key, value]) => {
                if (value) {
                    if (Array.isArray(value)) {
                        if (value.length) qs.push(value.map((v) => `${key}=${v}`).join('&'));
                    } else {
                        qs.push(`${key}=${value}`);
                    }
                }
            });
            return qs.join('&');
        },
        updateWaitMessage: function () {
            var numFound = this.searchResults.collectionData.numFound;
            if (this._start >= numFound) {
                this.$('.found').html(
                    numFound + ' ' + pluralise(numFound, 'location', 'locations') + ' found',
                );
            } else {
                this.$('.found').html('Retrieving location ' + this._start + ' of ' + numFound);
            }
        },
        closeResults: function () {
            $('body').removeClass('with-searchresults').removeClass('with-searchresults-max');

            $('#content').find('.panetitle, .pane-left, .pane-main').attr('style', null);

            $('#navigation').find('.titlebar').attr('style', null);

            $('#results').attr('style', null);
        },
        renderSubViews: function () {
            this.formView = new SectionResultsFormView({
                hasEmptyResult: this.hasEmptyResult(),
                queryParams: this.queryParams,
                model: this.model,
            });
            this.assign(this.formView, '.search-details');

            var noSectionResults = this.hasEmptyResult();

            if (
                !noSectionResults ||
                (noSectionResults &&
                    this.options.view == 'document' &&
                    (this.queryParams['date'] == 'range' || this.queryParams['date'] == 'period'))
            ) {
                this.assign(this.resultsTable, '.search-results');
                app.trigger('document:ready');
                app.trigger('search:results:section');

                this.updateResults();
                this.updateWaitMessage();

                if (this.options.view == 'document') {
                    this.closeResults();
                } else {
                    $('body').addClass('with-searchresults');
                    if (this.model.get('timetravel')) {
                        this.$('.search-tt-warning').show();
                    }
                }
                if (this.options.source == 'docSearch') {
                    this.$('.back-to-full-results').show();
                } else if (this.fullDocumentSearchResults) {
                    // We're coming back from Timeline, so the Back to Full Results button needs to be rendered, but with a url rather than setting a view
                    const BackToResults = require('../../components/Search/BackToResults').default;
                    const element = document.getElementById('search-navigation');

                    const handleStorage = () => {
                        if ('documentSearchResults' in window.localStorage) {
                            window.localStorage.removeItem('documentSearchResults');
                        }
                    };
                    if (element) {
                        ReactDOM.render(
                            <BackToResults
                                resultsURL={`${app.urls.search}?${this.fullDocumentSearchResults}`}
                                buttonClass="back-to-results"
                                buttonText="Back to Full Results"
                                tidyClickEvent={handleStorage}
                            />,
                            element,
                        );
                    }
                }
            } else {
                this.handleNoResults();
                $('body').addClass('with-searchresults');
            }
        },
        close: function () {
            this.resultsTable.close();
            this.formView.close();
            baseResultsViews.BaseSearchResultsView.prototype.close.apply(this, arguments);
        },
        resultSelected: function (view) {
            if (view.model.get('doc_id') === 'reg-timeline') {
                let docUrl;
                if (view.model.get('doc_state') === 'published') {
                    docUrl = `/timeline#${view.model.get('sec_id')}`;
                } else {
                    docUrl = `/timeline/${view.model.get('doc_instance')}#${view.model.get(
                        'sec_id',
                    )}`;
                }
                app.recentDocuments.open('reg-timeline');
                window.open(docUrl, '_self');
            } else {
                this.model.trigger('document:navigate', view.model.get('sec_id'), true);
                app.trigger('document:content:show');
                this.closeResults();
                // update published recent documents with this one and double check it isn't a Survey or News
                if (
                    view.model.get('doc_state') == 'published' &&
                    view.model.get('doc_primary_family_title') != 'Survey' &&
                    view.model.get('doc_primary_family_title') != 'News'
                ) {
                    app.recentDocuments.open(view.model.get('doc_id'));
                }
            }
        },
        updateResults: function () {
            var numFound = this.searchResults.collectionData.numFound;
            // Recursively get the next set of results until there are none left
            if (this._start < numFound) {
                this.searchResults.qs['start'] = this._start;
                this.searchResults
                    .fetch({
                        data: this.searchResults.qs,
                        processData: true,
                        remove: false,
                    })
                    .done(
                        _.bind(function () {
                            this.updateResults();
                        }, this),
                    );

                this._start += this._rows;
            }
        },
        getTerms: function (norris) {
            return getTerms(norris, this.sectionResults);
        },
        scrollResultIntoView: function (row) {
            var viewPortRect = this.$('.search-results')[0].getBoundingClientRect();
            var rowRect = row.getBoundingClientRect();

            if (rowRect.bottom > viewPortRect.bottom || rowRect.top < viewPortRect.top) {
                row.scrollIntoView();
            }
        },
        goToResultRow: function (direction) {
            // user has clicked one of the arrow buttons at the top
            // navigate to the next or previous result
            var idx = this.$('.results-table tbody tr.active').index();
            if (idx >= 0) {
                if (direction == 'next') {
                    idx = Math.min(idx + 1, this.$('.results-table tbody tr').length);
                } else if (direction == 'prev') {
                    idx = Math.max(idx - 1, 0);
                }

                var row = this.$('.results-table tbody tr')[idx];

                // we may have run out of rows
                if (row) {
                    // keep the row scrolled into view
                    this.scrollResultIntoView(row);

                    // make sure next row is rendered
                    baseResultsViews.BaseSearchResultsTable.prototype.renderVisibleRow.apply(
                        this.resultsTable,
                        [idx],
                    );

                    // activate the row
                    $(row).trigger('click', { trigger: 'nav' });
                }
            }
        },
    });

    return SectionSearchResultsView;
});
