import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { formatHtmlId, truncateString } from '../utils';

const ToDIndexFilter = ({ options }) => {
    const [active, setActive] = useState('');

    useEffect(() => {
        const hash = window.location.hash
            .replace('#', '')
            .replace('tod-section-', '')
            .replace('header-', '')
            .replaceAll('_', ' ')
            .replaceAll('\\', '');

        setActive(hash);
    }, [options]);

    const scrollIndexPanel = (panelTitle) => {
        const element = document.getElementById(`index-panel-${formatHtmlId(panelTitle)}`);
        if (element) element.scrollIntoView();
    };

    const handleActive = (title) => {
        setActive(title);
    };

    return (
        <div className="tod-index-filters">
            {options ? (
                options.map((option, index) => {
                    return (
                        <a
                            href={`#tod-section-header-${formatHtmlId(option.title)}`}
                            className={`btn tod-header-btn ${
                                option.title === active ? 'active' : ''
                            }`}
                            key={`${option.doc_filter}-${index}`}
                            onClick={() => {
                                scrollIndexPanel(option.title);
                                handleActive(option.title);
                            }}
                            title={option.title}
                        >
                            {truncateString(option.title, 15)}
                        </a>
                    );
                })
            ) : (
                <div></div>
            )}
        </div>
    );
};

export default ToDIndexFilter;

ToDIndexFilter.propTypes = {
    options: PropTypes.array,
};
