import React from 'react';
import PropTypes from 'prop-types';
import { ProgressBar } from 'react-bootstrap';
import { getPercentageSuccessfulUploads, getPercentageFailedUploads } from './utils';
import { WithLoading } from '../FormControls/WithLoading';

const DocumentUploadProgress = ({ upload }) => {
    const successfulPercentage = getPercentageSuccessfulUploads(upload);
    const failedPercentage = getPercentageFailedUploads(upload);

    return (
        <div className="upload-progress">
            <WithLoading loading={!upload}>
                <>
                    <div className="progress-total-uploads">
                        <span>Total: {upload?.total_count} </span>
                        <span>Successful: {upload?.successful_count} </span>
                        <span>Failed: {upload?.failed_count} </span>
                        <span>Indexed: {upload?.indexed_count} </span>
                    </div>

                    <ProgressBar>
                        <ProgressBar
                            variant="success"
                            now={successfulPercentage}
                            key={1}
                            label={`${
                                successfulPercentage >= 10 ? 'Successful' : 'S'
                            }: ${successfulPercentage}%`}
                            aria-label="progress success"
                        />
                        <ProgressBar
                            variant="danger"
                            now={failedPercentage}
                            key={3}
                            label={`${
                                failedPercentage >= 10 ? 'Failed' : 'F'
                            }: ${failedPercentage}%`}
                            aria-label="progress fail"
                        />
                    </ProgressBar>
                </>
            </WithLoading>
        </div>
    );
};

export default DocumentUploadProgress;

DocumentUploadProgress.propTypes = {
    upload: PropTypes.shape({
        total_count: PropTypes.number.isRequired,
        successful_count: PropTypes.number.isRequired,
        failed_count: PropTypes.number.isRequired,
        indexed_count: PropTypes.number.isRequired,
    }),
};
