// extras/ajax.js
// sets up global jquery ajax bits

define([
    'jquery',
    'jqueryCookie',
    './messages'
    ],
    function($) {

        // abort all xhr requests
        $.xhrPool = [];
        $.xhrPool.abortAll = function() {
            $(this).each(function(idx, xhr) {
                xhr.abort();
            });
            $.xhrPool.length = 0;
        };

        $.ajaxSetup({
            crossDomain: false,
            beforeSend: function(xhr, settings) {
                // add csrf token to headers
                if (!(/^(GET|HEAD|OPTIONS|TRACE)$/.test(settings.type))) {
                    xhr.setRequestHeader("X-CSRFToken", $.cookie('csrftoken'));
                }

                // add to pending xhr requests
                $.xhrPool.push(xhr);
            },
            complete: function(xhr) {
                var idx = $.inArray(xhr, $.xhrPool);
                if (idx > -1) {
                    $.xhrPool.splice(idx, 1);
                }
            }
        });

        function formatErrorMessage(jqxhr, exception) {
            if(jqxhr.status === 0) {
                return 'Not connected.\nPlease verify your network connection';
            } else if (jqxhr.status == 404) {
                return 'The requested page was not found [404]';
            } else if (jqxhr.status.toString().match('^403')) {
                return 'Access to this resource is forbidden ['+ jqxhr.status +']';
            } else if (jqxhr.status == 500) {
                return 'Internal Server Error [500]';
            } else if (exception === 'parsererror') {
                return 'Requested JSON parse failed';
            } else if (exception === 'timeout') {
                return 'Time out error';
            } else if (exception === 'abort') {
                return 'Ajax request aborted';
            }
        }

        // global error handling
        $(document).ajaxError(function(event, jqxhr, settings, exception) {
            // don't report on 4xx errors
            if(!jqxhr.status.toString().match('^4')) {
                var message = 'System Error', errorMessage;
                if(window.DEBUG && (errorMessage = formatErrorMessage(jqxhr, exception))) {
                    message += '<br /><small class="text-muted">('+ errorMessage +')</small>';
                }
                messages.clear();
                messages.error(message);
            }
        });

    }
);
