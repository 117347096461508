import PropTypes from 'prop-types';

export const favouritePropTypes = {
    document: PropTypes.string,
    family: PropTypes.string,
    favourite_url: PropTypes.string,
    name: PropTypes.string,
    owner: PropTypes.number,
    pk: PropTypes.number,
    ref_id: PropTypes.string,
    time_travelled: PropTypes.bool,
};

export const favouritesModel = {
    attributes: PropTypes.shape(favouritePropTypes),
    fetchByDoc: PropTypes.func.isRequired,
    fetchFavourites: PropTypes.func.isRequired,
};

export const favouriteModel = {
    save: PropTypes.func,
    update: PropTypes.func,
    destroy: PropTypes.func,
};

export const documentPropTypes = {
    options: PropTypes.shape({
        favourites: PropTypes.shape({
            document: PropTypes.string,
            models: PropTypes.array,
        }),
        doc: PropTypes.shape({
            id: PropTypes.string,
        }),
    }),
    model: PropTypes.shape({
        attributes: PropTypes.shape({
            document: PropTypes.string,
        }),
        ttViewDate: PropTypes.object,
        ttViewDate2: PropTypes.object,
        ttViewOption: PropTypes.string,
    }),
};
