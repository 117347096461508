module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='/* TODO: Try and move this into less files. */\n\n/* ==========================================================================\n    Pendragon Perspective styles\n    User styles generated via PREFS page\n    For default values, property should be left undeclared\n    ========================================================================== */\n\n/* 1. Font \n------------------------------------------------------ */\n/* Default: "Helvetica Neue", Helvetica, Arial, sans-serif; */\n.pane-doc .inner {\n    font-family: '+
((__t=( font ))==null?'':_.escape(__t))+
';    \n}\n\n/* 2. Text size \n------------------------------------------------------ */\n/* This sets the base font size for the document pane. Default 15px. \nAll document font sizes are relative to this, using EM units in doc.less */\n.pane-doc .inner {\n    font-size: '+
((__t=( text_size ))==null?'':_.escape(__t))+
'px;\n}\n\n.pane-doc .textnote,\n.pane-doc .txtnote {\n    font-size: '+
((__t=( text_size * 0.8 ))==null?'':_.escape(__t))+
'px;\n}\n\n/* 3. Line spacing \n------------------------------------------------------ */\n/* Default 1.428571429 (unitless). */\n.pane-doc .inner {\n    ';
 if (line_spacing == 'compact') {
__p+='\n        line-height: 1.2;\n    ';
} else if (line_spacing == 'spacious') {
__p+='\n        line-height: 1.8;\n    ';
} else {
__p+='\n        line-height: 1.4;\n    ';
} 
__p+='\n}\n/* TODO: Adjust line-height on all Hx elements */\n\n/* 4. Background colour \n------------------------------------------------------ */\n/* Choices: Default (white), Sepia, Dark */\n/* Affects background colour, but also other elements e.g. font colour */\n/* Uses <body> class: .bg-sepia or .bg-dark */\n\n/* 4a. Sepia */\n.bg-sepia .pane-doc,\n.bg-sepia .pane-doc .inner {\n    background-color: #efeadc;\n}\n.bg-sepia .pane-toc {\n    background-color: #e7dfcb;\n}\n\n/* 4b. Dark */\n.bg-dark .pane-doc,\n.bg-dark .pane-doc .inner {\n    background-color: #1E1E1E;\n}\n\n.bg-dark .pane-toc {\n    background-color: #2E313B;\n}\n.bg-dark .panetitle,\n.bg-dark .pane-grabber {\n    background-color: #444A69;\n    border-color: #444;\n    color: #fff;\n}\n.bg-dark .pane-doc .inner {\n    color: #fff;\n}\n\n.bg-dark .toc-tree LI .toc-item.active {\n    background-color: #52512D;\n}\n\n.bg-dark .pane-toc .toc-tree LI .toc-item {\n    color: #fff;\n}\n.bg-dark .pane-doc H1.title,\n.bg-dark .pane-doc .title\\.list .title,\n.bg-dark .pane-doc .case-tit,\n.bg-dark .pane-doc .sub-tit,\n.bg-dark .pane-doc .ednote,\n.bg-dark .pane-doc .note-tex {\n    color: pink;\n}\n/*.bg-dark .pane-doc .title,\n.bg-dark .pane-doc .title\\.sublist .title {\n    color: #fff;\n}*/\n.bg-dark .pane-doc .anchor,\n.bg-dark .pane-doc .inref {\n    color: #c4d2fb;\n}\n\n.bg-dark .tts-regular,\n.bg-dark .tts-repealed,\n.bg-dark .tts-future-repealed,\n.bg-dark .tts-future-repealed * {\n    color: #fff;\n}\n\n/* 6. Line length \n------------------------------------------------------ */\n/* Default: Line length is limited */\n\n.pane-doc .inner {\n\n    ';
 if (!linelength) {
__p+='\n        max-width: 84%;\n    ';
} 
__p+='\n    \n    \n}';
}
return __p;
};
