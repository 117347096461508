import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';

import MultipleGroupWarning from './MultipleGroupWarning';
import MultiSelect from '../FormControls/Select/MultiSelect';
import Select from '../FormControls/Select/Select';

import { getDefaultUserGroup, getOrganisationsList, getPreAlerts, getUserGroups } from './utils';
import { arrayNotEmpty } from '../utils';

const WorkplaceSection = ({
    setData,
    data,
    metadata,
    shouldCheckValidity,
    setEditedData,
    editedData,
    multiOrgs,
    setPreAlerts,
}) => {
    const [userGroupsInvalid, setUserGroupsInvalid] = useState(false);
    const [organisationInvalid, setOrganisationInvalid] = useState(false);
    const organisationOptions = getOrganisationsList(metadata?.organisations);
    const multiOrgOptions = [
        {
            label: 'Suggested',
            options: organisationOptions.filter((option) => multiOrgs?.includes(option.value)),
        },
        {
            label: 'Others',
            options: organisationOptions.filter((option) => !multiOrgs?.includes(option.value)),
        },
    ];
    useEffect(() => {
        if (shouldCheckValidity?.validate) checkInputValidity();
    }, [shouldCheckValidity]);

    const checkInputValidity = () => {
        let isValid = true;

        if (!data.organisation) {
            setOrganisationInvalid(true);
            isValid = false;
        }
        if (!arrayNotEmpty(data.user_groups)) {
            setUserGroupsInvalid(true);
            isValid = false;
        }

        return isValid;
    };

    const onChange = (newVal, name) => {
        setEditedData({ ...editedData, [name]: newVal });
    };

    const userGroupList = getUserGroups(metadata?.subscription_groups, data.organisation?.value);

    return (
        <React.Fragment>
            <Form.Group>
                <Form.Label>
                    Organisation {multiOrgs && <MultipleGroupWarning organisation={true} />}
                </Form.Label>

                {/* Selecting organisation pre-selects default user group for that organisation */}
                <Select
                    onChange={(organisation) => {
                        const defaultGroup = getDefaultUserGroup(organisation?.value, metadata);
                        setData({
                            ...data,
                            organisation,
                            user_groups: defaultGroup,
                        });
                        setPreAlerts(getPreAlerts(defaultGroup));
                        onChange(organisation, 'organisation');
                        setOrganisationInvalid(false);
                        setUserGroupsInvalid(false);
                    }}
                    value={data.organisation}
                    options={multiOrgs ? multiOrgOptions : organisationOptions}
                    name="organisation"
                    isRequired={true}
                    isInvalid={organisationInvalid}
                    placeholder="Select an organisation"
                    errorMessage="Please select organisation"
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    User groups {userGroupList.length > 1 && <MultipleGroupWarning />}
                </Form.Label>
                <MultiSelect
                    onChange={(user_groups) => {
                        setData({
                            ...data,
                            user_groups,
                        });
                        onChange(user_groups, 'user_groups');
                        setPreAlerts(getPreAlerts(user_groups));
                        setUserGroupsInvalid(false);
                    }}
                    options={userGroupList}
                    value={data.user_groups}
                    name="user_groups"
                    isRequired={true}
                    isInvalid={userGroupsInvalid}
                    placeholder="Select user groups"
                    errorMessage="Please select user groups"
                />
            </Form.Group>
        </React.Fragment>
    );
};

export default WorkplaceSection;

WorkplaceSection.propTypes = {
    setData: PropTypes.func.isRequired,
    data: PropTypes.object,
    metadata: PropTypes.object,
    shouldCheckValidity: PropTypes.object,
    setEditedData: PropTypes.func.isRequired,
    editedData: PropTypes.object,
    multiOrgs: PropTypes.arrayOf(PropTypes.number),
    setPreAlerts: PropTypes.func.isRequired,
};
