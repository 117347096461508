import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import RODContainer from '../ROD/RODContainer';
import DocSearchContainer from '../DocumentFinder/DocSearchContainer';
import { Modal, Button, InputGroup, FormControl } from 'react-bootstrap';
import KeySelectorFooter from '../KeySelectorFooter';

const SearchModal = ({ handleModal, show, compareDoc, handleSelection, target }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [showReset, setShowReset] = useState(false);
    const charCountTrigger = 2;

    const handleChange = ({ target: { value } }) => {
        setSearchQuery(value);
        setShowReset(true);
    };

    const handleClear = () => {
        setSearchQuery('');
    };

    const handleEscapeKeyDown = (event) => {
        event.preventDefault();
        if (event.type === 'keydown' && event.key === 'Escape' && searchQuery.length > 0) {
            // This overrides Bootstrap's having Escape close the modal so that it clears the input field
            setSearchQuery('');
        } else if (event.type === 'keydown' && event.key === 'Escape') {
            // Because we override Escape above, we have to explicitly ask it to do it now
            handleModal();
        }
    };

    const triggerComparison = (docId, docInstance) => {
        if (compareDoc) {
            handleSelection(docId, docInstance);
            handleModal();
        } else {
            return true;
        }
    };

    const autoFocus = useCallback((element) => (element ? element.focus() : null), []);

    const permission = app.user.has_perm('content.view_all_document_states');

    if (showReset && searchQuery.length === 0) {
        setShowReset(false);
    }

    return (
        <Modal
            role="main"
            aria-label="doc-finder-modal"
            className="doc-finder-modal"
            show={show}
            onHide={handleModal}
            animation={false}
            keyboard={true}
            onEscapeKeyDown={handleEscapeKeyDown}
        >
            <Modal.Header closeButton>
                <h1 className="sr-only">Open Document Modal</h1>
            </Modal.Header>
            <Modal.Body>
                <InputGroup className="modal-search">
                    <FormControl
                        type="text"
                        placeholder="Type at least two characters to search"
                        ref={autoFocus}
                        value={searchQuery}
                        onChange={handleChange}
                    />

                    {showReset && (
                        <InputGroup.Append className="btn-reset">
                            <Button variant="outline-secondary" onClick={handleClear}>
                                Reset
                            </Button>
                        </InputGroup.Append>
                    )}
                </InputGroup>

                {searchQuery.length < charCountTrigger && (
                    <div>
                        <p>
                            Start typing part of the document title or its abbreviation in the box
                            above.
                        </p>
                        <p>
                            Alternatively, select a document from the list of recently opened
                            documents below.
                        </p>
                    </div>
                )}
            </Modal.Body>
            <Modal.Body>
                {searchQuery.length >= charCountTrigger ? (
                    <DocSearchContainer
                        query={searchQuery}
                        {...{ permission, triggerComparison, handleModal }}
                    />
                ) : (
                    <RODContainer {...{ triggerComparison, handleModal }} />
                )}
            </Modal.Body>
            <Modal.Footer>
                <KeySelectorFooter {...{ searchQuery, charCountTrigger, compareDoc, target }} />
            </Modal.Footer>
        </Modal>
    );
};

export default SearchModal;

SearchModal.propTypes = {
    handleModal: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    compareDoc: PropTypes.bool.isRequired,
    handleSelection: PropTypes.func.isRequired,
    target: PropTypes.string.isRequired,
};
