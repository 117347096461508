import React from 'react';

import ConditionalWrapper from '../../../ConditionalWrapper';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

const SharedHelpText = () => {
    const popover = (
        <Popover id="shared-help-popover" className="popover fade top in">
            <Popover.Content className="popover-content">
                Only users with access to this document will be able to see shared annotations made
                in it.
            </Popover.Content>
        </Popover>
    );

    const displayToolTip = app.user.get('display_prefs').contextual_help;

    return (
        <ConditionalWrapper
            condition={displayToolTip}
            wrapper={(children) => (
                <OverlayTrigger placement="top" overlay={popover}>
                    {children}
                </OverlayTrigger>
            )}
        >
            <span
                className="btn shared-help-text"
                title="Only users with access to this document will be able to see shared annotations made in it"
            >
                ?
            </span>
        </ConditionalWrapper>
    );
};

export default SharedHelpText;
