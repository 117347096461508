import React, { useState, useEffect, useContext } from 'react';

import FormModal from '../FormControls/FormModal';
import SelectedLister from '../FormControls/SelectedLister';
import DomainChanger from '../Forms/DomainChanger';
import ConfirmationEmailSelector from '../FormControls/ConfirmationEmailSelector';
import confirm from '../FormControls/ConfirmModal/ConfirmModalService';
import SimpleTableControl from '../FormControls/Table/SimpleTableControl';
import ValidCheckMessage from '../FormControls/ConfirmModal/ValidCheckMessage';
import PropTypes from 'prop-types';
import { getSelectedFilterValues, asyncAction } from '../utils';
import { TableContext, TABLE_ACTION } from '../FormControls/Table/TableContext';

const getDomain = (email) => {
    const splitEmail = email.split('@');
    return splitEmail[1];
};

const EmailDomainsModal = ({ show, hideModal, usersModel }) => {
    const { tableState, dispatchTableState } = useContext(TableContext);

    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState([]);
    const [validation, setValidation] = useState([]);
    const [domainList, setDomainList] = useState({});

    const fetchData = async () => {
        try {
            const params = getSelectedFilterValues(tableState);
            const result = await usersModel.getSelectedUsers(params);
            setSelected(result);

            const resultDomains = result
                .reduce((arr, obj) => [...arr, getDomain(obj.email)], [])
                .reduce((obj, item) => {
                    obj[item] = (obj[item] || 0) + 1;
                    return obj;
                }, {});

            setDomainList(resultDomains);
        } catch (error) {
            hideModal();
            toaster.error(`Couldn't retrieve data`);
            console.error(error);
        }
    };

    useEffect(() => {
        setLoading(true);
        if (show) {
            fetchData();
        }
        // State persists even if modal is closed, so if someone opens modal with x users, closes
        // modal, reopens with x different users, the domainList is the persisting list...
        if (!show) {
            setDomainList({});
        }
        setLoading(false);
    }, [show]);

    const handleChange = ({ target: { name } }) => {
        // removes the error message when user starts typing if the error message was there
        const validity = validation.length > 0 && validation.find((v) => v.field === name);
        if (validity?.VALID === false) {
            const otherValidation = validation.filter((v) => v.field !== name);
            setValidation([...otherValidation, { VALID: true, field: name }]);
        }
    };

    const checkInputValidity = (form) => {
        const validationArr = [];
        const list = Object.keys(domainList).map((item) => {
            const domain = form.elements[item].value;
            if (domain) {
                if (!domain.includes('.')) {
                    validationArr.push({ VALID: false, field: item });
                    return false;
                }
            }
            validationArr.push({ VALID: true, field: item });
        });
        setValidation(validationArr);
        // checks if all domains should be changed at once
        const allDomains = form.elements.changeAll.value;
        if (allDomains) {
            if (!allDomains.includes('.')) {
                setValidation([...validation, { VALID: false, field: 'changeAll' }]);
                return false;
            }
        }
        return !list.includes(false);
    };

    const getTableColumns = (item) => {
        return [item.oldDomain, item.newDomain, item.userCount];
    };

    const getDomainConfirmation = (getDomainData, result, template) => {
        const headers = ['Existing domain', 'Change to', 'Users affected'];
        return (
            <>
                {!template && (
                    <b>
                        Warning: No confirmation email template was selected, valid emails will be
                        automatically updated.
                    </b>
                )}
                <div className="table-container">
                    <SimpleTableControl
                        className="confirm-table"
                        doFetch={getDomainData}
                        {...{ getTableColumns, headers, dataName: 'email domain' }}
                    />
                </div>
                {result.message && <ValidCheckMessage message={result.message} />}
            </>
        );
    };

    const adjustParams = (params, data) => {
        const adjustedTotalCount = data.reduce((total, currVal) => total + currVal.userCount, 0);
        const adjustedDomainCount = data.filter((item) => item.userCount > 0);
        const existingDomains = data.reduce((arr, user) => [...arr, ...user.matches], []);
        const adjustedEmails = params.emails.filter(
            (user) => !existingDomains.includes(user.email),
        );
        const adjustedSelect = adjustedEmails.reduce((arr, user) => [...arr, user.id], []);
        return {
            ...params,
            emails: adjustedEmails,
            'selectedList[]': adjustedSelect,
            total_count: adjustedTotalCount,
            domain_count: adjustedDomainCount.length,
        };
    };

    const updateEmails = (form) => {
        const changedDomains = [];
        let count = 0;
        const updatedData = selected.map((user) => {
            const emailParts = user.email.split('@');
            const oldDomain = emailParts[1];
            const newDomain = form.elements.changeAll.value
                ? form.elements.changeAll.value.trim()
                : form.elements[oldDomain].value
                ? form.elements[oldDomain].value.trim()
                : oldDomain;
            const newEmail = `${emailParts[0]}@${newDomain}`;
            if (newEmail !== user.email) {
                if (!changedDomains.some((item) => item.oldDomain === oldDomain)) {
                    changedDomains.push({
                        id: ++count,
                        oldDomain,
                        newDomain,
                        userCount: domainList[oldDomain],
                    });
                }
                const updatedUser = { ...user };
                updatedUser.email = newEmail;
                return { id: updatedUser.id, email: updatedUser.email };
            } else {
                return { id: user.id, email: newEmail };
            }
        });
        const template = form.elements.emailTemplate.value;
        const params = {
            'selectedList[]': updatedData.reduce((arr, user) => [...arr, user.id], []),
            emails: updatedData,
            email_template: template,
            total_count: updatedData.length,
            domain_count: changedDomains.length,
        };
        return { changedDomains, params };
    };

    const submitForm = async (form) => {
        const changedData = updateEmails(form);
        const { params, changedDomains } = changedData;

        const fun = (params) => usersModel.updateEmailDomains(params);

        // making sure each new email address doesn't already exist
        const doCheck = async () => {
            const result = await fun(params);
            return result;
        };

        const result = await doCheck();

        // adjusting the affected user count if an email does exist
        const data = changedDomains.map((item) => {
            const newEmailRegex = RegExp('[A-Za-z0-9._%+-]+@' + item.newDomain, 'g');
            const str = result.message;
            const matches = str.match(newEmailRegex);

            if (matches !== null) {
                const oldEmails = matches.map((email) => {
                    const emailArr = email.split('@');
                    return `${emailArr[0]}@${item.oldDomain}`;
                });
                // checking against old emails because if changeAll was used to edit the domains,
                // the userCount is decremented for all oldDomains, not just the one with the existing user
                if (selected.some((user) => oldEmails.includes(user.email))) {
                    return { ...item, userCount: item.userCount - matches.length, matches };
                } else {
                    return { ...item, matches: [] };
                }
            } else {
                return { ...item, matches: [] };
            }
        });

        const adjustedParams = adjustParams(params, data);

        const getDomainData = () => {
            return data;
        };

        const confirmedResult = await confirm({
            title: 'Confirm email domain changes',
            message: getDomainConfirmation(getDomainData, result, params.email_template),
        });

        if (confirmedResult === false) return false;

        const setDirty = () => dispatchTableState({ type: TABLE_ACTION.SET_DIRTY });

        await asyncAction({
            fun,
            setDirty,
            args: { ...adjustedParams, confirmed: true },
            actionName: 'change domain',
        });
        hideModal();
    };

    return (
        <FormModal
            title="Change email domains"
            {...{ show, submitForm, checkInputValidity, loading, hideModal }}
        >
            <SelectedLister
                {...{ selected }}
                type={selected.length > 1 ? 'users' : 'user'}
                property={'email'}
            />
            <DomainChanger {...{ domainList, validation, handleChange }} />
            <ConfirmationEmailSelector
                templateLabel={app.emailTemplateLabels.CHANGE_EMAIL}
                action="change email domains"
            />
        </FormModal>
    );
};

export default EmailDomainsModal;

EmailDomainsModal.propTypes = {
    show: PropTypes.bool.isRequired,
    hideModal: PropTypes.func.isRequired,
    usersModel: PropTypes.shape({
        fetch: PropTypes.func.isRequired,
        getSelectedUsers: PropTypes.func,
        updateEmailDomains: PropTypes.func,
    }),
};
