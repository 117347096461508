import React from 'react';
import { Form, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import FormModal, { MODAL_SIZE } from '../FormControls/FormModal';
import confirm from '../FormControls/ConfirmModal/ConfirmModalService';

const SelectTopicsModal = ({
    show,
    hideModal,
    selectedTopics,
    setSelectedTopics,
    topics,
    handleSubmit,
}) => {
    const confirmNone = async () => {
        const confirmed = await confirm({
            title: 'No Topic Selected',
            message: 'You have not selected any News topics - are you sure?',
        });
        return confirmed;
    };

    return (
        <FormModal
            title="Select topics"
            show={show}
            size={MODAL_SIZE.LARGE}
            saveText="Done"
            submitForm={async () => {
                if (selectedTopics.length === 0) {
                    const confirmed = await confirmNone();
                    if (!confirmed) {
                        return false;
                    } else {
                        handleSubmit();
                    }
                } else {
                    handleSubmit();
                }
            }}
            backdrop="static"
            {...{ hideModal }}
            className="select-topic"
        >
            <Form.Group>
                <h5 className="pull-left">Select all topics you are interested in</h5>
                <div className="pull-right selection-controls">
                    Select:
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setSelectedTopics(
                                topics.map((topic) => topic.topic_id),
                                true,
                            );
                        }}
                    >
                        All
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setSelectedTopics([], false);
                        }}
                    >
                        None
                    </Button>
                </div>
                <div className="topics-container">
                    <ul>
                        {topics.map((topic) => (
                            <li key={topic.topic_id}>
                                <Form.Check
                                    type="checkbox"
                                    id={`topic-${topic.topic_id}`}
                                    label={topic.title}
                                    checked={selectedTopics.includes(topic.topic_id)}
                                    onChange={(e) => {
                                        const changedTopics = e.currentTarget.checked
                                            ? [...selectedTopics, topic.topic_id]
                                            : selectedTopics.filter(
                                                  (selectedTopic) =>
                                                      selectedTopic !== topic.topic_id,
                                              );
                                        setSelectedTopics(
                                            changedTopics,
                                            changedTopics.length === topics.length,
                                        );
                                    }}
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            </Form.Group>
        </FormModal>
    );
};

SelectTopicsModal.propTypes = {
    show: PropTypes.bool.isRequired,
    hideModal: PropTypes.func.isRequired,
    selectedTopics: PropTypes.arrayOf(PropTypes.string),
    setSelectedTopics: PropTypes.func,
    topics: PropTypes.arrayOf(
        PropTypes.shape({ topic_id: PropTypes.string, title: PropTypes.string }),
    ),
    handleSubmit: PropTypes.func.isRequired,
};

export default SelectTopicsModal;
