import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { getAppInstance } from '../utils';
import VersionChip from './VersionChip';

const VersionContainer = ({ siteName, solrVersions }) => {
    const [solrVersion, setSolrVersion] = useState({ version: '', background: '' });
    const instance = getAppInstance(siteName);
    const newSolr = app.user.attributes.roles.some((role) => role.name === 'Solr Tester');

    useEffect(() => {
        if (newSolr && solrVersions.length > 1) {
            // eslint-disable-next-line no-prototype-builtins
            const version = solrVersions.find((version) => version.hasOwnProperty('candidate'));
            setSolrVersion({ version: version.candidate, background: 'grey' });
        } else {
            const version = solrVersions.find((version) => version.search);
            // Just in case the only version of solr is the candidate one, we should check
            setSolrVersion({
                version: version ? version.search : version.candidate,
                background: version ? '' : 'grey',
            });
        }
    }, [solrVersions]);

    return (
        <div className="version-container">
            <div className="perspective-version">Perspective v{window.APP_VERSION}</div>
            <VersionChip {...{ label: instance, background: 'dark-grey' }} />
            <VersionChip
                {...{
                    label: `SOLR (${newSolr ? 'P' : 'A'}) V${solrVersion.version}`,
                    background: solrVersion.background,
                }}
            />
        </div>
    );
};

export default VersionContainer;

VersionContainer.propTypes = {
    siteName: PropTypes.string.isRequired,
    solrVersions: PropTypes.arrayOf(PropTypes.object).isRequired,
};
