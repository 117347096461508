import zipObject from 'lodash/zipObject';
import difference from 'lodash/difference';
import React from 'react';

import { TABLE_ACTION } from './FormControls/Table/TableContext';

const formatDate = (date, format) => {
    return date ? app.moment(date).format(format) : '';
};

const getDateTime = (date) => {
    return formatDate(date, app.settings.longDateTimeFormat);
};

const getDate = (date) => {
    return formatDate(date, app.settings.longDateFormat);
};

const getFileDate = () => {
    return app.moment().format('DD-MM-YYYY');
};

const getShortDate = (date) => {
    return formatDate(date, app.settings.dateFormat);
};

const getAbbrevDate = (date) => {
    return date ? app.moment(date).format(app.settings.dateFormat) : '';
};

const getMediumDateTime = (date) => {
    return formatDate(date, app.settings.mediumDateTimeFormat);
};

const strToCapital = (str) => {
    const firstLetter = str.slice(0, 1);
    return firstLetter.toUpperCase().concat(str.toLowerCase().slice(1));
};

const post = async (url, data) => {
    return $.ajax({
        type: 'POST',
        url,
        data: JSON.stringify(data),
        contentType: 'application/json',
        processData: false,
    });
};

const attributeToStr = (attribute) => {
    const str = attribute.replace(/_/gi, ' ');
    return strToCapital(str);
};

const getAppInstance = (siteName) => {
    const regex = new RegExp('perspective');
    const deployed = regex.test(siteName);
    if (deployed) {
        const name_parts = siteName.split('.');
        const prod = regex.test(name_parts[0]);
        if (prod) {
            return;
        } else {
            return name_parts[0].toUpperCase();
        }
    } else {
        return 'DEV';
    }
};

const getHeadersFormatted = (headers) => {
    return (
        headers?.map((header) => {
            return { title: header };
        }) ?? []
    );
};

const getFilterValues = ({ filter, page, meta, itemsPerPage, sortColumn }) => {
    return {
        ...filter,
        ...meta,
        page,
        page_size: itemsPerPage,
        sort_column: sortColumn,
    };
};

const getSelectedFilterValues = (tableState) => {
    return {
        'selectedList[]': tableState.selectedList,
        selectedAll: tableState.selectedAll,
        ...getFilterValues(tableState),
        sort_column: '',
    };
};

const asyncAction = async ({
    fun,
    setDirty = () => {},
    args,
    actionName,
    setLoading = () => {},
}) => {
    try {
        setLoading(true);
        const result = await fun(args);
        setLoading(false);

        if (actionName === 'download') {
            const filePrefix = args.filePrefix;
            const downloadDate = getFileDate();
            const link = document.createElement('a');
            link.setAttribute('href', `data:text/csv;charset=utf-8,${encodeURIComponent(result)}`);
            link.setAttribute('download', `${filePrefix}-${downloadDate}.csv`);
            link.click();
            return;
        }

        if (result.confirm_required) {
            return result;
        }
        toaster.success(result.status);
        setDirty();

        return result;
    } catch (xhr) {
        handleSaveErrors({ xhr, genericMessage: `The action "${actionName}" cannot be performed` });
        setLoading(false);
        return { error: true };
    }
};

const formatToReadableText = (data, failedFormatReturnValue = 'ERROR') => {
    try {
        const formatToString = (text) => {
            if (!text && text !== false) return '';
            if (typeof text === 'string') return text.replace(/_/g, ' ');

            return JSON.stringify(text);
        };

        const formatObject = (obj) => {
            return Object.keys(obj)
                .map((key) => `${formatToString(key)} - ${formatToString(obj[key])}`)
                .join(', ');
        };

        const isFormatableObject = (obj) =>
            obj && typeof obj === 'object' && !Array.isArray(obj) && Object.keys(obj)?.length > 0;

        // Format array up to 2 levels, to make it look more user friendly. If any deeper than 2 levels - just revert to JSON.stringify,
        // as that would become the most readable format anyway
        if (Array.isArray(data)) {
            return data
                .map((child) => {
                    if (isFormatableObject(data)) {
                        return formatObject(data);
                    }
                    if (Array.isArray(child)) {
                        return child.map((grandChild) => formatToString(grandChild)).join(' - ');
                    }
                    return formatToString(child);
                })
                .join(', ');
        }

        if (isFormatableObject(data)) {
            return formatObject(data);
        }

        return formatToString(data);
    } catch (error) {
        console.error('There was a formatting error');
        console.error(error);
        return failedFormatReturnValue;
    }
};

const handleSaveErrors = ({ xhr, genericMessage, validFormFields = [] }) => {
    try {
        let formErrors = xhr.responseJSON?.form_errors ?? xhr.responseJSON?.errors;

        if (xhr.status == 400) {
            if (xhr.responseJSON?.status) {
                toaster.error(xhr.responseJSON.status);
                return;
            } else if (formErrors) {
                if (!Array.isArray(formErrors)) formErrors = [formErrors];
                for (let error of formErrors) {
                    toaster.error(formatToReadableText(error, genericMessage));
                }
                return;
            } else if (
                Object.keys(xhr.responseJSON ?? {}).some((val) => validFormFields.includes(val))
            ) {
                for (let key in xhr.responseJSON) {
                    if (validFormFields.includes(key)) {
                        toaster.error(`${xhr.responseJSON[key]} - (${key.replace('_', ' ')})`);
                    }
                }
                return;
            }
        }
    } catch (error) {
        console.error(error);
    }
    toaster.error(genericMessage);
};

const tableAsyncAction = async ({
    fun,
    tableState,
    dispatchTableState,
    args,
    actionName,
    setLoading,
}) => {
    const setDirty = () => dispatchTableState({ type: TABLE_ACTION.SET_DIRTY });

    const params = {
        ...getSelectedFilterValues(tableState),
        ...args,
    };

    return await asyncAction({ fun, setDirty, args: params, actionName, setLoading });
};

const doFetchSelectableTable = async ({ tableState, fetch, refreshSelected }) => {
    const params = {
        ...getFilterValues(tableState),
    };
    const dataResult = await fetch({ data: params });

    dataResult.selectedList = [];

    if (tableState.selectedList.length !== 0) {
        const params = getSelectedFilterValues(tableState);
        const result = await refreshSelected(params);
        dataResult.selectedList = result;
    }

    return dataResult;
};

const readFileAsText = (inputFile) => {
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
        fileReader.onerror = () => {
            fileReader.abort();
            reject(new DOMException('Problem parsing input file.'));
        };
        fileReader.onload = () => {
            resolve(fileReader.result);
        };
        fileReader.readAsText(inputFile);
    });
};

const csvFromText = (text, headers, optionalHeaders) => {
    const rows = text.split('\n');
    const headerData = rows
        .shift()
        .split(',')
        .map((s) => s.trim());

    const headersDifference = difference(headerData, headers);
    if (
        headersDifference.length !== 0 &&
        headersDifference.every((diff) => !optionalHeaders.includes(diff.toLowerCase()))
    ) {
        throw new Error('Mismatched headers');
    }

    const results = rows.reduce((acc, row) => {
        if (row) {
            acc.push(zipObject(headerData, row.split(',')));
        }
        return acc;
    }, []);

    return results;
};

const formatUserName = (firstName, lastName) => {
    const name =
        firstName || lastName ? (
            `${firstName} ${lastName}`
        ) : (
            <i>
                <small>Name not provided</small>
            </i>
        );
    return name;
};

const formatAlertFrequency = (alertFrequency) => {
    const frequency = alertFrequency ? alertFrequency.replaceAll('-', ' ') : null;
    return frequency;
};

const getConfirmationMessageUsers = (confirmationText, selectedCount) => {
    return `You are about to ${confirmationText} ${selectedCount} user${
        selectedCount > 1 ? 's' : ''
    }. Are you sure you want to continue?`;
};

const formatList = (list, capitalize = false) => {
    const newList = capitalize ? list.map((item) => strToCapital(item)) : list;
    if (newList.length === 0) {
        return;
    } else if (newList.length === 1) {
        return newList[0];
    } else if (newList.length === 2) {
        return newList.join(' and ');
    } else {
        const lastItem = newList.pop();
        return `${newList.join(', ')}, and ${lastItem}`;
    }
};

const multilineSplit = (arr, prop) => {
    return arr
        ?.map((me) => {
            return me[prop];
        })
        ?.sort()
        ?.reduce(
            (acc, x) =>
                acc === null ? (
                    x
                ) : (
                    <>
                        {acc},<br /> {x}
                    </>
                ),
            null,
        );
};

const formatNoSpaces = (text) => {
    return text?.replace(/\s/g, '')?.toUpperCase();
};

const stringCompareIgnoreSpace = (a, b) => {
    return formatNoSpaces(a) === formatNoSpaces(b);
};

const comparePostcodesForValidity = (p1, p2) => {
    const format = (p) => {
        const trimmedPostcode = formatNoSpaces(p);
        if (trimmedPostcode?.length > 1) return trimmedPostcode.slice(0, -2);
        return trimmedPostcode;
    };

    return format(p1) == format(p2);
};

const arrayNotEmpty = (list) => list && list.length > 0;

const mapArrayToOptionsField = (fields) => {
    return fields?.map((f) => mapObjectToOptionsField(f));
};

const mapObjectToOptionsField = (field) => {
    return {
        value: field?.id,
        label: field?.name,
    };
};

const mapStringToOptionsField = (field) => {
    if (!field) return;
    return {
        value: field,
        label: field,
    };
};

const reverseMapArrayOptions = (fields) => {
    return fields?.map((field) => {
        return {
            id: field.value,
        };
    });
};

const reverseMapArrayValue = (fields) => {
    return fields?.map((field) => field.label);
};

const checkPostcodeIsValid = (postcodeVal, groupPostcodes) => {
    let isValid = true;
    if (postcodeVal && postcodeVal.trim() != '' && groupPostcodes) {
        for (const groupPostcode of groupPostcodes) {
            const isValidForGroup = groupPostcode
                ?.split(',')
                ?.some((postcode) => comparePostcodesForValidity(postcode, postcodeVal));

            if (!isValidForGroup) {
                isValid = false;
                break;
            }
        }
    }

    return isValid;
};

const testPostcode = (groupPostcodes) => {
    try {
        const postcodeEl = $('input[name="postcode"]');
        const addressEl = $('textarea[name="address"]');
        const addressGroupEl = $('#address-group');

        const checkShowAddress = () => {
            const isValid = checkPostcodeIsValid(postcodeEl.val(), groupPostcodes);

            if (isValid) {
                addressGroupEl.hide();
            } else {
                addressGroupEl.show();
            }
        };

        postcodeEl.on('change keyup paste', _.debounce(checkShowAddress, 1000));

        // If address is set, show it. If it's not set - check postcode value to find out if should show
        if (!addressEl?.val() || addressEl?.val()?.trim() == '') {
            checkShowAddress();
        }
    } catch (error) {
        console.error(error);
        $('#address-group')?.show();
    }
};

const imageToData = (image) => {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(image);
    });
};

const sortOptions = (a, b) => {
    // Check what a and b are so we can sort by the right thing
    const itemA = Array.isArray(a) ? a[0] : a;
    const itemB = Array.isArray(b) ? b[0] : b;
    // some of the dates come in as year ranges, and should be reversed
    const reverse = itemA.split('-').every((el) => !isNaN(el));
    if (Number(itemA) && Number(itemB)) {
        return itemB - itemA;
    } else if (app.moment(itemA).isValid() && app.moment(itemB).isValid()) {
        return app.moment(itemB) - app.moment(itemA);
    } else {
        // Don't sort strings of text, just numbers
        if (reverse) {
            if (itemA < itemB) {
                return 1;
            } else {
                return -1;
            }
        }
    }
};

const navigateToParentWindow = (url) => {
    if (window.opener) {
        window.opener.open(url, '_blank');
    } else {
        window.open(url, '_blank');
    }
};

const getUserFamilies = () => {
    return app.user.attributes.user_groups.reduce(
        (famArray, group) => [
            ...famArray,
            ...group.libraries.reduce(
                (titleArr, library) => [...titleArr, ...library.families.split(', ')],
                [],
            ),
        ],
        [],
    );
};

const formatHtmlId = (idString) => {
    return idString
        .replaceAll(' ', '_')
        .replaceAll('(', '\\(')
        .replaceAll(')', '\\)')
        .replaceAll('&', '\\&')
        .replaceAll('/', '\\/');
};

const getNestedPath = (nestedObject, value) => {
    const nestArr = [];
    Object.entries(nestedObject).forEach(([key, val]) => {
        if (val instanceof Object) {
            const nested = getNestedPath(val, value);
            if (nested.length) {
                nestArr.splice(0, 0, key);
                nestArr.push(...nested);
            }
        } else {
            if (key === value) {
                nestArr.push(key);
            }
        }
    });
    return nestArr;
};

const truncateString = (str, n) => {
    let count = 0;
    let inTag = false;
    const cutString = str.split('').map((char) => {
        if (count <= n) {
            switch (char) {
                case '<':
                    inTag = true;
                    break;
                case '>':
                    if (inTag) {
                        inTag = false;
                    }
                    break;
                case '/':
                    if (!inTag) {
                        count++;
                    }
                    break;
                default:
                    if (!inTag) {
                        if (count === n) {
                            char += '...';
                            count++;
                            break;
                        } else if (count < n) {
                            count++;
                            break;
                        } else {
                            char = '';
                            count++;
                            break;
                        }
                    }
                    break;
            }
            return char;
        } else {
            return '';
        }
    });
    return cutString.join('');
};

const handleScrollToOption = () => {
    const hash = window.location.hash;
    const element = document.getElementById(hash.replace('#', ''));
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    }
};

const overrideIds = ['location', 'annotation-modal-title', 'annotation-modal-title-row'];

export {
    arrayNotEmpty,
    asyncAction,
    attributeToStr,
    checkPostcodeIsValid,
    comparePostcodesForValidity,
    csvFromText,
    doFetchSelectableTable,
    formatAlertFrequency,
    formatHtmlId,
    formatList,
    formatToReadableText,
    formatUserName,
    getAbbrevDate,
    getAppInstance,
    getConfirmationMessageUsers,
    getDate,
    getDateTime,
    getFileDate,
    getFilterValues,
    getHeadersFormatted,
    getMediumDateTime,
    getNestedPath,
    getSelectedFilterValues,
    getShortDate,
    getUserFamilies,
    handleSaveErrors,
    handleScrollToOption,
    imageToData,
    mapArrayToOptionsField,
    mapObjectToOptionsField,
    mapStringToOptionsField,
    multilineSplit,
    navigateToParentWindow,
    overrideIds,
    post,
    readFileAsText,
    reverseMapArrayOptions,
    reverseMapArrayValue,
    sortOptions,
    stringCompareIgnoreSpace,
    strToCapital,
    tableAsyncAction,
    testPostcode,
    truncateString,
};
