import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { useStickyScroll } from '../customHooks';

const CalendarMarker = ({ item }) => {
    const scrollRef = useRef();

    useStickyScroll(scrollRef);

    return (
        <>
            {item?.display_hidden ? (
                <div id={item.calendar_marker}></div>
            ) : (
                <div
                    id={item.calendar_marker.replace(/\s/, '-')}
                    ref={scrollRef}
                    className={`timeline-calendar ${item.type}`}
                    start-dt={item['start-dt']} // eslint-disable-line
                    end-dt={item['end-dt']} // eslint-disable-line
                >
                    {item.calendar_marker}
                </div>
            )}
        </>
    );
};

export default CalendarMarker;

CalendarMarker.propTypes = {
    item: PropTypes.shape({
        'start-dt': PropTypes.string,
        type: PropTypes.string,
        calendar_marker: PropTypes.string,
        display_hidden: PropTypes.bool,
    }).isRequired,
};
