define([
    'backbone',
    '../models',
    './articles',
    '../templates/news/news_tabs.html',
    '../templates/news/news_current.html',
    '../templates/news/news_archive.html',
    '../templates/news/news_archive_listing.html',
    '../templates/news/news_by_date.html',
    '../templates/news/news_by_date_listing.html',
    '../templates/news/news_no_content.html'
], function (Backbone, models, articlesBase,
    tabsTpl, currentTpl, archiveTpl, archiveListingTpl, bydateTpl,
    bydateListingTpl, noContentTpl) {

    var CurrentTabView = articlesBase.CurrentTabView.extend({
        template: currentTpl
    });

    var ArchiveListingView = articlesBase.ArchiveListingView.extend({
        template: archiveListingTpl
    });

    var ArchiveTabView = articlesBase.ArchiveTabView.extend({
        template: archiveTpl,
        archiveListingView: ArchiveListingView
    });

    var ByDateListingView = articlesBase.ByDateListingView.extend({
        template: bydateListingTpl
     });

    var ByDateTabView = articlesBase.ByDateTabView.extend({
        template: bydateTpl,
        byDateListingView: ByDateListingView
    });

    var EmptyContentView = articlesBase.EmptyContentView.extend({
        el: '.pane-main',
        template: noContentTpl
    });

    var TOCView = articlesBase.TOCView.extend({
        template: tabsTpl,
        currentTabView: CurrentTabView,
        archiveTabView: ArchiveTabView,
        byDateTabView: ByDateTabView,
        documentCollectionModel: models.NewsDocuments,
        topicCollectionModel: models.NewsTopics
    });

    var DocumentView = articlesBase.DocumentView.extend({
        tocView: TOCView,
        documentModel: models.NewsDocument,
        emptyContentView: EmptyContentView
    });

    return {
        DocumentView: DocumentView
    }
});
