/*
    Bootstrap helpers
*/
define(['jquery', 'underscore', 'moment', 'bootstrap'], function ($, _, moment) {
    // focus to the first field of all bootstrap modals
    $('body').on('shown.bs.modal', function () {
        $('.modal:visible :text:visible:first').focus();
    });

    // close modals on page navigation
    $(window).on('popstate pushstate', function () {
        $('.modal').modal('hide');
    });

    /*
            toggle elements - bit fragile for the moment, but does the trick,
            specifying with data attributes would make this more robust.

            <div id="foo">Hello</div>
            <button class="toggle-control" data-toggle-target="foo">hide</button>
            <button class="toggle-control" style="display:none;" data-toggle-target="foo">show</button>
        */
    $('body').on('click.toggle-control.data-api', '[data-toggle-target]', function (ev) {
        var $this = $(this);
        var $target = $('#' + $this.data('toggle-target'));
        $target.slideToggle(function () {
            $this.siblings('.toggle-control').show();
            $this.hide();
        });
    });

    // fade alerts out then remove them
    $('body').on('close.bs.alert', function (ev) {
        ev.preventDefault();
        $(ev.target).fadeOut(function () {
            $(this).remove();
        });
    });

    // temporary duration formatting for moment
    // https://github.com/moment/moment/issues/463
    // currently shows mm:ss
    // moment.duration(123, 'seconds').format('mm:ss')
    moment.duration.fn.format = function (fmt) {
        if (fmt == 'mm:ss') {
            var str = '';
            if (this.hours() > 1) {
                str += ('0' + this.hours()).slice(-2) + ':';
            }
            str += ('0' + this.minutes()).slice(-2) + ':' + ('0' + this.seconds()).slice(-2);
            return str;
        }
        throw 'Not implemented';
    };

    // really noddy Flash version detection
    function getFlashPlayerVersion() {
        var verDescr;
        try {
            verDescr = new ActiveXObject('ShockwaveFlash.ShockwaveFlash').GetVariable('$version');
        } catch (e) {
            try {
                if (navigator.mimeTypes['application/x-shockwave-flash'].enabledPlugin) {
                    verDescr = (
                        navigator.plugins['Shockwave Flash 2.0'] ||
                        navigator.plugins['Shockwave Flash']
                    ).description;
                }
            } catch (e) {}
        }
        if (verDescr) {
            try {
                version = verDescr
                    .replace(/\D+/g, ',')
                    .match(/^,?(.+),?$/)[1]
                    .split(',');
                return {
                    major: version[0],
                    minor: version[1],
                    release: version[2],
                    description: verDescr,
                };
            } catch (e) {}
        }
        return { major: null, minor: null, release: null, description: null };
    }

    window.FLASH_PLAYER_VERSION = getFlashPlayerVersion();
});
