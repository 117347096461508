module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table class="ui-table results-table multidoc-results">\n    <thead>\n        <tr>\n            ';
 if (app.user.has_perm('content.view_all_document_states')) { 
__p+='\n            <th class="status sortable" scope="col" data-sort_field="doc_state" title="Sort by Status">\n                Status <span class="picon" title="toggle sort"></span>\n            </th>\n            <th class="doc-id sortable" scope="col" data-sort_field="doc_id" title="Sort by Document ID">\n                ID <span class="picon" title="toggle sort"></span>\n            </th>\n            ';
 } 
__p+='\n            <th class="hits sortable sort-client" scope="col" data-sort_field="hits">\n                ';
 if (includeHits) { 
__p+='\n                    Hits <span class="picon" title="toggle sort"></span>\n                ';
 } 
__p+='\n            </th>\n            <th class="fragments sortable sort-client" scope="col" data-sort_field="fragments">\n                ';
 if (includeHits) { 
__p+='\n                    Extracts <span class="picon" title="toggle sort"></span>\n                ';
 } 
__p+='\n            </th>\n            <th class="family sortable" scope="col" data-sort_field="doc_primary_family_title_sort" title="Sort by Family">\n                Family <span class="picon" title="toggle sort"></span>\n            </th>\n            <th class="date sortable" scope="col" data-sort_field="doc_date" title="Sort by Date">\n                Date <span class="picon" title="toggle sort"></span>\n            </th>\n            <th class="title sortable" scope="col" data-sort_field="doc_title_sort" title="Sort by Document Title">\n                Document Title <span class="picon" title="toggle sort"></span>\n            </th>\n        </tr>\n    </thead>\n    <tbody></tbody>\n</table>\n';
}
return __p;
};
