import React from 'react';
import PropTypes from 'prop-types';

import PiconIcon from '../FormControls/Icons/PiconIcon';

const EditButton = ({ title, handleEditor }) => {
    const iconColour = app.darkMode ? 'lighter-blue' : 'blue';

    return (
        <button
            className="btn-link editable-content-btn"
            title={title}
            onClick={() => handleEditor()}
        >
            <PiconIcon iconName="edit" className={`${iconColour}`} />
        </button>
    );
};

export default EditButton;

EditButton.propTypes = {
    title: PropTypes.string,
    handleEditor: PropTypes.func,
};
