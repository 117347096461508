import PropTypes from 'prop-types';

import confirm from '../FormControls/ConfirmModal/ConfirmModalService';
import { PRIVACY, PRIVACY_DISPLAY } from './Privacy';

export const openDocument = (evt, item) => {
    evt.preventDefault();
    const url =
        app.urls.documents +
        '/' +
        item.document +
        '?annotation=' +
        item.id +
        '#' +
        encodeURIComponent(item.ref_id);
    if (window.opener) {
        window.opener.location.href = url;
    } else {
        window.location.href = url;
    }
};

export const formatGroups = (groups, nested, parentValue) => {
    if (!groups) return [];

    return groups
        .map((group) => {
            return {
                value: group.id,
                label: app.user.attributes.is_superuser ? group.description : group.name,
                ...(nested && { nested }),
                ...(parentValue && { parentValue }),
            };
        })
        .sort((a, b) => a.label.localeCompare(b.label));
};

export const formatProjects = (projects, nested, parentValue) => {
    if (!projects) return [];

    return projects
        .map((project) => {
            return {
                value: `project-${project.id}`,
                label: project.title,
                ...(nested && { nested }),
                ...(parentValue && { parentValue }),
            };
        })
        .sort((a, b) => a.label.localeCompare(b.label));
};

export const getChecked = (props, option) => {
    if (props.selectProps?.selectAll) {
        return true;
    }
    if (props.isSelected) {
        return true;
    }
    if (
        option?.parentValue &&
        props.selectProps.value.some((prop) => prop.value === option.parentValue)
    ) {
        return true;
    }
    return false;
};

export const confirmAnnoDelete = async (item) => {
    return await confirm({
        title: 'Delete annotation',
        message: `Are you sure you want to delete this ${
            PRIVACY_DISPLAY[item.model.attributes.privacy]
        } annotation? ${
            item.model.attributes.privacy === PRIVACY.PROJECT
                ? 'It will be removed from the project ' +
                  item.model.attributes.project_title +
                  ' and no longer visible to anyone.'
                : ''
        }`,
        confirmText: 'Confirm deletion',
        centered: false,
        position: getModalPosition(),
    });
};

export const getModalPosition = () => {
    const firstModal = document.querySelector('.react-draggable');
    if (firstModal) {
        return firstModal.style.transform;
    } else {
        return 'translate(0px, 0px)';
    }
};

export const annotationPropTypes = {
    can_edit: PropTypes.bool,
    doc_title: PropTypes.string,
    document: PropTypes.string,
    id: PropTypes.number,
    last_modified: PropTypes.string,
    model: PropTypes.object,
    modifier: PropTypes.string,
    org_name: PropTypes.string,
    privacy: PropTypes.oneOf(['PR', 'PB', 'GB', 'GP', 'PJ']),
    ref_id: PropTypes.string,
    ref_label: PropTypes.string,
    owner_name: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    project_name: PropTypes.string,
    project: PropTypes.number,
};

export const annotationsModel = {
    attributes: PropTypes.shape(annotationPropTypes),
    getAnnotationGroups: PropTypes.func.isRequired,
    fetch: PropTypes.func.isRequired,
};

export const annotationModel = {
    save: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
};

export const initialAnnoState = {
    number: 0,
    sectionID: '',
    showModal: () => true,
    sectionLabel: '',
    docID: '',
    model: PropTypes.shape(annotationModel),
};
