import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import FormModal from '../FormControls/FormModal';
import SelectedLister from '../FormControls/SelectedLister';
import PostcodeChanger from '../Forms/PostcodeChanger';
import confirm from '../FormControls/ConfirmModal/ConfirmModalService';
import ConfirmationEmailSelector from '../FormControls/ConfirmationEmailSelector';
import SimpleTableControl from '../FormControls/Table/SimpleTableControl';
import { getSelectedFilterValues, asyncAction, stringCompareIgnoreSpace } from '../utils';
import { TableContext } from '../FormControls/Table/TableContext';
import { getPostcodes } from '../UserDetails/utils';
import { useFetch } from '../customHooks';
import ValidCheckMessage from '../FormControls/ConfirmModal/ValidCheckMessage';

const formatPostcode = (postcode) => {
    return postcode?.replace(' ', '')?.toUpperCase() || 'No postcode registered';
};

const checkPostcodesAreValid = (selectedData, postcodeList) => {
    // Check that either postcode for all addressed is selected, or all postcodes are selected individually
    if (selectedData.postcodeForAll) {
        return true;
    }

    let isValid = true;
    for (const key of Object.keys(postcodeList)) {
        if (!selectedData.postcodes || !selectedData.postcodes[key]) {
            isValid = false;
            break;
        }
    }

    return isValid;
};

const OfficeAddressModal = ({ hideModal, usersModel, orgId }) => {
    const { tableState } = useContext(TableContext);

    const [data, setData] = useState({});
    const [postcodesInvalid, setPostcodesInvalid] = useState(false);

    const fetchPostcodes = async () => {
        const result = await usersModel.getSelectedPostcodes({
            org_id: orgId,
        });

        return getPostcodes(result);
    };

    const { data: postcodeOptions, loading: postcodesLoading } = useFetch({
        doFetch: async () => await fetchPostcodes(),
        defaultState: [],
    });

    const { data: selected, loading } = useFetch({
        doFetch: async () => await usersModel.getSelectedUsers(getSelectedFilterValues(tableState)),
        defaultState: [],
    });

    const postcodeList = selected
        .reduce((arr, obj) => [...arr, formatPostcode(obj.user_details?.postcode)], [])
        .reduce((obj, item) => {
            obj[item] = (obj[item] || 0) + 1;
            return obj;
        }, {});

    const checkInputValidity = () => {
        const isValid = checkPostcodesAreValid(data, postcodeList);
        setPostcodesInvalid(!isValid);
        return isValid;
    };

    const changePostcodeData = (newState) => {
        setData(newState);

        // On change only set it if it is valid. Setting it to invalid is left for validation that happens on submit
        if (checkPostcodesAreValid(newState, postcodeList)) {
            setPostcodesInvalid(false);
        }
    };

    const getTableColumns = (item) => {
        return [item.old_postcode, item.new_postcode, item.userCount];
    };

    const getAddressChangeConfirmation = (getData, skippedData) => {
        const headers = ['Existing postcode', 'Change to', 'Users affected'];

        return (
            <>
                <div className="table-container">
                    <SimpleTableControl
                        className="confirm-table"
                        doFetch={getData}
                        {...{ getTableColumns, headers }}
                    />
                </div>
                {skippedData.length > 0 && (
                    <ValidCheckMessage
                        message={`${skippedData.join(
                            ', ',
                        )} postcodes will be skipped, as they are being changed to same postcode`}
                    />
                )}
            </>
        );
    };

    const confirmForm = async (changedData, skippedData) => {
        const getData = () => {
            return changedData;
        };

        const confirmedResults = await confirm({
            title: 'Confirm office address change',
            message: getAddressChangeConfirmation(getData, skippedData),
        });
        return confirmedResults;
    };

    const submitForm = async (form) => {
        const changedData = [];
        const skippedData = [];
        const updatedSelected = selected
            .map((user) => {
                const old_postcode = formatPostcode(user.user_details?.postcode);
                const new_postcode = data.postcodeForAll
                    ? data.postcodeForAll.value.trim()
                    : data.postcodes[old_postcode]?.value;

                const address = form.elements.address?.value ?? '';

                const samePostcode = stringCompareIgnoreSpace(old_postcode, new_postcode);

                if (samePostcode) {
                    if (!skippedData.some((p) => stringCompareIgnoreSpace(p, new_postcode))) {
                        skippedData.push(new_postcode);
                    }

                    return { skipped: true };
                }
                if (
                    !changedData.some((item) =>
                        stringCompareIgnoreSpace(item.old_postcode, old_postcode),
                    )
                ) {
                    changedData.push({
                        old_postcode,
                        new_postcode,
                        userCount: postcodeList[old_postcode],
                        changed: 'postcode',
                        old_address: user.user_details?.address,
                        new_address: '',
                    });
                }

                return {
                    user_id: user.id,
                    email: user.email,
                    first_name: user.first_name,
                    last_name: user.last_name,
                    postcode: new_postcode,
                    address,
                };
            })
            .filter((p) => !p.skipped);

        // Because the template tags from admin don't match what django is looking for
        // TODO Why ^? Should fix that.
        const template =
            form.elements.emailTemplate.value === 'admin_address_change'
                ? 'address_change'
                : form.elements.emailTemplate.value;
        const confirmed = await confirmForm(changedData, skippedData);
        if (confirmed === false) return false;
        const fun = (params) => usersModel.updateAddress(params);

        await asyncAction({
            fun,
            args: {
                users: updatedSelected,
                update_count: changedData.length,
                email_template: template,
                context: changedData,
            },
            actionName: 'change office address',
        });
    };

    return (
        <FormModal
            title="Change office address"
            {...{
                show: true,
                submitForm,
                loading: loading || postcodesLoading,
                hideModal,
                checkInputValidity,
            }}
        >
            <SelectedLister
                selected={selected}
                type={selected.length > 1 ? 'users' : 'user'}
                property="email"
            />
            <PostcodeChanger
                {...{
                    postcodeOptions,
                    postcodeList,
                    data,
                    setData: changePostcodeData,
                    postcodesInvalid,
                }}
            />
            <ConfirmationEmailSelector
                templateLabel={app.emailTemplateLabels.BULK_CHANGE_POSTCODES}
                action="change office addresses"
            />
        </FormModal>
    );
};

export default OfficeAddressModal;

OfficeAddressModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    usersModel: PropTypes.shape({
        fetch: PropTypes.func.isRequired,
        getSelectedPostcodes: PropTypes.func.isRequired,
        getSelectedUsers: PropTypes.func.isRequired,
        updateAddress: PropTypes.func.isRequired,
    }),
    orgId: PropTypes.number.isRequired,
};
