define([
    'backbone',
    'bootbox',
    '../helpers',
    './calendar',
    './timetraveller',
    '../../templates/document/titlebar.html',
    '../../templates/document/toolbar_bottom.html',
    '../../templates/document/significant_dates.html',
    '../../widgets/windowbox',
    'jqueryCookie',
], function (
    Backbone,
    bootbox,
    helpers,
    CalendarView,
    TimeTraveller,
    titlebarTpl,
    toolbarBtmTpl,
    significantDatesTpl,
) {
    /*
        Significant dates popup
    */
    var SignificantDatesView = helpers.TemplateView.extend({
        template: significantDatesTpl,
        events: {
            'click .sig-date': 'updateDate',
        },
        initialize: function () {
            this.dates = [];
            this.section = '';
            this.listenTo(this.model, 'norris:initialized:dates', this.setDates);
            this.listenTo(this.model, 'document:section:change', this.sectionChange);
            if (this.model.get('timetravel')) {
                this.timeTraveller = new TimeTraveller(this);
            }
        },
        templateHelpers: function () {
            return {
                dates: this.dates.length ? Array.from(new Set(this.dates)).sort() : this.dates,
                section: this.section,
            };
        },
        setDates: function (norris) {
            this.norris = norris;
            this.changeDates();
        },
        sectionChange: function (sections) {
            this.currentSections = sections;
            this.changeDates();
        },
        changeDates: function () {
            if (this.norris && this.currentSections) {
                this.dates = [];
                var container = this.currentSections[0];
                var con_label = this.norris.getLabelFromId(container);
                var secs = this.currentSections;
                var sec_labels = [];
                if (this.currentSections.length > 1) {
                    secs = secs.slice(1);
                }
                _.each(
                    secs,
                    _.bind(function (sect) {
                        this.dates = this.dates.concat(this.norris.getDatesFromSection(sect));
                        sec_labels.push(this.norris.getLabelFromId(sect));
                    }, this),
                );
                if (secs.length > 1) {
                    this.section = con_label;
                    // include all sub-sections of a higher level
                    if (sec_labels[0] && _.last(sec_labels)) {
                        // delimiter if supersection has lbl
                        if (!_.isEmpty(this.section)) {
                            this.section += '; ';
                        }
                        this.section += sec_labels[0] + ' to ' + _.last(sec_labels);
                    }
                } else {
                    // If con_label is empty, try to use the first section label
                    this.section = con_label ? con_label : this.norris.getLabelFromId(secs[0]);
                }
                if (_.isEmpty(this.dates)) {
                    this.section = '';
                }
                if (this.section == 'top' || this.section == 'section') {
                    this.section = '';
                }
            } else {
                this.section = '';
                this.dates = [];
            }
            this.render();
        },
        render: function () {
            if (this.model.get('timetravel')) {
                var $sec = $('<div>').html(this.section);
                this.timeTraveller.filter(
                    $sec,
                    _.bind(function () {
                        this.section = $sec.html();
                        helpers.TemplateView.prototype.render.apply(this, arguments);
                    }, this),
                );
            }
        },
        updateDate: function (evt) {
            var date = app.moment(
                $(evt.currentTarget).find('.date').text(),
                app.settings.dateFormat,
            );

            if (date) {
                // Call the updateViewDate method on the calendar
                // that we passed in (this should be the 'from' calendar).
                // This will then go on to trigger a time travel update.
                this.options.calendar.updateViewDate(date);
            }
        },
    });

    var Calendar = function (options) {
        this.options = options;
        this.titlebar = options.titlebar;
        this.selector = options.selector;
        this.el = this.titlebar.$(this.selector); // The calendar text input field
        this.model = options.model;
        this.paramTTDate = options.paramTTDate;
        this.widgetId = options.widgetId;
        // Pass the initial date in as an option
        // Important that we assign the options.initialDate
        // to this.initialDate first as validateDate can return
        // this.initialDate for out of range dates. We still want
        // to show these. This allows highlighNotToday to work correctly.
        this.initialDate = options.initialDate;
        this.viewIsSet = options.viewIsSet;
        if (app.documentView) {
            this.initialDate = this.validateDate(this.initialDate);
        } else {
            this.listenTo(app, 'compareView:render', function () {
                // in case we're time-travelling into a compareView
                // we need to wait until the view is ready
                this.viewIsSet = true;
                return (this.initialDate = this.validateDate(this.initialDate));
            });
        }
        this.highlighNotToday();
        this.initCalendar();
    };

    _.extend(Calendar.prototype, Backbone.Events, {
        initCalendar: function () {
            this.el
                .windowbox({
                    container: 'body',
                    placement: 'bottom right',
                    trigger: 'click',
                    id: this.widgetId,
                    className: 'dd-date',
                    title: 'View Date',
                    view: new CalendarView({
                        getViewDate: _.bind(this.getViewDate, this),
                        calendar: this,
                    }),
                })
                .on(
                    'show.bs.windowbox',
                    _.bind(function () {
                        this.el.select();
                        if ($('.windowbox.in').length) {
                            // If we've opened another calendar whilst in a calendar,
                            // this closes the previous one and tidies things up.
                            const previousWindowId = $('.windowbox.in').attr('id');
                            const previousInput = document.querySelector(
                                `[aria-describedby="${previousWindowId}"`,
                            );
                            $(previousInput).trigger('click');
                        }
                        // TODO: Will this work .calendar-inline with side by side view?
                        this.titlebar
                            .$('.calendar-inline')
                            .datepicker('setDate', this.initialDate.toDate());
                    }, this),
                )
                .on('hidden.bs.windowbox', function (event) {
                    // This allows the windowbox calendar to pop back up with only 1 click
                    $(event.target).data('bs.windowbox').inState.click = false;
                });
        },
        getViewDate: function () {
            var viewDate = this.el.val();
            return app.moment(viewDate, app.settings.dateValidFormats, true);
        },
        validateBetweenDates: function (date) {
            var valid = true;
            if (_.isUndefined(date)) {
                date = this.initialDate;
            }
            if (
                (app.documentView && app.documentView.content.model.ttViewOption == 'tt-between') ||
                (app._compareView &&
                    app._compareView.models?.left?.model?.ttViewOption === 'tt-between')
            ) {
                var ttViewDate = app._compareView
                    ? app._compareView.contentViews.left.model.ttViewDate
                    : app.documentView.content.model.ttViewDate;
                var ttViewDate2 = app._compareView
                    ? app._compareView.contentViews.left.model.ttViewDate2
                    : app.documentView.content.model.ttViewDate2;
                if (this.paramTTDate == 'tt_date' && date.isAfter(ttViewDate2)) {
                    bootbox
                        .alert({
                            title: 'Invalid between dates',
                            message:
                                'The first date (' +
                                date.format(app.settings.dateFormat) +
                                ') is after the second date (' +
                                ttViewDate2.format(app.settings.dateFormat) +
                                ').',
                        })
                        .on(
                            'hidden.bs.modal',
                            _.bind(function () {
                                this.el.select();
                            }, this),
                        );
                    valid = false;
                } else if (this.paramTTDate == 'tt_date2' && date.isBefore(ttViewDate)) {
                    bootbox
                        .alert({
                            title: 'Invalid between date',
                            message:
                                'The second date (' +
                                date.format(app.settings.dateFormat) +
                                ') is before the first date (' +
                                ttViewDate.format(app.settings.dateFormat) +
                                ').',
                        })
                        .on(
                            'hidden.bs.modal',
                            _.bind(function () {
                                this.el.select();
                            }, this),
                        );
                    valid = false;
                }
            }
            return valid;
        },
        validateDate: function (dateToValidate) {
            var date = app.moment(dateToValidate, app.settings.dateValidFormats, true);
            if (!this.validateBetweenDates(date)) {
                return this.initialDate;
            }
            if (
                !date.isValid() ||
                date.isBefore(
                    app.moment(app.settings['dateLowerBound'], app.settings.dateFormat),
                ) ||
                date.isAfter(app.moment(app.settings['dateUpperBound'], app.settings.dateFormat))
            ) {
                bootbox
                    .alert({
                        title: 'Invalid date',
                        message:
                            'Please enter a valid date in the format ' +
                            app.settings.dateFormat +
                            " and make sure it's inclusive of " +
                            app.settings['dateLowerBound'] +
                            ' and ' +
                            app.settings['dateUpperBound'] +
                            '.',
                    })
                    .on(
                        'hidden.bs.modal',
                        _.bind(function () {
                            this.el.select();
                        }, this),
                    );
                return this.initialDate;
            }
            return date;
        },
        highlighNotToday: function () {
            // highlight field if not today's date
            this.el.toggleClass(
                'not-today',
                this.initialDate.isValid() && !app.moment().isSame(this.initialDate, 'day'),
            );
        },
        updateViewDate: function (viewDate) {
            if (!viewDate || _.has(viewDate, 'originalEvent')) {
                viewDate = this.getViewDate();
            } else {
                // Make sure the view date is a moment date
                viewDate = app.moment(viewDate, app.settings.dateValidFormats, true);
            }

            // only update if date's changed
            if (
                !this.initialDate ||
                !this.initialDate.isSame(viewDate, 'day') ||
                !viewDate.isValid()
            ) {
                // if view is ready, then try to validate its dates
                if (this.viewIsSet) {
                    this.initialDate = this.validateDate(viewDate);
                    this.el.val(this.initialDate.format(app.settings.dateFormat));
                    this.highlighNotToday();
                    // Single trigger for a date change event. This allows the tt dates
                    // on the model to be updated before triggering the timetravel:change
                    // event.
                    this.model.trigger('timetravel:changedate');
                }
            }
        },
    });
    /*
    Title bar controls - search, dates, etc.
    */
    var TitlebarView = helpers.TemplateView.extend({
        template: titlebarTpl,
        events: {
            'keydown .view-date .form-control': 'enterViewDate',
            'blur .view-date .form-control': 'enterViewDate',
            'click .toggle-search-hits': 'toggleSearchHits',
            'click .pane-open-new': 'openDocument',
            'click #close-doc-pane': 'closeDocPane',
            'change .view-date .form-control': 'updateViewDate',
        },
        initialize: function () {
            // Get the initial date first as these are passed into the
            // calendar objects.
            this.initialDates = this.getInitialDates();
            this.listenTo(this.model, 'timetravel:change', this.setDatePickers);
            this.listenTo(this.model, 'timetravel:changedate', this.updateModelDates);
            this.listenTo(app._notesView, 'route', this.somethingHappened);
        },
        render: function () {
            helpers.TemplateView.prototype.render.apply(this, arguments);
            this.initWidgets();
            this.setFavourite();
            this.togglePaneTools();
            this.adjustPosition();
            if (this.model.get('timetravel')) {
                this.enableDatePickers();
            }
            if (this.model.get('document')) {
                this.$el.removeClass('titlebar-nodate');
            }
        },
        templateHelpers: function () {
            return {
                ttViewDate: this.initialDates.ttViewDate.format(app.settings.dateFormat),
                ttViewDate2: this.initialDates.ttViewDate2.format(app.settings.dateFormat),
            };
        },
        adjustPosition: function () {
            // this may be in a search result so the top of the title elements
            // need to be moved
            $('#content')
                .find('.panetitle')
                .css('top', parseInt($('#titlebar').css('top')) + 30);
        },
        enableDatePickers: function () {
            this.$('.has-modal-dd-date-from, .has-modal-dd-date-to, .btn-sig-date')
                .removeClass('disabled')
                .prop('disabled', false);
        },
        setDatePickers: function () {
            if (this.model.ttViewOption == 'tt-between') {
                this.$el.addClass('titlebar-betweendates');
                this.$('.view-date .has-modal-dd-date-to').show();
                this.$('.view-date .betweendates-separator').show();
                if (this.model.get('timetravel')) {
                    this.$('.view-date .has-modal-dd-date-from').prop('title', 'From date');
                    this.$('.view-date .has-modal-dd-date-to').prop('title', 'To date (inclusive)');
                }
                this.calendarFrom.updateViewDate();
                this.calendarTo.updateViewDate();
            } else {
                if (this.$el.hasClass('titlebar-betweendates')) {
                    this.$el.removeClass('titlebar-betweendates');
                    this.$('.view-date .has-modal-dd-date-from').prop('title', '');
                    this.$('.view-date .has-modal-dd-date-to').hide();
                    this.$('.view-date .betweendates-separator').hide();
                }
            }
            if (!this.model.get('timetravel')) {
                this.$('.view-date .has-modal-dd-date-from').prop(
                    'title',
                    'This document does not time travel',
                );
                this.$('.view-date .has-modal-dd-date-to').prop(
                    'title',
                    'This document does not time travel',
                );
            }
            this.setFavourite();
        },
        togglePaneTools: function (toggle) {
            // Pane tools should not disappear if we're in compare view and a document is being changed
            toggle = toggle || this.options.showPaneTools || app.documents.length > 1 || false;
            if (toggle) {
                $('body').addClass('with-pane-tools');
                this.$('.inner').css('padding-right', this.$('.pane-tools').width());
            } else {
                $('body').removeClass('with-pane-tools');
                this.$('.inner').css('padding-right', '10px');
            }
        },
        toggleSearchHits: function () {
            var toggle = this.$('.toggle-search-hits').hasClass('active');
            $('body').toggleClass('with-hits-hidden', toggle);
            _.defer(
                _.bind(function () {
                    this.$('.toggle-search-hits')
                        .toggleClass('active', !toggle)
                        .find('input')
                        .prop('checked', !toggle);
                }, this),
            );
        },
        openDocument: function (evt) {
            evt.preventDefault();
            evt.stopPropagation();
            var data = _.fromPairs([...new URLSearchParams(window.location.search)]);
            data['search'] = 'section';
            data['doc_id'] = this.model.get('document');
            data['doc_instance'] = this.model['id'];
            data['tab'] = 'current';
            data['view'] = 'document';
            data['tt_option'] = this.model.ttViewOption ? this.model.ttViewOption : 'tt-current';
            data['tt_date'] = this.model.ttViewDate.format(app.settings.dateTransportFormat);
            if (
                !_.isEqual(this.model.ttViewDate, this.model.ttViewDate2) &&
                this.model.ttViewOption === 'tt-between'
            ) {
                data['tt_date2'] = this.model.ttViewDate2.format(app.settings.dateTransportFormat);
            }
            window.manager.open(app.urls.search + '?' + $.param(data, true));
        },
        closeDocPane: function () {
            $('body').addClass('with-searchresults').addClass('with-searchresults-max');
            if (this.options.queryParams.q) {
                // Go back to section results
                app.trigger('search:closed');
            } else {
                // We didn't come through section results, so do go back that way
                app.trigger('search:skip-section-results');
            }
        },
        initWidgets: function () {
            // We pass in the model so we can trigger a time travel change event.
            this.calendarFrom = new Calendar({
                titlebar: this,
                selector: '.view-date .form-control.has-modal-dd-date-from',
                model: this.model,
                paramTTDate: 'tt_date',
                widgetId: 'dd-date',
                initialDate: this.initialDates.ttViewDate,
                viewIsSet: app._compareView ? false : true,
            });
            this.listenTo(this.model, 'document:ready', this.calendarFrom.validateBetweenDates);
            this.calendarTo = new Calendar({
                titlebar: this,
                selector: '.view-date .form-control.has-modal-dd-date-to',
                model: this.model,
                paramTTDate: 'tt_date2',
                widgetId: 'dd-date2',
                initialDate: this.initialDates.ttViewDate2,
                viewIsSet: app._compareView ? false : true,
            });
            // Pass in the calendarFrom so we can update
            // this calendar from the significant dates view.
            this.$('.btn-sig-date').windowbox({
                placement: 'bottom right',
                trigger: 'click',
                id: 'mv-sig',
                className: 'mv-sig',
                title: 'Significant dates',
                draggable: true,
                view: new SignificantDatesView({
                    model: this.model,
                    calendar: this.calendarFrom,
                }),
            });
            // Initially set the date pickers
            this.setDatePickers();
            // poover for significant dates
            if (app.user.get('display_prefs').contextual_help) {
                this.$('#has-modal-mv-sig').popover({
                    placement: 'bottom',
                    title: 'Significant Dates',
                    trigger: 'hover',
                    delay: 100,
                    container: 'body',
                    content:
                        'Show significant dates in the current section. Click to time travel the document to that date.',
                });
            }
        },
        getInitialDates: function () {
            var tt_paramsInit = _.fromPairs(
                _.map(window.location.search.slice(1).split('&'), function (p) {
                    return p.split('=');
                }),
            );
            var defaults = {
                tt_date:
                    this.model.ttViewDate && document.referrer
                        ? this.model.ttViewDate
                        : app.moment(),
                tt_date2:
                    this.model.ttViewDate2 && document.referrer
                        ? this.model.ttViewDate2
                        : app.moment(),
            };
            _.defaults(tt_paramsInit, defaults);
            var initViewOption = _.pick(tt_paramsInit, ['tt_option']);
            var params = _.pick(tt_paramsInit, ['tt_date', 'tt_date2']);
            //processing the url-params
            var tt_params = _.map(
                params,
                function (v, k) {
                    var d = app.moment(v, app.settings.dateTransportFormat);
                    if (!d.isValid()) {
                        return k == 'tt_date' ? this.ttViewDate : this.ttViewDate2;
                    } else {
                        return d;
                    }
                },
                this.model,
            );
            var initDates = _.zipObject(['ttViewDate', 'ttViewDate2'], _.values(tt_params));
            if (initDates.ttViewDate.isAfter(initDates.ttViewDate2)) {
                initDates.ttViewDate2 = initDates.ttViewDate;
            }
            if (this.model) {
                this.model.ttViewDate = initDates.ttViewDate;
                this.model.ttViewDate2 = initDates.ttViewDate2;
                if (initViewOption.tt_option) {
                    this.model.ttViewOption = initViewOption.tt_option;
                } else if (!document.referrer) {
                    this.model.ttViewOption = 'tt-current';
                }
            }
            return initDates;
        },
        updateViewDate: function (evt) {
            var el = $(evt.currentTarget);
            if (el.hasClass('has-modal-dd-date-from')) {
                this.calendarFrom.updateViewDate();
            } else if (el.hasClass('has-modal-dd-date-to')) {
                this.calendarTo.updateViewDate();
            }
        },
        enterViewDate: function (evt) {
            // Only update on enter being pressed
            if (evt.key === 'Enter') {
                var el = $(evt.currentTarget);
                el.windowbox('hide');
                el.trigger('blur');
            }
        },
        updateModelDates: function () {
            this.model.ttViewDate = this.calendarFrom.getViewDate();
            if (this.model.ttViewOption == 'tt-between') {
                this.model.ttViewDate2 = this.calendarTo.getViewDate();
            } else {
                // prevent inconsistent dates if not showing both calendars
                this.model.ttViewDate2 = this.model.ttViewDate;
                this.calendarTo.el.val(this.model.ttViewDate.format(app.settings.dateFormat));
            }
            // Trigger a timetravel update after setting the dates on the model.
            this.model.trigger('timetravel:change');
        },
        setFavourite: function () {
            const React = require('react');
            const ReactDOM = require('react-dom');
            const DocumentFavourite =
                require('../../components/DocumentFavourites/DocumentFavourite').default;

            const element = document.getElementById('title-favourite');
            const that = this;

            // Check for favourites in options to stop this titlebar taking over from compare view
            if (element && that.options.favourites) {
                ReactDOM.render(
                    <DocumentFavourite
                        document={that}
                        sectionLabel=""
                        sectionID=""
                        canDelete={true}
                    />,
                    element,
                );
            }
        },
    });

    /*
    Bottom bar controls - time-travel, colour key, etc.
    */
    var ToolbarBottomView = helpers.TemplateView.extend({
        template: toolbarBtmTpl,
        events: {
            'click .view-buttons .btn': 'timeTravel',
            'click .btn-colourkey': 'toggleColourKey',
        },
        templateHelpers: function () {
            return {
                ttViews: _.keys(this.model.ttViewDescriptions),
                ttViewDescriptions: this.model.ttViewDescriptions,
                ttViewTitles: this.model.ttViewTitles,
            };
        },
        render: function () {
            helpers.TemplateView.prototype.render.apply(this, arguments);
            this.setTTOption();
            this.showColourKeyBtn();
            if (this.model.get('timetravel')) {
                this.initializePopovers();
                this.initializeTTButtons();
            }
        },
        timeTravel: function (evt) {
            this.model.ttViewOption = $(evt.currentTarget).find('input').attr('id');
            this.model.trigger('timetravel:change');
        },
        initializePopovers: function () {
            if (app.user.get('display_prefs').contextual_help) {
                const buttons = this.$('.view-buttons .btn');

                for (const button of buttons) {
                    const content = $(button).find('.popover-content').html();
                    $(button).popover({
                        container: app._compareView ? '.toolbar-btm-left' : '.toolbar-btm',
                        placement: 'top',
                        trigger: 'hover',
                        delay: 100,
                        template:
                            '<div class="popover dd-viewhelp">' +
                            '<div class="arrow"></div><h3 class="popover-title"></h3>' +
                            '<div class="popover-content"></div></div>',
                        html: true,
                        content,
                    });
                }
            }
        },
        initializeTTButtons: function () {
            // Enable time travel buttons
            this.$('.view-buttons .btn').removeClass('disabled');

            // Enable colour key depending on user prefs
            if (app.user.get('display_prefs').is_colour_key) {
                this.$('.btn-colourkey').addClass('active');
                this.$('.msg-colourkey').show();
                app.trigger('help:colourkey', true, this.options.target);
            } else {
                this.$('.btn-colourkey').removeClass('active');
                this.$('.msg-colourkey').hide();
                app.trigger('help:colourkey', false, this.options.target);
            }
        },
        setTTOption: function () {
            if (this.model) {
                this.$('input[id="' + this.model.ttViewOption + '"]')
                    .closest('label.btn')
                    .addClass('active');
            }
        },
        showColourKeyBtn: function () {
            if (this.model.get('document')) {
                this.$('.btn-colourkey').show();
            } else {
                this.$('.btn-colourkey').hide();
            }
        },
        toggleColourKey: function () {
            this.$('.btn-colourkey').toggleClass('active');
            this.$('.msg-colourkey').toggle();
            var enable = this.$('.btn-colourkey').hasClass('active');

            const displayPrefs = _.clone(app.user.attributes.display_prefs);
            displayPrefs.is_colour_key = enable;

            try {
                // Use updated ajax syntax
                $.ajax({
                    url: '/preferences/update',
                    type: 'POST',
                    data: displayPrefs,
                })
                    .done(function (response) {
                        messages.success(response);
                    })
                    .fail(function () {
                        this.$('.msg-colourkey').html(
                            'Unable to save colour key preference. Please contact helpdesk',
                        );
                    });
            } catch (err) {
                this.$('.msg-colourkey').html(
                    'Unable to save colour key preference. Please contact helpdesk',
                );
            }

            app.trigger('help:colourkey', enable, this.options.target);
        },
    });

    return {
        TitlebarView: TitlebarView,
        ToolbarBottomView: ToolbarBottomView,
    };
});
