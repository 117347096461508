import React from 'react';
import PiconIcon from '../Icons/PiconIcon';
import PropTypes from 'prop-types';

const ValidCheckMessage = ({ message }) => {
    return (
        <div className="confirm-message">
            <PiconIcon className="left" iconName="cross-circle" />
            {message}
        </div>
    );
};

export default ValidCheckMessage;

ValidCheckMessage.propTypes = {
    message: PropTypes.string.isRequired,
};
