import React from 'react';

const ImportUsers = () => {
    if (app.user.has_perm('main.bulk_upload_users')) {
        return (
            <a className="btn btn-primary import-users btn-sm" href={app.urls.importUsers}>
                Import Users
            </a>
        );
    } else {
        return <div></div>;
    }
};

export default ImportUsers;
