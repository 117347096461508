module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<p class="news-msg">\nAll articles from 1997 to the present day are categorised into broad subject areas.\nSelect the topic of interest from the list below to show the fifty most recent archived\narticles or click a specific year to show all archived articles in the selected topic and year.\n</p>\n\n<div id="archive-message">\n    <p class="news-msg">Fetching archive, please wait...</p>\n</div>\n\n';
 _.each(collection.models, function(topic) { 
__p+='\n\n    <div class="topic-links">\n        <h3><a class="topic-title archive-link" href="" data-topic="'+
((__t=( topic.attributes.topic_id ))==null?'':_.escape(__t))+
'">'+
((__t=( topic.attributes.title ))==null?'':_.escape(__t))+
'</a></h3>\n\n        ';
 _.each(topic.attributes.years.sort(function (a, b)  { return b.year - a.year }), function(year) { 
__p+='\n\n            <span class="news-msg">\n                <a class="archive-link" href="" data-topic="'+
((__t=( topic.attributes.topic_id ))==null?'':_.escape(__t))+
'" data-year="'+
((__t=( year.year ))==null?'':_.escape(__t))+
'">'+
((__t=( year.year.toString().substr(2,2) ))==null?'':_.escape(__t))+
'</a>\n            </span>\n\n        ';
 }); 
__p+='\n    </div>\n\n    <div class="" id="archive-listing-'+
((__t=( topic.attributes.topic_id ))==null?'':_.escape(__t))+
'">\n    <!-- Archive news listing gets inserted here for each topic -->\n    </div>\n\n';
 }); 
__p+='\n\n\n';
}
return __p;
};
