const initialTableState = {
    filter: {},
    page: 1,
    itemsPerPage: 100,
    itemsCount: 0,
    sortColumn: '',
    selectedAll: false,
    selectedList: [],
    orderedList: [],
};

const getSelectedCount = (tableState) => {
    if (tableState.itemsCount == 0) return 0;

    if (tableState.selectedAll) {
        return tableState.itemsCount - tableState.selectedList?.length;
    }

    return tableState.selectedList?.length;
};

export { initialTableState, getSelectedCount };
