import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import { WithLoading, LoaderOverlay } from '../FormControls/WithLoading';
import Filter, { FILTER_TYPES } from '../FormControls/Table/Filter';
import { tableAsyncAction, getSelectedFilterValues, getConfirmationMessageUsers } from '../utils';
import confirm from '../FormControls/ConfirmModal/ConfirmModalService';
import { getSelectedCount } from '../FormControls/Table/utils';
import { TableContext } from '../FormControls/Table/TableContext';

import EmailDomainsModal from '../Modals/EmailDomainsModal';
import MergeUserGroupsModal from '../Modals/MergeUserGroupsModal';
import OfficeAddressModal from '../Modals/OfficeAddressModal';
import { useFetch } from '../customHooks';
import AnnotationModal from './Annotations/AnnotationModal';
import { NotifyUserModal } from '../Modals/NotifyUserModal';
import { getOrganisationsList } from '../UserDetails/utils';

const ACTION_MODALS = {
    NONE: '',
    ANNOTATIONS: 'annotations',
    EMAIL: 'email',
    NOTIFY: 'notify',
    OFFICE: 'office',
    DEACTIVATE: 'deactivate',
    REACTIVATE: 'reactivate',
    USER_GROUPS: 'userGroups',
};
Object.freeze(ACTION_MODALS);

//#region helpers

const flagFilters = [
    {
        value: 'all',
        label: 'Flagged - all',
    },
    {
        value: 'postcode',
        label: 'Postcode',
    },
    {
        value: 'email',
        label: 'Email',
    },
    {
        value: 'email-hard_bounce',
        label: 'Email - Hard bounce',
    },
    {
        value: 'email-soft_bounce',
        label: 'Email - Soft bounce',
    },
    {
        value: 'email-spam',
        label: 'Email - Spam',
    },
    {
        value: 'email-unsub',
        label: 'Email - Unsub',
    },
    {
        value: 'email-reject',
        label: 'Email - Reject',
    },
    {
        value: 'orphaned',
        label: 'Orphaned annotations',
    },
    {
        value: 'other',
        label: 'Other',
    },
];

const statuses = [
    {
        value: 'active',
        label: 'Active',
    },
    {
        value: 'deactivated',
        label: 'Deactivated',
    },
    {
        value: 'locked',
        label: 'Locked',
    },
    {
        value: 'pending',
        label: 'Pending',
    },
    {
        value: 'scheduled',
        label: 'Scheduled',
    },
];

const getActionItems = (sameOrganisation, isReadonlySubscriptionPage, userStatusState) => {
    // TODO: This list will need to be a lot more context sensitive depending on where the
    // user filter is being called from. Possibly think about adding the list in from
    // outside of the component
    const actionItems = [];

    const addItem = (permission, item) => {
        if (app.user.has_perm(`main.${permission}`)) {
            actionItems.push(item);
        }
    };

    addItem('download_user_list', {
        key: 'download',
        text: 'Download user list as CSV',
        disabled: false,
    });

    // Subscription page should be readonly, and only allow to download csv
    if (isReadonlySubscriptionPage) return actionItems;

    addItem('notify_user', {
        key: ACTION_MODALS.NOTIFY,
        text: 'Resend invitations',
        confirmationText: 'resend invitations to',
        shouldConfirm: true,
        disabled: false,
    });

    addItem('flag_user', {
        key: 'changeFlags',
        text: 'Change flags',
        disabled: false,
    });

    addItem('deactivate_user', {
        key: ACTION_MODALS.DEACTIVATE,
        text: 'Deactivate users',
        disabled: false,
        shouldConfirm: true,
        confirmationText: 'You are about to deactivate',
    });

    addItem('reactivate_user', {
        key: ACTION_MODALS.REACTIVATE,
        text: 'Reactivate users',
        confirmationText: 'reactivate',
        shouldConfirm: true,
        disabled:
            userStatusState?.length == 0 ||
            !userStatusState?.every((s) => ['deactivated', 'locked'].includes(s)),
    });

    addItem('reassign_annotations', {
        key: ACTION_MODALS.ANNOTATIONS,
        text: 'Reassign shared annotations',
        disabled: !sameOrganisation,
    });

    addItem('change_domains_bulk', {
        key: ACTION_MODALS.EMAIL,
        text: 'Change email domain',
        disabled: !sameOrganisation,
        bulkChange: true,
    });

    addItem('change_userdetails', {
        key: ACTION_MODALS.OFFICE,
        text: 'Change office address',
        disabled: !sameOrganisation,
        bulkChange: true,
    });

    addItem('change_subscriptiongroup', {
        key: ACTION_MODALS.USER_GROUPS,
        text: 'Merge user groups',
        disabled: !sameOrganisation,
        bulkChange: true,
    });

    return actionItems;
};

const getCountLabel = (count, selectedCount) => {
    return `${selectedCount}/${count} user${count == 1 ? '' : 's'} selected`;
};

const getGroups = (groups, currentOrg) => {
    return (
        groups
            .filter((group) => group.org_id === currentOrg?.id)
            ?.sort((a, b) => a.description.localeCompare(b.description))
            .map((g) => {
                return {
                    value: g.name,
                    label: g.description,
                };
            }) ?? []
    );
};

const getFilterFields = (isReadonlySubscriptionPage, state) => {
    const filterFields = [
        {
            type: FILTER_TYPES.SEARCH,
            placeholder: 'Users...',
        },
        !isReadonlySubscriptionPage && app.user.has_perm('main.view_all_organisations')
            ? {
                  type: FILTER_TYPES.MULTISELECT,
                  options: state.organisations,
                  placeholder: 'Organisations...',
                  name: 'org[]',
              }
            : {
                  type: FILTER_TYPES.MULTISELECT,
                  options: state.groups,
                  placeholder: 'Groups...',
                  name: 'groups[]',
              },
        {
            type: FILTER_TYPES.MULTISELECT,
            options: state.states,
            placeholder: 'Statuses...',
            name: 'states[]',
        },
    ];

    // Flags should not be displayed in Subscriptor Information Pages unless it's Pendragon Staff viewing
    if (app.user.has_perm('main.flag_user')) {
        filterFields.push({
            type: FILTER_TYPES.MULTISELECT,
            options: flagFilters,
            placeholder: 'Flags...',
            name: 'flags[]',
        });
    }

    return filterFields;
};

//#endregion

const UserFilter = ({ usersModel, currentOrganisation, showFlags }) => {
    const initialState = {
        organisations: [],
        states: statuses,
        groups: [],
        loading: true,
    };

    const [state, setState] = useState(initialState);
    const [loadingOverlay, setLoadingOverlay] = useState(false);

    const { tableState, dispatchTableState } = useContext(TableContext);

    const isReadonlySubscriptionPage = typeof currentOrganisation !== 'undefined';

    useEffect(() => {
        //TODO Change this
        usersModel.metainfo.done(function () {
            setState({
                ...state,
                organisations: getOrganisationsList(usersModel.metadata.organisations),
                groups: getGroups(usersModel.metadata.subscription_groups, currentOrganisation),
                loading: false,
            });
        });
    }, []);

    const checkUserStates = async () => {
        if (isReadonlySubscriptionPage) return [];
        if (getSelectedCount(tableState) == 0) return [];

        if (tableState.filter.state?.length === 1) {
            return tableState.filter.state;
        }

        try {
            const params = getSelectedFilterValues(tableState);
            const result = await usersModel.checkSelectedStates(params);
            return result?.states;
        } catch (err) {
            toaster.error(`Can not enable action reactivate`);
        }
    };

    const checkOrganisations = async () => {
        if (isReadonlySubscriptionPage) return { sameOrganisation: true };
        if (getSelectedCount(tableState) == 0) return { sameOrganisation: false };

        if (tableState.filter.organisations?.length === 1 && getSelectedCount(tableState) > 0) {
            return { sameOrganisation: true, orgId: tableState.filter.organisations[0].id };
        }

        try {
            const params = getSelectedFilterValues(tableState);
            const result = await usersModel.checkUsersOrganisation(params);
            return {
                sameOrganisation: result.organisation_check,
                orgId: result.org_id,
            };
        } catch (err) {
            const bulkActions = actionItems.reduce((arr, action) => {
                if (action.bulkChange) {
                    return [...arr, action.text];
                } else {
                    return arr;
                }
            }, []);
            toaster.error(`Can not enable actions: ${bulkActions.join(', ')}`);
            return { sameOrganisation: false };
        }
    };

    const organisationState = useFetch({
        doFetch: checkOrganisations,
        dependencies: [
            tableState.selectedList.length,
            tableState.selectedAll,
            tableState.itemsCount,
        ],
    });

    const userStatusState = useFetch({
        doFetch: checkUserStates,
        dependencies: [
            tableState.selectedList.length,
            tableState.selectedAll,
            tableState.itemsCount,
            tableState.dirtyData,
        ],
    });

    const filterFields = getFilterFields(isReadonlySubscriptionPage, state);
    const actionItems = getActionItems(
        organisationState.data?.sameOrganisation,
        isReadonlySubscriptionPage,
        userStatusState.data,
    );
    const selectedCount = getSelectedCount(tableState);
    const doAction = async (action) => {
        const actionItem = actionItems.find((i) => i.key === action);

        switch (action) {
            case 'changeFlags':
                showFlags();
                return;
            default:
                if (Object.values(ACTION_MODALS).includes(action)) {
                    setShowActionModal(action);
                    setActionProps(actionItem);
                    return;
                }
                break;
        }

        //If more than one selected, show standard confirmation message for some of the actions
        if (selectedCount > 1 && actionItem?.shouldConfirm) {
            const confirmedResult = await confirm({
                message: getConfirmationMessageUsers(actionItem.confirmationText, selectedCount),
            });

            if (!confirmedResult) return;
        }

        const fun = (params) => usersModel.action(params);

        const filePrefix = currentOrganisation ? currentOrganisation.name : 'pendragon-user-list';

        const result = await tableAsyncAction({
            fun,
            tableState,
            dispatchTableState,
            args: { action, filePrefix },
            actionName: action,
            setLoading: setLoadingOverlay,
        });

        //If backend checks revealed that additional confirmation of an action is required (e.g. user has annotations),
        // confirm and try to submit action again
        if (result?.confirm_required) {
            const confirmedResult = await confirm({
                message: result.message,
            });

            if (confirmedResult) {
                await tableAsyncAction({
                    fun,
                    tableState,
                    dispatchTableState,
                    args: { action, confirmed: true },
                    actionName: action,
                    setLoading: setLoadingOverlay,
                });
            }
        }
    };

    const actions = {
        actionItems,
        doAction,
        loading: organisationState.loading,
    };

    const labels = {
        countLabel: getCountLabel(tableState.itemsCount, selectedCount),
    };

    const IActionProps = {
        key: '',
        text: '',
        disabled: true,
        shouldConfirm: true,
        confirmationText: '',
    };

    const [showActionModal, setShowActionModal] = useState(ACTION_MODALS.NONE);
    const [actionProps, setActionProps] = useState(IActionProps);
    const hideModal = () => {
        setShowActionModal(ACTION_MODALS.NONE);
        setActionProps(IActionProps);
    };

    return (
        <WithLoading loading={state.loading}>
            <>
                <Filter
                    {...{
                        actions,
                        filterFields,
                        doAction,
                        labels,
                    }}
                />
                <LoaderOverlay loading={loadingOverlay} className="full-screen" />
                <EmailDomainsModal
                    show={showActionModal == ACTION_MODALS.EMAIL}
                    {...{ hideModal, usersModel }}
                />
                <MergeUserGroupsModal
                    show={showActionModal == ACTION_MODALS.USER_GROUPS}
                    {...{ hideModal, usersModel }}
                />
                {showActionModal == ACTION_MODALS.OFFICE && (
                    <OfficeAddressModal
                        {...{ hideModal, usersModel, orgId: organisationState.data?.orgId }}
                    />
                )}
                {showActionModal == ACTION_MODALS.ANNOTATIONS && (
                    <AnnotationModal
                        {...{
                            hideModal,
                            usersModel,
                            orgId: organisationState.data?.orgId,
                        }}
                    />
                )}
                {showActionModal == ACTION_MODALS.DEACTIVATE && (
                    <NotifyUserModal
                        {...{
                            hideModal,
                            usersModel,
                            confirmationText: actionProps.confirmationText,
                            action: actionProps.key,
                            title: actionProps.text,
                            includeEmailOptions: false,
                        }}
                    />
                )}
                {showActionModal == ACTION_MODALS.REACTIVATE && (
                    <NotifyUserModal
                        {...{
                            hideModal,
                            usersModel,
                            confirmationText: actionProps.confirmationText,
                            action: actionProps.key,
                            title: actionProps.text,
                        }}
                    />
                )}
                {showActionModal == ACTION_MODALS.NOTIFY && (
                    <NotifyUserModal
                        {...{
                            hideModal,
                            usersModel,
                            confirmationText: actionProps.confirmationText,
                            canSkipSendingEmail: false,
                            action: actionProps.key,
                            title: actionProps.text,
                        }}
                    />
                )}
            </>
        </WithLoading>
    );
};

export default UserFilter;

UserFilter.propTypes = {
    usersModel: PropTypes.shape({
        action: PropTypes.func.isRequired,
        metainfo: PropTypes.object.isRequired,
        metadata: PropTypes.object.isRequired,
        checkUsersOrganisation: PropTypes.func.isRequired,
        checkSelectedStates: PropTypes.func.isRequired,
    }),
    currentOrganisation: PropTypes.object,
    showFlags: PropTypes.func.isRequired,
};
