module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if(!_.isUndefined(section) && dates.length) { 
__p+='\n<h4 class="sigdates-title">\n    <strong>'+
((__t=( section ))==null?'':__t)+
'</strong>\n</h4>\n<ol class="sigdates-list">\n    ';
 _.each(dates, function(date) { 
        var date = app.moment(date, app.settings.dateTransportFormat);
        if(date.isValid()) {
    
__p+='\n    <li>\n        <button class="sig-date '+
((__t=( date.isAfter() ? 'future' : '' ))==null?'':_.escape(__t))+
' '+
((__t=( date.isSame('3000-01-01') ? 'all-in-force' : '' ))==null?'':_.escape(__t))+
' btn btn-link">\n            <span class="date">'+
((__t=( date.format(app.settings.dateFormat) ))==null?'':_.escape(__t))+
'</span> \n            <span class="description"></span>\n        </button>\n    </li>    \n    ';
  } 
    }) 
__p+='\n</ol>\n';
 } else {
__p+='\n<h4 class="sigdates-title">\n    There are no significant dates for this section\n</h4>\n';
 } 
__p+='\n';
}
return __p;
};
