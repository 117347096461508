define(['jquery', 'underscore'], function($, _) {

    /*
        * A simple manager class for things wot drag up and down and rearrange
        * loads of other elements in the process
        *
        * Arguments:
        * - $el is the thing you click and drag
        * - repositionCallback is the function wot gets called when a drag event is
        *   fired, reposition your elements in this callback.
        * - bounds is an associative array, {top: 0, bottom: 10} in pixels. Outside
        *   of these bounds, repositionCallback will not be fired.
        */
    var Dragger = function($el, options) {
        this.$el = $el;
        this.offsetX = 0;
        this.offsetY = 0;
        this.dragging = false;
        this.repositionCallback = options.repositionCallback; // fired during the drag event
        
        this.bounds = {
            top: 0,
            right: $(document).width(),
            bottom: $(document).height(),
            left: 0
        };        
        _.each(this.bounds, _.bind(function(val, side) {
            if(options.bounds[side]) {
                this.bounds[side] = (_.isFunction(options.bounds[side])) ? options.bounds[side]() : options.bounds[side];
            }
        }, this));
        
        this.clickCallback = options.clickCallback; // fired when the user has only clicked, not dragged
    
        this.initialize();
    };
    
    Dragger.prototype.initialize = function() {
        var _dragger = this;

        var mousemove = function(e) {
            _dragger.dragging = true;
        
            var offsetY = e.pageY + _dragger.offsetY;
            var offsetX = e.pageX + _dragger.offsetX;
        
            if(offsetY < _dragger.bounds.top || offsetY > _dragger.bounds.bottom ||
              offsetX < _dragger.bounds.left || offsetX > _dragger.bounds.right) {
                return;
            }
            
            _dragger.repositionCallback(offsetX, offsetY, e);      
        }
    
        var mouseup = function(e) {
            e.preventDefault();
            if(_dragger.clickCallback) {
                _dragger.clickCallback(_dragger.dragging);
            }
            _dragger.dragging = false;     
            $(document).off('mousemove', mousemove);      
            $(document).off('mouseup', mouseup);
        }
    
        this.$el.on('mousedown', function(e) {
            if(e.which == 1) {
                e.preventDefault();
                var offsetX = _dragger.$el.offset().left - e.pageX;
                var offsetY = _dragger.$el.offset().top - e.pageY;
                
                _dragger.offsetX = offsetX;
                _dragger.offsetY = offsetY;
                
                $(document).on('mousemove', mousemove);      
                $(document).on('mouseup', mouseup);        
            }
        });
    };

    $.fn.dragger = function(options) {
        var options = $.extend({}, $.fn.dragger.defaults, options);
        return this.each(function() {
            new Dragger($(this), options);
        });
    };

    $.fn.dragger.defaults = {
        callback: function() {},
        bounds: {}
    };

    return {
        Dragger: Dragger
    }
});
