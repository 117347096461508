module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="view-info">\n    <dl class="dl-horizontal">\n        <dt>Document</dt>\n        <dd>'+
((__t=( title ))==null?'':_.escape(__t))+
'</dd>\n        ';
 if(timetravel) { 
__p+='\n        <dt>View date'+
((__t=( ttViewOption == 'tt-between' ? 's' : '' ))==null?'':_.escape(__t))+
'</dt>\n        <dd>'+
((__t=( ttViewDateString ))==null?'':_.escape(__t))+
'</dd>\n        <dt>View</dt>\n        <dd class="'+
((__t=( ttViewOption ))==null?'':_.escape(__t))+
'">            \n            <strong>'+
((__t=( ttViewTitle ))==null?'':_.escape(__t))+
':</strong> '+
((__t=( ttViewDescription ))==null?'':__t)+
'\n        </dd> \n        ';
 } 
__p+='\n</dl></div>\n<div class="copyright">\n    <p>&copy; Pendragon 1996-'+
((__t=( app.moment().year() ))==null?'':_.escape(__t))+
' Pendragon</p> \n    <p>&copy; Crown and Parliamentary copyright material is reproduced with the permission of the Controller of HMSO and Queen\'s Printer for Scotland.</p>\n    <p>Pensions Benefits Law Reports are published with the consent of Pensions Law Limited. Pensions Law Reports are published with the consent of Income Data Services.</p>\n    <p>All materials on Perspective, including the above, is copyright and the copyright owners reserve all their rights.</p>\n</div>\n';
}
return __p;
};
