import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

export const INPUT_TYPE = {
    TEXTAREA: 'textarea',
    TEXT: 'text',
    EMAIL: 'email',
};
Object.freeze(INPUT_TYPE);

const FormInput = ({
    label,
    type,
    name,
    defaultValue,
    required,
    maxLength,
    onChange,
    invalidMessage,
    helpText,
}) => {
    let as;
    let inputType;

    switch (type) {
        case INPUT_TYPE.TEXTAREA:
            as = 'textarea';
            inputType = 'text';
            break;
        case INPUT_TYPE.EMAIL:
            inputType = 'email';
            break;
        default:
            inputType = 'text';
            break;
    }

    return (
        <Form.Group>
            <Form.Label>{label}</Form.Label>
            <Form.Control
                required={required}
                as={as}
                type={inputType}
                name={name}
                maxLength={maxLength}
                defaultValue={defaultValue}
                onChange={(e) => onChange(e.target.value, name)}
                aria-label={label}
            />
            {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
            <Form.Control.Feedback type="invalid">
                {invalidMessage ?? `Please select ${label.toLowerCase()}`}
            </Form.Control.Feedback>
        </Form.Group>
    );
};

export default FormInput;

FormInput.defaultProps = {
    type: INPUT_TYPE.TEXT,
    required: false,
    maxLength: 254,
    onChange: () => {},
};

FormInput.propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.oneOf([INPUT_TYPE.TEXT, INPUT_TYPE.TEXTAREA, INPUT_TYPE.EMAIL]).isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    maxLength: PropTypes.number.isRequired,
    defaultValue: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    invalidMessage: PropTypes.string,
    helpText: PropTypes.string,
};
