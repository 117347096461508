import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import ChangeEmailModal from '../Modals/ChangeEmailModal';
import DetailBlock from './DetailBlock';
import DetailEditModal from './DetailEditModal';
import EmailAlerts from './EmailAlerts';
import models from '../../models';
import ProjectInfoBlock from './ProjectInfoBlock';
import UserHistorySettings from './UserHistorySettings';
import { handleSaveErrors, handleScrollToOption } from '../utils';
import JumpToNav from './JumpToNav';
import ChangePasswordModal from '../Modals/ChangePasswordModal';
import ContentManager from '../ContentManagement/ContentManager';
import { USER_SETTING_CONTENT_TYPES } from '../ContentManagement/ContentContainer';

const EDIT_MODALS = {
    PASSWORD: 1,
    EMAIL: 2,
    GENERIC: 3,
};
Object.freeze(EDIT_MODALS);

const NESTED_DETAILS = {
    PHONE: 'phone',
    ADDRESS: 'address',
    POSTCODE: 'postcode',
    JOB_TITLE: 'job_title',
};
Object.freeze(NESTED_DETAILS);

export const Lozenge = ({ text }) => <div className="lozenge">{text.toUpperCase()}</div>;

const formatGroups = (groups) => {
    return (
        <div>
            {groups.map((group, i) => {
                return (
                    <div key={i} className="user-group-details">
                        {group.organisation} - {group.name.replace(`${group.organisation}-`, '')}
                        <Lozenge
                            text={group.libraries
                                .reduce((arr, lib) => [...arr, lib.name], [])
                                .join(', ')}
                        />
                    </div>
                );
            })}
        </div>
    );
};

const populateInfo = (key, info) => {
    switch (key) {
        case 'organisation':
            return info.organisation_name;
        case 'roles':
            return info.reduce((arr, item) => [...arr, item.name], []).join(', ');
        case 'user_groups':
            return formatGroups(info);
        case 'password':
            return '**************';
        case 'address':
            return (
                <div>
                    {info.split(', ').map((part, i) => (
                        <div key={i}>{part}</div>
                    ))}
                </div>
            );
        default:
            return info;
    }
};

const initNavOptions = [
    {
        label: 'Your details',
        id: 'your-details',
    },
    {
        label: 'Clear history',
        id: 'clear-history',
    },
];

const AccountSettings = () => {
    const [userDetails, setUserDetails] = useState({});
    const [navOptions, setNavOptions] = useState(initNavOptions);
    const [showEdit, setShowEdit] = useState({ show: false });
    const [viewEmailAlerts, setViewEmailAlerts] = useState(false);
    const projectRef = useRef(null);

    const userModel = new models.User({ id: app.user.get('id') });

    /* handler region */

    const handleDetails = (user) => {
        const flattenUser = {
            ...user.user_details,
            name: `${user.first_name} ${user.last_name}`,
            ...user,
        };
        const updatedDetails = { ...initUserDetails };
        Object.entries(initUserDetails).forEach(([group, detail]) => {
            Object.keys(detail.details).forEach((key) => {
                return (detail.details[key].value = populateInfo(key, flattenUser[key]));
            });
            updatedDetails[group] = detail;
        });
        setUserDetails(updatedDetails);
    };

    const handleShow = ({ event, key, multiInput, textarea, required }) => {
        setShowEdit({
            show: true,
            modal: EDIT_MODALS.GENERIC,
            item: {
                property: key,
                label: $(event.target).siblings('.row-label')[0].textContent,
                value: $(event.target).siblings('.row-value')[0].textContent,
                ...(multiInput && { multiInput }),
                ...(textarea && { textarea }),
                ...(required && { required }),
            },
        });
    };

    const handleSave = async (data) => {
        // Hold original data off the current user using the keys from edited data
        const originalData = Object.fromEntries(
            Object.keys(data).map((key) => [key, app.user.attributes[key]]),
        );
        try {
            const results = await userModel.action(
                {
                    id: app.user.get('id'),
                    user_groups: app.user.get('user_groups').map((group) => ({ id: group.id })),
                    roles: app.user.get('roles').map((role) => ({ id: role.id })),
                    ...(Object.keys(data).some((property) =>
                        Object.values(NESTED_DETAILS).includes(property),
                    )
                        ? { user_details: data }
                        : data),
                    edited_data: data,
                    original_data: originalData,
                },
                'PATCH',
            );
            handleDetails(results);
            toaster.success('Details were successfully updated');
        } catch (err) {
            handleSaveErrors({
                xhr: err,
                genericMessage: 'The details could not be updated',
            });
        }
    };

    /* end handler region */

    const initUserDetails = {
        keyInfo: {
            title: 'Key Information',
            details: {
                name: {
                    label: 'Name',
                    value: '',
                    can_edit: (event) =>
                        handleShow({
                            event,
                            key: 'name',
                            multiInput: [
                                {
                                    label: 'New first name',
                                    property: 'first_name',
                                    value: app.user.get('first_name'),
                                },
                                {
                                    label: 'New last name',
                                    property: 'last_name',
                                    value: app.user.get('last_name'),
                                },
                            ],
                        }),
                },
                email: {
                    label: 'Email address',
                    value: '',
                    can_edit: () => setShowEdit({ show: true, modal: EDIT_MODALS.EMAIL }),
                },
                password: {
                    label: 'Password',
                    value: '**************',
                    can_edit: () => setShowEdit({ show: true, modal: EDIT_MODALS.PASSWORD }),
                },
                phone: {
                    label: 'Phone',
                    value: '',
                    can_edit: (event) => handleShow({ event, key: 'phone', required: true }),
                },
            },
        },
        accountInfo: {
            title: 'Account information',
            details: {
                roles: { label: 'User role', value: '' },
                user_groups: {
                    label: (
                        <>
                            Groups <Lozenge text="and libraries" />
                        </>
                    ),
                    value: '',
                },
            },
        },
        organisationInfo: {
            title: 'Organisation information',
            details: {
                organisation_name: { label: 'Organisation', value: '' },
                job_title: {
                    label: 'Job title or role',
                    value: '',
                    can_edit: (event) => handleShow({ event, key: 'job_title', required: true }),
                },
                address: {
                    label: 'Work address',
                    value: '',
                    can_edit: (event) =>
                        handleShow({ event, key: 'address', textarea: true, required: true }),
                },
                postcode: {
                    label: 'Work postcode',
                    value: '',
                    can_edit: (event) => handleShow({ event, key: 'postcode', required: true }),
                },
            },
        },
    };

    useEffect(() => {
        (async () => {
            try {
                // Because of the permissions on the UserViewset, we need to fetch CurrentUser rather than userModel
                const user = await new models.CurrentUser().fetch();
                handleDetails(user);

                const updatedNav = [...navOptions];

                const canSetEmailAlerts = user.user_groups.some(
                    (group) => group.email_alerts_enabled === true,
                );
                setViewEmailAlerts(canSetEmailAlerts);

                if (canSetEmailAlerts) {
                    updatedNav.splice(1, 0, {
                        label: 'Your email alerts',
                        id: 'your-alerts',
                    });
                }
                if (user.projects.length || app.user.has_perm('main.view_organisation')) {
                    updatedNav.splice(-1, 0, { label: 'Your projects', id: 'your-projects' });
                }
                setNavOptions(updatedNav);

                // set scrolling from here as putting in own useEffect scrolls to soon
                if (!canSetEmailAlerts) handleScrollToOption();
            } catch (err) {
                console.error(err);
                toaster.error('Unable to fetch user details');
            }
        })();
    }, []);

    return (
        <>
            <JumpToNav {...{ options: navOptions }} />
            <h2 id="your-details">Your details</h2>
            <ContentManager contentType={USER_SETTING_CONTENT_TYPES.PERSONAL_DETAILS} />
            {Object.keys(userDetails).length &&
                Object.values(userDetails).map((details, index) => (
                    <DetailBlock key={index} title={details.title} rows={details.details} />
                ))}
            {viewEmailAlerts && <EmailAlerts />}
            <div ref={projectRef}>
                <ProjectInfoBlock />
            </div>
            <UserHistorySettings {...{ userModel }} />
            {showEdit.show && showEdit.modal === EDIT_MODALS.PASSWORD && (
                <ChangePasswordModal
                    {...{
                        show: showEdit.show,
                        handleModal: () => setShowEdit({ show: false }),
                        userModel,
                    }}
                />
            )}
            {showEdit.show && showEdit.modal === EDIT_MODALS.EMAIL && (
                <ChangeEmailModal
                    {...{
                        show: showEdit.show,
                        handleModal: () => setShowEdit({ show: false }),
                        currEmail: app.user.attributes.email,
                        userID: `${app.user.attributes.id}`,
                        noTemplateOption: true,
                    }}
                />
            )}
            {showEdit.show && showEdit.modal === EDIT_MODALS.GENERIC && (
                <DetailEditModal
                    {...{
                        show: showEdit.show,
                        handleModal: () => setShowEdit({ show: false }),
                        currentValue: showEdit.item.value,
                        label: showEdit.item.label.toLowerCase(),
                        property: showEdit.item.property,
                        handleSave: (stuff) => handleSave(stuff),
                        ...(showEdit.item.multiInput && { multiInput: showEdit.item.multiInput }),
                        ...(showEdit.item.textarea && { textarea: true }),
                        ...(showEdit.item.required && { required: showEdit.item.required }),
                    }}
                />
            )}
        </>
    );
};

export default AccountSettings;

Lozenge.propTypes = {
    text: PropTypes.string.isRequired,
};
