import React, { useContext } from 'react';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import PropTypes from 'prop-types';

import TableControl from '../FormControls/Table/TableControl';
import FavouriteActions from './FavouriteActions';
import { TableContext, TABLE_ACTION } from '../FormControls/Table/TableContext';
import { favouritesModel } from './utils';

const headers = [
    { title: 'Name', dataField: 'name' },
    { title: 'Family', dataField: 'family' },
    { title: 'Actions' },
];

const FavouriteList = ({ model }) => {
    const { tableState, dispatchTableState } = useContext(TableContext);

    const doFetch = async () => {
        return await model.fetchFavourites({
            sort_column: tableState.sortColumn,
        });
    };

    const doUpdate = async () => {
        const copiedOrderedList = tableState.orderedList;
        const newOrder = copiedOrderedList.reverse().reduce((newList, item, index) => {
            const itemCopy = { ...item };
            if (itemCopy.ordinal != index + 1) {
                itemCopy.ordinal = index + 1;
                newList.push(itemCopy);
            }
            return newList;
        }, []);

        try {
            const result = await model.updateFavourites({
                favourites: newOrder,
            });
            // We have to let the component know it's data is dirty, and reset it
            // or the list will go out of whack
            dispatchTableState({
                type: TABLE_ACTION.SET_DIRTY,
            });
            return result;
        } catch (error) {
            toaster.error('Could not save updated Favourites order');
            console.log(error); // eslint-disable-line no-console
        }
    };

    const getTableColumns = (item) => {
        const name = (
            <a
                href="#"
                title="Click to open document at favourite location"
                onClick={(evt) => {
                    if (evt.ctrlKey) {
                        window.open(item.favourite_url);
                    } else {
                        // if we opened the doc with a time-travelled favourite_url but then changed tt info,
                        // trying to use the favourite_url to get back to time-travelled favourite doesn't work.
                        // This checks if the opener tt info is same, makes sure we're going to the original hash,
                        // and forces a reload of the page
                        const fave_url = new URL(item.favourite_url);
                        if (
                            window.opener.location.search === fave_url.search &&
                            fave_url.search !== ''
                        ) {
                            window.opener.location.hash = fave_url.hash;
                            window.opener.location.reload();
                        } else {
                            window.opener.location.href = item.favourite_url;
                        }
                    }
                }}
                onAuxClick={() => {
                    window.open(item.favourite_url);
                }}
            >
                {item.name}
            </a>
        );

        const actions = <FavouriteActions {...{ item, model }} />;

        return [name, item.family, actions];
    };

    return (
        <div className="favourite-list">
            <DndProvider
                backend={TouchBackend}
                options={{ enableTouchEvents: false, enableMouseEvents: true }}
            >
                <TableControl
                    {...{
                        doFetch,
                        getTableColumns,
                        headers,
                        className: 'favourites-table popup-table',
                        model,
                        doUpdate,
                    }}
                />
            </DndProvider>
        </div>
    );
};

export default FavouriteList;

FavouriteList.propTypes = {
    model: PropTypes.shape(favouritesModel).isRequired,
};
