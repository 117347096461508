import React from 'react';
import PropTypes from 'prop-types';

import FavouriteList from './FavouriteList';
import { TableContextWrapper } from '../FormControls/Table/TableContext';
import { useTableReducer } from '../customHooks';
import { initialTableState } from '../FormControls/Table/utils';

import models from '../../models';
import OrderableSortControl from '../FormControls/Table/OrderableSortControl';

export const SORT_OPTIONS = {
    ORDER: 'My order',
    NAME: 'By name',
    FAMILY: 'By family',
};

Object.freeze(SORT_OPTIONS);

const FavouriteManage = ({ userID }) => {
    const defaultTableState = {
        ...initialTableState,
        sortColumn: '',
    };

    const [tableState, dispatchTableState] = useTableReducer({
        tableName: 'favouriteManage',
        defaultTableState,
    });

    const faveModel = new models.Favourites({});

    return (
        <>
            <h1 className="sr-only">Favourites</h1>
            <TableContextWrapper
                {...{
                    tableState,
                    dispatchTableState,
                    options: {
                        selectable: false,
                        sortable: true,
                        pageable: false,
                        orderable: true,
                        idPropertyName: 'pk',
                    },
                }}
            >
                <OrderableSortControl sortOptions={SORT_OPTIONS} />
                <FavouriteList {...{ model: faveModel, userID }} />
            </TableContextWrapper>
        </>
    );
};

export default FavouriteManage;

FavouriteManage.propTypes = {
    userID: PropTypes.number.isRequired,
};
