import React from 'react';
import PropTypes from 'prop-types';

const BackToResults = ({ resultsURL, docPath, buttonClass, buttonText, tidyClickEvent }) => {
    const handleClick = (targetUrl, openToTab) => {
        if (tidyClickEvent) tidyClickEvent();
        openToTab ? window.open(targetUrl) : (window.location.href = targetUrl);
    };
    return (
        <div className="pane-tools">
            <div className="btn-group">
                <button
                    className={`btn toolbar-item ${buttonClass}`}
                    title={buttonText}
                    onClick={() => handleClick(resultsURL)}
                >
                    <span className="icon icon-sm">
                        <span className="picon picon-arrow-left"></span>
                    </span>
                    <span className="label">{buttonText}</span>
                </button>
                {docPath ? (
                    <button
                        type="button"
                        className="btn toolbar-item pane-open-new"
                        title="Show this document in a new tab"
                        onClick={() => handleClick(docPath, true)}
                    >
                        <span className="icon icon-sm">
                            <span className="picon picon-goto"></span>
                        </span>
                    </button>
                ) : (
                    <div></div>
                )}
            </div>
        </div>
    );
};

export default BackToResults;

BackToResults.propTypes = {
    resultsURL: PropTypes.string.isRequired,
    docPath: PropTypes.string,
    buttonClass: PropTypes.string,
    buttonText: PropTypes.string.isRequired,
    tidyClickEvent: PropTypes.func,
};
