module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="calendar-inline"></div>    \n<div class="date-confirm">Set view date to: <strong class="view-date">'+
((__t=( confirmDate ))==null?'':_.escape(__t))+
'</strong></div>\n\n<div class="modal-footer">    \n    <button type="button" class="btn btn-sm btn-primary set-view-date set-confirm">Set View Date</button>\n    <button type="button" class="btn btn-sm btn-default cancel" data-dismiss="modal">Cancel</button>\n</div>\n<div class="modal-semifooter">\n    <button class="toolbar-item btn btn-block set-view-date">\n        <span class="icon"><span class="picon picon-calendar"></span></span>\n        <span class="label">Set view date to today\'s date: <strong class="view-date">'+
((__t=( todayDate ))==null?'':_.escape(__t))+
'</strong></span>\n    </button>\n    <button class="toolbar-item btn btn-block set-view-date">\n        <span class="icon"><span class="picon picon-target"></span></span>\n        <span class="label">Set view date to <strong class="view-date">01/01/3000</strong> (Bring all amendments into force)</span>\n    </button>\n</div>\n    <!--\n<div class="modal-semifooter">\n    <button class="toolbar-item btn btn-block">\n        <span class="icon"><span class="picon picon-target"></span></span>\n        <span class="label">View significant dates for: <strong>Section 159</strong></span>\n    </button>\n</div>\n    -->    \n';
}
return __p;
};
