/*
    Window Manager
    --------------
    
    Links multiple windows
    
    Doesn't look like IE8 likes this, might be back to the drawing board :(
*/

define([
    'underscore'
    ], function(_) {
 
    /*
    
    */
    var Manager = function(win) {
        this.win = win;
        this.win.manager = this;
        this.initialize();        
    }
    
    _.extend(Manager.prototype, {
        _refreshFreq: 1000,
        _isClosed: function(win) {
            return (win == null || (win && win.closed));
        },
        initialize: function() {
            // update window name if it doesn't already have one
            this.setWindowName();
            this.refresh();
        },
        refresh: function() {
            if(!this.intervalID) {
                this.intervalID = setInterval(
                    _.bind(this.sync, this), this._refreshFreq);
            }
        },
        sync: function() {
            // close this window if it's the opener and orphan is false
            if(this.win.closeWithParent && this._isClosed(this.win.opener)) {
                this.win.close();
                return;
            }
        },
        _createName: function() {
            return Math.floor(Math.random() * 0x10000).toString(16);
        },
        setWindowName: function() {
            if(!this.win.name) {
                this.win.name = this._createName();
            }
            return this.win.name;
        },
        /*
            Cross browser window open
        */
        open: function(url, options) {
            var options = options || { name: null };
            var name = options.name || this._createName();
            
            if(options.features) {
                var defaults = {
                    top: 'auto', 
                    left: 'auto',
                    menubar: '0',
                    toolbar: '0',
                    status: '0',
                    resizable: '1',
                    scrollbars: '1',
                    location: '0'
                };
            
                // create an object out of the features string
                var features = _.extend(defaults, _.reduce(options.features.split(','), function(ps, p){
                    return p ? _.extend(ps, _.object([p.split('=')])) : ps;
                }, {}));
                
                // TODO: handle positioning
                
                // create a string out of the features object
                var featureStr = _.reduce(_.pairs(features), function(as, a) { 
                    as.push(a.join('=')); 
                    return as; 
                }, []).join(',');
                
                var win = window.open(url, name, featureStr);
            }
            else {
                var win = window.open(url, name);
            }
            
            return win;
        }
    });
    
    /*
        
    */
    new Manager(window);
});