define([
    'backbone',
    'bootbox',
    '../helpers',
    '../../models',
    './timetraveller',
    '../../templates/print/footer.html',
], function (Backbone, bootbox, helpers, models, TimeTraveller, printFooterTpl) {
    const colourfulTags = ['term', 'defterm', 'xref', 'inref', 'anchor', 'blob'];

    /*
        ToC view
    */
    var TOCView = helpers.BaseView.extend({
        el: '.toc',
        events: {
            'click li.expandable > .toc-item > .toc-title > .toc-icon': 'itemToggle',
            'change .toc-select': 'childSelect',
        },
        initialize: function () {},
        expandToRef: function (elementId) {
            var $element = this.$('label[ref="' + elementId + '"]');

            if (!$element.length) {
                $element = this.$('label[ref="top"]');
            }

            if ($element.length) {
                this.$('.toc-item').removeClass('active child-active');

                // highlight the entry and any collapsed parents
                $element
                    .addClass('active')
                    .parents('li.expandable:not(".expanded")')
                    .find('a.toc-item:first')
                    .addClass('active child-active');

                $element.removeClass('child-active').parents('li.expandable').addClass('expanded');

                $element[0].scrollIntoView();

                this.$('.toc-select').one('change', function () {
                    if (!$element.find('.toc-select:first').is(':checked')) {
                        $element.removeClass('active');
                    }
                });
            }
        },
        itemToggle: function (evt) {
            evt.preventDefault();
            $(evt.currentTarget).closest('li').toggleClass('expanded');
        },
        childSelect: function (evt) {
            var checked = $(evt.currentTarget).is(':checked');

            // child nodes
            $(evt.currentTarget)
                .closest('li')
                .find('ul .toc-select')
                .prop('checked', checked)
                .end()
                .find('.toc-item')
                .toggleClass('active', checked);

            // parent nodes
            $(evt.currentTarget)
                .parents('.expandable')
                .each(function (i, item) {
                    var $item = $(item);
                    var checked =
                        $item.find('ul .toc-select').length ==
                        $item.find('ul .toc-select:checked').length;
                    $item
                        .find('.toc-select:first')
                        .prop('checked', checked)
                        .closest('.toc-item')
                        .toggleClass('active', checked);
                });
        },
    });

    /*
        Print footer - only show with @media print
    */
    var PrintFooterView = helpers.TemplateView.extend({
        template: printFooterTpl,
        templateHelpers: function () {
            return {
                ttViewDateString: this.getViewDate(),
                ttViewOption: this.model.ttViewOption,
                ttViewTitle: this.model.getTTViewTitle(),
                ttViewDescription: this.model.getTTViewDescription(),
            };
        },
        getViewDate: function () {
            if (this.model.ttViewOption == 'tt-between') {
                return (
                    this.model.ttViewDate.format(app.settings.dateFormat) +
                    ' - ' +
                    this.model.ttViewDate2.format(app.settings.dateFormat)
                );
            }
            return this.model.ttViewDate.format(app.settings.dateFormat);
        },
    });

    /*
        Print view
    */
    var PrintView = helpers.BaseView.extend({
        el: 'body',
        events: {
            'click #toolbar-pwclose .btn': 'closeWindow',
            'click #print-include-toc': 'togglePrintTOC',
            'click #print-scope-toc': 'showToc',
            'click #print-scope-extract': 'showExtract',
            'click .toolbar-print .btn': 'setupPrint',
            'click .toc-select': 'checkChecked',
            'click #print-include-toc input': 'checkChecked',
            'click .doc-extract a': function (evt) {
                evt.preventDefault();
            },
            'change #print-font-size': 'changeStyle',
            'change #print-font-family': 'changeStyle',
            'change #print-line-height': 'changeStyle',
            'change #print-exclude-colour': 'removeColourText',
        },
        blockWarningThreshold: 5,
        initialize: function () {
            this.hasChecked = false;
            this.removeColour = app.user.get('display_prefs').remove_link_highlighting;

            // Law Reports shouldn't have the option to remove link highlighting
            if (this.model.attributes.families.some((family) => family.slug.includes('lr'))) {
                this.$('#print-exclude-colour').addClass('disabled');
            } else {
                // set the button as checked if they've checked it before
                if (this.removeColour) {
                    this.$('#print-exclude-colour .radio').prop('checked', true);
                }
            }

            if (!window.opener) {
                window.close();
                return;
            }

            if (this.model.get('timetravel')) {
                this.timeTraveller = new TimeTraveller(this);
                // inherit the parent window time travel settings
                this.model.ttViewOption = window.opener.app
                    .getDocument()
                    .getInstance().ttViewOption;
                this.model.ttViewDate = app.moment(
                    window.opener.app.getDocument().getInstance().ttViewDate.toString(),
                );
                this.model.ttViewDate2 = app.moment(
                    window.opener.app.getDocument().getInstance().ttViewDate2.toString(),
                );

                this.timeTraveller.filter(this.$('.toc'));
            }

            // populate title
            this.$('#doc-title').html(this.model.get('title'));
            document.title = this.model.get('title');

            // For the benefit of specific doc type/family css
            this.$('#navigation').addClass(
                this.model
                    .get('families')
                    ?.map((family) => `family-${family.slug}`)
                    .join(' '),
            );

            // fetch the norris
            this.norrisInitialised = false;
            this.norris = new models.ChunkNorris({ model: this.model });
            this.listenTo(this.norris, 'initialized', this.initialiseDoc);
            this.listenTo(this.norris, 'chunk:fetched', this._chunkFetched);

            // initialize ToC
            this.tocView = new TOCView({ model: this.model });

            // render print footer
            this.printFooterView = new PrintFooterView({ model: this.model });
            this.assign(this.printFooterView, '.print-footer');

            if (this.getExtract()) {
                // check if there is a selection in the opener window
                this.showExtract();
            } else {
                // otherwise just show the ToC selector
                this.showToc();
                this.$('#print-scope-extract').closest('label').addClass('disabled');
            }
        },
        closeWindow: function () {
            window.close();
        },
        changeStyle: function (evt) {
            this.$('.pane-doc .inner').css(
                $(evt.currentTarget).attr('name'),
                $(evt.currentTarget).val(),
            );
        },
        removeColourText: function (evt) {
            // setting a variable for if they're using the toc-tree to choose what to print
            // if opening with remove colour saved, it won't have an event, so don't invert value
            this.removeColour = evt ? !this.removeColour : this.removeColour;
            // Save the user's selection for next time
            app.user.displayPrefs({ remove_link_highlighting: this.removeColour });

            // if they've highlighted text in the main doc, this will show the text colour change
            // if the extract before hitting print
            if (this.removeColour) {
                colourfulTags.map((tag) => {
                    this.$('.doc-content.pane-doc, .doc-extract.pane-doc')
                        .find(`.${tag}`)
                        .addClass(`${tag}-colour-remove`)
                        .removeClass(tag);
                });
            } else if (!this.removeColour) {
                colourfulTags.map((tag) => {
                    this.$('.doc-content.pane-doc, .doc-extract.pane-doc')
                        .find(`.${tag}-colour-remove`)
                        .addClass(tag)
                        .removeClass(`${tag}-colour-remove`);
                });
            }
        },
        checkChecked: function () {
            this.hasChecked =
                this.$('.toc-select').is(':checked') ||
                this.$('#print-include-toc input').is(':checked');

            // disable until the norris is ready
            if (!this.norrisInitialised) {
                this.hasChecked = false;
            }

            if (this.$('#print-scope-toc').is(':checked')) {
                this.$('.toolbar-print .btn').toggleClass('disabled', !this.hasChecked);
            }
        },
        showToc: function (evt) {
            this.$('.doc-extract').hide();
            this.$('.tts-hidden').remove();
            this.$('.toc').show();
            this.$('#print-include-toc').show();
            if (!evt) {
                $('#print-scope-toc').prop('checked', true);
                $('#print-scope-extract').prop('checked', false);
            }
            this.$('.toolbar-print .btn').toggleClass('disabled', !this.hasChecked);

            this.$('#content').removeClass('print-selected-text').addClass('print-doc');
        },
        getExtract: function () {
            var sel, rng, parentEl, html;
            this.hasSelection = false;

            if (window.getSelection) {
                sel = window.opener.getSelection();
                // at least one thing has been selected and the start isn't at
                // the same point as the end
                if (sel.rangeCount && !sel.isCollapsed) {
                    rng = sel.getRangeAt(0);
                    parentEl = rng.commonAncestorContainer;
                    // the start and the end are both within the content container ('#top')
                    if ($(parentEl).closest('#top').length) {
                        html = rng.cloneContents();
                    }
                }
            } else if (document.selection && document.selection.createRange) {
                sel = window.opener.document.selection;
                rng = sel.createRange();

                if (sel.type == 'Text' && rng.text != '') {
                    parentEl = rng.parentElement();
                    if ($(parentEl).closest('#top').length) {
                        html = rng.htmlText;
                    }
                }
            }

            if (html) {
                // put the highlighted text into context by appending to parent
                // elements up to the block container boundary
                var childNode;
                var parentNode = parentEl.cloneNode(false);
                $(parentNode).append(html);

                $(parentEl)
                    .parentsUntil('#top')
                    .each(function (idx, el) {
                        childNode = parentNode;
                        parentNode = el.cloneNode(false);
                        parentNode.appendChild(childNode);
                    });

                this.html = parentNode.outerHTML;
                this.$('.doc-extract .inner').html(this.html);
                this.hasSelection = true;
                return true;
            }
            return false;
        },
        showExtract: function (evt) {
            if (evt) {
                // in case 'Items selected below' was clicked, sections selected,
                // and a print attempt canceled, reset the doc-extract or
                // it'll try to print the ticked sections
                this.$('.doc-extract .inner').html(this.html);
            }
            this.$('.doc-extract').show();
            this.$('.toc').hide();
            this.$('#print-include-toc').hide();
            if (!evt) {
                $('#print-scope-toc').prop('checked', false);
                $('#print-scope-extract').prop('checked', true);
            }
            this.$('.toolbar-print .btn').toggleClass('disabled', !this.hasSelection);

            this.$('#content').removeClass('print-doc').addClass('print-selected-text');

            this.removeColourText();
        },
        initialiseDoc: function () {
            // Add document class type to doc-extract and doc-content
            this.$('.doc-extract').addClass(this.norris.doctype);
            this.$('.doc-content').addClass(this.norris.doctype);

            this.norrisInitialised = true;
            this.checkChecked();

            var elementId = window.location.hash.replace(/^#/, '');
            elementId = this.norris.getSectionsFromId(elementId) || [];
            this.tocView.expandToRef(elementId[0]);
        },
        togglePrintTOC: function (evt) {
            $('body').toggleClass(
                'print-with-toc',
                $(evt.currentTarget).find(':checkbox').is(':checked'),
            );
        },
        setupPrint: function () {
            // if the document is time travelled, show warning
            if (
                this.model.ttViewOption != 'tt-current' ||
                !app.moment().isSame(this.model.ttViewDate, 'day')
            ) {
                bootbox.dialog({
                    title: 'Time travel',
                    message:
                        'The current document view uses special colours. We recommend you use a colour printer.',
                    animate: false,
                    buttons: {
                        cancel: {
                            label: 'Cancel',
                            className: 'btn-default',
                        },
                        confirm: {
                            label: 'OK',
                            className: 'btn-primary',
                            callback: _.bind(this._printOption, this),
                        },
                    },
                });
            } else {
                this._printOption();
            }
        },
        escapeDOMId: function (str) {
            return str.replace(/([^a-zA-Z0-9\-_])/g, '\\$1');
        },
        _getSelectedSections: function () {
            var sectionList = _.map(this.$('.toc-select:checked'), function (cbx) {
                return $(cbx).val();
            });
            var idx = _.indexOf(sectionList, 'top');
            if (idx >= 0) {
                sectionList[idx] = this.model.get('document');
            }
            return sectionList;
        },
        _getChunkList: function () {
            var sections = this.norris.getSectionChildren(this._getSelectedSections());
            var chunks = _.map(
                sections,
                _.bind(function (section) {
                    var idObj = this.norris.ids[section];
                    if (idObj) {
                        return idObj.block;
                    }
                }, this),
            );

            return { chunks: _.sortBy(_.without(_.uniq(chunks), undefined)), sections: sections };
        },
        _printOption: function () {
            // printing from section list
            if (this.$('#print-scope-toc').is(':checked')) {
                var chunkList = this._getChunkList();

                if (chunkList.chunks.length > this.blockWarningThreshold) {
                    bootbox.dialog({
                        title: 'Large document',
                        message: 'This will be a very long document, are you sure?',
                        buttons: {
                            cancel: {
                                label: 'Cancel',
                                className: 'btn-default',
                            },
                            confirm: {
                                label: 'OK',
                                className: 'btn-primary',
                                callback: _.bind(function () {
                                    bootbox.dialog({
                                        title: 'Loading document, please wait...',
                                        message:
                                            '<div class="progress"><div class="progress-bar" ' +
                                            'role="progressbar" aria-valuenow="" aria-valuemin="0" ' +
                                            'aria-valuemax="100" aria-label="download progress"></div></div>',
                                        buttons: {
                                            cancel: {
                                                label: 'Cancel',
                                                callback: _.bind(function () {
                                                    this.docLoadCancelled = true;
                                                    $.xhrPool.abortAll();
                                                }, this),
                                            },
                                        },
                                        animate: false,
                                    });

                                    // let the modal above render before we get on with loading chunks
                                    _.defer(
                                        _.bind(function () {
                                            this._loadChunks(chunkList);
                                        }, this),
                                    );
                                }, this),
                            },
                        },
                        animate: false,
                    });
                } else if (chunkList.chunks.length) {
                    // don't need to warn the user about the size of the selected doc
                    this._loadChunks(chunkList);
                } else {
                    // just print the ToC if include ToC checkbox is selected
                    if (this.$('#print-include-toc input').is(':checked')) {
                        $('body').addClass('print-with-toc');

                        window.print();
                        this.log();
                    }
                }
            }
            // printing from selection
            else if (this.$('#print-scope-extract').is(':checked')) {
                window.print();

                // log printing
                this.log();
            }
        },
        _toggleTocVisiblity: function () {
            // hide toc items that haven't been checked
            this.$('#toc-tree LI')
                .addClass('no-print')
                .has('.toc-select:checked')
                .removeClass('no-print');
        },
        _loadChunks: function (chunkList) {
            this.$('.doc-content .inner').html('');
            this._toggleTocVisiblity();
            this.chunkList = chunkList.chunks;
            this.sectionList = chunkList.sections;
            this.chunksLoaded = 0;
            this.docLoadCancelled = false;

            _.every(
                this.chunkList,
                _.bind(function (chunkID) {
                    if (this.docLoadCancelled) {
                        return false;
                    }

                    // create somewhere to put the chunks
                    this.$('.doc-content .inner').append('<div id="block' + chunkID + '" />');

                    // fetch the chunk
                    _.defer(
                        _.bind(function () {
                            this.norris.fetchChunk(chunkID);
                        }, this),
                    );

                    return true;
                }, this),
            );
        },
        _chunkFetched: function (chunkID, chunkData) {
            if (this.docLoadCancelled) {
                return;
            }
            var data = chunkData;

            if (this.removeColour) {
                // We need to use regex instead of jquery because jquery doesn't keep the whole of data,
                // and mutates it into just the tags we're changing
                colourfulTags.map((tag) => {
                    data = data.replaceAll(`class="${tag}`, `class="${tag}-colour-remove`);
                });
            } else {
                colourfulTags.map((tag) => {
                    data = data.replaceAll(`class="${tag}-colour-remove`, `class="${tag}`);
                });
            }

            for (
                var img_start = data.search('<img');
                img_start >= 0;
                img_start = data.indexOf('<img', img_end)
            ) {
                var img_end = data.indexOf('>', img_start);
                var img_str = data.slice(img_start, ++img_end);
                var attrs = img_str.split(' ');
                // searching for 'src' attribute in 'img' tag.
                for (let i in attrs) {
                    if (attrs[i].substr(0, 3) == 'src') {
                        var src_attr = attrs[i];
                        var src = src_attr.split('=');
                        var src_name = src[1].trim().slice(1, src[1].length - 1);
                        var src_url = src[0] + '=' + this.norris.mediaURL + src_name;
                        data = data.replace(src_attr, src_url);
                        break;
                    }
                }
                img_start = data.indexOf('<img', img_end);
            }

            this.$('#block' + chunkID).html(data);
            this.chunksLoaded++;

            if (this.chunksLoaded == this.chunkList.length) {
                this._filterContent();
            } else {
                // update progress bar
                this.$('.progress-bar')
                    .width((this.chunksLoaded / this.chunkList.length) * 100 + '%')
                    .attr('aria-valuenow', this.chunksLoaded);
            }
        },
        _filterElementTree: function (branch) {
            $.each(
                branch,
                $.proxy(function (index, element) {
                    var id = $(element).attr('id');
                    $(element).removeClass('no-print');
                    if (id) {
                        // Make sure exquo's don't get left out as they don't have the same parent_id for the section
                        const nestedElement = $('[parent_id="' + this.escapeDOMId(id) + '"]');
                        if (nestedElement.length) {
                            this._filterElementTree(nestedElement);
                        } else {
                            this._filterElementTree($(element).children());
                        }
                    }
                }, this),
            );
        },
        _filterContent: function () {
            if (this.docLoadCancelled) {
                return;
            }

            // hide sections from printing that have not been selected
            $('[parent_id]:not([protect])').addClass('no-print');
            this.sectionList.forEach(function (id) {
                this._filterElementTree($('#' + this.escapeDOMId(id)));
            }, this);

            bootbox.hideAll();

            // timetravel and print
            if (this.model.get('timetravel')) {
                var ttViewDate =
                    this.model.ttViewOption != 'tt-between'
                        ? this.model.ttViewDate
                        : this.model.ttViewDate2;
                if (app.moment(this.norris.docEnforDate, 'YYYYMMDD').isAfter(ttViewDate)) {
                    this.$('.pane-doc .inner, .pane-toc .inner').addClass('tts-doc-not-in-force');
                }

                this.timeTraveller.filter(
                    this.$('.doc-content'),
                    _.bind(function () {
                        setTimeout(function () {
                            window.print();
                        }, 500);
                        // log printing
                        this.log();
                    }, this),
                );
            } else {
                setTimeout(function () {
                    window.print();
                }, 500);
                // log printing
                this.log();
            }
        },
        log: function () {
            var data = {
                document: this.model.get('document'),
                elements: this._getSelectedSections().join(),
                type: this.$('#print-scope-toc').is(':checked') ? 'select' : 'extract',
                toc: this.$('#print-include-toc input').is(':checked'),
                url: document.location.href,
            };
            $.post('/log/print', data);
        },
    });

    return PrintView;
});
