import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import PiconIcon from '../FormControls/Icons/PiconIcon';
import { formatFileSize, formatFileTypeName } from '../../utils/upload';
import { FileContext, isFileSelected, fileBranchPropTypes } from './FileContext';
import FileCell from './FileCell';
import FileSelect from './FileSelect';
import ExpandIcon from './ExpandIcon';

export const DEFAULT_EXPANDED_LIMIT = 100;
const SHOW_ANIMATION_LIMIT = 300;

const FileRow = ({ fileBranch, level, showExpanded, showAll }) => {
    const {
        filesState: { files },
    } = useContext(FileContext);

    const totalFiles = files.length;
    const [showChildren, setShowChildren] = useState(totalFiles < DEFAULT_EXPANDED_LIMIT);

    useEffect(() => {
        if (showAll != null) setShowChildren(showAll);
    }, [showAll]);

    const hasChildren = fileBranch.children.length > 0;

    const getColumns = () => {
        const parsedUpload = fileBranch.parsedUpload;
        const isSelected = isFileSelected(files, fileBranch.fullPath);

        const fileName = (
            <span
                style={{ marginLeft: `${level * 40}px` }}
                title={fileBranch.isValid ? null : fileBranch.messages?.join(', ')}
            >
                {hasChildren && <ExpandIcon showExpanded={showChildren} />}

                <span>{fileBranch.path}</span>
                {!fileBranch.isValid && !isSelected && (
                    <PiconIcon iconName="warning-sign" className="red right" />
                )}
            </span>
        );

        const docId = parsedUpload?.docId;
        const size = parsedUpload ? formatFileSize(parsedUpload.file.size) : '';
        const type = parsedUpload ? formatFileTypeName(parsedUpload.type) : '';
        const select = <FileSelect fileBranch={fileBranch} />;

        return [fileName, docId, size, type, select];
    };

    const columns = getColumns();

    const isCollapsed = level > 0 && !showExpanded;
    const childrenVisible = showChildren && !isCollapsed;
    const renderChildren = totalFiles < SHOW_ANIMATION_LIMIT || childrenVisible;

    let classes = '';
    if (isCollapsed) classes = 'collapsed';
    if (hasChildren) classes += ' expandable';

    return (
        <>
            <tr onClick={() => setShowChildren(!showChildren)} className={classes}>
                {columns.map((column, i) => (
                    <FileCell key={i} showExpanded={!isCollapsed}>
                        {column}
                    </FileCell>
                ))}
            </tr>

            {renderChildren &&
                fileBranch.children.map((child) => {
                    return (
                        <FileRow
                            key={child.path}
                            fileBranch={child}
                            level={level + 1}
                            showExpanded={childrenVisible}
                        />
                    );
                })}
        </>
    );
};

export default FileRow;

FileRow.defaultProps = {
    level: 0,
    showExpanded: true,
};

FileRow.propTypes = {
    fileBranch: PropTypes.shape(fileBranchPropTypes).isRequired,
    level: PropTypes.number.isRequired,
    showExpanded: PropTypes.bool.isRequired,
    showAll: PropTypes.bool,
};
