module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n<!--<a href="/settings"><em>Account Settings</em></a></p>-->\n\n<div id="current-message">\n    <p class="news-msg">Fetching current surveys, please wait...</p>\n</div>\n\n';
 if (_.isEmpty(groups)) { 
__p+='\n\n        <p class="news-msg">No current surveys found. You may not have permission to view items.</p>\n\n';
 } else { 
__p+='\n\n    ';
 _.each(groups, function(surveys, published_date) { 
__p+='\n\n    <h3>'+
((__t=( app.moment(published_date).format(app.settings['longDateFormat']) ))==null?'':_.escape(__t))+
'</h3>\n    <ul>\n        ';
 _.each(surveys, function(item) { 
__p+='\n        <li>\n            <a class="doc-link" href="/surveys/'+
((__t=( item.attributes.doc_id ))==null?'':_.escape(__t))+
'" data-id="'+
((__t=( item.attributes.doc_id ))==null?'':_.escape(__t))+
'">'+
((__t=( item.attributes.title ))==null?'':_.escape(__t))+
'</a>\n        </li>\n        ';
 }); 
__p+='\n    </ul>\n\n    ';
 }); 
__p+='\n\n';
 } 
__p+='';
}
return __p;
};
