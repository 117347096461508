import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import App from './app';
import './less/main.less';
// The less webpack loader isn't pickingthese up for some reason,
// so load them explicitly here
import './fonts/perspective-icons.eot';
import './fonts/perspective-icons.svg';
import './fonts/perspective-icons.ttf';
import './fonts/perspective-icons.woff';
import './fonts/CharisSIL-Regular.ttf';

window.app.start();
