import React from 'react';
import PropTypes from 'prop-types';

import icons from '../../img/Icons';

export const PRIVACY = {
    PRIVATE: 'PR',
    PUBLIC: 'PB',
    GLOBAL: 'GB',
    GROUP: 'GP',
    PROJECT: 'PJ',
};
Object.freeze(PRIVACY);

export const PRIVACY_DISPLAY = {
    PR: 'Private',
    PB: 'Shared',
    GB: 'Shared',
    GP: 'Shared',
    PJ: 'Project',
};
Object.freeze(PRIVACY_DISPLAY);

export const HISTORIC_PRIVACY = ['GB', 'PB'];
Object.freeze(HISTORIC_PRIVACY);

const PrivacyIcon = ({ privacy, longTitle, groups, project }) => {
    let icon, text;

    switch (privacy) {
        case PRIVACY.PRIVATE:
            icon = 'private';
            text = 'Private';
            break;
        case PRIVACY.PUBLIC:
            icon = 'group';
            text = longTitle ? 'Shared with colleagues' : 'Shared';
            break;
        case PRIVACY.GLOBAL:
            icon = 'group';
            text = 'Global';
            break;
        case PRIVACY.GROUP:
            icon = 'group';
            text = `Shared with groups: ${groups?.map((g) => g.name).join(', ')}`;
            break;
        case PRIVACY.PROJECT:
            icon = 'project';
            text = `Shared with ${project}`;
    }

    const iconColour = app.darkMode ? '#eee' : '#555';

    return (
        <span>
            <span title={icon} className="picon top left">
                {icons[icon](iconColour)}
            </span>
            {text}
        </span>
    );
};

export default PrivacyIcon;

PrivacyIcon.defaultProps = {
    longTitle: false,
};

PrivacyIcon.propTypes = {
    privacy: PropTypes.string.isRequired,
    longTitle: PropTypes.bool.isRequired,
    groups: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
        }),
    ),
    project: PropTypes.string,
};
