import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import AnnotationCard from './AnnotationCard';
import AnnotationSectionModal from './AnnotationSectionModal';
import models from '../../models';
import PiconIcon from '../FormControls/Icons/PiconIcon';

import { EditAnnotationModal } from '../Annotations/EditAnnotations/EditAnnotationModal';
import { ViewAnnotationModal } from '../Annotations/ViewAnnotations/ViewAnnotationModal';
import icons from '../../img/Icons';
import { useResize, useStateRef } from '../customHooks';

const RESIZE_STEP = 0.5;
const PANEL_STOP_SIZE = -Infinity;
const PANEL_START_SIZE = Infinity;

export const filterProjects = (annos, projects) => {
    if (annos) {
        return annos.filter((anno) => {
            const annoProject = anno?.attributes ? anno.attributes.project : anno.project;
            // Don't filter out annos that aren't in Projects
            if (!annoProject) return anno;
            // Check that the anno's Project matches the Projects we're in
            if (projects.some((project) => project == annoProject)) {
                return anno;
            }
        });
    }
    return undefined;
};

const AnnotationPanel = ({ annotations, inProject, content, paneId, annoId }) => {
    const [showPanel, setShowPanel] = useState(false);
    const [annos, setAnnos] = useState([]);
    const [refId, setRefId] = useState('');
    const [refLabel, setRefLabel] = useState('');
    const [viewVisible, setViewVisible] = useState({ show: false });
    const [editVisible, setEditVisible] = useState({ show: false });
    const [viewList, setViewList] = useState({ show: false });
    const [annoPaneWidth, setAnnoPaneWidth] = useState();
    const [getValue, setValue] = useStateRef({ refId: '', refLabel: '' });

    const ref = useRef();
    const { initResize } = useResize(
        ref,
        RESIZE_STEP,
        PANEL_START_SIZE,
        PANEL_STOP_SIZE,
        true,
        () => handleDragResize(),
        true,
    );
    useEffect(() => {
        // because some docs and users don't have annotations, we need to adjust the main panetitle on arrival
        handleWidth();
    }, []);

    useEffect(() => {
        // Listening for Resizing the window so that the panetitle looks right.
        window.addEventListener('resize', handleWidth);
        return () => window.removeEventListener('resize', handleWidth);
    }, []);

    useEffect(() => {
        const handleSubmit = async (event) => {
            const inAnnotationModal = $(event.target).parents('.annotations');
            if (
                (event.target.type === 'submit' ||
                    (event.target.type === 'button' && $(event.target).hasClass('anno-delete'))) &&
                inAnnotationModal.length
            ) {
                const parent = inAnnotationModal[0];
                const classes = parent.classList;
                const section = Array.from(classes).filter((clss) =>
                    // schedules are hard to find, so just get the metadata doc_id, not the full doc_id
                    clss.includes(annotations.document.split('-')[1]),
                )[0];
                setRefId(section);
                setValue(section); // holding the refId for annotations.on(sync)
            }
        };

        // modals pop outside the main id
        const body = document.getElementsByTagName('body');
        body[0].addEventListener('click', handleSubmit);
        return () => body[0].removeEventListener('click', handleSubmit);
    }, []);

    useEffect(() => {
        const handleClick = (event) => {
            const $anno = $(event.target).parents('.dd-flag-anno.has-annos');
            const $annoKeyboard = $(event.target).hasClass('dd-flag-anno') ? $(event.target) : null;

            if ($anno?.length || $annoKeyboard?.length) {
                const hidden = document.getElementsByClassName('pane-anno-hidden');
                if (hidden) {
                    // open panel if it's not already opened
                    handleShow(true);
                }
                const sectionId = $($anno[0] || $annoKeyboard[0]).attr('ref_id');
                setRefId(sectionId);
                setAnnos(filterProjects(annotations.annoMap[sectionId], inProject));
                setRefLabel($($anno[0] || $annoKeyboard[0]).attr('ref_label'));
                setValue(sectionId); // holding the refId for annotations.on(sync)
                handleHighlight(sectionId);
            }
        };
        const docPage = document.getElementById(paneId);
        docPage.addEventListener('click', handleClick);
        return () => docPage.removeEventListener('click', handleClick);
    }, []);

    useEffect(() => {
        if (window.location.search.includes('annotation')) {
            const urlParams = _.fromPairs([...new URLSearchParams(window.location.search)]);
            content.once('navigate:done', () => {
                const sectionId = window.location.hash.replace('#', '');
                const sectionAnnos = annotations.annoMap[sectionId];
                const selectedAnno = sectionAnnos.find((anno) => anno.id == urlParams.annotation);
                setRefId(sectionId);
                setRefLabel(sectionAnnos[0].attributes.ref_label);
                setValue(sectionId); // holding the refId for annotations.on(sync)
                setAnnos(filterProjects(sectionAnnos, inProject));
                handleShow(true);
                handleHighlight(sectionId);
                setViewVisible({ show: true, item: selectedAnno.attributes });
            });
        }
    }, []);

    useEffect(() => {
        // For handling clicking to open / close the panel rather than dragging
        const opener = document.querySelector(`#${annoId} #open-control`);
        opener.addEventListener('click', () => {
            handleShow(!showPanel);
        });
        return () =>
            opener.removeEventListener('click', () => {
                handleShow(!showPanel);
            });
    }, [showPanel]);

    const handleDragResize = () => {
        const paneWidth = $(`#${annoId}`).width();
        setAnnoPaneWidth(paneWidth);
        const annoPaneTitle = document.querySelector(`#${annoId} #anno-panetitle`);
        annoPaneTitle.style.width = `${paneWidth}px`;
        handleWidth();
    };

    const handleWidth = () => {
        const startingWidth = app?._compareView ? $(window).width() * 0.49 : $(window).width();
        // below looks wrong because we should be grabing based off paneId, but left offset for right pane is huge
        const mainTitleWidth = startingWidth - $('.pane-main .panetitle').offset()['left'];
        const mainPaneTitle = document.querySelector(`#${paneId} > .panetitle-main`);
        mainPaneTitle.style.width = `${mainTitleWidth - $(`#${annoId} #anno-panetitle`).width()}px`;
        if (app.documentView) {
            const mainWidth = $(window).width() - $('.pane-main').offset()['left'];
            const mainPane = document.querySelector('.pane-main');
            mainPane.style.width = `${mainWidth - $(`#${annoId}`).width()}px`;
        } else {
            const mainWidth = $(window).width() * 0.51;
            const mainPane = document.querySelector(`#${paneId}`);
            mainPane.style.width = `${mainWidth - $(`#${annoId}`).width()}px`;
        }
    };

    const handleShow = (show) => {
        setShowPanel(show);

        const getAnnoWidth = () => {
            if (app?.documentView) {
                if (document.getElementsByClassName('pw-inter').length) {
                    return '20%';
                } else {
                    return $(window).width() >= 1258 ? '17%' : '22%';
                }
            } else if (app?._compareView) {
                return '11%';
            }
        };

        // Because a main document view has the doc status on the right side of the panetitle,
        // we need to do some styling here to make sure opening the panel doesn't cover it.
        // This isn't needed for compare views, though, as the status is on the left side.
        const annoTitleWidth = show ? getAnnoWidth() : '0px';
        const annoWidth = show ? getAnnoWidth() : '0px';
        const annoPaneTitle = document.querySelector(`#${annoId} #anno-panetitle`);
        annoPaneTitle.style.width = annoTitleWidth;
        const annoPane = document.getElementById(annoId);
        annoPane.style.width = annoWidth;

        handleWidth();
        if (!show) {
            $(`#${paneId}`).find('.selected-section').remove();
        }
    };

    const handleHighlight = (sectionId) => {
        // Remove highlightin on a previous section if it's there
        $(`#${paneId}`).find('.selected-section').remove();
        const selector = $(document.createElement('div')).addClass('selected-section');
        $(`#${paneId}`).find(`#${sectionId}`).append(selector[0]);
    };

    useEffect(() => {
        annotations.on('sync', () => {
            // This func within the sync can't access what refId has just been set to,
            // so we're using a custom hook to hold onto the refId value to get it back here
            const heldRefId = getValue();
            const newAnnos = annotations.annoMap[heldRefId];

            setAnnos(filterProjects(newAnnos, inProject));
            if (!newAnnos) {
                // Remove highlightin on a previous section if it's there
                $(`#${paneId}`).find('.selected-section').remove();
                handleShow();
            } else {
                // reset the ref label for the section in case it's the first anno added for the section
                setRefLabel(newAnnos[0].attributes.ref_label);
                handleShow(true);
                handleHighlight(heldRefId);
            }
        });
    }, []);

    const setDirty = async () => {
        app.trigger('annotation:changed');
        handleShow(true);
    };

    const annoModel = new models.Annotation({
        document: annotations.document,
        ref_id: refId,
        ref_label: refLabel,
        org: app.user.get('organisation'),
        can_edit: true,
    });

    const initAnno = {
        can_edit: true,
        isNew: true,
        ref_label: refLabel,
        model: annoModel,
    };

    return (
        <div className="anno-pane-wrapper" ref={ref}>
            <div
                id="anno-panetitle"
                className={`anno-panel-control panetitle panetitle-right ${
                    showPanel ? '' : 'pane-collapse'
                }`}
                onMouseDown={(evt) => {
                    initResize(evt);
                }}
            >
                <div className="anno-panel-draggable">
                    {showPanel && (
                        <div>
                            <PiconIcon className="sm light-blue" iconName={'next'} />
                        </div>
                    )}
                </div>
                <div id="open-control"></div>
            </div>
            {showPanel && annos ? (
                <div className="anno-panel">
                    <div className="anno-panel-options">
                        <button
                            id="add-annotation"
                            onClick={() =>
                                setEditVisible({
                                    show: true,
                                    item: initAnno,
                                })
                            }
                        >
                            {icons['addAnnotation']()}
                            Add annotation
                        </button>
                        <button onClick={() => setViewList({ show: true })}>
                            {`View all annotations in ${refLabel ? refLabel : 'selected area'}`}
                        </button>
                        <button
                            id="anno-panel-close"
                            className="btn btn-primary"
                            title="Close annotation panel"
                            onClick={() => handleShow(false)}
                        >
                            X
                        </button>
                    </div>
                    {annos
                        .sort((a, b) =>
                            a.attributes.last_modified < b.attributes.last_modified ? 1 : -1,
                        )
                        .map((anno, index) => (
                            <AnnotationCard
                                key={index}
                                {...{
                                    anno: anno.attributes,
                                    showModal: () =>
                                        setViewVisible({
                                            show: true,
                                            item: {
                                                ...anno.attributes,
                                                model: anno,
                                            },
                                        }),
                                    paneWidth: annoPaneWidth,
                                }}
                            />
                        ))}
                </div>
            ) : (
                <div className="pane-anno-hidden"></div>
            )}
            {viewVisible.show && (
                <ViewAnnotationModal
                    {...{
                        show: viewVisible.show,
                        item: viewVisible.item,
                        showEdit: () => setEditVisible({ show: true, item: viewVisible.item }),
                        hideModal: () => setViewVisible({ show: false }),
                        setDirty,
                    }}
                />
            )}
            {editVisible.show && (
                <EditAnnotationModal
                    {...{
                        hideModal: () => setEditVisible({ show: false }),
                        item: editVisible.item,
                        show: editVisible.show,
                        model: annotations,
                        setDirty,
                        inProject,
                    }}
                />
            )}
            {viewList.show && (
                <AnnotationSectionModal
                    {...{
                        annos,
                        show: viewList.show,
                        hideModal: () => setViewList({ show: false }),
                        handleAdd: () => setEditVisible({ show: true, item: initAnno }),
                        showViewModal: (item) =>
                            setViewVisible({
                                show: true,
                                item: item,
                            }),
                        label: refLabel,
                    }}
                />
            )}
        </div>
    );
};

export default AnnotationPanel;

AnnotationPanel.propTypes = {
    annotations: PropTypes.shape({
        annoMap: PropTypes.object,
        on: PropTypes.func,
        document: PropTypes.string,
    }),
    inProject: PropTypes.arrayOf(PropTypes.number),
    content: PropTypes.shape({
        once: PropTypes.func,
    }),
    paneId: PropTypes.string,
    annoId: PropTypes.string.isRequired,
};
