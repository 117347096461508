import React from 'react';
import PropTypes from 'prop-types';
import PiconIcon from '../FormControls/Icons/PiconIcon';

const ExpandIcon = ({ showExpanded }) => {
    return (
        <span>
            <PiconIcon
                iconName="next"
                className={`grey left folder ${showExpanded ? 'rotated' : ''}`}
            />
        </span>
    );
};

export default ExpandIcon;

ExpandIcon.propTypes = {
    showExpanded: PropTypes.bool.isRequired,
};
