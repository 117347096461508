import React, { useEffect, useState } from 'react';
import models from '../models';
import { strToCapital, overrideIds } from './utils';
import { useDarkMode } from './customHooks';

const MODES = {
    AUTO: 'auto',
    LIGHT: 'light',
    DARK: 'dark',
};
Object.freeze(MODES);

const DarkModeNavButtons = () => {
    const [userMode, setUserMode] = useState({});
    const darkMode = useDarkMode();
    const handleClick = async (mode) => {
        const newMode = { dark_mode: '', background_colour: '' };
        const sepia = app.user.attributes.display_prefs.background_colour === 'sepia';
        newMode.dark_mode = mode;
        newMode.background_colour = sepia ? 'sepia' : 'light';

        const prefersLight = window.matchMedia('(prefers-color-scheme: light)').matches;

        if (mode === MODES.AUTO) {
            if (prefersLight) {
                document.documentElement.setAttribute('data-theme', '');
            } else {
                document.documentElement.setAttribute('data-theme', 'dark');
            }
        } else {
            document.documentElement.setAttribute(
                'data-theme',
                `${mode === MODES.DARK ? mode : ''}`,
            );
        }

        if (mode === MODES.LIGHT || (mode === MODES.AUTO && prefersLight)) {
            $('body').removeClass('bg-dark');
            $('#prefs-bg-light').prop('disabled', false);
            $('#prefs-bg-sepia').prop('disabled', false);
            $('#prefs-bg-light').attr('checked', !sepia);
            $('#prefs-bg-sepia').attr('checked', sepia);
            if (!sepia) {
                $('body').removeClass('bg-sepia');
            } else {
                $('body').addClass('bg-sepia');
            }
        } else if (mode === MODES.DARK || (mode === MODES.AUTO && !prefersLight)) {
            $('body').removeClass('bg-sepia');
            $('.preview-area.pane-doc').removeClass('bg-sepia');
            $('body').addClass('bg-dark');
            $('#prefs-bg-light').prop('disabled', true);
            $('#prefs-bg-sepia').prop('disabled', true);
        }

        const response = await new models.User().setPreferences(newMode);

        if (response.result) {
            toaster.success(response.result);
            setUserMode(newMode);
            app.darkMode = document.documentElement.getAttribute('data-theme') === 'dark';
        } else {
            toaster.error('There was an issue setting your display mode.');
        }
    };

    useEffect(() => {
        setUserMode({
            dark_mode: app.user.attributes.display_prefs.dark_mode,
            background_colour: app.user.attributes.display_prefs.background_colour,
        });

        if (
            app.user.attributes.display_prefs.dark_mode === MODES.AUTO &&
            window.matchMedia('(prefers-color-scheme: light)').matches
        ) {
            $('#prefs-bg-light').prop('disabled', false);
            $('#prefs-bg-sepia').prop('disabled', false);
        } else {
            $('#prefs-bg-light').prop('disabled', true);
            $('#prefs-bg-sepia').prop('disabled', true);
        }
        if (app.user.attributes.display_prefs.background_colour === 'sepia') {
            if (app.user.attributes.display_prefs.dark_mode === MODES.DARK) {
                $('body').removeClass('bg-sepia');
                $('body').addClass('bg-dark');
            } else {
                $('body').addClass('bg-sepia');
            }
        }
    }, []);

    // For handling copying to clipboard when in dark mode.
    // So it doesn't copy white text to white background.
    // Does remove formatting and styles unfortunately.
    document.addEventListener('copy', (e) => {
        if (!darkMode) {
            return;
        }
        const selection = window.getSelection().toString();
        const textarea = document.getElementById('copy-textarea');

        if (overrideIds.includes(window.getSelection()?.focusNode?.id)) {
            return;
        }

        // Apply the desired style
        textarea.value = selection.replace(/(\r\n|\n|\r)/gm, '\n').replace(/\n/g, '\r\n');
        textarea.style.color = 'black';
        textarea.style.backgroundColor = 'white';

        // Select the textarea content
        textarea.select();
        textarea.setSelectionRange(0, textarea.value.length);

        // Copy the content from the textarea
        e.clipboardData.setData('text/plain', textarea.value);
        e.clipboardData.setData(
            'text/html',
            `<p style="color: black; background-color: white;">${textarea.value.replace(
                /(?:\r\n|\r|\n)/g,
                '<br>',
            )}</p>`,
        );
        e.preventDefault();
    });

    return (
        <>
            {Object.values(MODES).map((MODE) => {
                return (
                    <button
                        key={MODE}
                        role="menuitem"
                        className={`toolbar-item btn ${
                            userMode.dark_mode === MODE ? 'active' : ''
                        }`}
                        onClick={() => handleClick(MODE)}
                    >
                        <span className="label">{strToCapital(MODE)}</span>
                    </button>
                );
            })}
        </>
    );
};

export default DarkModeNavButtons;
