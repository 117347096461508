import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import ContentEditor from '../ContentManagement/ContentEditor';
import EditButton from '../ContentManagement/EditButton';
import { WithLoadingOverlay } from '../FormControls/WithLoading';

const TOD_LINE_REQUIRED_CONTENT = {
    CONTENT: 'content',
    FAMILIES: 'families',
};
Object.freeze(TOD_LINE_REQUIRED_CONTENT);

const ToDList = ({ loggedIn, todWindow, doFetch, doPost, families, fromCompare }) => {
    const [loading, setLoading] = useState(false);
    const [lines, setLines] = useState({ primary: [], secondary: [] });
    const [showEditor, setShowEditor] = useState({ show: false, content: {} });

    // This might not be needed now? Will have a think about what's going on between homepage and /tod
    const filterNews = (lines, wantNews) => {
        if (wantNews) {
            return lines.filter((line) => line.families.some((family) => family.slug === 'news'));
        }
        return lines.filter((line) => line.families.some((family) => family.slug !== 'news'));
    };

    const formatFamilies = (lines) => {
        if (!loggedIn) {
            // Filter out any families that shouldn't be viewed on logged out page
            return lines.filter((line) => !line.logged_in_view_only);
        }
        const userFamilySlugs = families.map((family) => family.slug);
        const filteredLines = userFamilySlugs.includes('all-documents')
            ? lines
            : lines.filter((line) =>
                  line.families.some((family) => userFamilySlugs.includes(family.slug)),
              );

        const groupedLines = _.groupBy(filteredLines, (line) => line.secondary_column);

        return {
            primary: groupedLines[false],
            secondary: groupedLines[true],
        };
    };

    useEffect(() => {
        (async () => {
            setLoading(true);
            const content = await doFetch();

            // Regroup lines that are viewable to logged-in user or
            // remove news and remove list of families that shouldn't be on logged out page
            const formattedLines = loggedIn
                ? formatFamilies(content)
                : { primary: formatFamilies(filterNews(content, loggedIn)) };
            setLines(formattedLines);
            setLoading(false);
        })();
    }, [loggedIn]);

    const ToDLine = (line, index) => (
        <li className="tod-item" key={`tod-${index}`}>
            <div
                dangerouslySetInnerHTML={{
                    __html: line.line,
                }}
                onClick={(evt) => {
                    // if we're in the ToD window, non-ToD or external URLs need to open a new tab in the parent window
                    let targetElement = evt.target;
                    while (targetElement !== evt.currentTarget) {
                        if (
                            todWindow &&
                            targetElement.tagName.toLowerCase() === 'a' &&
                            targetElement.hasAttribute('href') &&
                            !/\/tod\//.test(targetElement.href)
                        ) {
                            if (
                                fromCompare &&
                                (line.families.some((family) => family.slug === 'news') ||
                                    line.families.some((family) => family.slug === 'srv'))
                            ) {
                                targetElement = targetElement.parentElement;
                            } else {
                                evt.preventDefault();
                                window.manager.open(targetElement.href);
                                break;
                            }
                        } else {
                            targetElement = targetElement.parentElement;
                        }
                    }
                }}
            ></div>
            {app.user.has_perm('cms_lite.edit_tod_line') && (
                <EditButton
                    title="Edit ToD line"
                    handleEditor={() =>
                        setShowEditor({
                            show: true,
                            content: line,
                        })
                    }
                />
            )}
        </li>
    );

    const validateData = (data) => {
        const valid = {};

        if (data[TOD_LINE_REQUIRED_CONTENT.FAMILIES]) {
            if (data[TOD_LINE_REQUIRED_CONTENT.FAMILIES].length > 0) {
                valid[TOD_LINE_REQUIRED_CONTENT.FAMILIES] = true;
            } else {
                valid[TOD_LINE_REQUIRED_CONTENT.FAMILIES] = false;
            }
        }
        if (data[TOD_LINE_REQUIRED_CONTENT.CONTENT]) {
            if (
                data[TOD_LINE_REQUIRED_CONTENT.CONTENT].length > 1 &&
                data[TOD_LINE_REQUIRED_CONTENT.CONTENT] !== '<p></p>'
            ) {
                valid[TOD_LINE_REQUIRED_CONTENT.CONTENT] = true;
            } else {
                valid[TOD_LINE_REQUIRED_CONTENT.CONTENT] = false;
            }
        } else {
            valid[TOD_LINE_REQUIRED_CONTENT.CONTENT] = false;
        }

        return valid;
    };

    const saveContent = async (data) => {
        try {
            const newContent = await doPost(data);

            const flatLines = Object.values(lines).flat();
            flatLines.splice(
                _.findIndex(flatLines, (line) => line.pk === newContent.pk),
                1,
                newContent,
            );
            const regrouped = _.groupBy(flatLines, (line) => line.secondary_column);

            setLines({
                primary: regrouped[false],
                secondary: regrouped[true],
            });

            toaster.success('The Table of Documents was successfully updated');
        } catch (exc) {
            toaster.error('There was a problem updating a line in the Table of Documents');
        }
    };

    const ulClassName = `tod-menu ${loggedIn ? 'no-bullets' : ''}`;
    // TODO: move the styling for Contents into a className in css
    return (
        <WithLoadingOverlay loading={loading}>
            <div className="tod-container">
                <div className="tod-col-primary">
                    {loggedIn ? (
                        todWindow ? (
                            ''
                        ) : (
                            <a href="/tod">
                                <h2>Table of Documents</h2>
                            </a>
                        )
                    ) : (
                        <h2
                            style={{
                                borderTop: '3px solid #75c6c6',
                                borderBottom: '3px solid #75c6c6',
                                paddingTop: '5px',
                                paddingBottom: '5px',
                            }}
                        >
                            Contents
                        </h2>
                    )}
                    <ul className={`${ulClassName} primary`}>
                        {lines.primary?.map((line, index) => {
                            return ToDLine(line, index);
                        })}
                    </ul>
                </div>
                <div className="tod-col-secondary">
                    {lines.secondary && todWindow && (
                        <ul className="tod-section secondary">
                            {filterNews(lines.secondary, true).map((line, index) =>
                                ToDLine(line, index),
                            )}
                            <li className="tod-item">
                                <a
                                    className="new-amended-link"
                                    href="/tod/new-and-recently-amended-documents"
                                >
                                    New and Recently Amended Documents
                                </a>
                            </li>
                        </ul>
                    )}
                    <ul className={`secondary`}>
                        {
                            // Logged-in ToDs aren't exactly the same: Homepage should not include News
                            lines.secondary &&
                                filterNews(lines.secondary).map((line, index) =>
                                    ToDLine(line, index),
                                )
                        }
                    </ul>
                </div>

                {showEditor.show && (
                    <ContentEditor
                        show={showEditor.show}
                        hideModal={() => setShowEditor({ show: false, content: {} })}
                        content={showEditor.content.line}
                        title="ToD line content editor"
                        extraOptions={showEditor.content}
                        families={families}
                        saveContent={saveContent}
                        helpTextUrl={`/admincms_lite/todline/${showEditor.content.pk}/change/`}
                        validateData={validateData}
                    />
                )}
            </div>
        </WithLoadingOverlay>
    );
};

export default ToDList;

ToDList.propTypes = {
    loggedIn: PropTypes.bool,
    todWindow: PropTypes.bool,
    doFetch: PropTypes.func,
    doPost: PropTypes.func,
    families: PropTypes.array,
    fromCompare: PropTypes.bool,
};
