import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import Privacy from '../Privacy';
import AnnotationActions from './AnnotationActions';
import { annotationsModel, openDocument } from '../utils';
import { TableContext, TABLE_ACTION } from '../../FormControls/Table/TableContext';
import TableControl from '../../FormControls/Table/TableControl';
import { getHeadersFormatted, getDateTime, getFilterValues } from '../../utils';
import { ViewAnnotationModal } from '../ViewAnnotations/ViewAnnotationModal';
import { EditAnnotationModal } from '../EditAnnotations/EditAnnotationModal';
import { filterProjects } from '../../DocumentAnnotations/AnnotationPanel';

const headers = getHeadersFormatted([
    'Document',
    'Location',
    'Title',
    'Owner',
    'Privacy',
    'Modified',
    'Actions',
]);

const sortConditions = {
    all_docs: { Document: 'doc_title', Owner: 'owner_name', Modified: 'last_modified' },
    current_doc: {
        Location: 'ref_label',
        Title: 'title',
        Owner: 'owner_name',
        Modified: 'last_modified',
    },
};

const sortPrivateTitles = (data) => {
    const { results } = data;
    const privateList = results.filter((anno) => !anno.title.length);
    const publicList = results.filter((anno) => anno.title.length);
    return publicList.concat(privateList);
};

const AnnotationList = ({ model, families, docFamilies }) => {
    const { tableState, dispatchTableState } = useContext(TableContext);
    const [viewVisible, setViewVisible] = useState({ show: false });
    const [editVisible, setEditVisible] = useState({ show: false });
    const projectFams = app.user.attributes.projects.reduce((arr, proj) => [...arr, proj], []);

    const doFetch = async () => {
        const params = {
            ...getFilterValues(tableState),
        };
        const data = await model.fetch({ data: params });
        if (params.sort_column.replace('-', '') == sortConditions.current_doc.Title) {
            // Private titles of Annotations not owned by current user are empty,
            // but Django knows what they are and still sorts them alphabetically.
            // This takes the Private ones out of the alphabetical list,
            // and concats them to the end of the sorted titles.
            return sortPrivateTitles(data);
        }
        data.results =
            tableState.filter.doc_id == 'all'
                ? data.results
                : filterProjects(data.results, checkInProject());
        return data;
    };

    const setDirty = () => dispatchTableState({ type: TABLE_ACTION.SET_DIRTY });

    const checkInProject = () => {
        const results = projectFams.reduce((arr, proj) => {
            if (families.some((family) => proj.families.includes(family.slug))) {
                // If we're opening the list within a doc, filter as well on the doc's families separately
                if (docFamilies.length) {
                    if (docFamilies?.some((family) => proj.families.includes(family))) {
                        return [...arr, proj.id];
                    }
                } else {
                    return [...arr, proj.id];
                }
            }
            return arr;
        }, []);
        return results;
    };

    const getTableColumns = (item) => {
        const document = (
            <a
                href="#"
                title="Click to open document and show annotation"
                onClick={(evt) => openDocument(evt, item)}
            >
                <span className="dd-privacy-mask">{item.doc_title}</span>
            </a>
        );
        const location = (
            <a
                href="#"
                title="Click to open document and show annotation"
                onClick={(evt) => openDocument(evt, item)}
            >
                {item.ref_label}
            </a>
        );
        const title = (
            <a
                href="#"
                title="Click to view this annotation"
                onClick={() => setViewVisible({ show: true, item })}
            >
                {item.title}
            </a>
        );

        const owner = (
            <span
                className="dd-privacy-mask"
                title={
                    item.reassigned_from
                        ? `This annotation was originally created by ${item.reassigned_from}`
                        : ''
                }
            >{`${item.owner_name}${item.reassigned_from ? '*' : ''}`}</span>
        );
        const privacy = (
            <Privacy
                privacy={item.privacy}
                groups={item.user_groups}
                project={item.project_title}
            />
        );
        const modified = getDateTime(item.last_modified);

        const inProject = checkInProject();

        const actions = <AnnotationActions item={item} inProject={inProject} setDirty={setDirty} />;

        return [document, location, title, owner, privacy, modified, actions];
    };

    const getSortableHeaders = (headers, sortableList) => {
        const sortableHeader = headers.map((header) => {
            if (Object.keys(sortableList).includes(header.title)) {
                header.dataField = sortableList[header.title];
            } else {
                if (Object.hasOwn(header, 'dataField')) {
                    delete header.dataField;
                }
            }
            return header;
        });
        return sortableHeader;
    };

    return (
        <>
            <TableControl
                {...{
                    doFetch,
                    getTableColumns,
                    headers: getSortableHeaders(
                        headers,
                        tableState.filter.doc_id === 'all' || tableState.filter.doc_id === null
                            ? sortConditions.all_docs
                            : sortConditions.current_doc,
                    ),
                    className: 'popup-table',
                    model,
                }}
            />
            {viewVisible.show && (
                <ViewAnnotationModal
                    {...{
                        hideModal: () => setViewVisible({ show: false }),
                        item: viewVisible.item,
                        showEdit: () => setEditVisible({ show: true, item: viewVisible.item }),
                        show: viewVisible.show,
                        setDirty,
                        includeGoTo: true,
                    }}
                />
            )}
            {editVisible.show && (
                <EditAnnotationModal
                    {...{
                        hideModal: () => setEditVisible({ show: false }),
                        item: editVisible.item,
                        show: editVisible.show,
                        model,
                        setDirty,
                        inProject: checkInProject(),
                    }}
                />
            )}
        </>
    );
};

export default AnnotationList;

AnnotationList.propTypes = {
    model: PropTypes.shape(annotationsModel).isRequired,
    families: PropTypes.array.isRequired,
    docFamilies: PropTypes.array,
};
