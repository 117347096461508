import React from 'react';
import PropTypes from 'prop-types';

const TabRow = ({ tab, active, handleClick }) => (
    <li className={active ? 'active' : ''}>
        <a
            href={'#TODO'}
            onClick={(e) => {
                e.preventDefault();
                handleClick(tab);
            }}
        >
            {tab.title}
        </a>
    </li>
);

TabRow.propTypes = {
    tab: PropTypes.object,
    handleClick: PropTypes.func,
    active: PropTypes.bool,
};

export default TabRow;
