import React from 'react';

import models from '../../models';

import DocumentList from './DocumentList';
import DocumentFilter from './DocumentFilter';
import { useTableReducer } from '../customHooks';
import { TableContextWrapper, TABLE_ACTION } from '../FormControls/Table/TableContext';
import { Button } from 'react-bootstrap';
import { initialTableState } from '../FormControls/Table/utils';
import ConfirmModal from '../FormControls/ConfirmModal/ConfirmModal';

const defaultTableState = {
    ...initialTableState,
    sortColumn: '-uploaded_at',
};

const DocumentManage = () => {
    const [tableState, dispatchTableState] = useTableReducer({
        defaultTableState,
    });
    const [showDismissConfrim, setShowDismissConfirm] = React.useState({
        show: false,
        docId: null,
    });

    const documentSummariesModel = new models.DocumentInstanceSummaries();

    const setDirty = () => dispatchTableState({ type: TABLE_ACTION.SET_DIRTY });

    const handleFlag = async (shouldDismiss) => {
        if (shouldDismiss) {
            try {
                const response = await models.put(`${app.urls.download}/dismiss`, {
                    doc_id: showDismissConfrim.docId,
                });

                toaster.success(`${response.detail} for ${showDismissConfrim.docId}`);
                setShowDismissConfirm({ show: false, docId: null });
                setDirty();
            } catch (error) {
                toaster.error(`Error dismissing download flag for ${showDismissConfrim.docId}`);
            }
        } else {
            setShowDismissConfirm({ show: false, docId: null });
        }
    };

    const additionalButtons = (
        <div className="admin-header">
            {app.user.has_perm('content.upload_instances') && (
                <div className="centered-flex">
                    <Button className="btn-sm" href="/administration/upload">
                        Upload
                    </Button>
                </div>
            )}
        </div>
    );

    return (
        <TableContextWrapper
            {...{
                tableState,
                dispatchTableState,
                options: {
                    idPropertyName: 'uuid',
                    sortable: true,
                    selectable: true,
                    pageable: true,
                },
            }}
        >
            <div id="manage" className="rbs4 admin">
                <DocumentFilter documentSummariesModel={documentSummariesModel} />
                <DocumentList
                    documentSummariesModel={documentSummariesModel}
                    additionalButtons={additionalButtons}
                    setShowDismissConfirm={setShowDismissConfirm}
                    setDirty={setDirty}
                />
            </div>
            {showDismissConfrim.show && (
                <ConfirmModal
                    onClose={handleFlag}
                    title="Dismiss download flag"
                    message={`Are you sure you want to dismiss the download flag you created on ${showDismissConfrim.docId}?`}
                />
            )}
        </TableContextWrapper>
    );
};

export default DocumentManage;
