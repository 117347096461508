module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h2><span class="docid">'+
((__t=( doc_id ))==null?'':_.escape(__t))+
'</span> - '+
((__t=( title ))==null?'':_.escape(__t))+
'</h2>\n';
 if(published.length > 0) { 
__p+='\n<table class="table">\n    <tr>\n        ';
 if(published[0].abbrevs.length) { 
__p+='\n        <td>\n            <h4>Abbreviations</h4>\n            '+
((__t=( published[0].abbrevs.join(', ') ))==null?'':_.escape(__t))+
'\n        </td>\n        ';
 } 
__p+='\n        <td>\n            <h4>Families</h4>\n            ';
 _.each(published[0].families, function(item) { 
__p+='\n            <strong>'+
((__t=( item.title ))==null?'':_.escape(__t))+
'</strong>: /'+
((__t=( item.path ))==null?'':_.escape(__t))+
'\n            <br />\n            ';
 }); 
__p+='\n        </td>\n        <td>\n            <h4>Features</h4>\n            <strong>Time Travel: </strong>'+
((__t=( published[0].timetravel ? "On" : "Off" ))==null?'':_.escape(__t))+
'<br>\n            <strong>Display in Recent Documents: </strong>'+
((__t=( published[0].recent ? "On" : "Off" ))==null?'':_.escape(__t))+
'<br>\n            <strong>Allow Duplicate IDs: </strong>'+
((__t=( published[0].duplicates ? "On" : "Off" ))==null?'':_.escape(__t))+
'<br>\n        </td>\n    </tr>\n</table>\n';
 } 
__p+='\n<div id="published-table"></div>\n<div id="ready-table"></div>\n<div id="draft-table"></div>\n<div id="other-table"></div>\n<div id="deleted-table"></div>';
}
return __p;
};
