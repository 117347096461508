import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import models from '../../models';
import DocumentFavourite from '../DocumentFavourites/DocumentFavourite';
import BackToResults from '../Search/BackToResults';

const TimelineTitlebar = ({ instanceId, families, fromSearch }) => {
    const TIMELINE_ATTRIBUTES = {
        title: 'Timeline',
        ttViewOption: 'tt-current',
        ttViewDate: app.moment(),
        docId: 'reg-timeline',
    };

    const [document, setDocument] = useState({});
    const docModel = new models.Document({ doc_id: 'reg-timeline', instance_id: instanceId });
    const favouriteModel = new models.Favourites({ document: 'reg-timeline' });

    useEffect(() => {
        (async () => {
            try {
                const doc = await docModel.fetch();
                await favouriteModel.fetchByDoc();
                setDocument({
                    ...doc,
                    options: {
                        favourites: favouriteModel,
                    },
                });
            } catch (exc) {
                if (exc.status !== 403) {
                    toaster.error('There was a problem setting the Titlebar for this document');
                }
            }
        })();
    }, []);

    return (
        <div className="timeline-title titlebar">
            <span>Timeline</span>
            {Object.keys(document).length && families.length ? (
                <DocumentFavourite
                    sectionID=""
                    sectionLabel=""
                    canDelete={true}
                    document={document}
                    inTimeline={{
                        ...TIMELINE_ATTRIBUTES,
                        family: families?.find((family) => family.slug != 'all-documents'),
                    }}
                />
            ) : (
                <div></div>
            )}
            {fromSearch ? (
                <BackToResults
                    resultsURL={fromSearch}
                    docPath={`${app.urls.timeline}`}
                    buttonClass="pane-close"
                    buttonText="List search results"
                />
            ) : (
                <div></div>
            )}
        </div>
    );
};

export default TimelineTitlebar;

TimelineTitlebar.propTypes = {
    instanceId: PropTypes.string,
    families: PropTypes.array,
    fromSearch: PropTypes.string,
};
