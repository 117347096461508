module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<hr class="hidden">\n<div class="inner">\n    ';
 if(obj.abbrevs) { 
__p+='\n    <div class="doc-title" title="'+
((__t=( abbrevs.join(', ') ))==null?'':_.escape(__t))+
' - '+
((__t=( title ))==null?'':_.escape(__t))+
'">\n        <span class="title-text">'+
((__t=( title ))==null?'':_.escape(__t))+
'</span>\n        <div id="title-favourite"></div>\n    </div>\n    ';
 } else { 
__p+='\n    <div class="doc-title" title="'+
((__t=( title ))==null?'':_.escape(__t))+
'">\n        <span class="title-text">'+
((__t=( title ))==null?'':_.escape(__t))+
'</span>\n        <div id="title-favourite"></div>\n    </div>\n    ';
 } 
__p+='\n    ';
 if(obj.document) { 
__p+='\n    <div class="titlebar-tools">\n        <div class="view-date">\n            <label class="label sr-only" for="viewdate">View date</label>\n            <input class="form-control has-modal-dd-date-from" type="text" value="'+
((__t=( ttViewDate ))==null?'':_.escape(__t))+
'" placeholder="'+
((__t=( app.settings.dateFormat ))==null?'':_.escape(__t))+
'" id="has-modal-dd-date" title="" aria-label="view date input"\n            />\n\n            <span class="betweendates-separator" style="display: none;">&mdash;</span>\n\n            <label class="label sr-only " for="viewdate-to">To date</label>\n            <input class="form-control has-modal-dd-date-to" type="text" value="'+
((__t=( ttViewDate2 ))==null?'':_.escape(__t))+
'" placeholder="'+
((__t=( app.settings.dateFormat ))==null?'':_.escape(__t))+
'" id="has-modal-dd-date-to" title="To date (inclusive)" style="display: none;" />\n        </div>\n        <button class="toolbar-item btn btn-sig-date" id="has-modal-mv-sig" aria-haspopup="true">\n            <span class="icon icon-mid" title="Significant dates"><span class="picon picon-calendar"></span></span>\n            <span class="label">Significant dates</span>\n        </button>\n    </div>\n    ';
 } 
__p+='\n</div>\n<div class="pane-tools">\n    ';
 if(obj.document) { 
__p+='\n    <button class="pane-open-new" title="Open document in new tab">\n        <span class="btn close"><span class="icon icon-sm"><span class="picon picon-goto"></span></span>\n    </span></button>\n    ';
 } 
__p+='\n    <button class="pane-close-doc" title="Close document and side-by-side viewing">X</button>\n</div>';
}
return __p;
};
