import React from 'react';
import PropTypes from 'prop-types';

import SimpleTableControl from '../FormControls/Table/SimpleTableControl';
import PopoverOverlay from '../FormControls/PopoverOverlay';
import { getDateTime } from '../utils';

const LifeCycleItem = ({ item, instance }) => {
    const document = item.document;
    const uuid = instance.uuid;
    const state = instance.state;

    const lozenge = <span className="flag flag-grey">{state}</span>;

    if (state == 'error') return lozenge;

    return (
        <a className="flag-link" href={`/documents/${document}/${uuid}`} key={uuid}>
            {lozenge}
        </a>
    );
};

const LifeCycle = ({ item }) => {
    const doFetch = () => {
        return item.related_instances;
    };

    const headers = ['State', 'Uploaded at'];

    const getTableColumns = (instance) => {
        const selected = instance.uuid == item.uuid;
        const date = getDateTime(instance.uploaded_at);

        const state = selected ? <b> {instance.state} </b> : instance.state;
        const uploadedDate = selected ? <b> {date} </b> : date;

        return [state, uploadedDate];
    };

    return (
        <PopoverOverlay
            {...{
                placement: 'top',
                content: <SimpleTableControl {...{ doFetch, getTableColumns, headers }} />,
            }}
        >
            <div className="popover-overlay-content">
                {item.related_instances
                    .filter((instance) => item.uuid != instance.uuid)
                    ?.map((instance) => (
                        <LifeCycleItem key={instance.uuid} instance={instance} item={item} />
                    ))}
            </div>
        </PopoverOverlay>
    );
};

export default LifeCycle;

LifeCycle.propTypes = {
    item: PropTypes.shape({
        uuid: PropTypes.string.isRequired,
        related_instances: PropTypes.arrayOf(
            PropTypes.shape({
                uuid: PropTypes.string.isRequired,
                uploaded_at: PropTypes.string.isRequired,
            }),
        ).isRequired,
    }).isRequired,
};

LifeCycleItem.propTypes = {
    item: PropTypes.shape({
        uuid: PropTypes.string.isRequired,
        document: PropTypes.string.isRequired,
    }).isRequired,
    instance: PropTypes.shape({
        uuid: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
    }).isRequired,
};
