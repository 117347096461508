import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import RODItem from './RODItem';

const defaultFormat = {
    document_display_count: 50,
    panel_height: 'DEFAULT',
    panel_type: 'ROD_DOCS',
};

const RODContainer = ({ triggerComparison, homepageView, formatting, handleModal }) => {
    const [rod, setRod] = useState([]);
    const [format, setFormat] = useState(defaultFormat);

    useEffect(() => {
        app.recentDocuments
            .fetch()
            .then((documents) => setRod(documents.slice(0, format.document_display_count)));
    }, []);

    useEffect(() => {
        setFormat(formatting);
    }, [formatting]);

    const handleClick = async (event, docId, docInstance) => {
        event.persist();
        var notComparing = await triggerComparison(docId, docInstance);
        if (notComparing) {
            event.ctrlKey
                ? window.open(`${app.urls.documents}/${docId}`)
                : (window.location = `${app.urls.documents}/${docId}`);
            if (handleModal) handleModal();
        }
    };

    const handleClickTimeline = async (event) => {
        event.persist();
        event.ctrlKey ? window.open('/timeline') : (window.location = '/timeline');
        if (handleModal) handleModal();
    };

    if (rod.length > 0 || homepageView) {
        return (
            <div className="rod-container">
                <div className="box-head">
                    <h2>Recently Opened Documents</h2>
                    {homepageView && (
                        <p className="help-text">
                            <strong>Ctrl-click</strong> to open document in a new tab
                        </p>
                    )}
                </div>
                <div className="body-box">
                    <ul className="recent-docs">
                        {rod.map((doc, index) => {
                            if (doc.document.doc_id === 'reg-timeline') {
                                const inCompareView = /documents\/compare/.test(
                                    window.location.pathname,
                                );
                                return inCompareView ? null : (
                                    <RODItem
                                        key={index}
                                        doc={{
                                            ...doc.document,
                                            title: 'Timeline',
                                        }}
                                        handleClick={(event) => handleClickTimeline(event)}
                                    />
                                );
                            }
                            return (
                                <RODItem
                                    key={index}
                                    doc={doc.document}
                                    handleClick={(event) =>
                                        handleClick(
                                            event,
                                            doc.document.doc_id,
                                            doc.document.instances.find(
                                                (instance) => instance.state === 'published',
                                            ).uuid,
                                        )
                                    }
                                />
                            );
                        })}
                    </ul>
                </div>
            </div>
        );
    } else {
        return <div></div>;
    }
};

export default RODContainer;

RODContainer.defaultProps = {
    triggerComparison: () => {},
    homepageView: false,
    formatting: defaultFormat,
};

RODContainer.propTypes = {
    triggerComparison: PropTypes.func,
    homepageView: PropTypes.bool,
    formatting: PropTypes.object,
    handleModal: PropTypes.func,
};
