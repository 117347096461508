import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { TableContext, TABLE_ACTION } from './TableContext';
import Button from 'react-bootstrap/Button';
import { SORT_OPTIONS } from '../../Favourites/FavouriteManage';

const OrderableSortControl = ({ sortOptions }) => {
    const [active, setActive] = useState(SORT_OPTIONS.ORDER);
    const { dispatchTableState } = useContext(TableContext);

    useEffect(() => {
        setActive(SORT_OPTIONS.ORDER);
    }, []);

    const setSortColumn = (sortColumn) =>
        dispatchTableState({ type: TABLE_ACTION.SORT, sortColumn });

    return (
        <div className="orderable-sort-container">
            <p>Sort By:</p>
            {Object.values(sortOptions).map((option) => {
                return (
                    <Button
                        key={option}
                        className={`${option == active ? 'selected' : ''}`}
                        onClick={() => {
                            setSortColumn(option);
                            setActive(option);
                        }}
                    >
                        {option}
                    </Button>
                );
            })}
        </div>
    );
};

export default OrderableSortControl;

OrderableSortControl.propTypes = {
    sortOptions: PropTypes.shape(SORT_OPTIONS).isRequired,
};
