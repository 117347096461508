define([
    'backbone',
    './helpers',
    '../models',
    '../templates/recent_docs.html',
    '../templates/errorNav.html',
    '../templates/error404.html',
    '../templates/error403.html',
    '../templates/errorGeneric.html',
    'jqueryCookie',
], function (
    Backbone,
    helpers,
    models,
    recentDocsTpl,
    errorNavTpl,
    error404Tpl,
    error403Tpl,
    errorGenericTpl,
) {
    const React = require('react');
    const ReactDOM = require('react-dom');
    const {
        LIST_CONTENT_TYPES,
        CONTENT_PANEL_TYPES,
    } = require('../components/ContentManagement/CMDocumentList');
    /*
        Global actions
    */
    var GlobalActions = function () {
        // Cookie banner dismisal
        $('.cookie-message .btn-primary').click(function (e) {
            e.preventDefault();
            $('.cookie-message').slideUp();
            $.cookie('cookie_policy_accepted', true, { expires: 365 * 10, path: '/' });
        });
        // Screen size warning dismisal
        $('#m-devicewarning button').click(function () {
            $('#m-devicewarning').hide();
            $.cookie('devicewarning_dismissed', true, { expires: 1, path: '/' });
        });
        // hide modal if screen size dismissal cookie has been set
        if ($.cookie('devicewarning_dismissed')) {
            $('#m-devicewarning').hide();
        }
        // remove TT cookie if it doesn't belong to this page
        var ttCookie = $.cookie('tt-document');
        if (ttCookie) {
            var defaults = ttCookie.split(',');
            if (defaults[0] != window.location.pathname) {
                $.removeCookie('tt-document', { path: '/' });
            }
        }
    };

    /*
        Login Page
    */
    var LoginView = helpers.BaseView.extend({
        el: '#content',
        initialize: function () {
            this.remember_me = false;
            $('#id_remember_me').prop('disabled', false);
            this.$('#login-form')
                .attr('action', this.$('#login-form').attr('action') + document.location.hash)
                .find('#id_username')
                .focus();
            window.localStorage.setItem('logging_in', true);
        },
        events: {
            'click .remember-field': 'rememberToggle',
        },
        rememberToggle: function (evt) {
            this.remember_me = $('#id_remember_me').prop('checked');
            const warn = $(evt.currentTarget).find('.shared-warning');
            warn.css('visibility', this.remember_me ? 'visible' : 'hidden');
        },
    });

    /*
        Home Page
    */
    var IndexView = helpers.BaseView.extend({
        el: 'body',
        initialize: async function () {
            const formatModel = new models.ContentPanelFormat();
            this.formats = await formatModel.fetch();
            this.loggedInView = app.user.isAuthenticated;
            // populate recent docs if we're logged in
            if (this.loggedInView) {
                this.populateRecentDocs();
            }
            this.populateNewDocs();
            this.populateLatestNews();
        },
        events: {
            'click .box-tod a[href]': function (evt) {
                // If the user isn't signed in, don't trigger a popup
                if (app.user.isAuthenticated) {
                    // Pension Surveys should not open in popup window if clicked from homepage ToD
                    if (!evt.currentTarget.href.includes('surveys')) {
                        app.trigger('popupWin:open', evt);
                    }
                }
            },
            'focus #id_username': 'showRememberMe',
            click: 'hideRememberMe',
        },
        populateRecentDocs: function () {
            const rodFormat = this.formats.find(
                (format) => format.panel_type === CONTENT_PANEL_TYPES.ROD_DOCS.toUpperCase(),
            );
            const RODContainer = require('../components/ROD/RODContainer').default;
            ReactDOM.render(
                <RODContainer
                    {...{
                        homepageView: true,
                        formatting: rodFormat,
                        triggerComparison: () => true,
                    }}
                />,
                document.getElementById('recently_opened_documents'),
            );
        },
        populateNewDocs: function () {
            const docsViewType = this.loggedInView
                ? CONTENT_PANEL_TYPES.NEW_DOCS
                : CONTENT_PANEL_TYPES.NEW_DOCS_ANON;
            const docFormat = this.formats.find(
                (format) => format.panel_type === docsViewType.toUpperCase(),
            );

            const doFetch = async () => {
                const newAndAmendedModel = new models.NewAndRecentlyAmendedDocuments();
                try {
                    return await newAndAmendedModel.fetch({
                        data: { document_display_count: docFormat.document_display_count },
                    });
                } catch (exc) {
                    toaster.error(
                        'There was a problem setting the New and Recently Amended Documents panel',
                    );
                }
            };

            const doPost = async (data) => {
                const formatModel = new models.ContentPanelFormat();
                try {
                    return await formatModel.post(data);
                } catch (exc) {
                    toaster.error(
                        'There was a problem updating the formatting for New and Recently Amended Documents panel.',
                    );
                }
            };

            const groupByDate = (result) => result.level1_value;
            const listTitle = '<h3>New and Recently Amended Documents</h3>';
            const CMDocumentList =
                require('../components/ContentManagement/CMDocumentList').default;

            const ExtraButton = () => {
                return (
                    <a className="moreDocs-link" href={`${app.urls.newAndRecent}`}>
                        more...
                    </a>
                );
            };
            ReactDOM.render(
                <CMDocumentList
                    {...{
                        doFetch,
                        doPost,
                        listTitle,
                        handleGrouping: groupByDate,
                        displayType: LIST_CONTENT_TYPES.HTML,
                        formatting: docFormat,
                        ExtraButton: <ExtraButton />,
                    }}
                />,
                document.getElementById('new_and_recently_amended_documents'),
            );
        },
        populateLatestNews: function () {
            const docsViewType = this.loggedInView
                ? CONTENT_PANEL_TYPES.LATEST_NEWS
                : CONTENT_PANEL_TYPES.LATEST_NEWS_ANON;
            const docFormat = this.formats.find(
                (format) => format.panel_type === docsViewType.toUpperCase(),
            );

            const doFetch = async () => {
                const newsModel = new models.NewsHeadlines();
                try {
                    return await newsModel.fetch({
                        data: { document_display_count: docFormat.document_display_count },
                    });
                } catch (exc) {
                    if (exc.status !== 403) {
                        toaster.error('There was a problem retrieving News articles');
                    }
                }
            };

            const doPost = async (data) => {
                const formatModel = new models.ContentPanelFormat();
                try {
                    return await formatModel.post(data);
                } catch (exc) {
                    toaster.error(
                        'There was a problem updating the formatting for Latest News panel.',
                    );
                }
            };

            const groupByDate = (result) =>
                app.moment(result.instances[0].published_date).format('D MMM YYYY');

            const listTitle = `<a href="${app.urls.news}" ><h2>Latest News</h2></a>`;
            const CMDocumentList =
                require('../components/ContentManagement/CMDocumentList').default;

            const ExtraButton = () => {
                return (
                    <div className="extraButton-link">
                        <a href={`${app.urls.news}#current-tab`}>More Current and Recent News</a>
                        <p> &#8226; </p>
                        <a href={`${app.urls.news}#archive-tab`}>News Archive</a>
                    </div>
                );
            };

            const Subheading = () => {
                // If we're logged out, app.user.attributes is empty
                if (Object.keys(app.user.attributes).length) {
                    const canSubscribeToEmailAlerts =
                        !app.user.attributes.communication_prefs?.news_alerts &&
                        app.user.attributes?.user_groups.some((group) => {
                            return group.email_alerts_enabled;
                        });
                    return canSubscribeToEmailAlerts ? (
                        <div className="news-alert-signup">
                            <div className="signup-message">Sign up for</div>
                            <a href="/settings#your-alerts">Perspective Alert emails</a>
                        </div>
                    ) : (
                        <></>
                    );
                } else {
                    return <></>;
                }
            };

            ReactDOM.render(
                <CMDocumentList
                    {...{
                        doFetch,
                        doPost,
                        listTitle,
                        handleGrouping: groupByDate,
                        displayType: LIST_CONTENT_TYPES.TITLE,
                        formatting: docFormat,
                        ExtraButton: <ExtraButton />,
                        Subheading: <Subheading />,
                    }}
                />,
                document.getElementById('latest_news'),
            );
        },
        showRememberMe: function () {
            this.$('.account-tools').addClass('focused');
        },
        hideRememberMe: function (evt) {
            if (!$(evt.target).closest('.login-form').length) {
                this.$('.account-tools').removeClass('focused');
            }
        },
    });

    /*
        Navigation for error pages
    */
    var ErrorNavView = helpers.TemplateView.extend({
        template: errorNavTpl,
        events: {
            'click #toolbar-pwclose .btn': 'closeWindow',
            'click .logo': 'navigateParent',
        },
        closeWindow: function () {
            window.close();
        },
        navigateParent: function (evt) {
            if (window.opener) {
                evt.preventDefault();
                window.opener.location.href = evt.currentTarget.href;
                window.close();
            }
        },
    });

    /*
        Generic error
    */
    var ErrorGenericView = helpers.BaseView.extend({
        el: 'body',
        template: errorGenericTpl,
        initialize: function () {
            $('body').removeClass().addClass('pw with-toolbar non-paned');

            this.contentView = new helpers.TemplateView({
                template: this.template,
            });
            this.assign(this.contentView, '#content');

            this.navView = new ErrorNavView();
            this.assign(this.navView, '#navigation');
        },
    });

    /*
        404 error
    */
    var Error404View = ErrorGenericView.extend({
        template: error404Tpl,
    });

    /*
        403 error
    */
    var Error403View = ErrorGenericView.extend({
        template: error403Tpl,
    });

    return {
        GlobalActions,
        Error404View,
        Error403View,
        ErrorGenericView,
        LoginView,
        IndexView,
    };
});
