import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { TimelineContext } from './TimelineManager';
import icons from '../../img/Icons';

const CalendarItem = ({ count, monthText, lastDataMonthText }) => {
    const { setHash, today, active, setActive } = useContext(TimelineContext);
    const todayMonth = app.moment(today, 'YYYYMMDD').format('MMMM YYYY');
    const monthId = monthText.replace(' ', '-');

    const setWidthClass = (count) => {
        let countClass = '';
        const hasCount = 'has-count';
        switch (true) {
            case count >= 14:
                countClass = `${hasCount} extra-large`;
                break;
            case count >= 10:
                countClass = `${hasCount} large`;
                break;
            case count >= 7:
                countClass = `${hasCount} medium`;
                break;
            case count >= 2:
                countClass = `${hasCount} small`;
                break;
            case count >= 1:
                countClass = `${hasCount}`;
                break;
            default:
                countClass;
                break;
        }
        return countClass;
    };

    return (
        <li
            id={`calendar-s${monthId}`}
            className={`month-marker ${setWidthClass(count)} ${
                active.id == monthId ? 'active' : ''
            }`}
        >
            <a
                onClick={() => {
                    if (!count) {
                        if (lastDataMonthText) {
                            const lastMonthId = lastDataMonthText.replace(' ', '-');
                            setHash(`#${lastMonthId}`);
                            setActive({ id: lastMonthId, active: true });
                        } else {
                            setHash(`#${monthId}`);
                            setActive({ id: monthId, active: true });
                        }
                    } else {
                        setHash(`#${monthId}`);
                        setActive({ id: monthId, active: true });
                    }
                }}
            >
                <span className="card-count"></span>
                <div className="month-marker-text">{monthText}</div>
                {todayMonth == monthText && (
                    <div
                        id="calendar-today-marker"
                        title={`Today is ${app
                            .moment(today, 'YYYYMMDD')
                            .format(app.settings.longDateFormat)}`}
                    >
                        {icons['oswaldLeft']({ height: '19', width: '21' })}
                    </div>
                )}
            </a>
        </li>
    );
};

export default CalendarItem;

CalendarItem.propTypes = {
    count: PropTypes.number.isRequired,
    monthText: PropTypes.string.isRequired,
    lastDataMonthText: PropTypes.string,
};
