define([
    'backbone',
    'bootbox',
    '../helpers',
    '../../models',
    '../../templates/search/empty_results.html',
    '../../widgets/dragger',
], function (Backbone, bootbox, helpers, models, emptyResultsTpl) {
    /*
        Base search results table row
    */
    var BaseSearchResultsRow = helpers.TemplateView.extend({
        tagName: 'tr',
        events: {
            click: 'clickRow',
        },
        initialize: function () {
            this.$el.hover(function () {
                $(this).toggleClass('highlight');
            });
        },
        clickRow: function (evt) {
            evt.preventDefault();
            this.$el.addClass('active').siblings('tr').removeClass('active');
            this.trigger('result:selected');
        },
    });

    /*
        Base search results table
    */
    var BaseSearchResultsTable = helpers.CollectionView.extend({
        el: '.search-results',
        itemViewContainer: 'table.results-table tbody',
        _postponeTimeout: 0,
        pointOffset: 10,
        rowOffset: 25,
        renderedRows: {},
        initialize: function (options) {
            this.norris = options.norris;

            // clear this as it messes with the going straight to hits - #1202
            window.location.hash = '';

            this.itemViewOptions = { norris: this.norris };

            this.listenTo(this, 'item:result:selected', function (view, args) {
                this.trigger('result:selected', view, args[0] || {});
            });

            $(window).on('resize', _.bind(this.postponeRenderRows, this));
            this.listenTo(this.collection, 'sync', _.bind(this.renderRows, this, false));
            this.listenTo(app, 'search:closed', this.renderRows, this, true);
            this.listenTo(app, 'print:results', this.renderRowLimit, this);
            this.numFound = this.collection.collectionData.numFound;
        },
        stopListening: function () {
            $(window).off('resize', _.bind(this.postponeRenderRows, this));
            this.$el.off('scroll', _.bind(this.postponeRenderRows, this));
            helpers.CollectionView.prototype.stopListening.apply(this, arguments);
        },
        render: function () {
            var template = this.getTemplate();

            if (template) {
                this.$el.html(template(this.context()));
            }

            this.renderedRows = {};
            this.renderBlankRows();
            this.renderRows();

            this.$el.on('scroll', _.bind(this.postponeRenderRows, this));

            this.trigger('render');
            this.rendered = true;
        },
        close: function () {
            _.each(this.renderedRows, function (itemView) {
                if (itemView) {
                    itemView.close();
                }
            });
            this.$el.empty();
            this.stopListening();
        },
        renderBlankRows: function () {
            var rowStr = '';
            _.range(this.numFound).forEach(
                _.bind(function () {
                    rowStr += this.blankRowStr;
                }, this),
            );
            $(this.itemViewContainer).append(rowStr);
        },
        postponeRenderRows: function () {
            clearTimeout(this._postponeTimeout);
            var scrollActive = !$('body').hasClass('with-searchresults');
            this._postponeTimeout = setTimeout(_.bind(this.renderRows, this, scrollActive), 50);
        },
        getRowIndexes: function () {
            // visible rows, plus a top and bottom threshold
            var viewportRect = this.el.getBoundingClientRect();
            var leftPoint = viewportRect.left + this.pointOffset;
            var topPoint = viewportRect.top + this.pointOffset;
            var bottomPoint = viewportRect.top + viewportRect.height - this.pointOffset;

            let topRow = $(document.elementFromPoint(leftPoint, topPoint)).closest('tr');
            if (topRow.parent().prop('nodeName') == 'THEAD') {
                topRow = this.$('.results-table tbody tr:first');
            }
            var topRowIdx = this.$('.results-table tbody tr').index(topRow);

            let bottomRow = $(document.elementFromPoint(leftPoint, bottomPoint)).closest('tr');
            if (!bottomRow.length && this.$('.results-table tbody tr').length) {
                if (this.$('.results-table tbody tr:last').offset().top < bottomPoint) {
                    bottomRow = this.$('.results-table tbody tr:last');
                }
            }
            var bottomRowIdx = this.$('.results-table tbody tr').index(bottomRow);
            return _.range(
                Math.max(topRowIdx - this.rowOffset, 0),
                Math.min(bottomRowIdx + this.rowOffset, this.numFound),
            );
        },
        renderVisibleRow: function (idx) {
            var container = this.$(this.itemViewContainer);
            var row = container.find('tr:eq(' + idx + ')');

            if (this.renderedRows[idx]) {
                // make sure available to print
                row.removeClass('no-print');
                return;
            }
            var item = this.collection.models[idx];
            // item hasn't been loaded from the server yet
            if (!item) {
                return;
            }
            var itemView = this.buildItemView(item);
            row.removeClass('no-print');
            this.assign(itemView, row);
            this.forwardEvents(itemView);
            this.renderedRows[idx] = itemView;
        },
        renderRowLimit: function (numRows) {
            this.$('.results-table tbody tr').addClass('no-print');
            for (var i = 0; i <= numRows; i++) {
                this.renderVisibleRow(i);
            }
            app.trigger('print:loaded');
        },
        renderRows: function (scrollActive) {
            var rowIndexes = this.getRowIndexes();
            if (($('.bootbox.modal').data('bs.modal') || { isShown: false }).isShown) {
                return;
            }

            var activeRow = this.$('.results-table tbody tr.active')[0];
            if (!_.isUndefined(activeRow) && scrollActive) {
                var activeIndex = activeRow.sectionRowIndex;
                rowIndexes = _.union(rowIndexes, [activeIndex]);
                app.trigger('search:scrollactiverow', activeRow);
            }

            // render visible rows
            rowIndexes.forEach(
                _.bind(function (idx) {
                    this.renderVisibleRow(idx);
                }, this),
            );

            this.closeHiddenRows(rowIndexes);
        },
        closeHiddenRows: function (rowIndexes) {
            var hiddenRowIndexes = _.difference(
                _.map(_.keys(this.renderedRows), function (key) {
                    return parseInt(key);
                }),
                rowIndexes,
            );
            hiddenRowIndexes.forEach(
                _.bind(function (idx) {
                    var itemView = this.renderedRows[idx];
                    if (itemView) {
                        itemView.$el.replaceWith(this.blankRowStr);
                        itemView.close();
                    }
                    this.renderedRows[idx] = null;
                }, this),
            );
        },
    });

    /*
        Base search results form
    */
    var BaseResultsFormView = helpers.TemplateView.extend({
        el: '.search-details',
        initialize: function (options) {
            this.queryParams = options.queryParams;
        },
        templateHelpers: function () {
            return {
                searchMode: this.queryParams.q
                    ? this.getSearchModeString(this.queryParams.mode)
                    : '',
                formattedQuery: this.formattedQuery(),
            };
        },
        _fromISODate: function (dateStr) {
            return app.moment(dateStr).format(app.settings.dateFormat);
        },
        getSearchModeString: function (mode) {
            return {
                exact: 'the exact phrase',
                any: 'any of the terms',
                all: 'all terms',
            }[mode];
        },
        formattedQuery: function () {
            // Format the query terms into a user readable string
            var s = this.queryParams.q ? "'" + this.queryParams.q + "'" : '';
            var addedDate = false;
            var addedProximity = false;

            // Format the dates ranges
            switch (this.queryParams.date) {
                case 'range':
                    var from = this._fromISODate(this.queryParams.date_from);
                    var to = this._fromISODate(this.queryParams.date_to);

                    if (this.queryParams.search === 'section') {
                        s += ' in a document published between ' + from + ' and ' + to;
                    } else {
                        s += ' in documents published between ' + from + ' and ' + to;
                    }
                    addedDate = true;
                    break;

                case 'period':
                    var msg = '';

                    if (this.queryParams.search === 'section') {
                        msg = ' in a document published in the last';
                    } else {
                        msg = ' in documents published in the last';
                    }

                    var value = this.queryParams.date_last_value;
                    var units = this.queryParams.date_last_units;

                    if (value === '1') {
                        s += msg + ' ' + units;
                    } else {
                        units += 's';
                        s += msg + ' ' + value + ' ' + units;
                    }
                    addedDate = true;
                    break;
            }

            // Format the proximity
            switch (this.queryParams.mode) {
                case 'all':
                    // Only show proximity string if multiple search terms
                    // and the search is for all terms
                    var terms = this.queryParams.q.split(' ');

                    if (terms.length > 1) {
                        s += ' within the same section';
                        addedProximity = true;
                        break;
                    }
            }

            // Format the scope
            switch (this.queryParams.scope) {
                case 'exnotes':
                    if (addedDate && addedProximity) {
                        s += ', and';
                    }
                    s += ' in all text excluding notes';
                    break;
                case 'titlesheadings':
                    if (addedDate && addedProximity) {
                        s += ', and';
                    }
                    s += ' in titles and headings only';
                    break;
            }
            return s;
        },
    });

    /*
        Base search results
    */
    var BaseSearchResultsView = helpers.TemplateView.extend({
        events: {
            'click .change-query': 'changeQuery',
        },
        hasEmptyResult: function () {
            // TODO: Work out why Solr is returning results for stop words, but not snippets for the hits
            // In the mean time, just filter
            return (
                !this.sectionResults ||
                this.sectionResults.length == 0 ||
                this.sectionResults.find(function (r) {
                    return r.get('highlighting').hits == 0;
                })
            );
        },
        changeQuery: function (evt) {
            evt.preventDefault();
            app.trigger('search:changeQuery', evt);
        },
        handleNoResults: function () {
            this.$('.show-search-within').prop('disabled', true);
            this.emptyResultsView = new EmptyResultsView({
                query: this.queryParams,
            });
            this.assign(this.emptyResultsView, '.search-results');
        },
    });

    /*
        No results view
    */
    var EmptyResultsView = helpers.TemplateView.extend({
        template: emptyResultsTpl,
        templateHelpers: function () {
            return {
                query: this.options.query,
            };
        },
    });

    return {
        BaseSearchResultsTable: BaseSearchResultsTable,
        BaseSearchResultsRow: BaseSearchResultsRow,
        BaseSearchResultsView: BaseSearchResultsView,
        BaseResultsFormView: BaseResultsFormView,
    };
});
