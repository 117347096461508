import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

const FormInputUncontrolled = ({
    label,
    type,
    name,
    invalidMessage,
    value,
    onChange,
    onBlur,
    required,
    maxLength,
    helpText,
}) => {
    const [inputValue, setValue] = useState(value);

    useEffect(() => {
        setValue(value);
    }, [value]);

    const changeValue = (e) => {
        const newVal = e.target.value;
        setValue(newVal);
        if (onChange) onChange(newVal, name);
    };

    return (
        <Form.Group>
            <Form.Label>{label}</Form.Label>
            <Form.Control
                value={inputValue ?? ''}
                onChange={(e) => changeValue(e)}
                aria-label={label}
                {...{
                    name,
                    type,
                    required,
                    maxLength,
                    ...(onBlur && { onBlur: (e) => onBlur(e.target.value, name) }),
                }}
            />
            {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
            {invalidMessage && <div className="invalid-feedback">{invalidMessage}</div>}
        </Form.Group>
    );
};

export default FormInputUncontrolled;

FormInputUncontrolled.defaultProps = {
    type: 'text',
    required: false,
    maxLength: 254,
};

FormInputUncontrolled.propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['text', 'email', 'password']).isRequired,
    name: PropTypes.string.isRequired,
    invalidMessage: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    required: PropTypes.bool.isRequired,
    maxLength: PropTypes.number.isRequired,
    helpText: PropTypes.string,
};
