export const formatFileSize = (bytes, decimals = 2) => {
    //Code taken from https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript

    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const parseFile = async (file) => {
    return new Promise(function (resolve) {
        var fileReader = new FileReader();

        var fileData = {
            file: file,
            name: file.name,
            size: file.size,
            fullPath: (file.path ?? file.webkitdirectory)?.replace(/^\//, ''),
        };

        fileReader.onload = function () {
            var input = this.result.replace(/^\]>(\r\n|\n|\r)/m, '');
            var parser = new DOMParser();
            var parsererrorNS = parser
                .parseFromString('INVALID', 'text/xml')
                .getElementsByTagName('parsererror')[0].namespaceURI;
            var parsed = new DOMParser().parseFromString(input, 'text/xml');

            // Check if we have a news item
            if (parsed.firstChild.tagName == 'newsitem') {
                fileData['type'] = 'news';
                fileData['artId'] = parsed.firstChild.attributes['id']?.value;
                fileData['id'] = parsed.firstChild.attributes['writ-dt'].value;
            }
            // Check metadata by testing to see if we've failed on a parsing error...
            else if (parsed.getElementsByTagNameNS(parsererrorNS, 'parsererror').length == 0) {
                var metaNode = parsed.documentElement.getElementsByTagName('document-metadata')[0];
                if (metaNode && metaNode.hasAttribute('id')) {
                    fileData['type'] = 'meta';
                    fileData['id'] = metaNode.getAttribute('id');
                }
            }
            // ...which means this could be an sgm file
            else {
                parsed = new DOMParser().parseFromString(input, 'text/html');
                var root = parsed.documentElement.getElementsByTagName('body')[0].firstChild;
                if (root && root.hasAttribute) {
                    // potential News with parse errors
                    if (root.tagName == 'NEWSITEM') {
                        fileData['type'] = 'news';
                        fileData['artId'] = root.getAttribute('id');
                        fileData['id'] = root.getAttribute('writ-dt');
                    }
                    // potentially a Survey
                    else if (root.tagName === 'SURVEY') {
                        fileData['type'] = 'survey';
                        fileData['id'] = root.getAttribute('id');
                    }
                    // potentially the Timeline document
                    else if (root.tagName === 'TIMELINE') {
                        fileData['type'] = 'timeline';
                        fileData['id'] = root.getAttribute('id');
                    }
                    // id in the body
                    else if (root.hasAttribute('id')) {
                        fileData['type'] = 'main';
                        fileData['id'] = root.getAttribute('id');
                    }
                    // id in header
                    else if (root.getElementsByTagName('header').length) {
                        root = root.getElementsByTagName('header')[0];
                        if (root.hasAttribute('id')) {
                            fileData['type'] = 'main';
                            fileData['id'] = root.getAttribute('id');
                        }
                    }
                    // have another pop at the metadata node just in case we
                    // got a bad read error when trying to open it last time
                    else if (root.getElementsByTagName('document-metadata').length) {
                        root = root.getElementsByTagName('document-metadata')[0];
                        if (root.hasAttribute('id')) {
                            fileData['type'] = 'meta';
                            fileData['id'] = root.getAttribute('id');
                        }
                    }
                }
            }

            if (!fileData['id']) {
                // give up trying to parse, instead register as a resource
                fileData['type'] = 'resource';
            }

            fileData['docId'] = getFileId(fileData);

            resolve(fileData);
        };

        fileReader.readAsText(file);
    });
};

const getFileId = (parsedFile) => {
    if (parsedFile.type == 'news') {
        return parsedFile.artId?.replace('.', '-');
    }
    return parsedFile.id;
};

export const formatFileTypeName = (type) => {
    switch (type) {
        case 'news':
            return 'News';
        case 'survey':
            return 'Survey';
        case 'main':
            return 'Document';
        case 'meta':
            return 'Metadata';
        case 'resource':
            return 'Resource';
        default:
            return 'Unknown';
    }
};
