module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 _.each(blockRange, function(id) { 
__p+='\n<div id="block'+
((__t=( id ))==null?'':_.escape(__t))+
'" data-block="'+
((__t=( id ))==null?'':_.escape(__t))+
'" class="block-container loading" style="height:'+
((__t=( height ))==null?'':_.escape(__t))+
'px;"></div>\n';
 }); 
__p+='\n';
}
return __p;
};
