import React from 'react';
import PropTypes from 'prop-types';

import PiconIcon from '../FormControls/Icons/PiconIcon';
import { getDateTime } from '../utils';

export const getWarningText = (warning) => {
    if ('state' in warning) {
        return (
            <span>
                There is a <span className="warning-emph">{warning.state}</span> version of{' '}
                <span className="warning-emph">{warning.doc_id}</span> on the editor dashboard
                created by <span className="warning-emph">{warning.uploaded_by}</span> on{' '}
                <span className="warning-emph">{getDateTime(warning.uploaded_at)}</span>.
            </span>
        );
    } else {
        return (
            <span>
                This file, <span className="warning-emph">{warning.doc_id}</span> was last
                downloaded by <span className="warning-emph">{warning.downloaded_by}</span> on{' '}
                <span className="warning-emph">{getDateTime(warning.downloaded_at)}</span>.
            </span>
        );
    }
};

const DownloadWarning = ({ warningText, className, downloadedById }) => {
    return (
        <div className={`download-warning ${className}`}>
            <PiconIcon
                iconName="warning-sign"
                className={className}
                title="Multiple versions of this document exist; please contact the editor"
            />
            <span className="warning-text">
                {warningText}{' '}
                {downloadedById != app.user.attributes.id
                    ? 'Please contact them to see if they are still working on this file.'
                    : ''}
            </span>
        </div>
    );
};

export default DownloadWarning;

DownloadWarning.propTypes = {
    warningText: PropTypes.node,
    className: PropTypes.string,
    downloadedById: PropTypes.number,
};
