//

define(['backbone'], function (Backbone) {
    /*
        DocumentInstance model

        fetchTOC() is used to fetch the TOC html document for the document instance
        upon fetching, a `syncTOC` event is fired. fetchTOC() returns a jQuery promise
    */
    var DocumentInstance = Backbone.Model.extend({
        idAttribute: 'uuid',
        ttViewTitles: {
            'tt-current': 'Current View',
            'tt-between': 'Between Dates',
            'tt-past': 'Past',
            'tt-onthisday': 'On This Day',
            'tt-future': 'Future',
            'tt-all': 'All',
        },
        ttViewDescriptions: {
            'tt-current':
                'Shows all text in force and <span class="tts-not-in-force">original text that is not yet in force</span> on the View Date.',

            'tt-between':
                'Shows the changes that occur between two selected dates. ' +
                'Original text that comes into force after the first date and on or before the second date is ' +
                '<span class="tts-orig-into-force">highlighted</span>. <span class="tts-inserted">Insertions</span> and ' +
                '<span class="tts-repealed">repeals</span> that take effect after the first date and on or before the second date are also marked up.',

            'tt-past':
                'Shows original text in force and <span class="tts-not-in-force">original text that is not yet in force</span> ' +
                'on the View Date, as well as <span class="tts-inserted">Insertions</span> and <span class="tts-repealed">repeals</span> ' +
                'that take effect on or before the View Date.',

            'tt-onthisday':
                'Shows all text in force and <span class="tts-not-in-force">original text that is not yet in force</span> ' +
                'on the View Date, and also marks up <span class="tts-orig-into-force">original text that comes into force</span> ' +
                'on (but not before) the View Date and <span class="tts-inserted">insertions</span> and <span class="tts-repealed">repeals</span> ' +
                'that take effect on (but not before) the View Date.',

            'tt-future':
                'Shows all text in force and <span class="tts-not-in-force">original text that is not yet in force</span> ' +
                'on the View Date, as well as <span class="tts-future-inserted">insertions</span> and <span class="tts-future-repealed">repeals</span> ' +
                'that have not yet taken effect on the View Date.',

            'tt-all':
                'Combines Past and Future Views: <span class="tts-inserted">insertions</span> and <span class="tts-repealed">repeals</span> ' +
                'that take effect on or before the View Date, and <span class="tts-future-inserted">insertions</span> and <span class="tts-future-repealed">repeals</span> ' +
                'that have not yet taken effect on the View Date.',
        },
        initialize: function () {
            var ttCookie = $.cookie('tt-document');
            var defaults = [window.location.pathname, 'tt-current', app.moment(), app.moment()];
            if (ttCookie) {
                defaults = ttCookie.split(',');
                // check that this is saved TT info for this doc
                if (defaults[0] == window.location.pathname) {
                    defaults[1] = defaults[1].length > 0 ? defaults[1] : 'tt-current';
                    defaults[2] = app.moment(defaults[2], app.settings.dateValidFormats);
                    defaults[3] = app.moment(defaults[3], app.settings.dateValidFormats);
                }
            }
            var params = window.location.search.slice(1).split('&');
            const tt_params = params.map((param) => param.split('='));

            this.ttViewOption = _.has(tt_params, 'tt_option') ? tt_params.tt_option : defaults[1];
            this.ttViewDate = app.moment(defaults[2]).isValid() ? defaults[2] : app.moment();
            this.ttViewDate2 = app.moment(defaults[3]).isValid() ? defaults[3] : app.moment();
            // sets tt cookie
            this.listenTo(this, 'timetravel:change', this.setTTCookie);
        },
        setTTCookie: function () {
            var viewDate = this.ttViewDate.format(app.settings['dateFormat']);
            var viewDate2 = this.ttViewDate2.format(app.settings['dateFormat']);
            if (this.ttViewDate.isSame(app.moment(), 'day')) {
                viewDate = null;
            }
            if (this.ttViewDate2.isSame(app.moment(), 'day')) {
                viewDate2 = null;
            }
            if (!viewDate && !viewDate2 && this.ttViewOption == 'tt-current') {
                $.removeCookie('tt-document', { path: '/' });
            } else {
                $.cookie(
                    'tt-document',
                    [window.location.pathname, this.ttViewOption, viewDate, viewDate2].join(','),
                    { expires: 30, path: '/' },
                );
            }
        },
        getMediaURL: function () {
            return (
                // eslint-disable-next-line no-undef
                MEDIA_URL +
                this.get('uuid').substring(0, 1) +
                '/' +
                this.get('document') +
                '/' +
                this.get('uuid') +
                '/content/'
            );
        },
        getBaseName: function () {
            return this.get('document') + '.sgm';
        },
        fetchTOC: function () {
            var url = this.getMediaURL() + 'toc.html';
            return $.get(
                url,
                _.bind(function (data) {
                    this.trigger('syncTOC', data);
                }, this),
            );
        },
        fetchHistory: function () {
            var url = this.getMediaURL() + 'notes/doc.history.html';
            return $.get(
                url,
                _.bind(function () {
                    this.trigger('syncHistory');
                }, this),
            );
        },
        getTTViewTitle: function () {
            return this.ttViewTitles[this.ttViewOption];
        },
        getTTViewDescription: function () {
            return this.ttViewDescriptions[this.ttViewOption];
        },
        getPageUrl: function () {
            var url = document.location.origin;
            var frag = document.location.hash;
            url += app.urls.documents;
            url += '/' + this.get('document');
            if (this.get('state') != 'published') {
                url += '/' + this.get('uuid');
            }
            if (this.get('timetravel') && this.ttViewDate) {
                var option = this.ttViewOption ? this.ttViewOption : 'tt-current';
                url += '?tt_option=' + option;
                url += '&tt_date=' + this.ttViewDate.format(app.settings.dateTransportFormat);
                if (this.ttViewOption == 'tt-between') {
                    url += '&tt_date2=' + this.ttViewDate2.format(app.settings.dateTransportFormat);
                }
            }
            if (frag) {
                url += frag;
            }
            return url;
        },
    });

    /*
        DocumentInstance collection
    */
    var DocumentInstances = Backbone.Collection.extend({
        model: DocumentInstance,
        comparator: '-uploaded_at',
    });

    var DocumentInstanceSummary = Backbone.Model.extend({
        urlRoot: '/xhr/documentinstancesummaries',
        idAttribute: 'uuid',
    });

    var DocumentInstanceSummaries = Backbone.Collection.extend({
        model: DocumentInstanceSummary,
        url: '/xhr/documentinstancesummaries',
        action: function (params) {
            const solr_tester = app.user.attributes.roles.some(
                (role) => role.name === 'Solr Tester',
            );
            return post(`${this.url}/action`, { ...params, solr_tester });
        },
        checkStatusSelected: function (params) {
            return post(`${this.url}/check_status_selected`, params);
        },
        refreshSelected: function (params) {
            return get(`${this.url}/refresh_selected`, params);
        },
    });

    var DocumentUpload = Backbone.Model.extend({
        urlRoot: '/xhr/documentuploads',
        idAttribute: 'uuid',
    });

    var DocumentUploads = Backbone.Collection.extend({
        model: DocumentUpload,
        url: '/xhr/documentuploads',
    });

    var Document = Backbone.Model.extend({
        idAttribute: 'doc_id',
        urlRoot: '/xhr/documents',
        titles: {},
        initialize: function (options) {
            this.instances = new DocumentInstances();
            if (options.activeInstance) {
                this._activeInstance = options.activeInstance;
            }
            this.listenTo(this, 'add change', this.populate);
            this.populate();
        },
        parse: function (data) {
            this.titles[this.id] = data.title;
            return data;
        },
        setDefaultInstance: function () {
            var instance = null;
            if (
                !app.user.has_perm('content.view_instances') ||
                this.instances.where({ state: 'published' }).length > 0
            ) {
                instance = this.instances.where({ state: 'published' })[0];
            } else {
                if (this.instances.where({ state: 'ready' }).length > 0) {
                    instance = this.instances.where({ state: 'ready' })[0];
                } else if (this.instances.where({ state: 'draft' }).length > 0) {
                    instance = this.instances.where({ state: 'draft' })[0];
                }
            }
            if (instance) {
                this._activeInstance = instance.get('uuid');
                this._activeState = instance.get('state');
            }
        },
        getInstance: function () {
            if (!this._activeInstance) {
                this.setDefaultInstance();
            }
            return this.instances.get(this._activeInstance);
        },
        populate: function () {
            if (this.has('instances')) {
                this.instances.add(this.get('instances'));
                if (this._activeInstance) {
                    const instance = _.find(this.instances.models, { id: this._activeInstance });
                    if (instance) {
                        this._activeState = instance.get('state');
                    }
                }
            }
            if (!this._activeInstance) {
                this.setDefaultInstance();
            }
        },
        workflowAction: function (action, uuid) {
            const solr_tester = app.user.attributes.roles.some(
                (role) => role.name === 'Solr Tester',
            );
            var data = { action: action, uuid: uuid, solr_tester };
            return post(this.url() + '/workflow', data);
        },
        getTitles: function (docIds) {
            // if docIds is undefined, return the current document title
            // otherwise, look for document IDs in object title cache
            // if not found, fetch from API title endpoint
            //
            // N.B. ensure docIds is a list
            // N.B. returns a Promise

            var dfd = new jQuery.Deferred();

            var fetchIds = [];
            var docTitles = {};

            if (_.isEmpty(docIds)) {
                docTitles[this.id] = this.titles[this.id];
            } else {
                docIds.forEach((id) => {
                    if (Object.values(this.titles).includes(id)) {
                        docTitles[id] = this.titles[id];
                    } else {
                        fetchIds.push(id);
                    }
                });
            }

            if (_.isEmpty(fetchIds)) {
                dfd.resolve(docTitles);
            } else {
                var data = $.param({ doc_id: fetchIds }, true);
                $.ajax({
                    type: 'GET',
                    url: '/xhr/titles',
                    data: data,
                    context: this,
                })
                    .done(function (titles) {
                        _.reduce(
                            titles,
                            _.bind(function (list, item) {
                                this.titles[item['doc_id']] = item['title'];
                                list[item['doc_id']] = item['title'];
                                return list;
                            }, this),
                            docTitles,
                        );
                        dfd.resolve(docTitles);
                    })
                    .fail(function () {
                        dfd.reject(docTitles);
                    });
            }

            return dfd.promise();
        },
    });

    var Documents = Backbone.Collection.extend({
        model: Document,
        url: '/xhr/documents',
    });

    var NewsDocument = Document.extend({
        urlRoot: '/xhr/news',
    });

    var NewsDocuments = Documents.extend({
        url: '/xhr/news',
    });

    var NewsHeadlines = Documents.extend({
        url: '/xhr/news/headlines',
    });

    var Topic = Backbone.Model.extend({});

    var Topics = Backbone.Collection.extend({
        model: Topic,
        url: '/xhr/topics',
    });

    var NewsTopic = Backbone.Model.extend({});

    var NewsTopics = Backbone.Collection.extend({
        model: NewsTopic,
        url: '/xhr/newstopics',
    });

    var SurveyDocument = Document.extend({
        urlRoot: '/xhr/surveys',
    });

    var SurveyDocuments = Documents.extend({
        url: '/xhr/surveys',
    });

    var SurveyTopic = Backbone.Model.extend({});

    var SurveyTopics = Backbone.Collection.extend({
        model: SurveyTopic,
        url: '/xhr/surveytopics',
    });

    var SurveyPublisher = Backbone.Model.extend({});

    var SurveyPublishers = Backbone.Collection.extend({
        model: SurveyPublisher,
        url: '/xhr/surveypublishers',
    });

    var RecentDocument = Backbone.Model.extend({
        initialize: function () {
            this.doc = new Document(this.get('document')).getInstance();
        },
    });

    var RecentDocuments = Backbone.Collection.extend({
        model: RecentDocument,
        url: '/xhr/recent',
        open: function (documentID) {
            this.create(
                { document: documentID },
                {
                    wait: true,
                    merge: true,
                },
            );
        },
        close: function (documentID) {
            return $.post(this.url + '/close', { document: documentID });
        },
    });

    var Family = Backbone.Model.extend({
        idAttribute: 'slug',
        urlRoot: '/xhr/families',
        initialize: function () {
            this.children = new Families();
            this.listenTo(this, 'add change', this.populate);
        },
        populate: function () {
            if (this.has('children')) {
                this.children.add(this.get('children'));
            }
        },
        getChildren: function () {
            return this.children;
        },
        getTitles: function (famIds) {
            // if famIds is undefined, fetch the title from the server
            // otherwise return a list of the supplied famIds
            // N.B. ensure famIds is a list
            // N.B. returns a deferred
            var data = $.param({ fam: famIds }, true);
            return $.get(this.url() + '/title', data);
        },
    });

    var Families = Backbone.Collection.extend({
        model: Family,
        url: '/xhr/families',
        getUserFamilies: function () {
            return $.get(`${this.url}/get_user_subscribed_families`);
        },
    });

    /*
        Chunk fetching model-a-like

        Handles the block and id index and fetches relevant data chunks when
        requested
    */
    var ChunkNorris = function (options) {
        options || (options = {});
        this.labelMap = {
            act: { section: 'Section' },
            euregdoc: { section: 'Article' },
            regdoc: { appendix: 'Appendix', section: 'Paragraph' },
            si: { reg: 'Regulation', rule: 'Rule', article: 'Article' },
        };
        this.chunkFetchingCount = 0;
        this.chunkLoadingCount = 0;
        this.model = options.model;
        this.mediaURL = options.model.getMediaURL();
        this.initialize.apply(this, arguments);
    };

    _.extend(ChunkNorris.prototype, Backbone.Events, {
        initialize: function () {
            this.model.norris = this;
            $.when(
                // Get blocks.json and ids.json
                this.syncBlocks(),
                this.syncIds(),
            )
                .done(
                    _.bind(function (blocks, ids) {
                        this.initBlocks(blocks[0]);
                        this.initIds(ids[0]);

                        if (this.doctype != 'newsitem' && this.doctype != 'survey') {
                            $.when(this.syncLabels()).done(
                                _.bind(function (labels) {
                                    this.initLabels(labels);
                                    this.syncDates();
                                    this.trigger('initialized');
                                    this.model.trigger('norris:initialized', this);
                                    this.initialized = true;
                                }, this),
                            );
                            // Gotos are fetched with document from DB
                            //this.syncGotos();
                        } else {
                            this.trigger('initialized');
                            this.model.trigger('norris:initialized', this);
                            this.initialized = true;
                        }
                    }, this),
                )
                .fail(function () {
                    console.error('Failed to fetch document metadata');
                });
        },
        syncBlocks: function () {
            return $.getJSON(this.mediaURL + 'blocks.json');
        },
        initBlocks: function (blocks) {
            // Grab the document pass and enfor dates from the blocks
            // json as we need then when we first render the document.
            // We can defer the loading of significant dates until later.
            this.docPassDate = blocks.doc_pass_dt;
            this.docEnforDate = blocks.doc_enfor_dt;
            this.doctype = _.isEmpty(blocks.doctype) ? 'regdoc' : blocks.doctype;
            this.railway = blocks.railway;

            this.chunks = Array(blocks.count);
            for (let i in blocks.blocks) {
                this.chunks[i] = {
                    filename: blocks.blocks[i],
                };
            }
        },
        syncIds: function () {
            return $.getJSON(this.mediaURL + 'ids.json');
        },
        initIds: function (ids) {
            this.ids = ids;
        },
        syncDates: function () {
            $.getJSON(this.mediaURL + 'dates.json').done(_.bind(this.initDates, this));
        },
        initDates: function (dates) {
            this.dates = dates;
            //this.trigger('dates') // deprecated
            this.model.trigger('norris:initialized:dates', this);
        },
        syncLabels: function () {
            return $.getJSON(this.mediaURL + 'labels.json');
        },
        initLabels: function (labels) {
            this.labels = labels;
            app.trigger('norris:initialized:labels', labels);
        },
        syncGotos: function () {
            $.getJSON(this.mediaURL + 'gotos.json').done(_.bind(this.initGotos, this));
        },
        initGotos: function (gotos) {
            this.gotos = gotos;
            app.trigger('norris:initialized:gotos', gotos);
        },
        fetchChunk: function (chunkID) {
            var chunk = this.chunks[chunkID];
            if (!chunk) {
                return;
            }
            this.chunkLoadingCount++;

            $.get(this.mediaURL + chunk.filename)
                .done(
                    _.bind(function (data) {
                        this.chunkFetched(data, chunkID);
                    }, this),
                )
                .fail(
                    _.bind(function () {
                        this.chunkFailed(chunkID, arguments);
                    }, this),
                );

            this.trigger('chunk:fetching', chunkID);
        },
        chunkFetched: function (data, chunkID) {
            this.chunkLoadingCount--;
            this.trigger('chunk:fetched', chunkID, data);
        },
        chunkFailed: function (chunkID) {
            this.chunkLoadingCount--;
            this.trigger('chunk:failed', chunkID);
        },
        chunkCount: function () {
            return this.chunks ? this.chunks.length : 0;
        },
        getLabelFromId: function (id) {
            if (!this.labels) {
                return '';
            }

            var label = '';
            // look up label info
            const lab_info = this.labels[id];
            if (_.isUndefined(lab_info)) {
                return label;
            }
            // do Schedule Part weirdness
            if (lab_info['el'] == 'schpart' || lab_info['el'] == 'sischpart') {
                var number = id.split('-')[3];
                var sec_number = number.split('.')[0].toUpperCase();
                var rest_number = number.slice(number.indexOf('.') + 1).toUpperCase();
                label = 'Schedule ' + sec_number + ', pt ' + rest_number;
            } else if (lab_info['el'] == 'schedule' || lab_info['el'] == 'sisch') {
                label = lab_info['label'];
            } else {
                if (
                    _.isUndefined(this.labelMap[this.doctype]) ||
                    (lab_info['label'] && _.isEmpty(lab_info['label'].trim()))
                ) {
                    prefix = '';
                } else {
                    var prefix = this.labelMap[this.doctype][lab_info['el']];
                    if (_.isUndefined(prefix)) {
                        prefix = '';
                    }
                }
                // special case for paragraphs and schedule sections
                if (
                    lab_info['el'] == 'para' ||
                    lab_info['el'] == 'sch-sec' ||
                    (lab_info['el'] == 'section' && id.indexOf('sch-') != -1)
                ) {
                    var id_elements = id.split('-');
                    if (id_elements[0] == 'actsch' || id_elements[0] == 'sisch') {
                        var schedule_refs = id_elements[id_elements.length - 1].split('.');
                        var schedule_no = schedule_refs[0];
                        return 'Schedule ' + schedule_no;
                    } else {
                        prefix = 'Paragraph';
                    }
                }
                label = lab_info['label'].trim();
                if (prefix) {
                    label = prefix + ' ' + label;
                }
                label = label.replace(/&nbsp;/g, ' ');
            }
            return label;
        },
        getSectionsFromId: function (id) {
            var block = this.ids[id];
            if (_.isUndefined(block)) {
                return block;
            }
            var sections = this.ids[id]['section'];
            if (_.isUndefined(sections) || _.isEmpty(sections)) {
                return [id];
            }
            if (_.isString(sections)) {
                sections = [sections];
                sections = _.sortBy(sections, function (item) {
                    return parseInt(item.split('.').slice(-1));
                });
            } else {
                sections = _.sortBy(sections, function (item) {
                    return parseInt(item.split('.').slice(-1));
                });
                sections = _.uniq([id].concat(sections));
            }
            return sections;
        },
        getSectionChildren: function (ids) {
            // recursively get the children from the section ids
            return ids.reduce(
                _.bind(function (result, id) {
                    result.push(id);
                    var childIds = _.without(this.getSectionsFromId(id), id);
                    if (childIds.length) {
                        result = result.concat(this.getSectionChildren(childIds));
                    }
                    return result;
                }, this),
                [],
            );
        },
        getDatesFromSection: function (section) {
            return this.dates[section];
        },
    });

    var SearchResult = Backbone.Model.extend({
        getDocumentUrl: function () {
            var documentUrl = app.urls.documents + '/' + this.get('doc_id');
            if (app.user.has_perm('content.view_all_document_states')) {
                documentUrl += '/' + this.get('doc_instance');
            }
            documentUrl += '/#' + this.get('sec_id');

            return documentUrl;
        },
    });

    /*
        Base search result
    */
    var BaseSearchResults = Backbone.Collection.extend({
        url: '/xhr/search',
        model: SearchResult,
        responseData: {},
        fetch: function (options) {
            // find the qs (data) and store it
            this.qs = options['data'];
            options['data'] = $.param(options['data'], true);
            return Backbone.Collection.prototype.fetch.apply(this, arguments);
        },
        saveLog: function (filter) {
            $.post('/log/search', {
                terms: filter.q,
                type: filter.search,
                raw: JSON.stringify(filter),
            });
        },
    });

    /*
        Results from a section search
    */
    var SectionSearchResults = BaseSearchResults.extend({
        parse: function (data) {
            if (!this.collectionData) {
                this.collectionData = data.collectionData;
            }
            return data.sections;
        },
    });

    /*
        Results from a document search

        typeMap
            Dictates the type expected for each field for the benefit of sort normalising
            Because _normaliseSortVal defaults to string, we only need to declare
            non-string types i.e. int, float, date

    */
    var DocumentSearchResults = BaseSearchResults.extend({
        // no default UI sorting
        // sortField: 'doc_primary_family_title_sort',
        typeMap: {
            hits: 'int',
            score: 'float',
            doc_date: 'date',
            fragments: 'int',
        },
        reverseSort: false,
        parse: function (data) {
            // Check that these are correct results for query
            if (_.isUndefined(this.qs.q) || data.query == this.qs.q) {
                if (!this.collectionData) {
                    this.collectionData = data.collectionData;
                }
                return data.documents;
            }
            return this.models;
        },
        _normaliseSortVal: function (val, type) {
            /*
            Coerces field values to a sortable result
            Date values are converted to Unix timestamp
            String values are converted to the same value as that stated in the
            Solr schema type 'alphaOnlySort' i.e. trimmed lower case alphanumerics.
            */
            switch (type) {
                case 'int':
                    val = parseInt(val);
                    break;
                case 'float':
                    val = parseFloat(val);
                    break;
                case 'date':
                    val = app.moment(val).valueOf();
                    break;
                default:
                    val = val
                        .toString()
                        .toLowerCase()
                        .replace(/[^a-z0-9]/g, '');
            }
            return val;
        },
        comparator: function (a, b) {
            var dataA = null;
            var dataB = null;
            if (a.has(this.sortField)) {
                dataA = this._normaliseSortVal(a.get(this.sortField), this.typeMap[this.sortField]);
            }
            if (b.has(this.sortField)) {
                dataB = this._normaliseSortVal(b.get(this.sortField), this.typeMap[this.sortField]);
            }

            // User selected sort from UI
            if (this.reverseSort) {
                if (dataA && dataB) {
                    if (dataA > dataB) {
                        return -1;
                    }
                    if (dataB > dataA) {
                        return 1;
                    }
                } else if (dataA) {
                    return 1;
                } else if (dataB) {
                    return -1;
                }
            } else {
                if (dataA && dataB) {
                    if (dataA < dataB) {
                        return -1;
                    }
                    if (dataB < dataA) {
                        return 1;
                    }
                } else if (dataA && !dataB) {
                    return -1;
                } else if (dataB && !dataA) {
                    return 1;
                }
            }

            // Default ordering
            if (a.has('doc_reference_sort') && b.has('doc_reference_sort')) {
                if (a.get('doc_reference_sort') > b.get('doc_reference_sort')) {
                    return 1;
                }
                if (b.get('doc_reference_sort') > a.get('doc_reference_sort')) {
                    return -1;
                }
            } else if (a.has('doc_reference_sort')) {
                return -1;
            } else if (b.has('doc_reference_sort')) {
                return 1;
            }

            // Make sure documents with no reference sort still get grouped by family
            if (a.get('doc_primary_family_title_sort') > b.get('doc_primary_family_title_sort')) {
                return 1;
            }
            if (b.get('doc_primary_family_title_sort') > a.get('doc_primary_family_title_sort')) {
                return -1;
            }

            // Solr relevancy score
            if (a.get('score') > b.get('score')) {
                return -1;
            }
            if (b.get('score') > a.get('score')) {
                return 1;
            }

            // Fallback ordering by document id
            if (a.get('doc_id') > b.get('doc_id')) {
                return 1;
            }
            if (b.get('doc_id') > a.get('doc_id')) {
                return -1;
            }

            return 0;
        },
    });

    /*
        A User
    */
    var User = Backbone.Model.extend({
        urlRoot: '/xhr/users',
        action: function (data, action) {
            return $.ajax({
                type: action,
                url: this.url(),
                data: JSON.stringify(data),
                contentType: 'application/json',
                processData: false,
            });
        },
        changeEmail: function (params) {
            return post(`${this.url()}/change_email`, params);
        },
        changePassword: function (passwordData) {
            // Changing current user's password
            return $.post('/settings/newpass', passwordData);
        },
        resetUserPassword: function (passwordData) {
            // Resetting a user's password
            return $.post('/xhr/resetpassword', passwordData);
        },
        saveNote: function (params) {
            return post(this.url() + '/save_note', params);
        },
        validateDomain: function (params) {
            return post(`${this.url()}/validate_domain`, params);
        },
        setPreferences: function (params) {
            return $.post(`/preferences/update`, params);
        },
        clearHistory: function (params) {
            return post(`/xhr/user/clear_history`, params);
        },
    });

    var Users = Backbone.Collection.extend({
        url: '/xhr/users',
        model: User,
        initialize: function () {
            // get metadata
            this.metadata = {};
            this.metainfo = $.get(this.url + '/useroptions').done(
                _.bind(function (data) {
                    this.metadata['auth_groups'] = data['groups'];
                    this.metadata['subscription_groups'] = data['subscription_groups'];
                    this.metadata['organisations'] = data['organisations'];
                    this.metadata['states'] = data['user_states'];
                    this.metadata['limits'] = data['limits'];
                }, this),
            );
        },
        action: function (params) {
            return post(`${this.url}/users_action`, params);
        },
        checkUsersOrganisation: function (params) {
            return post(`${this.url}/check_users_organisation`, params);
        },
        checkSelectedStates: function (params) {
            return post(`${this.url}/check_user_states`, params);
        },
        getSelectedUsers: function (params) {
            return get(`${this.url}/get_selected_users`, params);
        },
        getSelectedPostcodes: function (params) {
            return get(`${this.url}/get_selected_postcodes`, params);
        },
        getSelectedUsersAnnotations: function (params) {
            return get(`${this.url}/get_selected_users_annotations`, params);
        },
        updateEmailDomains: function (params) {
            return post(`${this.url}/update_email_domains`, params);
        },
        updateSelected: function (params) {
            return post(`${this.url}/perform_user_group_update`, params);
        },
        updateAddress: function (params) {
            return post(`${this.url}/perform_address_update`, params);
        },
        refreshSelected: function (params) {
            return get(`${this.url}/refresh_selected`, params);
        },
        validateBatch: function () {
            return post(`${this.url}/validate_multiple`, {
                users: this.models,
            });
        },
        saveBatch: function (params) {
            return post(`${this.url}/create_multiple`, {
                ...params,
                users: this.models,
            });
        },
        reassignAnnotations: function (params) {
            return post(`${this.url}/reassign_annotations`, params);
        },
    });

    const Metadata = Backbone.Model.extend({
        urlRoot: '/xhr/users/useroptions',
    });

    const UserActivity = Backbone.Model.extend({
        urlRoot: '/xhr/useractivity',
    });

    const UserActivityLog = Backbone.Collection.extend({
        url: '/xhr/useractivity',
        model: UserActivity,
    });

    /*
        Currently logged in user
    */
    var CurrentUser = Backbone.Model.extend({
        url: '/xhr/user',
        has_perm: function (perm) {
            return _.indexOf(this.get('perms'), perm) > -1;
        },
        displayPrefs: function (data) {
            var endpoint = '/xhr/prefs/display';
            // set preferences and return the promise if there is data
            if (data) {
                return post(endpoint, data);
            }
            // get prefs if no data
            return $.get(endpoint);
        },
        commsPrefs: function (data, partial = false) {
            var endpoint = '/xhr/prefs/comms';
            if (partial) {
                return patch(endpoint, data);
            }
            // set preferences and return the promise if there is data
            if (data) {
                return put(endpoint, data);
            }
            // get prefs if no data
            return $.get(endpoint);
        },
        getSearchTerms: function () {
            // return recent search terms for this user
            return $.get('/xhr/terms');
        },
        changeEmail: function (params) {
            return post(`${this.url}/change_email`, params);
        },
        canViewAlertPreferences: function () {
            return this.get('user_groups').some((group) => group.email_alerts_enabled);
        },
        clearHistory: function (params) {
            return post(`${this.url}/clear_history`, params);
        },
    });

    /*
        Annotations
    */
    var Annotation = Backbone.Model.extend({
        urlRoot: '/xhr/annotations',

        getDocumentUrl: function () {
            return (
                app.urls.documents +
                '/' +
                this.get('document') +
                '?annotation=' +
                this.get('id') +
                '#' +
                encodeURIComponent(this.get('ref_id'))
            );
        },
    });

    var Annotations = Backbone.Collection.extend({
        url: '/xhr/annotations',
        model: Annotation,
        parse: function (data) {
            return data.results;
        },
        comparator: 'last_modified',
        document: '',
        initialize: function (options) {
            if (options.document) {
                this.document = options.document;
            }
        },
        remap: function () {
            this.annoMap = this.groupBy('ref_id');
        },
        fetch: function (options) {
            options || (options = {});
            var args = Array.prototype.slice.call(arguments);
            const data = { ...(options.data ?? {}) };
            if (!_.isEmpty(this.document)) {
                data['doc_id'] = this.document;
            }
            if (!_.isEmpty(data)) {
                options['data'] = data;
            }
            options['success'] = _.bind(function (collection) {
                this.annoMap = collection.groupBy('ref_id');
                collection.trigger('test_map', this);
            }, this);
            args.splice(0, 1, options);

            return Backbone.Collection.prototype.fetch.apply(this, args);
        },
        getAnnotationBySectionID: function (sectionID) {
            return this.annoMap[sectionID];
        },
        getAnnotationGroups: function () {
            if (app.user.attributes.is_superuser) {
                return $.get('/xhr/annotations/get_all_groups');
            }
            // don't send Hidden user groups back as an option
            return app.user.attributes.user_groups.filter(
                (group) => !group.hide_from_shared_groups,
            );
        },
    });

    /* 
        Projects
    */
    var Project = Backbone.Model.extend({
        urlRoot: '/xhr/projects',
        getUsers: function (params) {
            return get(`${this.url()}/get_users`, params);
        },
    });

    var Projects = Backbone.Collection.extend({
        url: '/xhr/projects',
        model: Project,
        document: '',
        initialize: function (options) {
            if (options?.document) {
                this.document = options.document;
            }
        },
        getProjectsByUser: function (params) {
            return get(`${this.url}/get_projects_by_user`, params);
        },
        getProjectsByOrganisation: function (params) {
            return get(`${this.url}/get_projects_by_organisation`, params);
        },
    });

    var Organisation = Backbone.Model.extend({
        urlRoot: '/xhr/organisations',
        idAttribute: 'id',
    });

    var Organisations = Backbone.Collection.extend({
        url: '/xhr/organisations',
        model: Organisation,
    });

    var Flag = Backbone.Model.extend({
        urlRoot: '/xhr/flags',
        idAttribute: 'id',
    });

    var Flags = Backbone.Collection.extend({
        url: '/xhr/flags',
        model: Flag,
        getFlagsForUser: function (params) {
            return get(`${this.url}/get_flags_for_user`, params);
        },
        getFlagsForUsers: function (params) {
            return get(`${this.url}/get_flags_for_users`, params);
        },
        resolveFlag: function (params) {
            return post(`${this.url}/resolve_flag`, params);
        },
        addFlags: function (params) {
            return post(`${this.url}/add_flags`, params);
        },
        snoozeFlag: function (params) {
            return post(`${this.url}/snooze_flag`, params);
        },
        cancelSnoozeFlag: function (params) {
            return post(`${this.url}/cancel_snooze_flag`, params);
        },
    });

    var NewsAlertFrequency = Backbone.Collection.extend({
        url: '/xhr/newsalertfrequency',
    });

    var DocumentAlertFrequency = Backbone.Collection.extend({
        url: '/xhr/documentalertfrequency',
    });

    const sendAjax = async (url, data, type) => {
        return $.ajax({
            type,
            url,
            data: JSON.stringify(data),
            contentType: 'application/json',
            processData: false,
        });
    };

    const put = async (url, data) => {
        return sendAjax(url, data, 'PUT');
    };

    const patch = async (url, data) => {
        return sendAjax(url, data, 'PATCH');
    };

    const post = async (url, data) => {
        return sendAjax(url, data, 'POST');
    };

    const get = async (url, data) => {
        return $.ajax({
            type: 'GET',
            url,
            data,
        });
    };

    const destroy = async (url, data) => {
        return $.ajax({
            type: 'DELETE',
            url,
            data,
        });
    };

    /*
    Mandrill Emails
    */
    const EmailTemplate = Backbone.Model.extend({
        idAttribute: 'slug',
    });

    const EmailTemplates = Backbone.Collection.extend({
        url: '/xhr/emailtemplates',
        model: EmailTemplate,
        fetchByLabel: async function (label, includeNullOption) {
            await this.fetch({ data: { label } });
            if (includeNullOption) {
                this.add({ slug: '', name: 'Do not send an email' }, { at: 0 });
            }
            return this.toJSON();
        },
    });

    const Favourite = Backbone.Model.extend({
        urlRoot: '/xhr/favourites',
        idAttribute: 'id',
        update: function () {
            return patch(`${this.urlRoot}/${this.attributes.pk}`, this.attributes);
        },
        destroy: function () {
            return destroy(`${this.urlRoot}/${this.attributes.pk}`, this.attributes);
        },
    });

    const Favourites = Backbone.Collection.extend({
        url: '/xhr/favourites',
        model: Favourite,
        parse: function (data) {
            return data;
        },
        document: '',
        initialize: function (options) {
            if (options.document) {
                this.document = options.document;
            }
        },
        fetchByDoc: async function () {
            await this.fetch({ data: { document: this.document } });
        },
        fetchFavourites: async function (data) {
            return await this.fetch({ data });
        },
        updateFavourites: function (params) {
            return patch(`${this.url}/update_favourites`, params);
        },
    });

    const HomepageAnonContent = Backbone.Model.extend({
        urlRoot: '/xhr/homepagecontentanon',
        idAttribute: 'id',
        retrieve: function () {
            return get(`${this.urlRoot}/get`);
        },
    });

    const HomepageContent = Backbone.Model.extend({
        urlRoot: '/xhr/homepagecontent',
        idAttribute: 'id',
        create: function (data) {
            return post(`${this.urlRoot}/add`, data);
        },
        retrieve: function () {
            return get(`${this.urlRoot}/get`);
        },
    });

    const UserSettingsContent = Backbone.Model.extend({
        urlRoot: '/xhr/usersettingscontent',
        idAttribute: 'id',
        create: function (data) {
            return post(`${this.urlRoot}/add`, data);
        },
        retrieve: function (contentType) {
            return get(`${this.urlRoot}/get/${contentType}`);
        },
    });

    const NewAndRecentlyAmendedDocuments = Backbone.Model.extend({
        urlRoot: '/xhr/documentnewandamended',
        idAttribute: 'id',
    });

    const ContentPanelFormat = Backbone.Model.extend({
        urlRoot: '/xhr/contentpanelformat',
        idAttribute: 'id',
        post: function (params) {
            return post(`${this.urlRoot}`, params);
        },
    });

    const TableOfDocuments = Backbone.Model.extend({
        urlRoot: '/xhr/tod',
        retrieve: function () {
            return get(`${this.urlRoot}/line/get`);
        },
        update: function (data) {
            // The ToDLine model doesn't accept 'content', just 'line', so we're mushing the data here
            const { content, ...formattedData } = data;
            formattedData.line = content;
            return post(`${this.urlRoot}/line/edit/${data.pk}`, formattedData);
        },
        retrieveDocList: function (params) {
            return get(`${this.urlRoot}/page/get`, params);
        },
        createContent: function (data) {
            return post(`${this.urlRoot}/content/add`, data);
        },
        retrieveContent: function (path) {
            return get(`${this.urlRoot}/content/get/${path}`);
        },
        updateContent: function (path, data) {
            return post(`${this.urlRoot}/content/edit/${path}`, data);
        },
        updateFilters: function (data) {
            return post(`${this.urlRoot}/filter/edit/${data.pk}`, data);
        },
    });

    const Images = Backbone.Model.extend({
        urlRoot: '/xhr/image',
        createImage: function (data) {
            return post(`${this.urlRoot}/add`, data);
        },
    });

    const Timeline = Backbone.Model.extend({
        urlRoot: '/xhr/timeline',
        fetch: function (url) {
            if (url === '/timeline' || url === '/timeline/') {
                return get(this.urlRoot);
            } else {
                const splitUrl = url.replace(/\/$/, '').split('/');
                const id = splitUrl[splitUrl.length - 1];
                return get(`${this.urlRoot}/${id}`);
            }
        },
    });

    return {
        Document,
        Documents,
        DocumentInstance,
        DocumentInstances,
        DocumentInstanceSummaries,
        DocumentUpload,
        DocumentUploads,
        RecentDocument,
        RecentDocuments,
        Family,
        Families,
        Flags,
        ChunkNorris,
        DocumentSearchResults,
        SectionSearchResults,
        User,
        Users,
        CurrentUser,
        Metadata,
        UserActivityLog,
        Annotation,
        Annotations,
        NewsDocument,
        NewsDocuments,
        NewsHeadlines,
        Topic,
        Topics,
        NewsTopic,
        NewsTopics,
        SurveyDocument,
        SurveyDocuments,
        SurveyTopic,
        SurveyTopics,
        SurveyPublisher,
        SurveyPublishers,
        Organisation,
        Organisations,
        EmailTemplate,
        EmailTemplates,
        NewsAlertFrequency,
        DocumentAlertFrequency,
        Favourite,
        Favourites,
        HomepageAnonContent,
        HomepageContent,
        UserSettingsContent,
        NewAndRecentlyAmendedDocuments,
        ContentPanelFormat,
        TableOfDocuments,
        Images,
        Project,
        Projects,
        Timeline,
        put, // exporting so we can use without a model and still has CSRFtoken
    };
});
