import React from 'react';
import PropTypes from 'prop-types';

const InvalidFeedback = ({ isInvalid, errorMessage }) => {
    return (
        <>
            <div className={isInvalid ? 'is-invalid' : ''} />
            <div className="invalid-feedback">{errorMessage}</div>
        </>
    );
};

export default InvalidFeedback;

InvalidFeedback.propTypes = {
    isInvalid: PropTypes.bool,
    errorMessage: PropTypes.string.isRequired,
};
