import React from 'react';
import PropTypes from 'prop-types';

import PiconIcon from '../FormControls/Icons/PiconIcon';

const CalendarPanelControl = ({ showPanel, initResize }) => (
    <div
        className="calendar-panel-control"
        title={`${showPanel ? 'Hide' : 'View'} Index Options`}
        onMouseDown={initResize}
    >
        {showPanel && <div className="panel-control-text">Timeline Map</div>}
        <div id="collapse-control">
            <PiconIcon className="sm blue" iconName={showPanel ? 'previous' : 'next'} />
            <PiconIcon className="sm blue" iconName={showPanel ? 'previous' : 'next'} />
        </div>
    </div>
);

export default CalendarPanelControl;

CalendarPanelControl.propTypes = {
    showPanel: PropTypes.bool.isRequired,
    initResize: PropTypes.func.isRequired,
};
