import React from 'react';
import PropTypes from 'prop-types';

export const WithLoading = ({ children, loading }) => {
    if (loading) return <div className="loader" />;

    return children;
};

export const WithLoadingOverlay = ({ children, loading, className, isMain }) => {
    return (
        <div role={isMain ? 'main' : ''} className={className}>
            <LoaderOverlay loading={loading} />
            {children}
        </div>
    );
};

export const LoaderOverlay = ({ loading, className }) => {
    return (
        <>
            {loading && (
                <div className={`loader-overlay ${className ?? ''}`}>
                    <div className="loader" />
                </div>
            )}
        </>
    );
};

WithLoading.propTypes = {
    loading: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
};

WithLoadingOverlay.propTypes = {
    ...WithLoading.propTypes,
    className: PropTypes.string,
    isMain: PropTypes.bool,
};

LoaderOverlay.propTypes = {
    loading: PropTypes.bool.isRequired,
    className: PropTypes.string,
};
