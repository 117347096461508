import React from 'react';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import DraggableModalDialog from '../DraggableModalDialog';

const ConfirmModal = ({
    onClose,
    title,
    message,
    confirmText,
    cancelText,
    className,
    size,
    showCancel,
    centered,
    position,
}) => {
    return (
        <Modal
            id="confirm-modal"
            className={`rbs4 ${className}`}
            show={true}
            onHide={() => onClose(false)}
            size={size}
            dialogAs={DraggableModalDialog}
            centered={centered}
            style={{ transform: position }}
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>

            <Modal.Footer>
                {showCancel && (
                    <Button variant="secondary" onClick={() => onClose(false)}>
                        {cancelText}
                    </Button>
                )}
                <Button type="submit" onClick={() => onClose(true)}>
                    {confirmText}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

ConfirmModal.defaultProps = {
    message: 'Are you sure?',
    title: 'Warning!',
    confirmText: 'Ok',
    cancelText: 'Cancel',
    className: '',
    size: 'sm',
    showCancel: true,
    centered: true,
    position: 'translate(0px, 0px)',
};

ConfirmModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    message: PropTypes.node,
    title: PropTypes.node,
    confirmText: PropTypes.node,
    cancelText: PropTypes.node,
    className: PropTypes.string,
    size: PropTypes.string,
    showCancel: PropTypes.bool,
    centered: PropTypes.bool,
    position: PropTypes.string,
};

export default ConfirmModal;
