import React from 'react';
import PropTypes from 'prop-types';
import { default as BaseSelect } from 'react-select';
import InvalidFeedback from '../InvalidFeedback';
import { useDarkMode } from '../../customHooks';
//#region styles

const CURSOR = 'pointer';
const BOX_SHADOW = 'inset 0 1px 1px rgba(0,0,0,.075)';
const Z_INDEX = 10;
const SMALL_HEIGHT = '30px';
const LARGE_HEIGHT = '34px';
const SMALL_PADDING = '0 0 0 3px';
const LARGE_PADDING = '6px 12px';

//Adding styles to looks consistent to other components
export const customStyles = (darkMode) => {
    return {
        control: (base, state) => ({
            ...base,
            color: darkMode ? 'white' : base.color,
            backgroundColor: darkMode ? '#1e1e1e' : base.backgroundColor,
            ':active': {
                ...base[':active'],
                backgroundColor: state.isSelected
                    ? darkMode
                        ? '#1e1e1e'
                        : 'white'
                    : darkMode
                    ? 'grey'
                    : 'white',
            },
            minHeight:
                state.selectProps.sizeProp == SELECT_SIZE.SMALL ? SMALL_HEIGHT : LARGE_HEIGHT,
            cursor: CURSOR,
            boxShadow: state.isFocused
                ? `${BOX_SHADOW}, 0 0 8px rgba(102, 175, 233, 0.6)`
                : BOX_SHADOW,
            borderColor: state.selectProps.isInvalid ? 'red' : '#cccccc',
        }),
        dropdownIndicator: (base) => ({
            ...base,
            padding: 0,
        }),
        placeholder: (base) => ({
            ...base,
            fontSize: '14px',
            color: darkMode ? 'white' : '#666',
        }),
        clearIndicator: (base) => ({
            ...base,
            padding: 0,
        }),
        option: (base, state) => ({
            ...base,
            cursor: CURSOR,
            color: darkMode ? 'white' : 'black',
            backgroundColor: darkMode ? '#1e1e1e' : state.isSelected ? '#e3edf8' : 'white',
            ':active': {
                ...base[':active'],
                backgroundColor: state.isSelected
                    ? darkMode
                        ? '#1e1e1e'
                        : 'white'
                    : darkMode
                    ? 'grey'
                    : 'white',
            },
        }),
        menu: (base) => ({
            ...base,
            'z-index': Z_INDEX,
            backgroundColor: darkMode ? '#1e1e1e' : 'white',
        }),
        valueContainer: (base, state) => ({
            ...base,
            padding:
                state.selectProps.sizeProp == SELECT_SIZE.SMALL ? SMALL_PADDING : LARGE_PADDING,
        }),
        multiValueLabel: (base) => ({
            ...base,
            padding: 0,
            paddingLeft: 0,
            whiteSpace: 'break-spaces',
            color: darkMode ? 'white' : base.color,
        }),
        multiValueRemove: (base) => ({
            ...base,
            padding: 0,
        }),
        multiValue: (base, state) => ({
            ...base,
            color: darkMode ? 'white' : base.color,
            marginColor: darkMode ? 'white' : base.marginColor,
            backgroundColor: darkMode ? '#1e1e1e' : base.backgroundColor,
            border: darkMode ? '1px solid white' : base.border,
            ':active': {
                ...base[':active'],
                backgroundColor: state.isSelected
                    ? darkMode
                        ? '#1e1e1e'
                        : 'white'
                    : darkMode
                    ? 'grey'
                    : 'white',
            },
        }),
        input: (base) => ({
            ...base,
            padding: 0,
            margin: 0,
            color: darkMode ? 'white' : base.color,
        }),
        singleValue: (base, state) => ({
            ...base,
            color: darkMode ? 'white' : base.color,
            backgroundColor: darkMode ? '#1e1e1e' : base.backgroundColor,
            ':active': {
                ...base[':active'],
                backgroundColor: state.isSelected
                    ? darkMode
                        ? '#1e1e1e'
                        : 'white'
                    : darkMode
                    ? 'grey'
                    : 'white',
            },
        }),
        noOptionsMessage: (base) => ({
            ...base,
            color: darkMode ? 'white' : base.color,
            backgroundColor: darkMode ? '#1e1e1e' : base.backgroundColor,
        }),
        menuList: (base) => ({
            ...base,
            paddingTop: 0,
            paddingBottom: 0,
        }),
    };
};

export const theme = (theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary: '#66afe9',
        neutral50: '#999999',
        primary25: '#f8f9fa',
        neutral10: 'white',
    },
});

export const SELECT_SIZE = {
    SMALL: 'sm',
    LARGE: 'lg',
};
Object.freeze(SELECT_SIZE);

//#endregion

const Select = ({
    onChange,
    options,
    placeholder,
    name,
    value,
    styles,
    theme,
    disabled,
    size,
    errorMessage,
    isInvalid,
    ...props
}) => {
    // wrapping BaseSelect in a label tag for accessibility reasons
    // See: https://github.com/JedWatson/react-select/issues/3355#issuecomment-585392536
    const darkMode = useDarkMode();
    return (
        <>
            <BaseSelect
                {...props}
                aria-label="select-input"
                onChange={onChange}
                options={options}
                placeholder={placeholder}
                styles={styles ?? customStyles(darkMode)}
                theme={theme}
                name={name}
                value={value}
                isDisabled={disabled}
                sizeProp={size}
                isInvalid={isInvalid}
                removeSelected={false}
                isSearchable={true}
            />
            {isInvalid && (
                <InvalidFeedback
                    errorMessage={errorMessage ?? 'Value is required'}
                    isInvalid={isInvalid}
                />
            )}
        </>
    );
};

export default Select;

const optionsPropTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string.isRequired,
};

Select.propTypes = {
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape(optionsPropTypes)).isRequired,
    placeholder: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape(optionsPropTypes)),
        PropTypes.shape(optionsPropTypes),
    ]),
    theme: PropTypes.func,
    styles: PropTypes.objectOf(PropTypes.any),
    size: PropTypes.oneOf([SELECT_SIZE.LARGE, SELECT_SIZE.SMALL]),
    disabled: PropTypes.bool,
    errorMessage: PropTypes.string,
    isInvalid: PropTypes.bool,
};

Select.defaultProps = {
    theme,
    size: SELECT_SIZE.LARGE,
};
