import React from 'react';
import PropTypes from 'prop-types';

import icons from '../../img/Icons';
import models from '../../models';
import PopoverOverlay from '../FormControls/PopoverOverlay';

import { strToCapital } from '../utils';
import { getViewOptionAndDate } from '../Favourites/FavouriteActions';
import { documentPropTypes, favouriteModel } from '../Favourites/utils';
import { useDarkMode } from '../customHooks';

export const formatViewOption = (option) => {
    const optionRegex = new RegExp('onthisday');
    const fOption = optionRegex.test(option) ? 'On This Day' : strToCapital(option.split('-')[1]);
    return fOption;
};

export const NewFavouritePopover = ({
    sectionID,
    showModal,
    sectionLabel,
    document,
    inTimeline,
}) => {
    const title = 'Add Favourite';
    const refID = 'new';
    const family = inTimeline
        ? inTimeline.family
        : document.model.attributes.families.find((family) => family.slug != 'all-documents');
    const docTitle = inTimeline ? inTimeline.title : document.model.attributes.title;
    const viewOption = inTimeline ? inTimeline.ttViewOption : document.model.ttViewOption;
    const viewDate = inTimeline
        ? inTimeline.ttViewDate
        : viewOption != 'tt-between'
        ? document.model.ttViewDate.format(app.settings.dateFormat)
        : `${document.model.ttViewDate.format(
              app.settings.dateFormat,
          )} - ${document.model.ttViewDate2.format(app.settings.dateFormat)}`;

    // Check if we're trying to favourite while in a search or compare view, and replace things if so
    const url = new URL(window.location.href);
    const inSearch = window.location.search;
    if (inSearch) {
        url.href = window.location.href
            .replace(`search${inSearch}`, document.model.get('document'))
            .split('#')[0];
    }

    const compareRegex = new RegExp(/compare|inter/, 'g');
    if (compareRegex.test(url.pathname)) {
        url.pathname = `${app.urls.documents}/${document.model.get('document')}`;
    }

    // make sure we're using the correct hash
    url.hash = sectionID
        ? `#${sectionID}`
        : inTimeline
        ? inTimeline.docId
        : `#${document.model.get('document')}`;
    const favourite_url = url.href;
    const model = new models.Favourite({
        name: sectionLabel ? `${sectionLabel} in ${docTitle}` : docTitle,
        ref_id: sectionID,
        family: family.title,
        favourite_url,
        document: inTimeline ? inTimeline.docId : document.options.doc.id,
        owner: app.user.get('id'),
    });

    const data = {
        isNew: true,
        viewDate,
        viewOption: formatViewOption(viewOption),
        model,
    };

    const label = `Create a new favourite for ${sectionLabel}`;

    return <FavouritePopover {...{ title, refID, data, sectionID, showModal, label }} />;
};

export const EditFavouritePopover = ({ model, sectionID, showModal, sectionLabel }) => {
    const title = 'Edit/Delete Favourite';
    const data = { ...model.attributes, model, tt_disabled: true };
    const darkMode = useDarkMode();
    const iconColour = darkMode ? '#bcc4fb' : '#0b008b';
    const iconClass = {
        colour: sectionID ? iconColour : '#e4f4ff',
        background: darkMode ? '#000' : '#fff',
    };
    const refID = sectionID;

    const faveParams = new URL(model.get('favourite_url'));
    const params = faveParams.search.split('?')[1]?.split('&');

    if (params) {
        Object.assign(data, getViewOptionAndDate(params));
    }

    const label = `View favourite for ${sectionLabel}`;

    return <FavouritePopover {...{ data, iconClass, refID, sectionID, showModal, title, label }} />;
};

const FavouritePopover = ({ title, refID, data, sectionID, showModal, iconClass, label }) => {
    return (
        <PopoverOverlay
            {...{
                placement: 'bottom',
                content: null,
                title,
                className: 'popover-dd-flag popover-dd-fave',
            }}
        >
            <button
                className={`dd-flag dd-flag-fave dd-flag-fave-${refID}`}
                ref_id={sectionID} // eslint-disable-line
                fave_id={data?.id} // eslint-disable-line
                id={`fave-${sectionID}`}
                onClick={() => showModal(data)}
            >
                <span className="sr-only">{label}</span>
                {iconClass ? icons['favourite'](iconClass) : icons['addFavourite']()}
            </button>
        </PopoverOverlay>
    );
};

NewFavouritePopover.propTypes = {
    sectionID: PropTypes.string,
    showModal: PropTypes.func.isRequired,
    sectionLabel: PropTypes.string,
    document: PropTypes.shape(documentPropTypes),
    inTimeline: PropTypes.shape({
        family: PropTypes.shape({
            slug: PropTypes.string,
        }),
        title: PropTypes.string,
        ttViewOption: PropTypes.string,
        ttViewDate: PropTypes.object,
        docId: PropTypes.string,
    }),
};

EditFavouritePopover.propTypes = {
    model: PropTypes.shape(favouriteModel).isRequired,
    sectionID: PropTypes.string,
    showModal: PropTypes.func.isRequired,
    sectionLabel: PropTypes.string,
};

FavouritePopover.propTypes = {
    title: PropTypes.string.isRequired,
    refID: PropTypes.string,
    data: PropTypes.shape(favouriteModel).isRequired,
    sectionID: PropTypes.string,
    showModal: PropTypes.func.isRequired,
    iconClass: PropTypes.object,
    label: PropTypes.string,
};
