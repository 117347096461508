import React, { useState } from 'react';

import { Button } from 'react-bootstrap';
import DownloadSelect from './DownloadSelect';
import DownloadList from './DownloadList';
import { useDownloadContext, initialDownloadState } from './DownloadContext';
import DownloadProgress from './DownloadProgress';
import DownloadWarning, { getWarningText } from './DownloadWarning';

const DEFAULT_DELAY = 5000;

const DownloadManage = () => {
    const [showProgress, setShowProgress] = useState(false);
    const { setDownloadState, countRef, setError, documentInfo, setDocumentInfo, list, setList } =
        useDownloadContext();

    const handleList = (newItem) => {
        setList((prevList) => [...prevList, newItem]);
    };

    const delay = (amount) =>
        new Promise((resolve) => {
            return setTimeout(resolve, amount);
        });

    const handleDownloadState = async (docId, pathsState) => {
        const rejected = pathsState.filter((path) => path.status === 'rejected');
        const fulfilled = pathsState.filter((path) => path.status === 'fulfilled');
        const docIdWarn =
            rejected.length !== pathsState.length && fulfilled.length !== pathsState.length
                ? true
                : false;

        countRef.current.completed = countRef.current.completed + fulfilled.length;
        countRef.current.failed = countRef.current.failed + rejected.length;

        setDownloadState((prevState) => ({
            ...prevState,
            ...(rejected.length
                ? {
                      failed: [
                          ...prevState.failed,
                          ...rejected.map((path) => path.value || path.reason),
                          ...(rejected.length === pathsState.length ? [docId] : []),
                      ],
                  }
                : {}),
            ...(fulfilled.length
                ? {
                      completed: [
                          ...prevState.completed,
                          ...fulfilled.map((path) => path.value),
                          ...(fulfilled.length === pathsState.length ? [docId] : []),
                      ],
                  }
                : {}),
            ...(docIdWarn ? { warn: [...prevState.warn, docId] } : {}),
        }));
        return;
    };

    const downloadFile = async (path, doc_id, state) => {
        try {
            const params = new URLSearchParams({ path, doc_id, state });
            const response = await fetch(`${app.urls.download}/path?${params}`, {
                method: 'GET',
            });
            const data = await response.json();

            const link = document.createElement('a');
            const blob = new Blob([atob(data.file)]);
            link.href = URL.createObjectURL(blob);
            link.setAttribute('download', data.file_name);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);
            link.remove();

            // Add a Delay based on file size to give the download time to complete
            const delayAmount = blob.size / 400; // 400 is an arbitrary number based off trial/error
            await delay(delayAmount);
            return path;
        } catch (error) {
            await delay();
            toaster.error('Failed to download file');
            return Promise.reject(path);
        }
    };

    const handleDownload = async () => {
        // Start the download process
        setShowProgress(true);
        setError({});
        setDocumentInfo(initialDownloadState);
        list.forEach(async (item) => {
            const finishedPaths = await Promise.allSettled(
                item.paths.map(async (path) => await downloadFile(path, item.doc_id, item.state)),
            );
            handleDownloadState(item.doc_id, finishedPaths);

            // Add a Delay to  give the frontend time to render the changes
            await delay(DEFAULT_DELAY);
        });
    };

    return (
        <div className="rbs4 admin download-container">
            <DownloadSelect handleList={handleList} disabled={countRef?.total > 0} />
            {Object.keys(documentInfo.downloadedWarning).length > 0 && (
                <DownloadWarning
                    warningText={getWarningText(documentInfo.downloadedWarning)}
                    className="downloaded-warning"
                    downloadedById={documentInfo.downloadedWarning.downloaded_by_id}
                />
            )}
            {Object.keys(documentInfo.stateWarnings).length > 0 &&
                Object.values(documentInfo.stateWarnings).map((warning, index) => (
                    <DownloadWarning
                        key={index}
                        warningText={getWarningText(warning)}
                        className="state-warning"
                    />
                ))}
            <DownloadList />
            <Button
                id="download-button"
                disabled={
                    list.map((item) => item.paths).flat().length && !showProgress ? false : true
                }
                onClick={handleDownload}
            >
                Download
            </Button>
            <Button
                disabled={!list.length}
                className="btn btn-secondary clear-list"
                onClick={() => {
                    setList([]);
                    setShowProgress(false);
                    setDownloadState({ warn: [], completed: [], failed: [] });
                    setDocumentInfo(initialDownloadState);
                    setError({});
                    countRef.current = { total: 0, completed: 0, failed: 0 };
                }}
            >
                Clear Download List
            </Button>
            {showProgress && <DownloadProgress />}
        </div>
    );
};

export default DownloadManage;
