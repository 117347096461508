import React, { useState, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import _ from 'lodash';

import confirm from '../../FormControls/ConfirmModal/ConfirmModalService';
import FormModal from '../../FormControls/FormModal';
import models from '../../../models';
import MultiSelect from '../../FormControls/Select/MultiSelect';

import { AnnotationsEditableBody } from './AnnotationsBody/AnnotationsEditableBody';
import { formatGroups, annotationPropTypes, getModalPosition } from '../utils';
import { handleSaveErrors } from '../../utils';
import { PRIVACY } from '../Privacy';
import { useFetch } from '../../customHooks';
import SharedHelpText from './AnnotationsBody/SharedHelpText';

const getInitiallySelectedUserGroup = (allowedGroups, item) => {
    // Get saved user groups if they exist

    if (item.user_groups?.length > 0) {
        const selectedGroup = allowedGroups.filter((allowed_g) =>
            item.user_groups.some((item_g) => item_g.id === allowed_g.id),
        );
        return formatGroups(selectedGroup);
    }

    // If nothing is selected, but only 1 group is available, select it
    if (allowedGroups.length === 1) {
        return formatGroups(allowedGroups);
    }

    return null;
};

export const EditAnnotationModal = ({ show, hideModal, item, model, setDirty, inProject }) => {
    const textRef = useRef();
    const [privacy, setPrivacy] = useState(item.privacy);
    const [groups, setGroups] = useState();
    const [project, setProject] = useState(item?.project);
    const [privacyError, setPrivacyError] = useState();
    const [bodyError, setBodyError] = useState(false);
    const [buttonText, setButtonText] = useState('Save');
    const [confirmSave, setConfirmSave] = useState(false);

    const { data, loading } = useFetch({
        doFetch: async () => await model.getAnnotationGroups(),
        setFetchedState: (allowedGroups) =>
            setGroups(getInitiallySelectedUserGroup(allowedGroups, item)),
    });

    useEffect(() => {
        if (item.privacy === PRIVACY.PROJECT) {
            setPrivacy(`${item.privacy} - ${item.project}`);
        } else {
            setPrivacy(item.privacy);
        }
    }, []);

    const availableGroups = formatGroups(data);

    const projectOptions = app.user.attributes.projects.filter((project) =>
        inProject.includes(project.id),
    );

    const checkInputValidity = async () => {
        let isValid = true;

        if (!privacy) {
            setPrivacyError('Please select privacy');
            isValid = false;
        } else if (privacy === PRIVACY.GROUP && (!groups || groups?.length == 0)) {
            setPrivacyError('Please select a group to share with');
            isValid = false;
        }

        if (textRef.current.innerHTML.trim() == '') {
            setBodyError(true);
            isValid = false;
        } else {
            setBodyError(false);
        }
        return isValid;
    };

    const submitForm = async (form) => {
        if (confirmSave) {
            const projectTitle = projectOptions.find((option) => option.id == project).title;
            const projectUsers = await new models.Project().getUsers({ id: project });
            const position = getModalPosition();
            const confirmed = await confirm({
                title: `Share with ${projectTitle}?`,
                message: (
                    <div>
                        <p>
                            {`Are you sure you want to share this annotation with ${projectTitle}? All members will be able to see this annotation.`}
                        </p>
                        <p className="project-user-list-header">{`Members of ${projectTitle}:`}</p>
                        {Object.entries(projectUsers.results)
                            .slice(0, 2)
                            .map(([org, userList]) => {
                                return (
                                    <div className="project-user-list" key={org}>
                                        <p className="user-list-org">{org} - </p>
                                        <p>{_.truncate(userList.join(', '), { length: 150 })}</p>
                                    </div>
                                );
                            })}
                        {(Object.keys(projectUsers.results).length > 2 ||
                            Object.values(projectUsers.results).some(
                                (list) => list.join(', ').length > 150,
                            )) && (
                            <a
                                href={`${app.urls.settings}#your-projects`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                View more project members
                            </a>
                        )}
                    </div>
                ),
                centered: false,
                position,
            });

            if (!confirmed) {
                return false;
            }
        }
        try {
            item.model.set('title', form.elements.title.value);
            item.model.set('text', textRef.current.innerHTML);
            item.model.set(
                'privacy',
                // remove the project name so it can be stored properly in Django
                privacy.includes(`${PRIVACY.PROJECT} - `) ? privacy.split(' - ')[0] : privacy,
            );

            if (!privacy.includes(`${PRIVACY.PROJECT} - `) && item.project) {
                item.model.set('project', null);
            } else {
                item.model.set('project', parseInt(project));
            }

            const selectedGroups = privacy !== PRIVACY.GROUP ? [] : groups.map((g) => g.value);
            item.model.set('group_ids', selectedGroups);

            if (Object.hasOwn(item, 'id')) {
                item.model.set('edited', true);
            }

            await item.model.save();
            setDirty();
            toaster.success('Annotation saved');
        } catch (error) {
            handleSaveErrors({
                xhr: error,
                genericMessage: 'Could not save the annotation',
                validFormFields: ['text', 'title', 'privacy'],
            });

            return false;
        }
    };

    const PrivacyRadio = ({ label, id, disabled, error }) => {
        return (
            <Form.Check
                type="radio"
                label={label}
                name="privacy"
                id={id}
                defaultChecked={privacy === id}
                onChange={() => {
                    setPrivacy(id);
                    setPrivacyError(null);
                    // check if it's a project privacy
                    if (id.includes(`${PRIVACY.PROJECT} - `)) {
                        setButtonText(`Save and share with ${label.replace('Share with ', '')}`);
                        setProject(id.replace(`${PRIVACY.PROJECT} - `, ''));
                        setConfirmSave(true);
                    } else {
                        setButtonText('Save');
                        setProject(null);
                        setConfirmSave(false);
                    }
                }}
                disabled={disabled}
                required
                isInvalid={error}
                feedback={error}
            />
        );
    };
    PrivacyRadio.propTypes = {
        label: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
        error: PropTypes.string,
    };

    const autoFocus = useCallback((element) => {
        return element ? element.focus() : null, [];
    });

    const groupRadioLabel =
        availableGroups?.length > 1
            ? `Share with specific group(s) within ${app.user.attributes.organisation_name} only`
            : `Share with all users within ${app.user.attributes.organisation_name}`;

    return (
        <>
            <FormModal
                {...{
                    loading,
                    submitForm,
                    show,
                    hideModal,
                    checkInputValidity,
                    title: `${item.isNew ? 'Add' : 'Edit'} annotation${
                        item.ref_label ? ' for ' + item.ref_label : ''
                    }`,
                    size: 'sm',
                    backdrop: 'static',
                    isMain: true,
                    saveText: buttonText,
                    className: `annotations ${
                        item?.model ? item.model.attributes?.ref_id : item.ref_id
                    }`,
                }}
            >
                <Form.Group>
                    <Form.Label>Annotation title</Form.Label>
                    <Form.Row id="annotation-modal-title-row">
                        <Form.Control
                            id="annotation-modal-title"
                            aria-label="annotation title"
                            type="text"
                            name="title"
                            defaultValue={item.title}
                            required
                            ref={autoFocus}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                            Please provide a title
                        </Form.Control.Feedback>
                    </Form.Row>
                </Form.Group>
                <AnnotationsEditableBody text={item.text} textRef={textRef} bodyError={bodyError} />

                <fieldset className="fieldset-box">
                    <Form.Group>
                        <Form.Label>Privacy</Form.Label>
                        <PrivacyRadio {...{ label: 'Private', id: PRIVACY.PRIVATE }} />

                        {/* Below are 2 deprecated options - show them disabled only if already in use */}
                        {item.privacy === PRIVACY.PUBLIC && (
                            <PrivacyRadio
                                {...{
                                    label: 'Shared with colleagues',
                                    id: PRIVACY.PUBLIC,
                                    disabled: true,
                                }}
                            />
                        )}
                        {item.privacy === PRIVACY.GLOBAL && (
                            <PrivacyRadio
                                {...{ label: 'Global', id: PRIVACY.GLOBAL, disabled: true }}
                            />
                        )}
                        <div className="radio-option-shared">
                            <PrivacyRadio
                                {...{
                                    label: groupRadioLabel,
                                    id: PRIVACY.GROUP,
                                    error: privacyError,
                                }}
                            />
                            <SharedHelpText />
                        </div>
                        {availableGroups.length > 1 && privacy == PRIVACY.GROUP && (
                            <MultiSelect
                                onChange={(groups) => {
                                    setGroups(groups);
                                    setPrivacyError(null);
                                }}
                                options={availableGroups}
                                placeholder="Select Groups"
                                name="Groups"
                                value={groups}
                                key="group"
                                disabled={availableGroups.length == 1}
                                required
                            />
                        )}
                        {inProject &&
                            projectOptions.map((project) => {
                                return (
                                    <div key={project.id} className="radio-option-project">
                                        <PrivacyRadio
                                            {...{
                                                label: `Share with ${_.truncate(project.title)}`,
                                                // radios get confused if some have same id
                                                id: `${PRIVACY.PROJECT} - ${project.id}`,
                                                error: privacyError,
                                            }}
                                        />
                                        <a
                                            href={`${app.urls.settings}#your-projects`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            View project members
                                        </a>
                                    </div>
                                );
                            })}
                    </Form.Group>
                </fieldset>
            </FormModal>
        </>
    );
};

EditAnnotationModal.propTypes = {
    item: PropTypes.shape(annotationPropTypes),
    show: PropTypes.bool.isRequired,
    hideModal: PropTypes.func.isRequired,
    model: PropTypes.shape({
        getAnnotationGroups: PropTypes.func.isRequired,
    }).isRequired,
    setDirty: PropTypes.func.isRequired,
    inProject: PropTypes.arrayOf(PropTypes.number),
};
