/*
    Backbone Mixin based on gist here: https://gist.github.com/hxgdzyuyi/3652964
*/

define([
    'backbone'
], function (Backbone) {
    Backbone.mixin = function(view) {
        var source = view.prototype || view;
        // Normally we could use Array.from here to convert the arguments list into a proper array
        // unfortunately this isn't supported in IE until Edge
        // Instead we call splice manually through Array's prototype as it's generic enough not to
        // care if the value is an Array, all that's needed is a length property.
        // Duck typing ftw
        var mixins = Array.prototype.splice.call(arguments, 1);

        _.each(mixins, function(mixin) {
            _.defaults(source, mixin);
            if(mixin.events) {
                if(source.events) {
                    _.defaults(_.result(source, 'events'), _.result(mixin, 'events'));
                } else {
                    source.events = mixin.events;
                }
            }
            if(mixin.initialize !== undefined) {
                var oldInitialize = source.initialize;
                source.initialize = function() {
                    mixin.initialize.apply(this, arguments);
                    oldInitialize.apply(this, arguments);
                }
            }
        });
    }
    return Backbone;
});
