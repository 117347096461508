const getPercentageUploads = (upload, comparator) => {
    if (!upload || upload.total_count === 0) {
        return 0;
    }
    return Math.min(100, Math.round((100 * comparator) / upload.total_count));
};

export const getPercentageFailedUploads = (upload) => {
    return getPercentageUploads(upload, upload?.failed_count);
};

export const getPercentageSuccessfulUploads = (upload) => {
    return getPercentageUploads(upload, upload?.successful_count);
};

export const getPercentageMetadataUploads = (upload) => {
    return getPercentageUploads(upload, upload?.metadata_count);
};

export const getPercentageIndexedUploads = (upload) => {
    return getPercentageUploads(upload, upload?.indexed_count);
};

export const getPercentageCompletedUploads = (upload) => {
    return getPercentageUploads(upload, upload?.successful_count + upload?.failed_count);
};
