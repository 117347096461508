import React from 'react';
import PropTypes from 'prop-types';
import requiredIf from 'react-required-if';

import { asyncAction } from '../../utils';
import FormModal, { MODAL_SIZE } from '../../FormControls/FormModal';
import FlagNote from './FlagNote';

export const ResolveFlagsModal = ({
    show,
    flag,
    hideFlagAction,
    hideFlags,
    setDirty,
    flagsModel,
}) => {
    const submitForm = async (form) => {
        const args = {
            flag_id: flag.id,
            notes: form.elements.notes.value,
        };

        const fun = (params) => flagsModel.resolveFlag(params);

        await asyncAction({ fun, setDirty, args, actionName: 'resolve flags' });

        hideFlagAction();
        hideFlags();
    };

    return (
        <FormModal
            title="Resolve flags"
            hideModal={hideFlagAction}
            size={MODAL_SIZE.SMALL}
            {...{ submitForm, show }}
        >
            <FlagNote required={true} />
        </FormModal>
    );
};

ResolveFlagsModal.propTypes = {
    hideFlagAction: PropTypes.func.isRequired,
    hideFlags: PropTypes.func.isRequired,
    setDirty: PropTypes.func.isRequired,
    flagsModel: PropTypes.shape({
        resolveFlag: PropTypes.func.isRequired,
    }),
    show: PropTypes.bool.isRequired,
    flag: requiredIf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
        }),
        (props) => props.show,
    ),
};
