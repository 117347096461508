import React, { createContext, useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';

const WithDownloadContext = createContext({});

export const useDownloadContext = () => useContext(WithDownloadContext);

export const initialDownloadState = {
    documentState: null,
    documentType: null,
    documentId: '',
    stateWarnings: {},
    downloadedWarning: {},
};

const WithDownloadProvider = ({ children }) => {
    const [downloadState, setDownloadState] = useState({
        warn: [],
        completed: [],
        failed: [],
    }); // Keeps track of each document as a whole
    const [error, setError] = useState({}); // Keeps track of input errors in DocumentSelect
    const [documentInfo, setDocumentInfo] = useState(initialDownloadState);
    const [list, setList] = useState([]);
    const countRef = useRef({ total: 0, completed: 0, failed: 0 });

    return (
        <WithDownloadContext.Provider
            value={{
                downloadState,
                setDownloadState,
                countRef,
                error,
                setError,
                documentInfo,
                setDocumentInfo,
                list,
                setList,
            }}
        >
            {children}
        </WithDownloadContext.Provider>
    );
};

export default WithDownloadProvider;

WithDownloadProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
