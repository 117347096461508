define([
    'backbone',
    '../../models',
    '../helpers',
    './titlebar',
    './toc',
    './content',
    '../../templates/document/compare/content.html',
    '../../templates/document/compare/finder.html',
    '../../templates/document/compare/recent_doc.html',
    '../../templates/document/compare/titlebar.html',
    '../../extras/backbone-mixin',
], function (
    Backbone,
    models,
    helpers,
    titlebarViews,
    DocumentTOCView,
    DocumentContentView,
    contentTpl,
    finderTpl,
    recentDocTpl,
    titleBarTpl,
) {
    const React = require('react');
    const ReactDOM = require('react-dom');
    /*
        Search bits and pieces
    */
    var SearchMixin = {
        initQuickSearch: function () {
            const DocumentFinder =
                require('../../components/DocumentFinder/DocumentFinder').default;
            const element = document.getElementById(
                `compare-toolbar-qsearch-${this.options.target}`,
            );
            // This is needed, because some popup pages don't have a search toolbar
            if (element) {
                const targetPanel = this.options.target === 'left' ? 'Document 1' : 'Document 2';
                ReactDOM.render(
                    <DocumentFinder
                        compareDoc={true}
                        target={targetPanel}
                        handleSelection={this.navigateCallback.bind(this)}
                    />,
                    element,
                );
            }
        },
        navigateCallback: function (docId, docInstance) {
            app.trigger('document:open', docId, docInstance, this.options.target);
        },
    };

    /*
        recent document item
    */
    var RecentDocView = helpers.TemplateView.extend({
        template: recentDocTpl,
        events: {
            'click .btn-lg': 'openDocument',
        },
        openDocument: function (evt) {
            evt.preventDefault();
            // bubble up to the parent view, where the target is added
            this.trigger('document:open', this.model.get('document').doc_id);
        },
    });

    /*
        Finder window (left and right)
    */
    var FinderView = helpers.CollectionView.extend({
        template: finderTpl,
        itemView: RecentDocView,
        itemViewContainer: '.compare-doc-chooser ul',
        events: {
            'click .toolbar-tod a': function (evt) {
                app.trigger('popupWin:open', evt);
            },
        },
        initialize: function () {
            this.listenTo(
                this,
                'item:document:open',
                function (view, args) {
                    app.trigger('document:open', args[0], null, this.options.target);
                },
                this,
            );
        },
        templateHelpers: function () {
            return {
                target: this.options.target,
            };
        },
    });

    Backbone.mixin(FinderView, SearchMixin);

    /*
        Titlebar view
    */
    var TitlebarView = titlebarViews.TitlebarView.extend({
        template: titleBarTpl,
        initialize: function () {
            titlebarViews.TitlebarView.prototype.initialize.apply(this, arguments);
            this.listenTo(this, 'render', this.toggleDatePickers);
        },
        events: function () {
            return _.extend(titlebarViews.TitlebarView.prototype.events, {
                'click .pane-open-new': 'openDocument',
            });
        },
        toggleDatePickers: function () {
            if (this.model.get('timetravel')) {
                this.$('.has-modal-dd-date-from, .has-modal-dd-date-to, .btn-sig-date')
                    .removeClass('disabled')
                    .prop('disabled', false);
            } else {
                this.$('.has-modal-dd-date-from, .has-modal-dd-date-to, .btn-sig-date')
                    .addClass('disabled')
                    .prop('disabled', true);
            }
        },
        templateHelpers: function () {
            return _.extend(
                titlebarViews.TitlebarView.prototype.templateHelpers.apply(this, arguments),
                {
                    title: this.options.title,
                },
            );
        },
        openDocument: function () {
            // make sure we remove this when we exit Compare View
            localStorage.removeItem('opener_activeElement');

            // opens document in a new window
            var url = app.urls.documents + '/' + this.model.get('document');
            if (this.model.get('state') != 'published') {
                url += '/' + this.model.get('uuid');
            }
            if (
                this.model.ttViewOption !== 'tt-current' ||
                app.moment(this.model.ttViewDate).format(app.settings.dateFormat) !==
                    app.moment().format(app.settings.dateFormat)
            ) {
                url += `?tt_option=${this.model.ttViewOption}&tt_date=${app
                    .moment(this.model.ttViewDate)
                    .format(app.settings.dateTransportFormat)}`;
                if (this.model.ttViewOption === 'tt-between') {
                    url += `&tt_date2=${app
                        .moment(this.model.ttViewDate2)
                        .format(app.settings.dateTransportFormat)}`;
                }
            }

            // a bit grim, but we need to figure out if we're in the left or right pane
            const pane = $(this.$el).hasClass('titlebar-left') ? 'left' : 'right';

            url += `#${app._compareView.contentViews[pane].activeElement}`;
            window.manager.open(url);
        },
    });

    /*
        Document Toc
    */
    var TOCView = DocumentTOCView.extend({
        el: null,
        attributes: {
            role: 'navigation',
        },
        className: function () {
            return (
                'pane-left pane-toc with-panetitle compare-' + this.options.target + '-pane-left'
            );
        },
        initialize: function () {
            DocumentTOCView.prototype.initialize.apply(this, arguments);
            this.bodyClassExpanded = 'compare-' + this.options.target + '-with-pane-left';
            this.bodyClassContracted = 'compare-' + this.options.target + '-with-pane-left-min';
            this.tocId = '#toc' + this.options.ordinal;
            this.contentId = '#main' + this.options.ordinal;
            this.annoId = '#anno-' + this.options.target;
        },
    });

    /*
        Document Content
    */
    var ContentView = DocumentContentView.extend({
        el: null,
        template: contentTpl,
        className: function () {
            return 'pane-main pane-doc has-history with-panetitle compare-' + this.options.target;
        },
        navigateOnHashChange: false,
        events: function () {
            return _.extend(DocumentContentView.prototype.events, {
                'click .change-compare-doc': 'toggleInlineFinder',
            });
        },
        templateHelpers: function () {
            return _.extend(DocumentContentView.prototype.templateHelpers.apply(this, arguments), {
                target: this.options.target,
            });
        },
        toggleInlineFinder: function () {
            // for the moment, go back to finder view
            app.trigger('document:compare:switch');
            this.trigger('finder:open');
        },
        initializeDocument: function () {
            // Add the current doctype to the toc and main pane
            const tocId = this.options.toc_id;
            const mainId = this.options.id;
            const target = this.options.target;
            return DocumentContentView.prototype.initializeDocument.apply(this, [
                tocId,
                mainId,
                target,
            ]);
        },
    });

    /*
        Left and right container
    */
    var CompareView = helpers.BaseView.extend({
        el: 'body',
        events: {
            'click #titlebar1, .toolbar-btm-left, #toc1, #main1': 'focusLeft',
            'click #titlebar2, .toolbar-btm-right, #toc2, #main2': 'focusRight',
            'click .pane-close-doc': 'closeDocPane',
            'click .doc-state-option a': 'changeDocState',
        },
        initialize: function () {
            this.recentDocuments = new models.RecentDocuments();
            this.ordinals = { left: 1, right: 2 };
            this.models = {};

            this.recentDocuments.fetch().done(
                _.bind(function () {
                    if (this.options.documentID) {
                        this.openDocument(
                            this.options.documentID,
                            this.options.documentInstance,
                            this.options.target,
                            this.options.hash,
                        );
                        // opening an intermediate window as side-by-side view
                        if (this.options.right?.documentID) {
                            this.openDocument(
                                this.options.right.documentID,
                                this.options.right.documentInstance,
                                this.options.right.target,
                                this.options.right.hash,
                            );
                        } else {
                            this.renderFinder('right');
                        }
                    } else {
                        this.renderFinder('left');
                        this.renderFinder('right');
                    }
                }, this),
            );

            // triggered by the ToD
            this.listenTo(app, 'document:open', this.openDocument);

            // subviews
            this.titlebarViews = {};
            this.toolbarBottomViews = {};
            this.finderViews = {};
            this.tocViews = {};
            this.contentViews = {};
        },
        focusLeft: function () {
            this.focusPane('left');
        },
        focusRight: function () {
            this.focusPane('right');
        },
        focusPane: function (target) {
            if (this.models[target]) {
                app.trigger('document:compare:switch', this.models[target]);
                var other = target == 'left' ? 'right' : 'left';
                this.$('.compare-' + target).addClass('pane-selected');
                this.$('.compare-' + other).removeClass('pane-selected');
            }
        },
        clearViews: function (target) {
            if (this.titlebarViews[target]) {
                this.titlebarViews[target].stopListening();
                this.titlebarViews[target].undelegateEvents();
            }

            if (this.toolbarBottomViews[target]) {
                this.toolbarBottomViews[target].stopListening();
                this.toolbarBottomViews[target].undelegateEvents();
            }

            if (this.finderViews[target]) {
                this.finderViews[target].remove();
            }
            if (this.tocViews[target]) {
                this.tocViews[target].remove();
            }
            if (this.contentViews[target]) {
                this.contentViews[target].remove();
            }
        },
        /*
            Left and Right search panels
        */
        renderFinder: function (target) {
            // remove existing views
            this.clearViews(target);

            // Finder
            this.finderViews[target] = new FinderView({
                collection: this.recentDocuments,
                target: target,
                id: 'main' + this.ordinals[target],
                className: 'pane-main compare-' + target,
            });
            this.finderViews[target].render();
            this.$('#content').append(this.finderViews[target].$el);
            this.finderViews[target].delegateEvents();
            this.finderViews[target].initQuickSearch();

            // reset width of toc and content
            $('body')
                .removeClass('compare-' + target + '-with-pane-left')
                .removeClass('compare-' + target + '-with-pane-left-min');

            // ick
            var dummyModel = new Backbone.Model({
                title: 'Document ' + this.ordinals[target],
                timetravel: false,
            });

            // Title bar
            this.titlebarViews[target] = new TitlebarView({
                model: dummyModel,
            });
            this.assign(this.titlebarViews[target], '#navigation .titlebar-' + target);

            this.$('#navigation .titlebar-' + target).addClass('titlebar-nodate');

            // Bottom toolbar
            this.toolbarBottomViews[target] = new titlebarViews.ToolbarBottomView({
                model: dummyModel,
            });
            this.assign(this.toolbarBottomViews[target], '#navigation .toolbar-btm-' + target);
        },
        openDocument: function (docId, docInstance, target, hash, timeTravelInfo) {
            // if we've closed the other pane and have just the RoDs, we can navigate straight to a document
            if (!$(this.el).hasClass('with-compare')) {
                window.location.href = `${app.urls.documents}/${docId}`;
            }

            var doc = new models.Document({
                doc_id: docId,
                activeInstance: docInstance,
            });

            app.documents.push(doc);

            var annotations = new models.Annotations({ document: docId });
            const favourites = new models.Favourites({ document: docId });

            $.when(annotations.fetch(), favourites.fetchByDoc(), doc.fetch()).done(
                _.bind(function () {
                    var docInstance = doc.getInstance();

                    // Store the new models in the compare view
                    this.models[target] = docInstance;
                    this.models[target].ttViewOption =
                        new URLSearchParams(window.location.search).get('tt_option') ||
                        timeTravelInfo?.tt_option ||
                        'tt-current';

                    // Update the model's view dates based off the time travel info if we were sent some
                    this.models[target].ttViewDate = timeTravelInfo?.tt_date
                        ? app.moment(timeTravelInfo.tt_date, app.settings.dateTransportFormat)
                        : app.moment();
                    this.models[target].ttViewDate2 = timeTravelInfo?.tt_date2
                        ? app.moment(timeTravelInfo.tt_date, app.settings.dateTransportFormat)
                        : app.moment();

                    this.listenTo(this.models[target], 'timetravel:change', this.timeTravel);

                    // remove existing views
                    this.clearViews(target);

                    // Do timetravel class fixing
                    this.$('#navigation .titlebar-' + target).removeClass('titlebar-nodate');

                    // one document may have timetravel the other may not
                    if (docInstance.get('timetravel')) {
                        $('body').addClass('with-timetravel');
                    }

                    // Title bar
                    this.titlebarViews[target] = new TitlebarView({
                        model: docInstance,
                    });
                    this.assign(this.titlebarViews[target], '#navigation .titlebar-' + target);
                    this.titlebarViews[target].togglePaneTools(true);

                    // Bottom toolbar
                    this.toolbarBottomViews[target] = new titlebarViews.ToolbarBottomView({
                        target: target,
                        model: docInstance,
                    });
                    this.assign(
                        this.toolbarBottomViews[target],
                        '#navigation .toolbar-btm-' + target,
                    );

                    // ToC view
                    this.tocViews[target] = new TOCView({
                        target: target,
                        ordinal: this.ordinals[target],
                        id: 'toc' + this.ordinals[target],
                        model: docInstance,
                        doc: doc,
                    });

                    this.listenTo(this.tocViews[target], 'render', function () {
                        this.$('#content').append(this.tocViews[target].$el);
                    });
                    this.tocViews[target].render();

                    // Content view
                    this.contentViews[target] = new ContentView({
                        target: target,
                        id: 'main' + this.ordinals[target],
                        model: docInstance,
                        doc: doc,
                        recentDocuments: this.recentDocuments,
                        annotations: annotations,
                        favourites: favourites,
                        paneHash: hash,
                        toc_id: 'toc' + this.ordinals[target],
                    });
                    this.listenTo(this.contentViews[target], 'render', function () {
                        this.$('#content').append(this.contentViews[target].$el);
                        app.trigger('compareView:render');
                        this.setFavourite(target);
                    });
                    this.contentViews[target].render();
                    this.listenTo(this.contentViews[target], 'finder:open', function () {
                        const annoId = target ? 'anno-' + target : 'anno';
                        // remove annotations
                        const oldAnnotations = $('#' + annoId);
                        if (oldAnnotations !== 0) {
                            oldAnnotations.remove();
                        }
                        this.renderFinder(target);
                    });

                    // body class
                    $('body').addClass('compare-' + target + '-with-pane-left-min');

                    // trigger document change and focus
                    this.focusPane(target);
                }, this),
            );
        },
        closeDocPane: function (target) {
            this.clearViews(target);

            // make sure we remove this when we exit Compare View
            localStorage.removeItem('opener_activeElement');

            const openPane = Object.keys(this.ordinals).find(
                (ordinal) =>
                    !$(target.currentTarget).parents().parents().get(0).className.includes(ordinal),
            );

            if (this.models[openPane]) {
                const pane = this.models[openPane];
                let url = `${app.urls.documents}/${pane.attributes.document}`;

                // get doc state
                if (app.user.has_perm('content.view_all_document_states')) {
                    url += '/' + pane.id;
                }

                // check if we've time travelled
                if (
                    pane.ttViewOption !== 'tt-current' ||
                    app.moment(pane.ttViewDate).format(app.settings.dateFormat) !==
                        app.moment().format(app.settings.dateFormat)
                ) {
                    url += `?tt_option=${pane.ttViewOption}&tt_date=${app
                        .moment(pane.ttViewDate)
                        .format(app.settings.dateTransportFormat)}`;
                    if (pane.ttViewOption === 'tt-between') {
                        url += `&tt_date2=${app
                            .moment(pane.ttViewDate2)
                            .format(app.settings.dateTransportFormat)}`;
                    }
                }
                url += `#${this.contentViews[openPane].activeSection}`;
                window.location.href = url;
            } else {
                window.location.href = app.urls.compare;
            }
        },
        changeDocState: function (evt) {
            const docID = $(evt.currentTarget).data('docid');
            const docInstance = $(evt.currentTarget).data('docinstance');
            const target = $(evt.currentTarget).data('target');

            this.openDocument(docID, docInstance, target);
        },
        timeTravel: function () {
            // If the view date is less than the document published date
            // then the whole document should be 'tts-doc-not-in-force'.
            // This can be applied to the inner wrapper div of content
            // and TOC.
            for (const i in this.models) {
                // If view option is tt-between use the second view date to
                // determine if the whole document is in force or not.
                var ttViewDate =
                    this.models[i].ttViewOption != 'tt-between'
                        ? this.models[i].ttViewDate
                        : this.models[i].ttViewDate2;
                var ttDocDate = this.contentViews[i].norris.docEnforDate;

                if (app.moment(ttDocDate, 'YYYYMMDD').isAfter(ttViewDate)) {
                    $('.compare-' + i + '.pane-doc .inner, .pane-toc .inner').addClass(
                        'tts-doc-not-in-force',
                    );
                } else {
                    $('.compare-' + i + '.pane-doc .inner, .pane-toc .inner').removeClass(
                        'tts-doc-not-in-force',
                    );
                }
                this.setFavourite(i);
            }
        },
        setFavourite: function (target) {
            const DocumentFavourite =
                require('../../components/DocumentFavourites/DocumentFavourite').default;

            const paneId = target === 'left' ? 'titlebar1' : 'titlebar2';
            const element = document.getElementById(paneId).querySelector('#title-favourite');
            const that = this.contentViews[target];

            if (element && that.options.favourites) {
                ReactDOM.render(
                    <DocumentFavourite
                        document={that}
                        sectionLabel=""
                        sectionID=""
                        canDelete={true}
                    />,
                    element,
                );
            }
        },
    });

    return CompareView;
});
