import React, { useState, forwardRef } from 'react';
import Form from 'react-bootstrap/Form';
import ReactDatePicker from 'react-datepicker';
import PropTypes from 'prop-types';

import 'react-datepicker/dist/react-datepicker.css';

import { enGB } from 'date-fns/locale';
import PiconIcon from './Icons/PiconIcon';

export const DatePicker = ({ minDate, maxDate, required, name, initialDate }) => {
    const [date, setDate] = useState(initialDate);
    const changeDate = (date) => setDate(date);

    const ref = React.createRef();

    const formatDate = (date) => {
        if (!date) return null;
        return app.moment(date, 'DD/MM/YYYY').format();
    };

    const TextField = forwardRef(({ onClick, value, onChange }, _ref) => {
        return (
            <>
                <PiconIcon className="blue internal" iconName="calendar" />
                <Form.Control
                    placeholder="DD/MM/YYYY"
                    type="text"
                    ref={_ref}
                    {...{ onChange, onClick, value, name, required }}
                    autoComplete="off"
                    data-formatted={formatDate(value)}
                />
                <Form.Control.Feedback type="invalid">Please choose a date.</Form.Control.Feedback>
            </>
        );
    });

    TextField.propTypes = {
        onClick: PropTypes.func,
        value: PropTypes.string,
        onChange: PropTypes.func,
    };

    return (
        <ReactDatePicker
            selected={date}
            onChange={changeDate}
            locale={enGB}
            dateFormat="dd/MM/yyyy"
            minDate={minDate}
            maxDate={maxDate}
            calendarClassName="calendar-picker"
            customInput={<TextField ref={ref} />}
        />
    );
};

DatePicker.defaultProps = {
    name: 'date',
};

DatePicker.propTypes = {
    initialDate: PropTypes.instanceOf(Date),
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
    required: PropTypes.bool,
    name: PropTypes.string,
};
