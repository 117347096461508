import React, { useState } from 'react';
import PropTypes from 'prop-types';

import NoteInput from './NoteInput';
import ScheduleActivation from './ScheduleActivation';
import WorkplaceSection from './WorkplaceSection';
import confirm from '../FormControls/ConfirmModal/ConfirmModalService';
import ConfirmationEmailSelector from '../FormControls/ConfirmationEmailSelector';
import FormInputUncontrolled from '../FormControls/Input/FormInputUncontrolled';
import FormModal from '../FormControls/FormModal';
import models from '../../models.js';

import { getUserGroups, getUserGroupAlertMessage, getPreAlerts } from './utils';
import { useFetch } from '../customHooks';
import { checkEmailValidity } from '../Modals/utils';
import { handleSaveErrors, strToCapital, arrayNotEmpty } from '../utils';

//#region constants

const SCHEDULE_TYPES = {
    IMMEDIATE: 0,
    SCHEDULED: 1,
};
Object.freeze(SCHEDULE_TYPES);

//#endregion

const UserAdderModal = ({ show, hideModal, setDirty }) => {
    const [data, setData] = useState({});
    const [editedData, setEditedData] = useState({});
    const [validateChildren, setValidateChildren] = useState({});
    const [multiOrgs, setMultiOrgs] = useState();
    const [preAlerts, setPreAlerts] = useState([]);
    const metadataModel = new models.Metadata();
    const userModel = new models.User();

    const { data: metadata, loading } = useFetch({
        doFetch: async () => await metadataModel.fetch(),
    });

    const submitForm = async (form) => {
        if (preAlerts.length > 0) {
            // trigger confirm modal for pre-alerts
            const isMultiple = preAlerts.length > 1;
            const confirmed = await confirm({
                title: `User Group Warning${isMultiple ? 's' : ''}`,
                message: getUserGroupAlertMessage(isMultiple, preAlerts),
                centered: true,
            });

            if (!confirmed) return false;
        }
        try {
            const organisation = data.organisation.value;
            const user_groups = data.user_groups.map((group) => {
                return { id: group.value };
            });

            const email_template = form.elements.emailTemplate?.value;
            const notes = form.elements.notes?.value;
            const schedule = form.elements.schedule?.value;
            const activation_req_on = form.elements?.date
                ? form.elements.date.dataset.formatted
                : app.moment().format();
            const allData = {
                ...data,
                organisation,
                user_groups,
                email_template,
                notes,
                activation_req_on,
                ...(schedule == SCHEDULE_TYPES.SCHEDULED && {
                    state: 'scheduled',
                }),
            };

            const result = await userModel.action(allData, 'POST');
            toaster.success(`New user ${result.email} successfully added`);
            setDirty();
        } catch (error) {
            handleSaveErrors({
                xhr: error,
                genericMessage: 'The user could not be added',
                validFormFields: ['email', 'organisation', 'user_groups'],
            });
            return false;
        }
    };

    const checkInputValidity = () => {
        setValidateChildren({ validate: true });
        if (data.organisation && arrayNotEmpty(data.user_groups)) {
            return true;
        }
        return false;
    };

    const checkDomainValidity = async (domain) => {
        try {
            const result = await userModel.validateDomain({ domain });
            return result.result;
        } catch (error) {
            console.error('Could not validate domain', error);
        }
    };

    const handleEmailAutofill = async (email) => {
        try {
            setMultiOrgs(undefined);
            // Auto-fills first/last names and organisation, user group from email input
            //  But only if they haven't been changed by user
            if (!checkEmailValidity(email)) return;
            const emailSplit = email.split('@');
            const unvalidatedDomain = emailSplit[1].toLowerCase();
            const fullName = emailSplit[0].split('.').map((name) => strToCapital(name));

            const first_name = preFillValue('first_name', fullName[0]);
            const last_name = preFillValue('last_name', fullName.length > 1 ? fullName[1] : '');

            // This is does an api call to check that the domain matches only 1 user group in only 1 organisation.
            // It doesn't blow up if they don't match, just won't autofill org, user group and will add an invalid_domain tag to the info sent to the db to flag the user
            const validUserGroupByDomain = await checkDomainValidity(unvalidatedDomain);
            // If the domain is invalid because it's in multiple groups within 1 organisation, the organisation_id is still sent back to trigger autofill
            const validOrg = metadata?.organisations?.find(
                (org) => org.id === validUserGroupByDomain.organisation_id,
            );
            let organisation = data.organisation;
            let user_groups = data.user_groups;
            if (validOrg && !editedData.organisation) {
                // If email matches a valid domain, and organisation hasn't been manually selected, preselect it
                organisation = { value: validOrg.id, label: validOrg.name };
                // Only preselect group if organisation has changed - because selected groups will become invalid
                if (data.organisation?.value != validOrg.id) {
                    // The pre-selected user group should be the one that the matched domain belongs to
                    user_groups = getUserGroups(metadata?.subscription_groups, validOrg.id)?.filter(
                        (group) => {
                            if ('id' in validUserGroupByDomain) {
                                if (group.value == validUserGroupByDomain.id) return group;
                            } else {
                                // or just select the default
                                if (group.isDefault) {
                                    return group;
                                }
                            }
                        },
                    );
                }
            }
            // If the email domain matches multiple organisations, do not autofill but pass suggested orgs to the select
            const groupOrgs = [];
            if (validUserGroupByDomain.invalid_domain?.multi_groups) {
                metadata?.subscription_groups?.map((group) => {
                    if (
                        group.domains?.includes(unvalidatedDomain) &&
                        !groupOrgs.includes(group.org_id)
                    ) {
                        groupOrgs.push(group.org_id);
                    }
                });
            }
            if (groupOrgs.length > 1) {
                setMultiOrgs(groupOrgs);
            }

            if (email != data.email && 'invalid_domain' in data) {
                // If the domain was invalid but now it's valid, remove the error message
                delete data.invalid_domain;
            }
            if ('domains' in validUserGroupByDomain) {
                // This might seem redundant seeing as we've checked the domain in the backend, but that's fuzzy matching for the group
                // This double checks that what's typed exact matches a domain in the list
                // (ex: isotoma.co matches enough for autofill, but would be caught and flagged here if domain in list contains isotoma.com)
                const domainList = validUserGroupByDomain.domains.replaceAll(' ', '').split(',');
                const exactMatch = domainList.find((domain) => domain === unvalidatedDomain);
                if (!exactMatch) {
                    validUserGroupByDomain.invalid_domain = {
                        no_groups: `Email domain "${email}" is not listed as a valid domain for any user groups`,
                    };
                }
            }

            if (user_groups) {
                setPreAlerts(getPreAlerts(user_groups));
            }

            setData({
                ...data,
                email,
                first_name,
                last_name,
                organisation,
                user_groups,
                ...(validUserGroupByDomain.invalid_domain
                    ? { invalid_domain: validUserGroupByDomain.invalid_domain }
                    : {}),
            });
        } catch (error) {
            console.error("Couldn't autofill user details: ", error);
        }
    };

    // Pre-fill with new value, but only if field hasn't been changed
    const preFillValue = (name, newValue) => {
        if (editedData[name]) return data[name];
        return newValue;
    };

    const onChange = (newVal, name) => {
        setData({ ...data, [name]: newVal });
        setEditedData({ ...editedData, [name]: newVal });
    };

    return (
        <FormModal
            title="Add new user"
            {...{
                submitForm,
                hideModal,
                show,
                checkInputValidity,
                loading,
                backdrop: 'static',
                className: 'add-user',
            }}
        >
            <FormInputUncontrolled
                label="Email address"
                type="email"
                name="email"
                invalidMessage="Please enter a valid email address"
                required={true}
                value={data.email}
                onChange={(newVal) => {
                    handleEmailAutofill(newVal);
                }}
            />
            <FormInputUncontrolled
                label="First name"
                name="first_name"
                value={data.first_name}
                onChange={onChange}
            />
            <FormInputUncontrolled
                label="Last name"
                name="last_name"
                value={data.last_name}
                onChange={onChange}
            />
            <WorkplaceSection
                {...{
                    setData,
                    data,
                    metadata,
                    setEditedData,
                    editedData,
                    shouldCheckValidity: validateChildren,
                    multiOrgs,
                    setPreAlerts,
                }}
            />

            <NoteInput maxLength={2000} />
            <ScheduleActivation {...{ setData, data }} />
            <ConfirmationEmailSelector
                templateLabel={app.emailTemplateLabels.ACTIVATION_EMAIL}
                type="activation_email"
                action="add a user"
                includeNullOption={data.scheduled == SCHEDULE_TYPES.SCHEDULED ? false : true}
            />
        </FormModal>
    );
};

export default UserAdderModal;

UserAdderModal.defaultProps = {
    setDirty: () => {},
};

UserAdderModal.propTypes = {
    show: PropTypes.bool,
    hideModal: PropTypes.func.isRequired,
    metadata: PropTypes.shape({
        organisations: PropTypes.array,
        groups: PropTypes.array,
        subscription_groups: PropTypes.array,
    }),
    setDirty: PropTypes.func,
};
