import React from 'react';

import ModalDialog from 'react-bootstrap/ModalDialog';
import Draggable from 'react-draggable';

const DraggableModalDialog = (props) => {
    return (
        <Draggable handle=".modal-title">
            <ModalDialog {...props} />
        </Draggable>
    );
};

export default DraggableModalDialog;
