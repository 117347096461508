import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import PiconIcon from '../../FormControls/Icons/PiconIcon';
import { ViewAnnotationModal } from '../ViewAnnotations/ViewAnnotationModal';
import { TableContext, TABLE_ACTION } from '../../FormControls/Table/TableContext';
import { annotationPropTypes, confirmAnnoDelete } from '../utils';
import { EditAnnotationModal } from '../EditAnnotations/EditAnnotationModal';

const AnnotationActions = ({ item, inProject }) => {
    const [viewVisible, setViewVisible] = useState(false);
    const [editVisible, setEditVisible] = useState(false);

    const { dispatchTableState, model } = useContext(TableContext);

    const iconColour = app.darkMode ? 'lighter-blue' : 'blue';

    const setDirty = () => {
        app.trigger('annotation:changed');
        dispatchTableState({ type: TABLE_ACTION.SET_DIRTY });
    };

    const deleteItem = async () => {
        const confirmed = await confirmAnnoDelete(item);

        if (!confirmed) {
            return false;
        }
        try {
            dispatchTableState({ type: TABLE_ACTION.LOADING, loading: true });
            await item.model.destroy();
            setDirty();
            toaster.success('Annotation deleted');
        } catch (e) {
            dispatchTableState({ type: TABLE_ACTION.LOADING, loading: false });
            toaster.error('Could not delete the annotation');
        }
    };

    return (
        <>
            <button
                className="btn-link"
                title="View annotation here"
                onClick={() => setViewVisible(true)}
                aria-label="view annotation"
            >
                <PiconIcon iconName="eye" className={`${iconColour}`} />
            </button>
            {item.can_edit && (
                <>
                    <button
                        className="btn-link"
                        title="Edit annotation"
                        onClick={() => setEditVisible(true)}
                        aria-label="edit annotation"
                    >
                        <PiconIcon iconName="edit" className={`${iconColour}`} />
                    </button>
                    <button
                        className="btn-link"
                        title="Delete annotation"
                        onClick={() => deleteItem()}
                        aria-label="delete annotation"
                    >
                        <PiconIcon iconName="delete" className={`${iconColour}`} />
                    </button>
                </>
            )}

            {viewVisible && (
                <ViewAnnotationModal
                    {...{
                        hideModal: () => setViewVisible(false),
                        item,
                        showEdit: () => setEditVisible(true),
                        show: viewVisible,
                        setDirty,
                        includeGoTo: true,
                    }}
                />
            )}
            {editVisible && (
                <EditAnnotationModal
                    {...{
                        hideModal: () => setEditVisible(false),
                        item,
                        show: editVisible,
                        model,
                        setDirty,
                        inProject,
                    }}
                />
            )}
        </>
    );
};

export default AnnotationActions;

AnnotationActions.propTypes = {
    item: PropTypes.shape(annotationPropTypes),
    inProject: PropTypes.arrayOf(PropTypes.number),
};
