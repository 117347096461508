import PropTypes from 'prop-types';

export const todFilterPropTypes = {
    title: PropTypes.string,
    doc_filter: PropTypes.string,
    index_level: PropTypes.string,
    ordinal: PropTypes.number,
    pk: PropTypes.number,
};

export const editableContentPropType = {
    title: PropTypes.string,
    filters: PropTypes.arrayOf(PropTypes.shape(todFilterPropTypes)),
    content: PropTypes.string,
    image: PropTypes.node,
    path: PropTypes.string,
    display_filter_options: PropTypes.bool,
};

export const todDocument = {
    display: PropTypes.string,
    doc_filter: PropTypes.string,
    level1_value: PropTypes.string,
    level2_value: PropTypes.string,
    level3_value: PropTypes.string,
};
