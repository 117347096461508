import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';

import PiconIcon from '../../../FormControls/Icons/PiconIcon';
import confirm from '../../../FormControls/ConfirmModal/ConfirmModalService';
import { AnnotationLinkModal } from './AnnotationLinkModal';
import InvalidFeedback from '../../../FormControls/InvalidFeedback';

// Uncontrolled component. Do not copy the patterns. React currently doesn't seem to have a nice way to handle contentEditable.
// Consider rewriting in the future if any new approaches arise.
export const AnnotationsEditableBody = ({ text, textRef, bodyError }) => {
    const [showLinkModal, setShowLinkModal] = useState(false);

    const insertLink = async () => {
        textRef.current.focus();
        const sel = window.getSelection();
        if (!sel.rangeCount || sel.isCollapsed) {
            await confirm({
                title: 'Insert link',
                message: 'Please select some text to use as a link',
                showCancel: false,
            });
            return;
        }

        setShowLinkModal(true);
    };

    return (
        <Form.Group>
            <Form.Row>
                <Col className="align-end">
                    <Form.Label>Annotation body</Form.Label>
                </Col>
                <Col className="justify-end">
                    <button
                        type="button"
                        className="btn-link"
                        onClick={(e) => insertLink(e)}
                        onMouseDown={(e) => e.preventDefault()}
                    >
                        <PiconIcon iconName="link" className="left blue" />
                        Insert link
                    </button>
                </Col>
            </Form.Row>
            <Form.Row>
                <div className="annotation-body-wrapper">
                    {/* dangerouslySetInnerHTML - !!Make sure the text is ALWAYS sanitized in back-end to prevent XSS!! */}
                    <div
                        className="form-control"
                        id="annotationBody"
                        contentEditable="true"
                        name="text"
                        role="textbox"
                        aria-label="Annotation-body"
                        dangerouslySetInnerHTML={{ __html: text }}
                        ref={textRef}
                    ></div>
                </div>
                <InvalidFeedback
                    errorMessage="Please provide annotation text"
                    isInvalid={bodyError}
                />
            </Form.Row>
            <AnnotationLinkModal show={showLinkModal} hideModal={() => setShowLinkModal(false)} />
        </Form.Group>
    );
};

AnnotationsEditableBody.propTypes = {
    text: PropTypes.string,
    textRef: PropTypes.shape({
        current: PropTypes.instanceOf(Element),
    }),
    bodyError: PropTypes.bool,
};
