import React from 'react';

const IconAddAnnotation = (fill) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 -2 24 24">
        <g id="Add-Annotation" transform="translate(-4454 750)">
            <path id="Frame" d="M24,0H0V24H24Z" transform="translate(4454 -750)" fill="none" />
            <path
                id="Annotation_icon"
                data-name="Annotation icon"
                d="M0,15.649v0L.008,1.565A1.563,1.563,0,0,1,1.565,0H14.084a1.567,1.567,0,0,1,1.565,1.565V7.1a5.5,5.5,0,0,0-8.325,4.72,5.577,5.577,0,0,0,.043.695H3.13L0,15.647Z"
                transform="translate(4458.176 -745.824)"
                fill={`${fill ? fill : '#cccae6'}`}
            />
            <circle
                id="Background_add"
                data-name="Background add"
                cx="4.5"
                cy="4.5"
                r="4.5"
                transform="translate(4466.5 -738.5)"
                fill={`${fill ? fill : '#cccae6'}`}
            />
            <path
                id="Add"
                d="M.795-2.213V0H-.618V-2.213h-2.2v-1.4h2.2v-2.2H.795v2.2H3v1.4Z"
                transform="translate(4471 -731)"
                fill="#0b108b"
            />
        </g>
    </svg>
);

const IconAddFavourite = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g id="Add-Favourite" transform="translate(-4485 750)">
            <path id="Frame" d="M0,0H24V24H0Z" transform="translate(4485 -750)" fill="none" />
            <path
                id="Favourite_icon"
                data-name="Favourite icon"
                d="M3.82,19v0L5.46,11.97,0,7.24,7.19,6.63,10,0l2.81,6.63L20,7.24l-2.769,2.4a5.5,5.5,0,0,0-6.7,5.953L10,15.27,3.822,19Z"
                transform="translate(4487 -748)"
                fill="#cccae6"
            />
            <circle
                id="Background_add"
                data-name="Background add"
                cx="4.5"
                cy="4.5"
                r="4.5"
                transform="translate(4498.5 -737.5)"
                fill="#cccae6"
            />
            <path
                id="Add"
                d="M.795-2.213V0H-.618V-2.213h-2.2v-1.4h2.2v-2.2H.795v2.2H3v1.4Z"
                transform="translate(4503 -730)"
                fill="#0b108b"
            />
        </g>
    </svg>
);

const IconAnnotation = ({ number, colour }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="36"
        height="24"
        viewBox="0 6 36 24"
    >
        <defs>
            <filter id="Ellipse_17" x="2" y="0" width="34" height="34" filterUnits="userSpaceOnUse">
                <feOffset dy="3" input="SourceAlpha" />
                <feGaussianBlur stdDeviation="3" result="blur" />
                <feFlood floodOpacity="0.161" />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
            </filter>
        </defs>
        <g id="Annotation" data-name="Group 2623" transform="translate(-1308 -590)">
            <g id="mode_comment_black_24dp" transform="translate(1308 599)">
                <path id="Path_23" data-name="Path 23" d="M24,0H0V24H24Z" fill="none" />
                <path
                    id="Path_24"
                    data-name="Path 24"
                    d="M2.008,3.565A1.563,1.563,0,0,1,3.565,2H16.084a1.569,1.569,0,0,1,1.565,1.565v9.389a1.569,1.569,0,0,1-1.565,1.565H5.13L2,17.649Z"
                    transform="translate(2.176 2.176)"
                    fill={`${colour.anno}`}
                />
            </g>
            <g transform="matrix(1, 0, 0, 1, 1308, 590)" filter="url(#Ellipse_17)">
                <circle
                    id="Ellipse_17-2"
                    data-name="Ellipse 17"
                    cx="8"
                    cy="8"
                    r="8"
                    transform="translate(11 6)"
                    fill={`${colour.circle}`}
                />
            </g>
            <text
                id="_1"
                data-name={`${number}`}
                transform="translate(1327 609)"
                fill={`${colour.text}`}
                fontSize={number < 10 ? '14' : '10'}
                fontFamily="Helvetica-Bold, Helvetica"
                fontWeight="700"
            >
                <tspan x={number < 10 ? '-3.893' : '-5.893'} y={number < 10 ? '0' : '-1'}>
                    {number}
                </tspan>
            </text>
        </g>
    </svg>
);

const IconAnnotationHover = ({ number, colour }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="36"
        height="24"
        viewBox="0 6 36 24"
    >
        <defs>
            <filter id="Ellipse_35" x="2" y="0" width="34" height="34" filterUnits="userSpaceOnUse">
                <feOffset dy="3" input="SourceAlpha" />
                <feGaussianBlur stdDeviation="3" result="blur" />
                <feFlood floodOpacity="0.161" />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
            </filter>
        </defs>
        <g id="Add-Annotation" transform="translate(-4454 759)">
            <path id="Frame" d="M24,0H0V24H24Z" transform="translate(4454 -750)" fill="none" />
            <path
                id="Annotation_icon"
                data-name="Annotation icon"
                d="M0,15.649v0L.008,1.565A1.563,1.563,0,0,1,1.565,0H14.084a1.567,1.567,0,0,1,1.565,1.565V7.1a5.5,5.5,0,0,0-8.325,4.72,5.577,5.577,0,0,0,.043.695H3.13L0,15.647Z"
                transform="translate(4458.176 -745.824)"
                fill={`${colour.anno}`}
            />
            <circle
                id="Background_add"
                data-name="Background add"
                cx="4.5"
                cy="4.5"
                r="4.5"
                transform="translate(4466.5 -738.5)"
                fill="#cccae6"
            />
            <path
                id="Add"
                d="M.795-2.213V0H-.618V-2.213h-2.2v-1.4h2.2v-2.2H.795v2.2H3v1.4Z"
                transform="translate(4471 -731)"
                fill={`${number}`}
            />
            <g transform="matrix(1, 0, 0, 1, 4454, -759)" filter="url(#Ellipse_35)">
                <circle
                    id="Ellipse_35-2"
                    data-name="Ellipse 35"
                    cx="8"
                    cy="8"
                    r="8"
                    transform="translate(11 6)"
                    fill={`${colour.circle}`}
                />
            </g>
            <text
                id="_1"
                data-name={`${number}`}
                transform="translate(4473 -740)"
                fill={`${colour.text}`}
                fontSize={number < 10 ? '14' : '10'}
                fontFamily="Helvetica-Bold, Helvetica"
                fontWeight="700"
            >
                <tspan x={number < 10 ? '-3.893' : '-5.893'} y={number < 10 ? '0' : '-1'}>
                    {number}
                </tspan>
            </text>
        </g>
    </svg>
);

const IconDropdownChevron = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
        <g transform="translate(-19118 -7753)">
            <rect width="12" height="12" transform="translate(19118 7753)" fill="none" />
            <path
                d="M246,434.5l-6-5.512,1.075-.988L246,432.548l4.925-4.525,1.075.988Z"
                transform="translate(18878 7328.254)"
                fill="#505fab"
            />
        </g>
    </svg>
);

const IconFavourite = ({ colour, background }) => (
    <svg
        id="Added-Favourite"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
    >
        <path id="Frame" d="M0,0H24V24H0Z" fill="none" />
        <path
            id="Favourite"
            d="M12,17.27,18.18,21l-1.64-7.03L22,9.24l-7.19-.61L12,2,9.19,8.63,2,9.24l5.46,4.73L5.82,21Z"
            fill={colour}
            stroke={background}
            strokeWidth={2}
        />
    </svg>
);

const IconGrip = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <path data-name="Path 109" d="M0,0H18V18H0Z" fill="none" />
        <path
            data-name="Path 110"
            d="M10,14.5A1.5,1.5,0,1,1,8.5,13,1.5,1.5,0,0,1,10,14.5Zm-1.5-6A1.5,1.5,0,1,0,10,10,1.5,1.5,0,0,0,8.5,8.5ZM8.5,4A1.5,1.5,0,1,0,10,5.5,1.5,1.5,0,0,0,8.5,4ZM13,7a1.5,1.5,0,1,0-1.5-1.5A1.5,1.5,0,0,0,13,7Zm0,1.5A1.5,1.5,0,1,0,14.5,10,1.5,1.5,0,0,0,13,8.5ZM13,13a1.5,1.5,0,1,0,1.5,1.5A1.5,1.5,0,0,0,13,13Z"
            transform="translate(-1.75 -1)"
            fill="#8b8b8b"
        />
    </svg>
);

const IconGroup = (fill) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 -2 24 24">
        <path d="M24,0H0V24H24Z" fill="none" />
        <path
            d="M40,272v-4a1.75,1.75,0,0,1,1.75-1.75h3.525a1.666,1.666,0,0,1,.812.212,2.023,2.023,0,0,1,.663.587,4.96,4.96,0,0,0,1.85,1.625,5.245,5.245,0,0,0,6.675-1.625,2.07,2.07,0,0,1,.65-.587,1.6,1.6,0,0,1,.8-.212H60.25A1.75,1.75,0,0,1,62,268v4H55.5v-2.975a6.492,6.492,0,0,1-2.062,1.275,6.692,6.692,0,0,1-2.438.45,6.593,6.593,0,0,1-2.425-.45,6.839,6.839,0,0,1-2.075-1.275V272Zm11-4.25a3.683,3.683,0,0,1-1.688-.413A3.476,3.476,0,0,1,48,266.2a3.276,3.276,0,0,0-.963-.925,3.542,3.542,0,0,0-1.212-.475,4.772,4.772,0,0,1,2.275-1.15,11.644,11.644,0,0,1,2.9-.4,11.79,11.79,0,0,1,2.913.4A4.782,4.782,0,0,1,56.2,264.8a3.542,3.542,0,0,0-1.213.475,3.276,3.276,0,0,0-.963.925,3.476,3.476,0,0,1-1.312,1.138A3.733,3.733,0,0,1,51,267.75Zm-8-3.25a2.753,2.753,0,1,1,1.95-.812A2.645,2.645,0,0,1,43,264.5Zm16,0a2.753,2.753,0,1,1,1.95-.812A2.645,2.645,0,0,1,59,264.5Zm-8-3a2.753,2.753,0,1,1,1.95-.813A2.645,2.645,0,0,1,51,261.5Z"
            transform="translate(-39 -252)"
            fill={fill}
        />
    </svg>
);

const IconOswaldPointLeft = (size) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size.width}
        height={size.height}
        viewBox="0 0 4123.75 3567.023"
    >
        <g id="oswold-pointing-left-light" transform="translate(0.001)">
            <g id="Group_2071" data-name="Group 2071" transform="translate(2904.695 3254.159)">
                <path
                    id="Line_53"
                    data-name="Line 53"
                    d="M-1,167.029H86.274V0H-1Z"
                    transform="translate(203.585 0)"
                    fill="#4c5658"
                />
                <path
                    id="Line_54"
                    data-name="Line 54"
                    d="M74.907,149.1,135.645,42.8,59.87-.5-.868,105.8Z"
                    transform="translate(148.465 145.876)"
                    fill="#4c5658"
                />
                <path
                    id="Line_55"
                    data-name="Line 55"
                    d="M36.484,184.471,264.253,78.179,227.346-.906-.423,105.386Z"
                    transform="translate(0.423 128.393)"
                    fill="#4c5658"
                />
                <path
                    id="Line_56"
                    data-name="Line 56"
                    d="M166.494,179.077l46.855-73.629L46.318-.844-.537,72.785Z"
                    transform="translate(223.332 131.059)"
                    fill="#4c5658"
                />
            </g>
            <g id="Group_2072" data-name="Group 2072" transform="translate(2236.572 3112.359)">
                <path
                    id="Line_53-2"
                    data-name="Line 53"
                    d="M76.345,328.688,228.191,40.182,150.961-.466-.885,288.04Z"
                    transform="translate(208.492 0.466)"
                    fill="#4c5658"
                />
                <path
                    id="Line_54-2"
                    data-name="Line 54"
                    d="M74.907,149.1,135.645,42.8,59.87-.5-.868,105.8Z"
                    transform="translate(148.465 287.676)"
                    fill="#4c5658"
                />
                <path
                    id="Line_55-2"
                    data-name="Line 55"
                    d="M36.484,184.471,264.253,78.179,227.346-.906-.423,105.386Z"
                    transform="translate(0.423 270.193)"
                    fill="#4c5658"
                />
                <path
                    id="Line_56-2"
                    data-name="Line 56"
                    d="M166.494,179.077l46.855-73.629L46.318-.844-.537,72.785Z"
                    transform="translate(223.332 272.859)"
                    fill="#4c5658"
                />
            </g>
            <path
                id="Path_94"
                data-name="Path 94"
                d="M14218.313,11817.688s-1495,127.313-2084-605.625c-179.125,210.063-427.687,1049.313-427.687,1049.313s-1518.062-452.062-1612.062-554c75.375,350.688,84.875,473.313,534,675.313,104,46.625,179.563,66,357.875,135.188.125,0,.313,53.438,0,53.125-197.437-35.187-512-190.375-749.5-334.125-.875,153.125,46.25,527.313,1003.25,680.688v63.688s-555.875-94.5-828.375-289.562c31.063,387.5,536.813,587,536.813,587s354.75,160.75,758,106.625c626.125,1472.688,2360.938,1020.063,2360.938,1020.063s-244.812-288.312-315.937-870.312S14218.313,11817.688,14218.313,11817.688Z"
                transform="translate(-10094.563 -11212.063)"
                fill="#73c7c6"
            />
            <g id="Group_2070" data-name="Group 2070" transform="translate(1731.155 741.122)">
                <path
                    id="Union_3"
                    data-name="Union 3"
                    d="M0,432.746C0,671.751,193.743,865.5,432.749,865.5A431.523,431.523,0,0,0,746.565,730.743c23.67,220.881,210.657,392.893,437.839,392.893,243.183,0,440.349-197.143,440.349-440.336S1427.587,242.953,1184.4,242.953a439.091,439.091,0,0,0-321.725,139.68C837.821,167.23,654.807,0,432.749,0,193.743,0,0,193.753,0,432.746Z"
                    transform="translate(1624.753 1123.636) rotate(180)"
                    fill="#fff"
                />
                <path
                    id="Path_95"
                    data-name="Path 95"
                    d="M128.339,342.722c70.88,0,128.34-84.2,128.34-155.076S199.22,0,128.34,0,0,116.766,0,187.646,57.459,342.722,128.339,342.722Z"
                    transform="translate(758.783 1221.541) rotate(-166)"
                    fill="#fff"
                />
                <ellipse
                    id="Ellipse_9"
                    data-name="Ellipse 9"
                    cx="113.885"
                    cy="113.884"
                    rx="113.885"
                    ry="113.884"
                    transform="translate(1002.185 409.982)"
                    fill="#4b5459"
                />
                <path
                    id="Ellipse_9_-_Outline"
                    data-name="Ellipse 9 - Outline"
                    d="M113.885,1A113.725,113.725,0,0,0,91.133,3.293,112.261,112.261,0,0,0,50.77,20.278a113.216,113.216,0,0,0-40.9,49.667A112.305,112.305,0,0,0,3.293,91.133a113.993,113.993,0,0,0,0,45.5A112.26,112.26,0,0,0,20.278,177a113.217,113.217,0,0,0,49.667,40.9,112.311,112.311,0,0,0,21.188,6.577,114,114,0,0,0,45.5,0A112.261,112.261,0,0,0,177,207.49a113.216,113.216,0,0,0,40.9-49.667,112.305,112.305,0,0,0,6.577-21.188,113.993,113.993,0,0,0,0-45.5A112.26,112.26,0,0,0,207.491,50.77a113.217,113.217,0,0,0-49.667-40.9,112.311,112.311,0,0,0-21.188-6.577A113.725,113.725,0,0,0,113.885,1m0-1A113.884,113.884,0,1,1,0,113.884,113.884,113.884,0,0,1,113.885,0Z"
                    transform="translate(1002.185 409.982)"
                    fill="#707070"
                />
                <ellipse
                    id="Ellipse_10"
                    data-name="Ellipse 10"
                    cx="113.885"
                    cy="113.884"
                    rx="113.885"
                    ry="113.884"
                    transform="translate(197.401 136.661)"
                    fill="#4b5459"
                />
                <path
                    id="Ellipse_10_-_Outline"
                    data-name="Ellipse 10 - Outline"
                    d="M113.885,1A113.725,113.725,0,0,0,91.133,3.293,112.261,112.261,0,0,0,50.77,20.278a113.216,113.216,0,0,0-40.9,49.667A112.305,112.305,0,0,0,3.293,91.133a113.993,113.993,0,0,0,0,45.5A112.26,112.26,0,0,0,20.278,177a113.217,113.217,0,0,0,49.667,40.9,112.311,112.311,0,0,0,21.188,6.577,114,114,0,0,0,45.5,0A112.261,112.261,0,0,0,177,207.49a113.216,113.216,0,0,0,40.9-49.667,112.305,112.305,0,0,0,6.577-21.188,113.993,113.993,0,0,0,0-45.5A112.26,112.26,0,0,0,207.491,50.77a113.217,113.217,0,0,0-49.667-40.9,112.311,112.311,0,0,0-21.188-6.577A113.725,113.725,0,0,0,113.885,1m0-1A113.884,113.884,0,1,1,0,113.884,113.884,113.884,0,0,1,113.885,0Z"
                    transform="translate(197.401 136.661)"
                    fill="#707070"
                />
            </g>
        </g>
    </svg>
);

const IconPrivate = (fill) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 -2 24 24">
        <path d="M24,0H0V24H24Z" fill="none" />
        <path
            d="M165.668,280.314a2.548,2.548,0,1,1,1.913-.744A2.59,2.59,0,0,1,165.668,280.314ZM160,286v-1.665a1.949,1.949,0,0,1,.337-1.151,2.161,2.161,0,0,1,.868-.726,13.318,13.318,0,0,1,2.276-.8,9.2,9.2,0,0,1,2.188-.266,8.813,8.813,0,0,1,2.179.275,15.092,15.092,0,0,1,2.267.788,2.136,2.136,0,0,1,.886.726,1.949,1.949,0,0,1,.337,1.151V286Z"
            transform="translate(-153.668 -268)"
            fill={fill}
        />
    </svg>
);

const IconProject = (fill) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 -2 24 24">
        <path d="M24,0H0V24H24Z" fill="none" />
        <path
            d="M67.4,164.367a1.467,1.467,0,0,1-1.078-.454,1.488,1.488,0,0,1-.451-1.086V154.9a1.54,1.54,0,0,1,1.529-1.529h3.125V151.91a1.487,1.487,0,0,1,.451-1.086,1.466,1.466,0,0,1,1.078-.454h3.621a1.466,1.466,0,0,1,1.078.454,1.487,1.487,0,0,1,.451,1.086v1.459h3.126a1.487,1.487,0,0,1,1.086.451,1.466,1.466,0,0,1,.454,1.078v7.929a1.55,1.55,0,0,1-1.54,1.54Zm4.655-11h3.621V151.91H72.053Z"
            transform="translate(-61.869 -145.369)"
            fill={fill}
        />
    </svg>
);

const icons = {
    addAnnotation: IconAddAnnotation,
    addFavourite: IconAddFavourite,
    annotation: IconAnnotation,
    annotationHover: IconAnnotationHover,
    dropdown: IconDropdownChevron,
    favourite: IconFavourite,
    grip: IconGrip,
    group: IconGroup,
    oswaldLeft: IconOswaldPointLeft,
    private: IconPrivate,
    project: IconProject,
};

export default icons;
