module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="windowbox-body">\n    ';
 if(!_.isUndefined(title)) { 
__p+='\n    <h2 class="popover-title windowbox-title" id="'+
((__t=( id ))==null?'':_.escape(__t))+
'-label">\n        <button aria-hidden="true" class="close" type="button">×</button>\n        <span class="label">'+
((__t=( title ))==null?'':_.escape(__t))+
'</span>\n    </h2>\n    ';
 } 
__p+='\n    <div class="popover-content"></div>\n</div>';
}
return __p;
};
