import React from 'react';
import PropTypes from 'prop-types';

const RODItem = ({ doc: { title, instances }, handleClick }) => {
    const abbrev = instances[0].abbrevs;

    return (
        <li>
            <div className="recent-doc">
                <button
                    className="anchor-btn"
                    data-dd-action-name="Select Recently Opened Document Item"
                    onClick={handleClick}
                    tabIndex={0}
                >
                    {title}
                </button>
                {abbrev.length > 0 && <p className="recent-doc-abbrev">[{abbrev}]</p>}
            </div>
        </li>
    );
};

export default RODItem;

RODItem.propTypes = {
    doc: PropTypes.shape({
        title: PropTypes.string.isRequired,
        instances: PropTypes.arrayOf(PropTypes.object),
    }),
    handleClick: PropTypes.func.isRequired,
};
