import React, { useEffect, useState, useContext } from 'react';

import FormModal from '../FormControls/FormModal';
import GroupMerger from '../Forms/GroupMerger';
import confirm from '../FormControls/ConfirmModal/ConfirmModalService';
import SimpleTableControl from '../FormControls/Table/SimpleTableControl';
import SelectedLister from '../FormControls/SelectedLister';
import ConfirmationEmailSelector from '../FormControls/ConfirmationEmailSelector';
import PropTypes from 'prop-types';

import models from '../../models';

import { formatGroupMetadata } from './utils';
import { getSelectedFilterValues, asyncAction } from '../utils';
import { TableContext, TABLE_ACTION } from '../FormControls/Table/TableContext';

const MergeUserGroupsModal = ({ show, hideModal, usersModel }) => {
    const { tableState, dispatchTableState } = useContext(TableContext);

    const [metadata, setMetadata] = useState({ organisations: [], user_groups: [] });
    const [selected, setSelected] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const [loading, setLoading] = useState(false);

    const metadataModel = new models.Metadata();
    const fetchMetadata = async () => {
        try {
            const results = await metadataModel.fetch();
            setMetadata({
                organisations: results.organisations,
                user_groups: formatGroupMetadata(results.subscription_groups),
            });
        } catch (err) {
            console.error(err);
            hideModal();
            toaster.error('Data could not be retrieved');
        }
    };

    const fetchUserData = async () => {
        try {
            const params = getSelectedFilterValues(tableState);
            const result = await usersModel.getSelectedUsers(params);
            setSelected(result);

            const groupListLong = result.reduce(
                (arr, user) => [
                    ...arr,
                    ...user.user_groups
                        .map((group) => group.name)
                        .reduce((outer, inner) => outer.concat(inner), []),
                ],
                [],
            );
            setGroupList(Array.from(new Set(groupListLong)));
        } catch (err) {
            console.error(err);
            hideModal();
            toaster.error('User data could not be retrieved');
        }
    };

    useEffect(() => {
        setLoading(true);
        if (show) {
            fetchMetadata();
            fetchUserData();
        }
        // State persists even if modal is closed, so if someone opens modal with x users, closes
        // modal, reopens with x different users, the groupList is the persisting list...
        if (!show) {
            setGroupList([]);
        }
        setLoading(false);
    }, [show]);

    const getTableColumns = (item) => {
        return [item.oldGroup, item.newGroup];
    };

    const getGroupChangeConfirmation = (getData, updatedSelected) => {
        const headers = ['Existing user group(s)', 'New user group(s)'];
        return (
            <>
                <SelectedLister selected={updatedSelected} type={'users'} property={'email'} />
                <p>
                    The listed users above will be merged into the corresponding user groups below
                </p>
                <div className="table-container">
                    <SimpleTableControl
                        doFetch={getData}
                        className="confirm-table"
                        {...{ headers, getTableColumns, dataName: 'user groups' }}
                    />
                </div>
            </>
        );
    };

    const confirmForm = async (changedData, updatedSelected) => {
        const getData = () => {
            return changedData;
        };

        const confirmedResult = await confirm({
            title: 'Confirm user group merge',
            message: getGroupChangeConfirmation(getData, updatedSelected),
        });

        return confirmedResult;
    };

    const submitForm = async (form) => {
        const chosenOrg = form.elements.organisation.value;
        const updatedSelected = selected.map((user) => {
            const newUserGroups = [];
            groupList.reverse().map((group) => {
                if (user.user_groups.some((userG) => userG.name === group)) {
                    newUserGroups.push(
                        metadata.user_groups[chosenOrg].find(
                            (subGroup) => subGroup.name === form.elements[group].value,
                        ),
                    );
                }
            });
            return {
                ...user,
                user_groups: newUserGroups,
                organisation: metadata.organisations.find((org) => org.name === chosenOrg),
            };
        });
        const changedData = groupList.map((group) => {
            return {
                oldGroup: group,
                newGroup: form.elements[group].value,
            };
        });
        const template = form.elements.emailTemplate.value;

        const confirmed = await confirmForm(changedData, updatedSelected);
        if (confirmed === false) return false;

        const fun = (params) => usersModel.updateSelected(params);

        const setDirty = () => dispatchTableState({ type: TABLE_ACTION.SET_DIRTY });

        await asyncAction({
            fun,
            setDirty,
            args: {
                users: updatedSelected,
                update_count: changedData.length,
                total_count: updatedSelected.length,
                email_template: template,
                context: changedData,
                edited_data: { data: 'user_groups', msg: 'merged subscription details' },
            },
            actionName: 'merge user groups',
        });
        hideModal();
    };

    return (
        <FormModal title="Merge user groups" {...{ show, submitForm, loading, hideModal }}>
            <GroupMerger {...{ metadata, groupList }} />
            <ConfirmationEmailSelector
                templateLabel={app.emailTemplateLabels.COMBINE_USER_GROUPS}
                action="merge user groups"
            />
        </FormModal>
    );
};

export default MergeUserGroupsModal;

MergeUserGroupsModal.propTypes = {
    show: PropTypes.bool.isRequired,
    hideModal: PropTypes.func.isRequired,
    usersModel: PropTypes.shape({
        getSelectedUsers: PropTypes.func,
        updateSelected: PropTypes.func,
    }),
};
