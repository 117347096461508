import React from 'react';
import PropTypes from 'prop-types';

import { ButtonGroup, ToggleButton } from 'react-bootstrap';

const DetailBlock = ({ title, rows, toggles, className, extraText }) => {
    return (
        <div className="detail-block">
            <div className="block-header">
                <div className="block-title">
                    {title}
                    {extraText && <p className="extra-text">{extraText}</p>}
                </div>
                <ButtonGroup className="block-toggles" toggle>
                    {toggles &&
                        toggles.map((toggle, index) => (
                            <ToggleButton
                                key={index}
                                type="radio"
                                value={toggle.value}
                                checked={toggle.active}
                                onChange={(e) => toggle.handleChange(e.currentTarget.value)}
                            >
                                {toggle.name}
                            </ToggleButton>
                        ))}
                </ButtonGroup>
            </div>
            <div className={`block-body ${className}`}>
                {Object.values(rows).map((row, index) => (
                    <div key={index} className="block-row">
                        <div className="row-label">{row.label}</div>
                        <div className="row-value">{row.value}</div>
                        {row.can_edit && (
                            <button className="row-edit" value={row.value} onClick={row.can_edit}>
                                Edit
                            </button>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DetailBlock;

DetailBlock.propTypes = {
    title: PropTypes.string.isRequired,
    rows: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    toggles: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.bool,
            active: PropTypes.bool,
        }),
    ),
    className: PropTypes.string,
    extraText: PropTypes.string,
};
