import React, { useContext, useEffect, useState } from 'react';
import FormModal from '../FormControls/FormModal';
import PropTypes from 'prop-types';

import ConfirmationEmailSelector from '../FormControls/ConfirmationEmailSelector';
import { getSelectedCount } from '../FormControls/Table/utils';
import { TableContext } from '../FormControls/Table/TableContext';
import { getConfirmationMessageUsers, tableAsyncAction } from '../utils';
import confirm from '../FormControls/ConfirmModal/ConfirmModalService';

export const NotifyUserModal = ({
    hideModal,
    usersModel,
    confirmationText,
    title,
    action,
    canSkipSendingEmail,
    includeEmailOptions,
}) => {
    const [loading, setLoading] = useState(false);
    const { tableState, dispatchTableState } = useContext(TableContext);

    const selectedCount = getSelectedCount(tableState);

    useEffect(() => {
        if (!includeEmailOptions) {
            const skipFirstModal = async () => {
                const response = await sendData({ confirmed: false });
                if (response.confirm_required) {
                    const confirmResult = await confirm({
                        message: response?.message
                            ? response.message
                            : getConfirmationMessageUsers(action, selectedCount),
                    });
                    if (!confirmResult) {
                        // have to explicitly close modal or it jams up the next action
                        hideModal();
                        return;
                    }

                    await sendData({ confirmed: true });
                    // have to explicitly close modal or it jams up the next action
                    hideModal();
                }
            };

            skipFirstModal();
        }
    }, []);

    const sendData = async (data) => {
        const fun = (params) => usersModel.action(params);

        return await tableAsyncAction({
            fun,
            tableState,
            dispatchTableState,
            args: {
                action,
                ...(includeEmailOptions
                    ? { email_template: data.elements.emailTemplate.value }
                    : data),
            },
            actionName: action,
            setLoading,
        });
    };

    const handleSubmit = async (data) => {
        if (selectedCount > 1 && includeEmailOptions) {
            const confirmedResult = await confirm({
                message: getConfirmationMessageUsers(confirmationText, selectedCount),
            });

            if (!confirmedResult) return;
        }

        await sendData(data);
    };

    if (includeEmailOptions) {
        return (
            <FormModal
                title={title}
                submitForm={handleSubmit}
                hideModal={hideModal}
                show={true}
                loading={loading}
                size="sm"
            >
                <ConfirmationEmailSelector
                    templateLabel={app.emailTemplateLabels.ACTIVATION_EMAIL}
                    type="activation_email"
                    action={action}
                    includeNullOption={canSkipSendingEmail}
                />
            </FormModal>
        );
    } else {
        return <></>;
    }
};

NotifyUserModal.defaultProps = {
    includeEmailOptions: true,
    canSkipSendingEmail: true,
};

NotifyUserModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    usersModel: PropTypes.shape({
        action: PropTypes.func.isRequired,
    }),
    confirmationText: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    canSkipSendingEmail: PropTypes.bool.isRequired,
    includeEmailOptions: PropTypes.bool,
};
