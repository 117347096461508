import React from 'react';
import requiredIf from 'react-required-if';

import models from '../../models';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';
import { useFetch } from '../customHooks';
import { WithLoading } from './WithLoading';

const ConfirmationEmailSelector = ({ action, templateLabel, includeNullOption, required }) => {
    const emailTemplateCollection = new models.EmailTemplates();
    const {
        data: emailTemplates,
        loading,
        error,
    } = useFetch({
        doFetch: async () =>
            await emailTemplateCollection.fetchByLabel(templateLabel, includeNullOption),
        dependencies: [includeNullOption],
    });

    // Try to select a template with some value (if available)
    const defaultValue = emailTemplates?.find((template) => template.slug)?.slug ?? '';

    return (
        <Form.Group>
            <Form.Label>Confirmation email template</Form.Label>
            <WithLoading loading={loading}>
                <Form.Control
                    as="select"
                    name="emailTemplate"
                    defaultValue={defaultValue}
                    required={required}
                    aria-label="email template"
                >
                    {!error &&
                        emailTemplates?.map((template) => (
                            <option key={template.slug} value={template.slug}>
                                {template.name}
                            </option>
                        ))}
                </Form.Control>
            </WithLoading>
            {error && (
                <div className="message-error">
                    <p className="text-error">Email Template data could not be retrieved</p>
                    {!required && (
                        <i>You can still {action}, but no confirmation email can be sent</i>
                    )}
                </div>
            )}
        </Form.Group>
    );
};

ConfirmationEmailSelector.defaultProps = {
    includeNullOption: true,
    required: false,
};

ConfirmationEmailSelector.propTypes = {
    action: requiredIf(PropTypes.string, (props) => !props.required),
    templateLabel: PropTypes.oneOf(Object.values(app.emailTemplateLabels)).isRequired,
    includeNullOption: PropTypes.bool.isRequired,
    required: PropTypes.bool,
};

export default ConfirmationEmailSelector;
