import React from 'react';

import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';

const DomainChanger = ({ domainList, validation, handleChange }) => {
    return (
        <>
            <Form.Group>
                <Form.Row>
                    <Col>
                        <Form.Label>Existing domains</Form.Label>
                        {Object.keys(domainList).map((item, index) => {
                            return (
                                <div className="item-list" key={index}>
                                    <Form.Control readOnly defaultValue={item} />
                                    <p>x {domainList[item]}</p>
                                </div>
                            );
                        })}
                    </Col>
                    <Col>
                        <Form.Label>Change to</Form.Label>
                        {Object.keys(domainList).map((item, index) => {
                            const validity =
                                validation.length > 0 && validation.find((v) => v.field === item);
                            return (
                                <div key={index}>
                                    <Form.Control
                                        className="option-list"
                                        type="text"
                                        name={item}
                                        placeholder="No change"
                                        maxLength={100}
                                        onChange={handleChange}
                                    />
                                    {/* React Bootstrap's validation only covers if required input isn't provided.
                                        This is a bit of a work-around for that as it's the only field that needs
                                        its input value validated before submission. If more fields require this
                                        functionality in the future, Formik should be brought in */}
                                    {validity?.VALID === false && validity.field === item && (
                                        <Form.Control.Feedback>
                                            <p className="text-error">
                                                Domains must contain a dot (e.g. example.com)
                                            </p>
                                        </Form.Control.Feedback>
                                    )}
                                </div>
                            );
                        })}
                    </Col>
                </Form.Row>
            </Form.Group>
            <Form.Group>
                <Form.Row>
                    <Form.Label>
                        Alternatively, change <i>all</i> selected email domains to
                    </Form.Label>
                    <Form.Control
                        type="text"
                        name="changeAll"
                        placeholder="No change"
                        maxLength={100}
                        onChange={handleChange}
                    />
                    {/* React Bootstrap's validation only covers if required input isn't provided.
                        This is a bit of a work-around for that as it's the only field that needs
                        ts input value validated before submission. If more fields require this
                        functionality in the future, Formik should be brought in */}
                    {validation.length > 0 &&
                        validation.find((v) => v.field === 'changeAll')?.VALID === false && (
                            <Form.Control.Feedback>
                                <p className="text-error">
                                    Domains must contain a dot (e.g example.com)
                                </p>
                            </Form.Control.Feedback>
                        )}
                </Form.Row>
            </Form.Group>
        </>
    );
};

export default DomainChanger;

DomainChanger.propTypes = {
    domainList: PropTypes.object.isRequired,
    validation: PropTypes.arrayOf(
        PropTypes.shape({
            VALID: PropTypes.bool.isRequired,
            field: PropTypes.string.isRequired,
        }),
    ),
    handleChange: PropTypes.func.isRequired,
};
