module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<hr class="hidden"/>\n';
 if(ttViews) { 
__p+='\n<div class="btn-group view-buttons" data-toggle="buttons" role="radiogroup">\n    ';
 _.each(ttViews, function(ttView) { 
__p+='\n        <label class="btn btn-primary btn-sm disabled" for="'+
((__t=( ttView ))==null?'':_.escape(__t))+
'" title="'+
((__t=( ttViewTitles[ttView] ))==null?'':_.escape(__t))+
'">\n            <button>\n                <input type="radio" role="radio" name="view-options" id="'+
((__t=( ttView ))==null?'':_.escape(__t))+
'"  > \n                '+
((__t=( ttViewTitles[ttView] ))==null?'':_.escape(__t))+
'\n                <div class="popover-content hidden">\n                    <p class="tt-current">\n                        '+
((__t=( ttViewDescriptions[ttView] ))==null?'':__t)+
'\n                    </p>\n                    <div class="popover-examples">\n                        <h4 class="example-title">Example:</h4>\n                        <img src="'+
((__t=( STATIC_URL + 'img/' + ttView + '.svg' ))==null?'':_.escape(__t))+
'">\n                    </div>\n                </div>\n            </button>\n        </label>\n    ';
 }) 
__p+='\n</div>\n<button class="btn toolbar-item btn-colourkey" style="display:none;" href="#" title="Explanation of the colours">\n    <span class="icon"><span class="picon picon-colour"></span></span>\n    <span class="label">Colour Key</span>\n</button>\n<span class="msg-colourkey" style="display:none;">Move your mouse over the document for an explanation of the colours in use.</span>\n';
 } 
__p+='\n<div class="search-key"></div>\n';
}
return __p;
};
