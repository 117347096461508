import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { annotationPropTypes } from '../Annotations/utils';
import { getDateTime, getMediumDateTime, truncateString } from '../utils';
import { PRIVACY_DISPLAY } from '../Annotations/Privacy';

const initCharLimit = 350;
const initWidth = 300; // this isn't the acutal width, but rather the triggering width

const AnnotationCard = ({ anno, showModal, fullText, paneWidth }) => {
    const [charLimit, setCharLimit] = useState(initCharLimit);
    const [previousWidth, setPreviousWidth] = useState(initWidth);

    useEffect(() => {
        if (!fullText) {
            // add or remove text from the card based off resizing of panel
            if (paneWidth && paneWidth > previousWidth) {
                setCharLimit(charLimit + 50);
                setPreviousWidth(previousWidth + 25);
            } else if (paneWidth && paneWidth < previousWidth - 25) {
                setCharLimit(charLimit - 50);
                setPreviousWidth(previousWidth - 25);
            }
        }
    }, [paneWidth]);
    const formatTimetamp = (timestamp) => {
        const parts = timestamp.split(' ');
        const timeStr = parts.shift();
        const dateStr = parts.join(' ');
        return (
            <>
                {timeStr} <span>{dateStr}</span>
            </>
        );
    };
    return (
        <button
            className={`annotation-card ${_.lowerCase(PRIVACY_DISPLAY[anno.privacy])}`}
            onClick={() => showModal()}
        >
            <div className="annotation-card-header">
                <div
                    className={`annotation-header-details ${
                        anno.owner_removed ? 'removed-owner' : ''
                    } `}
                >
                    <div
                        className="annotation-owner"
                        title={
                            anno.reassigned_from
                                ? `This annotation was originally created by ${anno.reassigned_from}`
                                : ''
                        }
                    >
                        {anno.owner_name}
                        {anno.reassigned_from ? '*' : ''}
                        {anno.owner_removed ? ' [removed]' : ''}
                    </div>
                    {fullText && <div className="annotation-organisation">{anno.org_name}</div>}
                    <div className="annotation-privacy" title={anno.project_title}>
                        {anno.project
                            ? _.truncate(anno.project_title)
                            : PRIVACY_DISPLAY[anno.privacy]}
                    </div>
                </div>
                <div className="annotation-last_modified" title={getDateTime(anno.last_modified)}>
                    <span>{`${anno.edited ? 'Last edit:' : ''}`}</span>
                    {formatTimetamp(getMediumDateTime(anno.last_modified))}
                </div>
            </div>
            <div className="annotation-card-body">
                <div className="annotation-title">{anno.title}</div>
                <p
                    className="annotation-extract"
                    dangerouslySetInnerHTML={{
                        __html: fullText ? anno.text : truncateString(anno.text, charLimit),
                    }}
                ></p>
            </div>
        </button>
    );
};

export default AnnotationCard;

AnnotationCard.propTypes = {
    anno: PropTypes.shape(annotationPropTypes).isRequired,
    showModal: PropTypes.func.isRequired,
    fullText: PropTypes.bool,
    paneWidth: PropTypes.number,
};
