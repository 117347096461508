import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import DetailBlock from './DetailBlock';
import models from '../../models';
import { Lozenge } from './AccountSettings';

const ProjectInfoBlock = ({ organisationId }) => {
    const [projectDetails, setProjectDetails] = useState();

    const currentUser = `${app.user.attributes.first_name} ${app.user.attributes.last_name}`;

    useEffect(() => {
        (async () => {
            try {
                if (organisationId) {
                    const response = await new models.Projects().getProjectsByOrganisation({
                        id: organisationId,
                    });
                    setProjectDetails(response);
                } else {
                    const projectList = app.user.attributes.projects.reduce(
                        (arr, project) => [...arr, project.id],
                        [],
                    );
                    if (projectList.length) {
                        const response = await new models.Projects().getProjectsByUser({
                            'project_ids[]': projectList,
                        });
                        setProjectDetails(response);
                    }
                }
            } catch (err) {
                toaster.error('There was a problem retrieving the projects');
            }
        })();
    }, []);

    const formatProjects = (project) => {
        return {
            project: {
                label: 'Users',
                value: (
                    <div className="project-organisation-details">
                        {Object.entries(project).map(([org, users], index) => {
                            return (
                                <div key={index} className="organisation-group">
                                    <div className="organisation-name">{org}</div>
                                    <ul>
                                        {users.map((user, idx) => (
                                            <li key={idx}>
                                                {user}
                                                {user === currentUser && <Lozenge text="you" />}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            );
                        })}
                    </div>
                ),
            },
        };
    };

    return app.user.has_perm('main.view_organisation') || projectDetails ? (
        <>
            <h2 id="your-projects">Your projects</h2>
            {projectDetails && Object.keys(projectDetails).length ? (
                Object.entries(projectDetails).map(([project, details], index) => (
                    <DetailBlock
                        key={index}
                        title={project}
                        rows={formatProjects(details)}
                        extraText={`${Object.keys(details).length} organisations`}
                    />
                ))
            ) : (
                <div>Your organisation currently has no active projects.</div>
            )}
            {app.user.has_perm('main.view_organisation') && !organisationId && (
                <a
                    href={`${app.urls.organisation}/${app.user.get('organisation')}`}
                >{`View your organisation's other projects`}</a>
            )}
        </>
    ) : (
        <></>
    );
};

export default ProjectInfoBlock;

ProjectInfoBlock.propTypes = {
    organisationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
