import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import { LoaderOverlay } from '../FormControls/WithLoading';
import Filter, { FILTER_TYPES } from '../FormControls/Table/Filter';
import { getSelectedCount } from '../FormControls/Table/utils';
import { tableAsyncAction, getSelectedFilterValues } from '../utils';
import confirm from '../FormControls/ConfirmModal/ConfirmModalService';
import { TableContext, TABLE_ACTION } from '../FormControls/Table/TableContext';
import { useFetch } from '../customHooks';

//#region enums

export const STATUS_SELECTED = {
    NONE: 0,
    DRAFT: 1,
    READY: 2,
    ERROR: 3,
};
Object.freeze(STATUS_SELECTED);

//#endregion

//#region helpers

const getStatusSelected = async (tableState, documentInstancesModel) => {
    // A check to see if all documents that are selected belong to a same status

    // Nothing selected
    if (getSelectedCount(tableState) == 0) return STATUS_SELECTED.NONE;

    const statusFilter = tableState.filter['statuses[]'];

    // There's a status filter on one (and just one) of the statuses. Thus one status is selected
    if (statusFilter?.length == 1) {
        if (statusFilter[0] == 'draft') return STATUS_SELECTED.DRAFT;
        if (statusFilter[0] == 'ready') return STATUS_SELECTED.READY;
        if (statusFilter[0] == 'error') return STATUS_SELECTED.ERROR;
        return STATUS_SELECTED.NONE;
    }

    const params = getSelectedFilterValues(tableState);
    const result = await documentInstancesModel.checkStatusSelected(params);

    // More than one status selected
    if (result.results.length !== 1) return STATUS_SELECTED.NONE;

    // There is just one status selected. Set which one.
    switch (result.results[0]) {
        case 'draft':
            return STATUS_SELECTED.DRAFT;
        case 'ready':
            return STATUS_SELECTED.READY;
        case 'error':
            return STATUS_SELECTED.ERROR;
        default:
            return STATUS_SELECTED.NONE;
    }
};

const getActionItems = (statusSelected) => {
    const actionItems = [
        {
            key: 'make_ready',
            text: 'Change status to Ready',
            confirmationText: 'change status to "Ready" for',
            shouldConfirm: true,
            disabled: !(statusSelected == STATUS_SELECTED.DRAFT),
        },
        {
            key: 'publish',
            text: ' Change status to Published',
            confirmationText: 'change status to "Published" for',
            shouldConfirm: true,
            disabled: !(statusSelected == STATUS_SELECTED.READY),
        },
        {
            key: 'index',
            text: 'Re-index',
            confirmationText: 're-index',
            shouldConfirm: true,
            disabled: false,
        },
        {
            key: 'delete_failed',
            text: 'Delete failed',
            confirmationText: 'delete failed',
            shouldConfirm: true,
            disabled: !(statusSelected == STATUS_SELECTED.ERROR),
        },
    ];

    const allowedActions = actionItems.filter((item) =>
        app.user.has_perm(`content.${item.key}_document`),
    );

    return allowedActions;
};

const getCountLabel = (count, selectedCount) => {
    return `${selectedCount}/${count} document${count == 1 ? '' : 's'} selected`;
};

//#endregion

//#region Fields

const getStatuses = (showPublish) => [
    {
        value: 'draft',
        label: 'Draft',
    },
    {
        value: 'ready',
        label: 'Ready',
    },
    ...(showPublish
        ? [
              {
                  value: 'published',
                  label: 'Published',
              },
          ]
        : []),
    {
        value: 'published_with_error',
        label: 'Published with error',
    },
    {
        value: 'error',
        label: 'Error',
    },
    {
        value: 'no_error',
        label: 'No Error',
    },
];

const index = [
    {
        value: 'ERR',
        label: 'Error',
    },
    {
        value: 'IND',
        label: 'Indexed',
    },
    {
        value: 'UNA',
        label: 'Unavailable',
    },
    {
        value: 'PEN',
        label: 'Index Pending',
    },
    {
        value: 'NOT',
        label: 'Not Indexed',
    },
];

const getFilterFields = (showPublish) => [
    {
        type: FILTER_TYPES.MULTISELECT,
        options: getStatuses(showPublish),
        placeholder: 'Status...',
        name: 'statuses[]',
    },
    {
        type: FILTER_TYPES.MULTISELECT,
        options: index,
        placeholder: 'Filter Index...',
        name: 'index[]',
    },
];

//#endregion

const DocumentFilter = ({ documentSummariesModel, showPublish }) => {
    const { tableState, dispatchTableState, options } = useContext(TableContext);
    const [loadingOverlay, setLoadingOverlay] = useState(false);

    const stateSelected = useFetch({
        doFetch: async () => await getStatusSelected(tableState, documentSummariesModel),
        dependencies: [
            tableState.selectedList.length,
            tableState.selectedAll,
            tableState.itemsCount,
        ],
        dirtyData: tableState.dirtyData,
    });

    const actionItems = options?.selectable ? getActionItems(stateSelected.data) : [];
    const selectedCount = getSelectedCount(tableState);

    const doAction = async (action) => {
        const actionItem = actionItems.find((i) => i.key === action);
        //If more than one selected, show standard confirmation message for some of the actions
        if (selectedCount > 1 && actionItem?.shouldConfirm) {
            const confirmedResult = await confirm({
                message: `You are about to ${actionItem.confirmationText} ${selectedCount} documents. Are you sure you want to continue?`,
            });

            if (!confirmedResult) return;
        }

        const fun = (params) => documentSummariesModel.action(params);

        const result = await tableAsyncAction({
            fun,
            tableState,
            dispatchTableState,
            args: { action },
            actionName: action,
            setLoading: setLoadingOverlay,
        });

        if (!result.error && action === 'publish') {
            dispatchTableState({ type: TABLE_ACTION.UNSELECT_ALL });
        }
    };

    const actions = {
        actionItems,
        doAction,
        loading: stateSelected.loading,
    };

    const labels = {
        countLabel: getCountLabel(tableState.itemsCount, selectedCount),
    };

    const filterFields = getFilterFields(showPublish);

    return (
        <>
            <Filter
                {...{
                    actions,
                    filterFields,
                    doAction,
                    labels,
                }}
            />
            <LoaderOverlay loading={loadingOverlay} className="full-screen" />
        </>
    );
};

export default DocumentFilter;

DocumentFilter.defaultProps = {
    showPublish: false,
};

DocumentFilter.propTypes = {
    documentSummariesModel: PropTypes.shape({
        action: PropTypes.func.isRequired,
        checkStatusSelected: PropTypes.func.isRequired,
    }).isRequired,
    showPublish: PropTypes.bool.isRequired,
};
