import React from 'react';
import PropTypes from 'prop-types';

const PiconIcon = ({ className, iconName }) => {
    return <span className={`picon picon-${iconName} ${className}`} />;
};

export default PiconIcon;

PiconIcon.defaultProps = {
    className: '',
};

PiconIcon.propTypes = {
    iconName: PropTypes.string.isRequired,
    className: PropTypes.string,
};
