import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

import models from '../../models.js';
import {
    getDateTime,
    getDate,
    attributeToStr,
    strToCapital,
    getHeadersFormatted,
    formatToReadableText,
} from '../utils';
import TableControl from '../FormControls/Table/TableControl';
import {
    TableContextWrapper,
    tableReducer,
    TABLE_ACTION,
} from '../FormControls/Table/TableContext.js';

//#region helpers

const formatActionText = (text) => {
    // The log the action text is talking about the action that's happened to the user, the of/for are needed. (changed email for)
    // But here, we know who it happened to, so we remove trailing for / of.
    const regex = /of$|for$/;
    return strToCapital(text?.replace(regex, ''))?.trim();
};

const getLogInfo = (data) => {
    if (data.action == 'UPDATE_FLAG') {
        return <UserHistoryFlagRow serializedData={data.serialized_data} />;
    }

    // This is for emails sent to the user, rather than Mandrill Events
    if (data.action == 'EMAIL') {
        return <UserHistoryEmailRow serializedData={data.serialized_data} />;
    }

    // This is for Mandrill Emails
    if (data.action == 'EMAIL_EVENT') {
        return <UserHistoryMandrillRow serializedData={data.serialized_data} />;
    }

    if (data.serialized_data?.action == 'user note updated') {
        return <UserHistoryNoteRow serializedData={data.serialized_data} />;
    }

    return <UserHistoryRow serializedData={data.serialized_data} />;
};

const headers = getHeadersFormatted(['Date', 'Action', 'Changed by', 'Data']);

const getTableColumns = (data) => {
    const date = getDateTime(data.timestamp);
    const action =
        data.action == 'EMAIL_EVENT'
            ? formatActionText(data.serialized_data?.action)
            : attributeToStr(data.action);
    const user = data.internal ? 'System' : data.user_name ?? '';
    const logInfo = getLogInfo(data);

    return [date, action, user, logInfo];
};

//#endregion

//#region row child components

const UserHistoryRow = ({ serializedData }) => {
    const data = formatToReadableText(serializedData?.data);
    return (
        <>
            <strong>{formatActionText(serializedData?.action)}. </strong>
            {data && <> Data: {data} </>}
        </>
    );
};

const UserHistoryEmailRow = ({ serializedData }) => {
    const reason = formatToReadableText(serializedData?.data);
    return (
        <>
            <strong>Subject: {formatActionText(serializedData?.action)}. </strong>
            {reason && <>Reason: {reason}</>}
        </>
    );
};

const UserHistoryFlagRow = ({ serializedData }) => {
    const flagData = serializedData.data;
    if (!flagData) return 'FLAG LOG ERROR';

    const reason = flagData.reason && ` Reason: ${flagData.reason}.`;
    const notes = flagData.notes && `Notes: ${flagData.notes}`;
    const snoozeDate = flagData.snooze_date && `Snoozed until: ${getDate(flagData.snooze_date)}`;

    return (
        <>
            <strong>
                &quot;{flagData.flag_type}&quot; {serializedData.action}.
            </strong>
            {reason} {notes} {snoozeDate}
        </>
    );
};

const UserHistoryMandrillRow = ({ serializedData }) => {
    const data = formatToReadableText(serializedData?.data);
    return <>{data && <> Data: {data} </>}</>;
};

UserHistoryMandrillRow.propTypes = {
    serializedData: PropTypes.object,
};

const UserHistoryNoteRow = ({ serializedData }) => {
    return (
        <>
            <strong>User note updated to:</strong> {serializedData.data?.notes}
        </>
    );
};

//#endregion

//#region main

const UserHistoryContainer = ({ userID, dirtyData, mandrillTable }) => {
    const [tableState, dispatchTableState] = useReducer(tableReducer, {
        page: 1,
        itemsPerPage: mandrillTable ? 50 : 100,
        itemsCount: 0,
        dirtyData,
    });

    const userActivityModel = new models.UserActivityLog();

    const doFetch = async () => {
        const data = {
            user: userID,
            page: tableState.page,
            page_size: tableState.itemsPerPage,
        };
        const result = await userActivityModel.fetch({ data });
        // Filtering out Mandrill events from other events to populate each table
        const filteredResults = result.results.reduce((acc, item) => {
            const check = item.action === 'EMAIL_EVENT';
            if (mandrillTable) {
                // we're filling the Mandrill events history table
                if (check) {
                    // if the event has data information, add it as is in to the list
                    if (item.serialized_data?.data[0].length > 0) {
                        acc.push(item);
                    } else {
                        // there wasn't any data on the event, so let's try to combine like events

                        // check if there's another Mandrill event on this item's datet with the same action
                        // that's already been added to the list
                        const previousValue = acc.find((data) => {
                            if (
                                app
                                    .moment(item.timestamp, app.settings.dateTransportFormat)
                                    .format(app.settings.dateTransportFormat) ===
                                    app
                                        .moment(data.timestamp, app.settings.dateTransportFormat)
                                        .format(app.settings.dateTransportFormat) &&
                                item.serialized_data.action === data.serialized_data.action &&
                                data.serialized_data.data.includes('total events')
                            ) {
                                return data;
                            }
                        });

                        if (previousValue) {
                            const copyPrevious = previousValue;
                            const newTotal =
                                previousValue.serialized_data.data[
                                    previousValue.serialized_data.data.indexOf('total events') + 1
                                ] + 1;
                            previousValue.serialized_data.data.splice(
                                previousValue.serialized_data.data.indexOf('total events') + 1,
                                1,
                                newTotal,
                            );
                            // replace the previous Mandrill event object with the updated one
                            acc.splice(acc.indexOf(copyPrevious), 1, previousValue);
                        } else {
                            // first time seeing this Mandrill action without data on this date, so add it to the list
                            item.serialized_data.data = ['total events', 1];
                            acc.push(item);
                        }
                    }
                }
            } else {
                // we're in regular History table, and don't want Mandrill Events
                if (!check) {
                    acc.push(item);
                }
            }

            return acc;
        }, []);

        const fResult = { ...result, results: filteredResults };
        return fResult;
    };

    useEffect(() => {
        if (dirtyData) dispatchTableState({ type: TABLE_ACTION.SET_DIRTY });
    }, [dirtyData]);

    const tableTitle = mandrillTable ? 'Mandrill Email Events' : 'History';

    return (
        <div className="rbs4">
            <h2>{tableTitle}</h2>

            <TableContextWrapper
                {...{
                    tableState,
                    dispatchTableState,
                    options: { selectable: false, sortable: false, pageable: true },
                }}
            >
                <TableControl
                    {...{
                        doFetch,
                        getTableColumns,
                        headers,
                        className: 'large',
                    }}
                />
            </TableContextWrapper>
        </div>
    );
};

export default UserHistoryContainer;

//#endregion

//#region proptypes

UserHistoryContainer.defaultProps = {
    mandrillTable: false,
};

UserHistoryContainer.propTypes = {
    userID: PropTypes.string.isRequired,
    dirtyData: PropTypes.bool,
    mandrillTable: PropTypes.bool,
};

UserHistoryRow.propTypes = {
    serializedData: PropTypes.shape({
        action: PropTypes.string.isRequired,
        data: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
    }),
};

UserHistoryEmailRow.propTypes = {
    serializedData: PropTypes.shape({
        action: PropTypes.string.isRequired,
        data: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
    }),
};

UserHistoryNoteRow.propTypes = {
    serializedData: PropTypes.shape({
        data: PropTypes.shape({
            notes: PropTypes.string,
        }),
    }),
};

UserHistoryFlagRow.propTypes = {
    serializedData: PropTypes.shape({
        action: PropTypes.string.isRequired,
        data: PropTypes.shape({
            flag_type: PropTypes.string.isRequired,
            reason: PropTypes.string,
            notes: PropTypes.string,
            snooze_date: PropTypes.string,
        }).isRequired,
    }),
};

//#endregion
