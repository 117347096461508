// base entry point for Perspective app

define([
    'backbone',
    './router',
    './controller',
    './models',
    'moment',
    './manager',
    './extras/app',
    './extras/ajax',
    'bootstrap',
    './widgets/bootstrap-datepicker',
], function (Backbone, Router, Controller, models, moment) {
    /*
            The base app object
        */
    var App = function () {
        this.moment = moment;
        this.controller = new Controller(this);
        this.router = new Router(this, this.controller);

        // don't cache jquery requests
        $.ajaxSetup({ cache: false });

        // defaults
        this.settings = {};

        this.settings['dateFormat'] = 'DD/MM/YYYY';
        this.settings['dateTransportFormat'] = 'YYYYMMDD';
        this.settings['longDateTimeFormat'] = 'D MMM YYYY HH:mm';
        this.settings['longDateFormat'] = 'D MMM YYYY';
        this.settings['mediumDateTimeFormat'] = 'HH:mm D MMM';
        this.settings['dateValidFormats'] = [
            this.settings['dateFormat'],
            'D/M/YYYY',
            'D/M/YY',
            'DD-MM-YYYY',
            'D-M-YYYY',
            'D-M-YY',
            'DD.MM.YYYY',
            'D.M.YYYY',
            'D.M.YY',
            'DDMMYYYY',
        ];
        this.settings['dateLowerBound'] = '01/01/1500';
        this.settings['dateUpperBound'] = '01/01/3000';
        // the datepicker takes a different format scheme to momentjs, so
        // we can't just copy the above
        $.fn.datepicker.defaults.format = 'dd/mm/yyyy';
        $.fn.datepicker.defaults.todayHighlight = true;
        $.fn.datepicker.defaults.weekStart = 1;
        $.fn.datepicker.defaults.startDate = this.settings['dateLowerBound'];
        $.fn.datepicker.defaults.endDate = this.settings['dateUpperBound'];

        // proximity bounds settings
        this.settings['proximityDefault'] = 30;
        this.settings['proximityLowerBound'] = 0;
        this.settings['proximityUpperBound'] = 99;

        // messages
        window.messages.ERROR = 'danger';
        window.messages.options['ttl'] = 5000;
        window.messages.removeMessages();
        window.toaster.ERROR = 'danger';
        window.toaster.options['ttl'] = 5000;
        window.toaster.removeMessages();

        // TODO: This needs to be more intelligent when we have comparison,
        // multitabs and recent documents
        this.documents = [];
        this.getDocument = function () {
            if (this.documents.length) {
                // get the most recently pushed doc - this is important if going
                // to side-by-side view from News/Surveys
                return this.documents[this.documents.length - 1];
            }
        };

        // For open and close functions
        this.recentDocuments = new models.RecentDocuments();
    };

    _.extend(App.prototype, Backbone.Events, {
        urls: {
            home: '/',
            login: '/login',
            tod: '/tod',
            rod: '/rod',
            news: '/news',
            surveys: '/surveys',
            documents: '/documents',
            search: '/documents/search',
            compare: '/documents/compare',
            printWindow: '/documents/print',
            intermediateWindow: '/inter',
            annotationsWindow: '/annotations',
            upload: '/upload',
            importUsers: '/administration/multiuserupload',
            favouritesWindow: '/favourites',
            cmsMedia: 'media/cms_page_media',
            newAndRecent: '/tod/new-and-recently-amended-documents',
            organisation: '/organisation',
            settings: '/settings',
            timeline: '/timeline',
        },
        emailTemplateLabels: JSON.parse(
            document.getElementById('email_template_labels').textContent,
        ),
        // for keeping track of popups
        popups: {
            intermediate: { name: 'interWindow', orphan: false },
            tod: { name: 'todWindow', orphan: false },
            rod: { name: 'rodWindow', orphan: false },
            print: { name: 'printWindow', orphan: false },
        },
        darkMode: document.documentElement.getAttribute('data-theme') === 'dark',
        start: function () {
            this.started = true;

            // mark this window for closing if it's a popup and it's opener is closed
            if (
                _.find(this.popups, function (o) {
                    return o.name == window.name && o.orphan == false;
                })
            ) {
                window.closeWithParent = true;
            }

            // global actions
            this.controller.global();

            // user data
            this.user = new models.CurrentUser();
            this.user
                .fetch()
                .done(
                    _.bind(function () {
                        // initialise toolbar for all logged in pages
                        this.controller.toolbar();
                        if (this.user.canViewAlertPreferences()) {
                            this.controller.emailAlertPreferencePopup();
                        }
                        this.user.isAuthenticated = true;
                    }, this),
                )
                .fail(
                    _.bind(function () {
                        this.user.isAuthenticated = false;
                    }, this),
                )
                .always(
                    _.bind(function () {
                        this.initHistory();
                    }, this),
                );
        },
        initHistory: function () {
            Backbone.history.start({
                pushState: true,
                silent: false,
                hashChange: false,
            });
        },
    });

    app = window.app = new App(); // eslint-disable-line no-global-assign
});
