const pluralise = (count, singular, plural) => (Math.abs(count) == 1 ? singular : plural);

const escapeDOMId = (str) => {
    return str.replace(/([^a-zA-Z0-9\-_])/g, '\\$1');
};

const isNewsItem = (instances) => {
    let isNews = false;
    _.each(instances, function (instance) {
        if (instance.families.filter((family) => family.slug === 'news').length) {
            isNews = true;
        }
    });
    return isNews;
};

const isSurveyItem = (instances) => {
    let isSurvey = false;
    _.each(instances, function (instance) {
        if (instance.families.filter((family) => family.slug === 'srv').length) {
            isSurvey = true;
        }
    });
    return isSurvey;
};

module.exports = {
    pluralise,
    escapeDOMId,
    isNewsItem,
    isSurveyItem,
};
