import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';

import { VALID_POSTCODE_LENGTH } from '../UserDetails/utils';
import TypedSelect from '../FormControls/Select/TypedSelect';
import { stringCompareIgnoreSpace, comparePostcodesForValidity } from '../utils';
import Select from '../FormControls/Select/Select';

const PostcodeChanger = ({ postcodeOptions, postcodeList, data, setData, postcodesInvalid }) => {
    const { postcodeForAll, postcodes } = data;
    const setPostcodes = (item, value) => {
        const newPostcodes = { ...postcodes, [item]: value };
        setData({ ...data, postcodes: newPostcodes });
    };

    const setPostcodeForAll = (postcodeForAll) => setData({ ...data, postcodeForAll });

    const shouldShowAddress =
        postcodeForAll &&
        postcodeOptions.length > 0 &&
        !postcodeOptions.some((p) => comparePostcodesForValidity(p?.value, postcodeForAll?.value));

    return (
        <>
            <Form.Group>
                <Form.Row>
                    <Col>
                        <Form.Label>Existing postcodes</Form.Label>
                    </Col>
                    <Col>
                        <Form.Label>Change to</Form.Label>
                    </Col>
                </Form.Row>

                {Object.keys(postcodeList).map((item) => {
                    return (
                        <Form.Row key={item}>
                            <Col>
                                <div className="item-list" key={item}>
                                    <Form.Control
                                        readOnly
                                        defaultValue={
                                            postcodeOptions.find((p) =>
                                                stringCompareIgnoreSpace(p.value, item),
                                            )?.value || item
                                        }
                                    />
                                    <p>x {postcodeList[item]}</p>
                                </div>
                            </Col>
                            <Col>
                                <Select
                                    key={item}
                                    onChange={(value) => {
                                        setPostcodes(item, value);
                                    }}
                                    options={postcodeOptions}
                                    placeholder="Select postcode"
                                    name={item}
                                    isInvalid={postcodesInvalid && (!postcodes || !postcodes[item])}
                                    errorMessage="Please select a postcode"
                                />
                            </Col>
                        </Form.Row>
                    );
                })}
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    Alternatively, change <i>all</i> selected addresses to
                </Form.Label>

                <TypedSelect
                    options={postcodeOptions}
                    onChange={(postcode) => {
                        setPostcodeForAll(postcode);
                    }}
                    value={postcodeForAll}
                    name="changeAllPostcode"
                    placeholder="Select or create a postcode to change all postcodes to"
                    maxLength={VALID_POSTCODE_LENGTH}
                    isInvalid={postcodesInvalid}
                    errorMessage="Please create or select a postcode"
                />
                <p></p>
                {/*Work address is required if the postcode doesn't match some of the available postcodes from organisation */}
                {shouldShowAddress && (
                    <>
                        <Form.Control
                            required
                            as="textarea"
                            type="text"
                            name="address"
                            placeholder="Address to change all selected addresses to"
                            maxLength={3000}
                        />
                        <div className="invalid-feedback">Please select an address</div>
                    </>
                )}
            </Form.Group>
        </>
    );
};

export default PostcodeChanger;

PostcodeChanger.propTypes = {
    postcodeOptions: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        }),
    ).isRequired,
    postcodeList: PropTypes.object.isRequired,
    data: PropTypes.shape({
        postcodeForAll: PropTypes.object,
        postcodes: PropTypes.object,
    }).isRequired,
    setData: PropTypes.func.isRequired,
    postcodesInvalid: PropTypes.bool,
};
