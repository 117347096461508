import React from 'react';
import PropTypes from 'prop-types';
import FormModal from '../FormControls/FormModal';
import Select from '../FormControls/Select/Select';
import { Form } from 'react-bootstrap';

const EDITABLE_FORMATTING_FIELD = {
    DOCUMENT_DISPLAY_COUNT: 'Document display count',
    PANEL_HEIGHT: 'Panel height',
};
Object.freeze(EDITABLE_FORMATTING_FIELD);

const FORMATTING_OPTIONS = {
    PANEL_HEIGHT_OPTION: {
        SMALL: 'Small',
        MEDIUM: 'Medium',
        LARGE: 'Large',
        DEFAULT: 'Default sizing',
        CONTENT: 'Fit to content',
    },
    DOCUMENT_DISPLAY_COUNT_OPTION: {
        10: '10',
        20: '20',
        50: '50',
        80: '80',
        300: '300',
    },
};
Object.freeze(FORMATTING_OPTIONS);

const ContentPanelFormatModal = ({
    show,
    hideModal,
    title,
    doPost,
    format,
    setFormat,
    handleFormatUpdate,
}) => {
    const submitForm = async (form) => {
        const updatedData = Object.keys(EDITABLE_FORMATTING_FIELD).reduce(
            (dataObject, key) => ({
                ...dataObject,
                [key.toLowerCase()]: form.elements[key.toLowerCase()].value,
            }),
            {},
        );

        const results = await doPost({ ...updatedData, panel_type: format.panel_type });
        handleFormatUpdate(results);
    };

    return (
        <FormModal {...{ show, hideModal, title, submitForm, size: 'md' }}>
            {Object.entries(format).map(([key, value]) => {
                if (EDITABLE_FORMATTING_FIELD[key.toUpperCase()]) {
                    return (
                        <Form.Group key={`${key}-edit-field`}>
                            <Form.Label>{key}</Form.Label>
                            <Select
                                onChange={(choice) =>
                                    setFormat({
                                        ...format,
                                        [key]: choice.value,
                                    })
                                }
                                value={{
                                    value: value,
                                    label: FORMATTING_OPTIONS[`${key.toUpperCase()}_OPTION`][value],
                                }}
                                options={Object.entries(
                                    FORMATTING_OPTIONS[`${key.toUpperCase()}_OPTION`],
                                ).map(([optionKey, optionValue]) => ({
                                    value: optionKey,
                                    label: optionValue,
                                }))}
                                name={key}
                            />
                        </Form.Group>
                    );
                }
            })}
        </FormModal>
    );
};

export default ContentPanelFormatModal;

ContentPanelFormatModal.propTypes = {
    show: PropTypes.bool,
    hideModal: PropTypes.func,
    title: PropTypes.string,
    doPost: PropTypes.func,
    format: PropTypes.shape({
        panel_height: PropTypes.string,
        panel_type: PropTypes.string,
        document_display_count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    setFormat: PropTypes.func,
    handleFormatUpdate: PropTypes.func,
};
