import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import confirm from '../FormControls/ConfirmModal/ConfirmModalService';
import PiconIcon from '../FormControls/Icons/PiconIcon';
import EditFavouriteModal from './EditFavouriteModal';
import { TableContext, TABLE_ACTION } from '../FormControls/Table/TableContext';

import { favouritePropTypes, favouritesModel } from './utils';
import { formatViewOption } from '../DocumentFavourites/FavouritePopover';

export const deleteItem = async (item, setDirty, hideModal) => {
    const confirmed = await confirm({
        title: 'Delete Favourite',
        message: (
            <div>
                <p>Are you sure you want to delete this Favourite?</p>
                <br />
                <p className="favourite-name">{item.model.attributes.name}</p>
            </div>
        ),
    });
    if (!confirmed) return false;

    try {
        await item.model.destroy();
        setDirty();

        if (hideModal) {
            hideModal();
        }

        toaster.success('Favourite deleted');
    } catch (error) {
        toaster.error('Could not delete the favourite');
    }
};

export const getViewOptionAndDate = (params) => {
    const tt_params = params?.reduce((acc, param) => {
        const parts = param.split('=');
        acc[parts[0]] = parts[1];
        return acc;
    }, {});

    const viewDate = app.moment(tt_params?.tt_date).format(app.settings.dateFormat);
    return {
        viewOption: formatViewOption(tt_params?.tt_option),
        viewDate:
            tt_params?.tt_option !== 'tt-between'
                ? viewDate
                : `${viewDate} - ${app.moment(tt_params.tt_date2).format(app.settings.dateFormat)}`,
    };
};

const FavouriteActions = ({ item, model }) => {
    const [editVisible, setEditVisible] = useState(false);

    const { dispatchTableState } = useContext(TableContext);

    const iconColour = app.darkMode ? 'lighter-blue' : 'blue';

    const setDirty = () => {
        app.trigger('favourite:changed');
        dispatchTableState({ type: TABLE_ACTION.SET_DIRTY });
    };

    const faveUrl = new URL(item.favourite_url);
    const params = faveUrl.search.split('?')[1]?.split('&');
    if (params) {
        Object.assign(item, getViewOptionAndDate(params));
    } else {
        Object.assign(item, {
            viewOption: 'Current',
            viewDate: app.moment().format(app.settings.dateFormat),
        });
    }

    return (
        <>
            <button
                className="btn-link"
                title="Edit Favourite"
                onClick={() => setEditVisible(true)}
                aria-label="view favourite"
            >
                <PiconIcon iconName="edit" className={`${iconColour}`} />
            </button>
            <button
                className="btn-link"
                title="Delete Favourite"
                onClick={() => deleteItem(item, setDirty)}
                aria-label="delete favourite"
            >
                <PiconIcon iconName="delete" className={`${iconColour}`} />
            </button>

            {editVisible && (
                <EditFavouriteModal
                    {...{
                        hideModal: () => setEditVisible(false),
                        item: { ...item, tt_disabled: true },
                        show: editVisible,
                        setDirty,
                        document: model,
                    }}
                />
            )}
        </>
    );
};

export default FavouriteActions;

FavouriteActions.propTypes = {
    item: PropTypes.shape(favouritePropTypes),
    model: PropTypes.shape(favouritesModel),
};

deleteItem.propTypes = {
    item: PropTypes.shape(favouritePropTypes),
    setDirty: PropTypes.func.isRequired,
    handleModal: PropTypes.func,
};
