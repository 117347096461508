import React from 'react';
import PropTypes from 'prop-types';
import Collapse from 'react-bootstrap/Collapse';

const FileCell = ({ showExpanded, children }) => {
    return (
        <td>
            <Collapse in={showExpanded}>
                <div>
                    <div className="with-padding"> {children}</div>
                </div>
            </Collapse>
        </td>
    );
};

export default FileCell;

FileCell.propTypes = {
    showExpanded: PropTypes.bool.isRequired,
    children: PropTypes.node,
};
