define([
    'backbone',
    '../helpers',
    '../../models',
    '../../templates/search/search_modal.html',
    '../../templates/search/search_modal_recent_docs.html',
    '../../templates/search/search_modal_recent_docs_item.html',
    '../../templates/search/search_modal_selected_collections.html',
    '../../templates/search/search_modal_selected_collections_item.html',
    'jquery-typeahead',
], function (
    Backbone,
    helpers,
    models,
    searchModalTpl,
    recentDocsTpl,
    recentDocsItemTpl,
    selectedCollectionsTpl,
    selectedCollectionsItemTpl,
) {
    /*
        Recent documents tab list item
    */
    var RecentDocsItemView = helpers.TemplateView.extend({
        tagName: 'li',
        template: recentDocsItemTpl,
        attributes: {
            role: 'presentation',
        },
        events: {
            'change .toc-select': 'selectItem',
        },
        selectItem: function () {
            this.$('label').toggleClass('selected', this.$('.toc-select').is(':checked'));
        },
    });

    /*
        Recent documents tab list
    */
    var RecentDocsView = helpers.CollectionView.extend({
        el: 'div.inner',
        itemViewContainer: 'ul',
        events: {
            'click .select-all': 'selectAll',
            'change .toc-select': 'toggleSelectAll',
        },
        template: recentDocsTpl,
        itemView: RecentDocsItemView,
        selectAll: function (evt) {
            if ($(evt.currentTarget).find(':checkbox').is(':checked')) {
                this.$('.toc-select').prop('checked', true);
                this.$('.toc-item').addClass('selected');
            } else {
                this.$('.toc-select').prop('checked', false);
                this.$('.toc-item').removeClass('selected');
            }
        },
        toggleSelectAll: function () {
            this.$('.select-all input:checkbox').prop(
                'checked',
                this.$('.toc-select:checked').length == this.$('.toc-select').length,
            );
        },
    });

    /*
        Selected collections tab list item
    */
    var SelectedCollectionsItemView = helpers.CollectionView.extend({
        template: selectedCollectionsItemTpl,
        tagName: 'li',
        attributes: {
            role: 'presentation',
        },
        itemViewContainer: 'ul',
        initialize: function () {
            if (this.model.children.length) {
                this.$el.addClass('expandable');
            }
        },
        buildItemView: function (item) {
            // recursively use this view on it's children
            return new SelectedCollectionsItemView(this.getOptions(item));
        },
        getCollection: function () {
            return this.model.children.models;
        },
    });

    /*
        Selected collections tab list
    */
    var SelectedCollectionsView = helpers.CollectionView.extend({
        el: 'div.inner',
        itemViewContainer: 'ul',
        events: {
            'click .select-all': 'selectAll',
            'change .toc-select': 'toggleSelectAll',
        },
        template: selectedCollectionsTpl,
        itemView: SelectedCollectionsItemView,
        selectAll: function (evt) {
            if ($(evt.currentTarget).find(':checkbox').is(':checked')) {
                this.$('.toc-select').prop('checked', true);
                this.$('.toc-item').addClass('selected');
            } else {
                this.$('.toc-select').prop('checked', false);
                this.$('.toc-item').removeClass('selected');
            }
        },
        toggleSelectAll: function (evt) {
            // parent checkboxes
            var $parent = $(evt.currentTarget).closest('.expandable');

            if ($(evt.currentTarget)[0] != $parent.find('.toc-select:first')[0]) {
                $parent
                    .find('.toc-select:first')
                    .prop(
                        'checked',
                        $parent.find('ul .toc-select:checked').length ==
                            $parent.find('ul .toc-select').length,
                    );
            }

            // Select All checkbox
            _.delay(
                _.bind(function () {
                    this.$('.select-all input:checkbox').prop(
                        'checked',
                        this.$('.toc-select:checked').length == this.$('.toc-select').length,
                    );
                }, this),
                10,
            );
        },
    });

    /*
        Search Modal
    */
    var SearchModalView = helpers.ModalView.extend({
        template: searchModalTpl,
        className: 'modal m-search',
        id: 'm-search',
        attributes: {
            tabindex: -1,
            role: 'dialog',
            'aria-labelledby': 'm-search-label',
            'aria-hidden': 'true',
        },
        events: {
            'show.bs.modal': 'disableScroll',
            'hide.bs.modal': 'enableScroll',
            'click .toggle-search': 'toggleSearchType',
            'click .insert-today': 'insertToday',
            'click .btn-datepicker': 'showPicker',
            'focus .search-date .form-control': 'toggleDateRange',
            'hide .search-date .form-control': 'hideDatePicker',
            'changeDate .search-date .form-control': 'changeDabente',
            'clearDate .search-date .form-control': 'clearDate',
            'click #searchInDocument': 'submitSearch',
            'focus .search-how .form-control': 'toggleSearchMode',
            'keyup .search-query': 'enterSearch',
            'click #pane-collections .inner .toc-icon': 'toggleCollection',
            'click #pane-collections .inner .toc-select': 'selectChildCollection',
        },
        templateHelpers: function () {
            return {
                docTitle: this.docTitle,
            };
        },
        disableScroll: function (evt) {
            // Disable scrolling before showing modals
            app.trigger('scroll:enable', false);
        },
        enableScroll: function (evt) {
            // Re-enable scrolling when closing modals
            app.trigger('scroll:enable', true);
        },
        initialize: function (options) {
            var urlParams = new URLSearchParams(window.location.search);
            this.queryParams = _.fromPairs([...urlParams]);
            // fromPairs doesn't handle if the value is an array, which means if we searched from a selection of docs, only the first would be kept
            this.queryParams.docs = urlParams.getAll('docs');
            var urlFamParams = urlParams.getAll('fam');
            // we still need to get any selected families, but if we're in SectionResults, the url doesn't contain the searched for fams so this tries to get them from the PreFill if that exists
            var selectedFamily =
                window.documentSearchResultsPreFill?.collectionData.last_fq.find((q) =>
                    q.includes('doc_family'),
                ) ?? '';
            this.queryParams.fam =
                urlFamParams.length > 0
                    ? urlFamParams
                    : selectedFamily
                          .substring(selectedFamily.indexOf('(') + 1, selectedFamily.length - 1)
                          .split(' ');
            if (this.queryParams.q) {
                this.queryParams.q = this.queryParams.q.replace(/%2F/g, '/');
            }
            if (this.model) {
                this.norris = options.norris;
                this.docId = this.model.get('doc_id');
                this.docTitle = this.model.get('title');
                // Disable unavailable states
                var hasDraft = Boolean(this.model.instances.where({ state: 'draft' }).length);
                var hasReady = Boolean(this.model.instances.where({ state: 'ready' }).length);
                var hasPublished = Boolean(
                    this.model.instances.where({ state: 'published' }).length,
                );
                var hasRetired = Boolean(this.model.instances.where({ state: 'retired' }).length);
                this.listenTo(this, 'render', function () {
                    this.$('#searchState-draft').attr('disabled', !hasDraft);
                    this.$('#searchState-ready').attr('disabled', !hasReady);
                    this.$('#searchState-published').attr('disabled', !hasPublished);
                    this.$('#searchState-retired').attr('disabled', !hasRetired);
                });
            } else if (this.queryParams.search) {
                this.docId = this.queryParams.doc_id;
                var document = new models.Document({ doc_id: this.docId });
                // fetch the document title
                this.listenTo(this, 'render', function () {
                    document.getTitles([this.docId]).done(
                        _.bind(function (data) {
                            this.$('.current-doc-title').html(data[this.docId]);
                        }, this),
                    );
                });
            }

            this.recentDocuments = new models.RecentDocuments();
            this.families = new models.Families();

            this.listenTo(this, 'render', this.renderSubViews);
            this.listenTo(this, 'render', this.setTabs);
            this.listenTo(this, 'render', this.setWidgets);

            this.selectedCollectionsView = new SelectedCollectionsView();
            this.recentDocsView = new RecentDocsView();

            this.listenTo(this, 'modal:shown', this.setMaxHeight);
            this.listenToOnce(this, 'modal:shown', this.populateSearchTerms);
            this.listenTo(this, 'modal:hidden', this.unsetWidgets);

            $(window).on('resize', _.debounce(_.bind(this.setMaxHeight, this), 100));
        },
        renderSubViews: function () {
            this.populateRecentDocs();
            this.populateSelectedCollections();
        },
        populateSelectedCollections: function () {
            this.families.fetch().done(
                _.bind(function () {
                    this.selectedCollectionsView.collection = this.families;
                    this.assign(this.selectedCollectionsView, '#pane-collections div.inner');
                }, this),
            );
        },
        populateRecentDocs: function () {
            this.recentDocuments.fetch().done(
                _.bind(function () {
                    this.recentDocsView.collection = this.recentDocuments;
                    this.assign(this.recentDocsView, '#pane-recentdocs div.inner');
                }, this),
            );
        },
        populateSearchTerms: async function () {
            const terms = await app.user.getSearchTerms();
            this.$('#searchQuery').typeahead({
                source: terms,
                offset: true,
                minLength: 1,
                emptyTemplate: '<span />',
                callback: {
                    onHideLayout: function () {
                        this.resultContainer.hide();
                    },
                    onShowLayout: function () {
                        this.resultContainer.show();
                    },
                    onSubmit: _.bind(function (node, forms, item, evt) {
                        this.submitSearch(evt);
                    }, this),
                },
            });
        },
        toggleCollection: function (evt) {
            evt.preventDefault();
            var $li = $(evt.currentTarget).closest('li');
            $li.toggleClass('expanded');
            $li.find('ul:first').toggle($li.hasClass('expanded'));
        },
        selectChildCollection: function (evt) {
            var checked = $(evt.currentTarget).is(':checked');
            var $el = $(evt.currentTarget).closest('li');

            $el.find('.toc-item').toggleClass('selected', checked);

            $el.find('ul li .toc-select').prop('checked', checked);
        },
        selectTab: function (tab) {
            this.clearErrors();

            this.$('.search-tabs .btn')
                .removeClass('selected')
                .attr('aria-selected', false)
                .attr('tabindex', -1);

            $(tab).addClass('selected').attr('aria-selected', true).attr('tabindex', 0);

            // disable 'All' if this is a section search
            this.$('#searchState-all').prop('disabled', $(tab).attr('id') == 'select-currentdoc');

            _.defer(
                _.bind(function () {
                    // wait till radio click is registered
                    $(tab).find('input:radio').prop('checked', true);

                    // disable date range controls for current doc search but not others
                    this.$('.search-date').prop(
                        'disabled',
                        $(tab).attr('id') == 'select-currentdoc',
                    );

                    // revert back to default if section search
                    if (this.model && $(tab).attr('id') == 'select-currentdoc') {
                        var docState = this.model.getInstance().get('state');
                        if (this.$('#searchState-' + docState).length) {
                            this.$('#searchState-' + docState).prop('checked', true);
                        }
                    }
                }, this),
            );
        },
        reset: function (changeQuery) {
            // Reset the widget data if switching
            // between toolbar search and change query
            // search.
            if (this.changeQuery != changeQuery) {
                var data = {};
                this.changeQuery = changeQuery;

                if (changeQuery == true) {
                    // Use the query parameters to populate the search box
                    data = this.queryParams;
                } else {
                    // Reset the search box to defaults
                    data = {
                        q: '',
                        mode: 'exact',
                        scope: 'exnotes',
                        date: 'all',
                    };

                    if (this.model || this.docId) {
                        data['tab'] = 'current';
                    } else {
                        data['tab'] = 'recent';
                        data['docs'] = [];
                    }

                    // reset recent docs and collections
                    this.$('.toc-item')
                        .toggleClass('selected', false)
                        .find('.toc-select')
                        .prop('checked', false);
                }
                this._setData(data);
            }
        },
        setTabs: function () {
            // Reset the search box parameters
            // Search where tabs

            var view = this;
            if (_.isEmpty(this.model) && _.isUndefined(this.docId)) {
                this.$('#select-currentdoc').hide();
                this.selectTab(this.$('#select-recentdocs'));
                this.$('#pane-recentdocs').addClass('active');
            } else {
                this.selectTab(this.$('#select-currentdoc'));
                this.$('#pane-currentdoc').addClass('active');
            }

            this.$('.search-tabs .btn').on('show.bs.tab', function () {
                _.bind(view.selectTab, view)(this);
            });
        },
        setWidgets: function () {
            // TODO: Datepicker not shown on previous search pages.
            // We have duplicate ID on the page, modal dialog conflicting
            // with search within results settings.

            // datepickers
            $('#daterange-from')
                .datepicker({
                    autoclose: true,
                })
                .on('show', function () {
                    $('.datepicker').css({ 'z-index': 100000000 });
                });

            $('#daterange-to')
                .datepicker({
                    autoclose: true,
                    multidate: false,
                })
                .on('show', function () {
                    $('.datepicker').css({ 'z-index': 100000000 });
                });
        },
        unsetWidgets: function () {
            this.$('#searchQuery').typeahead('destroy');
            this.$('#daterange-from').datepicker('destroy');
            this.$('#daterange-to').datepicker('destroy');
        },
        setMaxHeight: function () {
            // adapt height of modal to fit viewport

            var height =
                $(window).height() -
                (this.$('#search-controls').height() +
                    this.$('.modal-header').height() +
                    this.$('.modal-footer').height() +
                    200);

            this.$('.search-where .inner').css({ 'max-height': height });

            this.collectionColHeight = Math.min(parseInt(height) - 30, 200); // 30 for the scroll bar
        },
        toggleSearchType: function (evt) {
            evt.preventDefault();
            if ($(evt.currentTarget).hasClass('show-advanced-search')) {
                this.$('#standard-search').hide();
                this.$('#advanced-search').show();
            } else {
                this.$('#standard-search').show();
                this.$('#advanced-search').hide();
            }
        },
        toggleSearchMode: function (evt) {
            $(evt.currentTarget)
                .parents('.radio')
                .find('input[name="searchMode"]')
                .prop('checked', true);
        },
        toggleDateRange: function (evt) {
            $(evt.currentTarget)
                .parents('.radio')
                .find('input[name="searchDate"]')
                .prop('checked', true);
        },
        insertToday: function (evt) {
            evt.preventDefault();
            $(evt.currentTarget)
                .siblings('.form-control')
                .datepicker('update', app.moment().format(app.settings.dateFormat));
            $(evt.currentTarget).blur();
            $('#searchDate-range').prop('checked', true);
        },
        showPicker: function (evt) {
            evt.preventDefault();
            $(evt.currentTarget).siblings('.form-control').focus();
        },
        _toISODate: function (dateStr) {
            return (
                app.moment(dateStr, [app.settings.dateFormat]).format('YYYY-MM-DDTHH:mm:ss') + 'Z'
            );
        },
        _fromISODate: function (dateStr) {
            return app.moment(dateStr).format(app.settings.dateFormat);
        },
        // The following date methods prevent the date
        // input from clearing when enter button is pressed.
        // Does not prevent deselections in the datepicker.
        changeDate: function (evt) {
            this.date = $(evt.currentTarget).datepicker('getDate');
        },
        clearDate: function (evt) {
            this.date = $(evt.currentTarget).datepicker('getDate');
        },
        validateProximity: function (evt) {
            evt.preventDefault();

            var currentValue = $(evt.currentTarget).val();

            var intValue = parseInt(currentValue);

            if (isNaN(intValue)) {
                intValue = app.settings['proximityDefault'];
            } else if (intValue < app.settings['proximityLowerBound']) {
                intValue = app.settings['proximityLowerBound'];
            } else if (intValue > app.settings['proximityUpperBound']) {
                intValue = app.settings['proximityUpperBound'];
            }

            if (currentValue != intValue.toString()) {
                $(evt.currentTarget).val(intValue);
            }
        },
        _getData: function () {
            this.errorMsg = new Array();

            var data = {};

            // Get the documents or families we are are going to search
            data['tab'] = this.$('input[name="view-options"]:checked').val();

            switch (data['tab']) {
                case 'current':
                    // If we are doing a within document search
                    // we need the doc instance or we need to get the
                    // doc instance for the state we are searching for
                    // prior to sending the request to solr, so that our
                    // search cannot become invalid when instances change
                    // state/retired etc. We currently do the latter, getting
                    // the document instance from the dB for the selected state.
                    // Therefore, we don't need to pass the document instance
                    // uuid around.
                    data['doc_id'] = this.docId;
                    //data['doc_title'] = this.docTitle;
                    break;
                case 'recent':
                    var docs = [];
                    $('#pane-recentdocs .inner .doc-id:checked').each(function () {
                        docs.push($(this).val());
                    });

                    if (docs.length == 0) {
                        this.errorMsg.push('Please select one or more documents to search.');
                    } else if (docs.length == 1) {
                        // just do a section search if only one doc selected
                        data['tab'] = 'current';
                        data['doc_id'] = docs[0];
                        // this is a bit fragile!
                        // we need to extract the doc title for the benefit of
                        // the single search
                        //data['doc_title'] = $('#pane-recentdocs .inner :checked')
                        //.closest('.toc-title')
                        //.text();
                        //data['doc_title'] = $.trim(data['doc_title']);
                    } else {
                        data['docs'] = docs;
                    }
                    break;
                case 'collection':
                    var families = [];
                    $('#pane-collections .inner .family-slug:checked').each(function () {
                        families.push($(this).val());
                    });
                    if (families.length === 0) {
                        this.errorMsg.push('Please select one or more families to search.');
                    } else {
                        data['fam'] = families;
                    }
                    if ($('#pane-collections .inner .select-all input')[0].checked) {
                        //data['alldocs'] = true;
                    }
                    break;
            }

            // Only include state in the query if valid as this may not exist
            // for users that have permission to search for docs with different
            // states. The permissions are checked in django, and users without
            // permissions to view different states are defaulted to published.
            var state = this.$('input[name="searchState"]:checked').val();
            if (state) {
                data['state'] = state;
            }

            if (this.$('#standard-search').is(':visible')) {
                data['q'] = this.$('#searchQuery').val();
                data['date'] = this.$('input[name="searchDate"]:checked').val();

                // normalise smart quotes
                data['q'] = data['q'].replace('“', '"');
                data['q'] = data['q'].replace('”', '"');

                // remove lone brackets
                data['q'] = data['q'].replace(/(?:^|\s)[()](?:\s|$)/, ' ');

                if (!data['q'] || data['q'] == this.$('#searchQuery').attr('placeholder')) {
                    if (data['date'] === 'all') {
                        this.errorMsg.push('Please add one or more search terms.');
                    }
                }

                data['mode'] = this.$('input[name="searchMode"]:checked').val();
                data['scope'] = this.$('input[name="searchScope"]:checked').val();

                // Set the search within word proximity if all terms search
                if (data['mode'] == 'all') {
                    data['proximity'] = this.$('input[name="searchProximity"]').val();
                }

                // Date range/period specifics
                if (data['tab'] != 'current') {
                    switch (data['date']) {
                        case 'range':
                            data['date_from'] = app.moment(
                                this.$('#daterange-from').val(),
                                app.settings.dateFormat,
                            );
                            data['date_to'] = app.moment(
                                this.$('#daterange-to').val(),
                                app.settings.dateFormat,
                            );

                            if (!data['date_from'].isValid() || !data['date_to'].isValid()) {
                                this.errorMsg.push('Invalid or missing date(s).');
                            }

                            if (data['date_from'].isAfter(data['date_to'])) {
                                this.errorMsg.push('From date must be before To date.');
                            }

                            data['date_from'] = this._toISODate(data['date_from']);
                            data['date_to'] = this._toISODate(data['date_to']);

                            break;
                        case 'period':
                            data['date_last_value'] = this.$('#searchDate-last-value').val();
                            data['date_last_units'] = this.$(
                                '#searchDate-last-units option:selected',
                            ).val();
                            break;
                    }
                } else {
                    // default of current doc searches
                    data['date'] = 'all';
                }
            } else {
                data['q'] = this.$('#advSearch').val();
                data['mode'] = 'advanced';
                if (!data['q']) {
                    this.errorMsg.push('Please add one or more search terms.');
                }
            }
            return data;
        },
        _setData: function (data) {
            if (data) {
                // Populate the advanced/standard search field
                if (data['mode'] == 'advanced') {
                    this.$('#advSearch').val(data['q']);
                } else {
                    this.$('#searchQuery').val(data['q']);
                }

                // Selected tab
                switch (data['tab']) {
                    case 'current':
                        // If we don't have a model we don't want to set the
                        // current tab active.
                        if (!_.isEmpty(this.model)) {
                            this.$('.search-where.tab-pane').removeClass('active');
                            this.selectTab(this.$('#select-currentdoc'));
                            this.$('#pane-currentdoc').addClass('active');
                            this.$('#toc-tree-currentdoc .toc-select').prop('checked', true);
                        }
                        break;
                    case 'recent':
                        this.$('.search-where.tab-pane').removeClass('active');
                        this.selectTab(this.$('#select-recentdocs'));
                        this.$('#pane-recentdocs').addClass('active');

                        // Set the recently selected docs from the query data
                        var selectedRecentDocs = data['docs'];
                        if (this.recentDocsView.rendered) {
                            $('#toc-tree-recentdocs .toc-select').each(function () {
                                $(this).prop(
                                    'checked',
                                    _.includes(selectedRecentDocs, $(this).val()),
                                );
                                $(this).trigger('change');
                            });
                        } else {
                            this.listenTo(this.recentDocsView, 'render', function () {
                                _.defer(
                                    _.bind(function () {
                                        $('#toc-tree-recentdocs .toc-select').each(function () {
                                            $(this).prop(
                                                'checked',
                                                _.includes(selectedRecentDocs, $(this).val()),
                                            );
                                            $(this).trigger('change');
                                        });
                                    }, this),
                                );
                            });
                        }

                        break;
                    case 'collection':
                        function _activateCollectionTab() {
                            this.$('.search-where.tab-pane').removeClass('active');
                            this.selectTab(this.$('#select-collections'));
                            this.$('#pane-collections').addClass('active');

                            $('#toc-tree-collections .toc-select').each(function () {
                                var checked = _.includes(selectedFamilies, $(this).val());

                                $(this)
                                    .prop('checked', checked) // check the box
                                    .closest('.toc-item') // highlight the corresponding label
                                    .toggleClass('selected', checked);

                                if (checked) {
                                    $(this).parents('.expandable').addClass('expanded'); // expand all parent nodes
                                }
                            });

                            // TODO: check select all if all checkboxes are selected
                        }

                        // Set the recent collections from the query data
                        var selectedFamilies = data['fam'];
                        if (this.selectedCollectionsView.rendered) {
                            _.bind(_activateCollectionTab, this)();
                        } else {
                            this.listenTo(this.selectedCollectionsView, 'render', function () {
                                _.defer(_.bind(_activateCollectionTab, this));
                            });
                        }

                        break;
                }

                // How to search settings
                switch (data['mode']) {
                    case 'advanced':
                        this.$('#standard-search').hide();
                        this.$('#advanced-search').show();
                    case 'any':
                        this.$('#searchMode-or').prop('checked', true);
                        break;
                    case 'all':
                        this.$('#searchMode-proximity').prop('checked', true);
                        break;
                    default:
                        this.$('#searchMode-and').prop('checked', true);
                }

                // Add the proximity value if we have it
                if (data['proximity']) {
                    this.$('#searchProximity').val(data['proximity']);
                }

                // Search scope settings
                switch (data['scope']) {
                    case 'incnotes':
                        this.$('#searchScope-all-incnotes').prop('checked', true);
                        break;
                    case 'titlesheadings':
                        this.$('#searchScope-titlesheadings').prop('checked', true);
                        break;
                    default:
                        this.$('#searchScope-all-exnotes').prop('checked', true);
                }

                // Date range/period settings
                switch (data['date']) {
                    case 'range':
                        this.$('#searchDate-range').prop('checked', true);
                        this.$('#daterange-from').val(this._fromISODate(data['date_from']));
                        this.$('#daterange-to').val(this._fromISODate(data['date_to']));
                        break;
                    case 'period':
                        this.$('#searchDate-last').prop('checked', true);
                        this.$('#searchDate-last-value').val(data['date_last_value']);
                        this.$('#searchDate-last-units').val(data['date_last_units']);
                        break;
                    default:
                        this.$('#searchDate-all').prop('checked', true);
                }

                // Document state settings
                switch (data['state']) {
                    case 'draft':
                        this.$('#searchState-draft').prop('checked', true);
                        break;
                    case 'ready':
                        this.$('#searchState-ready').prop('checked', true);
                        break;
                    case 'retired':
                        this.$('#searchState-retired').prop('checked', true);
                        break;
                    case 'all':
                        this.$('#searchState-all').prop('checked', true);
                        break;
                    default:
                        this.$('#searchState-published').prop('checked', true);
                }
            }
        },
        enterSearch: function (evt) {
            // Although the typeahead has an onSubmit, it only submits exact options from typeahead__results
            // This handles submissions of new queries, or if a typeahead__result was selected and added to
            if (evt.keyCode == 13) {
                // this looks backwards, but it's how the css/typeahead work ¯\_(ツ)_/¯
                const typedAhead = this.$('.typeahead__result').css('display') == 'none';
                if (!typedAhead) {
                    this.submitSearch(evt);
                }
            }
        },
        clearErrors: function () {
            this.$('#errorContainer .errorlist').html('');
        },
        submitSearch: function (evt) {
            evt.preventDefault();

            var data = this._getData();
            this.clearErrors();

            if (this.errorMsg.length) {
                // display errors within modal
                var errorlist = this.$('#errorContainer .errorlist');
                _.each(this.errorMsg, function (msg) {
                    errorlist.append('<li>' + msg + '</li>');
                });

                this.$('#errorContainer').show();
            } else {
                this.$('#errorContainer').hide();

                if (data['alldocs']) {
                    data = _.omit(data, 'fam');
                }

                data['search'] = data['tab'] == 'current' ? 'section' : 'document';

                // allow for searches in documents to time-travel
                if (this.norris) {
                    data['tt_option'] = this.norris.ttViewOption;
                    data['tt_date'] = this.norris.ttViewDate.format(
                        app.settings.dateTransportFormat,
                    );
                    if (this.norris.ttViewOption === 'tt-between') {
                        data['tt_date2'] = this.norris.ttViewDate2.format(
                            app.settings.dateTransportFormat,
                        );
                    }
                }

                // inline section search if we're on the same document
                if (this.changeQuery) {
                    document.location.href = app.urls.search + '?' + $.param(data, true);
                } else {
                    window.manager.open(app.urls.search + '?' + $.param(data, true));
                }

                this.$el.modal('hide');
            }
            return false;
        },
    });

    return SearchModalView;
});
