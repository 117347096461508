import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { getSelectedFilterValues } from '../../utils';
import { TableContext } from '../../FormControls/Table/TableContext';
import SimpleTableControl from '../../FormControls/Table/SimpleTableControl';

const headers = ['User', 'Shared annotations', 'Private annotations'];

const formatEmptyCount = (count) => {
    if (count === 0) return [];

    const fillList = [];
    while (count > 0) {
        fillList.push('-');
        count--;
    }
    return fillList;
};

const getTableColumns = (item) => {
    const user = `${item.first_name} ${item.last_name}`;

    const projectHeaders = item?.project_annotations ? Object.keys(item?.project_annotations) : [];
    if (projectHeaders.length && !projectHeaders.every((header) => headers.includes(header))) {
        headers.push(...projectHeaders);
    }
    const headersLength = headers.length;
    const headersDifference = headersLength - 3;
    const projectCounts =
        headersLength > 3 && projectHeaders.length
            ? Object.values(item?.project_annotations)
            : formatEmptyCount(headersDifference);

    return [user, item.shared_annotations, item.private_annotations, ...projectCounts];
};

const SelectedUsers = ({ usersModel, setSharedAnnoCount, setWithProjects }) => {
    const { tableState } = useContext(TableContext);

    const doFetch = async () => {
        const result = await usersModel.getSelectedUsersAnnotations(
            getSelectedFilterValues(tableState),
        );
        const sharedAnnoCount = result.reduce(
            (total, currVal) =>
                total +
                currVal.shared_annotations +
                Object.values(currVal.project_annotations).reduce(
                    (count, current) => count + current,
                    0,
                ),
            0,
        );
        const withProjectAnnotations = result.some(
            (res) => Object.keys(res.project_annotations).length,
        );

        setSharedAnnoCount(sharedAnnoCount);
        setWithProjects(withProjectAnnotations);
        return result;
    };

    return (
        <SimpleTableControl
            {...{ doFetch, getTableColumns, headers, className: 'annotation-selected-users' }}
        />
    );
};

export default SelectedUsers;

SelectedUsers.propTypes = {
    usersModel: PropTypes.shape({
        getSelectedUsersAnnotations: PropTypes.func.isRequired,
    }),
    setSharedAnnoCount: PropTypes.func.isRequired,
    setWithProjects: PropTypes.func.isRequired,
};
