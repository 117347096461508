import React from 'react';
import PropTypes from 'prop-types';

import { Popover, OverlayTrigger } from 'react-bootstrap';

const PopoverOverlay = ({ children, title, content, placement, className }) => {
    const popover = (
        <Popover className={`rbs4 popover fade in ${className} ${placement}`}>
            {title && (
                <Popover.Title className="popover-title dd-privacy-mask">{title}</Popover.Title>
            )}
            {content && (
                <Popover.Content className="popover-content dd-privacy-mask">
                    {content}
                </Popover.Content>
            )}
        </Popover>
    );

    return (
        <OverlayTrigger
            delay={{ show: 100, hide: 100 }}
            placement={placement}
            overlay={popover}
            rootClose
        >
            {children}
        </OverlayTrigger>
    );
};

export default PopoverOverlay;

PopoverOverlay.defaultProps = {
    placement: 'bottom',
    className: '',
};

PopoverOverlay.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
    content: PropTypes.node,
    placement: PropTypes.oneOf(['left', 'right', 'top', 'bottom']).isRequired,
    className: PropTypes.string,
};
