import React, { useEffect, useState } from 'react';
import { convertToHTML } from 'draft-convert';
import { EditorState, ContentState, convertFromHTML, AtomicBlockUtils } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import Checkbox from '../FormControls/Checkbox';
import Form from 'react-bootstrap/Form';
import FormInput from '../FormControls/Input/FormInput';
import Modal from 'react-bootstrap/Modal';
import models from '../../models';
import MultiSelect from '../FormControls/Select/MultiSelect';
import { imageToData, navigateToParentWindow, attributeToStr } from '../utils';
import { WithLoadingOverlay } from '../FormControls/WithLoading';

export const EDITABLE_EXTRA_CONTENT_TYPE = {
    ORDINAL: 'input',
    FAMILIES: 'multiselect',
    SECONDARY_COLUMN: 'checkbox',
    TITLE: 'input',
    DISPLAY_FILTER_OPTIONS: 'checkbox',
    COLLAPSE_DOC_FILTER: 'checkbox',
    DOC_FILTER: 'input',
    INDEX_LEVEL: 'input',
    DOC_LIST_LEVEL: 'input',
    DEFAULT: 'checkbox',
    DISPLAY_AS_BUTTONS: 'input',
};
Object.freeze(EDITABLE_EXTRA_CONTENT_TYPE);

const ContentEditor = ({
    content,
    show,
    hideModal,
    title,
    saveContent,
    extraOptions,
    families,
    helpTextUrl,
    validateData,
}) => {
    const [editorState, setEditorState] = useState(); // needs to be empty so we can send either an empty EditorState or one with content
    const [convertedContent, setConvertedContent] = useState(null);
    const [image, setImage] = useState({ base64: '', srcString: '' });
    const [extraContent, setExtraContent] = useState({});
    const [multiselectInvalid, setMultiselectInvalid] = useState(false);
    const [validation, setValidation] = useState([]);
    const [fieldHelpText, setFieldHelpText] = useState({});

    const imageModel = new models.Images();

    useEffect(() => {
        if (editorState) {
            convertContentToHTML();
        }
    }, [editorState]);

    useEffect(() => {
        if (content) {
            setEditorState(
                EditorState.createWithContent(
                    ContentState.createFromBlockArray(convertFromHTML(escapeContent(content))),
                ),
            );
            setConvertedContent(content);
        } else {
            const state = EditorState.createEmpty();
            setEditorState(state);
        }
    }, [content]);

    useEffect(() => {
        // Remove the line coming in extraOptions as we're dealing it with elsewhere and don't want to get muddled with multiple lines
        if (extraOptions) {
            /* eslint-disable no-unused-vars */
            const { line, ...options } = extraOptions;
            const { content, ...smallerOptions } = options;
            const { images, ...noImagesOptions } = smallerOptions;
            const { header_content, ...noHeaderContent } = noImagesOptions;
            const { content_top, ...noTopContent } = noHeaderContent;
            const { content_bottom, help_text, ...cleanedOptions } = noTopContent;
            /* eslint-enable no-unused-vars */
            if (help_text) {
                setFieldHelpText(help_text);
            }
            setExtraContent(cleanedOptions);
        }
    }, [extraOptions]);

    const escapeContent = (content) => {
        return content
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&#x27;');
    };

    const unescapedContent = (content) => {
        return content
            .replace(/&amp;/g, '&')
            .replace(/&lt;/g, '<')
            .replace(/&gt;/g, '>')
            .replace(/&quot;/g, '"')
            .replace(/&#x27;/g, "'");
    };

    const handleEditorChange = (state) => {
        if (image.base64) {
            // Overwrite what React-Draft-wysiwyg does with images so that a src url is added
            const contentWithEntity = editorState
                .getCurrentContent()
                .createEntity('IMAGE', 'IMMUTABLE', {
                    src: image.base64,
                });
            const entityKey = contentWithEntity.getLastCreatedEntityKey();
            const block = AtomicBlockUtils.insertAtomicBlock(
                editorState,
                entityKey,
                image.srcString,
            );

            setEditorState(block);
            setImage({});
        } else {
            setEditorState(state);
        }
        setValidation(validation.splice(validation.indexOf('content'), 1));
    };

    const convertContentToHTML = () => {
        const html = convertToHTML({
            entityToHTML: (entity, originalText) => {
                if (entity.type === 'LINK') {
                    return <a href={entity.data.url}>{originalText}</a>;
                }
                return originalText;
            },
        })(editorState.getCurrentContent());
        setConvertedContent(unescapedContent(html));
    };

    const uploadCallback = async (file) => {
        const imageObject = {
            file: file,
            localSrc: URL.createObjectURL(file),
        };
        try {
            const imageData = await imageToData(imageObject.file);
            const imageResult = await imageModel.createImage({ image: imageData });
            const srcString = imageResult.results.image.split('/media');
            setImage({
                base64: imageData,
                srcString: `${window.location.protocol}/media${srcString[1]}`, // add protocol or images on nested pages get confused
                image: imageResult.results.image,
                id: imageResult.results.pk,
            });

            return new Promise((resolve) => {
                resolve({ data: { link: imageObject.localSrc } });
            });
        } catch (exc) {
            // Currently can't handle multiple images uploaded for one content block
            setValidation([...validation, 'image']);
            return new Promise((resolve) => {
                resolve({ data: {} });
            });
        }
    };

    const removeExtraPTags = (content) => {
        // React-Draft-WYSIWYG is p tag wild, and they're not always helpful
        content.replaceAll('<p> </p>', '');
        const carotCount = content
            ? [...content].reduce((count, letter) => (letter == '<' ? count + 1 : count, 0))
            : 0;
        if (content.startsWith('<p>') && carotCount > 2) {
            const edittedContent = content.replace('<p>', '').split('</p>');
            edittedContent.pop();
            const newContent = edittedContent.join('</p>');
            return newContent.trim();
        } else {
            return content;
        }
    };

    const handleSubmit = async () => {
        let buttonDisplay;
        if (!extraContent?.display_as_buttons) {
            buttonDisplay = [];
        } else if (Array.isArray(extraContent?.display_as_buttons)) {
            buttonDisplay = extraContent?.display_as_buttons;
        } else {
            buttonDisplay = extraContent.display_as_buttons.split(',');
        }
        const contentData = {
            ...(convertedContent && {
                content: image
                    ? convertedContent
                          .replaceAll('<figure>', `<img src="`)
                          .replaceAll('</figure', '"')
                          .replaceAll('<p></p>', '')
                    : convertedContent.replaceAll('<p></p>', ''),
            }),
            ...(extraContent && {
                ...extraContent,
                display_as_buttons: buttonDisplay,
                publish_start: app.moment(),
            }),
        };
        contentData.content = removeExtraPTags(contentData.content);
        const valid = validateData ? await validateData(contentData) : { no_validation: true };

        if (Object.values(valid)?.some((value) => value === false)) {
            // set errors in modal if there are any
            const fails = Object.entries(valid)
                .filter(([, value]) => value === false)
                .map((entrieArrs) => entrieArrs[0]);
            setValidation(fails);
        } else {
            // Save the content
            saveContent(contentData);
            hideModal();
        }
    };
    return (
        <Modal className="rbs4 content-editor-modal" show={show} onHide={hideModal} title={title}>
            <WithLoadingOverlay loading={editorState == undefined}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {content != undefined && (
                        <Form.Group>
                            <Form.Label>Content</Form.Label>
                            <Editor
                                toolbarClassName="editor-toolbar-custom"
                                editorState={editorState}
                                onEditorStateChange={handleEditorChange}
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                toolbar={{
                                    options: [
                                        'fontSize',
                                        'list',
                                        'textAlign',
                                        'colorPicker',
                                        'link',
                                        'inline',
                                        'blockType',
                                        'image',
                                    ],
                                    inline: { options: ['bold', 'italic', 'underline'] },
                                    image: {
                                        uploadCallback: uploadCallback,
                                        previewImage: true,
                                    },
                                }}
                            />
                            {!_.isEmpty(validation) &&
                                validation.map((field, index) => {
                                    switch (field) {
                                        case 'content':
                                            return (
                                                <Form.Control.Feedback key={index}>
                                                    <p className="text-error">
                                                        This field is required.
                                                    </p>
                                                </Form.Control.Feedback>
                                            );
                                        case 'image':
                                            return (
                                                <Form.Control.Feedback key={index}>
                                                    <p className="text-error">
                                                        There was an issue uploading and saving the
                                                        image.
                                                    </p>
                                                </Form.Control.Feedback>
                                            );
                                        default:
                                            // other validation messages are taken care of by the form
                                            break;
                                    }
                                })}
                        </Form.Group>
                    )}

                    {Object.keys(extraContent).length > 0 && (
                        <div>
                            {Object.entries(extraContent).map(([key, value]) => {
                                switch (EDITABLE_EXTRA_CONTENT_TYPE[key.toUpperCase()]) {
                                    case 'multiselect':
                                        return (
                                            <Form.Group key={`content-editor-${key}`}>
                                                <Form.Label>{attributeToStr(key)}</Form.Label>
                                                <MultiSelect
                                                    name={key}
                                                    options={families.reduce(
                                                        (familyArr, currFamily) => {
                                                            familyArr.push({
                                                                label: currFamily.title,
                                                                value: currFamily.slug,
                                                            });

                                                            return familyArr;
                                                        },
                                                        [],
                                                    )}
                                                    value={extraContent[key].reduce(
                                                        (familyArr, currFamily) => {
                                                            familyArr.push({
                                                                label: currFamily.title,
                                                                value: currFamily.slug,
                                                            });
                                                            return familyArr;
                                                        },
                                                        [],
                                                    )}
                                                    onChange={(choices) => {
                                                        if (choices) {
                                                            setExtraContent({
                                                                ...extraContent,
                                                                [key]: choices.map(
                                                                    ({ value, label }) => ({
                                                                        title: label,
                                                                        slug: value,
                                                                    }),
                                                                ),
                                                            });
                                                            setMultiselectInvalid(false);
                                                        } else {
                                                            setMultiselectInvalid(true);
                                                        }
                                                    }}
                                                    onBlur={(choices) => {
                                                        if (choices) {
                                                            setMultiselectInvalid(false);
                                                        }
                                                    }}
                                                    required
                                                    isInvalid={multiselectInvalid}
                                                    errorMessage="At least one family must be selected." // how to make this dynamic???
                                                />
                                                {fieldHelpText[key] && (
                                                    <Form.Text className="text-muted">
                                                        {fieldHelpText[key]}
                                                    </Form.Text>
                                                )}
                                            </Form.Group>
                                        );

                                    case 'checkbox':
                                        return (
                                            <Form.Group key={`content-editor-${key}`}>
                                                <Checkbox
                                                    checked={extraContent[key]}
                                                    onChange={() =>
                                                        setExtraContent({
                                                            ...extraContent,
                                                            [key]: !extraContent[key],
                                                        })
                                                    }
                                                    label={attributeToStr(key)}
                                                    helpText={fieldHelpText[key]}
                                                />
                                            </Form.Group>
                                        );
                                    case 'input':
                                        return (
                                            <Form.Group key={`content-editor-${key}`}>
                                                <FormInput
                                                    label={attributeToStr(key)}
                                                    name={key}
                                                    helpText={fieldHelpText[key]}
                                                    defaultValue={value ? value.toString() : ''}
                                                    onChange={(input) => {
                                                        setExtraContent({
                                                            ...extraContent,
                                                            [key]: input,
                                                        });
                                                        if (
                                                            validation.some(
                                                                (error) => error === key,
                                                            )
                                                        ) {
                                                            validation.splice(
                                                                validation.indexOf(key),
                                                                1,
                                                            ),
                                                                setValidation(validation);
                                                        }
                                                    }}
                                                />
                                                {/* because of how we're doing with saving/submitting the form, we have to do manual error messaging */}
                                                {!_.isEmpty(validation) &&
                                                    validation.some((field) => field === key) && (
                                                        <Form.Control.Feedback>
                                                            <p className="text-error">
                                                                {key === 'index_level'
                                                                    ? 'This field is required and must be a value 1 to 3.'
                                                                    : 'This field is required.'}
                                                            </p>
                                                        </Form.Control.Feedback>
                                                    )}
                                            </Form.Group>
                                        );
                                    default:
                                        // if the key isn't in the types object, don't do anything with it
                                        break;
                                }
                            })}
                            {helpTextUrl && (
                                <Form.Group>
                                    <b className="help-text-django-admin">
                                        You can also edit parts of the ToD in{' '}
                                        <a
                                            href={helpTextUrl}
                                            onClick={(evt) => {
                                                evt.preventDefault();
                                                navigateToParentWindow(evt.currentTarget.href);
                                            }}
                                        >
                                            Django Admin
                                        </a>
                                    </b>
                                </Form.Group>
                            )}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" onClick={handleSubmit}>
                        Save
                    </Button>
                    <Button variant="secondary btn-default" onClick={hideModal}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </WithLoadingOverlay>
        </Modal>
    );
};

export default ContentEditor;

ContentEditor.propTypes = {
    content: PropTypes.string,
    show: PropTypes.bool,
    hideModal: PropTypes.func,
    title: PropTypes.string,
    saveContent: PropTypes.func,
    extraOptions: PropTypes.object,
    families: PropTypes.array,
    helpTextUrl: PropTypes.string,
    validateData: PropTypes.func,
};
