define([
    'backbone',
    'bootbox',
    '../models',
    './helpers',
    './mixins/intermediate_window',
    './mixins/multi_ref',
    '../templates/notes/notes.html',
    '../widgets/dragger',
    '../extras/backbone-mixin',
], function (Backbone, bootbox, models, helpers, IntermediateWindowMixin, MultiRefMixin, notesTpl) {
    var NotesView = helpers.TemplateView.extend({
        el: '.content',
        template: notesTpl,
        $doc_notes: $('<div id="docnotes"></div>'),
        $sec_notes: $('<div id="secnotes"></div>'),
        note_types: {
            '.commnote': 'commences',
            '.amnotegp': 'amends',
            '.destnote': 'derv-dests',
            '.interptn .general': 'interprets',
            '.interptn .modapp': 'interprets',
            '.interptn .tranprov': 'interprets',
            '.interptn .extent': 'interprets',
            '.order': 'order-regs',
            '.case': 'cases',
            '.regultry': 'reg-refs',
            '.misc': 'expln-notes',
        },
        initialize: function (options) {
            this.doc_title = options.title;
            this.media_url = options.url;
            this.section = options.section;
            // Although this looks like it's not used, it's needed for some of the links to actually function properly when travelling to another intermediate window
            this.norris = new models.ChunkNorris({ model: this.model });

            $('.titlebar .inner .doc-title').attr('title', this.doc_title);
            $('.titlebar .inner .doc-title .title-text').html('Notes - ' + this.doc_title);
            $('.pane-main .inner').html('<p>Fetching document notes...</p>');

            $('.titlebar').addClass('titlebar-nodate');

            $.get(this.media_url + 'notes/doc.history.html').done(
                _.bind(function (data) {
                    //$('.pane-main .inner').html(data);
                    $(data).find('.title').appendTo(this.$doc_notes);
                    $(data).find('.note-tit').appendTo(this.$doc_notes);
                    $(data).find('.note-tex').appendTo(this.$doc_notes);
                    this.setHrefs(this.$doc_notes);
                    var $doctoc = $('#toc-docnotes .toc-item');
                    $doctoc.replaceWith(
                        $(
                            '<a class="toc-item" role="treeitem" href="#docnotes">' +
                                $doctoc[0].innerHTML +
                                '</a>',
                        ),
                    );
                }, this),
            );

            // load history fragment
            $.get(this.media_url + 'notes/history_' + this.section + '.html')
                .done(
                    _.bind(function (data) {
                        $(data)
                            .find('.note-tit')
                            .first()
                            .addClass('main-title')
                            .appendTo(this.$sec_notes);
                        this.initializeNotes(data);
                        //this.scrollToCurrent();
                        // load ftnotes fragment
                    }, this),
                )
                .fail(
                    _.bind(function () {
                        var section = this.section.includes('.')
                            ? this.section.slice(0, this.section.lastIndexOf('.'))
                            : this.section;
                        $.get(this.media_url + 'notes/history_' + section + '.html').done(
                            _.bind(function (data) {
                                $(data)
                                    .find('.note-tit')
                                    .first()
                                    .addClass('main-title')
                                    .appendTo(this.$sec_notes);
                                this.initializeNotes(data);
                            }, this),
                        );
                    }, this),
                )
                .always(
                    _.bind(function (data) {
                        $.get(this.media_url + 'notes/ftnotes_' + this.section + '.html')
                            .done(
                                _.bind(function (data) {
                                    if (_.isEmpty(this.$sec_notes.find('.note-tit'))) {
                                        $(data)
                                            .find('.note-tit')
                                            .first()
                                            .addClass('main-title')
                                            .appendTo(this.$sec_notes);
                                    } else if (
                                        this.$sec_notes.find('.note-tit').text() == ' ' ||
                                        this.$sec_notes.find('.note-tit').text() == ''
                                    ) {
                                        this.$sec_notes
                                            .find('.note-tit')
                                            .text($(data).find('.note-tit').first().text());
                                    }
                                    this.initializeNotes(data);
                                }, this),
                            )
                            .always(
                                _.defer(
                                    _.bind(function (data) {
                                        this.scrollToCurrent();
                                    }, this),
                                ),
                            );
                    }, this),
                );
            if (
                this.section != 'doc.history' &&
                window.location.hash.replace(/^#/, '') != 'docnotes'
            ) {
                _.defer(
                    _.bind(function () {
                        $('.pane-main .inner').empty().append(this.$sec_notes);
                    }, this),
                );
            } else {
                _.defer(
                    _.bind(function () {
                        $('.pane-main .inner').empty().append(this.$doc_notes);
                    }, this),
                );
            }
            $(window).on('hashchange', _.bind(this.hashChange, this));
            $('#toolbar-pwclose .btn').on('click', function () {
                window.close();
            });
        },

        initializeNotes: function (fragment) {
            _.forIn(
                this.note_types,
                _.bind(function (toc_id, note_class) {
                    $note = $(fragment).find(note_class);

                    const searchHitMap = window.opener.searchHitMap;
                    // for highlighting search terms from main doc in the notes - takes the object of arrays of objects, and creates an array of objects from the section we need
                    const sectionHitMap = Object.values(
                        _.pickBy(searchHitMap, (value, key) =>
                            value.find((v) => v.section == this.section),
                        ),
                    ).flat();

                    if (!_.isEmpty(sectionHitMap)) {
                        sectionHitMap.forEach((searchHit) => {
                            $note.find('p').html(function (index, html) {
                                html = html.replace(/[\r\n]+/g, ' ');
                                return html.replace(searchHit['regex'], searchHit['replace']);
                            });
                        });
                    }

                    if ($note.length) {
                        if (note_class == '.amnotegp') {
                            gp_title = $($note).children('.note-tit:first-child');
                            gp_tex = gp_title.find('.note-tex:first-child');
                            this.addNote('amends', gp_title, gp_tex);
                            _.each(
                                $note.find('.amdnote'),
                                _.bind(
                                    function (toc_id, $note, sub_note, ind, titles) {
                                        title = $(sub_note).find('.note-tit');
                                        amd_note = $(sub_note).find('.note-tex');
                                        this.addNote(`${toc_id}-${ind}`, title, amd_note);
                                    },
                                    this,
                                    toc_id,
                                    $note,
                                ),
                            );
                        } else {
                            nt_title = $note.find('.note-tit');
                            if (nt_title.length > 0) {
                                _.each(
                                    $note.find('.note-tit'),
                                    _.bind(
                                        function (toc_id, $note, sub_note, ind, titles) {
                                            this.addNote(
                                                toc_id,
                                                $(sub_note),
                                                $(sub_note)
                                                    .next('.note-tex')
                                                    .add(
                                                        $(sub_note)
                                                            .parent()
                                                            .find('.note-tex:first-child'),
                                                    ),
                                            );
                                        },
                                        this,
                                        toc_id,
                                        $note,
                                    ),
                                );
                            } else {
                                this.addNote(0, nt_title, $note);
                            }
                        }
                    }
                }, this),
            );
            this.convertMultiRefLinks(this.$el);
        },
        scrollToCurrent: function () {
            doc_section = window.location.hash.replace(/^#/, '');
            if (doc_section) {
                var $el = this.$sec_notes.find('#' + doc_section);
                if ($el.length) {
                    $('.pane-main').scrollTop($el.first().position().top);
                }
            }
        },
        addNote: function (note_id, title, content) {
            var $toc_item = $('#toc-' + note_id);
            title.addClass('sec-title').appendTo(this.$sec_notes);
            if ($toc_item.has('span.toc-item.disabled')) {
                title.attr('id', note_id);
                $toc_item.html(
                    '<a class="toc-item" role="treeitem" href="#' +
                        note_id +
                        '">' +
                        $toc_item.find('.toc-item').html() +
                        '</a>',
                );
            }
            _.each(
                content,
                _.bind(function (note) {
                    $(note).appendTo(this.$sec_notes);
                }, this),
            );
        },
        hashChange: function (evt) {
            var doc_section = window.location.hash.replace(/^#/, '');
            $('.toc-item').removeClass('active');
            $('#toc-' + doc_section + ' a').addClass('active');
            if (
                doc_section == 'docnotes' &&
                !_.isEmpty($('#toc-docnotes a')) &&
                !this.$doc_notes.is(':visible')
            ) {
                _.defer(
                    _.bind(function () {
                        $('.pane-main .inner').empty().append(this.$doc_notes);
                        $('.pane-main').scrollTop(0);
                    }, this),
                );
            } else if (
                (doc_section == 'secnotes' ||
                    !_.isEmpty(this.$sec_notes.find('#' + doc_section))) &&
                !this.$sec_notes.is(':visible')
            ) {
                _.defer(
                    _.bind(function () {
                        $('.pane-main .inner').empty().append(this.$sec_notes);
                        this.scrollToCurrent();
                    }, this),
                );
            } else {
                _.defer(_.bind(this.scrollToCurrent, this));
            }
        },
        setHrefs: function ($html) {
            var baseUrl = this.model.getMediaURL();
            $html
                .find('a:not([href*="/"]):not([href^="mailto:"]):not([href="#"])') // links to content
                .each(function () {
                    var lnk = $(this);
                    lnk.prop('href', baseUrl + lnk.attr('href'));
                });
            return $html;
        },
    });

    Backbone.mixin(NotesView, IntermediateWindowMixin, MultiRefMixin);

    return NotesView;
});
