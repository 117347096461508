import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';

import models from '../../models';
import FormModal from '../FormControls/FormModal';
import { handleSaveErrors } from '../utils';
import FormInput, { INPUT_TYPE } from '../FormControls/Input/FormInput';
import ConfirmationEmailSelector from '../FormControls/ConfirmationEmailSelector';

const ChangeEmailModal = ({
    show,
    handleModal,
    currEmail,
    userID,
    setDirty,
    noEmailOption,
    noTemplateOption,
}) => {
    const [loading, setLoading] = useState(false);

    // Use CurrentUser model is ids match
    const userModel =
        userID == app.user.id ? new models.CurrentUser() : new models.User({ id: userID });

    const handleSubmit = async (form) => {
        // Allow a user to change their own email even if they don't have change_user perm
        if (userID != app.user.id && !app.user.has_perm('main.change_user')) {
            toaster.error("Invalid permissions for updating a user's email address");
        } else {
            try {
                setLoading(true);

                const result = await userModel.changeEmail({
                    email: form.elements.email.value,
                    old_email: currEmail,
                    email_template: noTemplateOption
                        ? 'text-change-email-address-email' // this is a bit of a bad idea if the template slug changes
                        : form.elements.emailTemplate.value,
                });
                setLoading(false);
                toaster.success(result.result);
                if (setDirty) {
                    setDirty();
                }
                return result;
            } catch (err) {
                handleSaveErrors({
                    xhr: err,
                    genericMessage: "There was a problem changing the user's email address",
                });
                setLoading(false);
                return false;
            }
        }
    };

    // This will render a modal in a modal if the user decides to change an email address while changing a user's details. ¯\_(ツ)_/¯
    return (
        <FormModal
            title="Change email address"
            hideModal={handleModal}
            submitForm={handleSubmit}
            size="sm"
            isMain={true}
            {...{ show, loading }}
        >
            <Form.Group>
                <Form.Label>Current email address</Form.Label>
                <Form.Control
                    aria-label="Current email address"
                    readOnly
                    name="old_email"
                    defaultValue={currEmail}
                    placeholder={currEmail}
                    className="dd-privacy-mask"
                ></Form.Control>
            </Form.Group>
            <FormInput
                label="New email address"
                name="email"
                required={true}
                type={INPUT_TYPE.EMAIL}
                invalidMessage="Please enter a valid email address"
            />
            {noTemplateOption ? (
                <p>
                    A confirmation email will be sent to your new email address. Follow the
                    instructions in the email to complete the update.
                </p>
            ) : (
                <ConfirmationEmailSelector
                    templateLabel={app.emailTemplateLabels.CHANGE_EMAIL}
                    required={true}
                    includeNullOption={noEmailOption}
                />
            )}
        </FormModal>
    );
};

export default ChangeEmailModal;

ChangeEmailModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleModal: PropTypes.func.isRequired,
    currEmail: PropTypes.string.isRequired,
    userID: PropTypes.string.isRequired,
    setDirty: PropTypes.func,
    noEmailOption: PropTypes.bool,
    noTemplateOption: PropTypes.bool,
};
