import React from 'react';

import models from '../../models';

import DocumentList from './DocumentList';
import DocumentFilter from './DocumentFilter';
import { useTableReducer } from '../customHooks';
import { TableContextWrapper } from '../FormControls/Table/TableContext';
import { Button } from 'react-bootstrap';
import { initialTableState } from '../FormControls/Table/utils';

const defaultTableState = {
    ...initialTableState,
    sortColumn: '-uploaded_at',
};

const DocumentManage = () => {
    const [tableState, dispatchTableState] = useTableReducer({
        defaultTableState,
    });

    const documentSummariesModel = new models.DocumentInstanceSummaries();

    const additionalButtons = (
        <div className="admin-header">
            {app.user.has_perm('content.upload_instances') && (
                <div className="centered-flex">
                    <Button className="btn-sm" href="/administration/upload">
                        Upload
                    </Button>
                </div>
            )}
        </div>
    );

    return (
        <TableContextWrapper
            {...{
                tableState,
                dispatchTableState,
                options: {
                    idPropertyName: 'uuid',
                    sortable: true,
                    selectable: true,
                    pageable: true,
                },
            }}
        >
            <div id="manage" className="rbs4 admin">
                <DocumentFilter documentSummariesModel={documentSummariesModel} />
                <DocumentList
                    documentSummariesModel={documentSummariesModel}
                    additionalButtons={additionalButtons}
                />
            </div>
        </TableContextWrapper>
    );
};

export default DocumentManage;
