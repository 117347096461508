import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import ConfirmModal from './ConfirmModal';

const confirm = (props) => {
    return new Promise((resolve) => {
        let el = document.createElement('div');

        const handleResolve = (result) => {
            unmountComponentAtNode(el);
            if (document.contains(el)) {
                document.removeChild(el);
            }
            resolve(result);
        };

        render(<ConfirmModal {...props} onClose={handleResolve} />, el);
    });
};

export default confirm;
