import React from 'react';
import PropTypes from 'prop-types';
import PopoverOverlay from '../FormControls/PopoverOverlay';
import { PiconChip } from '../FormControls/Chip';
import { getDateTime, getMediumDateTime } from '../utils';

const DownloadFlag = ({ latestDownload, downloadedByName, downloadState, canEdit, showFlag }) => (
    <>
        <PopoverOverlay
            {...{
                placement: 'left',
                content: `${downloadedByName} downloaded the ${downloadState} version of this document on ${getDateTime(
                    latestDownload,
                )}`,
            }}
        >
            <div>
                <PiconChip
                    iconName="warning-sign"
                    state={`flagged ${canEdit ? 'editable-flag' : ''}`}
                    onClick={
                        canEdit
                            ? () => {
                                  showFlag();
                              }
                            : null
                    }
                >
                    {getMediumDateTime(latestDownload)}
                </PiconChip>
            </div>
        </PopoverOverlay>
    </>
);

export default DownloadFlag;

DownloadFlag.defaultProps = {
    canEdit: false,
};

DownloadFlag.propTypes = {
    latestDownload: PropTypes.string.isRequired,
    downloadedByName: PropTypes.string.isRequired,
    downloadState: PropTypes.string.isRequired,
    canEdit: PropTypes.bool,
    showFlag: PropTypes.func,
};
