import React from 'react';
import PropTypes from 'prop-types';

const KeySelectorFooter = ({ searchQuery, charCountTrigger, compareDoc, target }) => {
    const isSearching = searchQuery.length >= charCountTrigger;
    return (
        <div className="key-selector-helper-text">
            {isSearching && (
                <p>
                    Use up and down <strong>arrow keys</strong> to select document.{' '}
                </p>
            )}
            {isSearching && !compareDoc && (
                <p>
                    <strong>Enter</strong> opens selected document. <strong>Ctrl+click</strong> to
                    open document in a new tab.{' '}
                </p>
            )}
            {isSearching && compareDoc && (
                <p>
                    <strong>Enter</strong> and <strong>Ctrl+click</strong> will both open the
                    selected document as {target}.{' '}
                </p>
            )}
            {isSearching && (
                <p>
                    <strong>Esc</strong> to reset search.
                </p>
            )}
            {!isSearching && (
                <p>
                    <strong>Esc</strong> to cancel.
                </p>
            )}
        </div>
    );
};

export default KeySelectorFooter;

KeySelectorFooter.propTypes = {
    searchQuery: PropTypes.string.isRequired,
    charCountTrigger: PropTypes.number.isRequired,
    compareDoc: PropTypes.bool.isRequired,
    target: PropTypes.string,
};
