import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import FormModal from '../../FormControls/FormModal';

import { tableAsyncAction } from '../../utils';

import { TableContext } from '../../FormControls/Table/TableContext';
import { useFetch } from '../../customHooks';
import confirm from '../../FormControls/ConfirmModal/ConfirmModalService';

import { Form } from 'react-bootstrap';
import SelectedUsers from './SelectedUsers';
import Select from '../../FormControls/Select/Select';
import InvalidFeedback from '../../FormControls/InvalidFeedback';
import { IGNORE_PAGING } from '../../FormControls/Table/PaginationControl';
import PiconIcon from '../../FormControls/Icons/PiconIcon';

const getUserOptions = (users) => {
    return (
        users?.map((user) => {
            return {
                label: `${user.first_name} ${user.last_name} - ${user.email}`,
                value: user.id,
            };
        }) ?? []
    );
};

const AnnotationModal = ({ hideModal, usersModel, orgId }) => {
    const [submitLoading, setSubmitLoading] = useState(false);
    const [sharedAnnoCount, setSharedAnnoCount] = useState(0);
    const [userToReassignTo, setUserToReassignTo] = useState();
    const [userInvalid, setUserInvalid] = useState(false);
    const [withProjects, setWithProjects] = useState([]);
    const { tableState, dispatchTableState } = useContext(TableContext);

    const fetchUsersToReassignTo = async () => {
        const params = {
            current_org: orgId,
            sort_column: 'first_name',
            page_size: IGNORE_PAGING,
        };

        const dataResult = await usersModel.fetch({ data: params });
        return dataResult.results;
    };

    const { data: users, loading } = useFetch({
        doFetch: async () => await fetchUsersToReassignTo(),
    });

    const submitForm = async () => {
        if (!userToReassignTo) {
            setUserInvalid(true);
            return false;
        }

        const fun = (params) => usersModel.reassignAnnotations(params);

        const confirmed = await confirm({
            title: 'Reassign shared annotations',
            message: `Are you sure you want to reassign ${sharedAnnoCount} shared annotation${
                sharedAnnoCount > 1 ? 's' : ''
            } to user ${userToReassignTo.label}`,
        });

        if (!confirmed) return false;

        const result = await tableAsyncAction({
            fun,
            tableState,
            dispatchTableState,
            args: { reassign_to: userToReassignTo.value },
            actionName: 'reassign annotations',
            setLoading: setSubmitLoading,
        });

        return result;
    };

    return (
        <FormModal
            title="Reassign shared annotations"
            {...{ show: true, submitForm, hideModal, loading: loading || submitLoading }}
        >
            <div className="reassign-anno-modal">
                <SelectedUsers
                    usersModel={usersModel}
                    setSharedAnnoCount={setSharedAnnoCount}
                    setWithProjects={setWithProjects}
                />
                <Form.Label className="anno-reassign-label">
                    Reassign shared annotations to this user{' '}
                    {withProjects && (
                        <div>
                            <PiconIcon iconName="warning-sign" className="red" />
                            <i className="project-anno-warning">
                                **Note: Some of the users selected have Project annotations. Please
                                make sure the user who you reassign the annotations to also has
                                access to those projects.
                            </i>
                        </div>
                    )}
                </Form.Label>
                <Select
                    onChange={(item) => {
                        setUserToReassignTo(item);
                        setUserInvalid(false);
                    }}
                    options={getUserOptions(users)}
                    placeholder="Search for user by name or email address..."
                    name="reassignUser"
                />
                <InvalidFeedback errorMessage="Please choose a user" isInvalid={userInvalid} />
            </div>
        </FormModal>
    );
};

export default AnnotationModal;

AnnotationModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    usersModel: PropTypes.shape({
        fetch: PropTypes.func.isRequired,
        getSelectedUsers: PropTypes.func.isRequired,
        refreshSelected: PropTypes.func.isRequired,
        reassignAnnotations: PropTypes.func.isRequired,
    }),
    orgId: PropTypes.number.isRequired,
};
