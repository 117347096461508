import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import {
    FileContext,
    FILE_ACTION,
    isFileSelected,
    isFolderIndeterminate,
    fileBranchPropTypes,
} from './FileContext';
import Checkbox from '../FormControls/Checkbox';

const FileSelect = ({ fileBranch }) => {
    const {
        filesState: { files },
        dispatchFilesState,
    } = useContext(FileContext);

    const checked = isFileSelected(files, fileBranch.fullPath);
    const isIndeterminate =
        fileBranch.children.length > 0 && isFolderIndeterminate(files, fileBranch.fullPath);

    const onChange = (e) => {
        e.stopPropagation();

        if (checked || isIndeterminate) {
            dispatchFilesState({ type: FILE_ACTION.UNSELECT_FILE, fullPath: fileBranch.fullPath });
        } else {
            dispatchFilesState({ type: FILE_ACTION.SELECT_FILE, fullPath: fileBranch.fullPath });
        }
    };

    const dirty = files
        .filter((f) => f.fullPath.startsWith(fileBranch.fullPath))
        .map((f) => f.isSelected);

    return <Checkbox {...{ checked, isIndeterminate, onChange, dirty }} />;
};

export default FileSelect;

FileSelect.propTypes = {
    fileBranch: PropTypes.shape(fileBranchPropTypes).isRequired,
};
