import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import ContentContainer from '../ContentManagement/ContentContainer';
import ContentEditor from '../ContentManagement/ContentEditor';
import models from '../../models';
import ToDDocList from './ToDDocList';
import ToDIndexPanel from './ToDIndexPanel';
import ToDPageHeader from './ToDPageHeader';
import { useHash } from '../customHooks';
import { WithLoadingOverlay } from '../FormControls/WithLoading';

export const TOD_PAGE_REQUIRED_CONTENT = {
    TITLE: 'title',
};
Object.freeze(TOD_PAGE_REQUIRED_CONTENT);

const TOD_FILTER_REQUIRED_CONTENT = {
    DOC_FILTER: 'doc_filter',
    INDEX_LEVEL: 'index_level',
    DOC_LIST_LEVEL: 'doc_list_level',
};
Object.freeze(TOD_FILTER_REQUIRED_CONTENT);

const VALID_INDEX_LEVELS = {
    ONE: 1,
    TWO: 2,
    THREE: 3,
};
Object.freeze(VALID_INDEX_LEVELS);

const conditionallyFlattenObject = (nestedObject) => {
    const copy = { ...nestedObject };
    Object.entries(copy).map(([key, val]) => {
        if (key.startsWith('_')) {
            copy[key] = val;
        } else if (val instanceof Object && !Array.isArray(val)) {
            copy[key] = conditionallyFlattenObject(val);
        } else {
            copy[key] = '';
        }
    });
    return copy;
};

const ToDPage = ({ page, todModel, families }) => {
    const [loading, setLoading] = useState(false);
    const [documents, setDocuments] = useState({});
    const [hash, setHash] = useState('');
    const [editableContent, setEditableContent] = useState({});
    const [showEditor, setShowEditor] = useState({ show: false, content: {} });
    const [errorMessage, setErrorMessage] = useState(false);

    const fetchDocList = async (filters) => {
        const doc_filters = filters
            .sort((aFilter, bFilter) =>
                aFilter.ordinal > bFilter.ordinal ? 1 : bFilter.ordinal > aFilter.ordinal ? -1 : 0,
            )
            .reduce((filterArr, currFilter) => [...filterArr, currFilter.doc_filter], []);

        const filterNames = filters.reduce(
            (filterArr, currFilter) => [
                ...filterArr,
                { [currFilter.doc_filter]: currFilter.title },
            ],
            [],
        );
        const filterDepths = filters.reduce(
            (filterArr, currFilter) => [
                ...filterArr,
                { [currFilter.doc_filter]: currFilter.doc_list_level },
            ],
            [],
        );

        const userFamilyList = families.reduce((famArr, currFam) => [...famArr, currFam.slug], []);

        try {
            const pageResults = await todModel.retrieveDocList({
                doc_filters: doc_filters,
                families: userFamilyList,
                filter_names: JSON.stringify(filterNames),
                filter_depths: JSON.stringify(filterDepths),
            });
            setDocuments(pageResults.results);
        } catch (exc) {
            if (exc.status !== 400) {
                toaster.error('There was a problem retrieving the documents for this page.');
            }
            setErrorMessage(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const pageParts = page.split('/');
                const cleanedPath = pageParts[0];
                const contentResults = await todModel.retrieveContent(cleanedPath);
                setEditableContent(contentResults);

                if (contentResults.filters.length) {
                    if (
                        contentResults.filters.length === 1 &&
                        contentResults.filters[0].doc_filter === 'newlist'
                    ) {
                        // TODO: This could be called as a list more globaly
                        const formatModel = new models.ContentPanelFormat({ id: 'NEW_DOCS_TOD' });
                        const docFormat = await formatModel.fetch();

                        // If we're on the New & Recently Amended tod page, get the docs from its own api
                        const newAndAmendedModel = new models.NewAndRecentlyAmendedDocuments();
                        const results = await newAndAmendedModel.fetch({
                            data: { document_display_count: docFormat.document_display_count },
                        });
                        setDocuments({
                            [contentResults.filters[0].title]: _.groupBy(
                                results,
                                (result) => result.level1_value,
                            ),
                        });
                    } else {
                        fetchDocList(contentResults.filters);
                    }
                }
            } catch (exc) {
                if (exc.status === 403 && !app.user.isAuthenticated) {
                    window.location.href = app.urls.login;
                } else {
                    toaster.error('There was a problem retrieving this page.');
                    setErrorMessage(true);
                    console.error(exc);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    // Mocking a tag href # scrolling
    useHash(); // useHash needs to come before the useEffect for setting the hash, or things go funny
    useHash('index-panel', 'tod-section-header'); // send again so that the Index Panel will scroll too
    useEffect(() => {
        if (hash) window.location.hash = hash;
        // set last visited section for reopen
        app.user.displayPrefs({
            tod_lastpage: `${window.location.pathname.replace('/tod/', '')}${window.location.hash}`,
        });
    }, [hash]);

    useEffect(() => {
        const setTodPageContentPosition = () => {
            // Because the header can have editable content, and thus cannot have a set height
            // but scrolling using hashes needs tod-page-content to be fixed and have a top value
            // we're relying on jquery here to set those values since Backbone doesn't know when
            // ToDPage is ready/rendered to do it in popup.js
            const headerNavHeight = document
                .querySelector('#navigation div.toolbar')
                .getBoundingClientRect().height;
            const todHeader = document.querySelector('.tod-page-header');

            if (todHeader) {
                const headerHeight = todHeader.getBoundingClientRect().bottom;
                const inToD = document.querySelector('#table_of_documents');
                if (inToD) {
                    const todHeight = inToD.getBoundingClientRect().height;

                    const contentHeight = todHeight - headerHeight + headerNavHeight;
                    $('.tod-page-content').css({
                        position: 'fixed',
                        top: `${headerHeight}px`,
                        height: `${contentHeight}px`,
                    });
                } else {
                    // New and Recently Amended opened from the 'more...' link on the homepage
                    // doesn't open in a tod window, so doesn't have the same html wrapping it.
                    $('.tod-page-header').css({
                        position: 'fixed',
                        top: `${headerNavHeight}px`,
                    });
                    $('.tod-page-content').css({
                        position: 'fixed',
                        top: `${headerHeight}px`,
                    });
                }
            }
        };

        setTodPageContentPosition();
        window.addEventListener('resize', setTodPageContentPosition);
    }, [editableContent, documents]);

    const validatePageData = (data) => {
        const valid = {};

        if (
            data[TOD_PAGE_REQUIRED_CONTENT.TITLE] &&
            data[TOD_PAGE_REQUIRED_CONTENT.TITLE].length > 0
        ) {
            valid[TOD_PAGE_REQUIRED_CONTENT.TITLE] = true;
        } else {
            valid[TOD_PAGE_REQUIRED_CONTENT.TITLE] = false;
        }

        return valid;
    };

    const validateFilterData = (data) => {
        const valid = {};

        Object.values(TOD_FILTER_REQUIRED_CONTENT).forEach((field) => {
            if (field === 'doc_filter') {
                if (data[field].length > 0) {
                    valid[field] = true;
                } else {
                    valid[field] = false;
                }
            } else {
                if (
                    data[field] >= VALID_INDEX_LEVELS.ONE &&
                    data[field] <= VALID_INDEX_LEVELS.THREE
                ) {
                    valid[field] = true;
                } else {
                    valid[field] = false;
                }
            }
        });

        return valid;
    };

    const saveContent = async (data) => {
        const content = { ...editableContent };
        if ('doc_filter' in data) {
            try {
                setLoading(true);
                const results = await todModel.updateFilters({
                    ...data,
                    [data.contentType]: data.content,
                });
                content.filters.splice(
                    editableContent.filters.findIndex((filter) => filter.pk === results.pk),
                    1,
                    results,
                );

                fetchDocList(content.filters);
                setEditableContent(content);
                toaster.success('The document filters were successfully updated');
                return { results: { ...results, content: results[data.contentType] } };
            } catch (exc) {
                setLoading(false);
                const errorKeys = exc.responseJSON
                    ? `. Field errors for ${Object.keys(exc.responseJSON?.form_errors).join(', ')}`
                    : '';
                toaster.error(`There was a problem updating the document filters${errorKeys}`);
            }
        } else {
            // The content editor wants the html blocks on key 'content', but Django is expecting
            // header_content, content_top, content_bottom, so we need to sort out the object so
            // the html content is on the correct key
            const updatedData = { ...data, [data.contentType]: data.content };
            // eslint-disable-next-line no-unused-vars
            const { content, ...cleanedData } = updatedData;
            try {
                const results = await todModel.updateContent(page, cleanedData);
                setEditableContent(results);
                toaster.success('The page contents were successfully updated');
                // if page content html content has been edited via the ContentContainer, we need to return what we got to there
                return { results: { ...results, content: results[data.contentType] } };
            } catch (exc) {
                toaster.error('There was a problem updating the page contents');
            }
        }
    };

    const showIndexPanel = (documents) => {
        if (_.isEmpty(documents)) {
            return false;
        }
        if (
            Object.keys(documents).length === 1 &&
            Object.keys(documents)[0] === '' &&
            Object.keys(documents['']).length === 1
        ) {
            return false;
        }
        return true;
    };

    return (
        <WithLoadingOverlay loading={loading} className="tod-loader">
            <ToDPageHeader
                {...{
                    page,
                    editableContent,
                    setShowEditor,
                    validatePageData,
                }}
            />

            <div className="tod-page-content">
                {showIndexPanel(documents) && (
                    <ToDIndexPanel
                        {...{
                            indexOptions: conditionallyFlattenObject(documents),
                            indexFilterOptions: editableContent.filters,
                            setShowEditor,
                            validateFilterData,
                            saveContent,
                            setHash,
                        }}
                    />
                )}
                <div id="tod-page-list" className="tod-page-list">
                    <div id="top" className="tod-doc-container">
                        {!_.isEmpty(editableContent) && (
                            <ContentContainer
                                doFetch={() => ({
                                    content: editableContent.content_top,
                                    helpTextUrl: `/admincms_lite/todpagecontent/${editableContent.id}/change/`,
                                })}
                                doPost={saveContent}
                                contentType="content_top"
                            />
                        )}

                        {errorMessage ? (
                            <div className="tod-doc-error">
                                Sorry, you don&apos;t appear to have access to any documents in this
                                category. Please contact us if you think this is a mistake.
                            </div>
                        ) : (
                            <ToDDocList
                                {...{
                                    items: documents,
                                }}
                            />
                        )}

                        {!_.isEmpty(editableContent) && (
                            <ContentContainer
                                doFetch={() => ({
                                    content: editableContent.content_bottom,
                                    helpTextUrl: `/admincms_lite/todpagecontent/${editableContent.id}/change/`,
                                })}
                                doPost={saveContent}
                                contentType="content_bottom"
                            />
                        )}
                        <div style={{ height: '750px' }}></div>
                    </div>
                </div>
            </div>
            {showEditor.show && (
                <ContentEditor
                    show={showEditor.show}
                    hideModal={() => setShowEditor({ show: false, content: {} })}
                    content={showEditor.content.content}
                    title="ToD page content editor"
                    extraOptions={showEditor.content}
                    saveContent={saveContent}
                    families={families}
                    helpTextUrl={showEditor.helpTextUrl ? showEditor.helpTextUrl : `/admincms_lite`}
                    validateData={showEditor.validateData}
                />
            )}
        </WithLoadingOverlay>
    );
};

export default ToDPage;

ToDPage.propTypes = {
    page: PropTypes.string.isRequired,
    todModel: PropTypes.shape({
        retrieveContent: PropTypes.func.isRequired,
        retrieveDocList: PropTypes.func.isRequired,
        updateContent: PropTypes.func.isRequired,
        updateFilters: PropTypes.func.isRequired,
    }),
    families: PropTypes.array,
};
