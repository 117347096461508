import React from 'react';

import PiconIcon from '../FormControls/Icons/PiconIcon';
import StateIcon from './DownloadStateIcon';
import { useDownloadContext } from './DownloadContext';

const DownloadList = () => {
    const { list, setList } = useDownloadContext();
    const handleRemove = (index) => {
        const newList = [...list];
        newList.splice(index, 1);
        setList(newList);
    };

    return (
        <div className="download-list">
            <h1>Download List</h1>
            {list.length ? (
                <table className="table">
                    <thead>
                        <tr>
                            <th>Document ID</th>
                            <th>Document State</th>
                            <th>File Types</th>
                            <th>Document Files</th>
                            <th className="download-line-actions"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((download, index) => (
                            <tr
                                key={`${download.doc_id}-${download.state}-${download.type}`}
                                className={!download.paths.length ? 'no-paths' : ''}
                                title={
                                    !download.paths.length
                                        ? 'No documents found for this state or file type'
                                        : ''
                                }
                            >
                                <td>
                                    {download.doc_id} <StateIcon item={download.doc_id} />
                                </td>
                                <td>{download.state}</td>
                                <td>{download.type}</td>
                                <td>
                                    {download.paths.length ? (
                                        <ul className="download-file-list">
                                            {download.paths.map((path) => {
                                                const file = path.split('/').pop();
                                                return (
                                                    <li key={path}>
                                                        {file}
                                                        <StateIcon item={file} />
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    ) : (
                                        <span>No documents found for this state or file type</span>
                                    )}
                                </td>
                                <td title="Remove from download list">
                                    <button
                                        className="btn-link delete-download"
                                        onClick={() => handleRemove(index)}
                                        title={`Remove ${download.doc_id} from download list`}
                                    >
                                        <PiconIcon iconName="delete" />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <div></div>
            )}
        </div>
    );
};

export default DownloadList;
