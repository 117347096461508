define(['backbone'], function (Backbone) {
    var MultiRefMixin = {
        convertMultiRefLinks: function ($el) {
            /*
                Give multiref links a popover menu with the selection of links
            */
            var buildPopover = function (multiref, refs, hrefs, classes, titles) {
                var html = '<ul>';
                var titles = titles || {};
                if (classes == 'term') {
                    classes = 'xref';
                }
                _.each(refs, function (ref, index) {
                    var aRef = ref.split('-');
                    var docRef = aRef[0].replace(/sch$/, '') + '-' + aRef[1];
                    var title = titles[docRef];
                    if (!title) {
                        // if for some reason it can't get the title, it's better to send the reference than 'undefined'
                        title = docRef;
                    }
                    if (aRef[3] !== undefined) {
                        title = title + ' ' + aRef[3];
                    }
                    html += '<li>';
                    html += '<a href="' + hrefs[index] + '" ';
                    html += '<a ref="' + ref + '" ';
                    html += 'class="' + classes + '" ';
                    html += 'link="yes" ';
                    html += '>' + title + '</a>';
                    html += '</li>';
                });
                html += '</ul>';
                // If we're in a search, we need to try to get the a tag from $el to insure the connection of the popover to the element
                const target =
                    app.router.current == 'search'
                        ? $el.find(`a[ref="${$(multiref).attr('ref')}"]`)
                        : multiref;
                $(target)
                    .popover({
                        title: 'Select from Multiple References <a type="button" class="close">×</a>',
                        placement: 'bottom',
                        trigger: 'click',
                        html: true,
                        content: function () {
                            return html;
                        },
                    })
                    .on('shown.bs.popover', function () {
                        var $tip = $(this).data('bs.popover').$tip;

                        $tip.find('a.close').on('click', function () {
                            $tip.hide();
                            $tip.removeClass('in');
                        });
                    });
            };

            $el.find('.multiref, .term').each(function (i, multiref) {
                var classes = $(multiref).attr('class').replace('multiref', '').trim();
                var hrefs = $(multiref).attr('hrefs');
                var refs = [
                    $(multiref).attr('ref'),
                    $(multiref).attr('defref'),
                    $(multiref).attr('extdef'),
                    $(multiref).attr('noteref'),
                    $(multiref).attr('tabrefto'),
                ];
                if (classes == 'term' && !!refs[2]) {
                    var extdef = refs[2];
                    var extdef_ref =
                        ' /documents/' + extdef.split('-', 2).join('-').replace(/sch/,'') + '/#' + extdef;
                    if (_.isUndefined(hrefs)) {
                        hrefs = '';
                    }
                    if (
                        !_.isUndefined($(multiref).attr('href')) &&
                        $(multiref).attr('href') != '#'
                    ) {
                        // reset 'hrefs'
                        hrefs = $(multiref).attr('href');
                    }
                    hrefs += extdef_ref;
                    hrefs = hrefs.trim();
                    hrefsList = hrefs.split(' ');
                    $(multiref).attr('link', 'yes');
                    $(multiref).attr('class', classes + ' xref');
                    if (hrefsList.length == 1) {
                        $(multiref).attr('href', hrefs);
                    } else if (hrefsList.length > 1) {
                        $(multiref).attr('href', '#');
                        $(multiref).attr('hrefs', hrefs);
                        $(multiref).attr('class', classes + ' multiref');
                    }
                }
                refs = _.compact(refs).join(' ');
                if (!_.isUndefined(refs) && !_.isUndefined($(multiref).attr('hrefs'))) {
                    hrefs = $(multiref).attr('hrefs');
                    refs = refs.split(' ');
                    hrefs = hrefs.split(' ');
                    if (hrefs.length == refs.length) {
                        var docIds = {};
                        _.each(refs, function (ref, idx) {
                            var aRef = ref.split('-');
                            docIds[ref] = aRef[0].replace(/sch$/, '') + '-' + aRef[1];
                        });
                        app.getDocument()
                            .getTitles(_.uniq(_.values(docIds)))
                            .done(function (titles) {
                                buildPopover(multiref, refs, hrefs, classes, titles);
                            })
                            .fail(function (titles) {
                                if (titles) {
                                    // got some from cache possibly
                                    buildPopover(multiref, refs, hrefs, classes, titles);
                                } else {
                                    // no titles available
                                    buildPopover(multiref, refs, hrefs, classes);
                                }
                            });
                    }
                }
            });
        },
    };

    return MultiRefMixin;
});
