import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Select from '../FormControls/Select/Select';
import { Button, FormControl } from 'react-bootstrap';

import models from '../../models';
import { useDownloadContext } from './DownloadContext';
import PiconIcon from '../FormControls/Icons/PiconIcon';
import { strToCapital } from '../utils';

const DOWNLOADABLE_STATES = ['published', 'ready', 'draft'];
const PREPUBLISHED_STATES = ['draft', 'ready'];

const documentTypeOptions = [
    { value: 'sgm', label: 'SGM file' },
    { value: 'xml', label: 'XML metadata file' },
    { value: 'resources', label: 'Resource Files' },
    { value: 'all', label: 'All Files' },
];

const DownloadSelect = ({ handleList, disabled }) => {
    const [validInput, setValidInput] = useState(false);
    const [documentStateOptions, setDocumentStateOptions] = useState({});
    const { countRef, error, setError, documentInfo, setDocumentInfo, list } = useDownloadContext();
    const inputRef = useRef(null);

    const selectorTypes = [
        {
            type: 'state',
            label: 'Document State',
            placeholder: 'Select document state',
            infoKey: 'documentState',
            options: documentStateOptions[documentInfo.documentId] || [],
        },
        {
            type: 'type',
            label: 'File Types',
            placeholder: 'Select document type',
            infoKey: 'documentType',
            options: documentTypeOptions,
        },
    ];

    const getFilePaths = async (docInfo) => {
        try {
            const params = new URLSearchParams({ data: JSON.stringify(docInfo) });
            const response = await fetch(`${app.urls.download}?${params}`, {
                method: 'GET',
            });
            const data = await response.json();
            return data.result ?? [];
        } catch (error) {
            if (error?.responseJSON?.detail) {
                toaster.error(error.responseJSON.detail);
            } else {
                toaster.error(
                    'An error occurred while fetching the files to add to the download list',
                );
            }
            return [];
        }
    };

    const updateDocumentInfo = (field, value) => {
        setDocumentInfo((prevState) => {
            return { ...prevState, [field]: value };
        });
    };

    const checkDuplicate = (docId, state, type) => {
        const duplicates = {};
        const sameIds = list.filter((item) => item.doc_id === docId);
        if (sameIds.length === 0) return {};
        duplicates.docId = true;
        const sameState = sameIds.filter((item) => item.state === state);
        if (sameState.length === 0) return {};
        duplicates.state = true;
        const sameType = sameState.filter((item) => item.type === type);
        if (sameType.length === 0) return {};
        duplicates.type = true;
        return duplicates;
    };

    const handleInput = async (event) => {
        // Check there's actually a value before making the request
        if (event.target.value == '') {
            setError({ input: 'Document ID is required' });
        } else {
            try {
                // Attempt to fetch the document, but we don't actually need it
                const document = await new models.Document({ doc_id: event.target.value }).fetch();
                const downloaded_warning = {};
                if ('download_by_name' in document) {
                    downloaded_warning.downloaded_by = document.download_by_name;
                    downloaded_warning.downloaded_at = document.latest_download;
                    downloaded_warning.downloaded_by_id = document.download_by_id;
                    downloaded_warning.doc_id = document.doc_id;
                }
                const stateWarnings = {}; // Store upload info to set warning on pre-published states
                const states = document.instances.reduce((acc, instance) => {
                    if (!DOWNLOADABLE_STATES.includes(instance.state)) {
                        return acc;
                    }
                    if (
                        PREPUBLISHED_STATES.includes(instance.state) &&
                        `${app.user.attributes.first_name} ${app.user.attributes.last_name}` !==
                            instance.uploaded_by_name // Only add warning if user is not the uploader; this isn't foolproof
                    ) {
                        stateWarnings[instance.state] = {
                            uploaded_by: instance.uploaded_by_name,
                            uploaded_at: instance.uploaded_at,
                            state: instance.state,
                            doc_id: document.doc_id,
                        };
                    }
                    return [...acc, { value: instance.state, label: strToCapital(instance.state) }];
                }, []);
                setDocumentStateOptions((prevState) => ({
                    ...prevState,
                    [document.doc_id]: states,
                }));
                if (states.length === 1) {
                    // Go ahead and set the state if there's only one
                    updateDocumentInfo('documentState', states[0]);
                }
                if (Object.keys(downloaded_warning).length) {
                    updateDocumentInfo('downloadedWarning', downloaded_warning);
                }
                if (Object.keys(stateWarnings).length) {
                    updateDocumentInfo('stateWarnings', stateWarnings);
                }
                updateDocumentInfo('documentId', document.doc_id);
                setValidInput(true);
            } catch (error) {
                if (error.status === 404) {
                    // Set validation error
                    setError({ input: 'Document not found' });
                    setValidInput(false);
                } else {
                    // Some other problem happened, so show the error message
                    toaster.error(error.responseJSON.detail);
                }
            }
        }
    };

    const handleChange = (event) => {
        // Clear errors when the user types
        if (error && event.target.value) {
            setError((prevState) => {
                return { ...prevState, input: null };
            });
        }
    };

    const handleFocus = (field) => {
        setError((prevState) => {
            return {
                ...prevState,
                [field]: null,
                // Handle if there's a duplicate error and the user changes the field
                ...(error?.input && error.input.startsWith('Duplicated') ? { input: null } : {}),
            };
        });
        if (field === 'input') {
            setValidInput(false);
        }
    };

    const handleSubmit = async (keyEntered) => {
        const errored = [];
        // Set the errors if things don't have values
        if (!documentInfo.documentId || !inputRef.current.value) {
            setError((prevState) => {
                return { ...prevState, input: 'Document ID is required' };
            });
            errored.push(true);
        }
        if (!documentInfo.documentState) {
            setError((prevState) => {
                return { ...prevState, state: 'Document State is required' };
            });
            errored.push(true);
        }
        if (!documentInfo.documentType) {
            setError((prevState) => {
                return { ...prevState, type: 'Document Type is required' };
            });
            errored.push(true);
        }

        const duplicated = checkDuplicate(
            inputRef.current.value,
            documentInfo.documentState.value,
            documentInfo.documentType.value,
        );

        if (Object.keys(duplicated).length) {
            setError((prevState) => {
                return {
                    ...prevState,
                    input: 'Duplicated download request. Change either the document id, state, or type',
                };
            });
            errored.push(true);
        }

        if (errored.length) return;

        const docInfo = {
            doc_id: inputRef.current.value, // use inputRef as it updates before blur event
            state: documentInfo.documentState.value,
            type: documentInfo.documentType.value,
        };

        const paths = await getFilePaths(docInfo);

        countRef.current.total += paths.length;

        handleList({
            ...docInfo,
            paths,
        });
        inputRef.current.value = '';
        if (keyEntered) {
            // refocus on the input field if the user clicked the button
            inputRef.current.focus();
        }
        setValidInput(false);
        updateDocumentInfo('stateWarnings', {});
    };

    return (
        <Form className="form-row download-select-form" onSubmit={handleSubmit}>
            <Form.Group className="col">
                <Form.Label>Document ID</Form.Label>
                <FormControl
                    id="documentId-input"
                    placeholder="Enter document id"
                    type="search"
                    className=""
                    onBlur={handleInput}
                    onFocus={() => handleFocus('input')}
                    isInvalid={Boolean(error?.input)}
                    isValid={validInput}
                    onChange={handleChange}
                    ref={inputRef}
                />
                {validInput && <PiconIcon iconName="tick" className="green valid-field" />}
                {error?.input && (
                    <Form.Control.Feedback type="invalid">{error.input}</Form.Control.Feedback>
                )}
            </Form.Group>
            {selectorTypes.map((selector) => (
                <Form.Group key={selector.type} className="col">
                    <Form.Label>{selector.label}</Form.Label>
                    <Select
                        onChange={(type) => updateDocumentInfo(selector.infoKey, type)}
                        options={selector.options}
                        value={
                            selector.options.length === 1
                                ? selector.options[0]
                                : documentInfo[selector.infoKey]
                        }
                        isRequired={true}
                        placeholder={selector.placeholder}
                        isInvalid={Boolean(error?.[selector.type])}
                        errorMessage={error?.[selector.type]}
                        onFocus={() => handleFocus(selector.type)}
                    />
                </Form.Group>
            ))}
            <div className="col-1">
                <Button
                    className="btn btn-primary btn-sm"
                    disabled={disabled}
                    variant="primary"
                    onMouseDown={() => handleSubmit(false)}
                    onKeyDown={(e) => {
                        e.key === 'Enter' && handleSubmit(true);
                    }}
                >
                    Add to Download List
                </Button>
            </div>
        </Form>
    );
};

export default DownloadSelect;

DownloadSelect.propTypes = {
    handleList: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};
