import React from 'react';

const UserListHelperText = () => (
    <div className="users-info-container">
        <h2>Users</h2>
        <div>
            Below is a list of registered users on the Perspective.info web application as well as
            email alert service subscribers. Users can be on either or both systems and are
            registered separately on each.
        </div>
        <ul>
            <li>
                <strong>Inform us of changes. </strong>Please check the list and inform us of any
                users who have left your organization (unless they are already
                &ldquo;deactivated&rdquo;) or whose details have changed.
            </li>
            <li>
                <strong>Pending</strong> users have yet to respond to an email invitation to use
                Perspective.info. Contact us to have the invitation re-sent.
            </li>
            <li>
                <strong>Deactivated</strong> indicates users who have left your organization or are
                ineligible to use Perspective.info for other reasons.
            </li>
        </ul>
    </div>
);

export default UserListHelperText;
