import React from 'react';
import PropTypes from 'prop-types';

const FileInput = ({ value, onChange, buttonText, multiple, ...props }) => (
    <div>
        <label className="btn btn-primary">
            {buttonText}
            <input
                {...props}
                style={{ display: 'none' }}
                type="file"
                multiple={multiple}
                onChange={(e) => {
                    onChange([...e.target.files]);
                }}
            />
        </label>{' '}
        <span>
            {value.length
                ? `Selected file${multiple ? 's' : ''}: ${value.map((f) => f.name).join(', ')}`
                : ''}
        </span>
    </div>
);

export default FileInput;

FileInput.defaultProps = {
    value: [],
    onChange: () => {},
    buttonText: 'Upload file...',
    multiple: false,
};

FileInput.propTypes = {
    value: PropTypes.arrayOf(PropTypes.any),
    onChange: PropTypes.func,
    buttonText: PropTypes.string,
    multiple: PropTypes.bool,
};
