import React from 'react';
import PropTypes from 'prop-types';
import requiredIf from 'react-required-if';

import { PiconChip } from './../../FormControls/Chip';
import PopoverOverlay from '../../FormControls/PopoverOverlay';

const Flags = ({ flags, itemId, showFlags, isReadonly }) => {
    const canEdit = !isReadonly && app.user.has_perm('main.flag_user');
    return (
        <>
            {flags?.map((flag) => {
                return (
                    <PopoverOverlay
                        key={flag.id}
                        {...{
                            placement: 'left',
                            content: flag.reason,
                        }}
                    >
                        <div>
                            <PiconChip
                                state={`flagged ${canEdit ? 'editable-flag' : ''}`}
                                onClick={canEdit ? () => showFlags(itemId) : null}
                                iconName={'warning-sign'}
                            >
                                {flag.flag_type_name}
                            </PiconChip>
                        </div>
                    </PopoverOverlay>
                );
            })}
        </>
    );
};

export default Flags;

Flags.defaultProps = {
    isReadonly: false,
};

Flags.propTypes = {
    flags: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            reason: PropTypes.string.isRequired,
            flag_type_name: PropTypes.string.isRequired,
        }),
    ),
    itemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    isReadonly: PropTypes.bool.isRequired,
    showFlags: requiredIf(PropTypes.func, (props) => !props.isReadonly),
};
