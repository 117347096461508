import React from 'react';
import PropTypes from 'prop-types';

const VersionChip = ({ label, background }) => {
    return <div className={`chip chip-${background}`}>{label}</div>;
};

export default VersionChip;

VersionChip.propTypes = {
    label: PropTypes.string.isRequired,
    background: PropTypes.string.isRequired,
};
