import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import requiredIf from 'react-required-if';
import { initialTableState } from './utils';

const TABLE_ACTION = {
    CHANGE_ITEMS_PER_PAGE: 'CHANGE_ITEMS_PER_PAGE',
    CHANGE_PAGE: 'CHANGE_PAGE',
    CLEAR_FILTERS: 'CLEAR_FILTERS',
    FETCHED: 'FETCHED',
    LOADING: 'LOADING',
    SELECT_ALL: 'SELECT_ALL',
    SINGLE_SELECT: 'SINGLE_SELECT',
    SET_DIRTY: 'SET_DIRTY',
    SET_FILTER: 'SET_FILTER',
    SORT: 'SORT',
    ORDER: 'ORDER',
    UNSELECT_ALL: 'UNSELECT_ALL',
};

Object.freeze(TABLE_ACTION);

const getSelected = (tableState, id) => {
    if (tableState.selectedList?.includes(id)) {
        return tableState.selectedList?.filter((i) => i != id);
    } else {
        tableState.selectedList?.push(id);
        return tableState.selectedList;
    }
};

const tableReducer = (tableState, action) => {
    switch (action.type) {
        case TABLE_ACTION.CHANGE_ITEMS_PER_PAGE:
            return { ...tableState, page: 1, itemsPerPage: action.itemsPerPage, dirtyData: true };
        case TABLE_ACTION.CHANGE_PAGE:
            return { ...tableState, page: action.page, dirtyData: true };
        case TABLE_ACTION.CLEAR_FILTERS: {
            // Check if table state has a special default. If it does, use it, otherwise use generic table state.
            // Also store default for future usages.
            const initialState = tableState.default ?? initialTableState;
            const { selectedList, selectedAll } = tableState;
            return {
                ...initialState,
                dirtyData: true,
                default: tableState.default,
                selectedList,
                selectedAll,
            };
        }
        case TABLE_ACTION.FETCHED:
            return {
                ...tableState,
                selectedList: action.result.selectedList ?? tableState.selectedList,
                orderedList: action.result.results,
                dirtyData: false,
                loading: false,
                itemsCount: action.result.count,
            };
        case TABLE_ACTION.LOADING:
            return { ...tableState, loading: action.loading };
        case TABLE_ACTION.SELECT_ALL:
            return { ...tableState, selectedList: [], selectedAll: !tableState.selectedAll };
        case TABLE_ACTION.SELECT: {
            return {
                ...tableState,
                selectedList: getSelected(tableState, action.id),
            };
        }
        case TABLE_ACTION.UNSELECT_ALL:
            return { ...tableState, selectedList: [], selectedAll: false };
        case TABLE_ACTION.SET_DIRTY:
            return { ...tableState, dirtyData: true };
        case TABLE_ACTION.SET_FILTER:
            return {
                ...tableState,
                page: 1,
                dirtyData: true,
                filter: action.filter,
            };
        case TABLE_ACTION.SORT:
            return { ...tableState, sortColumn: action.sortColumn, dirtyData: true };
        case TABLE_ACTION.ORDER:
            return { ...tableState, orderedList: action.orderedList };
        default:
            toaster.error(`Invalid table action: ${action.type}`);
            return tableState;
    }
};

const TableContext = React.createContext({
    children: null,
    tableState: null,
    options: null,
    dispatchTableState: null,
    model: null,
});

const TableContextWrapper = ({ tableState, options, dispatchTableState, children, model }) => {
    const contextValue = useMemo(() => {
        return { tableState, dispatchTableState, options, model };
    }, [tableState, dispatchTableState]);

    return <TableContext.Provider value={contextValue}>{children}</TableContext.Provider>;
};

export { TableContextWrapper, TableContext, TABLE_ACTION, tableReducer };

TableContextWrapper.defaultProps = {
    options: {
        selectable: true,
        sortable: true,
        pageable: true,
        orderable: false,
        hidePagerOnLowRows: false,
        idPropertyName: 'id',
    },
};

TableContextWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    tableState: function (props) {
        return PropTypes.shape({
            filter: PropTypes.object,
            page: requiredIf(PropTypes.number, () => props.options.pageable),
            itemsPerPage: requiredIf(PropTypes.number, () => props.options.pageable),
            sortColumn: requiredIf(PropTypes.string, () => props.options.sortable),
            orderedList: requiredIf(PropTypes.array, () => props.options.orderable),
            selectedList: requiredIf(PropTypes.array, () => props.options.selectable),
            selectedAll: requiredIf(PropTypes.bool, () => props.options.selectable),
            dirtyData: PropTypes.bool,
            default: PropTypes.object,
        }).apply(this, arguments);
    },
    options: PropTypes.shape({
        selectable: PropTypes.bool,
        sortable: PropTypes.bool,
        pageable: PropTypes.bool,
        orderable: PropTypes.bool,
        hidePagerOnLowRows: PropTypes.bool,
        idPropertyName: PropTypes.string,
    }),
    dispatchTableState: PropTypes.func.isRequired,
    model: PropTypes.object,
};
