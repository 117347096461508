module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n<hr class="hidden">\n<div class="nav-container compare-doc-finder initial-state" id="compare-doc-finder-left">\n    <ul class="nav" role="menubar">\n        <li class="toolbar-instructions">\n            <span class="toolbar-item compare-instructions">\n                <span class="label">Choose from recent documents below, or</span>\n            </span>\n        </li>\n        <li class="toolbar-tod" role="presentation">\n            <a class="toolbar-item btn opens-pw" href="'+
((__t=( app.urls.tod ))==null?'':_.escape(__t))+
'?event=document:open&args='+
((__t=( target ))==null?'':_.escape(__t))+
'" title="Open Table of Documents in a new window" role="menuitem">\n                <span class="icon"><span class="picon picon-tod"></span></span>\n                <span class="label">Table of Documents</span>\n            </a>\n        </li>\n        <li id="compare-toolbar-qsearch-'+
((__t=( target ))==null?'':_.escape(__t))+
'" class="toolbar-qsearch" role="presentation">\n        </li>      \n    </ul>\n</div>\n<div class="inner">\n    <div class="compare-doc-chooser">\n        <h2>Choose from recent documents</h2>\n        <ul></ul>\n    </div>\n</div>\n';
}
return __p;
};
