import React, { useState } from 'react';
import PropTypes from 'prop-types';

import models from '../../models';

import UserList from './UserList';
import UserFilter from './UserFilter';
import { UserTableFlags } from './Flags/UserFlags';

import { useTableReducerWithCookies } from '../customHooks';

import UserAdd from './UserAdd';
import { TableContextWrapper, TABLE_ACTION } from '../FormControls/Table/TableContext';
import { initialTableState } from '../FormControls/Table/utils';
import ImportUsers from './ImportUsers';

//#region helpers

// Filter and sort column are added because that's what they want to see on arriving to a page
const defaultUserTableState = {
    ...initialTableState,
    sortColumn: '-updated_at',
    filter: {
        'flags[]': app.user.has_perm('main.flag_user') ? ['all'] : [],
    },
};

//#endregion

//#region main

const UserManage = ({ currentOrganisation }) => {
    const tableStateOverwrites = {
        //If this is a page for just one organisation, add it's value to state
        meta: {
            current_org: currentOrganisation?.id,
        },
    };

    const [tableState, dispatchTableState] = useTableReducerWithCookies({
        tableName: 'userManage',
        defaultTableState: defaultUserTableState,
        tableStateOverwrites,
    });

    const usersModel = new models.Users();

    const [flagState, changeFlags] = useState({
        flagShown: false,
    });
    const hideFlags = () => changeFlags({ flagShown: false });
    const showFlags = (selectedId) => {
        changeFlags({ flagShown: true, selectedId });
    };

    const setDirty = () => dispatchTableState({ type: TABLE_ACTION.SET_DIRTY });

    const additionalButtons = (
        <div className="admin-header">
            <div className="add-user-btns-container">
                {app.user.has_perm('main.add_user') && !currentOrganisation && (
                    <UserAdd setDirty={setDirty} className="btn-sm" />
                )}
                <ImportUsers />
            </div>
        </div>
    );

    return (
        <TableContextWrapper {...{ tableState, dispatchTableState }}>
            <>
                <div id="manage" className="rbs4 admin">
                    <UserFilter
                        {...{
                            usersModel,
                            currentOrganisation,
                            showFlags,
                        }}
                    />
                    <UserList
                        {...{
                            usersModel,
                            currentOrganisation,
                            showFlags,
                            additionalButtons,
                        }}
                    />
                </div>
                <UserTableFlags {...{ hideFlags, flagState }} />
            </>
        </TableContextWrapper>
    );
};

export default UserManage;

//#endregion

//#region proptypes

UserManage.propTypes = {
    currentOrganisation: PropTypes.object,
};

//#endregion
