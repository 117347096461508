/*
    Client side messages implementation

    - There must be at least one element somewhere on the page with a 'messages'
      class name, or override $el to give a custom container
    - Creates a global singleton 'messages' object
    - change the values of the message types (DEBUG, INFO, SUCCESS, WARNING and ERROR)
      to accomodate class names.
      e.g. Bootstrap calls 'error' 'danger', so override this object in the page and
      alter the Django setting:

      MESSAGE_TAGS = {
          messages.ERROR: 'danger',
      }

    - messages.addMessage
        - message: the HTML to render
        - type: the type of message
        - options: collection of options
            - ttl: time to live in milliseconds (optional, omitting this will keep
                   the message on the page until the user dismisses it)
    - messages.removeMessage
        - $msg: the element to remove
        - ttl: time to live in milliseconds
*/
define(['jquery', 'underscore', './templates/message.html'], function ($, _, MessageMarkup) {
    function Messages(selector = '.messages', isAlert = true) {
        this.$el = $(selector);
        this._template = MessageMarkup;
        this.isAlert = isAlert;

        this.DEBUG = 'debug';
        this.INFO = 'info';
        this.SUCCESS = 'success';
        this.WARNING = 'warning';
        this.ERROR = 'error';

        this.options = {
            ttl: null,
        };

        this.addMessage = function (message, type, options) {
            options = _.extend(_.clone(this.options), options || {});
            var context = {
                type: type,
                message: message,
                isAlert: this.isAlert,
            };

            var $msg = $(this._template(context)).prependTo(this.$el);
            if (this.isAlert) {
                $msg.fadeIn();
            } else {
                $msg.addClass('show');
            }

            const that = this;
            $msg.find('button').on('click', () => that.closeMessage($msg, 10));

            if (options.ttl) {
                this.removeMessage($msg, options.ttl);
            }

            return $msg;
        };

        this.closeMessage = function ($msg) {
            $msg.remove();
        };

        this.removeMessage = function ($msg, ttl) {
            $msg.delay(ttl).fadeOut(function () {
                $(this).remove();
            });
        };

        this.removeMessages = function () {
            this.$el.children().each(function (i, el) {
                window.messages.removeMessage($(el), window.messages.options['ttl']);
            });
        };

        this.clear = function () {
            this.$el.html('');
            return this;
        };

        this.debug = function (message, options) {
            return this.addMessage(message, this.DEBUG, options);
        };
        this.info = function (message, options) {
            return this.addMessage(message, this.INFO, options);
        };
        this.success = function (message, options) {
            return this.addMessage(message, this.SUCCESS, options);
        };
        this.warning = function (message, options) {
            return this.addMessage(message, this.WARNING, options);
        };
        this.error = function (message, options) {
            return this.addMessage(message, this.ERROR, options);
        };
    }

    if (typeof window.messages !== 'object') {
        window.messages = new Messages();
    }

    if (typeof window.toaster !== 'object') {
        window.toaster = new Messages('#toaster-notifications', false);
    }
});
