import React from 'react';
import PropTypes from 'prop-types';

import PiconIcon from '../../FormControls/Icons/PiconIcon';
import { openDocument, annotationPropTypes, confirmAnnoDelete } from '../utils';

const ViewAnnotationActions = ({ item, hideModal, setLoading, setDirty, includeGoTo }) => {
    const iconColour = app.darkMode ? 'lighter-blue' : 'blue';

    const deleteItem = async () => {
        setLoading(true);
        const confirmed = await confirmAnnoDelete(item);

        if (!confirmed) {
            setLoading(false);
            return false;
        }
        try {
            await item.model.destroy();
            setDirty();
            hideModal();
        } catch (error) {
            setLoading(false);
            toaster.error('Could not delete the annotation');
        }
    };

    return (
        <div className="footer-buttons">
            {item.can_edit && (
                <button
                    type="button"
                    className={`btn btn-link${item.can_edit ? '' : ' disabled'} anno-delete`}
                    onClick={() => deleteItem()}
                >
                    <PiconIcon iconName="delete" className={`${iconColour} left`} />
                    Delete
                </button>
            )}
            {includeGoTo && (
                <button
                    type="button"
                    className="btn btn-link"
                    onClick={(evt) => openDocument(evt, item)}
                >
                    <PiconIcon iconName="goto" className={`${iconColour} left`} />
                    Go To
                </button>
            )}
        </div>
    );
};

export default ViewAnnotationActions;

ViewAnnotationActions.propTypes = {
    item: PropTypes.shape(annotationPropTypes),
    setLoading: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
    setDirty: PropTypes.func.isRequired,
    includeGoTo: PropTypes.bool,
};
