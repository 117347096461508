/* 
    Client side highlighting of search terms in documents and notes
*/

const { escapeDOMId } = require('./index');

/* 
    Creates the Regex used in the document for highlighting
*/
const getTerms = (norris, sectionResults) => {
    var hitMap = {};
    // matches zero or more spaces
    // followed by zero or more tags
    // followed by zero or more spaces
    var reTag =
        '(?:\\s*(?:(?:<span class="before">.*?</span>(\\s*|(?:[.?*+^$[\\]\\\\\\(\\){}|-])?)?)?(?:<span class="after">.*?</span>(\\s*|(?:[.?*+^$[\\]\\\\\\(\\){}|-])?)?)?(?:<\\/?\\w+\\s*[^>]*>)?)*\\s*)';

    sectionResults.each(
        _.bind(function (result) {
            var section = result.get('sec_id');
            var doc_fam = result.get('doc_primary_family_title');
            var block = 0;
            if (section != 'top' && norris.ids[section]) block = norris.ids[section].block;

            if (!_.has(hitMap, block)) {
                hitMap[block] = [];
            }
            if (result.get('highlighting').hits == 0) {
                result.get('highlighting').snippets.push({
                    field: 'sec_text_exact',
                    fragment: $('<div>').html(this.sectionResults.qs.q),
                });
            }
            _.each(result.get('highlighting').snippets, function (snippet) {
                var $hits = $('<div>').html(snippet.fragment).find('.hit');
                _.each($hits, function (hit) {
                    var escaped = _.escapeRegExp($(hit).html().replace(/^[(]/, '')); // Remove bracket left by Solr syntax highlighter

                    var first_term = false;

                    var terms = [];
                    _.each(escaped.split(' '), function (term, idx) {
                        var i_term = '';
                        var word_search = RegExp(/\b.*\b/g);
                        var sa = word_search.exec(term);

                        // check for non-word beginning
                        if (sa.index == 0) {
                            // check for number
                            if (term.match(/^\d+/)) {
                                // don't allow decimals or longer number
                                if (idx != 0) {
                                    i_term = '(' + term;
                                } else {
                                    i_term = '(>|>[^<]*?[^\\d.<]+)((' + term;
                                    first_term = true;
                                }
                            } else {
                                if (idx == 0) {
                                    i_term = '(?!([^<]+)?>)((\\b' + term;
                                    first_term = true;
                                } else {
                                    i_term = '(\\b' + term;
                                }
                            }
                        } else {
                            var before = term.slice(0, sa.index);
                            var after = term.slice(sa.index);
                            var n_term =
                                before +
                                '(?:<span.*class="defterm"[^>]*>)?(?:<span class="before">.*</span>)?\\s*' +
                                after;
                            if (idx == 0) {
                                i_term = '(>[^<]*?)((' + n_term;
                                first_term = true;
                            } else {
                                i_term = '(' + n_term;
                            }
                        }
                        // check for non-word ending
                        if (word_search.lastIndex == term.length) {
                            i_term = i_term.includes('(\\b') ? i_term + '\\b' : i_term; // if it doesn't have \\b at the beginning, it shouldn't have it at the end
                        } else {
                            before = i_term.slice(
                                0,
                                i_term.length - (term.length - word_search.lastIndex),
                            );
                            after = i_term.slice(
                                i_term.length - (term.length - word_search.lastIndex),
                            );
                            i_term =
                                before +
                                '(?:<span class="after">.*?</span>)?(?:</span>)?(?:<\\/?\\w+\\s*[^>]*>)?\\s*' +
                                after;
                        }
                        i_term = i_term + ')';
                        terms[idx] = i_term;
                    });
                    var regex = new RegExp(terms.join(reTag) + ')', 'gm');
                    var replace =
                        '<span class="hit" title="Search hit"><span class="hit-icon"></span>$1</span>';
                    if (first_term) {
                        replace =
                            '$1<span style="display: inline-block" class="hit" title="Search hit"><span class="hit-icon"></span>$2</span>';
                    }

                    hitMap[block].push({
                        section: section,
                        type: snippet.field,
                        term: escaped,
                        regex: regex,
                        replace: replace,
                        doc_type: doc_fam,
                    });
                });
            });
            hitMap[block] = _.uniqWith(hitMap[block], _.isEqual);
            hitMap[block] = _.sortBy(hitMap[block], function (item) {
                return -1 * item.term.length;
            });
        }, this),
    );
    return hitMap;
};

/* 
    Uses the regex create in getTerms to find the matches within the document and apply highlighting
*/
const highlightTerms = ($block, chunkID, hitMap, norris) => {
    // making sure hits are deeply nested
    $block.find('.hit').replaceWith(function () {
        $(this).find('.hit-icon').remove();
        return $(this).html();
    });

    if (!_.isEmpty(hitMap)) {
        if (hitMap[chunkID]) {
            hitMap[chunkID].forEach((searchHit) => {
                var section = escapeDOMId(searchHit['section']);
                if (searchHit['type'] == 'sec_heading') {
                    $block
                        .find('#' + section + ', .' + section)
                        .contents()
                        .find('.title, .heading')
                        .addBack('.title, .heading')
                        .html(function (index, html) {
                            html = html.replace(/[\r\n]+/g, ' ');
                            return html.replace(searchHit['regex'], searchHit['replace']);
                        });
                }

                // TODO: define these names somewhere
                if (searchHit['type'] == 'sec_text_exact') {
                    // News articles, surveys, and Law Reports aren't sectioned in same way as other docs, so the below method doesn't apply hit highlighting
                    var nonSectionedDocs = ['news', 'survey', 'law reports'];
                    if (
                        !nonSectionedDocs.some((family) =>
                            new RegExp(family, 'gi').test(searchHit['doc_type']),
                        )
                    ) {
                        // This looks for the section as id or as class on the block, as well as if it's in refer or if the parent_id is reference because of how Law Reports are filtered into solr
                        $block
                            .find(`#${section}, .${section}, .refer, [parent_id=reference]`)
                            .contents()
                            .not('.dd-flag')
                            .html(function (index, html) {
                                html = html.replace(/[\r\n]+/g, ' ');
                                return html.replace(searchHit['regex'], searchHit['replace']);
                            });
                    } else {
                        $block.children().html(function (index, html) {
                            html = html.replace(/[\r\n]+/g, ' ');
                            return html.replace(searchHit['regex'], searchHit['replace']);
                        });
                    }
                }

                if (searchHit['type'] == 'sec_notes') {
                    var $note = $block.find('#' + section + ', .' + section).find('.dd-flag-note');
                    $note.after(
                        `<span class="hit invisible" style="position: absolute;" title="Search hit in Notes (view Notes to reveal)"><span class="hit-icon"></span></span>`,
                    );
                    $note.nextAll().css('top', $note.css('top'));
                }
            });
        }

        // add with-hits class to all found parent sections
        $block.find('.hit').parents('.tts').addClass('with-hits');

        // append adjacent sibling that can be used to hold hidden
        // search hits icon, as span.amend has display:none and
        // can't show an icon from within
        $block
            .find('span.amend .hit')
            .closest('span.amend')
            .after('<span class="tts-hidden-sibling" title="Hidden search hit"></span>');

        _.each(
            $block.find(
                '.section.with-hits.tts-hidden, .schedule.with-hits.tts-hidden, .sec-grp.with-hits.tts-hidden',
            ),
            _.bind(function (el) {
                $(el).after(
                    '<span class="tts-hidden-sibling" title="Hidden search hit in ' +
                        norris.getLabelFromId($(el).attr('id')) +
                        '"></span>',
                );
            }, this),
        );
    }
};

module.exports = {
    getTerms,
    highlightTerms,
};
