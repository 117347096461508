import React from 'react';

import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';

const SelectedLister = ({ selected, property, type }) => {
    const count = selected.length;
    return (
        <div className="selected-list-container">
            <Form.Label>{`${count} ${type} selected`}</Form.Label>
            <div className="selected-list">
                {selected.map((item, index) => {
                    return <p key={index}>{item[property]}</p>;
                })}
            </div>
        </div>
    );
};

export default SelectedLister;

SelectedLister.propTypes = {
    selected: PropTypes.arrayOf(PropTypes.object).isRequired,
    property: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
};
