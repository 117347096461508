import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import models from '../../models';

import DocumentList from './DocumentList';
import DocumentFilter from './DocumentFilter';
import { useTableReducer, useInterval } from '../customHooks';
import { TableContextWrapper, TABLE_ACTION } from '../FormControls/Table/TableContext';
import { initialTableState } from '../FormControls/Table/utils';
import DocumentUploadProgress from './DocumentUploadProgress';
import {
    getPercentageCompletedUploads,
    getPercentageIndexedUploads,
    getPercentageMetadataUploads,
} from './utils';

const REFRESH_RATE = 1000;

const defaultTableState = {
    ...initialTableState,
    sortColumn: '-uploaded_at',
};

const isMetadated = (result) => getPercentageMetadataUploads(result) === 100;
const isCompleted = (result) => getPercentageCompletedUploads(result) === 100;
const isIndexed = (result) => getPercentageIndexedUploads(result) === 100;

const DocumentUpload = ({ uploadID }) => {
    const [upload, setUpload] = useState();
    const [delay, setDelay] = useState(REFRESH_RATE);
    const [metadated, setMetadated] = useState(false);
    const [completed, setCompleted] = useState(false);

    const uploadModel = new models.DocumentUpload({ uuid: uploadID });

    const tableStateOverwrites = {
        //This is the page that shows only documents in the upload
        meta: {
            upload_id: uploadID,
            show_published: true,
        },
    };
    const [tableState, dispatchTableState] = useTableReducer({
        defaultTableState,
        tableStateOverwrites,
    });

    const fetchUpload = async () => {
        try {
            const result = await uploadModel.fetch();

            if (upload) {
                if (
                    upload.successful_count != result.successful_count ||
                    upload.failed_count != result.failed_count ||
                    upload.indexed_count != result.indexed_count ||
                    upload.metadata_count != result.metadata_count
                ) {
                    // Uploaded counts changed - refresh the document table and refresh rate
                    dispatchTableState({ type: TABLE_ACTION.SET_DIRTY });
                    setDelay(REFRESH_RATE);
                } else {
                    // Nothing changed. Restart delay
                    setDelay(delay);
                }
            }

            // All documents have complete their metadata.
            if (isMetadated(result)) {
                if (!metadated) {
                    messages.clear();
                    messages.info(
                        'Processing items. Please wait, page will refresh when upload is completed.',
                        { ttl: null },
                    );
                    setMetadated(true);
                }
            }

            // All documents are processed.
            if (isCompleted(result)) {
                if (!completed) {
                    messages.clear();
                    messages.info(
                        'Indexing items. Please wait, page will refresh when upload is completed.',
                        { ttl: null },
                    );
                    setCompleted(true);
                }
            }

            // All documents are indexed . Stop checking for changes
            if (isIndexed(result)) {
                messages.clear();
                messages.success('Document upload completed.', { ttl: null });
                setDelay(null);
            }

            setUpload(result);
            return result;
        } catch (err) {
            console.error(err);
            toaster.error('Document upload could not be retrieved');
        }
    };

    // Polling for results. TODO could replace with sockets
    useInterval(async () => {
        await fetchUpload();
    }, delay);

    useEffect(() => {
        (async () => {
            const result = await fetchUpload();
            if (!isCompleted(result)) {
                messages.info(
                    'Processing items. Please wait, page will refresh when upload is completed.',
                    { ttl: null },
                );
            }
        })();
    }, []);

    const documentSummariesModel = new models.DocumentInstanceSummaries();

    return (
        <TableContextWrapper
            {...{
                tableState,
                dispatchTableState,
                options: {
                    idPropertyName: 'uuid',
                    sortable: true,
                    selectable: false,
                    pageable: true,
                },
            }}
        >
            <div id="manage" className="rbs4 admin">
                <DocumentUploadProgress upload={upload} />
                <DocumentFilter
                    documentSummariesModel={documentSummariesModel}
                    showPublish={true}
                />
                <DocumentList documentSummariesModel={documentSummariesModel} />
            </div>
        </TableContextWrapper>
    );
};

export default DocumentUpload;

DocumentUpload.propTypes = {
    uploadID: PropTypes.string.isRequired,
};
