import React from 'react';
import PropTypes from 'prop-types';

import { formatHtmlId } from '../utils';

const ToDDocList = ({ items, nested, title }) => {
    return (
        <>
            {items &&
                Object.entries(items).map(([itemKey, values]) => {
                    const key = itemKey.replace('meta::', '');
                    const itemId = title ? `${title}-${key}` : key;
                    if (Array.isArray(values)) {
                        return (
                            <React.Fragment key={`tod-section-${formatHtmlId(itemId)}`}>
                                <div
                                    id={`tod-section-header-${formatHtmlId(itemId)}`}
                                    className={`tod-section-header ${
                                        nested > 0 ? 'todlevel' + nested : ''
                                    }`}
                                >
                                    {key}
                                </div>
                                <ul
                                    className={`tod-doc-list ${
                                        nested > 1 ? 'listlevel' + nested : ''
                                    }`}
                                >
                                    {values.map((doc, index) => (
                                        <li
                                            className="no-bullets"
                                            key={`${doc.doc_id}-${doc.doc_filter}/${index}`}
                                            ref={doc?.ref}
                                            dangerouslySetInnerHTML={{
                                                __html: doc.display?.replace(
                                                    '<a',
                                                    `<a data-doc_id="${doc.doc_id}" data-instance_id="${doc.doc_instance_id}" href="${app.urls.documents}/${doc.doc_id}"`,
                                                ),
                                            }}
                                        ></li>
                                    ))}
                                </ul>
                            </React.Fragment>
                        );
                    } else {
                        const itemId = title ? `${title}-${key}` : key;
                        return (
                            <React.Fragment key={`nested-tod-section-${itemId}`}>
                                <div
                                    id={`tod-section-header-${formatHtmlId(itemId)}`}
                                    className={`${
                                        nested > 0 ? 'todlevel' + nested : 'tod-list-title'
                                    }`}
                                >
                                    {key}
                                </div>
                                <ToDDocList items={values} nested={nested + 1} title={key} />
                            </React.Fragment>
                        );
                    }
                })}
        </>
    );
};

export default ToDDocList;

ToDDocList.defaultProps = {
    nested: 0,
};

ToDDocList.propTypes = {
    items: PropTypes.object.isRequired,
    nested: PropTypes.number,
    title: PropTypes.string,
};
