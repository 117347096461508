import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import PiconIcon from '../FormControls/Icons/PiconIcon';
import { WithLoadingOverlay } from '../FormControls/WithLoading';
import Table from 'react-bootstrap/Table';

import { readFileAsText, csvFromText } from '../utils';
import FileInput from '../FormControls/FileInput';
import models from '../../models';
import Select from '../FormControls/Select/Select';
import {
    getDefaultUserGroup,
    getOrganisationsList,
    getPreAlerts,
    getUserGroupAlertMessage,
    getUserGroups,
} from '../UserDetails/utils';
import MultiSelect from '../FormControls/Select/MultiSelect';
import MultipleGroupWarning from '../UserDetails/MultipleGroupWarning';
import confirm from '../FormControls/ConfirmModal/ConfirmModalService';

const UserImport = () => {
    const [files, setFiles] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [template, setTemplate] = useState();
    const [organisations, setOrganisations] = useState([]);
    const [organisation, setOrganisation] = useState();
    const [userGroups, setUserGroups] = useState([]);
    const [userGroup, setUserGroup] = useState([]);
    const [users, setUsers] = useState([]);
    const [metadata, setMetadata] = useState([]);
    const [isValid, setIsValid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [preAlerts, setPreAlerts] = useState([]);

    useEffect(() => {
        (async () => {
            const emailTemplateCollection = new models.EmailTemplates();
            await emailTemplateCollection.fetchByLabel(
                app.emailTemplateLabels.ACTIVATION_EMAIL,
                true,
            );
            setTemplates(emailTemplateCollection.toJSON());
            const defaultTemplate = emailTemplateCollection
                .toJSON()
                .find((template) =>
                    template.slug.includes(app.emailTemplateLabels.ACTIVATION_EMAIL),
                );
            setTemplate({ label: defaultTemplate.name, value: defaultTemplate.slug });

            const metadataModel = new models.Metadata();
            const metadata = await metadataModel.fetch();
            setOrganisations(metadata.organisations);
            setMetadata(metadata);
        })();
    }, []);

    useEffect(() => {
        doUpload();
    }, [files]);

    const headers = [
        { title: 'First name', dataField: 'first_name' },
        { title: 'Last name', dataField: 'last_name' },
        { title: 'Email', dataField: 'email' },
    ];
    // Optional headers will not display in the table, but will also not cause the csv upload to fail.
    const optionalHeaders = ['office'];

    const getTableColumns = (item) =>
        headers.map((header) => (
            // this is added when the rows are used
            // eslint-disable-next-line react/jsx-key
            <span
                title={
                    item.errors && item.errors[header.dataField]
                        ? item.errors[header.dataField].join(' ')
                        : null
                }
            >
                {item.errors && item.errors[header.dataField] && (
                    <>
                        <PiconIcon iconName="warning-sign" className="red right" />{' '}
                    </>
                )}
                {item[header.dataField]}
            </span>
        ));

    const rows =
        users?.map((item) => {
            return { id: item.id, cols: getTableColumns(item) };
        }) ?? [];

    const doUpload = async () => {
        if (files.length) {
            setLoading(true);

            try {
                const fileContents = await readFileAsText(files[0]);
                const fileData = csvFromText(
                    fileContents,
                    headers.map((header) => header.dataField),
                    optionalHeaders,
                );
                const cleanedData = fileData.map((user) => {
                    const cleaned = user.email.trim();
                    user.email = cleaned;
                    return user;
                });
                const userCollection = new models.Users(cleanedData);
                const validationData = await userCollection.validateBatch();
                const userData = fileData.map((user, i) => ({
                    ...user,
                    errors: validationData[i],
                }));

                setUsers(userData);
                setIsValid(!validationData);

                if (validationData) {
                    toaster.error('There were some issues with the records in the file');
                }
            } catch (err) {
                const message =
                    err.message === 'Mismatched headers'
                        ? ' - File headers do not match table headers'
                        : err.message;
                setErrorMessage(message);
                toaster.error(`There was a problem reading the file${message}`);
                setUsers([]);
            } finally {
                setLoading(false);
            }
        } else {
            setUsers([]);
        }
    };

    const doImport = async () => {
        if (preAlerts.length > 0) {
            // trigger confirm modal for pre-alerts
            const isMultiple = preAlerts.length > 1;
            const confirmed = await confirm({
                title: `User Group Warning${isMultiple ? 's' : ''}`,
                message: getUserGroupAlertMessage(isMultiple, preAlerts),
                centered: true,
            });

            if (!confirmed) return false;
        }

        setLoading(true);

        const userCollection = new models.Users(
            users.map((user) => ({
                ...user,
                organisation: organisation.value,
                user_groups: userGroup.map((group) => {
                    return { id: group.value };
                }),
            })),
        );

        try {
            const result = await userCollection.saveBatch({
                email_template: template.value,
            });
            if (result.redirect) {
                document.location.href = result.redirect;
            }
        } catch (err) {
            if (err?.responseJSON?.errors) {
                toaster.error('Please correct the errors in the file.');
            } else {
                console.error(err);
                toaster.error('There was a problem importing the users');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleDownload = async () => {
        const response = await fetch('/static/sample_user_upload_file.xlsx', {
            headers: {
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
        });
        const blob = await response.arrayBuffer();
        const link = document.createElement('a');
        const fileName = 'SampleUserUploadFile.xlsx';
        link.href = URL.createObjectURL(new Blob([blob]));
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    return (
        <div id="manage" className="rbs4 admin">
            <div>
                <Form id="admin-filter">
                    {userGroups?.length > 1 && (
                        <div className="admin-group-warning">
                            <MultipleGroupWarning />
                        </div>
                    )}
                    <Form.Row>
                        <Col>
                            <FileInput value={files} onChange={setFiles} />
                        </Col>
                        <Col>
                            <Select
                                onChange={(item) => setTemplate(item)}
                                options={templates.map((item) => ({
                                    label: item.name,
                                    value: item.slug,
                                }))}
                                value={template}
                                name="template"
                                placeholder="Select a template"
                            />
                        </Col>
                        <Col>
                            <Select
                                onChange={(item) => {
                                    const defaultGroup = getDefaultUserGroup(item.value, metadata);
                                    setOrganisation(item);
                                    setUserGroups(
                                        getUserGroups(metadata?.subscription_groups, item.value),
                                    );
                                    setUserGroup(defaultGroup);
                                    setPreAlerts(getPreAlerts(defaultGroup));
                                }}
                                options={getOrganisationsList(organisations)}
                                placeholder="Select an organisation"
                                name="organisation"
                            />
                        </Col>
                        <Col>
                            <MultiSelect
                                onChange={(group) => {
                                    setUserGroup(group);
                                    setPreAlerts(getPreAlerts(group));
                                }}
                                options={userGroups}
                                value={userGroup}
                                placeholder="Select user groups"
                            />
                        </Col>
                        <Col xs={1}>
                            <div className="items-count">
                                {users.length > 0 &&
                                    `${users.length} User${users.length === 1 ? '' : 's'}`}
                            </div>
                        </Col>
                        <Col xs="4">
                            <Button
                                as="input"
                                type="button"
                                onClick={doImport}
                                value="Import users"
                                disabled={
                                    users.length === 0 || !organisation || !template || !isValid
                                }
                            />
                        </Col>
                    </Form.Row>
                </Form>
            </div>
            {files.length === 0 && (
                <div className="admin-helper-text">
                    Import a CSV file with the following three fields in this order and with these{' '}
                    <b>exact, lower case</b> column headers:{' '}
                    <ul>
                        <li>first_name</li>
                        <li>last_name</li>
                        <li>email</li>
                    </ul>
                    <a href="#" onClick={() => handleDownload()}>
                        Download a sample Excel file
                    </a>
                </div>
            )}

            {files.length > 0 && (
                <WithLoadingOverlay loading={loading}>
                    <Table responsive>
                        <thead>
                            <tr>
                                {headers.map((header, index) => (
                                    <th key={index}>{header.title}</th>
                                ))}
                            </tr>
                        </thead>

                        <tbody>
                            {rows.length == 0 && (
                                <tr>
                                    <td colSpan={headers.length}>
                                        {errorMessage.length ? `${errorMessage}` : '- No data -'}
                                    </td>
                                </tr>
                            )}
                            {rows.map((row, i) => {
                                return (
                                    <tr key={i}>
                                        {row.cols.map((column, j) => (
                                            <td key={j}>{column}</td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </WithLoadingOverlay>
            )}
        </div>
    );
};

export default UserImport;

UserImport.propTypes = {};

//#endregion
