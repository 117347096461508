module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<hr class="hidden" />\n<div class="inner">\n  <h1 class="sr-only">Document view</h1>\n  <div class="doc-title" title="'+
((__t=( title ))==null?'':_.escape(__t))+
'">\n    <span class="title-text">'+
((__t=( title ))==null?'':_.escape(__t))+
' </span>\n    <div id="title-favourite"></div>\n  </div>\n\n  <div class="titlebar-tools">\n    <div class="view-date">\n      <label class="label sr-only" for="viewdate">View date</label>\n      <input\n        class="form-control has-modal-dd-date-from disabled"\n        disabled="disabled"\n        type="text"\n        value="'+
((__t=( ttViewDate ))==null?'':_.escape(__t))+
'"\n        placeholder="'+
((__t=( app.settings.dateFormat ))==null?'':_.escape(__t))+
'"\n        id="has-modal-dd-date"\n        title=""\n        aria-label="view date input"\n      />\n\n      <span class="betweendates-separator" style="display: none">&mdash;</span>\n\n      <label class="label sr-only" for="viewdate-to">To date</label>\n      <input\n        class="form-control has-modal-dd-date-to disabled"\n        disabled="disabled"\n        type="text"\n        value="'+
((__t=( ttViewDate2 ))==null?'':_.escape(__t))+
'"\n        placeholder="'+
((__t=( app.settings.dateFormat ))==null?'':_.escape(__t))+
'"\n        id="has-modal-dd-date-to"\n        title="To date (inclusive)"\n        style="display: none"\n      />\n    </div>\n    <button\n      class="toolbar-item btn btn-sig-date"\n      disabled="disabled"\n      id="has-modal-mv-sig"\n      aria-haspopup="true"\n    >\n      <span class="icon icon-mid"><span class="picon picon-calendar"></span></span>\n      <span class="label">Significant dates</span>\n    </button>\n  </div>\n  <div class="pane-tools">\n    <div class="btn-group">\n      <label class="btn toolbar-item toggle-search-hits active"><input type="checkbox" checked="checked" />Toggle search terms</label>\n    </div>\n    <div class="btn-group">\n      <button class="btn toolbar-item pane-close" id="close-doc-pane" title="List search results">\n        <span class="icon icon-sm"><span class="picon picon-arrow-left"></span></span>\n        <span class="label">List search results</span>\n      </button>\n      <button\n        type="button"\n        class="btn toolbar-item pane-open-new"\n        title="Show this document in a new tab"\n      >\n        <span class="icon icon-sm"><span class="picon picon-goto"></span></span>\n      </button>\n    </div>\n  </div>\n</div>\n';
}
return __p;
};
