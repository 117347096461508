import React, { useState, useContext } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import PropTypes from 'prop-types';

import PiconIcon from '../Icons/PiconIcon';
import MultiSelect from '../Select/MultiSelect';
import { DownCaretIcon } from '../Icons/CaretIcon';
import { getSelectedCount } from './utils';
import { TableContext, TABLE_ACTION } from './TableContext';
import { WithLoadingOverlay } from '../WithLoading';
import Select, { SELECT_SIZE } from '../Select/Select';

//#region constants

const INPUT_DELAY = 700;

export const FILTER_TYPES = {
    MULTISELECT: 0,
    SEARCH: 1,
    SELECT: 2,
    NESTEDSELECT: 3,
};
Object.freeze(FILTER_TYPES);

export const NESTED_SELECT_TYPES = {
    CHILD: 'child',
    GRANDCHILD: 'grandchild',
};
Object.freeze(NESTED_SELECT_TYPES);

//#endregion

//#region helpers

const lazySetTextField = _.debounce((filter, filterValue, fn) => {
    fn({ ...filter, search: filterValue });
}, INPUT_DELAY);

//#endregion

const Filter = ({ actions, filterFields, labels }) => {
    const { tableState, dispatchTableState } = useContext(TableContext);

    const [searchFieldState, setSearchFieldState] = useState(tableState.filter.search);

    const setFilter = (filter) => {
        dispatchTableState({ type: TABLE_ACTION.SET_FILTER, filter });
    };

    const handleMultiselectChange = (selectedOption, action) => {
        setFilter({ ...tableState.filter, [action.name]: selectedOption?.map((s) => s.value) });
    };

    const handleNestedSelectChange = (selectedOption, action) => {
        let values = selectedOption?.map((s) => s.value);
        const parents = selectedOption?.reduce((obj, option) => {
            if (option?.parentValue) {
                option.parentValue in obj
                    ? (obj[option.parentValue] = [...obj[option.parentValue], option.value])
                    : (obj[option.parentValue] = [option.value]);
            }
            return obj;
        }, {});
        if (values && values.length > 1) {
            if (
                typeof values[0] === 'string' &&
                (values[0].includes('all') || values[0].includes('_shared'))
            ) {
                // we had selected All or all of a section, but now we're refining
                values.shift();
            } else if (values.includes('all')) {
                // we had selected something specific, but now we've selected All
                values.splice(0, values.length - 1);
            } else if (Object.keys(parents).length) {
                const startIndex = values.findIndex(
                    (value) =>
                        typeof value === 'string' &&
                        (value.includes('all') || value.includes('_shared')),
                );
                const children = values.reduce((arr, value) => {
                    if (Object.hasOwn(parents, value)) {
                        return [...arr, ...parents[value]];
                    }
                    return arr;
                }, []);
                if (startIndex === values.length - 1) {
                    // we had selected a specific item in a section, but now we've selected the whole section
                    values = values.filter((value) => !children.includes(value));
                } else if (startIndex >= 0 && children.length) {
                    // we had selected a whole section, but now we want a specific item in the section
                    values.splice(startIndex, 1);
                }
            }
        }
        setFilter({ ...tableState.filter, [action.name]: values });
    };

    const handleSelectChange = (selectedOption, action) => {
        setFilter({ ...tableState.filter, [action.name]: selectedOption.value });
    };

    const handleTextSearchChange = (e) => {
        //Show users input into text field immediately, but delay (debounce) search
        setSearchFieldState(e.target.value);
        lazySetTextField(tableState.filter, e.target.value, setFilter);
    };

    const clearFilters = () => {
        dispatchTableState({ type: TABLE_ACTION.CLEAR_FILTERS });
        setSearchFieldState('');
    };

    const selectedCount = getSelectedCount(tableState);

    const someRowsSelected = selectedCount > 0;

    return (
        <div id="admin-filter" role="search">
            <Form.Row>
                {actions?.actionItems.length > 0 && (
                    <Col xs={1}>
                        <Dropdown className="actions-dropdown">
                            <Dropdown.Toggle id="dropdown-actions" className="btn-sm">
                                <PiconIcon className="white left" iconName="cog" />
                                <span className="filter-actions">Actions</span>
                                <DownCaretIcon />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <WithLoadingOverlay loading={actions.loading}>
                                    {actions.actionItems.map((item, i) => {
                                        return (
                                            <Dropdown.Item
                                                disabled={!someRowsSelected || item.disabled}
                                                key={i}
                                                onClick={() => actions.doAction(item.key)}
                                            >
                                                {item.text}
                                            </Dropdown.Item>
                                        );
                                    })}
                                </WithLoadingOverlay>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                )}
                {labels?.countLabel && (
                    <Col xs={1}>
                        <div className="items-count">{labels.countLabel}</div>
                    </Col>
                )}
                {filterFields.map((filterField, i) => {
                    const { placeholder, name, options } = filterField;

                    switch (filterField.type) {
                        case FILTER_TYPES.MULTISELECT:
                            return (
                                <Col key={i}>
                                    <MultiSelect
                                        onChange={handleMultiselectChange}
                                        options={options}
                                        placeholder={placeholder}
                                        name={name}
                                        value={options.filter((o) =>
                                            tableState.filter[name]?.includes(o.value),
                                        )}
                                        key={i}
                                        size={SELECT_SIZE.SMALL}
                                    />
                                </Col>
                            );
                        case FILTER_TYPES.SELECT:
                            return (
                                <Col key={i}>
                                    <Select
                                        onChange={handleSelectChange}
                                        options={options}
                                        placeholder={placeholder}
                                        name={name}
                                        value={options.filter((o) =>
                                            tableState.filter[name]?.includes(o.value),
                                        )}
                                        key={i}
                                    />
                                </Col>
                            );
                        case FILTER_TYPES.SEARCH:
                            return (
                                <Col key={i}>
                                    <InputGroup className="search">
                                        <PiconIcon className="grey internal" iconName="search" />
                                        <Form.Control
                                            onChange={handleTextSearchChange}
                                            placeholder={placeholder}
                                            name="search"
                                            value={searchFieldState ?? ''}
                                            type="search"
                                            key={i}
                                            className="sm"
                                        />
                                    </InputGroup>
                                </Col>
                            );
                        case FILTER_TYPES.NESTEDSELECT:
                            // This currently only handles nesting of 2 depth
                            return (
                                <Col key={i}>
                                    <MultiSelect
                                        onChange={handleNestedSelectChange}
                                        options={options}
                                        placeholder={placeholder}
                                        name={name}
                                        value={options.filter((o) =>
                                            tableState.filter[name]?.includes(o.value),
                                        )}
                                        key={i}
                                        size={SELECT_SIZE.SMALL}
                                        selectAll={tableState.filter[name]?.includes('all')}
                                    />
                                </Col>
                            );

                        default:
                            toaster.error(`Invalid filter type ${filterField.type}`);
                            break;
                    }
                })}
                <Col xs={1}>
                    <Button size="sm" onClick={clearFilters}>
                        Reset Filters
                    </Button>
                </Col>
            </Form.Row>
        </div>
    );
};

export default Filter;

Filter.propTypes = {
    actions: PropTypes.shape({
        actionItems: PropTypes.arrayOf(
            PropTypes.shape({
                disabled: PropTypes.bool,
                key: PropTypes.string.isRequired,
                text: PropTypes.string.isRequired,
            }),
        ).isRequired,
        doAction: PropTypes.func.isRequired,
        loading: PropTypes.bool,
    }),
    filterFields: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.oneOf([
                FILTER_TYPES.MULTISELECT,
                FILTER_TYPES.SEARCH,
                FILTER_TYPES.SELECT,
                FILTER_TYPES.NESTEDSELECT,
            ]),
            options: PropTypes.arrayOf(
                PropTypes.shape({
                    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
                    label: PropTypes.string.isRequired,
                }),
            ),
            placeholder: PropTypes.string,
            name: PropTypes.string,
        }),
    ),
    labels: PropTypes.shape({
        countLabel: PropTypes.string,
    }),
};
