import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import UserAdderModal from '../UserDetails/UserAdderModal';

const UserAdd = ({ className, setDirty }) => {
    const [show, setShow] = useState(false);

    const handleShow = () => {
        setShow(!show);
    };

    return (
        <div className="add-user-container">
            <Button className={`${className}`} onClick={handleShow}>
                Add User
            </Button>
            {show && <UserAdderModal {...{ show, setDirty }} hideModal={handleShow} />}
        </div>
    );
};

export default UserAdd;

UserAdd.defaultProps = {
    setDirty: () => {},
};

UserAdd.propTypes = {
    className: PropTypes.string,
    setDirty: PropTypes.func.isRequired,
};
