/*
    Widget based on Bootstrap tooltip, into which you can add views
*/

define([
    '../views/helpers',
    '../templates/windowbox_widget.html',
    'bootstrap',
    'jqueryUI'
], function (helpers, windowboxTpl) {

    /*

    */
    var WindowboxView = helpers.TemplateView.extend({
        template: windowboxTpl,
        events: {
            'click .close': function(e) { this.widget.hide(e); }
        },
        constructor: function(options) {
            options['className'] = 'popover windowbox ' + (
                options['className'] || '');
            this.attributes = {
                tabindex: '-1',
                role: 'dialog',
                'aria-labelledby': options.id + '-label'
            }
            this.widget = options.widget;

            if(options.draggable) {
                this.once('render', this.initDraggable);
            }

            helpers.TemplateView.prototype.constructor.apply(this, arguments);
        },
        initDraggable: function() {
            this.$el.draggable({
                handle: '.windowbox-title'
            });
        },
        context: function() {
            return {
                id: this.options.id,
                title: this.options.title
            }
        }
    });

    /*

    */
    var Windowbox = function(element, options) {
        this.init('windowbox', element, options);
        this.contentView = options.view;
        this.contentView.widget = this;
        this.view = new WindowboxView(_.extend(options, { widget: this }))
        .render();
    }

    Windowbox.prototype = $.extend({}, $.fn.popover.Constructor.prototype);

    Windowbox.prototype.constructor = Windowbox;

    Windowbox.prototype.setContent = function () {
        this.view.assign(this.contentView, '.popover-content');
        this.view.$el.removeClass('fade top bottom left right in');
    }

    Windowbox.prototype.tip = function () {
        if(!this.$tip) {
            this.$tip = this.view.$el;
        }
        return this.$tip
    }

    Windowbox.prototype.hasContent = function () { return true; }

    Windowbox.prototype.getCalculatedOffset = function (placement, pos, actualWidth, actualHeight) {
        // TODO: this could be more intelligent
        if(placement == 'bottom right') {
            return { top: pos.top + pos.height, left: pos.left }
        }
        else if(placement == 'bottom left') {
            return { top: pos.top + pos.height, left: pos.left - actualWidth }
        }
        else {
            return $.fn.popover.Constructor.prototype.getCalculatedOffset.apply(this, arguments);
        }
    }

    Windowbox.prototype.hide = function () {
        var e = $.Event('hide.bs.' + this.type);

        this.$element.trigger(e);

        if (e.isDefaultPrevented()) return;

        this.view.$el
        .removeClass('in')
        .hide();

        this.$element.trigger('hidden.bs.' + this.type);

        return this;
    }

    $.fn.windowbox = function (option) {
        return this.each(function () {
            var $this = $(this);
            var data = $this.data('bs.windowbox');
            var options = typeof option == 'object' && option;

            if (!data) { $this.data('bs.windowbox', (data = new Windowbox(this, options))); }
            if (typeof option == 'string') { data[option](); }
        });
    }

    $.fn.windowbox.Constructor = Windowbox;

    return {
        Windowbox: Windowbox,
        WindowboxView: WindowboxView
    }
});