import React, { useState, useEffect } from 'react';

import models from '../../models';
import { useModel } from '../customHooks';
import SelectTopicsModal from '../Modals/SelectTopicsModal';
import ContentContainer, {
    USER_SETTING_CONTENT_TYPES,
} from '../ContentManagement/ContentContainer';
import DetailBlock from './DetailBlock';
import AlertFrequencyModal from '../Modals/AlertFrequencyModal';
import ContentManager from '../ContentManagement/ContentManager';
import { handleScrollToOption } from '../utils';

export default () => {
    const [newsTopics, setNewsTopics] = useState([]);
    const [newsFrequencyOptions, setNewsFrequencyOptions] = useState([]);
    const [documentFrequencyOptions, setDocumentFrequencyOptions] = useState([]);
    const [showTopicsModal, setShowTopicsModal] = useState(false);
    const [showFrequencyModal, setShowFrequencyModal] = useState({ show: false });
    const [preferences, setPreferences] = useState({
        news_alerts: false,
        news_alert_frequency: null,
        news_alert_topics_all: true,
        news_alert_topics: [],
        document_alerts: false,
        document_alert_frequency: null,
    });

    const currentUser = useModel(app.user);

    const userSettingsContentModel = new models.UserSettingsContent();

    useEffect(() => {
        (async () => {
            // Have to make variables for frequency options here because can't access
            // their updated states within this useEffect
            const newsAlertFrequencyCollection = new models.NewsAlertFrequency();
            const documentAlertFrequencyCollection = new models.DocumentAlertFrequency();
            const topicsCollection = new models.NewsTopics();
            const [newsAlertFrequencyOptions, documentAlertFrequencyOptions, data] =
                await Promise.all([
                    newsAlertFrequencyCollection.fetch(),
                    documentAlertFrequencyCollection.fetch(),
                    topicsCollection.fetch(),
                ]);
            setNewsFrequencyOptions(newsAlertFrequencyOptions);
            setDocumentFrequencyOptions(documentAlertFrequencyOptions);
            // Filter out mandatory topics as users shouldn't be able to see or unselect them
            setNewsTopics(data.filter((topic) => topic.is_mandatory === false));
            const commPref = currentUser.communication_prefs;
            setPreferences({
                ...commPref,
                // for the scrolling useEffect bellow
                retrieved: true,
                news_alert_frequency: commPref.news_alert_frequency
                    ? newsAlertFrequencyOptions.find(
                          (option) => option.slug == commPref.news_alert_frequency,
                      )
                    : newsAlertFrequencyOptions[0],
                document_alert_frequency: commPref.document_alert_frequency
                    ? documentAlertFrequencyOptions.find(
                          (option) => option.slug == commPref.document_alert_frequency,
                      )
                    : documentAlertFrequencyOptions[0],
            });
        })();
    }, [currentUser]);

    useEffect(() => {
        if (preferences.retrieved) {
            handleScrollToOption();
        }
    }, [preferences]);

    const doFetch = async () => {
        const content = await userSettingsContentModel.retrieve(
            USER_SETTING_CONTENT_TYPES.ALERTS_GUIDANCE,
        );
        return {
            ...content,
            helpTextUrl: `/admincms_lite/usersettingscontent/${content.pk}/change/`,
        };
    };

    const doPost = async (data) => {
        const content = await userSettingsContentModel.create(data);
        return {
            ...content,
            helpTextUrl: `/admincms_lite/usersettingscontent/${content.pk}/change/`,
        };
    };

    const saveChanges = async (updatedPreferences) => {
        // Because of the new layout, we can't rely on preferences state being updated before saveChanges is called
        const prefs = updatedPreferences || preferences;
        const { news_alert_topics, news_alert_frequency, document_alert_frequency } = prefs;
        const validTopics = newsTopics.map((topic) => topic.topic_id);
        const cleanedNewsAlertTopics = news_alert_topics.filter((topic) =>
            validTopics.includes(topic),
        );
        try {
            await app.user.commsPrefs(
                {
                    ...prefs,
                    news_alert_topics: cleanedNewsAlertTopics,
                    news_alert_frequency: news_alert_frequency.slug,
                    document_alert_frequency: document_alert_frequency.slug,
                },
                true,
            );
            setPreferences(prefs);
            toaster.success('Communication preferences updated');
        } catch (exc) {
            toaster.error('There was a problem updating your communication preferences');
        }
    };

    const canEdit =
        app.user.has_perm('cms_lite.add_usersettings_content') &&
        app.user.has_perm('cms_lite.change_usersettings_content');

    const handleToggle = (value, alert_key) => {
        const prefs = { ...preferences, [alert_key]: value === 'true' };
        saveChanges(prefs);
    };

    return (
        <>
            <h2 id="your-alerts">Your email alerts</h2>
            <ContentManager contentType={USER_SETTING_CONTENT_TYPES.ALERTS_GUIDANCE} />
            {preferences.news_alert_frequency && newsTopics.length && (
                <DetailBlock
                    title="News alerts"
                    className={preferences.news_alerts === false ? 'disabled' : ''}
                    rows={
                        preferences.news_alerts && {
                            frequency: {
                                label: 'Frequency',
                                value: preferences.news_alert_frequency.label,
                                can_edit: () =>
                                    setShowFrequencyModal({
                                        show: true,
                                        item: {
                                            options: newsFrequencyOptions,
                                            title: 'Edit news alert frequency',
                                            active: preferences.news_alert_frequency,
                                            handleSubmit: (frequency) =>
                                                saveChanges({
                                                    ...preferences,
                                                    news_alert_frequency: frequency,
                                                }),
                                        },
                                    }),
                            },
                            topics: {
                                label: 'Topics',
                                value: preferences.news_alert_topics_all
                                    ? 'All topics'
                                    : preferences.news_alert_topics
                                          .reduce((arr, topic) => {
                                              const newsTopic = newsTopics.find(
                                                  (t) => t.topic_id === topic,
                                              );
                                              return newsTopic?.title
                                                  ? [...arr, newsTopic.title]
                                                  : arr;
                                          }, [])
                                          .join(', '),
                                can_edit: () => setShowTopicsModal(true),
                            },
                        }
                    }
                    toggles={[
                        {
                            name: 'Disabled',
                            value: false,
                            active: preferences.news_alerts === false,
                            handleChange: (value) => handleToggle(value, 'news_alerts'),
                        },
                        {
                            name: 'Enabled',
                            value: true,
                            active: preferences.news_alerts === true,
                            handleChange: (value) => handleToggle(value, 'news_alerts'),
                        },
                    ]}
                />
            )}
            {preferences.document_alert_frequency && (
                <DetailBlock
                    title="Document alerts"
                    className={preferences.document_alerts === false ? 'disabled' : ''}
                    rows={
                        preferences.document_alerts && {
                            frequency: {
                                label: 'Frequency',
                                value: preferences.document_alert_frequency.label,
                                can_edit: () =>
                                    setShowFrequencyModal({
                                        show: true,
                                        item: {
                                            options: documentFrequencyOptions,
                                            title: 'Edit document alert frequency',
                                            active: preferences.document_alert_frequency,
                                            handleSubmit: (frequency) =>
                                                saveChanges({
                                                    ...preferences,
                                                    document_alert_frequency: frequency,
                                                }),
                                        },
                                    }),
                            },
                        }
                    }
                    toggles={[
                        {
                            name: 'Disabled',
                            value: false,
                            active: preferences.document_alerts === false,
                            handleChange: (value) => handleToggle(value, 'document_alerts'),
                        },
                        {
                            name: 'Enabled',
                            value: true,
                            active: preferences.document_alerts === true,
                            handleChange: (value) => handleToggle(value, 'document_alerts'),
                        },
                    ]}
                />
            )}
            <SelectTopicsModal
                show={showTopicsModal}
                hideModal={() => setShowTopicsModal(false)}
                selectedTopics={preferences.news_alert_topics}
                setSelectedTopics={(topics, all) => {
                    setPreferences({
                        ...preferences,
                        news_alert_topics: topics,
                        news_alert_topics_all: all,
                    });
                }}
                topics={newsTopics}
                handleSubmit={saveChanges}
            />
            {showFrequencyModal.show && (
                <AlertFrequencyModal
                    show={showFrequencyModal.show}
                    hideModal={() => setShowFrequencyModal({ show: false })}
                    {...showFrequencyModal.item}
                />
            )}

            <ContentContainer
                {...{
                    doFetch,
                    doPost,
                    contentType: USER_SETTING_CONTENT_TYPES.ALERTS_GUIDANCE,
                    canEdit,
                }}
            />
        </>
    );
};
