import React from 'react';
import PropTypes from 'prop-types';

const SourceCard = ({ source }) => {
    const onClick = (e) => {
        // open xrefs in new window
        if (source.xref) {
            window.manager.open(e.target.href.replace('/documents/', '/inter/'), {
                name: e.target.href.substr(1),
                features: 'width=800,height=500',
            });
            e.preventDefault();
        }
    };

    return (
        <div className="source-card">
            <div className="source-title">{source.source_title}</div>
            <a
                onClick={onClick}
                className={`source-link ${source.type}`}
                href={source.href}
                target="_blank"
                rel="noopener noreferrer"
            >
                {source.source_ref}
            </a>
        </div>
    );
};

export default SourceCard;

SourceCard.propTypes = {
    source: PropTypes.shape({
        source_title: PropTypes.string,
        href: PropTypes.string,
        source_ref: PropTypes.string,
        type: PropTypes.string,
        xref: PropTypes.string,
    }).isRequired,
};
