import { useState, useEffect, useReducer, useRef, useContext } from 'react';
import { useCookies } from 'react-cookie';
import { initialTableState } from './FormControls/Table/utils';
import { tableReducer } from './FormControls/Table/TableContext';
import { TimelineContext } from './Timeline/TimelineManager';

const useTableReducer = ({ defaultTableState, tableStateOverwrites }) => {
    // Store default table state that will show if the filters were cleared
    const defaultStateStore = defaultTableState
        ? {
              default: { ...defaultTableState, ...tableStateOverwrites },
          }
        : {};

    // Be careful with the order of things here - it matters.
    const newState = {
        ...(defaultTableState ?? initialTableState),
        ...tableStateOverwrites,
        ...defaultStateStore,
    };

    const [tableState, dispatchTableState] = useReducer(tableReducer, newState);

    return [tableState, dispatchTableState];
};

const useTableReducerWithCookies = ({ tableName, defaultTableState, tableStateOverwrites }) => {
    const [cookies, setCookie] = useCookies([tableName]);

    // Store default table state that will show if the filters were cleared
    const defaultStateStore = defaultTableState
        ? {
              default: { ...defaultTableState, ...tableStateOverwrites },
          }
        : {};

    // Be careful with the order of things here - it matters. Cookies are meant to override default state.
    const newState = {
        ...(defaultTableState ?? initialTableState),
        ...cookies[tableName],
        ...tableStateOverwrites,
        ...defaultStateStore,
    };

    const [tableState, dispatchTableState] = useReducer(tableReducer, newState);

    useEffect(() => {
        const expires = app.moment.utc().add(2, 'hours').toDate();

        // Only store things that are required (selected by user and should be rememberded).
        // Be careful not to store other things, like current organisation, because it is not a user selection
        const cookieState = {
            filter: tableState.filter,
            page: tableState.page,
            itemsPerPage: tableState.itemsPerPage,
            sortColumn: tableState.sortColumn,
            selectedAll: tableState.selectedAll,
        };
        setCookie(tableName, cookieState, { expires });
    }, [
        tableState.filter,
        tableState.page,
        tableState.itemsPerPage,
        tableState.sortColumn,
        tableState.selectedAll,
        tableState.selectedList.length,
        tableName,
    ]);

    return [tableState, dispatchTableState];
};

const useFetch = ({
    doFetch,
    defaultState,
    dependencies = [],
    dirtyData = false,
    setFetchedState = () => {},
}) => {
    const [state, setState] = useState({
        loading: true,
        data: defaultState,
    });

    const fetchData = async (component) => {
        try {
            if (!component.cancelled) setState({ ...state, loading: true });
            const data = await doFetch();
            if (!component.cancelled) {
                setState({ ...state, loading: false, error: false, data });
            }
            if (!component.cancelled) setFetchedState(data);
        } catch (error) {
            toaster.error(`Couldn't retrieve data`);
            setState({ ...state, error: true });
        }
    };

    // Refetch data if any of the dependencies changed
    useEffect(() => {
        const component = { cancelled: false };
        fetchData(component);

        return () => {
            component.cancelled = true;
        };
    }, dependencies);

    // Refetch data if dirtyData change to true
    useEffect(() => {
        if (dirtyData) {
            const component = { cancelled: false };
            fetchData(component);
            return () => {
                component.cancelled = true;
            };
        }
    }, [dirtyData]);

    return state;
};

// This is for polling. TODO replace with Sockets
const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    });

    useEffect(() => {
        const tick = () => savedCallback.current();

        if (delay) {
            const id = setInterval(tick, delay);
            return () => {
                clearInterval(id);
            };
        }
    }, [delay]);
};

const useModel = (model) => {
    if (model) {
        const [data, setState] = useState(model.toJSON());
        model.on('change reset sync', () => setState(model.toJSON()));
        return data;
    }
};

const useCollection = (collection) => {
    const [data, setState] = useState(collection.toJSON());
    collection.on('add remove sort reset sync', () => setState(collection.toJSON()));
    return data;
};

const useHash = (scrollID, replacedID) => {
    const scrolledRef = useRef(false);
    const hash = scrollID
        ? window.location.hash.replace(replacedID, scrollID)
        : window.location.hash;
    const hashRef = useRef(hash);

    useEffect(() => {
        if (hash) {
            if (hashRef.current != hash) {
                hashRef.current = hash;
                scrolledRef.current = false;
            }

            if (scrolledRef.current === false) {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);

                if (element) {
                    element.scrollIntoView();
                    scrolledRef.current = true;
                }
            }
        }
    });
    return hash;
};

const useStateRef = (value) => {
    const ref = useRef(value);

    const setValue = (val) => {
        ref.current = val;
    };

    const getValue = () => {
        return ref.current;
    };

    return [getValue, setValue];
};

const useResize = (ref, step, startSize, stopSize, toRight, handlePage, updateParent) => {
    // only resizes horizontally
    ref = ref || {};
    const [coords, setCoords] = useState(Infinity);
    const [dims, setDims] = useState(Infinity);
    const [size, setSize] = useState(Infinity);

    useEffect(() => {
        if (startSize) {
            setSize(startSize);
        }
    }, [startSize]);

    const initResize = (evt) => {
        // This grabs where we are in the component for resizing
        if (!ref.current) return;
        setCoords(evt.clientX);
        const { width } = window.getComputedStyle(ref.current);
        setDims(parseInt(width, 10));
    };

    useEffect(() => {
        const getValue = (input) => Math.ceil(input / step) * step;

        const doDrag = (evt) => {
            if (!ref.current) return;

            const width = getValue(
                toRight ? Math.abs(evt.clientX - dims - coords) : dims + evt.clientX - coords,
            );
            if (stopSize <= width && width <= startSize) {
                // don't resize if not between start/stop sizes
                if (updateParent) {
                    ref.current.parentNode.style.width = `${width}px`;
                } else {
                    ref.current.style.width = `${width}px`;
                }
                setSize(width);
                if (handlePage) handlePage();
            }
        };

        const stopDrag = () => {
            document.removeEventListener('mousemove', doDrag, false);
            document.removeEventListener('mouseup', stopDrag, false);
        };

        document.addEventListener('mousemove', doDrag, false);
        document.addEventListener('mouseup', stopDrag, false);
    }, [dims, coords, step, ref]);

    return { initResize, size };
};

const useDarkMode = () => {
    const [darkMode, setDarkMode] = useState(
        document.documentElement.getAttribute('data-theme') === 'dark',
    );

    useEffect(() => {
        const handleDarkModeChange = () => {
            setDarkMode(document.documentElement.getAttribute('data-theme') === 'dark');
        };

        const observer = new MutationObserver((mutationsList) => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'attributes' && mutation.attributeName === 'data-theme') {
                    handleDarkModeChange();
                }
            }
        });

        observer.observe(document.documentElement, { attributes: true });

        return () => {
            observer.disconnect();
        };
    }, []);

    return darkMode;
};

const useHover = () => {
    const [hover, setHover] = useState(false);

    const ref = useRef(null);

    const handleMouseOver = () => setHover(true);
    const handleMouseOut = () => setHover(false);

    useEffect(() => {
        const node = ref.current;
        if (node) {
            node.addEventListener('mouseover', handleMouseOver);
            node.addEventListener('mouseout', handleMouseOut);

            return () => {
                node.removeEventListener('mouseover', handleMouseOver);
                node.removeEventListener('mouseout', handleMouseOut);
            };
        }
    }, [ref.current]);
    return [ref, hover];
};

const useStickyScroll = (ref) => {
    const { setStickyItems, setActive } = useContext(TimelineContext);
    // Sticking and Unsticking need to be own functions as trying to toggle the classes causes some mayhem with the CSS animations
    const setUnsticky = (endDate, stickyList) => {
        stickyList.length &&
            stickyList.forEach((item) => {
                const startDate = item.getAttribute('start-dt');
                const staySticky = app.moment(endDate) >= app.moment(startDate);
                if (!staySticky) {
                    const currSticky = document.querySelectorAll(
                        `.sticky[start-dt="${startDate}"]`,
                    );
                    if (currSticky.length) {
                        currSticky.forEach((el) => {
                            el.classList.add('unsticky');
                            el.classList.remove('resticky');
                        });
                    }
                }
            });
    };

    const setSticky = (triggerDate, stickyList, nextTriggerDate) => {
        stickyList.length &&
            stickyList.forEach((item) => {
                const endDate = item.getAttribute('end-dt');
                const startDate = item.getAttribute('start-dt');
                // something is sticky if it the trigger date is between the start and end date, or if the next trigger date is provided and the start date is before the next trigger date
                const beSticky =
                    (app.moment(startDate) <= app.moment(triggerDate) &&
                        app.moment(triggerDate) <= app.moment(endDate)) ||
                    (nextTriggerDate && app.moment(startDate) <= app.moment(nextTriggerDate));
                if (beSticky) {
                    item.classList.add('resticky');
                    item.classList.remove('unsticky');
                }
            });
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                // only change sticky on single point items or date markers
                if (
                    (entry.target.classList.contains('month') &&
                        entry.target.classList.contains('timeline-calendar')) ||
                    (entry.target.classList.contains('year') &&
                        entry.target.classList.contains('timeline-calendar')) ||
                    entry.target.getAttribute('end-dt') === entry.target.getAttribute('start-dt')
                ) {
                    // Get all the calendar markers and hold in a list so we can find a previous marker
                    const calendarMarkers = [...document.querySelectorAll('.timeline-calendar')];
                    const entryIndex = calendarMarkers.indexOf(entry.target);
                    // Get all the currently sticky items and check if they should be unsticky
                    const currentStickyList = Array.from(
                        document.querySelectorAll('.sticky:not(.unsticky)'),
                    );
                    if (entry.boundingClientRect.top < entry.rootBounds.bottom) {
                        setUnsticky(entry.target.getAttribute('end-dt'), currentStickyList);
                    }
                    if (
                        entry.boundingClientRect.bottom > entry.rootBounds.top &&
                        entry.boundingClientRect.bottom < entry.rootBounds.bottom
                    ) {
                        // Get all the currently unsticky items and check if they should be sticky
                        const currentUnstickyList = Array.from(
                            document.querySelectorAll('.sticky.unsticky'),
                        );
                        // Get previous calendar marker in case the sticky to unsticky doesn't fit perfectly into the entry.target's date range
                        const previousMarker = calendarMarkers[entryIndex - 1];
                        const previousTrigger = previousMarker
                            ? previousMarker.getAttribute('end-dt')
                            : undefined;
                        setSticky(
                            entry.target.getAttribute('end-dt'),
                            currentUnstickyList,
                            previousTrigger,
                        );
                    }
                    // Get latest sticky items after all the sticking/unsticking
                    const latestSticky = Array.from(
                        document.querySelectorAll('.sticky:not(.unsticky)'),
                    );
                    // Set the sticky items in the context
                    setStickyItems(latestSticky);
                }
                if (
                    entry.target.classList.contains('month') &&
                    entry.target.classList.contains('timeline-calendar')
                ) {
                    if (entry.isIntersecting) {
                        setActive({ id: entry.target.id, active: true });
                        const element = document.getElementById(`calendar-s${entry.target.id}`);
                        if (element) {
                            element.scrollIntoView();
                        }
                    }
                }
            },
            {
                rootMargin: '-5% 0% -80% 0%', // the observation area should be rather small, and offset to just the top portion of the page
            },
        );

        if (ref.current) observer.observe(ref.current);

        return () => observer.disconnect();
    }, [ref.current]);
};

export {
    useCollection,
    useFetch,
    useHash,
    useInterval,
    useModel,
    useStateRef,
    useResize,
    useTableReducer,
    useTableReducerWithCookies,
    useDarkMode,
    useHover,
    useStickyScroll,
};
