import React from 'react';
import PropTypes from 'prop-types';

import Filter, { FILTER_TYPES, NESTED_SELECT_TYPES } from '../../FormControls/Table/Filter';
import { WithLoading } from '../../FormControls/WithLoading';
import { annotationsModel, formatGroups, formatProjects } from '../utils';
import { useFetch } from '../../customHooks';

//#region helpers

const getGroups = (availableGroups) => {
    const projects = app.user.get('projects');
    const formattedGroups = formatGroups(
        availableGroups,
        NESTED_SELECT_TYPES.GRANDCHILD,
        'all_groups',
    );
    const formattedProjects = formatProjects(
        projects,
        NESTED_SELECT_TYPES.GRANDCHILD,
        'all_projects',
    );

    return [
        {
            value: 'all',
            label: 'Me and all my groups',
        },
        {
            value: 'private',
            label: 'Just me (private)',
            nested: NESTED_SELECT_TYPES.CHILD,
        },
        {
            value: 'mine_shared',
            label: 'Shared by me',
            nested: NESTED_SELECT_TYPES.CHILD,
        },
        ...(projects.length
            ? [
                  {
                      value: 'mine_groups',
                      label: 'Shared with groups by me',
                      nested: NESTED_SELECT_TYPES.GRANDCHILD,
                      parentValue: 'mine_shared',
                  },
                  {
                      value: 'mine_projects',
                      label: 'Shared with projects by me',
                      nested: NESTED_SELECT_TYPES.GRANDCHILD,
                      parentValue: 'mine_shared',
                  },
              ]
            : []),
        {
            value: 'all_groups',
            label: 'Groups',
            nested: NESTED_SELECT_TYPES.CHILD,
        },
        ...formattedGroups,

        ...(projects.length
            ? [
                  {
                      value: 'all_projects',
                      label: 'Projects',
                      nested: NESTED_SELECT_TYPES.CHILD,
                  },
                  ...formattedProjects,
              ]
            : []),
    ];
};

const documentFilter = (documentID) => {
    return [
        {
            value: 'all',
            label: 'Show All Documents',
        },
        {
            value: documentID,
            label: 'Show Current Document Only',
        },
    ];
};

const getFilterFields = (groupData, documentID) => {
    const filters = [];

    if (documentID) {
        filters.push({
            type: FILTER_TYPES.SELECT,
            options: documentFilter(documentID),
            name: 'doc_id',
        });
    }

    filters.push({
        type: FILTER_TYPES.NESTEDSELECT,
        options: getGroups(groupData),
        placeholder: 'Shared with...',
        name: 'shared[]',
    });

    return filters;
};

//#endregion

const AnnotationFilter = ({ model, documentID }) => {
    const { data, loading } = useFetch({
        doFetch: async () => await model.getAnnotationGroups(),
    });

    const filterFields = getFilterFields(data, documentID);

    return (
        <WithLoading loading={loading}>
            <Filter
                {...{
                    filterFields,
                }}
            />
        </WithLoading>
    );
};

export default AnnotationFilter;

AnnotationFilter.propTypes = {
    model: PropTypes.shape(annotationsModel).isRequired,
    documentID: PropTypes.string,
};
