import React, { useContext, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import PropTypes from 'prop-types';

import { TableContext } from '../Table/TableContext';
import TableRow from '../Table/TableRow';
import icons from '../../../img/Icons';

const MoveableItem = ({ id, row, index, moveItemHandler, doUpdate, draggable }) => {
    const { options } = useContext(TableContext);
    const ref = useRef(null);

    const [{ handlerId }, drop] = useDrop({
        accept: 'item',
        collect: (monitor) => {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover: (item, monitor) => {
            if (!ref.current) {
                return;
            }

            const dragIndex = item.index;
            const hoverIndex = index;

            // Check we're not just picking up and dropping back where we started
            if (dragIndex === hoverIndex) return;

            // Determine screen rectangle
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            // Dragging down
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                // Scroll if we're near the bottom of the window
                if (window.innerHeight - clientOffset.y < 100 && ref.current.nextSibling) {
                    ref.current.nextSibling.scrollIntoView({ behavior: 'smooth' });
                }
            }
            // Dragging up -> Somehow it already scrolls up, but it's a bit temperamental
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

            moveItemHandler(dragIndex, hoverIndex);
            // this isn't ideal as it's mutating things, but without it the monitor is way
            // too sensitive and panics about where things are hovering and dropping
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'item',
        item: () => {
            // Disable DnD if not in a draggable sort order
            if (draggable) {
                return { id, index };
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: () => {
            // Once we've stopped dragging, save the new order
            doUpdate();
        },
    });

    drag(drop(ref));

    return (
        <>
            {isDragging ? (
                <>
                    <tr
                        className={`moveable-item isDragging ${draggable ? '' : 'dragging'}`}
                        style={{ cursor: 'grabbing' }}
                    >
                        {options.orderable && (
                            <td
                                className={`dnd-grip`}
                                title="Reorder your list by dragging and dropping an item to the desired position when sorted by 'My order'."
                            >
                                {icons['grip']()}
                            </td>
                        )}
                        {row.cols.map((column, i) => (
                            <td key={i}>{column}</td>
                        ))}
                    </tr>
                </>
            ) : (
                <TableRow row={row} ref={ref} data-handler-id={handlerId} />
            )}
        </>
    );
};

export default MoveableItem;

MoveableItem.propTypes = {
    id: PropTypes.number.isRequired,
    row: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        cols: PropTypes.arrayOf(PropTypes.node).isRequired,
    }),
    index: PropTypes.number.isRequired,
    moveItemHandler: PropTypes.func.isRequired,
    doUpdate: PropTypes.func.isRequired,
    draggable: PropTypes.bool,
};
