import React from 'react';
import PropTypes from 'prop-types';

import ConditionalWrapper from '../ConditionalWrapper';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import PiconIcon from '../FormControls/Icons/PiconIcon';

const ViewFavouritesModal = ({ handleUpdate }) => {
    const handleModal = () => {
        const favouritesWindowRef = window.manager.open(`${app.urls.favouritesWindow}/`, {
            name: app.popups.intermediate.name,
            features: 'width=900,height=700',
        });
        app.popups.intermediate.ref = favouritesWindowRef;

        app.popups.intermediate.ref.focus();

        $(favouritesWindowRef).on('load', function () {
            favouritesWindowRef.app.trigger('loaded');
            favouritesWindowRef.app.listenTo(
                favouritesWindowRef.app,
                'favourite:changed',
                handleUpdate,
            );
        });
    };

    const popoverFaves = (
        <Popover id="popover-favourites" className="popover fade bottom in">
            <Popover.Title className="popover-title">Favourites</Popover.Title>
            <Popover.Content className="popover-content">
                Open your list of Favourites
            </Popover.Content>
        </Popover>
    );

    const displayToolTip = app.user.get('display_prefs').contextual_help;

    return (
        <ConditionalWrapper
            condition={displayToolTip}
            wrapper={(children) => (
                <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="bottom"
                    overlay={popoverFaves}
                    rootClose={true}
                >
                    {children}
                </OverlayTrigger>
            )}
        >
            <div>
                <button
                    className="toolbar-item btn"
                    onClick={handleModal}
                    title="Open your list of Favourites"
                    role="menuitem"
                >
                    <span className="icon">
                        <PiconIcon className={`lg left top `} iconName="star" />
                    </span>
                    <span className="label">Favourites</span>
                </button>
            </div>
        </ConditionalWrapper>
    );
};

export default ViewFavouritesModal;

ViewFavouritesModal.propTypes = {
    handleUpdate: PropTypes.func.isRequired,
};
