module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<form action="">\n    <div class="previous-search">\n        <div class="form-group previous-search-query">\n            <span class="label">This is a search for '+
((__t=( searchMode ))==null?'':_.escape(__t))+
'</span>\n            <span class="field">'+
((__t=( formattedQuery ))==null?'':_.escape(__t))+
'</span>\n        </div>\n        <div class="form-group previous-search-scope">\n            <span class="label">in</span>\n            <span class="field">'+
((__t=( title ))==null?'':_.escape(__t))+
'</span>\n        </div>\n    </div>\n    <div class="change-search-buttons">\n        <button type="button" class="btn btn-sm change-query" id="has-modal-m-search-again">Change Query</button>\n        <!--<label class="checkbox"><input id="hideSearchHits" type="checkbox" /> Hide search hits</label>-->\n        <button type="button" class="btn btn-sm show-search-within disabled" id="show-search-within" disabled>&darr; Search Within Results</button>\n    </div>\n</form>';
}
return __p;
};
