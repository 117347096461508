define(['backbone', 'bootbox'], function (Backbone, bootbox) {
    /*
        Intermediate window actions
    */
    var IntermediateWindowMixin = {
        events: {
            'click a.xref, a.legref, a.caseref, a.refer, a.inref, a.enab, a.txtmark, a.textmark, a.term':
                'openIntermediateWindow',
            'click .dd-flag-note, .dd-flag-modifications, .dd-flag-docnotes': 'openNotesWindow',
            'click a.anchor': 'openAnchorTab',
        },
        openAnchorTab: function (evt) {
            if (window.name == app.popups.intermediate.name) {
                // if an anchor class has been clicked in an intermediate window, open the link in a new tab in the parent window
                evt.preventDefault();
                window.opener.open(evt.currentTarget.href, '_blank');
            }
            // else just do whatever the anchor tag would normally do
        },
        openNotesWindow: function (evt) {
            evt.preventDefault();
            var url = $(evt.currentTarget).find('a').attr('href');

            // check if we're coming from a document view, and if so save where we are so we can get back there
            if (app.documentView) {
                window.localStorage.setItem(
                    'opener_activeElement',
                    app.documentView.content.activeElement,
                );
            }

            // make sure we're not already in an intermediate window
            if (window.name != app.popups.intermediate.name) {
                app.popups.intermediate.ref = window.manager.open(url, {
                    name: app.popups.intermediate.name,
                    features: 'width=800,height=500',
                });
                app.popups.intermediate.ref.focus();
            } else {
                window.location.href = url;
            }
        },
        openIntermediateWindow: function (evt) {
            /*
                '.xref' class is another document
                '.term, .inref' class is internal
            */
            evt.preventDefault();
            // Don't launch an intermediate window for multi refs
            if ($(evt.currentTarget).hasClass('multiref')) {
                return;
            }
            var lnk = $(evt.currentTarget);
            var url = app.urls.intermediateWindow;
            var ref, href, docid;
            // reference to the parent window's document
            if (!this.model && window.opener && window.opener.app.getDocument()) {
                this.model = window.opener.app.getDocument().getInstance();
            }
            if (
                lnk.hasClass('xref') ||
                lnk.hasClass('legref') ||
                lnk.hasClass('caseref') ||
                lnk.hasClass('refer')
            ) {
                // Deal with links to docs other than this one
                // pick first link from list for now
                ref =
                    lnk.attr('ref') ||
                    lnk.attr('tabrefto') ||
                    lnk.attr('extdef') ||
                    lnk.attr('href').substring(lnk.attr('href').indexOf('#') + 1);

                ref = ref.split(' ')[0];
                href = lnk.attr('href');
                if (href.indexOf('/') == 0 && href.split('/')[1] == 'documents') {
                    docid = href.split('/')[2];
                }
                url += '/' + docid;
            } else if (
                lnk.hasClass('inref') ||
                lnk.hasClass('enab') ||
                lnk.hasClass('term') ||
                lnk.hasClass('txtmark') ||
                lnk.hasClass('textmark')
            ) {
                ref =
                    lnk.attr('ref') ||
                    lnk.attr('defref') ||
                    lnk.attr('noteref') ||
                    lnk.attr('extdef') ||
                    lnk.attr('href').substring(lnk.attr('href').indexOf('#') + 1);

                // pick first link from list for now
                ref = ref.split(' ')[0];

                if (!this.norris || !_.has(this.norris.ids, ref)) {
                    app.trigger('scroll:enable', false);
                    bootbox
                        .dialog({
                            title: 'Destination not found',
                            message: 'The requested destination does not exist in this document',
                            buttons: {
                                ok: {
                                    label: 'OK',
                                    className: 'btn-primary',
                                },
                            },
                        })
                        .on('hide.bs.modal', function () {
                            // Re-enable scrolling when closing modals
                            app.trigger('scroll:enable', true);
                        });
                    return;
                }
                // append instance id if not published
                if (this.model.get('state') != 'published') {
                    url += '/' + this.model.get('document') + '/' + this.model.get('uuid');
                } else {
                    url += '/' + this.model.get('document');
                }
            }
            // Add time travel info by first checking the url of where we're coming from, then by checking the model's intance
            if (app.router.current === 'notes') {
                // need to use window.location.search url if we're going from a time-travelled note into a time-travelled document
                url += window.location.search;
            } else {
                if (
                    (this.model && this.model.ttViewOption != 'tt-current') ||
                    !app.moment().isSame(this.model.ttViewDate, 'day')
                ) {
                    var option = this.model.ttViewOption ? this.model.ttViewOption : 'tt-current';
                    url +=
                        '?tt_option=' +
                        option +
                        '&tt_date=' +
                        app.moment(this.model.ttViewDate).format(app.settings.dateTransportFormat);
                    // between dates doc view
                    if (this.model.ttViewOption == 'tt-between') {
                        url +=
                            '&tt_date2=' +
                            app
                                .moment(this.model.ttViewDate2)
                                .format(app.settings.dateTransportFormat);
                    }
                }
            }
            // apend ref
            if (ref) {
                url += '#' + ref;
            }

            // check if we're coming from a document view, and if so save where we are so we can get back there
            if (app.documentView) {
                window.localStorage.setItem(
                    'opener_activeElement',
                    app.documentView.content.activeElement,
                );
            }

            // make sure we're not already in an intermediate window
            if (window.name != app.popups.intermediate.name) {
                app.popups.intermediate.ref = window.manager.open(url, {
                    name: app.popups.intermediate.name,
                    features: 'width=800,height=500',
                });
                app.popups.intermediate.ref.focus();
            } else {
                window.location.href = url;
            }
        },
    };

    return IntermediateWindowMixin;
});
