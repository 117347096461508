import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import AnnotationCard from './AnnotationCard';
import DraggableModalDialog from '../FormControls/DraggableModalDialog';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { HISTORIC_PRIVACY, PRIVACY } from '../Annotations/Privacy';

const initFilters = [
    { label: 'All', id: 'all' },
    { label: 'Private', id: 'PR' },
    { label: 'Shared', id: 'GP' },
];

const AnnotationSectionModal = ({ annos, show, hideModal, handleAdd, showViewModal, label }) => {
    const [groupedAnnos, setGroupedAnnos] = useState({});
    const [filters, setFilters] = useState(initFilters);
    const [filter, setFilter] = useState({ label: 'All', id: 'all' });
    const [showDropdown, setShowDropdown] = useState({ show: false });

    useEffect(() => {
        const projectOptions = app.user.attributes.projects.map((project) => {
            const option = {};
            option.label = project.title;
            option.id = project.id;
            return option;
        });

        const filterList = [...initFilters, ...projectOptions];
        const nestedFilters = [...initFilters, [...projectOptions]];
        projectOptions.length > 1 ? setFilters(nestedFilters) : setFilters(filterList);

        const grouped = _.groupBy(annos, (anno) => {
            const hasAttributes = Object.hasOwn(anno, 'attributes');
            const privacy = hasAttributes ? anno.attributes.privacy : anno.privacy;
            if (privacy != PRIVACY.PROJECT) {
                return privacy;
            } else {
                return hasAttributes ? anno.attributes.project : anno.project;
            }
        });

        if (Object.keys(grouped).some((group) => HISTORIC_PRIVACY.includes(group))) {
            HISTORIC_PRIVACY.forEach((privacy) => {
                if (Object.hasOwn(grouped, privacy)) {
                    grouped.GP = [...grouped.GP, ...grouped[privacy]];
                    delete grouped[privacy];
                }
            });
        }

        if (!filterList.every((option) => Object.keys(grouped).includes(option.id))) {
            filterList.forEach((option) => {
                if (!Object.hasOwn(grouped, option.id)) {
                    grouped[option.id] = [];
                }
            });
        }
        grouped.all = annos;

        setGroupedAnnos(grouped);
    }, [annos]);

    const formatFilterButton = (filter, truncate) => {
        return `${truncate ? _.truncate(filter.label, { length: 15 }) : filter.label} ${
            groupedAnnos.all && groupedAnnos[filter.id].length
                ? '(' + groupedAnnos[filter.id].length + ')'
                : '(0)'
        }`;
    };

    const addActiveClass = (fltr) => {
        if (!Array.isArray(fltr)) {
            return `${fltr.label === filter.label ? 'active' : ''}`;
        } else {
            return `${fltr.includes(filter) ? 'active' : ''}`;
        }
    };

    return (
        <Modal
            role="dialog"
            aria-label="annotation-section-list-modal"
            show={show}
            onHide={hideModal}
            className="rbs4 annotation-section-list-modal"
            dialogAs={DraggableModalDialog}
            size="medium"
        >
            <Modal.Header closeButton>
                <Modal.Title>{`View all annotations in ${
                    label ? label : 'the selected area'
                }`}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="annotation-section-filter">
                <div>
                    {filters.map((fltr, index) =>
                        !Array.isArray(fltr) ? (
                            <Button
                                key={index}
                                className={addActiveClass(fltr)}
                                onClick={() => setFilter(fltr)}
                            >
                                {formatFilterButton(fltr, true)}
                            </Button>
                        ) : (
                            <Button
                                key={index}
                                className={`annotation-btn-dropdown ${addActiveClass(fltr)}`}
                                onClick={() =>
                                    setShowDropdown({ show: !showDropdown.show, filters: fltr })
                                }
                            >
                                +
                            </Button>
                        ),
                    )}
                </div>
                {showDropdown.show && (
                    <div className="annotation-dropdown-content">
                        {showDropdown.filters.map((filter, idx) => (
                            <span
                                key={idx}
                                onClick={() => {
                                    setFilter(filter);
                                    setShowDropdown({ show: false });
                                }}
                                className={addActiveClass(filter)}
                            >
                                <a>{formatFilterButton(filter)}</a>
                            </span>
                        ))}
                    </div>
                )}
            </Modal.Body>
            <Modal.Body className="annotation-section-list">
                {groupedAnnos.all &&
                    filter &&
                    groupedAnnos[filter.id]
                        .sort((a, b) =>
                            a.attributes.last_modified < b.attributes.last_modified ? 1 : -1,
                        )
                        .map((anno, index) => {
                            return (
                                <AnnotationCard
                                    key={index}
                                    {...{
                                        anno: anno.attributes,
                                        showModal: () =>
                                            showViewModal({ ...anno.attributes, model: anno }),
                                        fullText: true,
                                    }}
                                />
                            );
                        })}
            </Modal.Body>
            <Modal.Footer className="annotation-section-list-footer">
                <a className="annotation-section-list-footer-content" onClick={handleAdd}>
                    + Add Annotation
                </a>
            </Modal.Footer>
        </Modal>
    );
};

export default AnnotationSectionModal;

AnnotationSectionModal.propTypes = {
    annos: PropTypes.array.isRequired,
    show: PropTypes.bool.isRequired,
    hideModal: PropTypes.func.isRequired,
    handleAdd: PropTypes.func.isRequired,
    showViewModal: PropTypes.func.isRequired,
    label: PropTypes.string,
};
