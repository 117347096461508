define([
    'backbone',
    '../../models',
    '../helpers',
    './toc',
    './content',
    './titlebar',
    '../search/section_results',
], function (
    Backbone,
    models,
    helpers,
    TOCView,
    ContentView,
    titlebarViews,
    SectionSearchResultsView,
) {
    /*
        A document with titlebar, ToC, content pane and bottom toolbar
        Also handles inline search
    */
    var DocumentView = helpers.BaseView.extend({
        el: 'body',
        initialize: function (options) {
            this.options = options;

            this.timeTravellable = false;
            if (this.model.get('timetravel') && window.name != app.popups.intermediate.name) {
                this.timeTravellable = true;
            }

            $('body').toggleClass('with-timetravel', this.timeTravellable);

            this.listenTo(this.model, 'timetravel:change', this.timeTravel);

            this.renderSubViews();
            this.setWindowTitle();
            if (this.options.queryParams) {
                this.fetchSearchResults();
            }
        },
        renderSubViews: function () {
            this.toc = new TOCView(this.options);
            this.content = new ContentView(this.options);
            this.titlebar = new titlebarViews.TitlebarView({ ...this.options });
            this.toolbarBottom = new titlebarViews.ToolbarBottomView(this.options);

            this.listenTo(this.content, 'block:rendered', this.highlightSearchTerms);
            this.listenToOnce(
                this.content,
                'document:allBlocksRendered',
                this.highlightSearchTerms,
            );
            this.listenToOnce(
                this.content,
                'document:allChunksFetched',
                _.bind(function () {
                    if (this.content.hitMap && Object.keys(this.content.hitMap).length) {
                        var firstBlock = Object.keys(this.content.hitMap).map(Number).sort()[0];
                        // check if we're coming from clicking Show Document, and if so, don't scroll from top
                        var fromShowDocument = this.options.queryParams.show_doc;
                        if (fromShowDocument) {
                            this.model.trigger('document:navigate');
                        } else {
                            this.model.trigger(
                                'document:navigate',
                                this.content.hitMap[firstBlock][0]['section'],
                            );
                        }
                    }
                }, this),
            );

            this.assign(this.toc, '#content .pane-left');
            this.assign(this.content, '#content .pane-main');
            this.assign(this.titlebar, '#navigation .titlebar');
            this.assign(this.toolbarBottom, '#navigation .toolbar-btm');
        },
        closeSubViews: function () {
            // Because of the way the HTML for the subviews is constructed, we can't just remove a parent element
            // and replace it on remove. Instead we have to remove the subviews and then replace the removed
            // DOM elements with a shallow copy of the view's node in the various places on the page that they live.
            this.toc.stopListening();
            this.content.stopListening();
            this.titlebar.stopListening();
            this.toolbarBottom.stopListening();

            this.toc.$el.replaceWith(this.toc.el.cloneNode());
            this.content.$el.replaceWith(this.content.el.cloneNode());
            this.titlebar.$el.replaceWith(this.titlebar.el.cloneNode());
            this.toolbarBottom.$el.replaceWith(this.toolbarBottom.el.cloneNode());
            this.searchResultsView.$el.replaceWith(this.searchResultsView.el.cloneNode());

            this.searchResultsView.close();
        },
        fetchSearchResults: function () {
            /*
                Fetch the results from the server
            */
            this.searchResults = new models.SectionSearchResults();

            this.listenTo(this.searchResults, 'sync', function (collection) {
                app.trigger('search:results:synced', {
                    total: collection.collectionData.numFound,
                    loaded: collection.length,
                });
            });

            this.searchResults
                .fetch({
                    data: this.options.queryParams,
                    processData: true,
                })
                .done(
                    _.bind(function () {
                        // we're decoding the results of $.param so that the router doesn't go into a loop
                        // as it tests if the URL has changed
                        var searchUrl = `${window.location.pathname}?${$.param(
                            this.options.queryParams,
                            true,
                        )}`;
                        app.router.navigate(searchUrl, { replace: true });
                        // render section search results
                        this.renderSearchResults();
                        // log search
                        this.searchResults.saveLog(this.options.queryParams);
                    }, this),
                )
                .fail(function (xhr) {
                    if (xhr.status === 503) {
                        toaster.error(
                            'Could not retrieve search results. Please try again in a few moments.',
                        );
                    } else {
                        _.bind(app.controller.errorHandler, app.controller)(xhr);
                    }
                });
        },
        renderSearchResults: function () {
            /*
                Inline search results
            */
            this.searchResultsView = new SectionSearchResultsView(
                _.extend(this.options, { searchResults: this.searchResults }),
            );

            this.assign(this.searchResultsView, '#results');

            if (this.options.queryParams.view == 'document') {
                this.searchResultsView.closeResults();
            }

            this.titlebar.togglePaneTools(true);
            this.titlebar.adjustPosition();

            this.highlightSearchTerms();
        },
        highlightSearchTerms: function () {
            // deal with search highlighting
            if (!_.isUndefined(this.searchResultsView) && this.options.queryParams.q) {
                // this is the problem. we need it to not trying to highlight terms that aren't there
                if (this.content.norris.initialized) {
                    this.content.hitMap = this.searchResultsView.getTerms(this.content.norris);
                    this.content.highlightVisibleBlocks();
                } else {
                    this.listenTo(this.content.norris, 'initialized', function () {
                        this.content.hitMap = this.searchResultsView.getTerms(this.content.norris);
                        this.content.highlightVisibleBlocks();
                    });
                }
            }
        },
        setWindowTitle: function () {
            var title = this.model.get('title') + ' - ';
            if (this.model.get('timetravel')) {
                title += app.moment(this.model.ttViewDate).format(app.settings.dateFormat) + ' - ';

                if (this.model.ttViewOption == 'tt-between') {
                    title +=
                        app.moment(this.model.ttViewDate2).format(app.settings.dateFormat) + ' - ';
                }
                title += this.model.getTTViewTitle() + ' - ';
            }
            title += 'Perspective';
            document.title = title;
        },
        getTTViewOption: function () {
            return this.model.ttViewOption;
        },
        getTTViewDate: function () {
            // Get the first view date (for the between dates)
            return this.model.ttViewDate;
        },
        getTTViewDate2: function () {
            // Get the second view date (for the between dates)
            return this.model.ttViewDate2;
        },
        getTTDocDate: function () {
            return this.content.norris.docEnforDate;
        },
        timeTravel: function () {
            if (!this.timeTravellable) {
                return;
            }
            // If the view date is less than the document published date
            // then the whole document should be 'tts-doc-not-in-force'.
            // This can be applied to the inner wrapper div of content
            // and TOC.

            // If view option is tt-between use the second view date to
            // determine if the whole document is in force or not.
            var ttViewDate =
                this.model.ttViewOption != 'tt-between'
                    ? this.getTTViewDate()
                    : this.getTTViewDate2();

            if (app.moment(this.getTTDocDate(), 'YYYYMMDD').isAfter(ttViewDate)) {
                this.$('.pane-doc .inner, .pane-toc .inner').addClass('tts-doc-not-in-force');
            } else {
                this.$('.pane-doc .inner, .pane-toc .inner').removeClass('tts-doc-not-in-force');
            }

            this.setWindowTitle();
        },
    });

    /*
        Composite nav view for documents
    */
    var DocumentNavigationView = helpers.BaseView.extend({
        el: '#navigation',
        initialize: function (options) {
            this.options = options;

            this.titlebar = new titlebarViews.TitlebarView(options);
            this.toolbarBottom = new titlebarViews.ToolbarBottomView(options);
        },
        render: function () {
            this.assign(this.titlebar, '.titlebar');
            this.assign(this.toolbarBottom, '.toolbar-btm');
        },
    });

    return {
        DocumentView: DocumentView,
        DocumentNavigationView: DocumentNavigationView,
    };
});
