module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<p class="news-msg">Select a publisher by initial</p>\n\n<div id="publisher-message">\n    <p class="news-msg">Fetching publishers, please wait...</p>\n</div>\n\n<p class="publisher-initials">\n';
 _.each(groups, function(group, key) { 
__p+='\n    <a href="#publisher-initial-'+
((__t=( key ))==null?'':_.escape(__t))+
'">'+
((__t=( key ))==null?'':_.escape(__t))+
'</a>\n';
 }) 
__p+='\n</p>\n\n';
 _.each(groups, function(group, key) { 
__p+='\n\n    <div class="publisher-links">\n        <h3 id="publisher-initial-'+
((__t=( key ))==null?'':_.escape(__t))+
'">'+
((__t=( key.toUpperCase() ))==null?'':_.escape(__t))+
'</h3>\n        ';
 _.each(group, function(publisher, idx) { 
__p+='\n        <div>\n            <a class="publisher-link" href="" data-publisher="'+
((__t=( publisher.get('researcher') ))==null?'':_.escape(__t))+
'">'+
((__t=( publisher.get('researcher') ))==null?'':_.escape(__t))+
'</a>\n            <div class="publisher-listing" id="publisher-'+
((__t=( key ))==null?'':_.escape(__t))+
'-'+
((__t=( idx ))==null?'':_.escape(__t))+
'"></div>\n        </div>\n        ';
 }); 
__p+='\n    </div>\n\n';
 }); 
__p+='\n\n\n';
}
return __p;
};
