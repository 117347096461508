import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';

import FormModal from '../../../FormControls/FormModal';

//#region contants

const PATTERN_HOST_PATH = /^\/?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;
const PATTERN_PROTOCOL = /^https?:$/;

//#endregion

//#region helpers

const formatLink = (form) => {
    let result = form.elements.link.value.trim();

    if (result && !/^https?:/.test(result)) {
        result = 'https://'.concat(result);
    }

    return result;
};

//#endregion

export const AnnotationLinkModal = ({ show, hideModal }) => {
    const [linkIsValid, setLinkIsValid] = useState(true);
    const [range, setRange] = useState();

    useEffect(() => {
        if (show) {
            setRange(window.getSelection().getRangeAt(0));
        }
    }, [show]);

    const submitForm = async (form) => {
        // Direct DOM manipulation - bad - do not reuse.
        // We already lost control of this component (contentEditable)
        // There is no other way to know what is selected in it and to replace the selection
        // If a way is invented / or a rich editor is used, remove this thing
        const text = range.extractContents().textContent;
        const a = document.createElement('a');
        a.setAttribute('href', formatLink(form));
        a.setAttribute('rel', 'noopener noreferrer');
        a.setAttribute('target', '_blank');
        a.innerHTML = text;
        range.insertNode(a);
    };

    const checkInputValidity = async (form) => {
        const result = formatLink(form);

        try {
            const link = new URL(result);
            const host_path = link.hostname + link.pathname;

            if (!PATTERN_PROTOCOL.test(link.protocol) || !PATTERN_HOST_PATH.test(host_path)) {
                throw new TypeError('Failed to construct the URL: Invalid URL');
            }
        } catch (error) {
            setLinkIsValid(false);
            return false;
        }

        setLinkIsValid(true);
        return true;
    };

    return (
        <FormModal
            {...{
                submitForm,
                checkInputValidity,
                hideModal,
                show,
                size: 'sm',
                title: 'Insert link',
            }}
        >
            <Form.Group>
                <Form.Row>
                    <Form.Control
                        required
                        type="text"
                        placeholder="URL"
                        name="link"
                        maxLength={1000}
                        isInvalid={!linkIsValid}
                        defaultValue={$(range?.commonAncestorContainer.parentNode)
                            .closest('a')
                            .attr('href')}
                    />
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid URL
                    </Form.Control.Feedback>
                </Form.Row>
            </Form.Group>
        </FormModal>
    );
};

AnnotationLinkModal.propTypes = {
    show: PropTypes.bool,
    hideModal: PropTypes.func.isRequired,
};
