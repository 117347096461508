// eslint-disable-next-line no-unused-vars
define(['backbone', 'bootstrap'], function (Backbone, Bootstrap) {
    /*
        Abstract view from which all views spring forth
    */
    var BaseView = Backbone.View.extend({
        constructor: function (options) {
            this.options = options || {};
            Backbone.View.prototype.constructor.apply(this, arguments);
        },
        assign: function (view, selector) {
            view.setElement(this.$(selector)).render();
        },
        close: function () {
            this.$el.remove();
            this.unbind();
            this.trigger('close');
        },
    });

    /*
        Abstract view for rendering templates
    */
    var TemplateView = BaseView.extend({
        templateHelpers: {},
        context: function () {
            var data = this.templateHelpers;
            if (_.isFunction(data)) {
                data = data.call(this);
            }
            if (this.model) {
                data = _.extend(data, this.model.toJSON());
            }
            if (this.collection) {
                data = _.extend(data, { collection: this.collection });
            }
            return data;
        },
        getTemplate: function () {
            return this.options.template || this.template;
        },
        render: function () {
            var template = this.getTemplate();
            if (template) {
                this.$el.html(template(this.context()));
            }
            this.trigger('render');
            this.rendered = true;
            return this;
        },
    });

    /*
        View with lots of little views

        itemView - sub view class
        itemViewContainer - where to render the sub views to in the template
        itemViewOptions - anything else to instantiate the sub views with (optional)

    */
    var CollectionView = TemplateView.extend({
        itemViewOptions: {},
        _itemViews: [],
        context: function () {
            var data = TemplateView.prototype.context.apply(this, arguments);
            if (this.collection) {
                data = _.extend(data, { collection: this.collection.toJSON() });
            }
            return data;
        },
        getOptions: function (item) {
            var itemViewOptions = this.itemViewOptions;
            if (_.isFunction(itemViewOptions)) {
                itemViewOptions = _.bind(itemViewOptions, this)();
            }
            return _.extend({ model: item }, itemViewOptions);
        },
        buildItemView: function (item) {
            return new this.itemView(_.extend(this.getOptions(item)));
        },
        forwardEvents: function (view) {
            this.listenTo(
                view,
                'all',
                function () {
                    var args = _.toArray(arguments).slice(1);
                    var event = 'item:' + _.first(_.toArray(arguments));
                    this.trigger(event, view, args);
                },
                this,
            );
        },
        getCollection: function () {
            return this.collection.models;
        },
        render: function () {
            TemplateView.prototype.render.apply(this, arguments);
            var container = this.$(this.itemViewContainer);

            this.getCollection().forEach((item) => {
                var itemView = this.buildItemView(item);
                this.forwardEvents(itemView);
                itemView.render();
                container.append(itemView.$el);
            });
        },
        remove: function () {
            _.each(
                this._itemViews,
                _.bind(function (itemView) {
                    this.stopListening(itemView);
                    itemView.remove();
                    this._itemViews.pop();
                }, this),
            );

            TemplateView.prototype.remove.apply(this, arguments);
        },
    });

    /*
        View that populates a bootstrap modal
    */
    var ModalView = TemplateView.extend({
        modalEvents: ['show', 'shown', 'hide', 'hidden', 'loaded'],
        constructor: function (options) {
            this.options = options;
            TemplateView.prototype.constructor.apply(this, arguments);
            $('body').append(this.$el);
            this.forwardEvents();
        },
        show: function () {
            this.$el.modal('show');
        },
        close: function () {
            this.$el.modal('hide');
        },
        forwardEvents: function () {
            this.modalEvents.forEach((event) => {
                this.$el.on(
                    event + '.bs.modal',
                    _.bind(function () {
                        this.trigger('modal:' + event, arguments);
                    }, this),
                );
            });
        },
    });

    /*
        Form help
    */
    var FormMixin = {
        getForm: function () {
            return this.$('form');
        },
        handleErrors: function (model, xhr) {
            /*
            Drop in for error callback on model create
        */
            if (xhr.status == 400) {
                if (xhr.responseJSON['__all__']) {
                    messages.error(xhr.responseJSON['__all__']);
                    if (this.close) {
                        this.close();
                    }
                } else {
                    this.renderErrors(xhr.responseJSON);
                }
            }
        },
        removeErrors: function () {
            $('.has-error', this.getForm()).removeClass('has-error').find('.text-danger').remove();
        },
        renderErrors: function (errors) {
            this.removeErrors();
            var firstField = false;
            _.each(
                errors,
                _.bind(function (val, name) {
                    const errs = [];
                    if (_.isArray(val)) {
                        // Array of errors
                        _.each(val, function (error) {
                            if (_.has(error, 'non_field_errors')) {
                                errs.push(error.non_field_errors);
                            } else {
                                errs.push(error);
                            }
                        });
                    } else {
                        errs.push(val);
                    }
                    // FIXME: should this special case be here
                    if (name == 'subscription_groups') {
                        errs.push('Please select subscription group.');
                        name = 'usergroups';
                    }
                    var field = this.$('[name="' + name + '"]', this.getForm())
                        .before('<p class="text-danger">' + errs + '</p>')
                        .parent('.form-group')
                        .addClass('has-error')
                        .end();
                    if (!firstField && field.is('input:visible')) {
                        field.focus();
                        firstField = true;
                    }
                }, this),
            );
        },
    };

    return {
        BaseView: BaseView,
        TemplateView: TemplateView,
        CollectionView: CollectionView,
        ModalView: ModalView,
        FormMixin: FormMixin,
    };
});
