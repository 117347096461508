import React from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';

const DetailCard = ({ title, message, buttonText, handleClick }) => {
    return (
        <div className="detail-card">
            <div className="card-title">{title}</div>
            <div className="card-body">{message}</div>
            <Button onClick={handleClick}>{buttonText}</Button>
        </div>
    );
};

export default DetailCard;

DetailCard.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    buttonText: PropTypes.string,
    handleClick: PropTypes.func,
};
