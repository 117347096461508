import React, { useEffect, useRef, useState, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';

import CalendarItem from './CalendarItem';
import { useResize } from '../customHooks';
import CalendarPanelControl from './CalendarPanelControl';
import { TimelineContext } from './TimelineManager';

const RESIZE_STEP = 0.5;
const PANEL_STOP_SIZE = 22;
const PANEL_CONTENT_SHOW_SIZE = 35;

const monthMarkers = (calendarMap, year, monthsWithData) => {
    return Object.entries(calendarMap[year])
        .map(([month, count], idx) => {
            let lastDataMonthText;
            if (!count) {
                const lastMonthWithData = monthsWithData.find((date) =>
                    app.moment(date).isBefore(app.moment(`${year} ${month}`)),
                );
                if (!lastMonthWithData) {
                    const futureMonthWithData = monthsWithData
                        .toReversed()
                        .find((date) => app.moment(date).isAfter(app.moment(`${year} ${month}`)));
                    lastDataMonthText = app.moment(futureMonthWithData).format('MMMM YYYY');
                } else {
                    lastDataMonthText = app.moment(lastMonthWithData).format('MMMM YYYY');
                }
            }

            return (
                <CalendarItem
                    key={idx}
                    {...{
                        count,
                        lastDataMonthText,
                        monthText: `${app.moment(month, 'M').format('MMMM')} ${year}`,
                    }}
                />
            );
        })
        .reverse();
};

// to get the previous active year
function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}

const YearMarkers = ({ calendarMap, year, monthsWithData, index }) => {
    const { active, today } = useContext(TimelineContext);
    const activeYear = active.id.split('-')[1];
    const todayYear = String(app.moment(today).year());
    const prevActiveYear = usePrevious(activeYear);
    const [expanded, setExpanded] = useState((activeYear || todayYear) === year);

    // will expanded if this year is active but not expanded
    useEffect(() => {
        if (activeYear === year && !expanded) {
            setExpanded(true);
        } else if (prevActiveYear !== activeYear && prevActiveYear === year) {
            setExpanded(false);
        }
    }, [activeYear]);

    return (
        <div className="year-container" key={index}>
            <div className="year-marker-container">
                <p className="year-marker-text" onClick={() => setExpanded(!expanded)}>
                    {expanded ? <i className="arrow-up"></i> : <i className="arrow-down"></i>}
                    {year}
                </p>
            </div>
            {expanded ? (
                <ul className="year-marker">{monthMarkers(calendarMap, year, monthsWithData)}</ul>
            ) : (
                <>
                    <ul className="year-marker"></ul>
                    <br />
                </>
            )}
        </div>
    );
};

YearMarkers.propTypes = {
    calendarMap: PropTypes.object.isRequired,
    year: PropTypes.string,
    monthsWithData: PropTypes.array,
    index: PropTypes.number,
};

const CalendarPanel = ({ calendarMap, intro }) => {
    const [showPanel, setShowPanel] = useState(true);
    const [PANEL_START_SIZE, setPANEL_START_SIZE] = useState(275);
    const ref = useRef();
    const { initResize } = useResize(
        ref,
        RESIZE_STEP,
        PANEL_START_SIZE,
        PANEL_STOP_SIZE,
        false,
        () => handleDragResize(),
    );
    const { setHash, setActive, active } = useContext(TimelineContext);

    const handleWindowSizeChange = () => {
        // TODO: probably should make this into a customHook as used elsewhere
        const refWidth = ref.current.style.width;

        // Make sure if the panel is closed and the window resized the panel control
        // isn't extended when it shouldn't be
        if (`${PANEL_STOP_SIZE}px` != refWidth) {
            setPANEL_START_SIZE(window.innerWidth * 0.25);
            ref.current.style.width = `${window.innerWidth * 0.25}px`;
        }
    };

    const handleMainWidth = () => {
        const mainPane = document.querySelector('.pane-main');
        mainPane.style.width = `${$(window).width() - ref.current?.offsetWidth}px`;
        mainPane.style.left = `${ref.current?.offsetWidth}px`;
    };

    const handleDragResize = () => {
        if (ref.current?.offsetWidth < PANEL_CONTENT_SHOW_SIZE) {
            setShowPanel(false);
        } else {
            setShowPanel(true);
        }
        handleMainWidth();
    };

    useEffect(() => {
        const panelWidth = window.innerWidth * 0.25;

        setPANEL_START_SIZE(panelWidth);
        ref.current.style.width = `${panelWidth}px`;

        window.addEventListener('resize', handleWindowSizeChange);
        return () => window.removeEventListener('resize', handleWindowSizeChange);
    }, []);

    useEffect(() => {
        // TODO: probably should make this into a customHook as used similarly elsewhere
        // This handles if the calendar panel has been clicked to collapse rather than resized on drag
        const handleClick = () => {
            const shouldShow = !showPanel;
            shouldShow
                ? (ref.current.style.width = `${PANEL_START_SIZE}px`)
                : (ref.current.style.width = `${PANEL_STOP_SIZE}px`);
            handleMainWidth();
            setShowPanel(shouldShow);
        };

        const collapser = document.getElementById('collapse-control');
        collapser.addEventListener('click', handleClick);
        return () => collapser.removeEventListener('click', handleClick);
    }, [showPanel]);

    const monthsWithData = useMemo(() => {
        let monthsWithData = [];
        for (const year in calendarMap) {
            for (const month in calendarMap[year]) {
                if (calendarMap[year][month])
                    // this format is used to make sorting the array easy
                    monthsWithData.push(app.moment(`${year} ${month}`).format('YYYYMM'));
            }
        }
        return monthsWithData.sort().reverse();
    }, [calendarMap]);

    return (
        <div
            ref={ref}
            className={`pane-left hide-scrollbars calendar-panel ${showPanel ? 'open' : 'closed'}`}
            id="time-nav"
            role="complementary"
        >
            <CalendarPanelControl {...{ showPanel, initResize }} />
            {showPanel && ref.current?.offsetWidth > PANEL_CONTENT_SHOW_SIZE && (
                <>
                    {intro && (
                        <div
                            className={`intro-marker ${
                                active.id.includes('intro') ? 'active' : ''
                            }`}
                            onClick={() => {
                                setHash('intro');
                                setActive({ id: 'intro', active: true });
                            }}
                        >
                            {intro.replace(/(<([^>]+)>)/gi, '')}
                        </div>
                    )}
                    {Object.keys(calendarMap)
                        .reverse()
                        .map((year, index) => (
                            <YearMarkers
                                key={index}
                                calendarMap={calendarMap}
                                year={year}
                                monthsWithData={monthsWithData}
                                index={index}
                            />
                        ))}
                </>
            )}
        </div>
    );
};

export default CalendarPanel;

CalendarPanel.propTypes = {
    calendarMap: PropTypes.object.isRequired,
    intro: PropTypes.string,
};
