module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<label class="toc-item" role="treeitem">\n    <span class="toc-title">\n        <button aria-hidden="true" role="presentation" class="toc-icon search-modal-button"><i></i></button>\n        <input class="toc-select family-slug" type="checkbox" value="'+
((__t=( slug ))==null?'':_.escape(__t))+
'">\n        '+
((__t=( title ))==null?'':_.escape(__t))+
'\n    </span>\n</label>\n<ul role="group"></ul>\n';
}
return __p;
};
