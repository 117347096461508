import React from 'react';
import PropTypes from 'prop-types';

import PiconIcon from './Icons/PiconIcon';

const Chip = ({ state, children, onClick }) => {
    return (
        <span onClick={onClick} className={`flag flag-${state}`}>
            {children}
        </span>
    );
};

const PiconChip = ({ state, iconName, className, children, onClick }) => {
    return (
        <Chip {...{ state, onClick }}>
            <PiconIcon {...{ iconName, className: `left ${className}` }}></PiconIcon>
            {children}
        </Chip>
    );
};

export { Chip, PiconChip };

//#region propTypes

Chip.defaultProps = {
    onClick: () => {},
};

PiconChip.defaultProps = {
    className: '',
};

Chip.propTypes = {
    state: PropTypes.string.isRequired,
    children: PropTypes.node,
    onClick: PropTypes.func,
};

PiconChip.propTypes = {
    state: PropTypes.string.isRequired,
    children: PropTypes.node,
    className: PropTypes.string,
    iconName: PropTypes.string.isRequired,
    onClick: PropTypes.func,
};

//#endregion
