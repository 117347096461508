import React, { useState, useReducer } from 'react';
import { useDropzone } from 'react-dropzone';

import FileTable from './FileTable';
import { parseFile } from '../../utils/upload';
import { WithLoadingOverlay } from '../FormControls/WithLoading';
import PiconIcon from '../FormControls/Icons/PiconIcon';
import { FileContextWrapper, fileReducer, FILE_ACTION } from './FileContext';
import FileUpload from './FileUpload';

const UploadDocumentManage = () => {
    const [filesState, dispatchFilesState] = useReducer(fileReducer, {
        filesTree: {
            path: '',
            branchPath: '',
            children: [],
        },
        files: [],
    });

    const [loading, setLoading] = useState(false);

    const onDrop = async (acceptedFiles) => {
        setLoading(true);

        const parsedUploads = await Promise.all(_.map(acceptedFiles, (file) => parseFile(file)));
        dispatchFilesState({
            type: FILE_ACTION.ADD_FILES,
            parsedUploads,
        });

        setLoading(false);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const dropzoneProps = {
        webkitdirectory: '',
        directory: '',
        multiple: '',
    };
    return (
        <div className="rbs4">
            <FileContextWrapper {...{ filesState, dispatchFilesState }}>
                <WithLoadingOverlay loading={loading}>
                    <>
                        <div className="upload-controls">
                            <div
                                {...getRootProps({
                                    className: `drag-drop${isDragActive ? ' drag' : ''}`,
                                })}
                            >
                                <input {...getInputProps(dropzoneProps)} />
                                <span>Click to browse or drag a folder here</span>
                                <PiconIcon iconName="download" />
                            </div>
                            <FileUpload setLoading={setLoading} />
                        </div>

                        <FileTable />
                    </>
                </WithLoadingOverlay>
            </FileContextWrapper>
        </div>
    );
};

export default UploadDocumentManage;
