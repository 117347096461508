module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<li>\n    ';
 if (document.doc_id == 'reg-timeline') { 
__p+='\n    ';
 } else { 
__p+='\n    <a class="btn btn-lg" href="'+
((__t=( app.urls.documents ))==null?'':_.escape(__t))+
'/'+
((__t=( document.doc_id ))==null?'':_.escape(__t))+
'">\n        <span class="select">Select</span>\n        <span class="doctitle-full">'+
((__t=( document.title ))==null?'':_.escape(__t))+
'</span> \n    </a>\n    ';
 } 
__p+='\n</li>';
}
return __p;
};
