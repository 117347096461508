define([
    'backbone',
    'jquery',
    '../../models',
    '../helpers',
    './timetraveller',
    '../mixins/intermediate_window',
    '../mixins/colour_key',
    '../mixins/multi_ref',
    '../../templates/document/content.html',
    '../../templates/document/block.html',
    '../../templates/prefs/prefs.html',
    '../../widgets/dragger',
    '../../extras/backbone-mixin',
    '../../extras/app',
], function (
    Backbone,
    $,
    models,
    helpers,
    TimeTraveller,
    IntermediateWindowMixin,
    ColourKeyMixin,
    MultiRefMixin,
    contentTpl,
    blockTpl,
    prefsTpl,
) {
    const React = require('react');
    const ReactDOM = require('react-dom');
    const { highlightTerms } = require('../../extras/highlighting');
    const { escapeDOMId } = require('../../extras/index');
    const { overrideIds } = require('../../components/utils');

    /*
        ContentView

        Terminology: chunks are the data, blocks are the rendered content

        In order to maintain a fetch/render split, this.norris is a
        models.ChunkNorris instance that acts as the model to the view, firing
        chunk:fetched events with data, which this view subscribes to, rendering
        the data passed in the event.
    */
    var ContentView = helpers.TemplateView.extend({
        el: '.pane-main',
        template: contentTpl,
        cssPrefsTemplate: prefsTpl,
        initialBlockHeight: 1024, // blank block default height
        activeBlockPoint: { x: 200, y: 51 }, // a block at this point from the top left corner of the element's viewport is considered in scope
        activeBlock: 0, // Block which contains active block point
        activeElement: '', // Current active element of document - nearest id before the active block point
        activeSection: '', // Section containing active element
        sectionBlock: 0, // Block containing first element of current section
        loadedBlocks: [], // Currently loaded + rendered blocks
        requiredBlocks: [], // List of blocks needed to fill screen + extra
        navHash: '', // Last requested navigation ID
        enableScroll: true, // Global scroll event enable
        navigateOnHashChange: true, // trigger a navigate event when the url hash changes

        templateHelpers: function () {
            this.curr_doc.populate();
            var valid_instances = this.curr_doc.instances.filter(function (model) {
                return (
                    model.get('state') == 'published' ||
                    model.get('state') == 'draft' ||
                    model.get('state') == 'ready'
                );
            });
            var is_news = this.curr_doc
                .getInstance()
                .get('families')
                .some((family) => family.slug === 'news');
            var is_survey = this.curr_doc
                .getInstance()
                .get('families')
                .some((family) => family.slug === 'srv');
            return {
                valid_instances: valid_instances,
                doc_id: this.curr_doc.get('doc_id'),
                doc_state: this.curr_doc.getInstance().get('state'),
                timetravel: this.curr_doc.getInstance().get('timetravel'),
                is_news: is_news,
                is_survey: is_survey,
                url_path: is_news ? 'news' : is_survey ? 'surveys' : 'documents',
            };
        },
        events: {
            scroll: 'scroll',
            'shown.bs.popover .dd-flag-anno': 'truncateExtract',
            mousedown: 'mouse_down',
            mouseup: 'mouse_up',
            mouseenter: 'mouse_enter',
            mousemove: 'mouse_move',
            mouseleave: 'mouse_leave',
        },
        mouse_leave: function (e) {
            this.clearColourKey(e);
            if (!this.post_sel_mcount && document.getSelection().toString().length < 1) {
                this.reset_doc();
            }
            this.post_sel_mcount = 0;
        },
        mouse_move: function (e) {
            this.updateColourKey(e);
            // eslint-disable-next-line no-prototype-builtins
            if (this.hasOwnProperty('up_mouse')) {
                if (this.up_mouse) {
                    this.post_sel_mcount++;
                }
            }
        },
        mouse_enter: function () {
            // If in Side-by-Side, make sure the panel our mouse is in is the active panel
            if (app._compareView && document.hasFocus()) {
                if ($(this.el).hasClass('compare-left')) {
                    app._compareView.focusLeft();
                } else if ($(this.el).hasClass('compare-right')) {
                    app._compareView.focusRight();
                }
            }
            delete this.up_mouse;
            if (document.getSelection().toString().length < 1) {
                this.reset_doc();
            }
        },
        //disables ctrl+A
        cancel_all: function (e) {
            if (e.ctrlKey) {
                if (e.charCode == 97) {
                    e.preventDefault();
                } else if (e.charCode == 99) {
                    var sel = document.getSelection();
                    if (sel.focusNode != null) {
                        var cls = sel.focusNode.className;
                        var has_titlebar = cls.search('with-titlebar');
                        var has_toolbar = cls.search('with-toolbar');
                        if (has_titlebar || has_toolbar) {
                            e.preventDefault();
                            sel.collapse(sel.anchorNode, 0);
                        }
                    }
                }
            }
        },
        mouse_down: function (e) {
            if (e.which == 1) {
                this.up_mouse = false;
                this.post_sel_mcount = 0;
                this.reset_doc();
            }
        },
        mouse_up: function (e) {
            if (e.which == 1) {
                this.up_mouse = true;
                var sel = document.getSelection();
                this.hidden = Array();
                if (!sel.isCollapsed) {
                    // Removing hidden elements within the selection.
                    var rng = sel.getRangeAt(0);
                    var gp = rng.commonAncestorContainer;
                    var blocks = false;
                    for (i = 0; i < gp.childNodes.length; i++) {
                        var parnt = gp.childNodes[i];
                        if (parnt.nodeName == '#text') {
                            continue;
                        }
                        var cls = parnt.className;
                        if (cls?.search('loading') >= 0) {
                            blocks = true;
                            //block not yet loaded.
                            continue;
                        } else if (cls.search('container') >= 0) {
                            //parent is a container.
                            blocks = true;
                            var hidden_children = Array();
                            var hc;
                            hc = this.find_hidden_children(parnt.childNodes, hidden_children);
                            for (let j = 0; j < hc.length; j++) {
                                this.hidden.push(hc[j]);
                            }
                            hc = '';
                        }
                    }
                    // ca-container does not contain any sub-containers.
                    if (!blocks) {
                        const hidden_children = Array();
                        var hcs = this.find_hidden_children(gp.childNodes, hidden_children);
                        if (hcs.length) {
                            for (i = 0; i < hcs.length; i++) {
                                this.hidden.push(hcs[i]);
                            }
                        }
                    }
                    // showing all hidden elements...
                    var i;
                    for (i = 0; i < this.hidden.length; i++) {
                        var n = this.hidden[i];
                        var browser = manager.win.navigator.appName; //eslint-disable-line no-undef
                        if (browser == 'Netscape') {
                            try {
                                n[1].remove(); // no supported in IE 11
                            } catch (e) {
                                const rng = document.createRange();
                                rng.selectNode(n[1]);
                                rng.deleteContents();
                            }
                        } else if (browser == 'Microsoft Internet Explorer') {
                            const rng = document.createRange();
                            rng.selectNode(n[1]);
                            rng.deleteContents();
                        }
                    }
                }
            }
        },
        find_hidden_children: function (children, hc) {
            if (children.length > 0) {
                var parnt = children[0].parentNode;
                var child_nodes = children;
                var i;
                for (i = 0; i < child_nodes.length; i++) {
                    var n = child_nodes[i];
                    try {
                        if (n.nodeName == '#text') {
                            continue;
                        }
                        var cls = n.className;
                        if (cls.search('tts-hidden') >= 0) {
                            var ns = n.nextSibling ? n.nextSibling : null;
                            hc.push([parnt, n, ns, false]);
                        } else {
                            var el_hidden = n.querySelectorAll('.tts-hidden, .dd-flag-note');
                            for (let k = 0; k < el_hidden.length; k++) {
                                var c = el_hidden[k];
                                hc.push([c.parentNode, c, c.nextSibling]);
                            }
                        }
                    } catch (e) {
                        console.error(e.message);
                        continue;
                    }
                }
            }
            return hc;
        },
        reset_doc: function () {
            var sel = document.getSelection();
            if (sel.anchorNode != null) {
                if (!sel.isCollapsed || this.force_reset) {
                    this.force_reset = false;
                    sel.collapse(sel.anchorNode, 0);
                    /*insert hidden elements */
                    try {
                        if (this.hidden && this.hidden.length > 0) {
                            for (let i = this.hidden.length - 1; i >= 0; i--) {
                                var hn = this.hidden[i];
                                var c = hn[1];
                                var parnt = hn[0];
                                var ns = hn[2];
                                if (ns) {
                                    parnt.insertBefore(c, ns);
                                } else {
                                    parnt.appendChild(c);
                                }
                            }
                        }
                    } catch (e) {
                        console.error(e);
                    }
                }
            }
        },
        reset_doc_for_ttView_change: function () {
            this.force_reset = true;
            this.reset_doc();
        },
        initialize: function (options) {
            this.listenTo(this.model, 'syncHistory', this.initializeHistory);
            this.listenTo(this, 'render', this.initializeColourKey);
            this.listenTo(this, 'render', this.initializeDisplayPrefs);
            //this.$el.on('copy', _.bind(this.copyContent,this));
            this.listenTo(app, 'scroll:enable', this.enableScrollUpdates);
            this.navigating = false;
            this.curr_doc = options.doc;
            this.families = this.curr_doc.getInstance().get('families');
            this.is_news = this.families.some((family) => family.slug === 'news');
            this.is_survey = this.families.some((family) => family.slug === 'srv');
            this.canScroll = true;
            if (!_.isUndefined(options.enableScroll)) {
                this.enableScroll = options.enableScroll;
            }
            const projectFams = app.user.attributes.projects.reduce(
                (arr, proj) => [...arr, proj],
                [],
            );
            this.inProject = projectFams.reduce((arr, proj) => {
                if (this.families.some((family) => proj.families.includes(family.slug))) {
                    return [...arr, proj.id];
                }
                return arr;
            }, []);

            this.model.fetchTOC();
            if (this.model.get('timetravel')) {
                this.timeTraveller = new TimeTraveller(this);
                this.listenTo(this.model, 'timetravel:change', this.timeTravel);
            }
            this.norris = new models.ChunkNorris({ model: this.model });
            this.listenTo(this.norris, 'initialized', this.initializeDocument);
            this.listenTo(this.model, 'document:navigate', this.navigate);
            this.listenTo(app, 'annotation:changed', this.refetchAnnotations);
            this.listenTo(app, 'favourite:changed', this.refetchFavourites);
            document.onkeypress = this.cancel_all;
            document.onkeydown = function (e) {
                if (e.ctrlKey && e.keyCode == 65) {
                    e.preventDefault();
                }
            };
            this.el.onmousedown = function () {
                this.down_mouse = true;
            };
            this.el.onmouseleave = function () {
                //eslint-disable-next-line no-undef
                if (manager.win.navigator.appName == 'Microsoft Internet Explorer') {
                    if (this.down_mouse) {
                        this.down_mouse = false;
                        throw 'drag-out of doc not permitted';
                    }
                } else {
                    document.reset_doc;
                }
            };
            document.onselect = function (e) {
                var sel = document.getSelection();
                if (sel.focusNode != null) {
                    try {
                        var cls = sel.focusNode.className;
                        var has_titlebar = cls.search('with-titlebar');
                        var has_toolbar = cls.search('with-toolbar');
                        if (has_titlebar >= 0 || has_toolbar >= 0) {
                            e.preventDefault();
                            sel.collapse(sel.anchorNode, 0);
                        }
                    } catch (e) {
                        console.error(e);
                    }
                }
            };
            window.addEventListener('copy', (evt) => {
                const selection = window.getSelection();
                const selectedContent = selection.getRangeAt(0);

                if (overrideIds.includes(selection?.focusNode?.id)) {
                    return;
                }

                let clonedContainer = selectedContent.cloneContents();
                let parentContainer = selectedContent.commonAncestorContainer;

                while (parentContainer.parentElement) {
                    const tmpContainer = document.createElement(parentContainer.tagName);
                    tmpContainer.className = parentContainer.className;
                    if (parentContainer === selectedContent.commonAncestorContainer) {
                        tmpContainer.classList.add('copy-base-container');
                    }
                    tmpContainer.appendChild(clonedContainer);
                    clonedContainer = tmpContainer;

                    if (
                        parentContainer.tagName &&
                        parentContainer.tagName.toLowerCase() === 'body'
                    ) {
                        break;
                    }
                    parentContainer = parentContainer.parentElement;
                }

                document.body.appendChild(clonedContainer);

                const allowedProps = [
                    'background-color',
                    'content',
                    'border-top-color',
                    'border-top-style',
                    'border-top-width',
                    'border-right-color',
                    'border-right-style',
                    'border-right-width',
                    'border-bottom-color',
                    'border-bottom-style',
                    'border-bottom-width',
                    'border-left-color',
                    'border-left-style',
                    'border-left-width',
                    'color',
                    'display',
                    'font-family',
                    'font-size',
                    'font-style',
                    'font-variant',
                    'font-weight',
                    'line-height',
                    'list-style-type',
                    'margin-top',
                    'margin-right',
                    'margin-bottom',
                    'margin-left',
                    'padding-top',
                    'padding-right',
                    'padding-bottom',
                    'padding-left',
                    'text-align',
                    'text-decoration-color',
                    'text-decoration-line',
                    'text-decoration-style',
                    'white-space',
                    'word-wrap',
                ];

                [...clonedContainer.getElementsByTagName('*')].forEach((element) => {
                    const computedStyle = document.defaultView.getComputedStyle(element, '');

                    if (
                        $(element).hasClass('sr-only') ||
                        $(element).hasClass('hidden') ||
                        $(element).hasClass('skiplinks') ||
                        $(element).closest('svg').length > 0
                    ) {
                        // Remove screen reader only text, hidden elements or text in SVGs
                        $(element).remove();
                    } else {
                        const cssText = Object.values(computedStyle)
                            .filter((prop) => allowedProps.includes(prop))
                            .map((prop) => {
                                const propertyValue = computedStyle.getPropertyValue('text-align');
                                if (
                                    prop === 'text-align' &&
                                    (propertyValue === 'start' || propertyValue === 'right')
                                ) {
                                    // make sure copied text actually aligns left unless it's centred
                                    return 'text-align:left;';
                                }
                                return `${prop}:${computedStyle.getPropertyValue(prop)};`;
                            });
                        // For the benefit of Word which only recognises text-decoration shorthand
                        cssText.push(
                            `text-decoration:${computedStyle.getPropertyValue(
                                'text-decoration-line',
                            )};`,
                        );
                        if (!$(element).hasClass('quote')) {
                            // Nested styling is making the font size too big, so this is an attempt to fix it for quotes.
                            // There needs to be a proper ticket to address this, if it's something Pendragon want fixing
                            if ($(element).parents('.quote').length === 0) {
                                element.setAttribute('style', cssText.join(''));
                            }
                        }

                        if (element.tagName.toUpperCase() === 'A') {
                            element.setAttribute('href', element.href); // href property is absolute URL rather than relative
                        }
                    }
                });

                evt.clipboardData.setData('text/plain', selection.toString());
                evt.clipboardData.setData(
                    'text/html',
                    clonedContainer.getElementsByClassName('copy-base-container')[0].innerHTML,
                );

                if (
                    options.model.ttViewOption !== 'tt-current' &&
                    !$('body').find("[aria-label='copy-location-modal']").length
                ) {
                    const confirm =
                        require('../../components/FormControls/ConfirmModal/ConfirmModalService').default;
                    confirm({
                        title: 'Copy and Paste Warning',
                        message:
                            'WARNING: We recommend that you only copy and paste when in the Current View. Copying text from other views may include text that has been repealed or has not been brought into force.',
                        showCancel: false,
                    }).then(() => {
                        selection.removeAllRanges();
                        selection.addRange(selectedContent);
                    });
                }

                document.body.removeChild(clonedContainer);
                evt.preventDefault();
            });
        },
        initializeDisplayPrefs: function () {
            var displayPrefs = app.user.attributes.display_prefs;

            // Build stylesheet
            var html = this.cssPrefsTemplate(displayPrefs);

            // Add the stylesheet to the head
            $('head').append(
                '<style id="display-prefs-styles" type="text/css">' + html + '</style>',
            );
        },
        initializeDocument: function (tocId, mainId, target) {
            this.isInitialised = true;
            this.trigger('model:ready');

            const toc = tocId ? tocId : 'toc';
            const main = mainId ? mainId : 'main';
            const annoId = target ? 'anno-' + target : 'anno';

            // Add the current doctype to the toc and main pane
            $('#' + toc).addClass(this.norris.doctype);
            const mainEl = $('#' + main).addClass(this.norris.doctype);

            // remove old annotations
            const oldAnnotations = $('#' + annoId);
            if (oldAnnotations !== 0) {
                oldAnnotations.remove();
            }
            mainEl.after(
                `<div class="pane-right pane-anno with-panetitle${
                    target ? ' compare-' + target + '-pane-right compare-' + target : ''
                }" id="${annoId}" role="complementary" style="width: 0px;"></div>`,
            );

            // add railway stuff (yukky yuk)
            if (this.norris.railway) {
                $('#' + tocId).addClass('rwy');
                $('#' + mainId).addClass('rwy');
            }

            // highlight terms
            this.listenTo(this, 'block:rendered', this.highlightTerms);

            // initialize history
            this.once('block:rendered', this.model.fetchHistory, this.model);

            this.blockRange = _.range(this.norris.chunkCount());
            this.renderBlockContainers();
            this.fetchInitialChunk();
            $(window).on('hashchange', _.bind(this.hashChange, this));
            this.model.trigger('timetravel:change');

            if (this.options.annotations) {
                const AnnotationPanel =
                    require('../../components/DocumentAnnotations/AnnotationPanel').default;
                const element = document.getElementById(annoId);
                if (element) {
                    ReactDOM.render(
                        <AnnotationPanel
                            annotations={this.options.annotations}
                            inProject={this.inProject}
                            content={this}
                            paneId={main}
                            annoId={annoId}
                        />,
                        element,
                    );
                }
            }
        },
        copyContent: function (evt) {
            if (window.clipboardData) {
                evt.preventDefault();
                try {
                    var sel = document.getSelection();
                    var rng = sel.getRangeAt(0);
                    var frg = rng.cloneContents(); // gets the document fragment.
                    var hs = '';
                    var children = frg.childNodes;
                    for (let i = 0; i < children.length; i++) {
                        const cn = children[i];
                        if (cn.nodeName != '#text') {
                            if (cn.className.search('tts-hidden') >= 0) {
                                continue;
                            }
                            hs += cn.outerHTML;
                        } else {
                            hs += cn.textContent;
                        }
                    }
                    // eslint-disable-next-line no-unused-vars
                    var $copy_buffer = $('<div id="copy-buffer">')
                        .css({
                            position: 'absolute',
                            'z-index': '-1',
                            'text-indent': '-9999px',
                        })
                        .html(hs)
                        .appendTo('body')
                        .find('.tts-hidden')
                        .remove();
                    sel.selectAllChildren(document.getElementById('copy-buffer'));
                    this.$el.unbind('copy');
                    document.execCommand('copy');
                    $('#copy-buffer').remove();
                } catch (e) {
                    console.error(e.message);
                    return;
                }
                var fs = window.clipboardData.getData('Text');
                fs = fs.replace(/[\r\n\r\n]+/g, '\n');
                var chars = fs.split('');
                chars.forEach(function (i, idx) {
                    var cd = i.charCodeAt(0);
                    if (cd == 8226 || cd == 9726) {
                        chars[idx] = '\t';
                    }
                });
                // removes unwanted characters.
                var allowed_chars = [8211, 8212, 8220, 8221];
                var s = chars.filter(function (i) {
                    var c = i.charCodeAt(0);
                    return (
                        c < 255 ||
                        allowed_chars.some(function (k) {
                            return c == k;
                        })
                    );
                });
                s = s.join('');
                window.clipboardData.setData('Text', s);
                //this.$el.on('copy', _.bind(this.copyContent,this));
            }
        },
        truncateExtract: function () {
            var p = $('.anno-extract p');
            var divh = $('.anno-extract').height();
            while ($(p).outerHeight() > divh) {
                $(p).text(function (index, text) {
                    return text.replace(/\W*\s(\S)*$/, '...more');
                });
            }
            $('.anno-extract').trigger('change');
        },
        initializeHistory: function () {
            var popover_content =
                '<div class="popover-content hidden"><p>Click to view notes</p></div>';
            var $notes_link = $(
                '<span class="dd-flag dd-flag-docnotes" title="Document history notes" id="notes-history"><a class="icon" href="/notes/' +
                    this.curr_doc.id +
                    '/' +
                    this.model.id +
                    '/doc.history/#docnotes"><span class="picon picon-history"></span><span class="sr-only">Document history notes</span></a>' +
                    popover_content +
                    '</span>',
            );
            this.$('.inner').append($notes_link);
            $notes_link.popover({
                container: 'body',
                placement: 'right',
                trigger: 'hover',
                delay: 100,
                template:
                    '<div class="popover popover-dd-flag" id="notes-popover-history"><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"></div></div>',
                html: true,
                content: function () {
                    return $(this).find('.popover-content').html();
                },
            });
        },
        enableScrollUpdates: function (enable) {
            this.enableScroll = enable;
        },
        renderBlockContainers: function () {
            var blockContainers = blockTpl({
                blockRange: this.blockRange,
                height: this.initialBlockHeight,
            });
            this.$('.inner').html(blockContainers);

            // active block point debug

            /*var viewportRect = this.el.getBoundingClientRect();
            $('<div>')
            .css({
                position: 'absolute',
                top: viewportRect.top + this.activeBlockPoint.y,
                left: viewportRect.left,
                width: '195px',
                height: '1px',
                'background-color': '#F00',
                'z-index': '100000'
            })
            .appendTo('body');  */
        },
        fetchInitialChunk: function () {
            // If we're coming from compare view, we need to grab the hash differently
            var elementID =
                this.options?.paneHash ||
                this.options?.right?.paneHash ||
                window.location.hash.replace(/^#/, '');

            if (
                elementID &&
                !_.isUndefined(this.norris.ids[elementID]) &&
                elementID != this.curr_doc.get('doc_id')
            ) {
                this.once('navigate:done', function () {
                    this.model.documentReady = true; // set this to avoid the ToC race condition in #708
                    app.trigger('document:ready');
                    this.model.trigger('document:ready');
                });
                this.navigate(elementID);
            } else {
                this.once(
                    'document:allBlocksRendered',
                    function () {
                        this.activeElement =
                            this.is_news || this.is_survey ? 'header' : this.model.get('document'); // if in news, make sure not to cut off header
                        this.activeSection = this.model.get('document');
                        this.scrollToNearestVisibleElement(this.activeElement);
                        this.model.documentReady = true; // set this to avoid the ToC race condition in #708
                        app.trigger('document:ready');
                        this.model.trigger('document:ready');
                    },
                    this,
                );
                this.update();
            }
        },
        fetchChunk: function (chunkID) {
            this.fetchChunks(chunkID, true);
        },
        fetchChunks: function (chunkID, single) {
            this.requiredBlocks.push(chunkID);
            this.requiredBlocks = Array.from(new Set(this.requiredBlocks));
            // this.requiredBlocks = _.unique(this.requiredBlocks);
            if (!this.blockIsRendered(chunkID)) {
                this.listenToOnce(
                    this.norris,
                    'chunk:fetched',
                    _.partialRight(this.renderChunk, single),
                );
                this.norris.fetchChunk(chunkID);
            } else {
                var isUpper = chunkID < this.activeBlock;
                var nextBlock = isUpper ? chunkID - 1 : chunkID + 1;
                if (!single && !this.doesBlockFill(chunkID, isUpper)) {
                    this.fetchChunks(nextBlock, false);
                } else {
                    this.trigger('document:allBlocksRendered'); // this is a bit of a misnomer ¯\_(ツ)_/¯
                    this.trigger('document:allChunksFetched'); // this gets triggered several times ¯\_(ツ)_/¯
                }
            }
        },
        doesBlockFill: function (blockID, upper) {
            if (_.isNaN(blockID) || _.isUndefined(blockID)) {
                return true;
            }

            var viewportRect = this.el.getBoundingClientRect();
            var blockEl = this.$('#block' + blockID)[0];

            if (_.isUndefined(blockEl)) {
                return true;
            }

            if (upper) {
                const upperCheck =
                    this.$('#block' + this.activeBlock)[0].getBoundingClientRect().top - 500;
                return blockID <= 0 || upperCheck > blockEl.getBoundingClientRect().top;
            } else {
                const lowerCheck = viewportRect.bottom + 1000;
                return (
                    blockID >= this.norris.chunks.length - 1 ||
                    lowerCheck < blockEl.getBoundingClientRect().bottom
                );
            }
        },
        renderBlockContent: function (chunkID, data, single) {
            var isUpper = chunkID < this.activeBlock;
            var nextBlock = isUpper ? chunkID - 1 : chunkID + 1;
            var $data = data;
            var $block = this.$('#block' + chunkID);
            var initialBlockHeight = $block.height();
            var currentPosition = this.$el.scrollTop();
            var blockFills = false;
            this.setImgs(chunkID, $data);
            this.setHrefs(chunkID, $data);

            // Don't render if this whole block is hidden
            if (data.children('div').length > data.children('div.tts-hidden').length) {
                $block.html($data.contents()).height('auto').removeClass('loading');

                // adjust block position if we're loading blocks from above while scrolling as the new
                // chunks wil be pushing the active block downwards
                if (!this.navigating && chunkID <= this.activeBlock) {
                    this.$el.scrollTop(currentPosition + ($block.height() - initialBlockHeight));
                }
                // Add notes if any sections
                var $sections = $block
                    .find(
                        '.is-part.has-history, .is-part.has-ftnotes, .is-section.has-ftnotes,.is-section.has-history,.is-schsec.has-ftnotes,.is-schsec.has-history,.is-subpart.has-history, is-supbpart.has-ftnotes',
                    )
                    .not('[guardian_id]');
                $sections.each(
                    _.partial(function (that) {
                        var notes_type = $(this).hasClass('has-modapp') ? 'modifications' : 'note';
                        var title_text =
                            'Notes for ' + that.norris.getLabelFromId($(this).attr('id'));

                        if ($(this).hasClass('has-modapp')) {
                            title_text += '<br>This provision is subject to modification';
                        }
                        var popover_content =
                            '<div class="popover-content hidden"><p>' +
                            ($(this).hasClass('has-modapp')
                                ? 'Click to view details'
                                : 'Click to view notes') +
                            '</p></div>';
                        var $notes_link = $(
                            '<span class="dd-flag dd-flag-' +
                                notes_type +
                                '" title="' +
                                title_text +
                                '" id="notes-' +
                                $(this).attr('id') +
                                '"><a class="icon" href="/notes/' +
                                that.curr_doc.id +
                                '/' +
                                that.model.id +
                                '/' +
                                this.id +
                                '#notes"><span id="notes-span-' +
                                this.id +
                                '" class="picon picon-' +
                                notes_type +
                                '">' +
                                popover_content +
                                '<span class="sr-only">' +
                                title_text +
                                '</span></span></a></span>',
                        ).css('top', 5);

                        $notes_link.hover(
                            function () {
                                $(this).find('a').popover('show');
                            },
                            function () {
                                $(this).find('a').popover('hide');
                            },
                        );

                        $notes_link.find('a').popover({
                            container: 'body',
                            placement: 'right',
                            title: title_text,
                            trigger: 'manual',
                            template:
                                '<div class="popover popover-dd-flag" id="notes-popover-' +
                                $(this).attr('id') +
                                '"><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"></div></div>',
                            html: true,
                            content: function () {
                                return $(this).find('.popover-content').html();
                            },
                        });

                        $(this).append($notes_link);
                    }, this),
                );

                // In case the doc is initialised while time travelled
                this.updateNotesLink(this.model);
                this.setFavourites($block);

                // Create multi link popovers
                this.convertMultiRefLinks($block);

                // create the annotations icon and trigger for modals
                this.setAnnotations($block);

                blockFills = this.doesBlockFill(chunkID, isUpper);
            } else {
                // the contents have all been repealed, remove html and
                // loading class
                $block.html('').height('auto').removeClass('loading');
            }

            this.trigger('block:rendered', chunkID);
            this.loadedBlocks.push(chunkID);
            // maintain uniqueness here otherwise it gets hairy
            this.loadedBlocks = _.uniq(this.loadedBlocks);

            if (!single && !blockFills) {
                this.fetchChunks(nextBlock, false);
            } else {
                this.trigger('document:allBlocksRendered', chunkID);
            }
        },
        refetchAnnotations: function () {
            this.options.annotations.fetch();
        },
        refetchFavourites: function () {
            this.options.favourites.fetchByDoc();
        },
        renderChunk: function (chunkID, data, single) {
            var $data = $('<div>').append(data);

            if (this.model.get('timetravel')) {
                this.timeTraveller.filter(
                    $data,
                    _.bind(this.renderBlockContent, this, chunkID, $data, single),
                );
            } else {
                this.renderBlockContent(chunkID, $data, single);
            }
        },
        setFavourites: function ($block) {
            const DocumentFavourite =
                require('../../components/DocumentFavourites/DocumentFavourite').default;

            const $fave_secs = $block
                .find('.is-section, .is-schsec')
                .not('.tts-hidden')
                .not('[guardian_id]')
                .addClass('has-faves');
            $fave_secs.each(
                _.partial(function (that) {
                    const el = $('<span class="rbs4 dd-flags-fave has-faves"></span>');
                    // setFavourite gets called multiple times especailly if we're time-travelling or searching,
                    // so make sure we're only keeping final time the favourite for the block is set and remove
                    // the previously set favourite or the frontend goes funny.
                    $(this).find('span.rbs4.dd-flags-fave.has-faves').remove();
                    $(this).append(el);
                    const sectionID = $(this).attr('id');
                    const sectionLabel = that.norris.getLabelFromId(sectionID);
                    ReactDOM.render(
                        <DocumentFavourite
                            document={that}
                            sectionID={sectionID}
                            sectionLabel={sectionLabel}
                            canDelete={true}
                        />,
                        el[0],
                    );
                }, this),
            );
        },
        highlightTerms: function (chunkID) {
            var $block = this.$('#block' + chunkID);
            $block.find('.with-hits').removeClass('with-hits');
            const searchHitMap = _.pickBy(this.hitMap, (value) =>
                value.find((v) => v.type == 'sec_notes'),
            );
            window.searchHitMap = searchHitMap;
            highlightTerms($block, chunkID, this.hitMap, this.norris);

            // check if the block has search hits, and reset the annotation's and favourite's modal triggers
            if ($block.find('.with-hits')) {
                this.setAnnotations($block);
                this.setFavourites($block);
            }
        },
        highlightVisibleBlocks: function () {
            this.loadedBlocks.forEach((id) => this.highlightTerms(id));
        },
        timeTravel: function (blockID) {
            this.reset_doc_for_ttView_change();
            var blockIDs = !blockID ? this.loadedBlocks : [blockID];
            var numBlocks = blockIDs.length;
            _.each(
                blockIDs,
                _.bind(function (id) {
                    var currentBlock = this.$('#block' + id);
                    this.timeTraveller.filter(
                        currentBlock,
                        _.bind(
                            function (currEl) {
                                this.activeElement = currEl;
                                this.getActiveSection();
                                numBlocks--;
                                if (this.activeElement != currEl && numBlocks == 0) {
                                    this.navigate(currEl);
                                }
                                //this.scrollToNearestVisibleElement(currEl);
                            },
                            this,
                            this.activeElement,
                        ),
                    );
                    this.setFavourites(currentBlock);
                }, this),
            );
            this.updateNotesLink(this.model);
        },
        setAnnotations: function ($block) {
            const DocumentAnnotation =
                require('../../components/DocumentAnnotations/DocumentAnnotation').default;
            const $anno_secs = $block
                .find('.is-section, .is-schsec')
                .not('.tts-hidden')
                .not('[guardian_id]');
            $anno_secs.each(
                _.partial(function (that) {
                    const el = $('<span class="rbs4 dd-flags-anno has-annos"></span>');
                    // setAnnotations gets called multiple times especailly if we're time-travelling or searching,
                    // so make sure we're only keeping final time the annotation for the block is set and remove
                    // the previously set annotation or the frontend goes funny.
                    $(this).find('span.rbs4.dd-flags-anno.has-annos').remove();
                    $(this).append(el);
                    const sectionID = $(this).attr('id');
                    const sectionLabel = that.norris.getLabelFromId(sectionID);
                    ReactDOM.render(
                        <DocumentAnnotation
                            doc={that}
                            sectionID={sectionID}
                            sectionLabel={sectionLabel}
                            inProject={that.inProject}
                        />,
                        el[0],
                    );
                }, this),
            );
        },
        setImgs: function (chunkID, $html) {
            /*
                Find all the image and anchor tags with src and href attributes that
                point to a relative resource in the same directory i.e. without a
                forward slash anywhere, then prepend the document's media url.
            */
            var view = this;
            var baseUrl = this.model.getMediaURL();

            $html
                .find('img')
                .on('load', function () {
                    if (chunkID < view.activeBlock) {
                        var currentPosition = view.$el.scrollTop();
                        view.$el.scrollTop(currentPosition + $(this).height());
                    }
                })
                .end()
                .find('img:not([src*="/"])')
                .each(function () {
                    var img = $(this);
                    img.prop('src', baseUrl + img.attr('src'));
                })
                .end();

            return $html;
        },
        setHrefs: function (chunkID, $html) {
            /*
                Alter the media URL for off site links
            */
            $html
                .find('a[href^="perspective:"]') // links to perspective app
                .each(function () {
                    var frag = $(this).attr('href').substr('perspective:'.length).split('-');

                    $(this).prop(
                        'href',
                        app.urls.documents + '/' + frag[0] + '-' + frag[1] + '#' + frag.join('-'),
                    );
                });

            var baseUrl = this.model.getMediaURL();
            $html
                .find('a:not([href*="/"]):not([href^="mailto:"]):not([href="#"])') // links to content
                .each(function () {
                    var lnk = $(this);
                    lnk.prop('href', baseUrl + lnk.attr('href'));
                });

            $html.find('a[href^="http"]').each(function () {
                var lnk = $(this);
                lnk.prop('href', decodeURIComponent(lnk.attr('href')));
                lnk.attr('target', '_blank');
            });

            return $html;
        },
        blockIsRendered: function (blockID) {
            return this.$('#block' + blockID).html() != '';
        },
        getActiveBlock: function () {
            /*
                Gets the block currently in the viewport
            */
            var viewportRect = this.el.getBoundingClientRect();
            var topPoint = viewportRect.top + this.activeBlockPoint.y;
            var block;

            var visibleWindows = $('.windowbox:visible');
            visibleWindows.hide();

            while (topPoint > 0 && (!block || !block.hasClass('block-container'))) {
                block = this.$(
                    document.elementFromPoint(
                        viewportRect.left + this.activeBlockPoint.x,
                        topPoint,
                    ),
                ).closest('.block-container');
                topPoint--;
            }

            visibleWindows.show();

            // We are at end of doc (probably)
            if (_.isEmpty(block)) {
                block = this.$('.block-container').last();
            }

            return block.data('block');
        },
        purgeBlocks: function () {
            /*
                Remove the html from all the blocks that aren't in scope
            */

            var blocks = _.difference(this.loadedBlocks, _.uniq(this.requiredBlocks));

            // keep preceding block to current active one
            if (this.activeBlock > 0) {
                blocks = _.difference(blocks, [this.activeBlock - 1]);
            }

            // Don't drop block with active section div in (we need to attach notes to this div)

            var currScroll = this.$el.scrollTop();

            _.each(
                blocks,
                _.bind(function (blockID) {
                    this.canScroll = false;

                    var $block = this.$('#block' + blockID);
                    var elHeight = $block.height();
                    $block.html('').height(elHeight).addClass('loading');

                    this.$el.scrollTop(currScroll);
                    //this.updateNotes();

                    _.pull(this.loadedBlocks, blockID);
                    this.canScroll = true;
                }, this),
            );
        },
        update: function () {
            // Recalculate and reload required blocks from current position
            this.requiredBlocks = [];
            this.once('document:allBlocksRendered', function () {
                this.once('document:allBlocksRendered', function () {
                    this.purgeBlocks();
                    this.updateNotes();
                    this.convertMultiRefLinks(this.$('#block' + this.sectionBlock));
                });
                this.fetchChunk(this.sectionBlock);
            });
            this.fetchChunks(this.activeBlock, false);
        },
        updateNotes: function () {
            // Find notes in current section
            var $secEl = $(this.el).find(
                '[id="' +
                    escapeDOMId(this.activeSection) +
                    '"],[temp_id="' +
                    escapeDOMId(this.activeSection) +
                    '"]',
            );
            var $secNote = $secEl.find('.dd-flag-note, .dd-flag-modifications');
            // Special case schedule parts with no section children
            if (_.isEmpty($secNote)) {
                $secNote = $secEl
                    .parents('.is-subpart')
                    .children('.dd-flag-note, .dd-flag-modifications');
                if (!_.isEmpty($secNote)) {
                    $secEl = $secEl.parents('.is-subpart');
                }
            }
            var $secAnnos = $secEl.find('.dd-flags-anno');
            // Reset all other notes
            $(this.el).find('.dd-flag-note, .dd-flag-modifications').not($secNote).css('top', 5);
            $(this.el)
                .find('.dd-flag-note, .dd-flag-modifications')
                .not($secNote)
                .nextAll('.hit')
                .css('top', 5);
            $(this.el).find('.dd-flags-anno').not($secAnnos).css('top', 0);

            if (
                !_.isEmpty($secAnnos) &&
                $secEl.offset()['top'] <= this.el.getBoundingClientRect().top
            ) {
                $secAnnos.css('top', -$secEl.offset()['top'] + this.el.getBoundingClientRect().top);
            } else {
                $secNote.css('top', 0);
            }
            var offset = _.isEmpty($(this.el).find('.dd-flag-docnotes')) ? 0 : 25;
            if (
                !_.isEmpty($secNote) &&
                $secEl.offset()['top'] <= this.el.getBoundingClientRect().top + offset
            ) {
                $secNote.css(
                    'top',
                    -$secEl.offset()['top'] + this.el.getBoundingClientRect().top + 5 + offset,
                );
            } else {
                $secNote.css('top', 5);
            }
            $secNote.nextAll('.hit').css('top', $secNote.css('top'));
            $(this.el).find('.dd-flag-docnotes').css('top', this.el.scrollTop); // + (this.activeBlock==0 ? 0 :5) );
        },
        updateNotesLink: function (model) {
            // This updates the notes href's of a document when timetravelling, and because of when
            // the model is initialised, it's more reliable to pass the model in directly rather than use this.model
            var noteLinks = $(this.el).find('.dd-flag a:first-child');
            _.each(noteLinks, function (note) {
                var url = new URL(note.href);
                url.searchParams.set(
                    'tt_option',
                    model.ttViewOption ? model.ttViewOption : 'tt-current',
                );
                url.searchParams.set(
                    'tt_date',
                    model.ttViewDate.format(app.settings.dateTransportFormat),
                );
                if (
                    !_.isEqual(model.ttViewDate, model.ttViewDate2) &&
                    model.ttViewOption === 'tt-between'
                ) {
                    url.searchParams.set(
                        'tt_date2',
                        model.ttViewDate2.format(app.settings.dateTransportFormat),
                    );
                }
                note.href = url.href;
            });
        },
        hashChange: function () {
            if (!this.navigateOnHashChange) {
                return;
            }
            var elementID = window.location.hash.replace(/^#/, '');
            if (!this.navigating && this.activeElement != elementID) {
                this.navigate(elementID);
            } else if (this.activeElement != elementID) {
                // Save hash in case we miss a navigatable event while navigating
                this.navHash = elementID;
            }
        },
        getActiveSection: function () {
            /*
                Attempt to work out what element defines the current location
            */

            // If we are near the top of document, use Document ID
            if ($(this.el).scrollTop() < 20) {
                if (window.location.hash != '#' + this.model.get('document')) {
                    this.activeSection = this.model.get('document');
                    this.activeElement = this.activeSection;
                    var sections = [this.activeSection];
                    this.model.trigger('document:section:change', sections, 'top');
                }
                return;
            }

            var viewportRect = this.el.getBoundingClientRect();
            var topPoint = viewportRect.top + this.activeBlockPoint.y;
            var element;

            var visibleWindows = $('.windowbox:visible');
            visibleWindows.hide();

            // work up till we find an element with an id that is not
            // a block container - make sure we check for orphans
            while (!element || element.attr('id') == 'top' || element.hasClass('block-container')) {
                element = this.$(
                    document.elementFromPoint(
                        viewportRect.left + this.activeBlockPoint.x,
                        topPoint,
                    ),
                ).closest('[id], [guardian_id], [temp_id]');
                topPoint--;
            }

            visibleWindows.show();

            if (element.attr('id') == 'main') {
                //element = this.$("#" + this.escapeDOMId(this.model.get('document')));
                this.activeSection = this.model.get('document');
                this.activeElement = this.model.get('document');
                return;
            }

            // We are beyond end of document - not necessarily?
            if (_.isEmpty(element)) {
                // get last element in doc with an id
                element = $(this.el).find('[id]').last();
            }

            var id_text = '';

            if (element.attr('id')) {
                id_text = 'id';
            } else if (element.attr('guardian_id')) {
                id_text = 'guardian_id';
            } else if (element.attr('temp_id')) {
                id_text = 'temp_id';
            }

            // Check if we just caught an ancestor of the current element
            var isAns = $('#' + escapeDOMId(this.activeElement))
                .parents()
                .filter('[id="' + element.attr(id_text) + '"]');

            var isFirstChild = true;
            var firstChild = [];

            // Check we aren't currently in the first child element
            if (!_.isEmpty(isAns)) {
                firstChild = isAns
                    .find('>:first-child')
                    .filter('[id="' + element.attr(id_text) + '"]');
                if (_.isEmpty(firstChild)) {
                    isFirstChild = false;
                }
            }

            if (
                element &&
                isFirstChild &&
                id_text != '' &&
                this.activeElement != element.attr(id_text)
            ) {
                // if we're scrolling and not using the search nav buttons, reset activeElement
                if (!this.scrollToLocation) {
                    this.activeElement = element.attr(id_text);
                    this.scrollToLocation = false;
                }

                var section = this.norris.getSectionsFromId(this.activeElement);
                if (!_.isUndefined(section) && !_.isEmpty(section)) {
                    if (this.activeSection != section[0]) {
                        this.activeSection = section[0];
                        //find block for section and add to required
                        this.sectionBlock = this.norris.ids[this.activeSection].block;
                    }
                    //this.sectionChange(this.activeElement);
                }
            }
        },
        sectionChange: function (elementID, waitForID) {
            var sections;
            if (_.isUndefined(elementID)) {
                return;
            }
            if (elementID == 'top' || elementID == this.model.get('document')) {
                sections = [this.model.get('document')];
                this.model.trigger('document:section:change', sections, 'top');
            } else {
                sections = this.norris.getSectionsFromId(elementID);
                if (!_.isUndefined(sections) && !_.isEmpty(sections)) {
                    this.activeSection = sections[0];
                } else {
                    this.activeSection = elementID;
                    sections = [this.activeSection];
                }
                var parentId = $('#' + escapeDOMId(this.activeSection)).attr('parent_id');
                this.model.trigger('document:section:change', sections, parentId);
            }

            var hasGuardian = false;
            // figure out what element we're dealing with here for scrolling and dd-flag positioning
            const element = this.$('#' + escapeDOMId(elementID));
            element.attr('temp_id', elementID);

            if (waitForID) {
                if (element.attr('guardian_id')) {
                    hasGuardian = true;
                    element.removeAttr('guardian_id');
                } else {
                    element.removeAttr('id');
                }
            }

            this.secScroll = true;
            if (window.location.hash != '#' + elementID && !waitForID) {
                if (this.navigateOnHashChange) {
                    window.location.hash = '#' + elementID;
                }
            }

            if (waitForID) {
                setTimeout(
                    _.partial(
                        function (element, guardian, id) {
                            element.removeAttr('temp_id');
                            if (guardian) {
                                element.attr('guardian_id', id);
                            } else {
                                element.attr('id', id);
                            }
                        },
                        element,
                        hasGuardian,
                        elementID,
                    ),
                    300,
                );
            }
        },
        scrollToNearestVisibleElement: function (elementID) {
            var scrollToEl = null;
            // Check visibility of current element in doc
            if (elementID && !_.isEmpty(this.$('#' + escapeDOMId(elementID)))) {
                var selectedElement = this.$('#' + escapeDOMId(elementID));
                scrollToEl = selectedElement.closest(':visible')[0];
                if (selectedElement.is('li')) {
                    var rootElement = selectedElement.parents('.amend.tts-hidden:last');
                    if (!_.isEmpty(rootElement)) {
                        var closest_item = 'first';
                        var nextVisibleRootSibling =
                            $(rootElement).nextAll('.tts-regular, .listitem');
                        var previousVisibleRootSibling =
                            $(rootElement).prevAll('.tts-regular, .listitem');
                        if (!_.isEmpty(nextVisibleRootSibling)) {
                            scrollToEl = nextVisibleRootSibling[0];
                        } else if (!_.isEmpty(previousVisibleRootSibling)) {
                            scrollToEl = previousVisibleRootSibling[0];
                            closest_item = 'last';
                        }
                        if ($(scrollToEl).hasClass('amend')) {
                            // target element(scrollToEl) has children. goes to child closest to ref element(selectedElement)
                            var scrollToItems = $(scrollToEl).children(
                                'li:' + closest_item + ', .tts-regular:' + closest_item,
                            );
                            if (!_.isEmpty(scrollToItems)) {
                                scrollToEl = scrollToItems[0];
                            }
                        }
                    }
                }
            }
            if (scrollToEl) {
                // scroll to first hit if there
                if (this.scrollToLocation) {
                    var hitEl = $(scrollToEl).find('.hit');
                    if (hitEl.length) {
                        // check to see if the hit is actually in a hidden element
                        if ($(hitEl[0]).parents('.tts-hidden').length > 0) {
                            const closestVisible = $(hitEl[0]).closest(':visible')[0];
                            // check that the closest 'visible' element is also nested in a hidden element
                            if ($(closestVisible).parents('.tts-hidden').length > 0) {
                                // if it is, its parents will have a Hidden Sibling that we can scroll to reliably,
                                // and scroll it to the middle of the screen so it actually shows up
                                $($(closestVisible).parents('.tts-hidden:last')[0])
                                    .siblings('span.tts-hidden-sibling')[0]
                                    .scrollIntoView({ block: 'center' });
                            } else {
                                // Parents aren't hidden, so scroll to closest visible element
                                closestVisible.scrollIntoView();
                            }
                        } else {
                            // hit wasn't hidden at all
                            hitEl[0].scrollIntoView();
                        }
                    }
                } else {
                    // Not in a search at all

                    // Set activeElement, or we'll get lost later
                    this.activeElement = elementID;
                    scrollToEl.scrollIntoView();
                }
            } else {
                // Last ditch. We're here because the block the element is in hasn't been loaded
                // due to it and surrounding blocks having been time travelled away, but the
                // detection for active element under the scroll target hasn't hit.
                // We can force the issue by setting the element as the surrounding block container
                // as we know that will always be visible (albeit empty)
                var $activeBlock = this.$('#block' + (this.activeBlock || 0));
                if ($activeBlock.length) {
                    $activeBlock[0].scrollIntoView();
                }
            }
        },
        navigate: function (elementID, toLocation) {
            // If we're coming from a search, we scroll to the first hit
            // If we're coming from anywhere else, not so much
            this.scrollToLocation = toLocation;
            this.trigger('navigate:load');

            this.navHash = '';
            var sections;
            /*
                Find the block the element is in, if it's not loaded, get
                norris to fetch it, then scroll to the element
            */

            // Don't do anything if we don't have ids yet
            if (_.isUndefined(this.norris.ids)) {
                return;
            }

            if (elementID == 'top' || elementID == '') {
                elementID = this.model.get('document');
            }

            if (elementID == this.activeElement) {
                this.trigger('navigate:done');
                return;
            }

            this.activeElement = elementID;
            this.navigating = true;
            this.requiredBlocks = [];

            if (_.has(this.norris.ids, elementID)) {
                this.activeBlock = this.norris.ids[elementID].block;

                // This updates the activeBlock and elementID from a sublist
                // if the current elementID doesn't have a block because it has
                // been substituted. Currently tries to choose the latest link.
                if (_.isUndefined(this.activeBlock)) {
                    var sublinks = this.norris.ids[elementID].sublinks;

                    if (!_.isEmpty(sublinks)) {
                        // Maybe do something better here to pick the first sublink
                        // in the document for that id!
                        sublinks = sublinks.sort();
                        elementID = sublinks[0];
                        this.activeBlock = this.norris.ids[elementID].block;
                    } else {
                        // Just return if we don't have sublinks.
                        // This should not happen.
                        return;
                    }
                }

                sections = this.norris.getSectionsFromId(elementID);
                if (!_.isUndefined(sections) && !_.isEmpty(sections)) {
                    this.activeSection = sections[0];
                } else {
                    this.activeSection = elementID;
                    sections = [this.activeSection];
                }
            } else {
                this.activeBlock = 0;
                this.activeSection = this.activeElement = this.model.get('document');
                sections = [this.activeSection];
            }

            // May need better way to handle this hash change
            if (elementID && window.location.hash != '#' + elementID && this.enableScroll) {
                this.sectionChange(elementID, false);
            }

            // Disable block loading until adjustment
            this.canScroll = false;

            if (this.activeBlock > 0) {
                // Not at top, so need to load in blocks behind the current one
                this.once('document:allBlocksRendered', function () {
                    this.once('document:allBlocksRendered', function () {
                        this.canScroll = true;
                        var parentId = $('#' + escapeDOMId(this.activeSection)).attr('parent_id');
                        this.model.trigger('document:section:change', sections, parentId);
                        this.scrollToNearestVisibleElement(elementID);
                    });
                    this.fetchChunk(this.activeBlock);
                });
                this.fetchChunks(this.activeBlock - 1, false);
            } else {
                this.once('document:allBlocksRendered', function () {
                    this.canScroll = true;
                    var parentId = $('#' + escapeDOMId(this.activeSection)).attr('parent_id');
                    this.model.trigger('document:section:change', sections, parentId);
                    this.scrollToNearestVisibleElement(elementID);
                });
                this.fetchChunk(this.activeBlock);
            }
        },
        scroll: function () {
            if (!_.isEmpty(this.requiredBlocks) && this.enableScroll) {
                if (this._scrollTimeout === 0) {
                    this.scrollStart.apply(this, arguments);
                }
                clearTimeout(this._scrollTimeout);
                this._scrollTimeout = setTimeout(_.bind(this.scrollEnd, this), 100);
            }
        },
        scrollStart: function () {
            this.trigger('document:scroll:start');
        },
        navEnd: function () {
            this.navigating = false;
            this.canScroll = true;
            this._scrollTimeout = 0;
            this.updateNotes();
            if (this.navHash) {
                var gotoEl = this.navHash;
                this.navHash = '';
                this.navigate(gotoEl);
            }
            this.trigger('navigate:done');
        },
        scrollEnd: function () {
            this.trigger('document:scroll:end');

            if (_.includes(this.loadedBlocks, this.sectionBlock)) {
                this.updateNotes();
            }

            if (this.canScroll) {
                if (!this.navigating) {
                    this.activeBlock = this.getActiveBlock();
                }
                // Get current block, load it and forward blocks,
                // find current element, and then behind blocks if necessary
                this.once('document:allBlocksRendered', function () {
                    if (this.navigating) {
                        this.canScroll = false;
                        this.scrollToNearestVisibleElement(this.activeElement);
                        this.scrollToLocation = false;
                        setTimeout(_.bind(this.navEnd, this), 200);
                    } else {
                        var currEl = this.activeElement;
                        this.getActiveSection();
                        // don't do an update if we don't need to
                        if (
                            this.activeElement != currEl ||
                            (this.activeElement == currEl && currEl == this.model.get('document'))
                        ) {
                            this.sectionChange(this.activeElement, true);
                            this._scrollTimeout = 0;
                            this.update();
                        }
                    }
                });
                this.fetchChunks(this.activeBlock, false);
            }
        },
    });

    Backbone.mixin(ContentView, ColourKeyMixin, IntermediateWindowMixin, MultiRefMixin);

    return ContentView;
});
