module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 _.each(collection.models, function(item) {
    if(item.doc) { 
__p+='\n<li>\n    <a href="'+
((__t=( app.urls.documents ))==null?'':_.escape(__t))+
'/'+
((__t=( item.doc.get('document') ))==null?'':_.escape(__t))+
'">\n        <span class="doctitle-full">'+
((__t=( item.doc.get('title') ))==null?'':_.escape(__t))+
'</span>\n        ';
 if(item.doc.get('abbrevs').length) { 
__p+='\n        <span class="doctitle-abbr">['+
((__t=( item.doc.get('abbrevs').join(', ') ))==null?'':_.escape(__t))+
']</span>\n        ';
 } 
__p+='\n    </a>\n</li>\n';
 }}) 
__p+='';
}
return __p;
};
