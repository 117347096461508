import React, { useState } from 'react';

import { Popover, OverlayTrigger } from 'react-bootstrap';
import ConditionalWrapper from '../ConditionalWrapper';
import CopyLocationModal from './CopyLocationModal';
import PiconIcon from '../FormControls/Icons/PiconIcon';

const CopyLocation = () => {
    const [showModal, setShowModal] = useState(false);

    const handleModal = () => {
        document.body.click();
        setShowModal(!showModal);
    };

    // This needs to be set within this component or react gets confused
    const popoverCopyLoc = (
        <Popover>
            <Popover.Title className="popover-title">Copy Location</Popover.Title>
            <Popover.Content className="popover-content">
                Create a shareable link to you current document, at your current location, view
                date, and time travel view
            </Popover.Content>
        </Popover>
    );

    const displayToolTip = app.user.get('display_prefs').contextual_help;

    return (
        <ConditionalWrapper
            condition={displayToolTip}
            wrapper={(children) => (
                <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="bottom"
                    overlay={popoverCopyLoc}
                    rootClose={true}
                >
                    {children}
                </OverlayTrigger>
            )}
        >
            <div>
                <button
                    className="toolbar-item btn"
                    onClick={handleModal}
                    title="Copy current location"
                    role="menuitem"
                >
                    <span className="icon">
                        <PiconIcon className="lg left top" iconName="link" />
                    </span>
                    <span className="label">Copy Location</span>
                </button>
                {showModal && <CopyLocationModal handleModal={handleModal} show={showModal} />}
            </div>
        </ConditionalWrapper>
    );
};

export default CopyLocation;
