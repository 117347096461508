import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import EditButton from './EditButton';
import ContentPanelFormatModal from './ContentPanelFormatModal';

export const LIST_CONTENT_TYPES = {
    HTML: 'html',
    TITLE: 'title',
};
Object.freeze(LIST_CONTENT_TYPES);

export const CONTENT_PANEL_TYPES = {
    NEW_DOCS: 'new_docs',
    ROD_DOCS: 'rod_docs',
    LATEST_NEWS: 'latest_news',
    NEW_DOCS_ANON: 'new_docs_anon',
    LATEST_NEWS_ANON: 'latest_news_anon',
};
Object.freeze(CONTENT_PANEL_TYPES);

const PANEL_HEIGHT_TYPES = {
    SMALL: { height: '250px', overflowY: 'scroll' },
    MEDIUM: { height: '500px', overflowY: 'scroll' },
    LARGE: { height: '750px', overflowY: 'scroll' },
    DEFAULT: { height: 'auto' },
    CONTENT: { height: 'fit-content' },
};

const defaultFormat = {
    document_display_count: 20,
    panel_height: 'DEFAULT',
};

const CMDocumentList = ({
    doFetch,
    doPost,
    handleGrouping,
    listTitle,
    displayType,
    formatting,
    ExtraButton,
    Subheading,
}) => {
    const [results, setResults] = useState([]); // we need to hold on to the results array in case display count is changed
    const [docs, setDocs] = useState({});
    const [format, setFormat] = useState(defaultFormat);
    const [showEditor, setShowEditor] = useState(false);

    useEffect(() => {
        (async () => {
            const results = await doFetch();
            setResults(results);
            if (results) {
                const NewDocsSortedResults =
                    formatting.panel_type && _.sortBy(results, ['sort_value']);
                const docsToGroup = NewDocsSortedResults.length ? NewDocsSortedResults : results;
                setDocs(
                    _.groupBy(docsToGroup.slice(0, formatting.document_display_count), (doc) =>
                        handleGrouping(doc),
                    ),
                );
            }
            setFormat(formatting);
        })();
    }, []);

    const handleFormatUpdate = (newFormat) => {
        setFormat(newFormat);
        setDocs(
            _.groupBy(results.slice(0, newFormat.document_display_count + 1), (result) =>
                handleGrouping(result),
            ),
        );
    };

    const handleClick = (evt, href) => {
        evt.ctrlKey ? window.open(href) : (window.location.href = href);
    };

    return (
        <>
            <div style={PANEL_HEIGHT_TYPES[format.panel_height]}>
                <div className="content-panel-header">
                    <div dangerouslySetInnerHTML={{ __html: listTitle }}></div>
                    {app.user.has_perm('cms_lite.add_homepage_content') && (
                        <EditButton
                            title="Edit content panel formatting for this document list"
                            handleEditor={() => setShowEditor(true)}
                        />
                    )}
                </div>
                {Subheading ? <div>{Subheading}</div> : <></>}
                {Object.keys(docs).map((date, index) => {
                    return (
                        <div key={index}>
                            {displayType === LIST_CONTENT_TYPES.TITLE ? (
                                <div className="header big-header">{date}</div>
                            ) : (
                                <div className="header small-header">
                                    {date.replaceAll('meta::', '')}
                                </div>
                            )}
                            <ul>
                                {docs[date].map((doc, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            {/* Use Switch Case if we have more list types in future */}
                                            {displayType === LIST_CONTENT_TYPES.HTML ? (
                                                <li
                                                    key={doc.id}
                                                    className="new-doc-link"
                                                    onClick={(evt) =>
                                                        handleClick(
                                                            evt,
                                                            `${app.urls.documents}/${doc.doc_id}`,
                                                        )
                                                    }
                                                    dangerouslySetInnerHTML={{
                                                        __html: doc.display,
                                                    }}
                                                ></li>
                                            ) : (
                                                <li key={doc.doc_id}>
                                                    <a href={`${app.urls.news}/${doc.doc_id}`}>
                                                        <span className="doctitle-full">
                                                            {doc.title}
                                                        </span>
                                                    </a>
                                                </li>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                            </ul>
                        </div>
                    );
                })}
                {ExtraButton && results.length ? <div>{ExtraButton}</div> : <></>}
            </div>
            {showEditor && (
                <ContentPanelFormatModal
                    show={showEditor}
                    hideModal={() => setShowEditor(false)}
                    title="Edit content panel formatting"
                    doPost={doPost}
                    format={format}
                    setFormat={setFormat}
                    handleFormatUpdate={handleFormatUpdate}
                />
            )}
        </>
    );
};

export default CMDocumentList;

CMDocumentList.propTypes = {
    doFetch: PropTypes.func.isRequired,
    doPost: PropTypes.func,
    handleGrouping: PropTypes.func.isRequired,
    listTitle: PropTypes.string,
    displayType: PropTypes.string,
    formatting: PropTypes.object,
    ExtraButton: PropTypes.element,
    Subheading: PropTypes.element,
};
