import React from 'react';
import PropTypes from 'prop-types';

const DocResultsHeader = ({ permission }) => {
    return (
        <thead>
            <tr className="doc-search-item table-divider">
                <th className="doc-search-abbrev result-header">Abbreviation</th>
                <th className="doc-search-title result-header">Document title</th>
                <th className="doc-search-fam result-header">Family</th>
                {permission && <th className="doc-search-status result-header">Status</th>}
            </tr>
        </thead>
    );
};

export default DocResultsHeader;

DocResultsHeader.propTypes = {
    permission: PropTypes.bool.isRequired,
};
