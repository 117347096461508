const formatGroupMetadata = (subscription_groups) => {
    const groupData = subscription_groups.reduce((orgObj, group) => {
        orgObj[group.organisation]
            ? orgObj[group.organisation].push({ ...group })
            : (orgObj[group.organisation] = [{ ...group }]);
        return orgObj;
    }, {});
    return groupData;
};

const checkEmailValidity = (email) => {
    // This validity check is simply to make sure there's an @ and at least one . in the domain.
    // This DOES NOT check that the domain is a valid domain for use of Perspective
    const hasAtmark = email.includes('@');
    if (hasAtmark) {
        const emailParts = email.split('@');
        const hasFullStop = emailParts[1].includes('.');
        if (!hasFullStop) {
            return false;
        } else {
            return true;
        }
    } else {
        return false;
    }
};

export { formatGroupMetadata, checkEmailValidity };
