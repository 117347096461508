import React from 'react';
import PropTypes from 'prop-types';

import NoteInput from '../../UserDetails/NoteInput';

const FlagNote = ({ required }) => {
    return <NoteInput maxLength={1000} required={required} />;
};

export default FlagNote;

FlagNote.propTypes = {
    required: PropTypes.bool,
};
