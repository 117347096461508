module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='    ';
 if(isAlert) { 
__p+='\n    <div class="alert alert-block alert-'+
((__t=( type ))==null?'':__t)+
'" style="display:none;" data-dismiss="alert">\n        '+
((__t=( message ))==null?'':__t)+
'\n    </div>\n\n    ';
 } else { 
__p+='\n        <div class="rbs4 toast toast-'+
((__t=( type ))==null?'':__t)+
' " style="display:none;" data-dismiss="alert">\n            <span>\n                ';
 if(type=='danger'){ 
__p+='\n                    <span class="picon picon-warning-sign">  </span>\n                ';
 } 
__p+='\n                '+
((__t=( message ))==null?'':__t)+
'\n            </span>\n            <button type="button" class="close" data-dismiss="alert"><span aria-hidden="true">×</span></button>\n        </div>\n    ';
 } 
__p+='';
}
return __p;
};
