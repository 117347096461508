import PropTypes from 'prop-types';
import React from 'react';

import icons from '../../img/Icons';
import PopoverOverlay from '../FormControls/PopoverOverlay';
import { annotationPropTypes } from '../Annotations/utils';
import { useHover } from '../customHooks';

export const AnnotationPopover = ({
    data,
    number,
    refID,
    sectionID,
    showModal,
    title,
    label,
    iconClass,
    annoClass,
}) => {
    const [hoverRef, isHovered] = useHover();
    return (
        <>
            <PopoverOverlay
                {...{
                    placement: 'left',
                    title,
                    className: 'popover-dd-anno popover-dd-flag',
                }}
            >
                <button
                    className={`dd-flag dd-flag-anno dd-flag-anno-${refID} ${annoClass}`}
                    ref={hoverRef}
                    ref_id={sectionID} // eslint-disable-line
                    ref_label={label} // eslint-disable-line
                    id={`anno-${refID}-${sectionID}`}
                    onClick={() => showModal(data)}
                >
                    <span className="sr-only">{label}</span>
                    {iconClass
                        ? icons[`${isHovered ? 'annotationHover' : 'annotation'}`]({
                              number,
                              colour: iconClass,
                          })
                        : icons['addAnnotation']()}
                </button>
            </PopoverOverlay>
            {annoClass === 'has-annos' && (
                <div className="skiplinks">
                    <a href="#add-annotation">Skip to annotation panel</a>
                </div>
            )}
        </>
    );
};

//#region PropTypes

AnnotationPopover.propTypes = {
    data: PropTypes.shape(annotationPropTypes).isRequired,
    iconClass: PropTypes.shape({
        anno: PropTypes.string,
        circle: PropTypes.string,
        text: PropTypes.string,
    }),
    number: PropTypes.number.isRequired,
    refID: PropTypes.string.isRequired,
    sectionID: PropTypes.string,
    showModal: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    label: PropTypes.string,
    annoClass: PropTypes.string,
};

//#endregion
