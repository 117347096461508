import React, { useMemo, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import PropTypes from 'prop-types';

import { customStyles, theme } from './Select';
import InvalidFeedback from '../InvalidFeedback';
import { useDarkMode } from '../../customHooks';

const menuHeaderStyle = {
    padding: '8px 12px',
    color: '#999999',
};

const TypedSelect = ({
    options,
    onChange,
    value,
    name,
    placeholder,
    maxLength,
    isInvalid,
    errorMessage,
}) => {
    const darkMode = useDarkMode();

    const typedSelectStyle = useMemo(() => {
        return {
            ...customStyles(darkMode),
            noOptionsMessage: (base) => ({
                ...base,
                display: 'none',
            }),
        };
    }, [darkMode]);

    const MenuList = (props) => (
        <components.MenuList {...props}>
            <div style={menuHeaderStyle}>{placeholder ?? 'Create or select...'}</div>
            {props.children}
        </components.MenuList>
    );

    MenuList.propTypes = {
        children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    };

    const [createdOptions, setCreatedOptions] = useState([]);
    const [isInvalidLength, setIsInvalidLength] = useState(false);

    const handleCreate = (input) => {
        if (input && input.length > maxLength) {
            setIsInvalidLength(true);
            return;
        }
        const newOption = { value: input, label: input };
        setCreatedOptions([...createdOptions, newOption]);
        onChange(newOption);
        setIsInvalidLength(false);
    };

    const handleChange = (item) => {
        setIsInvalidLength(false);
        onChange(item);
    };

    return (
        <>
            <CreatableSelect
                isClearable
                components={{ MenuList }}
                styles={typedSelectStyle}
                theme={theme}
                options={[...createdOptions, ...options]}
                onChange={(item) => handleChange(item)}
                value={value}
                name={name}
                onCreateOption={(item) => handleCreate(item)}
                placeholder={placeholder}
                isInvalid={isInvalidLength}
                removeSelected={false}
            />
            {isInvalidLength && (
                <InvalidFeedback
                    errorMessage={`Value is too long. Maximum allowed length is ${maxLength}`}
                    isInvalid={isInvalidLength}
                />
            )}
            {isInvalid && (
                <InvalidFeedback
                    errorMessage={errorMessage ?? 'Value is required'}
                    isInvalid={isInvalid}
                />
            )}
        </>
    );
};

export default TypedSelect;

TypedSelect.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
        }),
    ),
    onChange: PropTypes.func.isRequired,
    value: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
    }),
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    maxLength: PropTypes.number.isRequired,
    errorMessage: PropTypes.string,
    isInvalid: PropTypes.bool,
};
