import React from 'react';
import PropTypes from 'prop-types';

import FormModal from '../FormControls/FormModal';
import { asyncAction } from '../utils';
import NoteInput from '../UserDetails/NoteInput';

const EditNotesModal = ({ show, notes, hideNotes, setDirty, userModel }) => {
    const submitForm = async (form) => {
        const args = {
            notes: form.elements.notes.value,
        };

        const fun = (params) => userModel.saveNote(params);
        await asyncAction({ fun, setDirty, args, actionName: 'save note' });
        hideNotes();
    };

    return (
        <FormModal title="Edit Note" hideModal={hideNotes} size="sm" {...{ submitForm, show }}>
            <NoteInput rows={6} maxLength={2000} defaultValue={notes} />
        </FormModal>
    );
};

export default EditNotesModal;

EditNotesModal.propTypes = {
    show: PropTypes.bool.isRequired,
    notes: PropTypes.string,
    hideNotes: PropTypes.func.isRequired,
    setDirty: PropTypes.func.isRequired,
    userModel: PropTypes.shape({
        saveNote: PropTypes.func.isRequired,
    }),
};
