import React from 'react';

const Error403 = () => {
    return (
        <div id="pagewrapper">
            <div id="wrapper">
                <div id="mainbody">
                    <div className="logo"></div>
                    <div className="content-403">
                        <p>Sorry, you don&apos;t have permission to view this document.</p>
                        <p>Please contact us to discuss access.</p>
                        <p>
                            <strong>0800 980 1332</strong>
                        </p>
                        <p>or email</p>
                        <p>
                            <strong>
                                <a href="mailto:helpdesk@pendragon.co.uk?subject=perspective.info Access Denied">
                                    helpdesk@pendragon.co.uk
                                </a>
                            </strong>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Error403;
