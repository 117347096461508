import React from 'react';

import icons from '../../img/Icons';

const TodayMarker = () => (
    <>
        <div id="today-marker" className="today-marker">
            Today
            <span className="today-marker-circle"></span>
            <span className="today-marker-line"></span>
            <span className="today-marker-text">
                Please&nbsp;<a href="mailto:helpdesk@pendragon.co.uk">email Pendragon</a>&nbsp;with
                suggestions for entries to the Timeline.
            </span>
            <div className="today-marker-oswald">
                {icons.oswaldLeft({ height: '51', width: '58' })}
            </div>
            <span className="today-marker-fade"></span>
        </div>
    </>
);

export default TodayMarker;
