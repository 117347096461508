import React from 'react';

import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

const NoteInput = ({ defaultValue, rows, maxLength, required }) => {
    return (
        <Form.Group>
            <Form.Label>Notes (not shown to the user)</Form.Label>
            <Form.Row>
                <Form.Control
                    as="textarea"
                    rows={rows}
                    name="notes"
                    maxLength={maxLength}
                    defaultValue={defaultValue}
                    required={required}
                    aria-label="notes"
                />
            </Form.Row>
            <span>
                Use this to record any admin details about this user, e.g. regarding status flags
            </span>
        </Form.Group>
    );
};

export default NoteInput;

NoteInput.defaultProps = {
    rows: 3,
    required: false,
};

NoteInput.propTypes = {
    defaultValue: PropTypes.string,
    rows: PropTypes.number.isRequired,
    maxLength: PropTypes.number.isRequired,
    required: PropTypes.bool,
};
