import React, { useState, useEffect } from 'react';
import models from '../../models';
import UserManage from '../UserAdmin/UserManage';
import UserListHelperText from './UserListHelperText';
import UserGroupsList from './UserGroupsList';
import TabRow from './TabRows';
import PropTypes from 'prop-types';
import ProjectInfoBlock from '../UserSettings/ProjectInfoBlock';

const OrganisationContainer = ({ organisationID }) => {
    const [tabIndex, setTabIndex] = useState(0);
    const [organisation, setOrganisation] = useState();
    const [tabContent, setTabContent] = useState([]);

    useEffect(() => {
        (async function fetchData() {
            try {
                const organisationData = await new models.Organisation({
                    id: organisationID,
                }).fetch();
                setOrganisation(organisationData);
            } catch (err) {
                if (err.status === 404) {
                    app.controller.error404();
                } else {
                    toaster.error('There was a problem retrieving the organisation');
                    console.error(err);
                }
            }
        })();
    }, [organisationID]);

    useEffect(() => {
        if (organisation) {
            const tabList = organisation.content

                .filter((item) => item.content_type === 'subscriber_information_tab')
                .sort((a, b) => a.order - b.order)
                .map((item) => ({
                    title: item.title,
                    content: (
                        <div
                            className="organisation-details-container"
                            dangerouslySetInnerHTML={{ __html: item.content }}
                        />
                    ),
                }));
            tabList.push({
                title: 'Users',
                content: (
                    <div className="organisation-user-container">
                        <UserListHelperText />
                        <UserManage currentOrganisation={organisation} />
                    </div>
                ),
            });
            tabList.push({
                title: 'User groups',
                content: <UserGroupsList groups={organisation.groups} />,
            });
            tabList.push({
                title: 'Projects',
                content: (
                    <div className="projects-tab-container">
                        <ProjectInfoBlock organisationId={organisationID} />
                    </div>
                ),
            });
            setTabContent(tabList);
            if (document.referrer.includes(app.urls.settings)) {
                setTabIndex(tabList.length - 1);
            }
        }
    }, [organisation]);

    if (tabContent.length) {
        return (
            <>
                <h2>{organisation.name}</h2>
                <div className="organisation-container">
                    <ul className="nav-tabs vertical organisation-tabs">
                        {tabContent.map((tab, key) => (
                            <TabRow
                                key={key}
                                tab={tab}
                                active={key === tabIndex}
                                handleClick={() => setTabIndex(key)}
                            />
                        ))}
                    </ul>
                    {tabContent[tabIndex].content}
                </div>
            </>
        );
    }

    return null;
};

export default OrganisationContainer;

OrganisationContainer.propTypes = {
    organisationID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
