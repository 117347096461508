import React from 'react';
import PropTypes from 'prop-types';

import { getDateTime, doFetchSelectableTable, multilineSplit } from '../utils';
import TableControl from '../FormControls/Table/TableControl';
import PiconIcon from '../FormControls/Icons/PiconIcon';
import { useContext } from 'react';
import { TableContext } from '../FormControls/Table/TableContext';
import LifeCycle from './LifecycleItems';

//#region helpers

const headers = [
    { title: 'Document', dataField: 'title' },
    { title: 'Families', dataField: 'family_titles' },
    { title: 'Date uploaded', dataField: 'uploaded_at' },
    { title: 'Uploaded by', dataField: 'uploaded_by' },
    { title: 'Notes', dataField: 'conversion_notes' },
    { title: 'Index', dataField: 'index_order' },
    { title: 'Status', dataField: 'state' },
    { title: 'Other Versions' },
];

const getTableColumns = (item) => {
    const hasBothDraftAndReady =
        item.related_instances?.filter((item) => item.state == 'draft' || item.state == 'ready')
            .length > 1;

    const title = (
        <span title={hasBothDraftAndReady ? 'Has both Draft and Ready statuses' : null}>
            <a href={`/administration/documents/${item.document}`}>
                <div>
                    <b>{item.title}</b>
                </div>
                {item.document}
            </a>
            {hasBothDraftAndReady && <PiconIcon iconName="warning-sign" className="red right" />}
        </span>
    );

    const families = multilineSplit(
        item.families.filter((f) => f.slug != 'all-documents'),
        'title',
    );

    const uploadedAt = getDateTime(item.uploaded_at);
    const uploadedBy = item.uploaded_by_name;

    const notes = item.conversion_notes;

    const otherStatuses = <LifeCycle item={item} />;

    const status = (
        <a className="flag-link" href={`/administration/documents/${item.document}`}>
            <span className={'flag flag-' + item.state}>
                <PiconIcon
                    className="left"
                    iconName={item.state == 'ready' ? 'thumbs-up' : 'edit'}
                />
                {item.state}
            </span>
        </a>
    );

    const index = item.indexed_name;

    return [title, families, uploadedAt, uploadedBy, notes, index, status, otherStatuses];
};

//#endregion

const DocumentList = ({ documentSummariesModel, additionalButtons }) => {
    const { tableState } = useContext(TableContext);

    const doFetch = async () => {
        return await doFetchSelectableTable({
            tableState,
            fetch: async (params) => await documentSummariesModel.fetch(params),
            refreshSelected: async (params) => await documentSummariesModel.refreshSelected(params),
        });
    };

    return (
        <TableControl
            {...{
                headers,
                getTableColumns,
                doFetch,
                className: 'document-list',
                additionalButtons,
            }}
        />
    );
};

export default DocumentList;

DocumentList.propTypes = {
    documentSummariesModel: PropTypes.shape({
        fetch: PropTypes.func.isRequired,
        refreshSelected: PropTypes.func.isRequired,
    }),
    additionalButtons: PropTypes.element,
};
