import React from 'react';
import SimpleTableControl from '../FormControls/Table/SimpleTableControl';
import PiconIcon from '../FormControls/Icons/PiconIcon';
import PropTypes from 'prop-types';

const UserGroupsList = ({ groups }) => {
    const doFetch = () => {
        return groups;
    };

    const getTableColumns = (item) => {
        const isDefault = item.is_organisation_default ? (
            <span className="is-default" title="Is default user group">
                <PiconIcon className="dark-grey" iconName={'star'} />{' '}
            </span>
        ) : (
            ''
        );

        const groupName = (
            <div>
                {item.name} {isDefault}
            </div>
        );

        const libraries =
            item.libraries.length === 0 ? (
                'None'
            ) : (
                <ul>
                    {item.libraries.map((library, index) => {
                        return (
                            <li key={index}>
                                {library.name}: <ul>Families: {library.families}</ul>
                            </li>
                        );
                    })}
                </ul>
            );

        return [groupName, libraries];
    };

    const headers = ['Group name', 'Libraries'];

    return (
        <div className="organisation-details-container">
            <h2>User groups</h2>
            <SimpleTableControl
                {...{
                    doFetch,
                    headers,
                    getTableColumns,
                    className: 'user-groups',
                    dataName: 'user group',
                }}
            />
        </div>
    );
};

UserGroupsList.propTypes = {
    groups: PropTypes.array,
};

export default UserGroupsList;
