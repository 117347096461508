import React, { createContext, useEffect, useState } from 'react';

import CalendarMarker from './CalendarMarker';
import CalendarPanel from './CalendarPanel';
import models from '../../models';
import TimelineCard from './TimelineCard';
import TimelineTitlebar from './TimelineTitlebar';
import TodayMarker from './TodayMarker';
import { useHash } from '../customHooks';
import { WithLoadingOverlay } from '../FormControls/WithLoading';
import { uniqueId } from 'lodash';
import Error403 from '../Error403';
import IntroContainer from './IntroContainer';

export const TimelineContext = createContext();

const TimelineManager = () => {
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [intro, setIntro] = useState({});
    const [today, setToday] = useState('');
    const [stickyItems, setStickyItems] = useState([]);
    const [stickyItemsToggle, setStickyItemsToggle] = useState({});
    const [calendarMap, setCalendarMap] = useState({});
    const [instanceId, setInstanceId] = useState('');
    const [families, setFamilies] = useState([]);
    const [hash, setHash] = useState(window.location.hash);
    const [active, setActive] = useState({ id: hash, active: !!hash.replace('#', '') });
    const [fromSearch, setFromSearch] = useState('');
    const [isError, setIsError] = useState(false);
    const timelineModel = new models.Timeline();

    const doFetch = async () => {
        // need to get today's date to send back for initial fetch when we have more docs
        return await timelineModel.fetch(window.location.pathname);
    };

    const handleToday = () => {
        // Scroll today in the timeline and within the panel calendar
        const element = document.getElementById('today-marker');
        if (element) {
            element.scrollIntoView();
        }
        const panelElement = document.getElementById('calendar-today-marker');
        if (panelElement) {
            panelElement.scrollIntoView();
        }
    };

    useEffect(() => {
        document.title = 'Timeline';
    }, []);

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const data = await doFetch();
                const now = app.moment().format(app.settings.dateTransportFormat);
                setToday(now);
                setIntro(data?.metadata.find((item) => 'intro' in item) || {});
                const todayCard = { 'start-dt': now, 'end-dt': now, today: true };
                const insertIndex = data.results.findIndex(
                    (result) =>
                        result['start-dt'] <= todayCard['start-dt'] &&
                        result['end-dt'] <= todayCard['end-dt'],
                );

                if (!hash) {
                    const momentNow = app.moment(now);
                    const todayId = `${momentNow.format('MMMM')}-${momentNow.format('YYYY')}`;
                    setActive({ id: todayId, active: true });
                }

                // Put the Today card in the right place in the results
                data.results.splice(insertIndex, 0, todayCard);
                setCalendarMap(data.calendar_map);
                setFamilies(data.families);
                setInstanceId(data.instance_id);
                // this looks funny to have setLoading false before setting Items, but stickyScrolling breaks if setItems isn't last...
                setLoading(false);

                const items = data.results.map((item) => ({ ...item, id: uniqueId() }));
                const toggleState = items.reduce((acc, current) => {
                    const endDate = current['end-dt'];
                    const startDate = current['start-dt'];
                    const itemId = `${startDate}-${endDate}-${current.id}`;
                    const periodMarker = startDate !== endDate;
                    return current['colour'] ? { ...acc, [itemId]: periodMarker } : acc;
                }, {});
                setStickyItemsToggle(toggleState);
                setItems(items);

                if (!hash) {
                    handleToday();
                }

                // We need to handle what happens if we come to Timeline through search to get back to the results list
                if (document.referrer.includes('search')) {
                    setFromSearch(document.referrer);
                }
            } catch (exc) {
                if (exc.status != 403) {
                    // Don't show the error message if it's a Permission denied error
                    toaster.error(
                        `There was a problem retrieving the Timeline: ${exc?.responseJSON?.error}`,
                    );
                }
                setLoading(false);
                setIsError(true);
            }
        })();
    }, []);

    useHash();
    useEffect(() => {
        if (hash) {
            window.location.hash = hash;
        }
    }, [hash]);

    return (
        <TimelineContext.Provider
            value={{
                stickyItems,
                setStickyItems,
                setHash,
                today,
                active,
                setActive,
                stickyItemsToggle,
                setStickyItemsToggle,
            }}
        >
            <WithLoadingOverlay loading={loading}>
                {!isError ? (
                    <>
                        <TimelineTitlebar {...{ instanceId, families, fromSearch }} />
                        <CalendarPanel {...{ calendarMap, intro: intro?.title }} />
                        <div className="pane-main pane-doc regdoc">
                            <div id="header" className="timeline-header-container">
                                <div className="timeline-header" alt="Timeline header"></div>
                            </div>
                            {Object.keys(intro).length ? (
                                <IntroContainer {...{ intro }} />
                            ) : (
                                <div></div>
                            )}
                            <div className="timeline-vertical">
                                {items.map((item, index) => {
                                    if ('calendar_marker' in item) {
                                        return <CalendarMarker key={index} {...{ item }} />;
                                    } else if ('today' in item) {
                                        return <TodayMarker key={index} />;
                                    } else {
                                        return (
                                            <TimelineCard
                                                key={index}
                                                {...{
                                                    item,
                                                }}
                                            />
                                        );
                                    }
                                })}
                            </div>
                        </div>
                    </>
                ) : (
                    <Error403 />
                )}
            </WithLoadingOverlay>
        </TimelineContext.Provider>
    );
};

export default TimelineManager;
