import React from 'react';
import PropTypes from 'prop-types';
import requiredIf from 'react-required-if';

//#region constants

const NO_META_MATCH = 'Documents require to have both Metadata and Document types';
const NO_DOCUMENTS = 'Folder does not contain any documents, only resource files';
const TOO_MANY_DOCUMENTS = 'Folder contains multiple documents';
const DOCUMENT_MISSING_ID = 'Document id is required';

//#endregion

//#region tree methods

const getNodeByPath = (paths, tree) => {
    // Pop the top path
    const path = paths.slice(0, 1)[0];
    if (path == '') return tree;

    // Find current branch
    const branch = tree.children.find((branch) => branch.path === path);

    // This is the branch we are looking for
    if (branch.children.length == 0 || paths.length == 1) return branch;
    // There is more paths - go deeper into the tree
    return getNodeByPath(paths.slice(1), branch);
};

const makeNode = (paths, currentResult, parsedUpload) => {
    // Pop the top path
    const path = paths.slice(0, 1)[0];

    // Check if this path is already added on a tree
    let currentNode = currentResult.find((r) => r.path == path);

    // This node doesn't exist - add it
    if (!currentNode) {
        // Get full path to this node by removing the remaining paths from the file path
        // or use filePath if this node is a file (has no more paths)
        // E.g. folder1/myNode/myFile.txt - if this is myNode node, you'd want fullPath to be folder1/myNode/
        const remainingPathLength = paths.length == 1 ? 0 : paths.slice(1)?.join('/').length;
        const fullPath = parsedUpload.fullPath.substring(
            0,
            parsedUpload.fullPath.length - remainingPathLength,
        );

        // path - current folder name
        // fullPath - path including the parent paths of this node
        // children - sub-folders or files that belong to this node
        currentNode = {
            path,
            fullPath,
            children: [],
        };
        currentResult.push(currentNode);
        currentResult.sort((a, b) => a.path.localeCompare(b.path));
    }

    // This is the last node - it must be the file. Add parsed file onto node and return
    if (paths.length == 1) {
        currentNode.parsedUpload = parsedUpload;
        return;
    }

    // Make nodes for remaining paths (sub-folders) as children of this node
    makeNode(paths.slice(1), currentNode.children, parsedUpload);
};

const makeTree = (parsedUploads, filesState) => {
    const newFilesTree = {
        fullPath: '',
        path: '',
        children: [...filesState.filesTree.children],
    };

    for (const parsedUpload of parsedUploads) {
        const paths = parsedUpload.fullPath.split('/');
        makeNode(paths, newFilesTree.children, parsedUpload);
    }

    const newFilesSelected = _.uniqBy([...filesState.files, ...parsedUploads], 'fullPath');

    validateNode(newFilesTree, newFilesSelected);
    setInitialSelection(newFilesSelected);

    return { newFilesTree, newFilesSelected };
};

//#endregion

//#region validation

// TODO validate if same doc id is in 2 different folders.

const validateForbiddenFiles = (files) => {
    const checkHasFileName = (name) => {
        if (files.some((f) => f.name === name)) {
            toaster.warning(
                `File '${name}' was skipped, because it is not allowed in document uploads`,
            );
        }
    };

    checkHasFileName('COMPLETED');
    checkHasFileName('PROCESSED');

    return files.filter((f) => f.name !== 'COMPLETED' && f.name !== 'PROCESSED');
};

const getMessages = (element, messages) => {
    const allMessages = [...(element.messages ?? []), ...(messages ?? [])];

    return allMessages.reduce((unique, item) => {
        return unique.includes(item) ? unique : [...unique, item];
    }, []);
};

const hasMetadataMatch = (parent, docId) => {
    // return true if there is a corresponding metadata file and document
    // or the document files are neither
    const types = _.uniq(parent.children.map((child) => child.parsedUpload?.type));

    if (types.length) {
        // Doesn't have meta or main - no need to match
        if (!types.includes('meta') && !types.includes('main')) {
            return true;
        }

        //Has both meta and main - let's check the document Ids aren't different
        if (types.includes('meta') && types.includes('main')) {
            // No doc id provided - assume it's all OK
            if (!docId) return true;
            const hasCorrespondingType = (type) =>
                parent.children.some(
                    (child) =>
                        child.parsedUpload?.type == type && child.parsedUpload?.docId == docId,
                );

            return hasCorrespondingType('meta') && hasCorrespondingType('main');
        }
    }

    return false;
};

const validateIds = (parent, newFilesSelected) => {
    const typesWithIds = ['news', 'survey', 'main', 'meta'];

    let isValid = true;
    for (const file of parent.children) {
        if (typesWithIds.includes(file.parsedUpload?.type) && !file.parsedUpload?.docId) {
            setFileInvalid({ parent, file, newFilesSelected, messages: [DOCUMENT_MISSING_ID] });
            isValid = false;
        }
    }

    if (isValid) return { isValid: true };
    return { isValid: false, messages: [DOCUMENT_MISSING_ID] };
};

const setNodeValid = ({ node, newFilesSelected, isValid, messages }) => {
    // look through new selected files, find ones that belong to this node, set as valid
    newFilesSelected.map((file) => {
        if (node.children.some((child) => child.fullPath === file.fullPath)) {
            file.isValid = isValid;
        }

        return file;
    });

    // mark children as valid
    for (const child of node.children) {
        child.isValid = isValid;
        if (!isValid) child.messages = getMessages(child, messages);
    }

    // mark parent node as valid
    node.isValid = isValid;
    if (!isValid) node.messages = getMessages(node, messages);
};

const setNodePartiallyValid = ({ node, newFilesSelected, id, messages }) => {
    // look through new selected files, find ones that belong to this node and have selected id, set as valid
    newFilesSelected.map((file) => {
        if (node.children.some((child) => child.fullPath === file.fullPath)) {
            file.isValid = file.id === id;
        }

        return file;
    });

    // mark children as valid if they have selected id or no id at all
    for (const child of node.children) {
        const isValid = !child.parsedUpload?.id || child.parsedUpload?.id === id;
        child.isValid = isValid;
        if (!isValid) child.messages = getMessages(child, messages);
    }

    // mark parent node as invalid
    node.isValid = false;
    node.messages = getMessages(node, messages);
};

const setFileInvalid = ({ parent, file, newFilesSelected, messages }) => {
    // look through new selected files, find the one that is being set
    newFilesSelected.map((selectedFile) => {
        if (selectedFile.fullPath === file.fullPath) {
            selectedFile.isValid = false;
        }

        return selectedFile;
    });

    file.isValid = false;
    file.messages = messages;

    // mark parent node as valid
    parent.isValid = false;
    parent.messages = getMessages(parent, messages);
};

const validateFiles = (parent, newFilesSelected) => {
    if (!hasMetadataMatch(parent)) {
        //All files are invalid - no meta match
        const result = { isValid: false, messages: [NO_META_MATCH] };
        setNodeValid({
            node: parent,
            newFilesSelected,
            ...result,
        });
        return result;
    }

    // check whether the files consist of resources and the same id
    const ids = _.uniq(parent.children.map((child) => child.parsedUpload?.id)).filter(
        (item) => item,
    );

    const isBulkUpload = parent.children.reduce((bool, currVal, index) => {
        // If there's only 1 id in the list, return bool value (which initially is false)
        // Or if we're at the final value in the list, return bool value as there's nothing left to compare
        if (parent.children.length === 1 || index === parent.children.length - 1) return bool;

        bool = currVal.parsedUpload?.type === parent.children[index + 1].parsedUpload?.type;
        return bool;
    }, false);

    let result = {};
    if (ids.length == 0) {
        //All files are invalid
        result = { isValid: false, messages: [NO_DOCUMENTS] };
        setNodeValid({ node: parent, newFilesSelected, ...result });
        return result;
    } else if (ids.length == 1 || isBulkUpload) {
        //All valid
        setNodeValid({ node: parent, newFilesSelected, isValid: true });
        result = { isValid: true };
    } else {
        //Things with ids should be set as invalid
        result = { isValid: false, messages: [TOO_MANY_DOCUMENTS] };
        setNodePartiallyValid({
            node: parent,
            newFilesSelected,
            id: null,
            messages: [TOO_MANY_DOCUMENTS],
        });
    }

    // If we reached here - result is either valid, or partially valid. Validate file ids.
    const fileResult = validateIds(parent, newFilesSelected);
    if (!fileResult.isValid) {
        result = { isValid: false, messages: getMessages(fileResult, result.messages) };
    }

    return result;
};

const isFile = (child) => child.children.length == 0;

const validateNode = (node, files) => {
    //check if it has only files as children
    if (node.children.length > 0 && node.children.every((child) => isFile(child))) {
        const { isValid, messages } = validateFiles(node, files);
        return { isValid, messages };
    } else {
        // There's sub-folders in this folder.
        let isValid = true;
        let messages = [];

        //There could also be files - if so validate them
        if (node.children.some((child) => isFile(child))) {
            const result = validateFiles(node, files);
            isValid = result.isValid;
            messages = getMessages(result, messages);
        }

        // Validate the sub-folders
        const subFolders = node.children.filter((child) => !isFile(child));
        for (const child of subFolders) {
            const result = validateNode(child, files);
            if (!result.isValid) {
                isValid = false;
                messages = getMessages(result, messages);
            }
        }
        node.isValid = isValid;
        if (!isValid) node.messages = getMessages(node, messages);
        return { isValid, messages };
    }
};

//#endregion

//#region file selection

const setInitialSelection = (files) => {
    files.map((f) => (f.isSelected = f.isValid));
};

const isFileSelected = (files, fullPath) => {
    const filesByPath = getFileByPath(files, fullPath);
    return filesByPath?.length > 0 && filesByPath?.every((f) => f.isSelected);
};

const isFolderIndeterminate = (files, fullPath) => {
    const filesByPath = getFileByPath(files, fullPath);
    const selected = filesByPath?.some((f) => f.isSelected);
    const unselected = filesByPath?.some((f) => !f.isSelected);
    return selected && unselected;
};

const getFileByPath = (files, fullPath) => {
    return files.filter((file) => file.fullPath.startsWith(fullPath));
};

const setFileSelected = (files, fullPath, isSelected) => {
    return files.map((file) => {
        if (file.fullPath.startsWith(fullPath)) {
            file.isSelected = isSelected;
        }
        return file;
    });
};

const setDocumentSelected = ({ files, currentFile, parentPath, isSelected }) => {
    return files.map((file) => {
        // Only auto select items in same folder (parent)
        const sameParent = file.fullPath.startsWith(parentPath);
        if (!sameParent) return file;

        if (file.id == currentFile.id && (file.type == 'meta' || file.type == 'main')) {
            // Auto select main or meta files with same ids
            file.isSelected = isSelected;
        } else if (file.fullPath == currentFile.fullPath) {
            // do selection for current file
            file.isSelected = isSelected;
        } else if (isSelected && file.id && file.id != currentFile.id) {
            // Unselect files with other ids
            file.isSelected = false;
        }
        return file;
    });
};

const toggleSelection = (filesState, fullPath, isSelected) => {
    const newState = { ...filesState };

    if (typeof fullPath === 'undefined') return [];

    const currentNode = getNodeByPath(fullPath.replace(/\/$/, '').split('/'), newState.filesTree);

    //check if this is a folder and that it is valid
    if (currentNode.children.length > 0) {
        // If it's an unselect, or if the folder is valid, do selection.
        if (!isSelected || validateNode(currentNode, filesState.files).isValid) {
            return setFileSelected(newState.files, fullPath, isSelected);
        }
        toaster.warning(`Can't select folder - has invalid files in it`);
        return newState.files;
    }

    //find parent
    const parentPath = fullPath.split('/').slice(0, -1);

    // If it has no parent path, root of tree must be the parent. Otherwise, get the parent folder
    const parent =
        parentPath.length === 0
            ? newState.filesTree
            : getNodeByPath(parentPath, newState.filesTree);

    // documents must have metadata and the document
    if (!hasMetadataMatch(parent, currentNode?.parsedUpload?.docId)) {
        toaster.warning(`Can't select file - document must have both metadata and document files`);
        return newState.files;
    }

    // If it's a select of an invalid file - show message
    if (
        isSelected &&
        currentNode.parsedUpload?.type == 'resource' &&
        currentNode.isValid === false
    ) {
        toaster.warning(`Can't select invalid files`);
        return newState.files;
    }

    if (currentNode.parsedUpload?.id) {
        return setDocumentSelected({
            files: newState.files,
            currentFile: currentNode.parsedUpload,
            parentPath: parent.fullPath,
            isSelected,
        });
    }

    return setFileSelected(newState.files, fullPath, isSelected);
};

//#endregion

//#region reducer

const FILE_ACTION = {
    SELECT_FILE: 'SELECT_FILE',
    UNSELECT_FILE: 'UNSELECT_FILE',
    ADD_FILES: 'ADD_FILES',
};

Object.freeze(FILE_ACTION);

const fileReducer = (filesState, action) => {
    switch (action.type) {
        case FILE_ACTION.ADD_FILES: {
            const validatedUploads = validateForbiddenFiles(action.parsedUploads);
            const { newFilesTree, newFilesSelected } = makeTree(validatedUploads, filesState);
            return {
                ...filesState,
                filesTree: newFilesTree,
                files: newFilesSelected,
            };
        }
        case FILE_ACTION.SELECT_FILE:
            return {
                ...filesState,
                files: [...toggleSelection(filesState, action.fullPath, true)],
            };

        case FILE_ACTION.UNSELECT_FILE:
            return {
                ...filesState,
                files: [...toggleSelection(filesState, action.fullPath, false)],
            };

        default:
            toaster.error(`Invalid file action: ${action.type}`);
            return filesState;
    }
};

//#endregion

//#region wrappers

const FileContext = React.createContext({
    children: null,
    filesState: null,
    dispatchFilesState: null,
});

const FileContextWrapper = ({ filesState, dispatchFilesState, children }) => (
    <FileContext.Provider value={{ filesState, dispatchFilesState }}>
        {children}
    </FileContext.Provider>
);

//#endregion

//#region PropTypes

const parsedFilePropTypes = {
    fullPath: PropTypes.string.isRequired,
    id: PropTypes.string,
    isSelected: PropTypes.bool.isRequired,
    isValid: PropTypes.bool,
    name: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    file: PropTypes.object.isRequired,
};

export const fileBranchPropTypes = {
    parsedUpload: PropTypes.shape(parsedFilePropTypes),
    path: PropTypes.string.isRequired,
    children: PropTypes.array,
    isValid: PropTypes.bool,
    messages: requiredIf(PropTypes.arrayOf(PropTypes.string), (props) => props.isValid === false),
};

FileContextWrapper.propTypes = {
    children: PropTypes.element.isRequired,
    dispatchFilesState: PropTypes.func.isRequired,
    filesState: PropTypes.shape({
        files: PropTypes.arrayOf(PropTypes.shape(parsedFilePropTypes)).isRequired,
        filesTree: PropTypes.shape({
            fullPath: PropTypes.string,
            children: PropTypes.arrayOf(PropTypes.shape(fileBranchPropTypes)).isRequired,
            path: PropTypes.string,
        }).isRequired,
    }),
};

//#endregion

export {
    FileContextWrapper,
    FileContext,
    FILE_ACTION,
    fileReducer,
    isFileSelected,
    isFolderIndeterminate,
};
