import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TableContext, TABLE_ACTION } from './TableContext';
import Checkbox from '../Checkbox';
import icons from '../../../img/Icons';
import { SORT_OPTIONS } from '../../Favourites/FavouriteManage';

const TableRow = React.forwardRef(({ row }, ref) => {
    const { tableState, dispatchTableState, options } = useContext(TableContext);

    const isRowSelected = (id) => {
        if (!options.selectable) return false;
        const listIncludesId = tableState.selectedList?.includes(id);
        return tableState.selectedAll ? !listIncludesId : listIncludesId;
    };

    const onChange = () => dispatchTableState({ type: TABLE_ACTION.SELECT, id: row.id });
    const checked = isRowSelected(row.id);
    const draggable =
        options.orderable &&
        (tableState.sortColumn == SORT_OPTIONS.ORDER || tableState.sortColumn == '');

    const getClassName = (conditionArray) => {
        const classes = [];
        conditionArray.forEach((item) => {
            if (item.condition) classes.push(item.class);
        });

        return classes.length > 0 ? classes.join(' ') : '';
    };

    return (
        <tr
            ref={ref}
            className={getClassName([
                { condition: options.orderable, class: 'moveable-item' },
                { condition: checked, class: 'checked-highlight' },
            ])}
        >
            {options.selectable && (
                <td>
                    <Checkbox {...{ checked, onChange }} />
                </td>
            )}
            {options.orderable && (
                <td
                    className={`dnd-grip ${draggable ? '' : 'disabled'}`}
                    title="Reorder your list by dragging and dropping an item to the desired position when sorted by 'My order'."
                >
                    {icons['grip']()}
                </td>
            )}
            {row.cols.map((column, i) => (
                <td key={i}>{column}</td>
            ))}
        </tr>
    );
});

export default TableRow;

TableRow.propTypes = {
    row: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        cols: PropTypes.arrayOf(PropTypes.node).isRequired,
    }),
};
