module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!-- Debug fields to be removed -->\n<td class="hits">'+
((__t=( hits ))==null?'':_.escape(__t))+
'</td>\n<td class="loc">\n    <a href="'+
((__t=( documentUrl ))==null?'':_.escape(__t))+
'">'+
((__t=( location ))==null?'':_.escape(__t))+
'</a>\n</td>\n<td class="title">\n    <ul>\n        ';
 _.each(highlighting.snippets, function(snippet) { 
__p+='\n            <li>\n                <a href="'+
((__t=( documentUrl ))==null?'':_.escape(__t))+
'">\n                    <!-- This is debug information about the field the fragment came from (notes/boundary etc) -->\n                    ';
 if (snippet.field != 'sec_text_exact') { 
__p+='<!--<b>[DEBUG:'+
((__t=( snippet.field ))==null?'':__t)+
']</b>-->';
 } 
__p+='\n                    '+
((__t=( snippet.fragment ))==null?'':__t)+
'\n                </a>\n            </li>\n        ';
 }) 
__p+='\n\n    </ul>\n</td>\n';
}
return __p;
};
