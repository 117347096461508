import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { UpCaretIcon, DownCaretIcon, DoubleCaret } from '../Icons/CaretIcon';
import { TableContext, TABLE_ACTION } from './TableContext';
import Checkbox from '../Checkbox';
import confirm from '../ConfirmModal/ConfirmModalService';

const Header = ({ headers }) => {
    const { tableState, dispatchTableState, options } = useContext(TableContext);
    const { sortColumn, selectedAll } = tableState;

    const setSortColumn = (sortColumn) =>
        dispatchTableState({ type: TABLE_ACTION.SORT, sortColumn });

    const selectAll = async () => {
        if (tableState.itemsCount - tableState.itemsPerPage > 0 && !tableState.selectedAll) {
            const selectAllContinue = await confirm({
                title: 'Select All on Multiple Pages',
                message: `You are about to select all items across ${Math.ceil(
                    tableState.itemsCount / tableState.itemsPerPage,
                )} pages. Do you want to continue?`,
            });
            if (selectAllContinue) {
                dispatchTableState({ type: TABLE_ACTION.SELECT_ALL });
            } else {
                console.error(
                    'need to figure out how to untick the box if not continuing',
                    tableState,
                );
                // The Checkbox is waiting to uncheck if we tell it to
                return true;
            }
        } else {
            dispatchTableState({ type: TABLE_ACTION.SELECT_ALL });
        }
    };

    return (
        <thead>
            <tr>
                {options.selectable && (
                    <th>
                        <Checkbox
                            {...{
                                checked: selectedAll,
                                onChange: selectAll,
                            }}
                        />
                    </th>
                )}

                {headers.map(({ title, dataField }, index) => {
                    //For columns that aren't sortable
                    if (!dataField || !options.sortable || (options.sortable && options.orderable))
                        return <th key={index}>{title}</th>;

                    let caretIcon = <DoubleCaret />;

                    if (sortColumn === dataField) {
                        //Already sorting on this column. Flip to descending sort
                        dataField = `-${dataField}`;
                        caretIcon = <UpCaretIcon />;
                    } else if (sortColumn === `-${dataField}`) {
                        //Already sorting on this column. Flip to ascending sort
                        caretIcon = <DownCaretIcon />;
                    }

                    return (
                        <th key={index} onClick={() => setSortColumn(dataField)}>
                            {title} {caretIcon}
                        </th>
                    );
                })}
            </tr>
        </thead>
    );
};

export default Header;

export const headersPropTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    dataField: PropTypes.string,
};

Header.propTypes = {
    headers: PropTypes.arrayOf(PropTypes.shape(headersPropTypes)),
};
