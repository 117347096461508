import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import UserAdderModal from '../UserDetails/UserAdderModal';

const UserAddContainer = ({ setDirty, shouldShow }) => {
    const [show, setShow] = useState(true);

    useEffect(() => {
        setShow(true);
    }, [shouldShow]);

    return (
        <>
            Add User
            {show && <UserAdderModal {...{ show, setDirty }} hideModal={() => setShow(false)} />}
        </>
    );
};

export default UserAddContainer;

UserAddContainer.defaultProps = {
    setDirty: () => {},
};

UserAddContainer.propTypes = {
    shouldShow: PropTypes.object.isRequired,
    setDirty: PropTypes.func.isRequired,
};
