import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import SourceCard from './SourceCard';
import { useStickyScroll } from '../customHooks';
import { TimelineContext } from './TimelineManager';

const REFS_AND_LINKS = ['anchor', 'source_ref', 'xref'];

const TimelineCard = ({ item }) => {
    const endDate = item['end-dt'];
    const startDate = item['start-dt'];
    const itemId = `${startDate}-${endDate}-${item.id}`;
    const periodMarker = startDate != endDate ? 'period-marker sticky' : '';
    const [collapse, setCollapse] = useState(!!periodMarker);
    let stickyTop = 20;
    const { stickyItems, setStickyItemsToggle, stickyItemsToggle } = useContext(TimelineContext);
    const cardRef = useRef();

    useStickyScroll(cardRef);

    // for telling all the other sticky to update when opening
    useEffect(() => {
        if (!!stickyItemsToggle[itemId] !== collapse) {
            setStickyItemsToggle({ ...stickyItemsToggle, [itemId]: collapse });
        }
    }, [collapse]);

    const getStickyTop = () => {
        const refIndex = stickyItems?.findIndex((el) => el.id === cardRef.current.id);
        if (refIndex > 0) {
            const alreadyStuck = stickyItems.slice(0, refIndex);
            if (alreadyStuck.length !== 0) {
                const newTop = alreadyStuck.reduce((acc, curr, index) => {
                    if (index == 0) acc += 20;
                    return (acc += curr.offsetHeight + 10);
                }, 0);
                stickyTop = newTop;
            }
        }
    };

    if (stickyItems.length) {
        getStickyTop();
    }

    return (
        <div
            id={itemId}
            className={`timeline-card ${item.colour ? item.colour : ''} ${periodMarker} ${
                collapse ? 'collapsed' : ''
            }`}
            ref={cardRef}
            start-dt={startDate} // eslint-disable-line
            end-dt={endDate} // eslint-disable-line
            style={periodMarker && stickyItems.length ? { top: `${stickyTop}px` } : {}}
        >
            <div className="period-header">{item.period_title}</div>
            <div className="card-title" dangerouslySetInnerHTML={{ __html: item.title }}></div>
            {periodMarker && (
                <span
                    className={`timeline-dropdown ${collapse ? 'collapsed' : 'opened'}`}
                    onClick={() => {
                        setCollapse(!collapse);
                    }}
                >
                    {collapse ? 'More info…' : 'Less info'}
                </span>
            )}
            {!collapse && (
                <>
                    <div
                        onClick={(e) => {
                            // open xrefs in new window
                            if (e.target.className === 'xref') {
                                window.manager.open(
                                    e.target.href.replace('/documents/', '/inter/'),
                                    {
                                        name: e.target.href.substr(1),
                                        features: 'width=800,height=500',
                                    },
                                );
                                e.preventDefault();
                            }
                        }}
                        className="card-body pane-doc pane-doc-timeline"
                        dangerouslySetInnerHTML={{ __html: item.body }}
                    ></div>
                    {item.source &&
                        item.source.map((source, index) => {
                            const source_ref = REFS_AND_LINKS.find((link) => link in source);
                            source.source_ref = source[source_ref];
                            source.type = source_ref;
                            return <SourceCard key={index} source={source} />;
                        })}
                </>
            )}
            {!periodMarker && <span className="timeline-circle" />}
        </div>
    );
};

export default TimelineCard;

TimelineCard.propTypes = {
    item: PropTypes.shape({
        body: PropTypes.string,
        colour: PropTypes.string,
        'end-dt': PropTypes.string.isRequired,
        period_title: PropTypes.string.isRequired,
        source: PropTypes.array,
        'start-dt': PropTypes.string.isRequired,
        title: PropTypes.string,
        stickyItems: PropTypes.object,
        setStickyItems: PropTypes.func,
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
};
