module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="publisher-listing">\n\n    ';
 if(collection.length) { 
__p+='\n        <ul>\n        ';
 collection.each(function(item) { 
__p+='\n            <li>\n                <a class="doc-link" href="/surveys/'+
((__t=( item.get('doc_id') ))==null?'':_.escape(__t))+
'" data-id="'+
((__t=( item.get('doc_id') ))==null?'':_.escape(__t))+
'">'+
((__t=( item.get('title') ))==null?'':_.escape(__t))+
'</a>\n            </li>\n        ';
 }); 
__p+='\n        </ul>\n    ';
 } else { 
__p+='\n        <p class="news-msg">No surveys found for this publisher.</p>\n    ';
 } 
__p+='\n\n</div>';
}
return __p;
};
