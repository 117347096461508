import React, { useState } from 'react';
import PropTypes from 'prop-types';

import UserHistoryContainer from './UserHistoryContainer';
import UserDetailRow from './UserDetailRow';
import ChangeEmailModal from '../Modals/ChangeEmailModal';
import EditNotesModal from '../Modals/EditNotesModal';
import models from '../../models.js';
import { Button } from 'react-bootstrap';
import { strToCapital, getDateTime, formatAlertFrequency } from '../utils';
import { UserFlags } from '../UserAdmin/Flags/UserFlags';
import Flags from '../UserAdmin/Flags/Flags';
import ResetPassword from '../Modals/ResetPasswordModal';
import UserEditorModal from './UserEditorModal';
import { useFetch } from '../customHooks';
import { WithLoadingOverlay } from '../FormControls/WithLoading';

const { pluralise } = require('../../extras/index');

const UserDetailsContainer = ({ userID }) => {
    const [modal, updateModal] = useState({ show: false, id: '' });
    const [dirtyData, setDirty] = useState(false);

    const userModel = new models.User({ id: userID }, 'GET');
    const metadataModel = new models.Metadata();

    const { data: metadata, loading: loadingMetadata } = useFetch({
        doFetch: async () => await metadataModel.fetch(),
    });

    const { data: user, loading } = useFetch({
        doFetch: async () => await userModel.fetch(),
        dirtyData: dirtyData,
        setFetchedState: () => setDirty(false),
    });

    // There's still a lot of data that needs to be retrieved from wherever it's stored to populate the form...
    const {
        email,
        first_name,
        last_name,
        organisation,
        roles,
        state,
        user_groups,
        user_details,
        date_added,
        date_joined,
        flags,
        last_login,
        notes,
        communication_prefs,
    } = user ?? {};

    const capitalisedState = state && strToCapital(state);
    const dateActivationEmail =
        date_added && state != 'scheduled' ? getDateTime(date_added) : 'scheduled';
    const dateActivated = date_joined && getDateTime(date_joined);
    const passwordResetDisabled =
        user_details?.reset_requests === metadata?.max_reset_requests || date_joined === null;
    const passwordDisabledMsg =
        date_joined === null ? 'User account has not been activated' : 'Limit reached';
    const news_alerts = formatAlertFrequency(communication_prefs?.news_alert_frequency);
    const doc_alerts = formatAlertFrequency(communication_prefs?.document_alert_frequency);

    const showFlags = () => updateModal({ show: true, id: 'flags' });
    const snoozedFlagCount = flags?.filter((flag) => flag.snoozed_until).length;
    const flagList = (
        <span className="user-details-flags">
            <Flags
                {...{
                    itemId: userID,
                    flags: flags?.filter((flag) => !flag.snoozed_until),
                    showFlags,
                }}
            />
            {snoozedFlagCount ? (
                <small>{`plus ${snoozedFlagCount} snoozed ${pluralise(
                    snoozedFlagCount,
                    'flag',
                    'flags',
                )}`}</small>
            ) : (
                ''
            )}
        </span>
    );

    const entryFields = [
        {
            title: 'First name',
            data: first_name,
        },
        {
            title: 'Last name',
            data: last_name,
        },
        {
            title: 'Job title',
            data: user_details?.job_title,
        },
        {
            title: 'Department',
            data: user_details?.department,
        },
        {
            title: 'Email address',
            data: email,
            aTagEdit: { btn: 'Change', id: 'email', permission: 'main.change_user' },
        },
        {
            title: 'Phone',
            data: user_details?.phone,
        },
        {
            title: 'Organisation',
            data: organisation?.name,
        },
        {
            title: 'User group',
            data: user_groups ? user_groups.map((group) => group.name) : '',
        },
        {
            title: 'Role / Permissions',
            data: roles?.map((role) => role.name),
        },
        {
            title: 'Work postcode',
            data: user_details?.postcode,
        },
        {
            title: 'Work address',
            data: user_details?.address,
        },
        { title: 'Date last activation email sent', data: dateActivationEmail },
        { title: 'Date activated', data: dateActivated },
        {
            title: 'Date of last sign on',
            data: getDateTime(last_login),
        },
        {
            title: 'Number of password resets',
            data: user_details?.reset_requests,
            aTagEdit: {
                btn: 'Send password reset email',
                id: 'password',
                permission: 'main.change_user',
                msg: passwordDisabledMsg,
            },
            disabled: passwordResetDisabled,
        },
        { title: 'News alerts', data: communication_prefs?.news_alerts ? news_alerts : '' },
        { title: 'Document Alerts', data: communication_prefs?.document_alerts ? doc_alerts : '' },
        { title: 'Status', data: capitalisedState },
        {
            title: 'Flag(s)',
            data: flagList,
            aTagEdit: { btn: 'Change', id: 'flags', permission: 'main.flag_user' },
        },
        {
            title: 'Notes',
            data: <span className="notes-display">{notes}</span>,
            aTagEdit: { btn: 'Edit', id: 'notes', permission: 'main.change_user' },
        },
    ];

    const handleModal = (id) => {
        updateModal({ show: !modal.show, id });
    };

    const userCanEdit =
        app.user.has_perm('main.change_userdetails') && app.user.has_perm('main.change_user');

    return (
        <WithLoadingOverlay loading={loading || loadingMetadata}>
            <div className="user-details-header">
                <div>
                    <h2>
                        <span className="user-name">
                            {first_name} {last_name}
                        </span>
                        {organisation?.name}
                    </h2>
                </div>
                {userCanEdit && (
                    <Button onClick={() => handleModal('details')}>Edit details</Button>
                )}
            </div>
            <table className="table user-details-table">
                <tbody>
                    {entryFields.map((entry, index) => {
                        return <UserDetailRow key={index} item={entry} handleModal={handleModal} />;
                    })}
                </tbody>
            </table>
            <UserHistoryContainer {...{ userID, dirtyData }} />
            <UserHistoryContainer {...{ userID, dirtyData, mandrillTable: true }} />
            {modal.show && modal.id === 'details' && (
                <UserEditorModal
                    userID={userID}
                    show={modal.show}
                    hideModal={() => handleModal('details')}
                    setDirty={() => setDirty(true)}
                    metadata={metadata}
                />
            )}
            {modal.show && modal.id === 'email' && (
                <ChangeEmailModal
                    handleModal={() => handleModal('email')}
                    show={modal.show}
                    currEmail={email}
                    userID={userID}
                    noEmailOption={state === 'scheduled'}
                    setDirty={() => {
                        setDirty(true);
                    }}
                />
            )}
            <EditNotesModal
                show={modal.show && modal.id === 'notes'}
                notes={notes}
                hideNotes={() => handleModal('notes')}
                setDirty={() => setDirty(true)}
                userModel={userModel}
            />
            <UserFlags
                show={modal.show && modal.id === 'flags'}
                selectedId={userID}
                hideFlags={() => handleModal('flags')}
                setDirty={() => setDirty(true)}
            />
            <ResetPassword
                handleModal={() => handleModal('password')}
                show={modal.show && modal.id === 'password'}
                email={email}
                fetchUser={() => {
                    setDirty(true);
                }}
                resetRequests={user_details?.reset_requests}
            />
        </WithLoadingOverlay>
    );
};

export default UserDetailsContainer;

UserDetailsContainer.propTypes = {
    userID: PropTypes.string.isRequired,
};
