import React, { useContext } from 'react';

import Pagination from 'react-bootstrap/Pagination';
import Form from 'react-bootstrap/Form';

import { TableContext, TABLE_ACTION } from './TableContext';

const PAGE_OPTIONS = [25, 50, 100, 250, 500];
const MIN_PAGER_OPTION = 25;
export const IGNORE_PAGING = 1000000000;

const PaginationControl = () => {
    const { tableState, dispatchTableState, options } = useContext(TableContext);
    const { itemsCount, page, itemsPerPage } = tableState;

    // Should it show pager?
    if (!options.pageable || (options.hidePagerOnLowRows && itemsCount < MIN_PAGER_OPTION))
        return <></>;

    const changePage = (page) => dispatchTableState({ type: TABLE_ACTION.CHANGE_PAGE, page });
    const changeItemsPerPage = (e) => {
        dispatchTableState({
            type: TABLE_ACTION.CHANGE_ITEMS_PER_PAGE,
            itemsPerPage: parseInt(e.target.value),
        });
    };

    const totalPages = itemsCount ? Math.ceil(itemsCount / itemsPerPage) : 1;

    const addPageItem = ({ number, active, key, text, disabled, className }) => {
        return (
            <Pagination.Item
                className={className}
                onClick={!disabled ? () => changePage(number) : undefined}
                key={key || number}
                active={active}
                disabled={disabled}
            >
                {text || number}
            </Pagination.Item>
        );
    };

    const items = [];

    //Add 'Previous' page
    const previous = {
        text: (
            <span>
                <span className="arrow arrow-right">« </span> <span>PREVIOUS </span>
            </span>
        ),
        key: 'previous',
        className: 'with-arrow',
    };
    if (page == 1) {
        items.push(addPageItem({ disabled: true, ...previous }));
    } else {
        items.push(addPageItem({ number: page - 1, ...previous }));
    }

    //Numbered pages
    if (totalPages < 6) {
        for (let number = 1; number <= totalPages; number++) {
            items.push(addPageItem({ number, active: number === page }));
        }
    } else {
        //There's a lot of pages. Show ellipses instead of showing all
        if (page - 3 > 1) {
            //Show ellipses at the start
            items.push(addPageItem({ number: 1, active: false }));
            items.push(<Pagination.Ellipsis key="ellipses-before" disabled />);
            items.push(addPageItem({ number: page - 1, active: false }));
            items.push(addPageItem({ number: page, active: true }));
        } else {
            //Show items up to active
            for (let number = 1; number <= page; number++) {
                items.push(addPageItem({ number, active: number === page }));
            }
        }

        if (page + 3 < totalPages) {
            //Show ellipse at the end
            items.push(addPageItem({ number: page + 1, active: false }));
            items.push(<Pagination.Ellipsis key="ellipses-after" disabled />);
            items.push(addPageItem({ number: totalPages, active: false }));
        } else {
            //Show items up to the end
            for (let number = page + 1; number <= totalPages; number++) {
                items.push(addPageItem({ number, active: false }));
            }
        }
    }

    //Add 'next' page
    const next = {
        text: (
            <span>
                <span>NEXT </span>
                <span className="arrow arrow-left">»</span>{' '}
            </span>
        ),
        key: 'next',
        className: 'with-arrow',
        changePage,
    };
    if (page == totalPages) {
        items.push(addPageItem({ disabled: true, ...next }));
    } else {
        items.push(addPageItem({ number: page + 1, ...next }));
    }

    return (
        <div className="pagination-control">
            <Pagination>{items}</Pagination>
            <div className="page-select">
                <Form.Label>Show:</Form.Label>
                <Form.Control
                    as="select"
                    value={itemsPerPage}
                    onChange={changeItemsPerPage}
                    aria-label="show per page"
                >
                    {PAGE_OPTIONS.map((pageOption) => (
                        <option key={pageOption}>{pageOption}</option>
                    ))}
                </Form.Control>
            </div>
        </div>
    );
};

export default PaginationControl;
