define([
    'backbone',
    'bootbox',
    '../models',
    './helpers',
    './document/main',
    './document/content',
    '../widgets/dragger',
], function (Backbone, bootbox, models, helpers, documentViews, DocumentContentView) {
    var monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    function groupByMulti(obj, values, context) {
        if (!values.length) {
            return obj;
        }
        var byFirst = _.groupBy(obj, values[0], context);
        var rest = values.slice(1);
        for (var prop in byFirst) {
            byFirst[prop] = groupByMulti(byFirst[prop], rest, context);
        }
        return byFirst;
    }

    function openInCompareView(id) {
        const pane = document.referrer.split('args=')[1];
        window.opener?.app._compareView.openDocument(
            id,
            null,
            pane,
            window.location.hash.replace('#', ''),
        );
        window.close();
    }

    var CurrentTabView = helpers.TemplateView.extend({
        el: '#current-tab',
        events: {
            'click .doc-link': 'navigate',
        },
        templateHelpers: function () {
            // Regroup the items by date
            var groups = _.groupBy(this.collection.models, function (item) {
                return item.attributes.instances[0].published_date;
            });
            // Sometimes the first of a month goes funny, so this resorts the groups just in case
            groups = Object.keys(groups)
                .sort((a, b) => new Date(b) - new Date(a))
                .reduce((date, key) => ((date[key] = groups[key]), date), {});
            return {
                groups: groups,
            };
        },
        initialize: function (options) {
            this.collection = options.collection;

            this.collection.bind('add', _.bind(this.render, this));
            this.collection.bind('reset', _.bind(this.render, this));
            this.collection.bind('remove', _.bind(this.render, this));
            this.listenTo(this, 'tab:current:loaded', this.currentLoaded);

            var qs = { tab: 'current' };
            this.collection
                .fetch({
                    data: qs,
                    processData: true,
                    reset: true,
                })
                .done(
                    _.bind(function () {
                        if (this.collection.models.length) {
                            var id = this.collection.models[0].attributes.doc_id;
                            this.trigger('tab:current:loaded', id);
                        } else {
                            this.notAvailable();
                        }
                    }, this),
                );
        },
        navigate: function (evt) {
            evt.preventDefault();
            var id = $(evt.currentTarget).data('id');
            if (window.opener?.app._compareView) {
                openInCompareView(id);
            } else {
                this.trigger('toc:navigate', id);
            }
        },
        currentLoaded: function () {
            $('#current-message').hide();
        },
        notAvailable: function () {
            $('#current-message').hide();
        },
    });

    var ArchiveListingView = helpers.TemplateView.extend({
        events: {
            'click .doc-link': 'navigate',
        },
        navigate: function (evt) {
            evt.preventDefault();
            var id = $(evt.currentTarget).data('id');
            if (window.opener?.app._compareView) {
                openInCompareView(id);
            } else {
                this.trigger('toc:navigate', id);
            }
        },
        templateHelpers: function () {
            // Regroup the items by date
            var groups = groupByMulti(this.collection.models, [
                function (item) {
                    return app.moment(item.attributes.instances[0].published_date).year();
                },
                function (item) {
                    return app.moment(item.attributes.instances[0].published_date).month();
                },
                function (item) {
                    return item.attributes.instances[0].published_date;
                },
            ]);
            return {
                title: this.title,
                publisher: this.publisher,
                year: this.year,
                groups: groups,
                topic: this.topic,
                monthNames: monthNames,
            };
        },
    });

    var ArchiveTabView = helpers.TemplateView.extend({
        el: '#archive-tab',
        events: {
            'click .archive-link': 'getArchive',
        },
        archiveListingView: ArchiveListingView,
        initialize: function (options) {
            this.collection = options.collection;
            this.listingCollection = options.listingCollection;

            this.collection.bind('sync', _.bind(this.render, this));
            this.listenTo(this, 'tab:archive:loaded', this.archiveLoaded);

            var qs = { tab: 'archive' };
            this.collection
                .fetch({
                    data: qs,
                    processData: true,
                    reset: true,
                })
                .done(
                    _.bind(function () {
                        this.trigger('tab:archive:loaded');
                    }, this),
                );
        },
        getArchive: function (evt) {
            evt.preventDefault();
            var update = false;

            var title = $(evt.currentTarget).closest('.topic-links').find('.topic-title').text();
            var topic = $(evt.currentTarget).data('topic');
            var year = $(evt.currentTarget).data('year');
            var options = { year: year, topic: topic, title: title };

            // Clear the previous archive listing view/element
            if (this.listingView) {
                if (this.listingView.topic != topic || this.listingView.year != year) {
                    update = true;
                    $(this.listingView.el).empty();
                }
            } else {
                update = true;
            }

            // Initialise the archive listing view
            if (update == true) {
                var listings = new this.listingCollection();
                listings
                    .fetch({
                        data: {
                            tab: 'archive',
                            topic: topic,
                            year: year,
                        },
                        processData: true,
                        reset: true,
                    })
                    .done(
                        _.bind(function () {
                            options['collection'] = listings;
                            this.listingView = new this.archiveListingView(options);
                            this.listenTo(this.listingView, 'toc:navigate', function (id) {
                                this.trigger('toc:navigate', id);
                            });
                            this.assign(this.listingView, '#archive-listing-' + topic);
                        }, this),
                    );
            }
        },
        archiveLoaded: function () {
            $('#archive-message').hide();
        },
    });

    var ByDateListingView = helpers.TemplateView.extend({
        el: '#bydate-listing',
        events: {
            'click .doc-link': 'navigate',
        },
        templateHelpers: function () {
            // Regroup the items by date
            var groups = groupByMulti(this.collection.models, [
                function (item) {
                    return app.moment(item.attributes.instances[0].published_date).year();
                },
                function (item) {
                    return app.moment(item.attributes.instances[0].published_date).month();
                },
                function (item) {
                    return item.attributes.instances[0].published_date;
                },
            ]);
            // Sometimes the dates inside the months are out of order, so this reorders them just in case
            for (var year in groups) {
                for (var month in groups[year]) {
                    groups[year][month] = Object.keys(groups[year][month])
                        .sort()
                        .reverse()
                        .reduce(
                            (dateObj, date) => (
                                (dateObj[date] = groups[year][month][date]), dateObj
                            ),
                            {},
                        );
                }
            }
            return {
                dateFrom: this.dateFrom,
                dateTo: this.dateTo,
                groups: groups,
                monthNames: monthNames,
            };
        },
        initialize: function (options) {
            this.dateFrom = options.dateFrom;
            this.dateTo = options.dateTo;
        },
        navigate: function (evt) {
            evt.preventDefault();
            var id = $(evt.currentTarget).data('id');
            if (window.opener?.app._compareView) {
                openInCompareView(id);
            } else {
                this.trigger('toc:navigate', id);
            }
        },
    });

    var ByDateTabView = helpers.TemplateView.extend({
        el: '#by-date-tab',
        events: {
            'click .insert-today': 'insertToday',
            'click .btn-datepicker': 'showPicker',
            'click #clearSearchDates': 'clearSearchDates',
            'click #searchByDate': 'searchByDate',
        },
        byDateListingView: ByDateListingView,
        initialize: function (options) {
            this.listingCollection = options.listingCollection;
            this.listenTo(this, 'render', this.setWidgets);
        },
        setWidgets: function () {
            $('#bydate-message').hide();

            $('.date-entry .form-control').datepicker({
                autoclose: true,
                multidate: false,
            });
        },
        insertToday: function (evt) {
            evt.preventDefault();

            $(evt.currentTarget)
                .siblings('.form-control')
                .val(app.moment().format(app.settings.dateFormat));
        },
        showPicker: function (evt) {
            evt.preventDefault();
            $(evt.currentTarget).siblings('.form-control').focus();
        },
        clearListing: function () {
            if (this.listingView) {
                $(this.listingView.el).empty();
            }
        },
        clearSearchDates: function () {
            // Reset the datepicker's default to today before clearing the inputs
            $('.date-entry .form-control').datepicker('setDate', app.moment().format('DD/MM/YYYY'));
            $('#daterange-from').val('');
            $('#daterange-to').val('');
            this.clearListing();
        },
        searchByDate: function () {
            // Clear the previous search listing view/element
            this.clearListing();

            // Grab the values from the date picker
            var dateFrom = $('#daterange-from').val();
            var dateTo = $('#daterange-to').val();

            if (dateFrom) {
                var listings = new this.listingCollection();
                var options = {
                    dateFrom: dateFrom,
                    dateTo: dateTo,
                    collection: this.listingCollection,
                };
                this.$('#bydate-message').show();

                listings
                    .fetch({
                        data: { tab: 'bydate', date_from: dateFrom, date_to: dateTo },
                        processData: true,
                        reset: true,
                    })
                    .done(
                        _.bind(function () {
                            options['collection'] = listings;

                            // Initialise the new by date listing view
                            this.listingView = new this.byDateListingView(options);
                            this.listenTo(this.listingView, 'toc:navigate', function (id) {
                                this.trigger('toc:navigate', id);
                            });
                            this.assign(this.listingView, '#bydate-listing');

                            this.$('#bydate-message').hide();
                        }, this),
                    );
            }
        },
    });

    var PublisherListingView = helpers.TemplateView.extend({
        events: {
            'click .doc-link': 'navigate',
        },
        templateHelpers: function () {
            // Regroup the items by date
            var groups = groupByMulti(this.collection.models, [
                function (item) {
                    return app.moment(item.attributes.instances[0].published_date).year();
                },
                function (item) {
                    return app.moment(item.attributes.instances[0].published_date).month();
                },
                function (item) {
                    return item.attributes.instances[0].published_date;
                },
            ]);

            return {
                title: this.title,
                publisher: this.publisher,
                year: this.year,
                groups: groups,
                monthNames: monthNames,
            };
        },
        initialize: function (options) {
            this.year = options.year;
            this.topic = options.topic;
            this.title = options.title;
        },
        navigate: function (evt) {
            evt.preventDefault();
            var id = $(evt.currentTarget).data('id');
            if (window.opener?.app._compareView) {
                openInCompareView(id);
            } else {
                this.trigger('toc:navigate', id);
            }
        },
    });

    var PublisherTabView = helpers.TemplateView.extend({
        el: '#publisher-tab',
        publisherListingView: PublisherListingView,
        events: {
            'click .publisher-initials a': 'scrollToGroup',
            'click .publisher-link': 'showSurveys',
        },
        initialize: function (options) {
            $('#publisher-message').show();

            this.collection = options.collection;
            this.listingCollection = options.listingCollection;
            this.collection.bind('sync', _.bind(this.publishersLoaded, this));

            this.collection.fetch({
                data: { tab: 'publisher' },
                processData: true,
                reset: true,
            });
        },
        templateHelpers: function () {
            var groups = this.collection.groupBy(function (item) {
                return item.get('researcher').substr(0, 1).toLowerCase();
            });

            return {
                groups: groups,
            };
        },
        publishersLoaded: function () {
            this.render();
            $('#publisher-message').hide();
        },
        scrollToGroup: function (evt) {
            evt.preventDefault();
            this.$($(evt.currentTarget).attr('href'))[0].scrollIntoView();
        },
        showSurveys: function (evt) {
            evt.preventDefault();
            var update = false;
            var publisher = $(evt.currentTarget).data('publisher');
            var listingEl = $(evt.currentTarget).siblings('.publisher-listing').attr('id');

            // Clear the previous listing view/element
            if (this.listingView) {
                if (this.listingView.publisher != publisher) {
                    update = true;
                    $(this.listingView.el).empty();
                }
            } else {
                update = true;
            }

            if (update) {
                var listings = new this.listingCollection();
                listings
                    .fetch({
                        data: {
                            tab: 'publisher',
                            researcher: publisher,
                        },
                        processData: true,
                        reset: true,
                    })
                    .done(
                        _.bind(function () {
                            this.listingView = new this.publisherListingView({
                                collection: listings,
                            });
                            this.listenTo(this.listingView, 'toc:navigate', function (id) {
                                this.trigger('toc:navigate', id);
                            });
                            this.assign(this.listingView, '#' + listingEl);
                        }, this),
                    );
            }
        },
    });

    var EmptyContentView = helpers.TemplateView.extend({
        el: '.pane-main',
    });

    var TOCView = helpers.TemplateView.extend({
        el: '.pane-left',
        currentTabView: CurrentTabView,
        archiveTabView: ArchiveTabView,
        byDateTabView: ByDateTabView,
        initialize: function (options) {
            this.options = options;
            this.current = new this.currentTabView({
                collection: new this.documentCollectionModel(),
            });
            this.listenTo(this.current, 'toc:navigate', function (id) {
                this.trigger('toc:navigate', id);
            });
            this.listenTo(this.current, 'tab:current:loaded', function (id) {
                this.trigger('tab:current:loaded', id);
            });

            this.archive = new this.archiveTabView({
                collection: new this.topicCollectionModel(),
                listingCollection: this.documentCollectionModel,
            });
            this.listenTo(this.archive, 'toc:navigate', function (id) {
                this.trigger('toc:navigate', id);
            });

            this.bydate = new this.byDateTabView({
                listingCollection: this.documentCollectionModel,
            });
            this.listenTo(this.bydate, 'toc:navigate', function (id) {
                this.trigger('toc:navigate', id);
            });

            if (this.options.showPublishers) {
                this.publisher = new this.publisherTabView({
                    collection: new this.publisherCollectionModel(),
                    listingCollection: this.documentCollectionModel,
                });
                this.listenTo(this.publisher, 'toc:navigate', function (id) {
                    this.trigger('toc:navigate', id);
                });
            }

            this.listenTo(this, 'render', this.initDragger);
            this.listenTo(this, 'render', this.renderSubViews);
            this.listenTo(this, 'render', this.setInitialTab);
        },
        renderSubViews: function () {
            this.assign(this.current, '#current-tab');
            this.assign(this.archive, '#archive-tab');
            this.assign(this.bydate, '#by-date-tab');
            if (this.options.showPublishers) {
                this.assign(this.publisher, '#publisher-tab');
            }
        },
        setInitialTab: function () {
            if (this.options.hash) {
                $('[href="' + this.options.hash + '"]').tab('show');
            }
        },
        initDragger: function () {
            var draggerWidth = parseInt(this.$('#toggle-pane-left').css('width'));
            var view = this;

            this.$('#toggle-pane-left').dragger({
                repositionCallback: function (x, y, e) {
                    if ($('body').hasClass('with-pane-left-min')) {
                        return;
                    }
                    var tocWidth = x + draggerWidth;
                    // write in the next frame
                    _.defer(function () {
                        view.doc.$('.pane-left, .panetitle-left').css({
                            width: tocWidth,
                        });
                        view.doc.$('.pane-main, .panetitle-main').css({
                            left: tocWidth,
                        });
                    });
                },
                bounds: {
                    left: 0,
                    right: function () {
                        return $(document).width() - draggerWidth - 100;
                    },
                },
                clickCallback: _.bind(function (hasDragged) {
                    if (!hasDragged) {
                        $('.panetitle-left, #toc, #main, .panetitle-main').removeAttr('style'); // cheating
                        $('body').toggleClass('with-pane-left');
                        $('body').toggleClass('with-pane-left-min');
                    } else if ($('body').hasClass('with-pane-left-min')) {
                        $('.panetitle-left, #toc, #main, .panetitle-main').removeAttr('style'); // cheating
                        $('body').addClass('with-pane-left');
                        $('body').removeClass('with-pane-left-min');
                    } else if ($('.panetitle-left').width() < 10) {
                        $('.panetitle-left, #toc, #main, .panetitle-main').removeAttr('style'); // cheating
                        $('body').removeClass('with-pane-left');
                        $('body').addClass('with-pane-left-min');
                    }
                }, this),
            });
        },
    });

    /*
        A document with TOC and content pane
    */
    var DocumentView = helpers.TemplateView.extend({
        el: 'body',
        initialize: function (options) {
            this.options = options;

            // Initialise the TOC tab view
            this.toc = new this.tocView(options);
            this.listenTo(this.toc, 'toc:navigate', this.navigate);
            this.listenTo(this.toc, 'tab:current:loaded', this.currentLoaded);

            // Initialise the content view only if we have a model,
            // otherwise we load an empty container and get the latest
            // item when the current tab has finished loading.
            if (this.model) {
                this.content = new DocumentContentView(options);
            } else {
                this.content = new this.emptyContentView(options);
            }

            // This is required for the dragger to work
            this.toc.doc = this.content.doc = this;

            this.listenTo(this, 'render', this.renderSubViews);
            this.setWindowTitle();
        },
        setWindowTitle: function () {
            var title = '';
            if (this.model) {
                title += this.model.get('title') + ' ' + this.model.get('document') + ' - ';
            }
            title += 'Perspective';
            document.title = title;
        },
        renderSubViews: function () {
            this.assign(this.toc, '.pane-left');
            this.assign(this.content, '.pane-main');
        },
        navigate: function (id) {
            // Create the new document model
            var doc = new this.documentModel({
                doc_id: id,
            });

            // Load the document
            doc.fetch()
                .done(
                    _.bind(function () {
                        var doc_instance = doc.getInstance();

                        if (doc_instance) {
                            this.content = new DocumentContentView({
                                model: doc_instance,
                                doc: doc,
                                enableScroll: false,
                            });
                            this.content.doc = this;

                            // Renders the title and toolbar
                            app._navigationView = new documentViews.DocumentNavigationView({
                                model: doc_instance,
                                doc: doc,
                            });
                            app._navigationView.render();
                            app.documents.push(doc);

                            // Store the model (doc_instance) and doc and set the window title
                            this.model = doc_instance;
                            this.doc = doc;
                            this.setWindowTitle();
                        } else {
                            //app.trigger('error:404');
                        }
                    }, this),
                )
                .fail(
                    _.bind(function (xhr) {
                        if (xhr.status == 404) {
                            //app.trigger('error:404');
                        }
                    }, this),
                );
        },
        currentLoaded: function (id) {
            // If we have not loaded any items we show the latest
            // when the current tab has finsished loading.
            if (
                !this.model &&
                this.options.hash != '#archive-tab' &&
                this.options.hash != '#by-date-tab'
            ) {
                this.navigate(id);
            }
        },
    });

    return {
        CurrentTabView: CurrentTabView,
        ArchiveListingView: ArchiveListingView,
        ArchiveTabView: ArchiveTabView,
        ByDateListingView: ByDateListingView,
        ByDateTabView: ByDateTabView,
        PublisherListingView: PublisherListingView,
        PublisherTabView: PublisherTabView,
        EmptyContentView: EmptyContentView,
        TOCView: TOCView,
        DocumentView: DocumentView,
        groupByMulti: groupByMulti,
    };
});
