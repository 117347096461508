module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h3>'+
((__t=( tableTitle ))==null?'':_.escape(__t))+
'</h3>\n\n';
 if(multiselect) { 
__p+='\n<div class="doc-actions">\n    <select class="form-control">\n        <option>---</option>\n        <option value="delete">Delete</option>\n    </select>\n</div>\n';
 } 
__p+='\n\n<table class="table table-bordered table-striped table-admin">\n    <thead>\n        <tr>\n            ';
 if(multiselect) { 
__p+='\n            <th class="select">Select</th>\n            ';
 } 
__p+='\n            <th class="instance">Instance</th>\n\n            <th class="title">Title</th>\n            <th class="notes">Notes</th>\n            <th class="date">Date Uploaded</th>\n            <!--<th class="who">Last updated by</th>-->\n            <th class="status">Status</th>\n            <th class="index">Search</th>\n            ';
 //if(actions.length) { 
__p+='\n            <th class="action">Actions</th>\n            ';
 //} 
__p+='\n        </tr>\n    </thead>\n    <tbody>\n        ';
 _.each(collection, function(instance) { 
__p+='\n        <tr>\n            ';
 if(multiselect) { 
__p+='\n            <td class="select">\n                <input type="checkbox" class="select-other", value="'+
((__t=( instance.uuid ))==null?'':_.escape(__t))+
'">\n            </td>\n            ';
 } 
__p+='\n            <td class="instance" title="'+
((__t=( instance.uuid ))==null?'':_.escape(__t))+
'">\n                ';
 if('reg-timeline' == instance.document) { 
__p+='\n                    ';
 if(instance.state == 'published') { 
__p+='\n                        <a href="'+
((__t=( viewInPlaceUrl ))==null?'':_.escape(__t))+
'">'+
((__t=( instance.uuid.substr(0,8) ))==null?'':_.escape(__t))+
'</a>\n                    ';
 } else if (instance.state == 'ready' || instance.state == 'draft' || instance.state == 'retired') {  
__p+='\n                        <a href="'+
((__t=( viewInPlaceUrl ))==null?'':_.escape(__t))+
'/'+
((__t=( instance.uuid ))==null?'':_.escape(__t))+
'">'+
((__t=( instance.uuid.substr(0,8) ))==null?'':_.escape(__t))+
'</a>\n                    ';
 } else { 
__p+='\n                        '+
((__t=( instance.uuid.substr(0,8) ))==null?'':_.escape(__t))+
'\n                    ';
 } 
__p+='\n                ';
 } else { 
__p+='\n                    ';
 if(instance.state == 'ready' || instance.state == 'draft' || instance.state == 'published' || instance.state == 'retired') { 
__p+='\n                        <a href="'+
((__t=( viewInPlaceUrl ))==null?'':_.escape(__t))+
'/'+
((__t=( instance.document ))==null?'':_.escape(__t))+
'/'+
((__t=( instance.uuid ))==null?'':_.escape(__t))+
'">'+
((__t=( instance.uuid.substr(0,8) ))==null?'':_.escape(__t))+
'</a>\n                    ';
 } else { 
__p+='\n                        '+
((__t=( instance.uuid.substr(0,8) ))==null?'':_.escape(__t))+
'\n                    ';
 } 
__p+='\n                ';
 } 
__p+='\n            </td>\n\n            <td class="title" title="Checksum: '+
((__t=( instance.checksum ))==null?'':_.escape(__t))+
'">'+
((__t=( instance.title ))==null?'':_.escape(__t))+
'</th>\n            <td class="notes">'+
((__t=( instance.conversion_notes_html ))==null?'':__t)+
'</th>\n            <td class="date">'+
((__t=( app.moment(instance.uploaded_at).format(app.settings['longDateTimeFormat']) ))==null?'':_.escape(__t))+
'</td>\n            <!--<td class="who"></td>-->\n            <td class="status">\n                <span class="flag flag-'+
((__t=( instance.state ))==null?'':_.escape(__t))+
'" title="'+
((__t=( instance.conversion_notes ))==null?'':_.escape(__t))+
'">\n                    ';
 if(instance.state == 'draft') { 
__p+='\n                    <span class="picon picon-edit"></span>\n                    ';
 } else if(instance.state == 'ready') { 
__p+='\n                    <span class="picon picon-thumbs-up"></span>\n                    ';
 } else if(instance.state == 'published') { 
__p+='\n                    <span class="picon picon-tick-circle"></span>\n                    ';
 } else if(instance.state == 'processing') { 
__p+='\n                    <span class="picon picon-edit"></span>\n                    ';
 } else { 
__p+='\n                    <span class="picon picon-trash"></span>\n                    ';
 } 
__p+='\n                    ';
 if (instance.state != 'archived') { 
__p+='\n                        '+
((__t=( instance.state ))==null?'':_.escape(__t))+
'\n                    ';
 } else { 
__p+='\n                        deleted\n                    ';
 } 
__p+='\n                </span>\n            </td>\n            <td class="index">\n                <span class="flag-'+
((__t=( instance.indexed ))==null?'':_.escape(__t))+
'">\n                    ';
 if(instance.indexed == 'UNA') { 
__p+='\n                    <span class="picon picon-down-circle"></span>\n                    ';
 } else if(instance.indexed == 'NOT') { 
__p+='\n                    <span class="picon picon-cross-circle"></span>\n                    ';
 } else if(instance.indexed == 'IND') { 
__p+='\n                    <span class="picon picon-tick-circle"></span>\n                    ';
 } else if(instance.indexed == 'ERR') { 
__p+='\n                    <span class="picon picon-uniE101"></span>\n                    ';
 } else if(instance.indexed == 'PEN') { 
__p+='\n                    <span class="picon picon-time"></span>\n                    ';
 } 
__p+='\n               </span>\n            </td>\n            <td class="action">\n                <div class="action-menu">\n                    <button class="btn btn-sm ';
 if (actions.length > 0 && (tableTitle != 'Other' || (instance.indexed != 'NOT' && instance.indexed != 'PEN'))) { 
__p+='btn-dropdown-toggle';
 } else {
__p+=' disabled ';
 } 
__p+='" ';
 if (tableTitle != 'Other' || instance.indexed != 'NOT') { 
__p+='data-toggle="dropdown"';
 } 
__p+=' title="Actions..." haspopup="';
 if(tableTitle != 'Other' || instance.indexed != 'NOT') { 
__p+=' true ';
 } else { 
__p+='false';
 } 
__p+='" role="menuitem">\n                        <span class="picon picon-cog"></span>\n                        <b class="caret"></b>\n                    </button>\n                    <ul class="dropdown-menu" role="menu" ';
 if (actions.length == 0 || (tableTitle == 'Other' && instance.indexed =='NOTE')) { 
__p+='disabled';
 } 
__p+='>\n                        ';
 _.each(actions, function(action) {  
__p+='\n                            ';
 if (action.index_state == '' || action.index_state == instance.indexed ||
                            (action.show_on_failed && instance.state == 'error')) { 
__p+='\n                                <li role="presentation">\n                                    <a href="#" role="menuitem"\n                                    data-action="'+
((__t=( action.action ))==null?'':_.escape(__t))+
'"\n                                    data-uuid="'+
((__t=( instance.uuid ))==null?'':_.escape(__t))+
'"\n                                    data-indexed="'+
((__t=( instance.indexed ))==null?'':_.escape(__t))+
'">'+
((__t=( action.title ))==null?'':_.escape(__t))+
'</a>\n                                </li>\n                            ';
 } 
__p+='\n                        ';
 }) 
__p+='\n                    </ul>\n                </div>\n            </td>\n        </tr>\n        ';
 }) 
__p+='\n    </tbody>\n</table>';
}
return __p;
};
