import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import PiconIcon from '../FormControls/Icons/PiconIcon';

import IndexItem from './IndexItem';
import { todFilterPropTypes } from './utils';
import _ from 'lodash';
import ContentContainer from '../ContentManagement/ContentContainer';
import { useResize } from '../customHooks';

const RESIZE_STEP = 0.5;
const PANEL_STOP_SIZE = 25;

const IActiveItem = { id: '', active: false };

const ToDIndexPanel = ({
    indexOptions,
    indexFilterOptions,
    setShowEditor,
    validateFilterData,
    saveContent,
    setHash,
}) => {
    const [showPanel, setShowPanel] = useState(true);
    const [activeItem, setActiveItem] = useState(IActiveItem);
    const [PANEL_START_SIZE, setPANEL_START_SIZE] = useState(250);
    const ref = useRef();
    const { initResize } = useResize(ref, RESIZE_STEP, PANEL_START_SIZE, PANEL_STOP_SIZE);

    const handleWindowSizeChange = () => {
        const refWidth = ref.current.style.width;

        // Make sure if the panel is closed and the window resized the panel control
        // isn't extended when it shouldn't be
        if (`${PANEL_STOP_SIZE}px` != refWidth) {
            setPANEL_START_SIZE(window.innerWidth * 0.25);
            ref.current.style.width = `${window.innerWidth * 0.25}px`;
        }
    };

    const handleScrollToTop = () => {
        setActiveItem(IActiveItem);
        setHash('#top');
    };

    const isInView = (element) => {
        const bounding = element.getBoundingClientRect();
        return (
            bounding.top >= 0 &&
            bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        );
    };

    const handleListScrolling = () => {
        const hashTarget = document.getElementById(window.location.hash.replace('#', ''));
        if (hashTarget) {
            const inView = isInView(hashTarget);
            if (!inView) {
                setHash('#');
            }
        }
    };

    useEffect(() => {
        if (_.isEmpty(indexOptions) && _.isEmpty(indexFilterOptions)) {
            setShowPanel(false);
        } else {
            setShowPanel(true);
        }
    }, [indexFilterOptions, indexOptions]);

    useEffect(() => {
        const panelWidth = window.innerWidth * 0.25;

        setPANEL_START_SIZE(panelWidth);
        ref.current.style.width = `${panelWidth}px`;

        window.addEventListener('resize', handleWindowSizeChange);
        return () => window.removeEventListener('resize', handleWindowSizeChange);
    }, []);

    useEffect(() => {
        // This handles if the index panel has been clicked to collapse rather than resized on drag
        const handleClick = () => {
            const shouldShow = !showPanel;
            shouldShow
                ? (ref.current.style.width = `${PANEL_START_SIZE}px`)
                : (ref.current.style.width = `${PANEL_STOP_SIZE}px`);
            setShowPanel(shouldShow);
        };

        const collapser = document.getElementById('collapse-control');
        collapser.addEventListener('click', handleClick);
        return () => collapser.removeEventListener('click', handleClick);
    }, [showPanel]);

    useEffect(() => {
        // This handles reclicking same index panel item after clicking the scroll to top button
        const topButton = document.getElementById('toolbar-top');
        topButton.addEventListener('click', handleScrollToTop);
        return () => topButton.removeEventListener('click', handleScrollToTop);
    }, []);

    useEffect(() => {
        // This handles if reclicking same index item after scrolling away
        const pageList = document.getElementById('tod-page-list');
        pageList.addEventListener('scroll', handleListScrolling);
        return () => pageList.removeEventListener('scroll', handleListScrolling);
    });

    return (
        <div
            ref={ref}
            className={`tod-index-panel hide-scrollbars ${showPanel ? 'open' : 'closed'}`}
        >
            <div
                className="tod-index-panel-control"
                title={`${showPanel ? 'Hide' : 'View'} Index Options`}
                onMouseDown={initResize}
            >
                <div id="collapse-control">
                    <PiconIcon className="sm blue" iconName={showPanel ? 'previous' : 'next'} />
                    <PiconIcon className="sm blue" iconName={showPanel ? 'previous' : 'next'} />
                </div>
            </div>
            {showPanel && ref.current?.offsetWidth > 20 && (
                <div id="top-index" className="tod-index-panel-inner">
                    {Object.entries(indexOptions).map(([key, values]) => {
                        const filterOption =
                            key === '' && indexFilterOptions.length === 1
                                ? indexFilterOptions[0]
                                : indexFilterOptions.find((option) => option.title === key);
                        const itemTitle = filterOption ? filterOption.title : '';
                        // Determine how the index items need to be displayed (although this isn't a nice way of doing it)
                        const displayAsBtnList = filterOption?.display_as_buttons;

                        return (
                            <React.Fragment key={`tod-index-${key}`}>
                                <ContentContainer
                                    doFetch={() => ({
                                        pk: filterOption.pk,
                                        doc_filter: filterOption.doc_filter,
                                        content: filterOption.content_top,
                                        helpTextUrl: `/admincms_lite/todfilter/${filterOption.pk}/change/`,
                                    })}
                                    doPost={saveContent}
                                    contentType="content_top"
                                    canEdit={app.user.has_perm('cms_lite.edit_tod_filter')}
                                />
                                <IndexItem
                                    {...{
                                        title: itemTitle,
                                        items: values,
                                        activeItem,
                                        setActiveItem,
                                        indexDepth: parseInt(filterOption?.index_level),
                                        setShowEditor: () => {
                                            // eslint-disable-next-line no-unused-vars
                                            const { content_top, content_bottom, ...fOption } =
                                                filterOption;
                                            setShowEditor({
                                                show: true,
                                                content: fOption,
                                                validateData: validateFilterData,
                                                helpTextUrl: `/admincms_lite/todfilter/${filterOption.pk}/change/`,
                                            });
                                        },
                                        displayAsBtnList,
                                        collapseInnerFilters: filterOption?.collapse_doc_filter,
                                        setHash,
                                        docFilter: filterOption?.doc_filter,
                                    }}
                                />
                                <ContentContainer
                                    doFetch={() => ({
                                        pk: filterOption?.pk,
                                        doc_filter: filterOption?.doc_filter,
                                        content: filterOption?.content_bottom,
                                        helpTextUrl: `/admincms_lite/todfilter/${filterOption?.pk}/change/`,
                                    })}
                                    doPost={saveContent}
                                    contentType="content_bottom"
                                    canEdit={app.user.has_perm('cms_lite.edit_tod_filter')}
                                />
                            </React.Fragment>
                        );
                    })}
                </div>
            )}
            <div style={{ height: '750px' }}></div>
        </div>
    );
};

export default ToDIndexPanel;

ToDIndexPanel.propTypes = {
    indexOptions: PropTypes.object,
    indexFilterOptions: PropTypes.arrayOf(PropTypes.shape(todFilterPropTypes)),
    setShowEditor: PropTypes.func.isRequired,
    validateFilterData: PropTypes.func.isRequired,
    saveContent: PropTypes.func.isRequired,
    setHash: PropTypes.func.isRequired,
};
