// view controller for Perspective app

define([
    'backbone',
    './models',
    './views/main',
    './views/document/main',
    './views/document/compare',
    './views/document/popup',
    './views/document/print',
    './views/news',
    './views/surveys',
    './views/notes',
    './views/nav',
    './views/search/doc_results',
    './views/administration',
    './views/preferences',
    './components/utils',
], function (
    Backbone,
    models,
    mainViews,
    documentViews,
    CompareView,
    winView,
    PrintView,
    newsViews,
    surveyViews,
    NotesView,
    navViews,
    DocumentSearchResultsView,
    administrationViews,
    prefsViews,
    utils,
) {
    var Controller = function (app) {
        app.on('popupWin:open', winView.openPopupWin, this);
        app.on('error:404', this.error404, this);
    };

    const React = require('react');
    const ReactDOM = require('react-dom');

    _.extend(Controller.prototype, Backbone.Events, {
        error404: function () {
            var errorView = new mainViews.Error404View();
            errorView.render();
        },
        error403: function () {
            var errorView = new mainViews.Error403View();
            errorView.render();
        },
        errorGeneric: function () {
            var errorView = new mainViews.ErrorGenericView();
            errorView.render();
        },
        errorHandler: function (xhr) {
            switch (xhr.status) {
                case 404: {
                    this.error404(xhr);
                    break;
                }
                case 403: {
                    this.error403(xhr);
                    break;
                }
                default: {
                    this.errorGeneric(xhr);
                    break;
                }
            }
        },
        /*
                Execute actions on every page
            */
        global: function () {
            this.globalActions = new mainViews.GlobalActions();
        },
        /*
                Nearly all pages need a toolbar
            */
        toolbar: function () {
            this.toolbar = new navViews.ToolbarView();
            app._toolbar = this.toolbar;
        },
        emailAlertPreferencePopup: function () {
            if (window.localStorage.getItem('logging_in') == 'true') {
                window.localStorage.setItem('logging_in', null);

                const user = app.user.attributes;
                if (
                    !user.communication_prefs.has_set_alert_preferences &&
                    (user.is_superuser || user.user_groups.some((g) => g.email_alerts_enabled))
                ) {
                    const mainSelector = 'email-alert';
                    const el = $(`<span class="rbs4" id=${mainSelector}></span>`);
                    const main = $(document.getElementById('main'));
                    if (main.length == 0) return;
                    main.append(el);
                    const EmailAlertPreferenceModal =
                        require('./components/UserSettings/EmailAlertPreferenceModal').default;

                    this.initReactComponent({
                        children: <EmailAlertPreferenceModal />,
                        mainSelector,
                        elementSelector: '',
                    });
                }
            }
        },
        index: async function () {
            const loggedInView = app.user.isAuthenticated;

            // Homepage Panels set up
            const {
                HOMEPAGE_CONTENT_TYPES,
                HOMEPAGE_ANON_CONTENT_TYPES,
            } = require('./components/ContentManagement/ContentContainer');
            const ContentContainer =
                require('./components/ContentManagement/ContentContainer').default;
            const contentModel = new models.HomepageContent();
            const anonContentModel = new models.HomepageAnonContent();
            const canEdit =
                app.user.has_perm('cms_lite.add_homepage_content') &&
                app.user.has_perm('cms_lite.edit_homepage_content');

            // Render Panels
            if (loggedInView) {
                const panels = await contentModel.retrieve();

                Object.values(HOMEPAGE_CONTENT_TYPES).forEach((contentType) => {
                    const doFetch = async () => {
                        const panel = panels.find(
                            (panel) => panel.content_type === contentType.toUpperCase(),
                        );
                        if (panel) {
                            return {
                                ...panel,
                                helpTextUrl: `/admincms_lite/homepagecontent/${panel.pk}/change/`,
                            };
                        } else {
                            // If the panel has never been set then it's undefined
                            // But we need to make sure we send some panel object through for the editor to be happy
                            return { content_type: contentType.toUpperCase(), content: '' };
                        }
                    };
                    const doPost = async (data) => {
                        const content = await contentModel.create(data);
                        return {
                            ...content,
                            helpTextUrl: `/admincms_lite/homepagecontent/${content.pk}/change/`,
                        };
                    };
                    ReactDOM.render(
                        <ContentContainer {...{ doFetch, doPost, contentType, canEdit }} />,
                        document.getElementById(contentType),
                    );
                });
            } else {
                const anonPanels = await anonContentModel.retrieve();

                Object.values(HOMEPAGE_ANON_CONTENT_TYPES).forEach((contentType) => {
                    const doFetch = async () => {
                        try {
                            // this is just getting the first one, needs to look at dates most likely
                            const panel = anonPanels.find(
                                (panel) => panel.content_type === contentType.toUpperCase(),
                            );
                            return panel;
                        } catch (exc) {
                            toaster.error('There was a problem retrieving page content.');
                        }
                    };
                    ReactDOM.render(
                        <ContentContainer {...{ doFetch, contentType, canEdit }} />,
                        document.getElementById(contentType),
                    );
                });
            }

            // Homepage ToD set up
            this.tod();

            app._indexView = new mainViews.IndexView();
        },
        login: function () {
            app._indexView = new mainViews.LoginView();
        },
        testPostcode: function () {
            const groupPostcodes = JSON.parse($('#org-postcodes').text().replaceAll("'", '"'));
            utils.testPostcode(groupPostcodes);
        },
        activate: function () {
            window.localStorage.setItem('logging_in', true);

            this.testPostcode();

            $('input:radio[name="auth_method"]').on('change', function () {
                if ($('#auth_method-password').is(':checked')) {
                    $('#password_container').show();
                    $('#id_password').focus();
                } else {
                    $('#password_container').hide();
                }
            });
        },
        /*
            Function to initialize react components on main container.
        */
        initReactComponent: function ({
            children,
            cookies,
            mainSelector = 'main',
            elementSelector = '.main-content .container ',
        }) {
            const main = $(document.getElementById(mainSelector));
            $(main).addClass('rbs4');
            const el = elementSelector !== '' ? main.find(elementSelector)[0] : main[0];

            if (cookies) {
                const CookiesProvider = require('react-cookie').CookiesProvider;
                ReactDOM.render(<CookiesProvider>{children}</CookiesProvider>, el);
            } else {
                ReactDOM.render(children, el);
            }
        },
        /*
                Single serving document view
            */
        document: function (documentID, instanceID, queryParams) {
            var doc = new models.Document({
                doc_id: documentID,
                activeInstance: instanceID,
            });
            var annotations = new models.Annotations({ document: documentID });
            var favourites = new models.Favourites({ document: documentID });

            $.when(annotations.fetch(), doc.fetch(), favourites.fetchByDoc())
                .done(
                    _.bind(function () {
                        var docInstance = doc.getInstance();

                        if (docInstance) {
                            app.documentView = new documentViews.DocumentView({
                                model: docInstance,
                                doc: doc,
                                queryParams: queryParams,
                                annotations: annotations,
                                favourites: favourites,
                            });

                            app.documents.push(doc);

                            app.trigger('document:content:show');

                            // update published recent documents with this one and double check it isn't a Survey or News
                            if (
                                docInstance.get('state') == 'published' &&
                                !docInstance
                                    .get('families')
                                    .find((fam) => fam.slug === 'srv' || fam.slug === 'news')
                            ) {
                                app.recentDocuments.open(documentID);
                            }
                        } else {
                            this.error404();
                        }
                    }, this),
                )
                .fail(_.bind(this.errorHandler, this));
        },
        /*
                Notes (in an intermediate window)
            */
        notes: function (documentID, instanceID, sectionID) {
            var doc = new models.Document({
                doc_id: documentID,
                activeInstance: instanceID,
            });
            doc.fetch()
                .done(
                    _.bind(function () {
                        var doc_instance = doc.getInstance();
                        if (doc_instance) {
                            var m_url = doc_instance.getMediaURL();
                            var n_title = doc.get('title');
                            app._notesView = new NotesView({
                                url: m_url,
                                title: n_title,
                                section: sectionID,
                                model: doc_instance,
                            });
                            app._notesView.render();
                            app.documents.push(doc);
                        } else {
                            this.error404();
                        }
                    }, this),
                )
                .fail(_.bind(this.errorHandler, this));
        },
        /*
                Annotations
            */
        annos: async function (documentID, familiesList) {
            const AnnotationManager =
                require('./components/Annotations/AnnotationsTable/AnnotationManager').default;
            const families = await new models.Families().getUserFamilies();
            this.initReactComponent({
                children: (
                    <AnnotationManager
                        documentID={documentID}
                        families={families}
                        docFamilies={familiesList}
                    />
                ),
                mainSelector: 'content',
                elementSelector: '',
            });
        },
        /*
                Favourites
        */
        faves: function () {
            const FavouriteManage = require('./components/Favourites/FavouriteManage').default;
            const userID = app.user.attributes.id;

            this.initReactComponent({
                children: <FavouriteManage {...{ userID }} />,
                mainSelector: 'content',
                elementSelector: '',
            });
        },
        /*
                Single serving news view
            */
        news: function (documentID, instanceID) {
            if (documentID || instanceID) {
                var doc = new models.NewsDocument({
                    doc_id: documentID,
                    activeInstance: instanceID,
                });

                doc.fetch()
                    .done(
                        _.bind(function () {
                            var doc_instance = doc.getInstance();

                            if (doc_instance) {
                                // Renders the news item
                                app.documentView = new newsViews.DocumentView({
                                    model: doc_instance,
                                    doc: doc,
                                    enableScroll: false,
                                });
                                app.documentView.render();

                                // Renders the title and toolbar
                                app._navigationView = new documentViews.DocumentNavigationView({
                                    model: doc_instance,
                                    doc: doc,
                                });
                                app._navigationView.render();
                            } else {
                                this.error404();
                            }
                        }, this),
                    )
                    .fail(_.bind(this.errorHandler, this));
            } else {
                var hash = window.location.hash;
                // Renders a blank news view with just toc
                app.documentView = new newsViews.DocumentView({
                    hash: hash,
                });
                app.documentView.render();
            }
        },
        /*
                Single serving survey view
            */
        surveys: function (documentID, instanceID) {
            if (documentID || instanceID) {
                var doc = new models.SurveyDocument({
                    doc_id: documentID,
                    activeInstance: instanceID,
                });

                doc.fetch()
                    .done(
                        _.bind(function () {
                            var doc_instance = doc.getInstance();

                            if (doc_instance) {
                                // Renders the survey item
                                app.documentView = new surveyViews.DocumentView({
                                    model: doc_instance,
                                    doc: doc,
                                    enableScroll: false,
                                    showPublishers: true,
                                });
                                app.documentView.render();

                                // Renders the title and toolbar
                                app._navigationView = new documentViews.DocumentNavigationView({
                                    model: doc_instance,
                                    doc: doc,
                                });
                                app._navigationView.render();
                            } else {
                                this.error404();
                            }
                        }, this),
                    )
                    .fail(_.bind(this.errorHandler, this));
            } else {
                var hash = window.location.hash;
                // Renders a blank survey view with just toc
                app.documentView = new surveyViews.DocumentView({
                    hash: hash,
                    showPublishers: true,
                });
                app.documentView.render();
            }
        },
        // recently opened documents
        rod: function () {
            app._popupWin = new winView.PopupWin();
        },
        // Pensions Timeline
        timeline: async function () {
            const TimelineManager = require('./components/Timeline/TimelineManager').default;
            app.recentDocuments.open('reg-timeline');

            this.initReactComponent({
                children: <TimelineManager />,
                mainSelector: 'content',
                elementSelector: '',
            });
        },
        // table of documents
        tod: async function (path) {
            const todWindow = /tod/.test(window.location.pathname);
            const loggedInView = app.user.isAuthenticated;
            const fromCompare = document.referrer.includes('compare') && window.opener != null;

            const ToDList = require('./components/ToD/ToDList').default;
            const ToDPage = require('./components/ToD/ToDPage').default;

            const familiesModel = new models.Families();
            const families = loggedInView ? await familiesModel.getUserFamilies() : [];

            const todModel = new models.TableOfDocuments();

            const doFetch = async () => {
                try {
                    return await todModel.retrieve();
                } catch (exc) {
                    toaster.error('There was a problem retrieving the Table of Documents');
                }
            };

            const doPost = async (data) => {
                try {
                    return await todModel.update(data);
                } catch (exc) {
                    toaster.error('There was a problem updating a line of the Table of Documents');
                }
            };

            // Make sure it initialize PopupWin so nav works as expected
            app._popupWin = new winView.PopupWin();

            if (!path) {
                // Set tod_lastpage to the tod homepage
                if (loggedInView && todWindow) app.user.displayPrefs({ tod_lastpage: '' });
                // Render ToD
                ReactDOM.render(
                    <ToDList
                        loggedIn={loggedInView}
                        todWindow={todWindow}
                        doFetch={doFetch}
                        doPost={doPost}
                        families={families}
                        fromCompare={fromCompare}
                    />,
                    document.getElementById('table_of_documents'),
                );
            } else {
                // the pane-main doesn't need to be able to scroll anymore as .inner will handle the scrolling
                $('.pane-main').addClass('no-scroll');
                ReactDOM.render(
                    <ToDPage page={path} todModel={todModel} families={families} />,
                    document.getElementById('table_of_documents'),
                );
            }
        },
        newAndRecent: async function () {
            const ToDPage = require('./components/ToD/ToDPage').default;
            const loggedInView = app.user.isAuthenticated;
            const familiesModel = new models.Families();
            const families = loggedInView ? await familiesModel.getUserFamilies() : [];

            const todModel = new models.TableOfDocuments();

            ReactDOM.render(
                <ToDPage
                    page="new-and-recently-amended-documents"
                    todModel={todModel}
                    families={families}
                />,
                document.getElementById('new_and_recently_amended_documents'),
            );
        },
        /*
                User settings page
            */
        settings: function () {
            const AccountSettings = require('./components/UserSettings/AccountSettings').default;
            ReactDOM.render(<AccountSettings />, document.getElementById('account-settings'));
        },
        /*
                Display preferences page
            */
        preferences: function () {
            app._displayPreferencesView = new prefsViews.DisplayPreferencesView();
        },
        /*
                Print popup
            */
        documentPrint: function (documentID, instanceID) {
            var doc = new models.Document({
                doc_id: documentID,
                activeInstance: instanceID,
            });

            doc.fetch()
                .done(
                    _.bind(function () {
                        var docInstance = doc.getInstance();
                        if (docInstance) {
                            app._printView = new PrintView({
                                model: docInstance,
                            });
                        } else {
                            this.error404();
                        }
                    }, this),
                )
                .fail(_.bind(this.errorHandler, this));
        },
        /*
             Document Upload page
            */
        documentUpload: function () {
            const UploadDocumentManage =
                require('./components/UploadDocument/UploadDocumentManage').default;
            this.initReactComponent({ children: <UploadDocumentManage /> });
        },
        /*
            Bulk user upload.
        */
        multiUserUpload: function () {
            if (app.user.has_perm('main.bulk_upload_users')) {
                const UserImport = require('./components/UserAdmin/UserImport').default;
                this.initReactComponent({ children: <UserImport /> });
            }
        },
        /*
                Document/section search page
            */
        search: async function (queryParams) {
            const families = new models.Families();
            await families.fetch();

            const formatFamilyTitle = (result) => {
                // Because the Display title for family can be changed in Django Admin,
                // we need to match the doc_primary_family saved in Solr to the family slug.
                const resultFam = families.models.find(
                    (fam) => fam.attributes.slug === result.attributes.doc_primary_family,
                );
                result.attributes['doc_primary_family_set_title'] =
                    resultFam?.attributes.title || '';

                return result;
            };

            if (queryParams.search == 'document') {
                var searchResults = new models.DocumentSearchResults(
                    window.documentSearchResultsPreFill.documents,
                );
                app.trigger('search:results:synced', {
                    total: window.documentSearchResultsPreFill.collectionData.numFound,
                    loaded: window.documentSearchResultsPreFill.documents.length,
                });

                searchResults.qs = queryParams;
                searchResults.collectionData = window.documentSearchResultsPreFill.collectionData;

                // Because it's sent to the template via context, we've got to get
                // the title sorted before it hits DocumentSearchResultsRow
                searchResults.models.forEach((result) => formatFamilyTitle(result));

                if (!_.isUndefined(queryParams.reverse_sort)) {
                    searchResults.reverseSort = queryParams.reverse_sort == 'true';
                }
                if (queryParams.sort_field) {
                    searchResults.sortField = queryParams.sort_field;
                }
                if (queryParams.reverse_sort || queryParams.sort_field) {
                    searchResults.sort();
                }

                this.listenTo(searchResults, 'sync', function (collection) {
                    // searchResults only holds the initial 30 hits, so this makes
                    // sure results 31+ also get correctly formatted.
                    collection.models.forEach((result) => formatFamilyTitle(result));

                    app.trigger('search:results:synced', {
                        total: collection.collectionData.numFound,
                        loaded: collection.length,
                    });
                });

                app._documentResultsView = new DocumentSearchResultsView({
                    searchResults: searchResults,
                });
                // log search
                searchResults.saveLog(queryParams, 'document');
            } else if (queryParams.search == 'section') {
                this.document(queryParams.doc_id, queryParams.doc_instance, queryParams);
            }
        },
        /*
                Compare 2 documents together
            */
        documentCompare: function (documentID, instanceID) {
            // if we're coming from an intermediate window, get the opener's active element
            const openerActiveElement = localStorage.getItem('opener_activeElement');
            // check if we're coming from an intermediate window and need to open a left and right pane at the same time
            const referrerURL = document.referrer ? new URL(document.referrer) : undefined;
            const rightDoc = referrerURL?.pathname.includes('inter')
                ? {
                      documentID: referrerURL.pathname.replaceAll('/', ' ').trim().split(' ')[1],
                      documentInstance: referrerURL.pathname
                          .replaceAll('/', ' ')
                          .trim()
                          .split(' ')[2],
                      target: 'right',
                      hash: localStorage.getItem('inter_window_hash'),
                  }
                : {};
            app._compareView = new CompareView({
                documentID: documentID,
                documentInstance: instanceID,
                target: 'left',
                hash: openerActiveElement,
                right: rightDoc,
            });
            app._compareView.render();
            localStorage.removeItem('inter_window_hash');
            // Don't remove opener_activeElement here or we'll loose our place
        },
        /*
                Administration Adding New Client-user.
            */

        addUser: function () {
            $(document.getElementById('add-user')).on('click', () => {
                const UserAddContainer = require('./components/UserAdmin/UserAddContainer').default;
                ReactDOM.render(
                    <UserAddContainer shouldShow={{ show: true }} />,
                    document.getElementById('add-user-container'),
                );
            });
        },

        /*
            Administration Users
        */
        administrationUsers: function () {
            const UserManage = require('./components/UserAdmin/UserManage').default;
            this.initReactComponent({ children: <UserManage />, cookies: true });
        },

        /*
                Administration Document list (publisher dashboard)
        */
        administrationDocuments: function () {
            const DocumentManage =
                require('./components/PublisherDashboard/DocumentManage').default;

            this.initReactComponent({ children: <DocumentManage /> });
        },

        /*
                Administration Document Upload list
        */
        administrationDocumentUpload: function (uploadID) {
            const DocumentUpload =
                require('./components/PublisherDashboard/DocumentUpload').default;

            this.initReactComponent({
                children: <DocumentUpload uploadID={uploadID} />,
            });
        },
        /*
                Administration Document detail (publisher dashboard)
            */
        administrationDocument: function (documentID) {
            var doc = new models.Document({
                doc_id: documentID,
            });

            doc.fetch()
                .done(function () {
                    app._administrationDocumentView = new administrationViews.DocumentView({
                        model: doc,
                    });
                    app._administrationDocumentView.render();
                })
                .fail(_.bind(this.errorHandler, this));
        },
        Organisation: function (organisationID) {
            const OrganisationContainer =
                require('./components/Organisation/OrganisationContainer').default;

            this.initReactComponent({
                children: <OrganisationContainer organisationID={organisationID} />,
            });
        },
        administrationUserDetails: function (userID) {
            const UserDetailsContainer =
                require('./components/UserDetails/UserDetailsContainer').default;
            ReactDOM.render(
                <UserDetailsContainer userID={userID} />,
                document.getElementById('user-details'),
            );
        },
    });

    return Controller;
});
