import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Select from '../FormControls/Select/Select';
import { DatePicker } from '../FormControls/DatePicker';

const ScheduleActivation = ({ data, setData }) => {
    const [schedule, updateSchedule] = useState(false);

    const tomorrow = app.moment.utc().add(1, 'days').toDate();

    return (
        <React.Fragment>
            <Form.Group>
                <Form.Label column sm={2}>
                    Schedule User Activation
                </Form.Label>

                <Select
                    onChange={(option) => {
                        if (option.value == 1) {
                            updateSchedule(true);
                        } else {
                            updateSchedule(false);
                        }
                        setData({ ...data, scheduled: option.value });
                    }}
                    defaultValue={{ value: '0', label: 'Immediately' }}
                    options={[
                        { value: '0', label: 'Immediately' },
                        { value: '1', label: 'Schedule activation to occur on...' },
                    ]}
                    name="schedule"
                    aria-label="schedule activation"
                />
            </Form.Group>
            {schedule && (
                <Form.Group>
                    <DatePicker minDate={tomorrow} required={schedule} />
                </Form.Group>
            )}
        </React.Fragment>
    );
};

export default ScheduleActivation;

ScheduleActivation.propTypes = {
    setData: PropTypes.func.isRequired,
    data: PropTypes.object,
};
