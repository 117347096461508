module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (app.user.has_perm('content.view_all_document_states')) { 
__p+='\n<td class="status">\n    <span class="flag flag-'+
((__t=( doc_state ))==null?'':_.escape(__t))+
'">\n        ';
 if(doc_state == 'draft') { 
__p+='\n        <span class="picon picon-edit"></span>\n        ';
 } else if(doc_state == 'ready') { 
__p+='\n        <span class="picon picon-thumbs-up"></span>\n        ';
 } else if(doc_state == 'published') { 
__p+='\n        <span class="picon picon-tick-circle"></span>\n        ';
 } else if(doc_state == 'processing') { 
__p+='\n        <span class="picon picon-spinner icon-spin"></span>\n        ';
 } else { 
__p+='\n        <span class="picon picon-trash"></span>\n        ';
 } 
__p+='\n        '+
((__t=( doc_state ))==null?'':_.escape(__t))+
'\n    </span>\n</td>\n<td class="id">'+
((__t=( doc_id  ))==null?'':_.escape(__t))+
'</td>\n';
 } 
__p+='\n<td class="hits">\n    ';
 if (includeHits) { 
__p+='\n        '+
((__t=( hits ))==null?'':_.escape(__t))+
'\n    ';
 } 
__p+='\n</td>\n<td class="fragments">\n    ';
 if (includeHits) { 
__p+='\n        '+
((__t=( fragments ))==null?'':_.escape(__t))+
'\n    ';
 } 
__p+='\n</td>\n<td class="family">'+
((__t=( doc_primary_family_set_title ))==null?'':_.escape(__t))+
'</td>\n<td class="date">\n    ';
 if (doc_primary_family == 'news' || doc_primary_family == 'srv') { 
__p+='\n        '+
((__t=( app.moment(doc_date).format('DD MMM YYYY') ))==null?'':_.escape(__t))+
'\n    ';
 } else { 
__p+='\n        '+
((__t=( app.moment(doc_date).format('MMM YYYY') ))==null?'':_.escape(__t))+
'\n    ';
 } 
__p+='\n</td>\n<td class="title">\n    <a href="/documents/'+
((__t=( doc_id ))==null?'':_.escape(__t))+
'/';
 if (app.user.has_perm('content.view_all_document_states')) { 
__p+=''+
((__t=( doc_instance ))==null?'':_.escape(__t))+
'';
 } 
__p+='">\n        ';
 if (doc_primary_family === 'timeline') { 
__p+='\n            Timeline\n        ';
 } else { 
__p+='\n            '+
((__t=( doc_title ))==null?'':_.escape(__t))+
'\n        ';
 } 
__p+='\n    </a>\n    <div class="row-controls">\n        ';
 if(obj.doc_search_abstract) { 
__p+='\n        <button type="button" class="btn btn-sm show-abstract no-print" title="Show document abstract">\n            <span class="icon icon-sm"><span class="picon picon-info"></span></span>\n        </button>\n        ';
 } 
__p+='\n        <div class="btn-group">\n            <button type="button" class="btn btn-sm show-document no-print" title="Show this document">\n                Show Document\n            </button>\n            <button type="button" class="btn btn-sm show-document new-tab no-print" title="Show this document in a new tab">\n                <span class="icon icon-sm"><span class="picon picon-goto"></span></span>\n            </button>\n        </div>\n    </div>\n    <div class="mini-previewpane">\n        ';
 if(obj.doc_search_abstract) { 
__p+='\n            <p>'+
((__t=( obj.doc_search_abstract ))==null?'':_.escape(__t))+
'</p>\n        ';
 } 
__p+='\n    </div>\n</td>\n';
}
return __p;
};
