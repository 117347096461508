import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

import Checkbox from '../FormControls/Checkbox';
import FormModal, { MODAL_SIZE } from '../FormControls/FormModal';

const EmailAlertPreferenceModal = () => {
    const [show, setShow] = useState(true);
    const [checkbox, setCheckbox] = useState(false);

    const submitForm = () => {
        document.location.href = `${app.urls.settings}#your-alerts`;
    };

    const hideModal = async () => {
        try {
            if (checkbox) {
                await app.user.commsPrefs(
                    {
                        has_set_alert_preferences: true,
                    },
                    true,
                );
            }
        } catch (error) {
            console.error("Couldn't disable showing alert preference reminder");
        }

        setShow(false);
    };

    const setChecked = () => {
        setCheckbox(!checkbox);
    };

    const extraButtons = (
        <div className="modal-footer-checkbox">
            <Checkbox
                checked={checkbox}
                onChange={(e) => setChecked(e)}
                label="Don't ask me again"
            />
        </div>
    );

    return (
        <FormModal
            title="Set up your email alerts"
            {...{
                show,
                submitForm,
                hideModal,
                extraButtons,
                loading: false,
                size: MODAL_SIZE.MEDIUM,
                saveText: 'Configure email alerts',
            }}
        >
            <div className="info-text-alert">
                <p>
                    Make Perspective even more useful for you by setting up email alerts. You can
                    control their frequency and unsubscribe at any time.
                </p>
                <Form.Label>Perspective news alerts</Form.Label>
                <p>
                    Stay up to date with all developments affecting the UK pensions industry, on the
                    topics of your choice.
                </p>
                <Form.Label>Document alerts</Form.Label>
                <p>
                    Want to know when documents get updated or added to Perspective? Subscribe to
                    receive additions to the New And Recently Amended Documents list by email.
                </p>
            </div>
        </FormModal>
    );
};

export default EmailAlertPreferenceModal;
