define([], function() {

    var currentNodes = [];

    /*
        Time travel function
    */
    var TimeTraveller = function(view) {

        this.view = view;

        var ttClasses = 'tts tts-regular tts-hidden tts-orig-into-force tts-orig-into-force-strike tts-not-in-force tts-not-in-force-future-repealed tts-repealed tts-repealed-before-in-force tts-repealed-before-future-inserted tts-inserted tts-inserted-repealed tts-inserted-future-repealed tts-future-repealed tts-future-inserted tts-future-inserted-future-repealed';

        var getClass = function(element, viewDate, viewDate2, viewOption) {

            // Don't time travel if the between view dates are invalid
            if ((viewOption == 'tt-between') && (viewDate > viewDate2)) {
                return;
            }

            var tt = '';
            var lenfo = element.attr('enfor-dt');
            var lrenf = element.attr('renfo-dt');
            
            if (lenfo && lrenf) {

                // Only legal values of attribute are orig="orig" (default if attribute is missing) and orig="new"    
                var lview = viewDate;
                var lview2 = viewDate2;
                var orig = element.attr('orig');
                var sAttrib = orig ? orig.toUpperCase() :  '';

                if (sAttrib == 'ORIG') {
                    sAttrib = '';
                }

                // Reuse the tt-between view for tt-onthisday
                // but adjust the dates so that we are looking
                // over a 1 day range based on the current view 
                // date and the day before.
                if ( viewOption == 'tt-onthisday' ) {
                    viewOption = 'tt-between';
                    lview2 = lview;
                    
                    // Subtract a day off the original view date then we can compare using
                    // the between dates transforms
                    var lviewDate = app.moment(lview, app.settings.dateTransportFormat);
                    if (lviewDate.isValid()) {
                        lviewDate = lviewDate.subtract(1, 'day');
                        lview = lviewDate.format(app.settings.dateTransportFormat);
                    }
                }

                // Current section view
                if ( viewOption == 'tt-current' ) {

                    if (sAttrib == '') {
                    
                        if ( (lview >= lenfo) && (lview < lrenf) ) {
                            tt = 'tts-regular';
                        }
                        else if ( (lview < lenfo) && (lview < lrenf) ) {
                            tt = 'tts-not-in-force';
                        }
                        else {
                            tt = 'tts-hidden';
                        }

                    } else if (sAttrib == 'NEW') {
                    
                        if ( (lview >= lenfo) && (lview < lrenf) ) {
                            tt = 'tts-regular';
                        }
                        else {
                            tt = 'tts-hidden';
                        }
                    }
                } // current view

                // Past amendents section view
                if ( viewOption == 'tt-past' ) {

                    if (sAttrib == '') {
                    
                        if ( (lview >= lenfo) && (lview < lrenf) ) {
                            tt = 'tts-regular';
                        }
                        else if ( (lview < lenfo) && (lview < lrenf) ) {
                            tt = 'tts-not-in-force';
                        }
                        else if ( (lview >= lenfo) && (lview >= lrenf) ) {
                            tt = 'tts-repealed';
                        }
                        else if ( (lview < lenfo) && (lview >= lrenf) ) {
                            tt = 'tts-repealed-before-in-force';
                        }

                    } else if (sAttrib == 'NEW') {

                        if ( (lview >= lenfo) && (lview < lrenf) ) {
                            tt = 'tts-inserted';
                        }
                        else if ( (lview >= lenfo) && (lview >= lrenf) ) {
                            tt = 'tts-inserted-repealed';
                        }
                        else {
                            tt = 'tts-hidden';
                        }
                    }
                } // past amendments

                
                // On this day view (orginally called Past (Yesterday) view)
                // This now reuses the between dates view code.
                

                // Future amendments section
                if ( viewOption == 'tt-future' ) {

                    if (sAttrib == '') {
                    
                        if ( (lview >= lenfo) && (!(lrenf < 30000102)) && (lview < lrenf) ) {
                            tt = 'tts-regular';
                        }
                        else if ( (lview < lenfo) && (!(lrenf < 30000102)) && (lview < lrenf) ) {
                            tt = 'tts-not-in-force';
                        }
                        else if ( (lview >= lenfo) && (lrenf < 30000102) && (lview < lrenf) ) {
                            tt = 'tts-future-repealed';
                        }
                        else if ( (lview < lenfo) && (lrenf < 30000102) && (lview < lrenf) ) {
                            tt = 'tts-not-in-force-future-repealed';
                        }
                        else {
                            tt = 'tts-hidden';
                        }

                    } else if (sAttrib == 'NEW') {
                    
                        if ( (lview >= lenfo) && (!(lrenf < 30000102)) && (lview < lrenf) ) {
                            tt = 'tts-regular';
                        }
                        else if ( (lview < lenfo) && (!(lrenf < 30000102)) && (lview < lrenf) ) {
                            tt = 'tts-future-inserted';
                        }
                        else if ( (lview >= lenfo) && (lrenf < 30000102) && (lview < lrenf) ) {
                            tt = 'tts-future-repealed';
                        }
                        else if ( (lview < lenfo) && (lrenf < 30000102) && (lview < lrenf) ) {
                            tt = 'tts-future-inserted-future-repealed';
                        }
                        else {
                            tt = 'tts-hidden';
                        }
                    }
                } // future amendments

                // All amendments section view
                if ( viewOption == 'tt-all' ) {

                    if (sAttrib == '') {
                        
                        if ( (lview >= lenfo) && (!(lrenf < 30000102)) && (lview < lrenf) ) {
                            tt = 'tts-regular';
                        }
                        else if ( (lview < lenfo) && (!(lrenf < 30000102)) && (lview < lrenf) ) {
                            tt = 'tts-not-in-force';
                        }
                        else if ( (lview >= lenfo) && (lrenf < 30000102) && (lview < lrenf) ) {
                            tt = 'tts-future-repealed';
                        }
                        else if ( (lview < lenfo) && (lrenf < 30000102) && (lview < lrenf) ) {
                            tt = 'tts-not-in-force-future-repealed';
                        }
                        else if ( (lview >= lenfo) && (lview >= lrenf) ) {
                            tt = 'tts-repealed';
                        }
                        else if ( (lview < lenfo) && (lview >= lrenf) ) {
                            tt = 'tts-repealed-before-in-force';
                        }

                    } else if (sAttrib == 'NEW') {
                    
                        if ( (lview >= lenfo) && (!(lrenf < 30000102)) && (lview < lrenf) ) {
                            tt = 'tts-inserted';
                        }
                        else if ( (lview < lenfo) && (!(lrenf < 30000102)) && (lview < lrenf) ) {
                            tt = 'tts-future-inserted';
                        }
                        else if ( (lview >= lenfo) && (lrenf < 30000102) && (lview < lrenf) ) {
                            tt = 'tts-inserted-future-repealed';
                        }
                        else if ( (lview < lenfo) && (lrenf < 30000102) && (lview < lrenf) ) {
                            tt = 'tts-future-inserted-future-repealed';
                        }
                        else if ( (lview >= lenfo) && (lview >= lrenf) ) {
                            tt = 'tts-inserted-repealed';
                        }
                        else if ( (lview < lenfo) && (lview >= lrenf) ) {
                            tt = 'tts-repealed-before-future-inserted';
                        }
                    }
                } // all amendments view

                // the new view
                if (viewOption == 'tt-between') {

                    if (sAttrib == '') { 
            
                        if ((lview < lenfo) && (lview2 < lenfo) && (lview < lrenf) && (lview2 < lrenf)) {
                            tt = 'tts-not-in-force'; //1/1
                        }
                        else if ((lview < lenfo) && (lview2 >= lenfo) && (lview < lrenf) && (lview2 < lrenf)) {
                            tt = 'tts-orig-into-force'; //1/2
                        }    
                        else if ((lview < lenfo) && (lview2 >= lenfo) && (lview < lrenf) && (lview2 < lrenf)) {
                            tt = 'tts-orig-into-force'; //1/3
                        }
                        else if ((lview < lenfo) && (lview2 >= lenfo) && (lview < lrenf) && (lview2 >= lrenf)) {
                            tt = 'tts-orig-into-force-strike'; //1/4
                        }
                        else if ((lview < lenfo) && (lview2 >= lenfo) && (lview < lrenf) && (lview2 >= lrenf)) {
                            tt = 'tts-orig-into-force-strike'; //1/5
                        }
                        else if ((lview < lenfo) && (lview2 < lenfo) && (lview < lrenf) && (lview2 < lrenf)) {
                            tt = 'tts-not-in-force'; //1/6
                        }
                        else if ((lview < lenfo) && (lview2 < lenfo) && (lview < lrenf) && (lview2 >= lrenf)) {
                            tt = 'tts-repealed-before-in-force'; //1/7
                        }
                        else if ((lview < lenfo) && (lview2 < lenfo) && (lview < lrenf) && (lview2 >= lrenf)) {
                            tt = 'tts-repealed-before-in-force'; //1/8
                        }
                        else if ((lview >= lenfo) && (lview2 >= lenfo) && (lview < lrenf) && (lview2 < lrenf)) {
                            tt = 'tts-regular'; //2/2
                        }               
                        else if ((lview >= lenfo) && (lview2 >= lenfo) && (lview < lrenf) && (lview2 < lrenf)) {
                            tt = 'tts-regular'; //2/3
                        }
                        else if ((lview >= lenfo) && (lview2 >= lenfo) && (lview < lrenf) && (lview2 >= lrenf)) {
                            tt = 'tts-repealed'; //2/4
                        }   
                        else if ((lview >= lenfo) && (lview2 >= lenfo) && (lview < lrenf) && (lview2 >= lrenf)) {
                            tt = 'tts-repealed'; //2/5
                        }
                        else if ((lview >= lenfo) && (lview2 >= lenfo) && (lview < lrenf) && (lview2 < lrenf)) {
                            tt = 'tts-regular'; //3/3
                        }
                        else if ((lview >= lenfo) && (lview2 >= lenfo) && (lview < lrenf) && (lview2 >= lrenf)) {
                            tt = 'tts-repealed'; //3/4
                        }
                        else if ((lview >= lenfo) && (lview2 >= lenfo) && (lview >= lrenf) && (lview2 >= lrenf)) {
                            tt = 'tts-hidden'; //4/4
                        }   
                        else if ((lview >= lenfo) && (lview2 >= lenfo) && (lview >= lrenf) && (lview2 >= lrenf)) {
                            tt = 'tts-hidden'; //5/4
                        }
                        else if ((lview >= lenfo) && (lview2 >= lenfo) && (lview >= lrenf) && (lview2 >= lrenf)) {
                            tt = 'tts-hidden'; //5/5
                        }
                        else if ((lview < lenfo) && (lview2 >= lenfo) && (lview < lrenf) && (lview2 < lrenf)) {
                            tt = 'tts-orig-into-force'; //6/3
                        }
                        else if ((lview < lenfo) && (lview2 >= lenfo) && (lview < lrenf) && (lview2 >= lrenf)) {
                            tt = 'tts-orig-into-force-strike'; //6/4
                        }
                        else if ((lview < lenfo) && (lview2 < lenfo) && (lview < lrenf) && (lview2 < lrenf)) {
                            tt = 'tts-not-in-force'; //6/6
                        }
                        else if ((lview < lenfo) && (lview2 < lenfo) && (lview < lrenf) && (lview2 >= lrenf)) {
                            tt = 'tts-repealed-before-in-force'; //6/7
                        }
                        else if ((lview < lenfo) && (lview2 >= lenfo) && (lview >= lrenf) && (lview2 >= lrenf)) {
                            tt = 'tts-hidden'; //7/4
                        }   
                        else if ((lview < lenfo) && (lview2 < lenfo) && (lview >= lrenf) && (lview2 >= lrenf)) {
                            tt = 'tts-hidden'; //7/7
                        }   
                        else if ((lview < lenfo) && (lview2 >= lenfo) && (lview >= lrenf) && (lview2 >= lrenf)) {
                            tt = 'tts-hidden'; //8/4    
                        }   
                        else if ((lview < lenfo) && (lview2 >= lenfo) && (lview >= lrenf) && (lview2 >= lrenf)) {
                            tt = 'tts-hidden'; //8/5
                        }   
                        else if ((lview < lenfo) && (lview2 < lenfo) && (lview >= lrenf) && (lview2 >= lrenf)) {
                            tt = 'tts-hidden'; //8/7
                        }   
                        else if ((lview < lenfo) && (lview2 < lenfo) && (lview >= lrenf) && (lview2 >= lrenf)) {
                            tt = 'tts-hidden'; //8/8
                        }
            
                    } else if (sAttrib == 'NEW') {
            
                        if ((lview < lenfo) && (lview2 < lenfo) && (lview < lrenf) && (lview2 < lrenf)) {
                            tt = 'tts-hidden'; //9/9
                            }
                        else if ((lview < lenfo) && (lview2 >= lenfo) && (lview < lrenf) && (lview2 < lrenf)) {
                            tt = 'tts-inserted'; //9/10
                        }   
                        else if ((lview < lenfo) && (lview2 >= lenfo) && (lview < lrenf) && (lview2 < lrenf)) {
                            tt = 'tts-inserted'; //9/11
                        }
                        else if ((lview < lenfo) && (lview2 >= lenfo) && (lview < lrenf) && (lview2 >= lrenf)) {
                            tt = 'tts-inserted-repealed'; //9/12
                        }   
                        else if ((lview < lenfo) && (lview2 >= lenfo) && (lview < lrenf) && (lview2 >= lrenf)) {
                            tt = 'tts-inserted-repealed'; //9/13
                        }   
                        else if ((lview < lenfo) && (lview2 < lenfo) && (lview < lrenf) && (lview2 < lrenf)) {
                            tt = 'tts-hidden'; //9/14
                        }   
                        else if ((lview < lenfo) && (lview2 < lenfo) && (lview < lrenf) && (lview2 >= lrenf)) {
                            tt = 'tts-hidden'; //9/15
                        }   
                        else if ((lview < lenfo) && (lview2 < lenfo) && (lview < lrenf) && (lview2 >= lrenf)) {
                            tt = 'tts-hidden'; //9/16
                        }
                        else if ((lview >= lenfo) && (lview2 >= lenfo) && (lview < lrenf) && (lview2 < lrenf)) {
                            tt = 'tts-regular'; //10/10
                        }   
                        else if ((lview >= lenfo) && (lview2 >= lenfo) &&(lview < lrenf) && (lview2 < lrenf)) {
                            tt = 'tts-regular'; //10/11
                        }
                        else if ((lview >= lenfo) && (lview2 >= lenfo) && (lview < lrenf) && (lview2 >= lrenf)) {
                            tt = 'tts-repealed'; //10/12
                        }   
                        else if ((lview >= lenfo) && (lview2 >= lenfo) && (lview < lrenf) && (lview2 >= lrenf)) {
                            tt = 'tts-repealed'; //10/13
                        }   
                        else if ((lview >= lenfo) && (lview2 >= lenfo) && (lview < lrenf) && (lview2 < lrenf)) {
                            tt = 'tts-regular'; //11/11
                        }
                        else if ((lview >= lenfo) && (lview2 >= lenfo) && (lview < lrenf) && (lview2 >= lrenf)) {
                            tt = 'tts-repealed'; //11/12
                        }
                        else if ((lview >= lenfo) && (lview2 >= lenfo) && (lview >= lrenf) && (lview2 >= lrenf)) {
                            tt = 'tts-hidden'; //12/12
                        }   
                        else if ((lview >= lenfo) && (lview2 >= lenfo) && (lview >= lrenf) && (lview2 >= lrenf)) {
                            tt = 'tts-hidden'; //13/12
                        }   
                        else if ((lview >= lenfo) && (lview2 >= lenfo) && (lview >= lrenf) && (lview2 >= lrenf)) {
                            tt = 'tts-hidden'; //13/13
                        }
                        else if ((lview < lenfo) && (lview2 >= lenfo) && (lview < lrenf) && (lview2 < lrenf)) {
                            tt = 'tts-inserted'; //14/11
                        }
                        else if ((lview < lenfo) && (lview2 >= lenfo) && (lview < lrenf) && (lview2 >= lrenf)) {
                            tt = 'tts-inserted-repealed'; //14/12
                        }
                        else if ((lview < lenfo) && (lview2 < lenfo) && (lview < lrenf) && (lview2 < lrenf)) {
                            tt = 'tts-hidden'; //14/14
                        }   
                        else if ((lview < lenfo) && (lview2 < lenfo) && (lview < lrenf) && (lview2 >= lrenf)) {
                            tt = 'tts-hidden'; //14/15
                        }   
                        else if ((lview < lenfo) && (lview2 >= lenfo) && (lview >= lrenf) && (lview2 >= lrenf)) {
                            tt = 'tts-hidden'; //15/12
                        }   
                        else if ((lview < lenfo) && (lview2 < lenfo) && (lview >= lrenf) && (lview2 >= lrenf)) {
                            tt = 'tts-hidden'; //15/15
                        }   
                        else if ((lview < lenfo) && (lview2 >= lenfo) && (lview >= lrenf) && (lview2 >= lrenf)) {
                            tt = 'tts-hidden'; //16/12
                        }   
                        else if ((lview < lenfo) && (lview2 >= lenfo) && (lview >= lrenf) && (lview2 >= lrenf)) {
                            tt = 'tts-hidden'; //16/13
                        }   
                        else if ((lview < lenfo) && (lview2 < lenfo) && (lview >= lrenf) && (lview2 >= lrenf)) {
                            tt = 'tts-hidden'; //16/15
                        }   
                        else if ((lview < lenfo) && (lview2 < lenfo) && (lview >= lrenf) && (lview2 >= lrenf)) {
                            tt = 'tts-hidden'; //16/16
                        }
                    }

                } // between dates                
            }
            if (lenfo && lrenf && element.hasClass('ednote')) {
                var lview = viewDate;
            
                if ((lview >= lenfo) && (lview < lrenf)) {
                    tt = 'tts-regular';
                } else {
                    tt = 'tts-hidden';
                }
            }
            
            return tt;
        }        

        this.filter = _.bind(function(element, callback) {
            var process = false;
            var viewOption = this.view.model.ttViewOption;
            var viewDate = app.moment(this.view.model.ttViewDate);
            var viewDate2 = app.moment(this.view.model.ttViewDate2);

            // Format the view dates if valid and set the process flag
            if ((viewOption == 'tt-between') &&  viewDate.isValid() && viewDate2.isValid()) {
                viewDate = viewDate.format('YYYYMMDD');
                viewDate2 = viewDate2.format('YYYYMMDD');
                process = true;
            } else if ((viewOption != 'tt-between') && viewDate.isValid()) {
                viewDate = viewDate.format('YYYYMMDD');
                process = true;
            }
            
            if (process) {
                // declaring this local to the function - is this ok?
                var currentNodes = [];
                var els = $(element).find('li, ul.amend, span.amend, span.version, span.effective, div');
                if(els.length) {
                    els.each(_.bind(function(cb, i, el) {
                        // need to bind callback to this local context
                        var cbfunc = cb
                        var $el = $(el);
                        var ttClass = getClass($el, viewDate, viewDate2, viewOption);
                        currentNodes.push($el);                    
    
                        _.defer(function() {
                            if(ttClass) {
                                $el
                                .removeClass(ttClasses)
                                .addClass('tts ' + ttClass);
                            }
                            
                            _.pull(currentNodes, $el);
                            if(_.isEmpty(currentNodes) && cbfunc) {
                               cbfunc();
                            }
                        }, this);
                    }, this, callback));
                }
                else {
                    if(callback) {
                        callback();
                    }
                }
            }
            else {
                if(callback) {
                    callback();
                }
            }
        }, this);        
    }
    
    return TimeTraveller;

});
